import React, { useCallback } from "react";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CoverageEligibilityCheckResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CheckCoverageEligibility/CoverageEligibilityCheckResult";
import CoverageEligibilityCheckResultSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/CoverageEligibilityCheckResultSelectBox/CoverageEligibilityCheckResultSelectBox";

interface ICoverageEligibilityCheckResultColumnDependencies {
    toolkitLocalizationService: IToolkitLocalizationService;
}

type ICoverageEligibilityCheckResultColumnProps = IDataGridColumnBaseProps & { _dependencies?: ICoverageEligibilityCheckResultColumnDependencies; };


const CoverageEligibilityCheckResultColumn: React.FC<ICoverageEligibilityCheckResultColumnProps> = props => {

    const getColor = (value: CoverageEligibilityCheckResult) => {
        switch (value) {
            case CoverageEligibilityCheckResult.Green:
                return "#21C313";
            case CoverageEligibilityCheckResult.Red:
                return "#CE5374";
            case CoverageEligibilityCheckResult.Blue:
                return "#82D4ED";
            case CoverageEligibilityCheckResult.Yellow:
                return "#DDC00D";
            case CoverageEligibilityCheckResult.Brown:
                return "#978307";
            case CoverageEligibilityCheckResult.Orange:
                return "#FFA500";
        }
    };

    const getShorthand = useCallback((value: CoverageEligibilityCheckResult) => {
        const localizationResult = props._dependencies.toolkitLocalizationService.localizeEnum(CoverageEligibilityCheckResult[value], "CoverageEligibilityCheckResult");
        return localizationResult?.Shorthand || "";
    }, []);

    const valueRenderer = useCallback((value: CoverageEligibilityCheckResult) => {
        return <span style={{color: getColor(value)}}>{getShorthand(value)}</span>;
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<CoverageEligibilityCheckResult>) => {
        return <CoverageEligibilityCheckResultSelectBox {...filterProps} hoverOnlyIndicatorIcons />;
    }, []);

    const { children, ...columnProps } = props;

    return (
        <DataGridColumn
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            onRenderHintValue={getShorthand}
        />
    );
};

export default connect(
    CoverageEligibilityCheckResultColumn,
    new DependencyAdapter<ICoverageEligibilityCheckResultColumnProps, ICoverageEligibilityCheckResultColumnDependencies>(c => ({
        toolkitLocalizationService: c.resolve("IToolkitLocalizationService"),
    }))
);