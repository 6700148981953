import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import RiskAssessmentId from "@Primitives/RiskAssessmentId.g";
import RiskAssessmentStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/RiskAssessment/RiskAssessmentStore";
import RiskAssessmentStoreMapper from "./RiskAssessmentStoreMapper";
import PatientId from "@Primitives/PatientId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import { getAddRiskAssessmentControllerDto, getUpdateRiskAssessmentControllerDto, getDeleteRiskAssessmentHistoryItemControllerDto, getDeleteRiskAssessmentControllerDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/RiskAssessment/RiskAssessmentDtoMapper";

@Di.injectable()
export default class RiskAssessmentApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IRiskAssessmentClient") private readonly apiClient: Proxy.IRiskAssessmentClient,
        @Di.inject("RiskAssessmentStoreMapper") private readonly storeMapper: RiskAssessmentStoreMapper
    ) {
        super();
    }

    public getByIdAsync(riskAssessmentId: RiskAssessmentId, requestLock: boolean): Promise<RiskAssessmentStore> {
        return this.processOperationAsync(
            new RiskAssessmentStore(false),
            async target => {
                const response = await this.apiClient.getRiskAssessmentByIdCommandAsync(
                    CreateRequestId(),
                    new Proxy.GetRiskAssessmentByIdControllerDto({ riskAssessmentId: riskAssessmentId, requestLock: requestLock })
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public addRiskAssessmentAsync(store: RiskAssessmentStore, patientId: PatientId, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new RiskAssessmentStore(false),
            async target => {
                const response = await this.apiClient.addRiskAssessmentCommandAsync(
                    CreateRequestId(),
                    getAddRiskAssessmentControllerDto(store, patientId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public updateRiskAssessmentAsync(store: RiskAssessmentStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new RiskAssessmentStore(false),
            async target => {
                const response = await this.apiClient.updateRiskAssessmentCommandAsync(
                    CreateRequestId(),
                    getUpdateRiskAssessmentControllerDto(store, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deleteRiskAssessmentHistoryItemAsync(store: RiskAssessmentStore, versionNumber: number, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new RiskAssessmentStore(false),
            async target => {
                const response = await this.apiClient.deleteRiskAssessmentHistoryItemCommandAsync(
                    CreateRequestId(),
                    getDeleteRiskAssessmentHistoryItemControllerDto(store, versionNumber, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deleteRiskAssessmentAsync(store: RiskAssessmentStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new SimpleStore<any>(),
            async target => {
                const response = await this.apiClient.deleteRiskAssessmentCommandAsync(
                    CreateRequestId(),
                    getDeleteRiskAssessmentControllerDto(store),
                    isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public validateAsync(store: RiskAssessmentStore, patientId: PatientId) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response =
                    store.isNew ?
                        await this.apiClient.addRiskAssessmentCommandAsync(CreateRequestId(), getAddRiskAssessmentControllerDto(store, patientId, true)) :
                        await this.apiClient.updateRiskAssessmentCommandAsync(CreateRequestId(), getUpdateRiskAssessmentControllerDto(store, true, store.lockInfo?.lockId, false));

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    public async checkPermissionForAddNewItemAsync(store: RiskAssessmentStore, patientId: PatientId): Promise<RiskAssessmentStore> {
        return await this.addRiskAssessmentAsync(store, patientId, true);        
    }

    public async checkPermissionForUpdateItemAsync(store: RiskAssessmentStore): Promise<RiskAssessmentStore> {
        return await this.updateRiskAssessmentAsync(store, true);
    }

    public async checkPermissionForDeleteItemAsync(store: RiskAssessmentStore): Promise<SimpleStore> {
        return await this.deleteRiskAssessmentAsync(store, true);
    }
}
