export default class ReferenceDataCollector<T = unknown> {

    private readonly _data: T[] = [];
    public get data() { return this._data; }

    constructor(
        public readonly typeName: string
    ) {}

    public add(item: T | T[]) {
        if (!item) {
            return;
        }

        if (Array.isArray(item)) {
            this._data.push(...item);
        } else {
            this._data.push(item);
        }
    }
}