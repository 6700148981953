import React, { useCallback } from "react";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import { RegisterLocalActionProcessor } from "@Toolkit/ReactClient/ActionProcessing/ActionDispatcher";
import ICareActivitiesForEpisodeOfCareScreenExtensionProps from "@HisPlatform/Packages/Care/Screens/CareActivitiesForEpisodeOfCareScreen/ICareActivitiesForEpisodeOfCareScreenExtensionProps";
import TryShowCreateNewTelemetrySessionScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/TryShowCreateNewTelemetrySessionScreenAction.g";
import ShowCreateNewTelemetrySessionScreenAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowCreateNewTelemetrySessionScreenAction.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareActivitiesForEpisodeOfCareScreenExtensionApiAdapter from "./CareActivitiesForEpisodeOfCareScreenExtensionApiAdapter";
import StaticAssecoMedWebAppResources from "@AssecoMedPlugin/StaticResources/StaticAssecoMedWebAppResources";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import TelemedicineAppointmentDialogParams, { ITelemedicineAppointmentDialogResult } from "@AssecoMedPlugin/Packages/Care/Screens/TelemedicineScreen/TelemedicineAppointmentDialog/TelemedicineAppointmentDialogParams";
import ShowNewTelemedicineAppointmentsAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ShowNewTelemedicineAppointmentsAction.g";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import CallState from "@HisPlatform/BoundedContexts/Telecommunication/Api/Telecommunication/Enum/CallState.g";
import TelecommunicationApiAdapter from "@HisPlatform/Packages/Care/ApiAdapter/TelecommunicationApiAdapter";
import AssecoRoomsCallContext from "@AssecoRoomsPlugin/AssecoRoomsCallContext";
import StartTelemedicineCallAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/StartTelemedicineCallAction.g";
import EndTelemedicineCallAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/EndTelemedicineCallAction.g";
import ShowCareActivityTabbedScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityTabbedScreenAction.g";
import ResumeTelemedicineCallAction from "@AssecoMedPlugin/Packages/Care/FrontendActions/ResumeTelemedicineCallAction.g";

interface IExtendedCareActivitiesForEpisodeOfCareScreenExtensionProps extends ICareActivitiesForEpisodeOfCareScreenExtensionProps {
    _dependencies?: IExtendedCareActivitiesForEpisodeOfCareScreenExtensionDependencies;
    _modalService?: IModalService;
    refreshListEvent: TypedAsyncEvent;
}

interface IExtendedCareActivitiesForEpisodeOfCareScreenExtensionDependencies {
    apiAdapter: CareActivitiesForEpisodeOfCareScreenExtensionApiAdapter;
    telecommunicationApiAdapter: TelecommunicationApiAdapter;
    assecoRoomsCallContext: AssecoRoomsCallContext;
    notificationService: INotificationService;
}

function CareActivitiesForEpisodeOfCareScreenExtension(props: IExtendedCareActivitiesForEpisodeOfCareScreenExtensionProps) {

    const tryShowCreateNewTelemetrySessionScreen = useCallback(async (action: FrontendActionBase) => {
        if (!(action instanceof TryShowCreateNewTelemetrySessionScreenAction)) {
            return;
        }

        const result = await props._dependencies.apiAdapter.isForeignTelemetrySessionInProgressAsync(action.patientId, action.episodeOfCareId);
        if (result.result) {
            props._dependencies.notificationService.error(StaticAssecoMedWebAppResources.CareActivitiesForEpisodeOfCareScreen.ActiveForeignEpisodeError);
            return;
        }

        await props.actionDispatcher.dispatchAsync(
            new ShowCreateNewTelemetrySessionScreenAction(
                ScreenDisplayMode.Detail,
                action.patientId,
                action.externalPatientId,
                action.episodeOfCareId
            )
        );
    }, [props.actionDispatcher]);

    const onCreateTelemedicineAppointmentAsync = useCallback(async (action: FrontendActionBase) => {
        if (!(action instanceof ShowNewTelemedicineAppointmentsAction)) {
            return;
        }
        const result = await props._modalService.showDialogAsync<ITelemedicineAppointmentDialogResult>(new TelemedicineAppointmentDialogParams(action.patientId, action.episodeOfCareId));
        if (result.careActivityId) {
            await props.refreshListEvent.emitAsync();
        }
    }, [props.refreshListEvent]);

    const onStartTelemedicineCallAsync = useCallback(async (action: FrontendActionBase) => {
        if (!(action instanceof StartTelemedicineCallAction)) {
            return;
        }

        const call = (await props._dependencies.telecommunicationApiAdapter.tryGetCallByCareActivityIdAsync(action.careActivityId)).result;
        await props._dependencies.assecoRoomsCallContext.loadMicrophonePermissionAsync();
        await props._dependencies.assecoRoomsCallContext.loadCameraPermissionAsync();

        const startedCall = (await props._dependencies.telecommunicationApiAdapter.updateCallStateByIdAsync(call.id, CallState.Active, call.rowVersion)).result;
        props._dependencies.assecoRoomsCallContext.showAssecoRooms(startedCall.organizerIdentifier);
        await props._dependencies.assecoRoomsCallContext.checkForPermissionsAsync();

        await props.actionDispatcher.dispatchAsync(
            new ShowCareActivityTabbedScreenAction(
                ScreenDisplayMode.Detail,
                "CareActivityOfEpisodeOfCare",
                action.careActivityId
            )
        );

        await props.refreshListEvent.emitAsync();
    }, [props.refreshListEvent]);

    const onResumeTelemedicineCallAsync = useCallback(async (action: FrontendActionBase) => {
        if (!(action instanceof ResumeTelemedicineCallAction) || props._dependencies.assecoRoomsCallContext.assecoRoomsIsVisible) {
            return;
        }

        const call = (await props._dependencies.telecommunicationApiAdapter.tryGetCallByCareActivityIdAsync(action.careActivityId)).result;
        props._dependencies.assecoRoomsCallContext.showAssecoRooms(call.organizerIdentifier);

        await props.actionDispatcher.dispatchAsync(
            new ShowCareActivityTabbedScreenAction(
                ScreenDisplayMode.Detail,
                "CareActivityOfEpisodeOfCare",
                action.careActivityId
            )
        );

        await props.refreshListEvent.emitAsync();
    }, [props.refreshListEvent, props._dependencies.assecoRoomsCallContext.assecoRoomsIsVisible]);

    const onEndTelemedicineCallAsync = useCallback(async (action: FrontendActionBase) => {
        if (!(action instanceof EndTelemedicineCallAction)) {
            return;
        }

        const call = (await props._dependencies.telecommunicationApiAdapter.tryGetCallByCareActivityIdAsync(action.careActivityId)).result;
        await props._dependencies.telecommunicationApiAdapter.updateCallStateByIdAsync(call.id, CallState.Closed, call.rowVersion);
        props._dependencies.assecoRoomsCallContext.hideAssecoRooms();
        await props._dependencies.apiAdapter.setTelemedicineUnderCareAsync(action.careActivityId);
        await props.refreshListEvent.emitAsync();
    }, [props.refreshListEvent]);

    return (
        <>
            <RegisterLocalActionProcessor
                actionId={TryShowCreateNewTelemetrySessionScreenAction.id}
                processAsync={tryShowCreateNewTelemetrySessionScreen}
                displaySettings={{
                    displayName: StaticAssecoMedWebAppResources.TelemetrySessionScreen.Buttons.CreateNew,
                    iconName: "briefcaseSolid"
                }}
            />

            <RegisterLocalActionProcessor
                actionId={ShowNewTelemedicineAppointmentsAction.id}
                processAsync={onCreateTelemedicineAppointmentAsync}
                displaySettings={{
                    displayName: StaticAssecoMedWebAppResources.TelemedicineScreen.Title,
                    iconName: "new_appointment"
                }}
            />

            <RegisterLocalActionProcessor
                actionId={StartTelemedicineCallAction.id}
                processAsync={onStartTelemedicineCallAsync}
                displaySettings={{
                    displayName: StaticAssecoMedWebAppResources.TelemedicineScreen.Buttons.StartCall,
                    iconName: "phone_call"
                }}
            />

            <RegisterLocalActionProcessor
                actionId={ResumeTelemedicineCallAction.id}
                processAsync={onResumeTelemedicineCallAsync}
                displaySettings={{
                    displayName: StaticAssecoMedWebAppResources.TelemedicineScreen.Buttons.ResumeCall,
                    iconName: "phone_call"
                }}
            />

            <RegisterLocalActionProcessor
                actionId={EndTelemedicineCallAction.id}
                processAsync={onEndTelemedicineCallAsync}
                displaySettings={{
                    displayName: StaticAssecoMedWebAppResources.TelemedicineScreen.Buttons.EndCall,
                    iconName: "end_call"
                }}
            />
        </>
    );
}

export default connect(
    CareActivitiesForEpisodeOfCareScreenExtension,
    new DependencyAdapter<IExtendedCareActivitiesForEpisodeOfCareScreenExtensionProps, IExtendedCareActivitiesForEpisodeOfCareScreenExtensionDependencies>(container => ({
        apiAdapter: container.resolve("CareActivitiesForEpisodeOfCareScreenExtensionApiAdapter"),
        telecommunicationApiAdapter: container.resolve("TelecommunicationApiAdapter"),
        assecoRoomsCallContext: container.resolve("AssecoRoomsCallContext"),
        notificationService: container.resolve("INotificationService")
    })),
    new HisModalServiceAdapter()
);