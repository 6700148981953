import React, { useContext } from "react";
import RoutingFrameContext from "@Toolkit/ReactClient/Routing/RoutingFrameContext";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { IBusinessErrorHandler, BusinessErrorHandlerContext } from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface INavigateAwayHookProps {
    isEnabled?: boolean;
    onNavigateAwayAsync: () => Promise<boolean>;
}

class NavigateAwayHookCore extends React.PureComponent<INavigateAwayHookProps & { _context: IRoutingController, _businessErrorHandler: IBusinessErrorHandler }> {

    public componentDidMount() {
        this.setHook();
    }

    public componentDidUpdate(prevProps: INavigateAwayHookProps & { _context: IRoutingController, _businessErrorHandler: IBusinessErrorHandler }) {
        if (prevProps.onNavigateAwayAsync !== this.props.onNavigateAwayAsync || prevProps._context !== this.props._context) {
            this.unMountHook(prevProps._context);
            this.setHook();
        }
    }

    public componentWillUnmount() {
        if (!isNullOrUndefined(this.props._context) && !isNullOrUndefined(this.props._context.navigatingAwayHookAsync)) {
            this.props._context.navigatingAwayHookAsync = null;
        }
    }

    private unMountHook(context: IRoutingController) {
        if (context) {
            context.navigatingAwayHookAsync = null;
        }
    }
    private setHook() {
        if (!isNullOrUndefined(this.props._context)) {
            this.props._context.navigatingAwayHookAsync = this.navigatingAwayHook;
        }
    }

    @State.bound
    private navigatingAwayHook() {
        return new Promise((resolve: (ret: boolean) => void, _reject: (err: Error) => void) => {
            if (!this.props.isEnabled) {
                resolve(true);
            }

            dispatchAsyncErrors(this.props.onNavigateAwayAsync().then(resolve), this);
        });
    }

    public render() {
        return null as React.ReactNode;
    }

}


const NavigateAwayHook: React.FC<INavigateAwayHookProps> = props => {
    const routingFrameContext = useContext(RoutingFrameContext);
    const businessErrorHandlerContext = useContext(BusinessErrorHandlerContext);

    return <NavigateAwayHookCore {...props} _context={routingFrameContext} _businessErrorHandler={businessErrorHandlerContext} />;
};

NavigateAwayHook.defaultProps = {
    isEnabled: true
};

export default NavigateAwayHook;