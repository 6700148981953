import BusinessErrorBase from "@HisPlatform/Model/DomainModel/BusinessError/BusinessErrorBase";
import UserId from "@Primitives/UserId.g";
import LockId from "@Toolkit/CommonWeb/Model/LockId";

export default class LockAcquisitionError extends BusinessErrorBase {

    public static businessErrorName = "LockAcquisitionError";
    public get name(): string { return LockAcquisitionError.businessErrorName; }

    constructor(userIdValue: string, public readonly lockId: LockId | null) {
        super();
        if (userIdValue) {
            this.lockOwnerUserId = new UserId(userIdValue);
        }
    }

    public lockOwnerUserId: UserId;
}