import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import ReferenceDataCollector from "@HisPlatform/Services/Definition/ReferenceDataLoader/ReferenceDataCollector";
import Di from "@Di";

export type ReferenceLoaderFunction<T> = (refs: T[]) => Promise<void>;

@Di.injectable()
export default class ReferenceDataLoader implements IReferenceDataLoader {

    private readonly referenceDataLoaders = new Map<string, ReferenceLoaderFunction<unknown>>();

    public register<T>(typeName: string, loaderFunction: ReferenceLoaderFunction<T>): void {
        this.referenceDataLoaders.set(typeName, loaderFunction);
    }

    public tryCreateCollector<T>(typeName: string) {
        const normalizedTypeName = typeName.replace(/\[\]$/, "");
        
        if (!this.referenceDataLoaders.has(normalizedTypeName)) {
            return null;
        }

        return new ReferenceDataCollector<T>(normalizedTypeName);
    }

    public async loadCollectedAsync<T>(collector: ReferenceDataCollector<T>) {
        const loader = this.referenceDataLoaders.get(collector.typeName);

        if (!loader) {
            throw new Error(`ReferenceDataLoader for type '${collector.typeName}' is not registered!`);
        }

        await loader(collector.data);
    }
}