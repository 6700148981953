import React from "react";
import RecurringTimePhasePanelView from "./RecurringTimePhasePanelView";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import TimePhaseRecurrenceElement from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseRecurrenceElement";
import RecurrenceType from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/RecurrenceType";
import RecurrenceFrequency from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/RecurrenceFrequency";
import _ from "@HisPlatform/Common/Lodash";
import WeekParity from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/WeekParity";
import { arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import TimePhaseInterval from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseInterval";
import DayOfWeek from "@HisPlatform/BoundedContexts/Scheduling/Api/Configuration/Enum/DayOfWeek.g";
import WeekOfMonth from "@HisPlatform/BoundedContexts/Scheduling/Api/Configuration/Enum/WeekOfMonth.g";

interface IRecurringTimePhasePanelProps {
    interval: TimePhaseInterval;
    setTimePhaseInterval: (from: TimeOfDay, to: TimeOfDay, isFinal: boolean) => void;

    recurrenceElements: TimePhaseRecurrenceElement[];
    onSetRecurrenceElements: (value: TimePhaseRecurrenceElement[]) => void;

    propertyIdentifier?: string;
}

@State.observer
export default class RecurringTimePhasePanel extends React.Component<IRecurringTimePhasePanelProps> {

    private static missingElementsError = new Error("IRecurringTimePhasePanelProps.recurrenceElements must be initialized with a non-empty TimePhaseRecurrenceElement array.");

    @State.computed private get recurrenceFrequency() {
        if (this.props.recurrenceElements?.length > 0) {
            return this.props.recurrenceElements[0].recurrenceType === RecurrenceType.DayOfMonth ? RecurrenceFrequency.Monthly : RecurrenceFrequency.Weekly;
        }
        throw RecurringTimePhasePanel.missingElementsError;
    }

    @State.computed private get weekParity() {
        if (this.props.recurrenceElements?.length > 0) {
            switch (this.props.recurrenceElements[0].recurrenceType) {
                case RecurrenceType.DayOfMonth:
                case RecurrenceType.DayOfWeek:
                    return WeekParity.All;
                case RecurrenceType.EvenOdd:
                    return this.props.recurrenceElements[0].isEvenWeek ? WeekParity.Even : WeekParity.Odd;
            }
        }
        throw RecurringTimePhasePanel.missingElementsError;
    }

    @State.computed private get weeksOfMonth() {
        if (this.props.recurrenceElements?.length > 0) {
            const weeks = _.uniq(this.props.recurrenceElements.map(e => e.weekOfMonth).filter(w => !isNullOrUndefined(w))).sort();
            return weeks.length > 0 ? weeks : [WeekOfMonth.First];
        }
        throw RecurringTimePhasePanel.missingElementsError;
    }

    @State.computed private get daysOfWeek() {
        if (this.props.recurrenceElements?.length > 0) {
            const days = _.uniq(this.props.recurrenceElements.map(e => e.dayOfWeek).filter(d => !isNullOrUndefined(d))).sort();
            return days;
        }
        throw RecurringTimePhasePanel.missingElementsError;
    }

    @State.action.bound
    private setRecurrenceFrequency(value: RecurrenceFrequency) {
        const allRecurrenceElements = this.daysOfWeek.reduce((allElements, day) => {
            const elementsOfDay: TimePhaseRecurrenceElement[] = [];

            const recurrenceType = this.getRecurrenceType(value, this.weekParity);

            if (recurrenceType === RecurrenceType.DayOfMonth) {
                const elementsOfWeeks = this.weeksOfMonth.reduce((elements, week) => {
                    const element = new TimePhaseRecurrenceElement();
                    element.setRecurrenceType(recurrenceType);
                    element.setDayOfWeek(day);
                    element.setWeekOfMonth(week);
                    elements.push(element);
                    return elements;
                }, [] as TimePhaseRecurrenceElement[]);
                elementsOfDay.push(...elementsOfWeeks);
            } else {
                const element = new TimePhaseRecurrenceElement();
                element.setRecurrenceType(recurrenceType);
                element.setDayOfWeek(day);
                if (recurrenceType === RecurrenceType.EvenOdd) {
                    element.setEvenWeek(this.getEvenWeek(this.weekParity));
                }
                elementsOfDay.push(element);
            }

            return allElements.concat(elementsOfDay);
        }, [] as TimePhaseRecurrenceElement[]);

        this.props.onSetRecurrenceElements(allRecurrenceElements);
    }

    @State.action.bound
    private setWeeksOfMonth(value: WeekOfMonth[]) {
        if (!arrayIsNullOrEmpty(value)) {
            const allRecurrenceElements = this.daysOfWeek.reduce((allElements, day) => {
                const elementsOfDay: TimePhaseRecurrenceElement[] = [];

                const recurrenceType = this.getRecurrenceType(this.recurrenceFrequency, this.weekParity);

                if (recurrenceType === RecurrenceType.DayOfMonth) {
                    const elementsOfWeeks = value.reduce((elements, week) => {
                        const element = new TimePhaseRecurrenceElement();
                        element.setRecurrenceType(recurrenceType);
                        element.setDayOfWeek(day);
                        element.setWeekOfMonth(week);
                        elements.push(element);
                        return elements;
                    }, [] as TimePhaseRecurrenceElement[]);
                    elementsOfDay.push(...elementsOfWeeks);
                } else {
                    throw new Error("Weeks of month can only have value if recurrence type is DayOfMonth."); // TODO tidy up
                }

                return allElements.concat(elementsOfDay);
            }, [] as TimePhaseRecurrenceElement[]);

            this.props.onSetRecurrenceElements(allRecurrenceElements);
        }
    }

    @State.action.bound
    private setWeekParity(value: WeekParity) {
        const allRecurrenceElements = this.daysOfWeek.reduce((allElements, day) => {
            const elementsOfDay: TimePhaseRecurrenceElement[] = [];

            const recurrenceType = this.getRecurrenceType(this.recurrenceFrequency, value);

            if (recurrenceType === RecurrenceType.DayOfMonth) {
                throw new Error("Week parity cannot have value if recurrnce type is DayOfMonth."); // TODO tidy up
            } else {
                const element = new TimePhaseRecurrenceElement();
                element.setRecurrenceType(recurrenceType);
                element.setDayOfWeek(day);
                if (recurrenceType === RecurrenceType.EvenOdd) {
                    element.setEvenWeek(this.getEvenWeek(value));
                }
                elementsOfDay.push(element);
            }

            return allElements.concat(elementsOfDay);
        }, [] as TimePhaseRecurrenceElement[]);

        this.props.onSetRecurrenceElements(allRecurrenceElements);
    }

    @State.action.bound
    private setDaysOfWeek(value: DayOfWeek[]) {
        if (!arrayIsNullOrEmpty(value)) {
            const allRecurrenceElements = value.reduce((allElements, day) => {
                const elementsOfDay: TimePhaseRecurrenceElement[] = [];

                const recurrenceType = this.getRecurrenceType(this.recurrenceFrequency, this.weekParity);

                if (recurrenceType === RecurrenceType.DayOfMonth) {
                    const elementsOfWeeks = this.weeksOfMonth.reduce((elements, week) => {
                        const element = new TimePhaseRecurrenceElement();
                        element.setRecurrenceType(recurrenceType);
                        element.setDayOfWeek(day);
                        element.setWeekOfMonth(week);
                        elements.push(element);
                        return elements;
                    }, [] as TimePhaseRecurrenceElement[]);
                    elementsOfDay.push(...elementsOfWeeks);
                } else {
                    const element = new TimePhaseRecurrenceElement();
                    element.setRecurrenceType(recurrenceType);
                    element.setDayOfWeek(day);
                    if (recurrenceType === RecurrenceType.EvenOdd) {
                        element.setEvenWeek(this.getEvenWeek(this.weekParity));
                    }
                    elementsOfDay.push(element);
                }

                return allElements.concat(elementsOfDay);
            }, [] as TimePhaseRecurrenceElement[]);

            this.props.onSetRecurrenceElements(allRecurrenceElements);
        }
    }

    private getRecurrenceType(recurrenceFrequency: RecurrenceFrequency, weekParity: WeekParity) {
        switch (recurrenceFrequency) {
            case RecurrenceFrequency.Weekly:
                switch (weekParity) {
                    case WeekParity.All:
                        return RecurrenceType.DayOfWeek;
                    case WeekParity.Even:
                    case WeekParity.Odd:
                        return RecurrenceType.EvenOdd;
                }
                break;
            case RecurrenceFrequency.Monthly:
                return RecurrenceType.DayOfMonth;
        }
    }

    private getEvenWeek(weekParity: WeekParity) {
        switch (weekParity) {
            case null:
            case WeekParity.All:
                return null;
            case WeekParity.Even:
                return true;
            case WeekParity.Odd:
                return false;
        }
    }

    public render() {
        return (
            <RecurringTimePhasePanelView
                interval={this.props.interval}
                setTimePhaseInterval={this.props.setTimePhaseInterval}

                recurrenceFrequency={this.recurrenceFrequency}
                setRecurrenceFrequency={this.setRecurrenceFrequency}

                weeksOfMonth={this.weeksOfMonth}
                setWeeksOfMonth={this.setWeeksOfMonth}

                weekParity={this.weekParity}
                setWeekParity={this.setWeekParity}

                daysOfWeek={this.daysOfWeek}
                setDaysOfWeek={this.setDaysOfWeek}
            />
        );
    }
}