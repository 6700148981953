import DependencyContainer from "@DiContainer";
import UsersApiAdapter from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Users/UsersApiAdapter";
import UserStoreMapper from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Users/UserStoreMapper";
import UserValidationProblemMapper from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Users/UserValidationProblemMapper";

DependencyContainer
    .bind("UsersApiAdapter")
    .to(UsersApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("UserStoreMapper")
    .to(UserStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("UserValidationProblemMapper")
    .to(UserValidationProblemMapper);