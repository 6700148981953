import React from "react";
import { ITokenSettingsPanelPropsBase } from "@PluginInterface/BoundedContexts/DocumentManagement/TokenSettingsPanelRegistry/ITokenSettingsPanelRegistry";
import TextBox from "@CommonControls/TextBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";

interface IBooleanTokenFormatterSettingsPanelProps extends ITokenSettingsPanelPropsBase<Proxy.BooleanTokenValueFormatterSettingsDto> {
}

/** @screen */
export default class BooleanTokenFormatterSettingsPanel extends React.Component<IBooleanTokenFormatterSettingsPanelProps> {
    public render() {
        return (
            <>
                <TextBox
                    label={StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.TrueValueText}
                    value={this.props.tokenFormatterSettings?.trueValueString ?? ""}
                    onChange={this.setTrueValueString}
                    automationId="trueValueStringTextBox" />
                <TextBox
                    label={StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.FalseValueText}
                    value={this.props.tokenFormatterSettings?.falseValueString ?? ""}
                    onChange={this.setFalseValueString}
                    automationId="falseValueStringTextBox" />
            </>
        );
    }

    @State.bound
    private setTrueValueString(value: string) {
        this.props.onSettingsChange(new Proxy.BooleanTokenValueFormatterSettingsDto({
            trueValueString: value,
            falseValueString: this.props.tokenFormatterSettings?.falseValueString ?? ""
        }), null);
    }

    @State.bound
    private setFalseValueString(value: string) {
        this.props.onSettingsChange(new Proxy.BooleanTokenValueFormatterSettingsDto({
            trueValueString: this.props.tokenFormatterSettings?.trueValueString ?? "",
            falseValueString: value
        }), null);
    }
}