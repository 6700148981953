import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { ExtensibleEnumSelectBox } from "@HisPlatform/Components/HisPlatformControls";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";
import InvoiceState from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/InvoiceState";
import { UniversalEnumSelector } from "@CommonControls/index";

interface IInvoiceStateColumnDependencies {
    localizationService: ILocalizationService;
}

interface IInvoiceStateColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IInvoiceStateColumnDependencies;
}

const filterValueSerializer: IDataGridFilterValueSerializer<InvoiceState | InvoiceState[]> = {
    serialize: value => JSON.stringify(value),
    deserialize: value => JSON.parse(value)
};

const InvoiceStateColumn: React.FC<IInvoiceStateColumnProps> = (props: IInvoiceStateColumnProps) => {
    const valueRenderer = useCallback((value: InvoiceState) => {
        const localized = isNullOrUndefined(value) ? null : props._dependencies.localizationService.localizeEnum(InvoiceState[value], "InvoiceState");
        if (isNullOrUndefined(localized)) {
            return null;
        }

        return localized.Name;
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<InvoiceState>) => {
        return (
            <UniversalEnumSelector
                {...filterProps}
                maxMenuWidth={commonMenuMaxWidth}
                enumOrigin="server"
                enumName={"InvoiceState"} 
                enumType={InvoiceState}
                displayMode="selectBox"
                hoverOnlyIndicatorIcons
                automationId="InvoiceStateColumnFilterSelector" />
        );
    }, []);

    return (
        <DataGridColumn
            width={50}
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderHintValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
        />
    );
};

export default connect(
    InvoiceStateColumn,
    new DependencyAdapter<IInvoiceStateColumnProps, IInvoiceStateColumnDependencies>( c => ({
        localizationService: c.resolve("ILocalizationService"),
        financeReferenceDataStore: c.resolve("FinanceReferenceDataStore")
    }))
);