import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import IDataGridDataSource from "@CommonControls/DataGrid/DataSource/IDataGridDataSource";
import {IMultiEntitySelectorBaseProps} from "@CommonControls/MultiEntitySelectorBase/index";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class MultiEntitySelectorStore<TId extends IStringEntityId, TEntity extends EntityStoreBase<TId>> {
    @State.observable private allItemsPageCount: number = 1;
    @State.observable private selectedItemsPageCount: number = 1;

    @State.observable.ref public allItemsDataSource: TEntity[] | IPagedItems<TEntity> | IDataGridDataSource;
    @State.observable.ref public selectedItemsDataSource: TEntity[] | IPagedItems<TEntity> | IDataGridDataSource;

    @State.action.bound
    public reInitDataSources(props: IMultiEntitySelectorBaseProps<TId, TEntity>) {
        this.allItemsDataSource = props.allItemsDataSource;
        this.selectedItemsDataSource = props.selectedItemsDataSource;
    }

    @State.bound
    public isItemSelected(item: TEntity) {
        if (Array.isArray(this.selectedItemsDataSource)) {
            return (this.selectedItemsDataSource as TEntity[]).some(entity => entity.id.value === item.id.value);
        } else if (!this.selectedItemsDataSource) {
            return false;
        }
        const dataSource = this.selectedItemsDataSource as IDataGridDataSource;
        if (dataSource.data) {
            if (Array.isArray(dataSource.data)) {
                return dataSource.data.some(entity => entity.id.value === item.id.value);
            }
            return dataSource.data.items.some(entity => entity.id.value === item.id.value);
        }
        const pagedDataSource = this.selectedItemsDataSource as IPagedItems<TEntity>;
        return pagedDataSource.items.some(entity => entity.id.value === item.id.value);
    }

    @State.action.bound
    public setAllItemsPageCount(count: number) {
        this.allItemsPageCount = count;
    }

    @State.action.bound
    public setSelectedItemsPageCount(count: number) {
        this.selectedItemsPageCount = count;
    }

    @State.computed public get forceShowPagers() {
        return this.allItemsPageCount > 1 || this.selectedItemsPageCount > 1;
    }

    @State.bound
    public async reloadAsync() {
        const dataSource = this.allItemsDataSource as IDataGridDataSource;
        if (dataSource.onChangeAsync) {
            await dataSource.reloadAsync();
        }
    }
}
