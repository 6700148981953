class ActionIdentifiers {
    public static readonly recordCareActivityBaseData = "CareRegister_RecordCareBaseData";
    public static readonly recordDischargeData = "CareRegister_RecordDischargeData";
    public static readonly dischargePatient = "CareRegister_DischargePatient";
    public static readonly recordDiagnosis = "CareRegister_RecordDiagnosis";
    public static readonly editDocuments = "CareRegister_EditDocuments";
    public static readonly recordPerformedServices = "CareRegister_RecordProvidedServices";
    public static readonly editCareActivityFinanceData  = "CareRegister_EditCareActivityFinanceData";
    public static readonly editTextBlocks = "CareRegister_EditTextBlocks";
}

export default ActionIdentifiers;