exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PageTitle_no-text-selection_TJLAt {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.PageTitle_container_2XV5D {\n  width: 100%;\n  display: flex;\n  line-height: normal;\n  letter-spacing: -0.4px;\n  color: #004358;\n  padding: 0px 8px;\n}\n.PageTitle_container_2XV5D .PageTitle_title_1x4ru {\n  font-family: TitilliumWeb;\n  text-transform: uppercase;\n  font-size: 20px;\n}\n.PageTitle_container_2XV5D .PageTitle_title_1x4ru b {\n  font-family: TitilliumWebBold;\n}\n.PageTitle_container_2XV5D.PageTitle_limited-width_1qlT6 {\n  max-width: 1200px;\n  margin: 8px auto;\n}\n@media (min-width: 1900px) {\n  .PageTitle_container_2XV5D.PageTitle_limited-width_1qlT6 {\n    max-width: 1550px;\n  }\n}\n.PageTitle_container_2XV5D .PageTitle_full-width_1VLax {\n  width: calc(100% - 16px);\n}\n.PageTitle_container-padded_2_LTZ .PageTitle_title_1x4ru {\n  padding-top: 12px;\n}\n.PageTitle_label-container_3zLMZ {\n  display: inline;\n  margin-left: 24px;\n  padding-top: 4px;\n}\n.PageTitle_label-container_3zLMZ .PageTitle_separator_-6QIa {\n  display: inline-block;\n  border-left: solid 2px #cfdee7;\n  width: 4px;\n  height: 32px;\n  margin-top: 8px;\n}\n.PageTitle_label_oB_t3 {\n  display: inline-block;\n  padding-left: 30px;\n  padding-right: 23px;\n  line-height: 1;\n}\n.PageTitle_label_oB_t3 label {\n  display: block;\n  font-family: PragatiNarrow;\n  font-size: 16px;\n  letter-spacing: normal;\n  color: #8695a7;\n}\n.PageTitle_label_oB_t3 p {\n  font-family: PragatiNarrowBold;\n  font-size: 16px;\n  letter-spacing: normal;\n  color: #4f5b72;\n  padding: 0px;\n  margin: 4px 0 0 0;\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "PageTitle_no-text-selection_TJLAt",
	"noTextSelection": "PageTitle_no-text-selection_TJLAt",
	"container": "PageTitle_container_2XV5D",
	"title": "PageTitle_title_1x4ru",
	"limited-width": "PageTitle_limited-width_1qlT6",
	"limitedWidth": "PageTitle_limited-width_1qlT6",
	"full-width": "PageTitle_full-width_1VLax",
	"fullWidth": "PageTitle_full-width_1VLax",
	"container-padded": "PageTitle_container-padded_2_LTZ",
	"containerPadded": "PageTitle_container-padded_2_LTZ",
	"label-container": "PageTitle_label-container_3zLMZ",
	"labelContainer": "PageTitle_label-container_3zLMZ",
	"separator": "PageTitle_separator_-6QIa",
	"label": "PageTitle_label_oB_t3"
};