import IObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/IObservation";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import ObservationId from "@Primitives/ObservationId.g";
import ObservationDefinitionId from "@Primitives/ObservationDefinitionId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PatientId from "@Primitives/PatientId.g";
import Category from "@Toolkit/CommonWeb/Model/Category";

export default class CategoryObservation implements IObservation {

    @State.observable.ref public isDirty: boolean = false;
    @State.observable.ref public categories: Category[] = [];

    @State.computed public get singleCategory() {
        return arrayIsNullOrEmpty(this.categories) ? null : this.categories[0];
    }

    constructor(
        public readonly observationId: ObservationId,
        public readonly observationDefinitionId: ObservationDefinitionId,
        public readonly rowVersion: RowVersion,
        public readonly patientId: PatientId,
        public readonly careActivityId: CareActivityId,
        selectedCategories: Category[]
    ) {
        if (!arrayIsNullOrEmpty(selectedCategories)) {
            this.categories = selectedCategories;
        }
    }

    @State.action.bound
    public setSingleCategory(newValue: Category) {
        this.categories = [newValue];
        this.isDirty = true;
    }
    
    @State.action.bound
    public setMultiCategories(newValue: Category[]) {
        this.categories = newValue;
        this.isDirty = true;
    }
}