import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import DashboardsApiAdapter from "@HisPlatform/BoundedContexts/Dashboards/ApplicationLogic/ApiAdapter/Dashboards/DashboardsApiAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDashboardWidget from "@HisPlatform/BoundedContexts/Dashboards/ApplicationLogic/Model/Dashboards/IDashboardWidget";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import { emptyArray, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import IWidgetRegistry from "@PluginInterface/Dashboard/IWidgetRegistry";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import SizeMeasurer, { ISize } from "@Toolkit/ReactClient/Components/SizeMeasurer/SizeMeasurer";
import IWidgetContainer from "@CommonControls/DashboardWidgetLayout/IWidgetContainer";
import * as Ui from "@CommonControls";
import UserProfileStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Profiles/UserProfileStore";
import _ from "@HisPlatform/Common/Lodash";
import IDashboardState from "./IDashboardState";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import Styles from "./Dashboard.less";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PatientWidget from "@HisPlatform/Components/Widgets/PatientWidget/PatientWidget";
import CareActivityWidget from "@HisPlatform/Components/Widgets/CareActivityWidget/CareActivityWidget";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import { DashboardMode } from "@CommonControls/Dashboard/DashboardMode";
import { DashboardContextStore } from "@CommonControls/Dashboard/DashboardContext";

interface IHisDashboardCoreDependencies {
    dashboardsApiAdapter: DashboardsApiAdapter;
    applicationContext: ApplicationContext;
    widgetRegistry: IWidgetRegistry;
    userProfileStore: UserProfileStore;
    userContext: UserContext;
}

export interface IHisDashboardCoreProps {
    _dependencies?: IHisDashboardCoreDependencies;
    _pointOfCareId?: PointOfCareId;
    _hasPatientContext?: boolean;
    _hasCareActivityContext?: boolean;

    currentUseCaseIdentifier: string;
}

@State.observer
class HisDashboardCore extends React.Component<IHisDashboardCoreProps> {

    private get dashboardsApiAdapter() { return this.props._dependencies.dashboardsApiAdapter; }
    private get widgetRegistry() { return this.props._dependencies.widgetRegistry; }
    private get applicationContext() { return this.props._dependencies.applicationContext; }
    private get userProfileStore() { return this.props._dependencies.userProfileStore; }
    private get userContext() { return this.props._dependencies.userContext; }

    @State.observable.ref private isLoading = true;
    @State.observable.ref private widgets: IDashboardWidget[] = new Array<IDashboardWidget>();
    @State.observable.ref private mode: DashboardMode = null;
    @State.observable.ref private widgetStateMap: Map<string, object> = null;
    public readonly isOpenMap = State.createObservableShallowMap<string, boolean>();

    public componentDidMount() {

        this.setupMode();

        dispatchAsyncErrors(this.loadAsync(
            this.props._pointOfCareId
        ), this);
    }

    private setupMode() {
        let localMode = localStorage.getItem("dashboard_mode");
        if (!localMode) {
            localStorage.setItem("dashboard_mode", "normal");
            localMode = "normal";
        }
        this.setMode(localMode as DashboardMode);
    }

    public componentDidUpdate(prevProps: IHisDashboardCoreProps) {

        if (prevProps.currentUseCaseIdentifier !== this.props.currentUseCaseIdentifier) {
            dispatchAsyncErrors(this.loadAsync(
                this.props._pointOfCareId
            ), this);
            return;
        }
    }

    @State.loadingState()
    private async loadAsync(pointOfCareId: PointOfCareId) {
        if (this.props.currentUseCaseIdentifier) {
            const useCaseId = new UseCaseIdentifier(this.props.currentUseCaseIdentifier);
            if (ValueWrapper.equals(useCaseId, UseCaseIdentifier.None)) {
                this.setDashboardState(emptyArray, null);
                return;
            }
            const currentPointOfCareIdOrWorklistDefinitionId = pointOfCareId?.value ?? "none";
            const savedDashboardConfig = this.applicationContext.getDashboardConfig(currentPointOfCareIdOrWorklistDefinitionId, this.userContext.id, this.props.currentUseCaseIdentifier);
            const result = savedDashboardConfig ? savedDashboardConfig : await this.dashboardsApiAdapter.getDashboardAsync(useCaseId, pointOfCareId);
            if (result && result.value) {
                this.applicationContext.setDashboardConfig(result, currentPointOfCareIdOrWorklistDefinitionId, this.userContext.id, this.props.currentUseCaseIdentifier);
                const dashboardState = await this.userProfileStore.getSavedWidgetModeAsync();
                this.setDashboardState(result.value ? result.value.widgets : emptyArray, dashboardState);
            }
        }
    }

    @State.action.bound
    private setIsOpen(widgetName: string, isOpen: boolean) {
        this.isOpenMap.set(widgetName, isOpen);
        this.saveStateDebounced();
    }

    @State.action
    private setDashboardState(widgets: IDashboardWidget[], dashboardState: IDashboardState) {

        this.widgets = widgets;

        this.widgetStateMap = new Map(this.widgets?.map((widget) => {
            const store = this.widgetRegistry.createStore(widget.widgetName);
            return [widget.widgetName.value, store];
        }));

        if (dashboardState) {
            this.isOpenMap.replace(dashboardState.isOpenMap);
        } else {
            this.isOpenMap.replace(this.getInitialIsOpenMap());
        }
    }

    @State.action
    private setMode(mode: DashboardMode) {
        this.mode = mode;
        localStorage.setItem("dashboard_mode", this.mode);
    }

    @State.action.bound
    private toggleMode() {
        this.setMode(this.mode === "small" ? "normal" : "small");
    }

    public render() {
        return (
            <Ui.Dashboard
                orientation="vertical"
                mode={this.mode}
                onToggleMode={this.toggleMode}
                isOpenMap={this.isOpenMap}
                onOpenStateChanged={this.setIsOpen}
            >
                <PatientWidget key="patientWidget" />
                <CareActivityWidget key="careActivityWidget" />
                {(this.props._hasPatientContext || this.props._hasCareActivityContext) && <div className={Styles.separator}></div>}
                {!this.isLoading && this.widgets?.map(this.renderWidget)}
            </Ui.Dashboard>
        );
    }

    private getInitialIsOpenMap() {
        const ret: Map<string, boolean> = new Map();
        this.widgets?.map(w => ret.set(w.widgetName.value, w.configuration.isOpen ? w.configuration.isOpen : true));
        return ret;
    }

    private saveStateDebounced = _.debounce(() => {
        dispatchAsyncErrors(this.saveStateAsync(), this);
    }, 250);

    private async saveStateAsync() {
        const dashboardState: IDashboardState = {
            isOpenMap: [...this.isOpenMap]
        };
        await this.userProfileStore.saveWidgetModeAsync(dashboardState);
    }

    @State.bound
    private renderWidget(widget: IDashboardWidget) {

        const Widget = this.widgetRegistry.get(widget.widgetName);
        const isOpen = this.isOpenMap?.get(widget.widgetName.value) ?? true;
        const store = this.widgetStateMap?.get(widget.widgetName.value);
        if (Widget) {
            return (
                <div key={widget.widgetName.value}>
                    <Widget
                        key={widget.widgetName.value}
                        divKey={widget.widgetName.value}
                        configuration={widget.configuration}
                        name={widget.widgetName.value}
                        store={store}
                    />
                </div>
            );
        }

        return null;
    }
}

export default connect(
    HisDashboardCore,
    new DependencyAdapter<IHisDashboardCoreProps, IHisDashboardCoreDependencies>(c => ({
        dashboardsApiAdapter: c.resolve("DashboardsApiAdapter"),
        applicationContext: c.resolve("ApplicationContext"),
        widgetRegistry: c.resolve("IWidgetRegistry"),
        userProfileStore: c.resolve("UserProfileStore"),
        userContext: c.resolve("UserContext"),
    })),
    new CareActivityContextAdapter<IHisDashboardCoreProps>(c => ({
        _pointOfCareId: c.careActivity?.pointOfCareId,
        _hasCareActivityContext: true
    })),
    new PatientContextAdapter<IHisDashboardCoreProps>(c => ({
        _hasPatientContext: true
    }))
);