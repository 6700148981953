export function wrapProxyValue<TValue, TProxyValue, TTarget>(targetType: new (init: { value?: TProxyValue }) => TTarget, clientValue: { value: TValue, toProxy?: any }): TTarget {
    if (!clientValue) {
        return null;
    }

    return new targetType({ value: clientValue.value as any });
}

export function wrapValue<TValue, TProxyValue, TTarget>(targetType: new (value: TValue) => TTarget, proxyValue: { value?: TProxyValue }): TTarget {
    if (!proxyValue) {
        return null;
    }
    
    return new targetType("value" in proxyValue ? proxyValue.value : (proxyValue as any).Value);
}

export function wrappedValuesAreEquals<T>(left: { value: T }, right: { value: T }): boolean {
    if ((left === undefined || left === null) && (right === undefined || right === null)) {
        return true;
    }

    return left?.value === right?.value;
}