import DependencyContainer from "@DiContainer";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import { IActionPermissionProvider } from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/IActionPermissionProvider";
import PermittedActionsApiAdapter from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/Authorization/PermittedActionsApiAdapter";
import ContextHeaderApiAdapter from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ContextHeaderApiAdapter";
import PatientApiAdapter2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/PatientApiAdapter2";
import AdmitPatientAndCareActivityBaseDataValidationProblemMapper from "@HisPlatform/Packages/Care/Screens/AdmitPatientAndCareActivityBaseDataScreen/AdmitPatientAndCareActivityBaseDataValidationProblemMapper";
import AdmitPatientAndCareActivityBaseDataScreenApiAdapter from "@HisPlatform/Packages/Care/Screens/AdmitPatientAndCareActivityBaseDataScreen/AdmitPatientAndCareActivityBaseDataScreenApiAdapter";
import EpisodeOfCareScreenApiAdapter from "@HisPlatform/Packages/Care/Screens/EpisodeOfCareScreen/EpisodeOfCareScreenApiAdapter";
import DischargePatientScreenApiAdapter from "@HisPlatform/Packages/Care/Screens/DischargePatientScreen/DischargePatientScreenApiAdapter";
import CareActivityTextBlockListPanelScreenApiAdapter from "@HisPlatform/Packages/Care/Screens/CareActivityTextBlockListPanelScreen/CareActivityTextBlockListPanelScreenApiAdapter";
import CareActivityTextBlockScreenApiAdapter from "@HisPlatform/Packages/Care/Screens/CareActivityTextBlockScreen/CareActivityTextBlockScreenApiAdapter";
import CareWorklistApiAdapter from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/CareWorklistApiAdapter";
import EpisodeOfCareSelectBoxApiAdapter from "@HisPlatformControls/EpisodeOfCareSelectBox/EpisodeOfCareSelectBoxApiAdapter";
import ServiceRequestListScreenApiAdapter from "@HisPlatform/Packages/ServiceRequest/ServiceRequestListScreen/ServiceRequestListScreenApiAdapter";
import PerformedServicesScreenApiAdapter from "@HisPlatform/Packages/Care/Screens/PerformedServicesScreen/PerformedServicesScreenApiAdapter";
import CombinePatientsScreenApiAdapter from "@HisPlatform/Packages/Patients/Screens/CombinePatientsScreen/CombinePatientsScreenApiAdapter";
import PatientEpisodeOfCareListScreenApiAdapter from "@HisPlatform/Packages/Care/Screens/PatientEpisodeOfCareListScreen/PatientEpisodeOfCareListScreenApiAdapter";
import GlobalEpisodeOfCareListScreenApiAdapter from "@HisPlatform/Packages/Care/Screens/GlobalEpisodeOfCareListScreen/GlobalEpisodeOfCareListScreenApiAdapter";
import ImmunizationListScreenApiAdapter from "@HisPlatform/Packages/Patients/Screens/ImmunizationListScreen/ImmunizationListScreenApiAdapter";
import DocumentListScreenApiAdapter from "@HisPlatform/Packages/Patients/Screens/DocumentListScreen/DocumentListScreenApiAdapter";
import DocumentScreenApiAdapter from "@HisPlatform/Packages/Patients/Screens/DocumentScreen/DocumentScreenApiAdapter";
import TelecommunicationApiAdapter from "@HisPlatform/Packages/Care/ApiAdapter/TelecommunicationApiAdapter";
import PatientAdministrativeDataScreenApiAdapter from "@HisPlatform/Packages/Patients/Screens/PatientAdministrativeDataScreen/PatientAdministrativeDataScreenApiAdapter";
import ServiceRequestScreenApiAdapter from "@HisPlatform/Packages/ServiceRequest/ServiceRequestScreen/ServiceRequestScreenApiAdapter";
import PatientMovedCareActivitiesScreenApiAdapter from "@HisPlatform/Packages/Care/Screens/PatientMovedCareActivitiesScreen/PatientMovedCareActivitiesScreenApiAdapter";
import PatientAdministrativeDataValidationProblemMapper from "@HisPlatform/Packages/Patients/Screens/PatientAdministrativeDataScreen/PatientAdministrativeDataValidationProblemMapper";
import AdmitPatientAndCareActivitiyBaseDataScreenExtension from "@HisPlatform/Packages/Care/FormExtensions/AdmitPatientAndCareActivitiyBaseDataScreenExtension";
import CustomerListScreenApiAdapter from "@HisPlatform/Packages/Settings/Screens/Customer/CustomerListScreenApiAdapter";
import CustomerScreenApiAdapter from "@HisPlatform/Packages/Settings/Screens/Customer/CustomerScreenApiAdapter";
import InvoiceListScreenApiAdapter from "@HisPlatform/Packages/Patients/Screens/InvoiceListScreen/InvoiceListScreenApiAdapter";
import InvoiceScreenApiAdapter from "@HisPlatform/Packages/Patients/Screens/InvoiceScreen/InvoiceScreenApiAdapter";
import InvoicPdfScreenApiAdapter from "@HisPlatform/Packages/Patients/Screens/InvoicePdfScreen/InvoicePdfScreenApiAdapter";

DependencyContainer
    .bind("IAuthorizationClient")
    .to(Proxy.AuthorizationClient)
    .inSingletonScope();

DependencyContainer
    .bind("IActionPermissionProvider")
    .to(PermittedActionsApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("AuthorizationService")
    .to(AuthorizationService)
    .inSingletonScope();

DependencyContainer
    .bind("ContextHeaderApiAdapter")
    .to(ContextHeaderApiAdapter)
    .inSingletonScope();

// BEGIN: Moved from CarePackageInitializer to mitigate a DI error

DependencyContainer
    .bind("ICareClient")
    .to(Proxy.CareClient)
    .inSingletonScope();

DependencyContainer
    .bind("AdmitPatientAndCareActivityBaseDataScreenApiAdapter")
    .to(AdmitPatientAndCareActivityBaseDataScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PatientAdministrativeDataScreenApiAdapter")
    .to(PatientAdministrativeDataScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("DischargePatientScreenApiAdapter")
    .to(DischargePatientScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PatientEpisodeOfCareListScreenApiAdapter")
    .to(PatientEpisodeOfCareListScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("DocumentScreenApiAdapter")
    .to(DocumentScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("GlobalEpisodeOfCareListScreenApiAdapter")
    .to(GlobalEpisodeOfCareListScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("DocumentListScreenApiAdapter")
    .to(DocumentListScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EpisodeOfCareScreenApiAdapter")
    .to(EpisodeOfCareScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("EpisodeOfCareSelectBoxApiAdapter")
    .to(EpisodeOfCareSelectBoxApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("AdmitPatientAndCareActivityBaseDataValidationProblemMapper")
    .to(AdmitPatientAndCareActivityBaseDataValidationProblemMapper);

DependencyContainer
    .bind("PatientAdministrativeDataValidationProblemMapper")
    .to(PatientAdministrativeDataValidationProblemMapper);

DependencyContainer
    .bind("CareWorklistApiAdapter")
    .to(CareWorklistApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("CareActivityTextBlockListPanelScreenApiAdapter")
    .to(CareActivityTextBlockListPanelScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("CareActivityTextBlockScreenApiAdapter")
    .to(CareActivityTextBlockScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PerformedServicesScreenApiAdapter")
    .to(PerformedServicesScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("TelecommunicationApiAdapter")
    .to(TelecommunicationApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("PatientMovedCareActivitiesScreenApiAdapter")
    .to(PatientMovedCareActivitiesScreenApiAdapter)
    .inSingletonScope();

DependencyContainer.bind("AdmitPatientAndCareActivitiyBaseDataScreenExtension").to(AdmitPatientAndCareActivitiyBaseDataScreenExtension);

// END: Moved from CarePackageInitializer to mitigate a DI error

// BEGIN: Moved from PatientsPackageInitializer to mitigate a DI error

DependencyContainer
    .bind("IPatientsClient")
    .to(Proxy.PatientsClient)
    .inSingletonScope();

DependencyContainer
    .bind("PatientApiAdapter2")
    .to(PatientApiAdapter2)
    .inSingletonScope();

DependencyContainer
    .bind("CombinePatientsScreenApiAdapter")
    .to(CombinePatientsScreenApiAdapter)
    .inSingletonScope();

// END: Moved from PatientsPackageInitializer to mitigate a DI error

// BEGIN: Moved from ServiceRegisterPackageInitializer to mitigate a DI error

DependencyContainer
    .bind("ServiceRequestListScreenApiAdapter")
    .to(ServiceRequestListScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("IServiceRequestClient")
    .to(Proxy.ServiceRequestClient)
    .inSingletonScope();

DependencyContainer
    .bind("ServiceRequestScreenApiAdapter")
    .to(ServiceRequestScreenApiAdapter)
    .inSingletonScope();

// END: Moved from ServiceRegisterPackageInitializer to mitigate a DI error

DependencyContainer
    .bind("ImmunizationListScreenApiAdapter")
    .to(ImmunizationListScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("ISettingsClient")
    .to(Proxy.SettingsClient)
    .inSingletonScope();

DependencyContainer
    .bind("CustomerListScreenApiAdapter")
    .to(CustomerListScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("CustomerScreenApiAdapter")
    .to(CustomerScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("InvoiceListScreenApiAdapter")
    .to(InvoiceListScreenApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("InvoiceScreenApiAdapter")
    .to(InvoiceScreenApiAdapter)
    .inSingletonScope();

    DependencyContainer
    .bind("InvoicPdfScreenApiAdapter")
    .to(InvoicPdfScreenApiAdapter)
    .inSingletonScope();    

