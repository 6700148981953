import LockingEntityStoreBase from "@Toolkit/CommonWeb/Model/LockingEntityStoreBase";
import DocumentId from "@Primitives/DocumentId.g";
import ScopeIdentifier from "./ScopeIdentifier";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import UserId from "@Primitives/UserId.g";
import moment from "moment";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import DocumentScope from "@HisPlatform/BoundedContexts/DocumentManagement/Api/ReferenceData/Enum/DocumentScope.g";
import DocumentKind from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentKind.g";
import DocumentState from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentState.g";
import DocumentAction from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentAction.g";
import { MetaPropertyBase } from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";

export default class DocumentInfo extends LockingEntityStoreBase<DocumentId> {

    constructor(
        isNew: boolean,
        id: DocumentId,
        public readonly scopeIdentifiers: ScopeIdentifier[],
        public readonly documentTypeId: DocumentTypeId,
        public readonly kind: DocumentKind,
        public readonly createdById: UserId,
        public readonly createdAt: moment.Moment,
        public readonly lastModifiedById: UserId,
        public readonly lastModifiedAt: moment.Moment,
        state: DocumentState,
        name: string,
        public readonly possibleActions: DocumentAction[],
        public readonly isPrimary: boolean,
        public readonly metaProperties?: MetaPropertyBase[],
        public readonly extensionData?: any
    ) {
        super(isNew);
        this.id = id;
        this.state = state;
        this.name = name;
    }

    @State.observable.ref public state: DocumentState = null;
    @State.observable.ref public name: string = "";

    @State.computed
    public get pointOfCareId(): PointOfCareId {
        const possiblePointOfCareId = this.scopeIdentifiers.filter(x => x.documentScope === DocumentScope.Organization);
        if (possiblePointOfCareId.length == 0)
            return null;

        return new PointOfCareId(possiblePointOfCareId[0].identifier.toString(10));
    }
}