import React from "react";
import { provideImmunizationListScreenStore, useImmunizationListScreenStore } from "./ImmunizationListScreenStoreProvider";
import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import FormExtensionRegistryAdapter from "@PluginInterface/FormExtension/FormExtensionRegistryAdapter";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import NDataScreen from "@HisPlatform/BoundedContexts/Productivity/Components/NDataScreen/NDataScreen";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import { useScreenNavigationContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import ShowCreateNewImmunizationScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowCreateNewImmunizationScreenAction.g";
import ShowImmunizationListScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowImmunizationListScreenAction.g";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";

export interface IImmunizationListScreenProps extends IScreenPropsBase<ShowImmunizationListScreenAction> {
    _formExtension?: IFormExtension<any>;
}

function ImmunizationListScreen(props: IImmunizationListScreenProps) {

    const screenNavigationContext = useScreenNavigationContext();
    const store = useImmunizationListScreenStore();

    return (
        <>
            <NDataScreen
                iconName="vaccine"
                defaultPageTitle={StaticCareResources.PatientMenu.Immunization}
                onGetDynamicListAsync={store.getDynamicListAsync}
                refreshListEvent={store.refreshListEvent}
                hasNewRow={screenNavigationContext.currentSecondaryScreen?.action?.id === ShowCreateNewImmunizationScreenAction.id}
                beforeGlobalActionsToolbar={!screenNavigationContext.isSecondaryScreenOpen && (
                    <HisPlatformExtensionPoint
                        type="medicalConditionSynchronizationExtension"
                        extensionProps={store.synchronizationExtensionProps}>
                        <></>
                    </HisPlatformExtensionPoint>
                )}
                defaultExtraFilterVisibility={true}
                extraFilter={(
                    <HisPlatformExtensionPoint
                        type="medicalConditionSynchronizationErrorHandlerExtension"
                        extensionProps={store.synchronizationExtensionProps}
                    />
                )}
                subtitle={(
                    <HisPlatformExtensionPoint
                        type="medicalConditionLastSynchronizationSubtitleExtension"
                        extensionProps={store.lastSynchronizationSubtitleExtensionProps}
                    />
                )}
            />
        </>
    );
}

export default connect<IImmunizationListScreenProps>(
    provideImmunizationListScreenStore(ImmunizationListScreen),
    new FormExtensionRegistryAdapter("ManageMedicalCondition")
);