import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import _ from "@HisPlatform/Common/Lodash";
import HisDashboardCore from "./HisDashboardCore";

interface IHisDashboardDependencies {
    applicationContext: ApplicationContext;
}

interface IHisDashboardProps {
    _dependencies?: IHisDashboardDependencies;
}

@State.observer
class HisDashboard extends React.Component<IHisDashboardProps> {

    private get applicationContext() { return this.props._dependencies.applicationContext; }

    private currentUseCaseIdentifierObserverDisposer: () => void = null;
    @State.observable.ref private currentUseCaseIdentifier: UseCaseIdentifier = null;

    public componentDidMount() {
        this.currentUseCaseIdentifierObserverDisposer = State.reaction(
            () => [this.applicationContext.currentUseCaseIdentifier],
            () => {
                State.runInAction(() => {
                    this.currentUseCaseIdentifier = this.applicationContext.currentUseCaseIdentifier;
                });
            }, {
            delay: 350,
            fireImmediately: this.applicationContext.currentUseCaseIdentifier ? true : false
        });
    }

    public componentWillUnmount() {
        if (this.currentUseCaseIdentifierObserverDisposer) {
            this.currentUseCaseIdentifierObserverDisposer();
        }
    }

    public render() {
        return (
            <HisDashboardCore {...this.props as any} currentUseCaseIdentifier={this.currentUseCaseIdentifier?.value} />
        );
    }
}

export default connect(
    HisDashboard,
    new DependencyAdapter<IHisDashboardProps, IHisDashboardDependencies>(c => ({
        applicationContext: c.resolve("ApplicationContext"),
    }))
);