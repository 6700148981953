import MedicationReferenceBase from "./MedicationReferenceBase";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import Dosage from "./Dosage";
import MedicationFormulaListItem from "./MedicationFormulaListItem";
import PackagingUnitId from "@Primitives/PackagingUnitId.g";

export default class MedicationFormulaList extends MedicationReferenceBase {
    @State.observable.ref public amount: number = 0;
    @State.observable public name: string = "";
    @State.observable public dosage: Dosage;
    @State.observable public instruction: string = "";
    @State.observable.ref public packagingUnitId: PackagingUnitId = null;
    @State.observable.ref public medicationFormulaListItems: IObservableArray<MedicationFormulaListItem> = State.observable([]);

    @State.action.bound
    public setDosage(dosage: Dosage) {
        this.dosage = dosage;
    }

    @State.action.bound
    public setAmount(amount: number) {
        this.amount = amount;
    }

    @State.action.bound
    public setName(name: string) {
        this.name = name;
    }
    @State.action.bound
    public setInstruction(instruction: string) {
        this.instruction = instruction;
    }
    @State.action.bound
    public setPackagingUnitId(packagingUnitId: PackagingUnitId) {
        this.packagingUnitId = packagingUnitId;
    }
    @State.action.bound
    public removeMedicationFormulaListItem(medicationFormulaListItem: MedicationFormulaListItem) {
        this.medicationFormulaListItems = State.observable(this.medicationFormulaListItems.filter(m => m !== medicationFormulaListItem));
    }
}