import React from "react";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import MasterDetailLayout from "@CommonControls/Layout/MasterDetailLayout";
import MasterDetailMaster from "@CommonControls/Layout/MasterDetailMaster";
import MasterDetailDetail from "@CommonControls/Layout/MasterDetailDetail";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PrescriptionListView from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/PrescriptionListPanel/PrescriptionListView";
import ExternalPrescriptionDetailViewSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/ExternalPrescriptionDetailView/ExternalPrescriptionDetailViewSelector";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import IPrescriptionListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IPrescriptionListItem";
import Prescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Prescription";
import { IPagingState, IOrderingState, DataGridLoadType } from "@CommonControls/DataGrid/IDataGridProps";
import IExternalPrescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IExternalPrescription";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import EhiUserContext from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiUserContext";
import PrescriptionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionApiAdapter";
import CareActivityId from "@Primitives/CareActivityId.g";
import PrescriptionFilterStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/PrescriptionListPanel/PrescriptionFilterStore";
import PatientId from "@Primitives/PatientId.g";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import _ from "@HisPlatform/Common/Lodash";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import PrescriptionSearchBase from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionSearchBase";
import PrescriptionDataSource from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionDataSource";
import EhiPrescriptionSearchMode from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/EhiPrescriptionSearchMode";
import EhiErrorSummary from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiErrorSummary";
import StaticHunEHealthInfrastructureWebAppResources from "@HunEHealthInfrastructurePlugin/StaticResources/StaticHunEHealthInfrastructureWebAppResources";
import EhiPrescriptionService from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/EhiPrescriptionService";
import EhiUserType from "@HunEHealthInfrastructurePlugin/Model/EhiUserType";
import EhiServiceCallStatus from "@HunEHealthInfrastructurePlugin/Model/EhiServiceCallStatus";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IEhiError from "@HunEHealthInfrastructurePlugin/Common/Model/IEhiError";
import EhiErrors from "@HunEHealthInfrastructurePlugin/Common/Model/EhiErrors";
import { isNullOrUndefined, isNullOrWhiteSpace } from "@Toolkit/CommonWeb/NullCheckHelpers";
import moment from "moment";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import PrescriptionItemType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Prescription/Enum/PrescriptionItemType.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";

interface IEhiDigitalPrescriptionMasterDetailPanelDependencies {
    ehiUserContext: EhiUserContext;
    prescriptionApiAdapter: PrescriptionApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
    hunCareReferenceDataStore: MedicationRequestReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    notificationService: INotificationService;
}

interface IEhiDigitalPrescriptionMasterDetailPanelProps {
    _dependencies?: IEhiDigitalPrescriptionMasterDetailPanelDependencies;
    _careActivityId?: CareActivityId;
    patientId: PatientId;
    ehiPrescriptionService: EhiPrescriptionService;
    onBack?: () => void;
    ehiCareIdentifier: string;
    isEmergency: boolean;
    emergencyReason: string;
    beginningOfCare: moment.Moment;
    dischargedAt: moment.Moment;
}

@State.observer
class EhiDigitalPrescriptionMasterDetailPanel extends React.Component<IEhiDigitalPrescriptionMasterDetailPanelProps> {

    private get ehiUserContext() { return this.props._dependencies.ehiUserContext; }
    private get prescriptionApiAdapter() { return this.props._dependencies.prescriptionApiAdapter; }
    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }
    private get organizationReferenceDataStore() { return this.props._dependencies.organizationReferenceDataStore; }
    private get hunCareReferenceDataStore() { return this.props._dependencies.hunCareReferenceDataStore; }
    private get notificationService() { return this.props._dependencies.notificationService; }

    @State.observable private isLoading: boolean = false;
    @State.observable.ref private prescriptionList: PagedItemStore<IPrescriptionListItem> = new PagedItemStore([], 0);
    @State.observable.ref private selectedPrescription: Prescription = null;
    @State.observable public paging: IPagingState = { currentPage: 0, pageSize: 20 };
    @State.observable public ordering: IOrderingState = { columnId: "PrescribedOn", direction: "desc" };
    @State.observable.ref private selectedExternalPrescription: IExternalPrescription = null;
    @State.observable.ref private ehiErrorExtensionData: IEhiError[] = [];
    @State.observable public showFilter: boolean = false;

    private filterStore = new PrescriptionFilterStore(this.debouncedLoadSync, this.setShowFilter, this.clearPrescriptionList);
    private extensionData: any = {};

    private ehiServiceCallStatus: EhiServiceCallStatus = null;
    private isInitialLoad: boolean = true;

    public componentDidMount() {
        this.setFilterStoreEhiDefaults();
        this.loadSync();
    }

    @State.action.bound
    private setFilterStoreDateFieldsBasedOnProps() {
        if (this.props.beginningOfCare) {
            this.filterStore.setEhiPrescriptionSearchStartDate(LocalDate.createFromMoment(this.props.beginningOfCare));
            const end = this.props.dischargedAt ? this.props.dischargedAt : DateTimeService.now();
            const diff = Math.ceil(end.diff(this.props.beginningOfCare, "months", true));
            this.filterStore.setEhiPrescriptionSearchMonths(diff);
        }
    }

    public componentDidUpdate(prevProps: IEhiDigitalPrescriptionMasterDetailPanelProps) {
        if (prevProps.beginningOfCare !== this.props.beginningOfCare || prevProps.dischargedAt !== prevProps.dischargedAt) {
            this.setFilterStoreDateFieldsBasedOnProps();
        }

        if (prevProps.ehiCareIdentifier !== this.props.ehiCareIdentifier) {
            this.setSelectedExternalPrescription(null);
            this.loadSync();
        }
    }

    @State.action.bound
    private setFilterStoreEhiDefaults() {
        this.filterStore.setPrescriptionDataSource(PrescriptionDataSource.Ehi, true);
        this.filterStore.setPrescriptionSearchBase(PrescriptionSearchBase.Patient, true);
        this.filterStore.setEhiPrescriptionSearchMode(EhiPrescriptionSearchMode.AllPrescriptions);
        
        if (this.props.beginningOfCare) {
            this.setFilterStoreDateFieldsBasedOnProps();
        }

        this.filterStore.setIncludeDeleted(true, true);
    }

    @State.bound
    private clearPrescriptionList() {
        this.setPrescriptionList(PagedItemStore.createEmpty());
    }

    @State.action.bound
    public setShowFilter(showFilter: boolean) {
        this.showFilter = showFilter;
    }

    @State.bound
    private selectRow(row: IPrescriptionListItem) {
        this.loadExternalPrescriptionSync(row?.extensionData?.EhiIdentifier, row?.itemType);
    }

    @State.bound
    private loadExternalPrescriptionSync(identifier: string, itemType: PrescriptionItemType) {
        dispatchAsyncErrors(this.loadExternalPrescriptionAsync(identifier, itemType), this);
    }

    @State.boundLoadingState("isLoading")
    private async loadExternalPrescriptionAsync(identifier: string, itemType: PrescriptionItemType) {

        if (isNullOrWhiteSpace(identifier) || isNullOrUndefined(itemType)) {
            this.setSelectedExternalPrescription(null);
            return;
        }

        const ehiServiceCallStatus = await this.props.ehiPrescriptionService.setEhiAccessDataToExtensionDataAsync(this.props.patientId, this.extensionData, EhiUserType.Doctor);
        this.handleEhiServiceCall(ehiServiceCallStatus);

        if (ehiServiceCallStatus === EhiServiceCallStatus.Success) {
            const externalPrescriptionStore = await this.prescriptionApiAdapter.getExternalPrescriptionDataAsync(
                identifier,
                this.props._careActivityId,
                this.props.isEmergency,
                this.props.emergencyReason,
                itemType,
                this.extensionData);

            this.setSelectedExternalPrescription(externalPrescriptionStore.value.value);
        }
    }

    @State.action.bound
    private async onGridChangeAsync(type: DataGridLoadType, paging: IPagingState, ordering: IOrderingState | IOrderingState[]) {
        if (type === "changed") {
            this.paging = paging;
            this.ordering = _.isArray(ordering) ? ordering[0] : ordering as IOrderingState;
            await this.loadAsync();
        }
    }

    @State.boundLoadingState("isLoading")
    private async loadAsync() {
        this.ehiServiceCallStatus = await this.props.ehiPrescriptionService.setEhiAccessDataToExtensionDataAsync(this.props.patientId, this.extensionData, EhiUserType.Doctor, this.ehiUserContext.loggedInUserPractitionerId);
        this.handleEhiServiceCall(this.ehiServiceCallStatus);
        if (this.ehiServiceCallStatus === EhiServiceCallStatus.Success) {
            const results = await this.prescriptionApiAdapter.getPrescriptionsAsync(
                null,
                this.props.patientId,
                this.filterStore.dateRangeFilter,
                this.paging,
                this.ordering,
                this.filterStore.statuses,
                this.filterStore.medicationNameFilter,
                this.filterStore.pointOfCareIds,
                this.filterStore.practitionerId,
                this.filterStore.prescriptionSearchBase,
                this.filterStore.healthCareProfessionIds,
                this.filterStore.prescriptionDataSource,
                this.filterStore.ehiPrescriptionSearchMode,
                this.filterStore.ehiPrescriptionSearchStartDate,
                this.filterStore.ehiPrescriptionSearchMonths,
                this.props.isEmergency,
                this.props.emergencyReason,
                this.filterStore.ehiPrescriptionStatuses,
                this.filterStore.ehiPrescriptionIdentifier,
                this.props.ehiCareIdentifier,
                this.extensionData
            );

            await this.loadReferenceDataAsync(results);
            this.setPrescriptionList(results);
            this.setEhiErrorExtensionData(results);
            
            this.isInitialLoad = false;
        }
    }

    @State.bound
    private handleEhiServiceCall(ehiServiceCallStatus: EhiServiceCallStatus) {
        switch (ehiServiceCallStatus) {
            case EhiServiceCallStatus.NoEhiId:
                this.notificationService.error(StaticHunEHealthInfrastructureWebAppResources.NoEhiIdError);
                break;
            case EhiServiceCallStatus.NoEhiToken:
                this.notificationService.error(StaticHunEHealthInfrastructureWebAppResources.NoEhiTokenError);
                break;
            case EhiServiceCallStatus.NoOrganizationUnit:
                this.notificationService.error(StaticHunEHealthInfrastructureWebAppResources.NoOrganizationUnitError);
                break;
            case EhiServiceCallStatus.Success:
            default:
                break;
        }
    }

    @State.bound
    private debouncedLoadSync() {
        if (this.filterStore && this.filterStore.prescriptionDataSource === PrescriptionDataSource.His) {
            this.loadSync();
        }
    }

    @State.bound
    private loadSync() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.action.bound
    private setSelectedExternalPrescription(externalPrescription: IExternalPrescription) {
        this.selectedExternalPrescription = externalPrescription;
    }

    @State.bound
    private async loadReferenceDataAsync(list: PagedItemStore<IPrescriptionListItem>) {
        for (const prescription of list.items) {
            const medicationVersionSelectors = prescription.products
                .map(i => i.medicationVersionSelector)
                .filter(i => !isNullOrUndefined(i));

            await this.careReferenceDataStore.medications.ensureLoadedAsync(medicationVersionSelectors);
            await this.careReferenceDataStore.dosageMode.ensureLoadedAsync();
            await this.careReferenceDataStore.condition.ensureLoadedAsync([{ id: prescription.conditionId, validOn: prescription.prescribedOn || LocalDate.today() }]);
        }

        await this.hunCareReferenceDataStore.medicationSubsidyClaimTypes.ensureAllLoadedAsync();
        await this.organizationReferenceDataStore.healthCareProfession.ensureAllLoadedAsync();
        await this.hunCareReferenceDataStore.packagingUnits.ensureAllLoadedAsync();
        await this.hunCareReferenceDataStore.ehiPrescriptionStatuses.ensureLoadedAsync();
    }

    @State.action.bound
    private setPrescriptionList(prescriptionList: PagedItemStore<IPrescriptionListItem>) {
        this.prescriptionList = prescriptionList;
    }

    @State.action.bound
    private setEhiErrorExtensionData(store: PagedItemStore<IPrescriptionListItem>) {
        this.ehiErrorExtensionData = EhiErrors.createFromExtensionDto(store?.extensionData);
    }

    @State.bound
    private onPrescriptionSelected(prescription: IPrescriptionListItem) {
        this.loadExternalPrescriptionSync(prescription?.extensionData?.EhiIdentifier, prescription?.itemType);
    }

    public renderErrorSummary() {
        return (
            <EhiErrorSummary
                errors={this.ehiErrorExtensionData}
            />
        );
    }

    public render() {
        return (
            <SingleLayout>
                <>
                    {this.renderErrorSummary()}
                </>
                <MasterDetailLayout
                    selectedItem={this.selectedExternalPrescription}
                    onSelectedItemChange={this.onPrescriptionSelected}
                    showDetailCloseButton
                >
                    <MasterDetailMaster title={StaticHunEHealthInfrastructureWebAppResources.EhiDigitalPrescriptionMasterDetailPanel.Title} iconName="eeszt">
                        <PrescriptionListView
                            isLoading={this.isLoading}
                            onRowSelected={this.selectRow}
                            prescriptionList={this.prescriptionList}
                            selectedPrescription={this.selectedPrescription}
                            showExtendedFilter={false}
                            filterStore={this.filterStore}
                            onBack={this.props.onBack}
                            onGridChangeAsync={this.onGridChangeAsync}
                            ordering={this.ordering}
                            paging={this.paging}
                            readonly={true}
                            onLoadAsync={this.loadAsync}
                            externalPrescriptionSelected={!!this.selectedExternalPrescription}
                            hideDetailButton
                            hideEmergencyPanel
                            ehiServiceCallStatus={this.ehiServiceCallStatus}
                            isInitialLoad={this.isInitialLoad}
                            hideUpdateDateWarning
                        />
                    </MasterDetailMaster>
                    <MasterDetailDetail>
                        <ExternalPrescriptionDetailViewSelector
                            externalPrescription={this.selectedExternalPrescription}
                        />
                    </MasterDetailDetail>
                </MasterDetailLayout>
            </SingleLayout>
        );
    }
}

export default connect(EhiDigitalPrescriptionMasterDetailPanel,
    new DependencyAdapter<IEhiDigitalPrescriptionMasterDetailPanelProps, IEhiDigitalPrescriptionMasterDetailPanelDependencies>(c => ({
        ehiUserContext: c.resolve("EhiUserContext"),
        prescriptionApiAdapter: c.resolve("PrescriptionApiAdapter"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        hunCareReferenceDataStore: c.resolve("MedicationRequestReferenceDataStore"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        notificationService: c.resolve("INotificationService")
    }))
);