import { DashboardMode } from "@CommonControls/Dashboard/DashboardMode";
import { emptyObject } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State, { ObservableMap } from "@Toolkit/ReactClient/Common/StateManaging";
import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";
import React, { useContext } from "react";

export class DashboardContextStore {
    @State.observable.ref public mode: DashboardMode = "normal";

    constructor(
        private readonly isOpenMap: ObservableMap<string, boolean> | null,
        private readonly onOpenStateChanged: (name: string, isOpen: boolean) => void
    ) { }

    @State.action.bound
    public setMode(mode: DashboardMode) {
        this.mode = mode;
    }

    @State.action.bound
    public setOpenState(name: string, isOpen: boolean) {
        this.isOpenMap?.set(name, isOpen);
        this.onOpenStateChanged?.(name, isOpen);
    }

    public isOpen(name: string) {
        return this.isOpenMap?.get(name) ?? true;
    }
}

const DashboardReactContext = React.createContext<DashboardContextStore | null>(null);

function useDashboardContext(): DashboardContextStore | null {
    return useContext(DashboardReactContext);
}

export class DashboardContextAdapter<TProps> implements IComponentAdapter<TProps> {

    public get usedContexts(): Array<React.Context<any>> {
        return [DashboardReactContext];
    }

    constructor(
        private readonly mapStateToProps: (dashboardContext: DashboardContextStore, props: TProps) => Partial<TProps>
    ) { }

    public getMappedProps(props: TProps, contextValues: Map<React.Context<any>, any>): Partial<TProps> {
        const store = contextValues.get(DashboardReactContext) as DashboardContextStore;
        if (!store) {
            return emptyObject;
        }
        return this.mapStateToProps(store, props);
    }
}

export {
    DashboardReactContext,
    useDashboardContext
};