import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import IVaccineHintItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IVaccineHintItem";
import VaccineId from "@Primitives/VaccineId.g";
import IVaccine from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IVaccine";
import ComplexSearchVaccinesQueryOrderingFields from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/Vaccines/Enum/ComplexSearchVaccinesQueryOrderingFields.g";

@Di.injectable()
export default class VaccinesApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IVaccinesClient") private readonly apiClient: Proxy.IVaccinesClient
    ) {
        super();
    }

    public searchVaccines(freeText: string, maxResultCount: number) {
        return this.processOperationAsync(
            new SimpleStore<IVaccineHintItem[]>(),
            async target => {
                const response = await this.apiClient.searchVaccinesQueryAsync(
                    CreateRequestId(),
                    freeText,
                    maxResultCount.toString()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.vaccines.map(ai => {
                    return {
                        id: ai.id,
                        code: ai.code,
                        name: ai.name
                    } as IVaccineHintItem;
                });
            }
        );
    }

    public getAllIdsAsync(): Promise<SimpleStore<VaccineId[]>> {
        return this.processOperationAsync(
            new SimpleStore<VaccineId[]>(),
            async target => {
                const response = await this.apiClient.getVaccineIdsQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.ids.map(id => id);
            }
        );
    }

    @State.bound
    public loadVaccinesAsync(ids: VaccineId[]): Promise<SimpleStore<IVaccine[]>> {
        return this.processOperationAsync(
            new SimpleStore<IVaccine[]>(),
            async (target) => {
                const response = await this.apiClient.getVaccinesByIdsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetVaccinesByIdsControllerDto({ ids: ids })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.vaccines.map(this.mapVaccine);
            }
        );
    }

    private mapVaccine(ai: Proxy.VaccineDto): IVaccine {
        return {
            id: ai.id,
            code: ai.code,
            name: ai.name
        } as IVaccine;
    }

    @State.bound
    public complexSearchVaccines(
        freeText: string,
        ordering: IOrderingState,
        paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<IPagedItems<IVaccine>>(),
            async (target) => {
                const response = await this.apiClient.complexSearchVaccinesQueryAsync(
                    CreateRequestId(),
                    new Proxy.ComplexSearchVaccinesControllerDto({
                        freeText: freeText,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfComplexSearchVaccinesQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfComplexSearchVaccinesQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: ComplexSearchVaccinesQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        })
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results && response.results.values.map(this.mapVaccine)
                };

            }
        );
    }
}
