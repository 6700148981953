import React, { useMemo, useCallback } from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import { isNullOrUndefinedOrGivenString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { getUseCaseFromNDataUseCaseStateAsUrlParam, parseUseCaseStateFromUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import FilterConditionConverters from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/FilterConditionConverters";
import IRatFormCareActivityBasedRouteParams from "@HisPlatform/Components/Pages/OutpatientTreatment/IRatFormCareActivityBasedRouteParams";
import CareActivityId from "@Primitives/CareActivityId.g";
import FormBoundNDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/FormBoundNDataPanel";
import UseCases from "@Primitives/UseCases";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";

interface IRatFormCareActivityPageDependencies {
    routingStore: GlobalRoutingStore;
}

interface IRatFormCareActivityPageProps extends IRoutingFrameContentProps<IRatFormCareActivityBasedRouteParams> {
    _dependencies?: IRatFormCareActivityPageDependencies;
}

function RatFormCareActivityPage(props: IRatFormCareActivityPageProps) {

    const [careActivityId, selectedId, useCaseState, isReadOnly] = useMemo(() => {
        const routeParameters = props.routingController.currentRoute.parameters;

        let isReadOnly = false;
        if (props._dependencies.routingStore.currentQueryString) {
            const params = new URLSearchParams(props._dependencies.routingStore.currentQueryString);
            isReadOnly = params.get("isReadOnly") === "false" ? false : true;
        }

        return [
            new CareActivityId(routeParameters.careActivityId),
            isNullOrUndefinedOrGivenString(routeParameters.selectedId, "null") ? null : new FormInstanceId(routeParameters.selectedId),
            parseUseCaseStateFromUrlParam(routeParameters.useCase),
            isReadOnly
        ];
    }, [props.routingController.currentRoute.parameters.careActivityId, props.routingController.currentRoute.parameters.selectedId, props.routingController.currentRoute.parameters.useCase, props.routingController.currentRoute.queryString]);

    const setState = useCallback((id: string, newUseCaseState: INDataUseCaseState) => {
        let useCaseId = id;
        if (newUseCaseState?.useCase?.value === UseCases.editableRatFormDetail) {
            useCaseId = newUseCaseState.useCaseArguments.find(it => it.name === "formInstanceId")?.value?.value ?? "new";
        }
        props.routingController.replace(props.routingController.currentRoute.definition.makeRoute({
            selectedId: useCaseId ?? "null",
            useCase: getUseCaseFromNDataUseCaseStateAsUrlParam(newUseCaseState),
            careActivityId: careActivityId.value
        }), "?isReadOnly=" + isReadOnly ? "true" : "false");
    }, [props.routingController]);

    const dynamicFilters = {};
    const conditionConverter = FilterConditionConverters[WorklistConditionType.String];
    dynamicFilters["FormField_RatForm.CareActivity"] = conditionConverter(careActivityId.value);

    return (
        <SingleLayout>
            <FormBoundNDataPanel
                worklistDefinitionReference="RatFormForCareActivity"
                cacheKey={`CareActivityId_${careActivityId.value}`}
                dynamicFilters={dynamicFilters}
                onChange={setState}
                useCaseState={useCaseState}
                selectedRowId={selectedId?.value}
                hideMasterToolbar={isReadOnly}
            />
        </SingleLayout>
    );
}

export default connect(
    withLoadingBoundary(RatFormCareActivityPage),
    new DependencyAdapter<IRatFormCareActivityPageProps, IRatFormCareActivityPageDependencies>(container => {
        return {
            routingStore: container.resolve("GlobalRoutingStore"),
        };
    })
);