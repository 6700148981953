import React from "react";
import Styles from "./Sidebar.less";
import SidebarTab from "./SidebarTab";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface ISidebarProps {
    selectedItemName?: string;
    automationId?: string;
}

@State.observer
export default class Sidebar extends React.Component<ISidebarProps, undefined> {

    
    public static Tab = SidebarTab;

    constructor(props: ISidebarProps) {
        super(props);
    }

    private getIsSelected(child: any) {
        return child.props.name === this.props.selectedItemName;
    }

    public render() {
        return (
            <div className={Styles.sidebarContainer} data-automation-id={this.props.automationId || undefined}>
                <div className={Styles.sidebar}>
                    {React.Children.map(this.props.children, (child: any, index: number) => {
                        return React.cloneElement(child, {
                            isSelected: this.getIsSelected(child),
                        });
                    })}
                </div>
            </div>
        );
    }
}