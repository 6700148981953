import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import ITemplateInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateInfo";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import DocumentTemplateManagementMasterPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/DocumentTemplateManagementMasterPanel";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import TemplateApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Templating/TemplateApiAdapter";
import TemplateId from "@Primitives/TemplateId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import DocumentTemplateManagementDetailPanel from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/DocumentTemplateManagementDetailPanel";
import SaveButton from "@CommonControls/SaveButton";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import ITokenGroup from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITokenGroup";
import ITemplateToken from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateToken";
import TemplateStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/TemplateStore";
import Button from "@CommonControls/Button";
import TokenUsageListStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/TokenUsageListStore";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import ModalServiceAdapter from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAdapter";
import TemplateInfoDialogParams, { ITemplateInfoDialogResult } from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Panels/Templating/DocumentTemplateManagementMasterDetailPanel/TemplateInfoDialog/TemplateInfoDialogParams";
import DocumentManagementReferenceDataStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentManagementReferenceDataStore";
import ITemplateListItem from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateListItem";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import _ from "@HisPlatform/Common/Lodash";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import { createRawContent, mapContentStringTemplate } from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Templating/TemplateDtoMappers";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import * as Ui from "@CommonControls";
import * as Styles from "./DocumentTemplateManagementMasterDetailPanel.less";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import Encoding from "@Toolkit/CommonWeb/Encoding";
import Log from "@Log";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import { stringifyAndOrderByKey } from "@Toolkit/CommonWeb/JsonHelpers";
import IFormEngineApiAdapter from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import AggregateRootSchema from "@Toolkit/FormEngine/Model/Schema/AggregateRootSchema";
import TemplateTokenGroupId from "@Primitives/TemplateTokenGroupId.g";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import CompositeFormDataElement from "@Toolkit/FormEngine/Model/Schema/CompositeFormDataElement";
import ReferencedAggregateFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedAggregateFormDataElement";

interface IDocumentTemplateManagementMasterDetailPanelDependencies {
    templateApiAdapter: TemplateApiAdapter;
    formEngineApiAdapter: IFormEngineApiAdapter;
    notificationService: INotificationService;
    dialogService: IDialogService;
    referenceDataStore: DocumentManagementReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    fileSaverService: IFileSaverService;
    currentCultureProvider: ICurrentCultureProvider;
}

interface IDocumentTemplateManagementMasterDetailPanelProps {
    _dependencies?: IDocumentTemplateManagementMasterDetailPanelDependencies;
    _modalService?: IModalService;

    selectedId: TemplateId;
    onSelectedIdChange: (selectedId: TemplateId) => void;
}

@State.observer
class DocumentTemplateManagementMasterDetailPanel extends React.Component<IDocumentTemplateManagementMasterDetailPanelProps> {

    private get templateApiAdapter() { return this.props._dependencies!.templateApiAdapter; }
    private get formEngineApiAdapter() { return this.props._dependencies!.formEngineApiAdapter; }
    private get referenceDataStore() { return this.props._dependencies!.referenceDataStore; }
    private get dialogService() { return this.props._dependencies!.dialogService; }
    private get notificationService() { return this.props._dependencies!.notificationService; }
    private get organizationReferenceDataStore() { return this.props._dependencies!.organizationReferenceDataStore; }
    private get modalService() { return this.props._modalService!; }
    private get fileSaverService() { return this.props._dependencies!.fileSaverService; }
    private get currentCultureProvider() { return this.props._dependencies!.currentCultureProvider; }

    private readonly dataSource = new InMemoryDataGridDataSource(() => this.list);
    private readonly panelResources = StaticDocumentManagementResources.DocumentManagementMasterDetailPanel;
    private readonly inputOpenFileRef = React.createRef<HTMLInputElement>();

    private lastContentSnapshot: string = null;
    @State.observable.ref private newTemplate: TemplateStore = null;
    @State.observable.ref private _list: ITemplateListItem[] = null;
    @State.observable.ref private currentTemplate: TemplateStore = null;
    @State.observable.ref private previousTemplateInfo: ITemplateInfo = null;
    @State.observable.ref private tokenGroups: ITokenGroup[] = null;
    @State.observable.ref private tokens: Map<string, ITemplateToken> = null;
    @State.observable.ref private showDeleted: boolean = false;

    @State.computed private get list(): ITemplateListItem[] {
        if (this.newTemplate) {
            const newItem: ITemplateListItem = {
                ...this.newTemplate.info,
                availableAt: null
            };

            return [newItem, ...this._list];
        }
        return this._list;
    }

    private get isDetailOpen() {
        return !!this.props.selectedId;
    }

    private get isDirty() {
        return !!this.currentTemplate &&
            (!!this.newTemplate || this.currentTemplate.documentContent.getContent() !== this.lastContentSnapshot || stringifyAndOrderByKey(this.currentTemplate.info) !== stringifyAndOrderByKey(this.previousTemplateInfo));
    }

    constructor(props: IDocumentTemplateManagementMasterDetailPanelProps) {
        super(props);
        this.dataSource.paging.pageSize = 20;
    }

    @State.action.bound
    public async changeShowDeletedAsync() {
        this.showDeleted = !this.showDeleted;
        await this.loadListAsync();
    }

    @State.action.bound
    private async executeDeleteAsync() {
        const dialogResult = await this.dialogService.yesNoCancel(this.panelResources.Message.DeleteConfirmationTitle,
            this.panelResources.Message.DeleteConfirmationMessage);

        if (dialogResult.resultCode === DialogResultCode.Yes) {
            this.currentTemplate.info.isDeleted = true;
            await this.templateApiAdapter.updateTemplateAsync(this.currentTemplate);
            await this.loadAsync();
            await this.loadListAsync();
            this.props.onSelectedIdChange(null);
        }
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticDocumentManagementResources.TemplateManagement.ListTitle} />;
        }

        return (
            <SingleLayout>
                <MasterDetailLayout
                    isSeparatedMode
                    selectedItem={this.props.selectedId?.value ?? null}
                    onSelectedItemChange={this.setSelectedId}
                >
                    <MasterDetail.Master
                        title={StaticDocumentManagementResources.TemplateManagement.ListTitle}
                        toolbar={this.renderMasterToolbar()}>
                        <DocumentTemplateManagementMasterPanel dataSource={this.dataSource} />
                    </MasterDetail.Master>
                    <MasterDetail.Detail
                        title={this.currentTemplate?.info.name || StaticDocumentManagementResources.TemplateManagement.DetailTitle}
                        subTitle={this.currentTemplate?.info.isDeleted ? StaticDocumentManagementResources.TemplateManagement.StateColumnValueDeleted : null}
                        toolbar={(
                            <>
                                {!!this.newTemplate && (<Button text={this.panelResources.Discard} onClickAsync={this.discardNewTemplateAsync} automationId="discardNewTemplateButton" />)}
                                <Button iconName="export" onClick={this.downloadTemplate} automationId="downloadDocumentTemplateButton" tooltipContent="Export" />
                                <Button iconName="align_top" onClick={this.showOpenFileDialog} automationId="uploadDocumentTemplateButton" tooltipContent="Import" disabled={this.currentTemplate?.info.isDeleted} />
                                <input type="file" onChange={this.uploadTemplateAsync} ref={this.inputOpenFileRef} style={{ display: "none" }} accept=".mwt" disabled={this.currentTemplate?.info.isDeleted} />
                                <Button iconName="more" onClickAsync={this.editBaseDataAsync} automationId="moreButton" />
                                <Button iconName="trash" onClickAsync={this.executeDeleteAsync} automationId="deleteDocumentTemplateButton" disabled={this.currentTemplate?.info.isDeleted || !!this.newTemplate} />
                                <SaveButton onClickAsync={this.saveAsync} automationId="saveDocumentTemplateButton" disabled={this.currentTemplate?.info.isDeleted} />
                            </>
                        )}
                    >
                        <DocumentTemplateManagementDetailPanel
                            currentTemplate={this.currentTemplate}
                            tokenGroups={this.tokenGroups}
                            tokens={this.tokens}
                        />
                    </MasterDetail.Detail>
                </MasterDetailLayout>
                <NavigateAwayHook onNavigateAwayAsync={this.navigateAwayAsync} />
            </SingleLayout>
        );
    }

    @State.bound
    private renderMasterToolbar() {
        return (
            <Ui.Flex xsJustify="end" verticalSpacing="none" className={Styles.headerContainer}>
                {!this.isDetailOpen && <Ui.Flex.Item>
                    <Ui.CheckBox
                        verticalAlign="noPadding"
                        className={Styles.headerButton}
                        label={StaticWebAppResources.Common.Button.ShowDeleted}
                        value={this.showDeleted}
                        labelPosition={"right"}
                        onChange={this.changeShowDeletedAsync}
                        displayMode="switch"
                        automationId="showDeletedCheckBox" />

                </Ui.Flex.Item>}
                <Ui.Flex.Item>
                    <Button
                        className={Styles.headerButton}
                        iconName="plus"
                        onClickAsync={this.createNewTemplateAsync}
                        automationId="addDocumentTemplateButton" />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync, this.templateApiAdapter.updateTemplatePermissionCheckAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IDocumentTemplateManagementMasterDetailPanelProps) {
        if (!ValueWrapper.equals(prevProps.selectedId, this.props.selectedId)) {
            dispatchAsyncErrors(this.loadDetailAsync(), this);
        }
    }

    @State.bound
    private async loadAsync() {
        await this.loadListAsync();

        if (this.props.selectedId?.value === "new") {
            this.clearDetailState();
            this.props.onSelectedIdChange(null);
        } else {
            await this.loadDetailAsync();
        }

        const tokenGroups = await this.templateApiAdapter.getAllTokenGroupsAsync();
        const tokens = await this.templateApiAdapter.getAllTokensAsync();

        tokens.value.find(i => i.symbol === "DynamicAggregateDataElement").tokenFormatterSettingsType = "DynamicAggregateDataElement";
        this.setPanelState(tokenGroups.value, tokens.value);
    }

    private async loadListAsync() {
        const result = await this.templateApiAdapter.getTemplateListAsync(this.showDeleted);
        await this.referenceDataStore.documentTypeMap.ensureAllLoadedAsync();
        const organizationUnitIds = _.flatten(result.value.map(i => i.availableAt).filter(usages => !!usages));
        await this.organizationReferenceDataStore.organizationUnitMap.ensureLoadedAsync(organizationUnitIds);
        this.setListState(result.value);
    }

    @State.action
    private setListState(list: ITemplateListItem[]) {
        this._list = list;
    }

    @State.bound
    private setSelectedId(rawId: string) {
        this.props.onSelectedIdChange(rawId ? new TemplateId(rawId) : null);
    }

    private async loadDetailAsync() {
        if (this.props.selectedId === null) {
            this.setDetailState(null);
            return;
        }

        if (this.props.selectedId.value === "new" && !!this.newTemplate) {
            this.setDetailState(this.newTemplate);
            return;
        }

        const result = await this.templateApiAdapter.getTemplateByIdAsync(this.props.selectedId);

        State.runInAction(() => {
            this.previousTemplateInfo = result.info;
        });

        this.setDetailState(result);
    }

    @State.action
    private setDetailState(template: TemplateStore) {
        this.currentTemplate = template;
        this.lastContentSnapshot = template?.documentContent.getContent() ?? null;
    }

    @State.action
    private setPanelState(tokenGroups: ITokenGroup[], tokens: ITemplateToken[]) {
        this.tokenGroups = tokenGroups;
        this.tokens = new Map<string, ITemplateToken>(tokens.map(t => ([t.symbol, t])));
    }

    @State.bound
    private async saveAsync() {
        if (!this.currentTemplate) {
            return;
        }

        if (!this.newTemplate) {
            if (this.isDirty) {
                const result = await this.templateApiAdapter.updateTemplateAsync(this.currentTemplate);
                this.notificationService.showSaveResult(result.isPersistedByOperationInfo);
                if (result.isPersistedByOperationInfo) {
                    this.clearDetailState();
                    this.props.onSelectedIdChange(null);
                    await this.loadListAsync();
                }
            } else {
                this.notificationService.warning(StaticDocumentManagementResources.TemplateManagement.Message.NoChangesToDocument);
            }
        } else {
            const result = await this.templateApiAdapter.createTemplateAsync(this.currentTemplate);
            this.notificationService.showSaveResult(result.isPersistedByOperationInfo);
            if (result.isPersistedByOperationInfo) {
                this.clearDetailState();
                this.props.onSelectedIdChange(result.value);
                await this.loadListAsync();
            }
        }
    }

    @State.bound
    private async navigateAwayAsync() {
        if (this.isDirty) {
            const confirmDialog = await this.dialogService.confirmIfNotSaved(
                StaticDocumentManagementResources.TemplateManagement.SaveTemplateTitle,
                StaticDocumentManagementResources.TemplateManagement.WouldYouLikeToSaveTemplateMessage);

            switch (confirmDialog.resultCode) {
                case DialogResultCode.Yes:
                    await this.saveAsync();
                    await this.loadListAsync();
                    return true;
                case DialogResultCode.No:
                    this.clearDetailState();
                    this.props.onSelectedIdChange(null);
                    return true;
                case DialogResultCode.Cancel:
                case DialogResultCode.None:
                default:
                    return false;
            }
        }

        return true;
    }

    @State.bound
    private async discardNewTemplateAsync() {
        const dialogResult = await this.dialogService.yesNoCancel(this.panelResources.Message.DiscardChangesConfirmationTitle,
            this.panelResources.Message.DiscardChangesConfirmationMessage);

        if (dialogResult.resultCode === DialogResultCode.Yes) {
            this.clearDetailState();
            this.props.onSelectedIdChange(null);
        }
    }

    @State.bound
    private async createNewTemplateAsync() {
        const newTemplate = new TemplateStore(true);
        newTemplate.id = new TemplateId("new");
        newTemplate.rowVersion = null;
        newTemplate.tokenUsages = new TokenUsageListStore();

        const emptyTemplateInfo: ITemplateInfo = {
            id: newTemplate.id,
            code: "",
            description: "",
            name: "",
            cultureCode: this.currentCultureProvider.cultureCode,
            contentId: null,
            documentTypeId: null,
            rowVersion: newTemplate.rowVersion,
            isPrimary: false,
            isDeleted: false
        };

        const dialogResult = await this.modalService.showDialogAsync<ITemplateInfoDialogResult>(new TemplateInfoDialogParams(emptyTemplateInfo, true));
        if (dialogResult?.newTemplateInfo) {
            newTemplate.info = dialogResult.newTemplateInfo;
            State.runInAction(() => {
                this.newTemplate = newTemplate;
            });
            this.props.onSelectedIdChange(newTemplate.id);
        }
    }

    @State.action
    private clearDetailState() {
        this.newTemplate = null;
        this.currentTemplate = null;
        this.lastContentSnapshot = null;
    }

    @State.action.bound
    private setLastContent(content: string) {
        this.lastContentSnapshot = content;
    }

    @State.bound
    private async editBaseDataAsync() {
        const dialogResult = await this.modalService.showDialogAsync<ITemplateInfoDialogResult>(new TemplateInfoDialogParams(this.currentTemplate.info, false));
        if (dialogResult?.newTemplateInfo) {
            State.runInAction(() => {
                this.currentTemplate.info = dialogResult.newTemplateInfo;
            });
        }
    }

    @State.bound
    private downloadTemplate() {
        if (!this.currentTemplate) {
            return;
        }

        const blob = new Blob([createRawContent(this.currentTemplate.documentContent.getContent(), this.currentTemplate.tokenUsages.items)],
            { type: "application/json" });

        this.fileSaverService.saveAs(blob, `${this.currentTemplate?.info.name ?? "document_template"}_${DateTimeService.now().format("YYYY-MM-DD_HH-mm-ss")}.mwt`);
    }

    @State.bound
    private showOpenFileDialog() {
        this.inputOpenFileRef.current.click();
    }

    @State.bound
    private async uploadTemplateAsync(event: React.ChangeEvent<HTMLInputElement>) {
        event.persist();

        const file = event.target.files[0];
        // eslint-disable-next-line no-useless-escape
        const extension = file.name.match(/\.([^\.]+)$/)[1];

        try {
            if (extension === "mwt") {
                const bytes = await file.toUint8ArrayAsync();
                const rawContent = Encoding.UTF8.getString(bytes);
                const rawParsed: any = JSON.parse(rawContent);
                const content = mapContentStringTemplate(rawParsed);

                this.currentTemplate.documentContent.setContent(content.documentContent);
                this.currentTemplate.setTokenUsages(new TokenUsageListStore(content.tokenUsages));
            } else {
                await this.dialogService.ok(StaticDocumentManagementResources.TemplateManagement.Message.FileFormatErrorMessageTitle, StaticDocumentManagementResources.TemplateManagement.Message.FileFormatErrorMessage);
            }
        } catch (error) {
            Log.error(error.message);
            this.notificationService.error(StaticDocumentManagementResources.TemplateManagement.Message.FileUploadErrorMessage);
        }
    }
}

export default connect(
    DocumentTemplateManagementMasterDetailPanel,
    new ModalServiceAdapter(),
    new DependencyAdapter<IDocumentTemplateManagementMasterDetailPanelProps, IDocumentTemplateManagementMasterDetailPanelDependencies>(c => ({
        templateApiAdapter: c.resolve("TemplateApiAdapter"),
        notificationService: c.resolve("INotificationService"),
        dialogService: c.resolve("IDialogService"),
        referenceDataStore: c.resolve("DocumentManagementReferenceDataStore"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        fileSaverService: c.resolve<IFileSaverService>("IFileSaverService"),
        currentCultureProvider: c.resolve("ICurrentCultureProvider"),
        formEngineApiAdapter: c.resolve<IFormEngineApiAdapter>("IFormEngineApiAdapter"),
    }))
);