import SelectBox, { inputChangeActionType, ISelectBoxProps } from "@CommonControls/SelectBox";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import TextBox from "@CommonControls/TextBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import React from "react";

export interface IAutoCompleteFieldProps extends ISelectBoxBaseProps {
    value: string;
    onChange: (newValue: string) => void;
    onSearchForSuggestions?: () => void;
    suggestions: ISelectBoxItem[];
    capitalize?: boolean;
}

@State.observer
export default class AutoCompleteField extends React.Component<IAutoCompleteFieldProps> {
    @State.observable.ref private menuIsOpen = false;
    @State.observable.ref private quickSearchText = "";

    @State.computed
    private get suggestions(): Array<ISelectBoxItem<string>> {
        return [{
            text: this.props.value,
            value: this.props.value
        } as ISelectBoxItem<string>, ...this.props.suggestions.filter(suggestion => suggestion.value !== this.props.value)];
    }

    @State.action.bound
    private setQuickSearchText(quickSearchText: string) {
        this.quickSearchText = quickSearchText;
    }

    @State.action.bound
    private setText(text: string, action: inputChangeActionType) {
        const modifiedText = this.props.capitalize ? _.capitalize(text) : text;
        switch (action) {
            case "input-change":
                if (this.props.value !== text) {
                    this.props.onChange(modifiedText);
                    if (this.quickSearchText !== modifiedText) {
                        this.setQuickSearchText(modifiedText);
                    }
                    this.props.onSearchForSuggestions?.();
                }
                break;

            case "set-value":
                this.setQuickSearchText("");
                break;
            case "menu-close":
            case "input-blur": {
                if (this.quickSearchText !== modifiedText) {
                    this.setQuickSearchText(modifiedText);
                }
                break;
            }
        }

    }

    @State.action.bound private openMenu() { this.menuIsOpen = true; }
    @State.action.bound private closeMenu() { this.menuIsOpen = false; }

    public render() {
        if (this.props.isReadOnly) {
            return (
                <TextBox
                    label={this.props.label}
                    id={this.props.id}
                    automationId={this.props.automationId}
                    disabled={this.props.disabled}
                    name={this.props.name}
                    tabIndex={this.props.tabIndex}
                    value={this.props.value}
                    isReadOnly
                />
            );
        }

        return (
            <SelectBox
                {...this.props}
                items={this.suggestions}
                onInputChange={this.setText}
                value={this.props.value}
                menuIsOpen={this.menuIsOpen}
                onMenuOpen={this.openMenu}
                onMenuClose={this.closeMenu}
                hideSelectedOptions={false}
                inputValue={this.quickSearchText}
            />
        );
    }

}