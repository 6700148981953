import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import OrganizationUnit from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnit";
import OrganizationUnitBaseData from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnitBaseData";
import Address from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/Address";
import Contact from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/Contact";
import OrganizationUnitNaturalIdentifier from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnitNaturalIdentifier";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import Di from "@Di";

@Di.injectable()
export default class OrganizationUnitStoreMapper extends EntityStoreMapper<Proxy.OrganizationUnitDto, OrganizationUnit> {
    protected storeEntityIdType = OrganizationUnitId;

    protected applyToStoreCore(target: OrganizationUnit, dto: Proxy.OrganizationUnitDto) {
        target.parentId = dto.parentId;
        target.validity = dto.validity;

        target.baseData = new OrganizationUnitBaseData();
        target.baseData.address = new Address(
            dto.baseData.address.countryId,
            dto.baseData.address.settlement,
            dto.baseData.address.zipCode,
            dto.baseData.address.addressLine
        );
        
        target.baseData.contacts.replace(dto.baseData.contacts.map(c => new Contact(c.contactTypeId, c.value)));

        target.baseData.naturalIdentifier = new OrganizationUnitNaturalIdentifier(
            dto.baseData.organizationUnitNaturalIdentifier.name,
            dto.baseData.organizationUnitNaturalIdentifier.shortName
        );
        target.baseData.managerId = dto.baseData.managerId;

        target.code = dto.code;
        target.organizationUnitDefinitionId = dto.definitionId;
    }
}
