import React from "react";
import * as Ui from "@CommonControls";
import PrescriptionDataSource from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionDataSource";

interface IPrescriptionDataSourceSelectorProps {
    value: PrescriptionDataSource,
    onChange: (newValue: PrescriptionDataSource) => void;
    label: string
}

export default class PrescriptionDataSourceSelector extends React.Component<IPrescriptionDataSourceSelectorProps> {
    public render() {
        return (
            <Ui.UniversalEnumSelector
                enumName={"PrescriptionDataSource"}
                enumOrigin={"server"}
                value={this.props.value}
                onChange={this.props.onChange}
                enumType={PrescriptionDataSource}
                displayMode={"groupedRadioButtons"}
                label={this.props.label}
                automationId="prescriptionDataSourceSelector"
            />
        );
    }
}