import React, { useMemo } from "react";
import * as Ui from "@CommonControls";

interface IAvatarProps {
    gender: "male" | "female";
    size?: "large" | "mini" | "compact" | "normal" | "largeish" | "extra large" | "giant";
}


const Avatar: React.FC<IAvatarProps> = (props) => {

    const iconName = useMemo(() => {
        switch (props.gender) {
            case "female":
                return "patient_woman";
            case "male":
                return "patient_man";
            default:
                return "patient_unknown";
        }
    }, [props.gender]);

    if (!iconName) {
        return null;
    }

    return <Ui.Icon iconName={iconName} size={props.size} />;
};

Avatar.defaultProps = {
    size: "large"
};

export default Avatar;