import React from "react";
import Styles from "./Led.less";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";
import TextCard from "@CommonControls/InfoButton/TextCard";

interface ILedProps {
    ledColor: string;
    bordered?: boolean;
    label?: string;
    innerLabel?: string;
    toolTipText?: string;
    automationId?: string;
    visualMode?: "normal" | "dark";    
}


const Led: React.FC<ILedProps> = (props) => {

    if (!props.ledColor) {
        return null;
    }

    const renderLed = (() => {
        const led = <div className={combineClasses(Styles.led, props.bordered && Styles.border)} style={{ background: props.ledColor }} />;

        if (!props.toolTipText) {
            return led;
        } else {
            return (
                <Popper
                    tooltipContent={(
                        <TextCard text={props.toolTipText} />
                    )}
                >
                    {led}
                </Popper>
            );
        }
    });

    return (
        <div>
            {props.label && <label className={Styles.label}>{props.label}</label>}
            <div className={Styles.ledContainer}>
                {renderLed()}
                {props.innerLabel && <label className={combineClasses(Styles.innerLabel, props.visualMode === "dark" && Styles.innerLabelDark)} data-automation-id={props.automationId && `${props.automationId}_Label` || undefined}>{props.innerLabel}</label>}
            </div>
        </div>
    );
};

Led.defaultProps = {
    bordered: false
};

export default Led;