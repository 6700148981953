import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";
import Prescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Prescription";
import PrescriptionId from "@Primitives/PrescriptionId.g";
import Di from "@Di";
import MedicationRequestCoverage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationRequestCoverage";
import { mapCurativeItem, mapFamilyDoctorDocumentMedicationReference } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/MedicationRequestMapperHelpers";
import FamilyDoctorDocumentStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/FamilyDoctorDocument/FamilyDoctorDocumentStore";
import FamilyDoctorDocumentId from "@Primitives/FamilyDoctorDocumentId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

type ResponseType = Proxy.CreateFamilyDoctorDocumentCommandResponse | Proxy.UpdateFamilyDoctorDocumentCommandResponse | Proxy.GetFamilyDoctorDocumentByIdCommandResponse;

@Di.injectable()
export default class FamilyDoctorDocumentStoreMapper extends LockingEntityStoreMapper<Proxy.FamilyDoctorDocumentDto, FamilyDoctorDocumentStore, ResponseType> {
    protected applyToStoreCore(target: FamilyDoctorDocumentStore, response: ResponseType): void {
        const dto = this.entityDtoSelector(response);

        if (isNullOrUndefined(dto)) {
            return;
        }
        target.familyDoctorDocumentSubject = dto.subject;
        target.patientId = dto.patientId;
        target.createdAt = dto.createdAt;
        target.createdBy = dto.createdBy;
        target.careActivityId = dto.careActivityId;
        target.pointOfCareId = dto.pointOfCareId;
        target.familyDoctorDocumentMedicationReferences = State.observable(dto.medicationReferencesItems.map(medicationReferencesItem =>
            mapFamilyDoctorDocumentMedicationReference(medicationReferencesItem)));
        target.curativeItems = State.observable(dto.curativeItems.map(curativeItem =>
            mapCurativeItem(curativeItem)));
    }

    protected entityDtoSelector(response: ResponseType) {
        return response.familyDoctorDocument;
    }

    protected storeEntityIdType = FamilyDoctorDocumentId;

    protected vGetStoreIdentifier() {
        return "FamilyDoctorDocument";
    }
}