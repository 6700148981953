import React from "react";
import { provideFormPanelStore, useFormPanelStore } from "./FormPanelStoreProvider";
import FormLayoutAnyBlock from "./LayoutElements/FormLayoutAnyBlock";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";

export type ChangeAction = "set" | "add" | "remove";

export interface IFormPanelProps {
    definitionId: FormDefinitionId;
    formDefinition?: IFormDefinition;
    form?: IForm;
    isReadOnly?: boolean;
    onChange?: (propertyIdentifier: string, value: any, action: ChangeAction) => void;
}

function FormPanel(_props: IFormPanelProps) {

    const store = useFormPanelStore();

    if (!store.areReferenceDataLoaded.get()) {
        return null;
    }

    return (
        <ReadOnlyContext.Provider value={_props.isReadOnly}>
            {store.layout?.map((b, idx) => <FormLayoutAnyBlock cultureCode={store.cultureCodeProvider.cultureCode} block={b} key={idx} schemaReference={_props.form.data.SchemaReference} />)}
        </ReadOnlyContext.Provider>
    );
}

export default provideFormPanelStore(FormPanel);
