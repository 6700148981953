import Di from "@Di";
import IPatientDocumentFormattingRegistry from "@PluginInterface/PatientDocumentFormatting/IPatientDocumentFormattingRegistry";
import IPatientDocumentFormattingDescriptor from "@PluginInterface/PatientDocumentFormatting/IPatientDocumentFormattingDescriptor";

@Di.injectable()
export default class PatientDocumentFormattingRegistry implements IPatientDocumentFormattingRegistry {

    private readonly formattingDescriptors: Map<string, IPatientDocumentFormattingDescriptor> = new Map<string, IPatientDocumentFormattingDescriptor>();

    public register(identifierSystems: string[], regexp: RegExp, formatting: string): void {
        identifierSystems.forEach(identifierSystem => {
            this.formattingDescriptors.set(identifierSystem,
                {
                    identifierSystem: identifierSystem,
                    regexp: regexp,
                    formatting: formatting
                } as IPatientDocumentFormattingDescriptor
            );
        });
    }

    public get(identifierSystem: string): IPatientDocumentFormattingDescriptor {
        return this.formattingDescriptors.get(identifierSystem);
    }

    public getAll(): Map<string, IPatientDocumentFormattingDescriptor> {
        return this.formattingDescriptors;
    }

    public format(identifierSystem: string, value: string): string {
        const formattingDescriptor = this.get(identifierSystem);
        if (formattingDescriptor !== null && formattingDescriptor !== undefined) {
            return value?.replace(formattingDescriptor.regexp, formattingDescriptor.formatting);
        }

        return value;
    }
}