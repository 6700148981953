import Di from "@Di";
import PrescriptionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionApiAdapter";
import DosageInfoColumnData from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/DosageInfoColumnData";
import PermanentPrescriptionId from "@Primitives/PermanentPrescriptionId.g";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import PermanentPrescriptionDosage from "./PermanentPrescriptionDosage";

@Di.injectable()
export default class PermanentPrescriptionReferenceDataStore extends ReferenceDataStoreBase {
    public readonly dosages = new ReferenceDataStore<PermanentPrescriptionId, PermanentPrescriptionDosage>(
        this.prescriptionApiAdapter.getDosagesByPermanentPrescriptionIdsAsync
    );

    constructor(
        @Di.inject("PrescriptionApiAdapter") private readonly prescriptionApiAdapter: PrescriptionApiAdapter,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader) {
        super();
        referenceDataLoader.register<DosageInfoColumnData>("DosageInfoDto", refs =>
            this.dosages.ensureLoadedAsync(refs.map(i => i.permanentPrescriptionId)));
    }
}