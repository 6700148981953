import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import React from "react";
import * as Ui from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import * as Style from "./AppointmentScheduleServicesGrid.less";

interface IAppointmentScheduleServicesGridProps {
    schedulingServices: InMemoryDataGridDataSource;
    onDeleteSchedulingServiceAsync: (service: SchedulingServiceSubjectStore) => Promise<void>;
    onEditSchedulingServiceAsync: (service: SchedulingServiceSubjectStore) => Promise<void>;
    onAddServiceAsync: () => Promise<void>;
}


const AppointmentScheduleServicesGrid: React.FC<IAppointmentScheduleServicesGridProps> = props => {
    const deleteHandlerFactory = (store: SchedulingServiceSubjectStore) => {
        return async () => {
            await props.onDeleteSchedulingServiceAsync(store);
        };
    };

    const editHandlerFactory = (store: SchedulingServiceSubjectStore) => {
        return async () => {
            await props.onEditSchedulingServiceAsync(store);
        };
    };

    const renderSchedulingService = (value: string, rowData: SchedulingServiceSubjectStore) => {
        return (
            <div className={Style.serviceColumn}>
                <b>{rowData.code}</b> {rowData.name} {!!rowData.durationInMinutes && <>({rowData.durationInMinutes} perc)</>}
            </div>
        );
    };

    const renderAddServiceButton = () => {
        return (
            <Ui.Button
                text={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.BaseData.AddServiceButton}
                onClickAsync={props.onAddServiceAsync}
                iconName="plus"
                className={Style.addButton}
                automationId="addButton"
            />
        );
    };

    const renderServiceRowButtons = (value: string, row: SchedulingServiceSubjectStore) => {
        return (
            <div className={Style.actionsColumn}>
                <Ui.Button
                    iconName="trash"
                    visualStyle="negative-standard"
                    onClickAsync={deleteHandlerFactory(row)}
                    automationId={row.id.value + "_deleteButton"}
                />
                <Ui.Button
                    iconName="pen"
                    visualStyle="standard"
                    onClickAsync={editHandlerFactory(row)}
                    automationId={row.id.value + "_editButton"}
                />
            </div>
        );
    };

    const filterValueGetter = (value: string, row: SchedulingServiceSubjectStore) => {
        return `${row.code}${row.name}`;
    };

    return (
        <Ui.GroupBox title={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.BaseData.CalendarServices}>
            <Ui.DataGrid
                dataSource={props.schedulingServices}
                generateInitialFilterStore={true}
                changeOnMount
                actionsColumn={false}
                automationId="appointmentScheduleServicesGrid_dataGrid"
            >
                <DataGridColumn
                    id={0}
                    onRenderCellValue={renderSchedulingService}
                    isFilterable
                    width="80%"
                    clientSideFilterableValueGetter={filterValueGetter}
                    header={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.BaseData.Search}
                />
                <DataGridColumn
                    id={1}
                    onRenderCellValue={renderServiceRowButtons}
                    onRenderFilter={renderAddServiceButton}
                    isFilterable
                    automationId={"Actions"}
                    isObserver
                    width="20%"
                />
            </Ui.DataGrid>
        </Ui.GroupBox>
    );
};

export default AppointmentScheduleServicesGrid;