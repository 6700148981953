import React from "react";
import { PageBox2, InfoBox } from "@CommonControls";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";

interface IUnauthorizedAccessPageBoxProps {
    title: string;
    headerToolbar?: React.ReactNode;
}

export default function UnauthorizedAccessPageBox(props: React.PropsWithChildren<IUnauthorizedAccessPageBoxProps>) {

    return (
        <SingleLayout>
            <PageBox2>
                <PageBox2.Header title={props.title} toolbar={props.headerToolbar} />
                <PageBox2.Body hasSidePadding hasVerticalPadding>
                    <UnauthorizedAccessContent />
                </PageBox2.Body>
            </PageBox2>
        </SingleLayout>
    );
}