import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import * as Styles from "./UniquePermissionConfigurationPanel.less";
import * as Ui from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import IPermissionDefinition from "@PluginInterface/OperationAccessControl/IPermissionDefinition";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import PermissionDefinitionSelectBox from "@HisPlatform/BoundedContexts/Authorization/Components/Controls/PermissionDefinitionSelectBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PermissionId from "@Primitives/PermissionId.g";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";
import UniquePermissionConfigurationStore from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/RoleManagement/PermissionConfigurationPanel/UniquePermissionConfigurationPanel/UniquePermissionConfigurationStore";
import StaticAuthorizationResources from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";
import PointOfCareScopeSelector from "@HisPlatform/BoundedContexts/Authorization/Components/Controls/PointOfCareScopeSelector/PointOfCareScopeSelector";
import IPermissionScope from "@PluginInterface/OperationAccessControl/IPermissionScope";
import { getPermissionDefinitionName } from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/PermissionLocalizationHelper";

interface IUniquePermissionConfigurationPanelViewDependencies {
    localizationService: ILocalizationService;
}

interface IUniquePermissionConfigurationPanelViewProps {
    _dependencies?: IUniquePermissionConfigurationPanelViewDependencies;
    permissions: IPermissionDefinition[];
    store: UniquePermissionConfigurationStore;
    addPermission: () => void;
    removePermission: (permission: IPermissionDefinition) => void;
    onPointOfCareTypeRowValueChange: (newValue: IPermissionScope, permission: IPermissionDefinition) => void;
    onPermissionAssignmentTypeChanged: (value: boolean, row: IPermissionDefinition) => void;
    getPermissionRowProhibitionValue: (row: IPermissionDefinition) => boolean;
    getPointOfCareScopeValue: (permission: IPermissionDefinition) => IPermissionScope;
    permissionDefinitionHasScopeType: (scopeType: string) => boolean;
    setPermissionId: (id: PermissionId) => void;
    getPointOfCareNames: (ids: IPermissionScope) => string;
}

@State.observer
class UniquePermissionConfigurationPanelView extends React.Component<IUniquePermissionConfigurationPanelViewProps> {
    private get localizationService() {
        return this.props._dependencies.localizationService;
    }

    @State.bound
    private renderRowButtons(value: any, row: IPermissionDefinition, rowIndex: number) {

        const clickHandler = () => this.props.removePermission(row);

        return (
            <Ui.Button
                visualStyle="negative-standard"
                iconName="trash"
                size="compact"
                onClick={clickHandler}
                className={Styles.marginRight}
                automationId={`__deletePermissionButton${rowIndex}`}
            />
        );
    }

    @State.bound
    private renderPointOfCareTypeColumn(value: IPermissionScope, row: IPermissionDefinition, rowIndex: number) {
        const changeHandler = (newValue: IPermissionScope) => {
            this.props.onPointOfCareTypeRowValueChange(newValue, row);
        };

        return (
            <>
                {!!value ? <PointOfCareScopeSelector
                    value={value}
                    onValueChange={changeHandler}
                    automationId={`__pointOfCareScopeSelector${rowIndex}`}
                /> : <i>n/a</i>}
            </>
        );
    }

    @State.bound
    private getPermissionDefinitionName(value: IPermissionDefinition) {
        return getPermissionDefinitionName(this.localizationService, value);
    }

    @State.bound
    private renderPermissionProhibitionColumn(value: boolean, row: IPermissionDefinition, rowIndex: number) {
        const changeHandler = (newValue: boolean) => {
            this.props.onPermissionAssignmentTypeChanged(newValue, row);
        };

        return (
            <Ui.CheckBox
                value={value}
                onChange={changeHandler}
                automationId={`__prohibitPermissionCheckBox${rowIndex}`}
            />
        );
    }

    @State.bound
    private renderPointOfCareSelector() {
        const permissionDefinitionHasScopeType = this.props.permissionDefinitionHasScopeType(PermissionScopeType.pointOfCare);

        return (
            <>
                {permissionDefinitionHasScopeType &&
                    <PointOfCareScopeSelector
                        value={this.props.store.pointOfCareScope}
                        onValueChange={this.props.store.setPointOfCareScope}
                        automationId="_newPermissionPointOfCareScopeSelector"
                    />}
            </>
        );
    }

    @State.bound
    private renderFirstRow() {
        return (
            <tr className={Styles.newElementPanel}>
                <th>
                    <PermissionDefinitionSelectBox
                        excludedItems={this.props.permissions}
                        value={this.props.store.permission?.aliasedId}
                        onChange={this.props.setPermissionId}
                        automationId="__newPermissionIdSelectBox"
                    />
                </th>
                <th>
                    <Ui.CheckBox
                        value={this.props.store.isPermissionProhibition}
                        onChange={this.props.store.setIsPermissionProhibition}
                        automationId="__prohibitNewPermissionCheckBox"
                    />
                </th>
                <th>
                    {this.renderPointOfCareSelector()}
                </th>
                <th>
                    <Ui.Button
                        onClick={this.props.addPermission}
                        iconName="check"
                        size="compact"
                        float="right"
                        visualMode="inverted"
                        className={Styles.marginRight}
                        automationId="__addPermissionButton"
                    />
                </th>
            </tr>
        );
    }

    @State.bound
    private renderPointOfCareHint(value: IPermissionScope) {
        return this.props.getPointOfCareNames(value);
    }
    public render() {
        return (
            <Ui.DataGrid
                dataSource={this.props.permissions}
                onRenderHeader={this.renderFirstRow}
                rowHeight={44}
                fixedHeight="50%"
                propertyIdentifier=".*"
                automationId="UniquePermissionsGrid"
            >
                <DataGridColumn
                    isObserver
                    header={StaticAuthorizationResources.PermissionConfiguration.UniquePermissionConfiguration.PermissionName}
                    dataGetter={this.getPermissionDefinitionName}
                    width="30%"
                />
                <DataGridColumn
                    header={StaticAuthorizationResources.PermissionConfiguration.UniquePermissionConfiguration.Prohibition}
                    width="15%"
                    dataGetter={this.props.getPermissionRowProhibitionValue}
                    onRenderCellValue={this.renderPermissionProhibitionColumn}
                    showHint={false}
                />
                <DataGridColumn
                    isObserver
                    header={StaticAuthorizationResources.PermissionConfiguration.UniquePermissionConfiguration.PointOfCares}
                    dataGetter={this.props.getPointOfCareScopeValue}
                    onRenderCellValue={this.renderPointOfCareTypeColumn}
                    onRenderHintValue={this.renderPointOfCareHint}
                    width="45%"
                />
                <DataGridColumn
                    cellTextAlign="right"
                    width="10%"
                    onRenderCellValue={this.renderRowButtons}
                />
            </Ui.DataGrid>
        );
    }
}

export default connect(
    UniquePermissionConfigurationPanelView,
    new DependencyAdapter<IUniquePermissionConfigurationPanelViewProps, IUniquePermissionConfigurationPanelViewDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService")
    }))
);