import Di from "@Di";
import _ from "@HisPlatform/Common/Lodash";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import ServiceRequestsListStoreMapper from "./ServiceRequestsListStoreMapper";
import ServiceRequestsListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestsListStore";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import ServiceRequestDefinitionCode from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestDefinitionCode";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import { getGetActiveServiceRequestDefinitionVersionsControllerDto as mapGetActiveServiceRequestDefinitionVersionsControllerDto, mapGetServiceRequestDefinitionVersionsBySelectorsControllerDto } from "./ServiceRequestManagementDtoMapper";
import { CareLocation } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/CareLocation";
import ServiceRequestDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestDefinition";
import PatientId from "@Primitives/PatientId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import IObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/IObservation";
import { getObservation } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/Observations/ObservationStoreMapper";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";

@Di.injectable()
export default class ServiceRequestManagementApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IServiceRequestManagementClient") private readonly apiClient: Proxy.IServiceRequestManagementClient,
        @Di.inject("ICareRegisterClient") private readonly careRegisterClient: Proxy.ICareRegisterClient,
        @Di.inject("ServiceRequestsListStoreMapper") private readonly serviceRequestsListStoreMapper: ServiceRequestsListStoreMapper,
        @Di.inject("ICurrentCultureProvider") private readonly currentCultureProvider: ICurrentCultureProvider
    ) {
        super();
    }

    @State.bound
    public getObservationsForRequestedServicesAsync(serviceRequestId: ServiceRequestId): Promise<SimpleStore<IObservation[]>> {
        return this.processOperationAsync(
            new SimpleStore<IObservation[]>(),
            async target => {
                const response =
                    await this.careRegisterClient.getObservationsForRequestedServicesQueryAsync(CreateRequestId(), serviceRequestId.value);

                target.operationInfo = createOperationInfo(response);
                target.value = response.observations.map(i => getObservation(i));
            }
        );
    }
    
    public getObservationsResultAsync(careActivityId: CareActivityId, serviceRequestId: ServiceRequestId) {
        return this.processOperationAsync(
            new SimpleStore<Proxy.GetObservationsResultQueryResponse>(),
            async target => {
                const response = await this.apiClient.getObservationsResultQueryAsync(
                    CreateRequestId(),
                    careActivityId.value, 
                    this.currentCultureProvider.cultureCode,
                    serviceRequestId.value
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response;
            }
        );
    }

    @State.bound
    public getServiceRequestsByCareActivityIdAsync(id: CareActivityId): Promise<ServiceRequestsListStore> {
        return this.processOperationAsync(
            new ServiceRequestsListStore(),
            async target => {
                const response = await this.apiClient.getServiceRequestsForStarterCareActivityQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetServiceRequestsForStarterCareActivityControllerDto({
                        careActivityId: id
                    })
                );
                this.serviceRequestsListStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public getServiceRequestsForPatientAsync(patientId: PatientId, activeCareActivityId: CareActivityId): Promise<ServiceRequestsListStore> {
        return this.processOperationAsync(
            new ServiceRequestsListStore(),
            async target => {
                const response = await this.apiClient.getServiceRequestsForPatientQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetServiceRequestsForPatientControllerDto({
                        activeCareActivityId: activeCareActivityId,
                        patientId: patientId
                    })
                );

                this.serviceRequestsListStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public getActiveServiceRequestDefinitionsAsync(validOn: LocalDate): Promise<SimpleStore<ServiceRequestDefinition[]>> {
        return this.processOperationAsync(
            new SimpleStore<ServiceRequestDefinition[]>(),
            async target => {
                const response = await this.apiClient.getActiveServiceRequestDefinitionVersionsQueryAsync(
                    CreateRequestId(),
                    mapGetActiveServiceRequestDefinitionVersionsControllerDto(validOn)
                );
                target.operationInfo = createOperationInfo(response);
                target.value = response.serviceRequestDefinitions.map(this.mapServiceRequestDefinitions);
            }
        );
    }

    @State.bound
    public getServiceRequestDefinitionsBySelectorsAsync(selector: Array<IEntityVersionSelector<ServiceRequestDefinitionId>>): Promise<SimpleStore<ServiceRequestDefinition[]>> {
        return this.processOperationAsync(
            new SimpleStore<ServiceRequestDefinition[]>(),
            async target => {
                const response = await this.apiClient.getServiceRequestDefinitionVersionsBySelectorsQueryAsync(
                    CreateRequestId(),
                    mapGetServiceRequestDefinitionVersionsBySelectorsControllerDto(selector));
                    target.operationInfo = createOperationInfo(response);
                target.value = response.serviceRequestDefinitions.map(this.mapServiceRequestDefinitions);
            }
        );
    }

    @State.bound
    private mapServiceRequestDefinitions(dto: Proxy.ServiceRequestDefinitionVersionDto): ServiceRequestDefinition {
        return new ServiceRequestDefinition(
            dto.id,
            new ServiceRequestDefinitionCode(dto.code.value),
            dto.name,
            dto.shortName,
            dto.clinicalQuestionAllowed,
            dto.medicalServiceIds,
            dto.careLocations.map(this.getCareLocation),
            new LocalDateRange(LocalDate.createFromMoment(dto.validity.from), LocalDate.createFromMoment(dto.validity.to)),
            dto.availableDirections,
            dto.medicalServicePanelIds,
            dto.requestDocumentTypeCode,
            dto.reportDocumentTypeCode,
            dto.specimenDataRequired
        );
    }

    private getCareLocation(location: Proxy.CareLocationDto): CareLocation {
        if (location instanceof Proxy.ExternalCareLocationDto) {
            return new ExternalCareLocation(new EntityVersionSelector<ExternalLocationId>(
                location.externalLocationSelector.entityId,
                location.externalLocationSelector.validOn
            ));
        } else if (location instanceof Proxy.InternalCareLocationDto) {
            return new InternalCareLocation(location.pointOfCareId);
        }

        throw new Error("Cannot map care location (unknown concrete type)");
    }
}
