import ShowPatientMovedCareActivitiesAction from "@HisPlatform/Packages/Care/FrontendActions/ShowPatientMovedCareActivitiesAction.g";
import {IScreenPropsBase} from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import {ScreenStateContextAdapter, ScreenStateContextStore} from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import CareActivityId from "@Primitives/CareActivityId.g";
import {useDependencies} from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import PatientMovedCareActivitiesScreenApiAdapter from "@HisPlatform/Packages/Care/Screens/PatientMovedCareActivitiesScreen/PatientMovedCareActivitiesScreenApiAdapter";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import {useCallback} from "react";
import ShowPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientScreenAction.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import NDataScreen from "@HisPlatform/BoundedContexts/Productivity/Components/NDataScreen/NDataScreen";
import {Button} from "@CommonControls";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import {HisActionDispatcherAdapter} from "@HisPlatform/Common/FrontendActions/HisActionDispatcher";
import React from "react";

export interface IPatientMovedCareActivitiesScreenProps extends IScreenPropsBase<ShowPatientMovedCareActivitiesAction> {
    _screenState?: ScreenStateContextStore;
    _actionDispatcher?: IActionDispatcher;
}

function PatientMovedCareActivitiesScreen(props: IPatientMovedCareActivitiesScreenProps) {
    let careActivityIds: CareActivityId[] = null;

    const { apiAdapter } = useDependencies(c => ({
        apiAdapter: c.resolve<PatientMovedCareActivitiesScreenApiAdapter>("PatientMovedCareActivitiesScreenApiAdapter"),
    }));

    const getDynamicListAsync = useCallback(async (args: FrontendListParameters) => {
        if (!careActivityIds) {
            const screenData = await apiAdapter.getPatientMovedCareActivitiesScreenDataAsync(props.action.keptPatientId, props.action.deletedPatientId);
           careActivityIds =  screenData.result;
        }
        const response = await apiAdapter.getPatientMovedCareActivitiesListAsync(props.action.keptPatientId, careActivityIds, args);
        return response.result;
    }, []);

    const onClose = useCallback(() => {
        props._actionDispatcher.dispatchAsync(new ShowPatientScreenAction(ScreenDisplayMode.Full, props.action.keptPatientId, null))
            .catch((err) => {throw err;});
    }, []);

    return (
        <NDataScreen
            onGetDynamicListAsync={getDynamicListAsync}
            rightFooter={<Button automationId={"closeButton"} visualStyle={"primary"} onClick={onClose} text={StaticCareResources.Common.Button.Close} />}
            onModalCancelled={onClose}
            modalFixedHeight={"88vh"}
            modalMaxHeight={"88vh"}
        />
    );
}

export default connect<IPatientMovedCareActivitiesScreenProps>(
    PatientMovedCareActivitiesScreen,
    new ScreenStateContextAdapter(),
    new HisActionDispatcherAdapter<IPatientMovedCareActivitiesScreenProps>(),
);