import FormDataElementBase from "./FormDataElementBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class ReferencedAggregateFormDataElement extends FormDataElementBase {

    @State.observable.ref public aggregateName: string;
    @State.observable.ref public isSchemaBased: boolean;

    constructor(
        name: string,
        isArray: boolean,
        defaultValue: number,
        isReadOnly: boolean,
        isVisible: boolean,
        isSchemaBased: boolean,
        aggregateName?: string
    ) {
        super(name, isArray, defaultValue, isReadOnly, isVisible);
        this.aggregateName = aggregateName;
        this.isSchemaBased = isSchemaBased;
    }

    public formDataElementType = "ReferencedAggregate";
}