import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalConditionHistoryItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemBase";
import FormerConditionDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/FormerConditionDetail";
import ConditionStatementClinicalStatus from "@HisPlatform/BoundedContexts/Care/Api/MedicalCondition/ConditionStatement/Enum/ConditionStatementClinicalStatus.g";

export default class ConditionStatementHistoryItem extends MedicalConditionHistoryItemBase {
    @State.observable.ref public onsetDate: LocalDate;
    @State.observable.ref public formerConditionDetail: FormerConditionDetail;
    @State.observable.ref public description: string;
    @State.observable public clinicalStatus: ConditionStatementClinicalStatus;

    @State.action.bound
    public setOnsetDate(newValue: LocalDate) {
        this.onsetDate = newValue;
    }

    @State.action.bound
    public setFormerConditionDetail(newValue: FormerConditionDetail) {
        this.formerConditionDetail = newValue;
    }

    @State.action.bound
    public setDescription(newValue: string) {
        this.description = newValue;
    }

    @State.action.bound
    public setClinicalStatus(newValue: ConditionStatementClinicalStatus) {
        this.clinicalStatus = newValue;
    }
}
