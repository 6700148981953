import Di from "@Di";
import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import TemplateApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Templating/TemplateApiAdapter";
import TemplateId from "@Primitives/TemplateId.g";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import DocumentTypesApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/ReferenceData/DocumentTypesApiAdapter";
import ITemplateInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateInfo";

@Di.injectable()
export default class DocumentManagementReferenceDataStore extends ReferenceDataStoreBase {

    public template = new ReferenceDataStore<TemplateId, ITemplateInfo>(this.templateApiAdapter.getByIdsAsync);
    public documentTypeMap = new ReferenceDataStore(this.documentTypesApiAdapter.getDocumentTypesByIdsAsync, this.documentTypesApiAdapter.getAllDocumentTypeIdsAsync);

    constructor(
        @Di.inject("TemplateApiAdapter") private readonly templateApiAdapter: TemplateApiAdapter,
        @Di.inject("DocumentTypesApiAdapter") private readonly documentTypesApiAdapter: DocumentTypesApiAdapter
    ) {
        super();
    }

}
