import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import EhiCovidTestType from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/PatientRegister/Model/EhiCovidTestType";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IEhiCovidTestTypeColumnDependencies {
    localizationService: ILocalizationService;
}

interface IEhiCovidTestTypeColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IEhiCovidTestTypeColumnDependencies;
}


const EhiCovidTestTypeColumn: React.FC<IEhiCovidTestTypeColumnProps> = (props: IEhiCovidTestTypeColumnProps) => {

    const valueRenderer = (value: EhiCovidTestType) => {
        if (!isNullOrUndefined(value)) {
            return props._dependencies!.localizationService.localizeEnum(EhiCovidTestType[value], "EhiCovidTestType").Name;
        }
        return "";
    };

    return (
        <DataGridColumn
            onRenderCellValue={valueRenderer}
            {...props}
        />
    );
};

export default connect(
    EhiCovidTestTypeColumn,
    new DependencyAdapter<IEhiCovidTestTypeColumnProps, IEhiCovidTestTypeColumnDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService")
    }))
);