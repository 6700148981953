import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { Modal, Flex, Button, TextBox } from "@CommonControls";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import { IFormFieldModalParams, IFormFieldModalResult } from "./FormFieldModalParams";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IFormEngineApiAdapter from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import _ from "@HisPlatform/Common/Lodash";
import WorklistItemDefinitionStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/WorklistDefinition/WorklistItemDefinitionStore";
import AttributeReference from "@Primitives/AttributeReference.g";
import ItemVisibility from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/ItemVisibility.g";
import ResourceId from "@Primitives/ResourceId.g";
import FormFieldSelector from "@HisPlatform/BoundedContexts/FormEngine/Components/FormFieldSelector/FormFieldSelector";
import FormDefinitionDescriptor from "@Toolkit/FormEngine/Model/FormDefinitionDescriptor";
import IFormFieldReference from "@HisPlatform/BoundedContexts/FormEngine/Components/FormFieldSelector/IFormFieldReference";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import ItemPlacement from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/ItemPlacement.g";

interface IFormFieldModalDependencies {
    formEngineApiAdapter: IFormEngineApiAdapter;
    cultureCodeProvider: ICurrentCultureProvider;
}

export interface IFormFieldModalProps extends IModalComponentParams<IFormFieldModalResult>, IFormFieldModalParams {
    _dependencies?: IFormFieldModalDependencies;
}

interface IItem {
    value: IFormFieldReference;
    label: string;
    formDataElementType: string;
}

@State.observer
class FormFieldModal extends React.Component<IFormFieldModalProps> {

    @State.observable.ref private descriptors: FormDefinitionDescriptor[] = [];

    // @State.computed private get selectedFormDataElement() {

    //     const items = this.props.formField.attributeReference.value.split("_");
    //     const [formDefinitionName, formDataElementName] = items[1].split(".");

    //     return {
    //         formDataElementName: formDataElementName,
    //         formDefinitionName: formDefinitionName,
    //         schemaVersion: null
    //     } as IFormFieldReference;
    // }

    @State.observable.ref private formFieldReference: IFormFieldReference = null;
    @State.observable.ref private formDataElementDescription: string = null;
    private itemDefinition: WorklistItemDefinitionStore;

    public componentDidMount() {
        this.initialize();
    }

    @State.action
    private initialize() {
        let formBaseEntity: string;
        if (this.props.worklistDefinition.worklistType.value === "CareActivityBased") {
            formBaseEntity = "CareActivity";
        } else if (this.props.worklistDefinition.worklistType.value === "PatientBased") {
            formBaseEntity = "Patient";
        }

        this.itemDefinition = this.props.formField;

        if (this.props.formField?.attributeReference) {
            const items = this.props.formField.attributeReference.value.split("_");
            const [formDefinitionName, formDataElementName, formDataElementType] = items[1].split(".");

            this.formFieldReference = {
                formDefinitionName: formDefinitionName,
                formDataElementName: formDataElementName,
                formDataElementType: formDataElementType,
                schemaVersion: null
            } as IFormFieldReference;
        } else {
            this.formFieldReference = null;
        }
    }

    private getExtendedValue(value: IFormFieldReference): IItem {
        const descriptor = this.descriptors.find(d => d.formDefinitionName === value.formDefinitionName);
        const dataElement = descriptor?.formDataElementDescriptors.find(de => de.formDataElementName === value.formDataElementName);

        if (!dataElement) {
            return null;
        }

        return {
            value,
            formDataElementType: dataElement.formDataElementType,
            label: dataElement.multiLingualLabel.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.props._dependencies.cultureCodeProvider.cultureCode),
        } as IItem;
    }

    // @State.action.bound
    // private setDescriptors(descriptors: FormDefinitionDescriptorTreeNode[]) {
    //     this.formDefinitionDescriptors = descriptors;

    //     if (!isNullOrUndefined(this.props.formField)) {
    //         const formDataElementDescriptors = _.flatten(this.formDefinitionDescriptors.map(i => i.children as FormDataElementDescriptorTreeNode[]));

    //         const items = this.props.formField.attributeReference.value.split("_");
    //         const formItems = items[1].split(".");

    //         const foundDescriptor = formDataElementDescriptors.find(i =>
    //             i.parent.formDefinitionName === formItems[0] &&
    //             i.entry.formDataElementName === formItems[1]);

    //         if (isNullOrUndefined(foundDescriptor)) {
    //             return;
    //         }

    //         const parentDescriptor = this.formDefinitionDescriptors.find(i => i.entry.formDefinitionName === formItems[0]);
    //         parentDescriptor.isOpen = true;

    //         this.selectedFormDataElementDescriptor = {
    //             ...foundDescriptor
    //         };

    //         const resourceParts = this.props.formField.headerResourceId.value.split(".");
    //         this.formDataElementDescription = resourceParts[1];
    //     }
    // }

    @State.action.bound
    private setFormFieldReference(value: IFormFieldReference) {
        this.formFieldReference = value;

        if (isNullOrUndefined(this.formDataElementDescription)) {
            this.formDataElementDescription = this.getExtendedValue(this.formFieldReference).label;
        }
    }

    @State.action.bound
    private setFormDataElementDescription(value: string) {
        this.formDataElementDescription = value;
    }

    @State.action.bound
    private close() {
        this.props.onClose(null);
    }

    @State.action.bound
    private closeWithResult() {
        if (isNullOrUndefined(this.formFieldReference)) {
            this.close();
        }

        const extendedValue = this.getExtendedValue(this.formFieldReference);

        const formFieldDescriptor = extendedValue.value.formDefinitionName + "." + this.formFieldReference.formDataElementName;
        const attributeReference =
            new AttributeReference("FormField_" + formFieldDescriptor);
        const resourceId = new ResourceId("FormField." + formFieldDescriptor);

        const isSortingEnabled = extendedValue.formDataElementType !== "ReferencedEntity";

        if (isNullOrUndefined(this.itemDefinition)) {
            this.itemDefinition = new WorklistItemDefinitionStore(
                true,
                0,
                ItemVisibility.Master,
                ItemPlacement.Header,
                true,
                isSortingEnabled,
                "50px",
                true,
                attributeReference,
                resourceId,
                this.formDataElementDescription,
                this.formDataElementDescription,
                false);
        } else {
            this.itemDefinition.columnName = this.formDataElementDescription;
            this.itemDefinition.headerResourceId = resourceId;
            this.itemDefinition.attributeReference = attributeReference;
        }

        this.props.onClose({
            updatedFormField: this.itemDefinition
        });
    }

    public render() {
        return (
            <Modal
                title={StaticProductivityResources.Worklist.Definition.Modal.Title}
                closeButton
                onClose={this.close}
                isOpen
                size="tiny"
                closeOnOutsideClick={false}
                closeOnEscape={false}
            >
                <Modal.Body>
                    <Flex>
                        <Flex.Item xs={12}>
                            <FormFieldSelector
                                automationId="selectedFormDataElementDescriptor_SelectBox"
                                onLoaded={this.setDescriptors}
                                value={this.formFieldReference}
                                onChange={this.setFormFieldReference}
                            />
                        </Flex.Item>
                        <Flex.Item xs={12}>
                            <TextBox
                                label={StaticProductivityResources.Worklist.Definition.Modal.FieldDescription}
                                value={this.formDataElementDescription}
                                onChange={this.setFormDataElementDescription}
                                automationId="formDataElementDescription"
                            />
                        </Flex.Item>
                    </Flex>
                </Modal.Body>
                <Modal.Footer>
                    <Flex>
                        <Flex.Item sm={6}>
                            <Button
                                onClick={this.close}
                                visualStyle="link"
                                text={StaticProductivityResources.Worklist.Definition.Modal.Cancel}
                                automationId="closeButton"
                            />
                        </Flex.Item>
                        <Flex.Item sm={6}>
                            <Button
                                onClick={this.closeWithResult}
                                visualStyle="primary"
                                text={StaticProductivityResources.Worklist.Definition.Modal.Ok}
                                float="right"
                                automationId="closeWithResultButton"
                            />
                        </Flex.Item>
                    </Flex>
                </Modal.Footer>
            </Modal>
        );
    }

    @State.action.bound
    private setDescriptors(descriptors: FormDefinitionDescriptor[]) {
        this.descriptors = descriptors;
    }
}

export default connect(
    FormFieldModal,
    new DependencyAdapter<IFormFieldModalProps, IFormFieldModalDependencies>(c => {
        return {
            formEngineApiAdapter: c.resolve<IFormEngineApiAdapter>("IFormEngineApiAdapter"),
            cultureCodeProvider: c.resolve<ICurrentCultureProvider>("ICurrentCultureProvider")
        };
    })
);