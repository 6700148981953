import {IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer} from "./IDataGridColumnProps";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import DataGridColumn from "./DataGridColumn";
import React, {useCallback} from "react";
import MomentRange from "@Toolkit/CommonWeb/Model/MomentRange";
import {RowId} from "@CommonControls/DataGrid/IDataGridProps";
import {DateRangePicker} from "@CommonControls";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

interface IDataGridDateTimeRangeColumnDependencies {
    toolkitLocalizationService: IToolkitLocalizationService;
}

type IDataGridDateTimeColumnProps =
    IDataGridColumnBaseProps
    & {
    _dependencies?: IDataGridDateTimeRangeColumnDependencies;
    isClearable?: boolean;
};


const DataGridDateTimeRangeColumn: React.FC<IDataGridDateTimeColumnProps> = (props) => {

    const {children, ...columnProps} = props;

    const valueRenderer = useCallback((value: MomentRange, row: any, rowId: RowId, rowIndex: number, isUnderEditing: boolean, dataSetter: (newValue: LocalDateRange, index?: number) => void) => {
        const localizer = props._dependencies.toolkitLocalizationService;
        const from = value?.from ? localizer.localizeDateTime(value.from, false, false, true) : "";
        const to = value?.to ? localizer.localizeDateTime(value.to, false, false, true) : "";
        const dateRange = isUnderEditing && new LocalDateRange(LocalDate.createFromMoment(value?.from), LocalDate.createFromMoment(value?.to));
        return isUnderEditing ? (
                <DateRangePicker
                    value={dateRange}
                    onChange={dataSetter}
                    closeOnSelect
                    automationId="filterPicker"
                />)
            : from + "\xa0- " + to;
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<LocalDateRange>) => {
        return <DateRangePicker {...filterProps} hoverOnlyIndicatorIcons />;
    }, []);

    const filterValueSerializer: IDataGridFilterValueSerializer<LocalDateRange> = {
        serialize: value => JSON.stringify(value),
        deserialize: value => LocalDateRange.parse(value)
    };

    return (
        <DataGridColumn
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
            showHint={false}
        />
    );
};

export default connect(
    DataGridDateTimeRangeColumn,
    new DependencyAdapter<IDataGridDateTimeColumnProps, IDataGridDateTimeRangeColumnDependencies>(container => {
        return {
            toolkitLocalizationService: container.resolve("IToolkitLocalizationService")
        };
    })
);
