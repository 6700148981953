import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IUserMultiSelectDialogParams, IUserMultiSelectDialogResult } from "./UserMultiSelectDialogParams";
import UserId from "@Primitives/UserId.g";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import UserMultiSelector from "@HisPlatform/BoundedContexts/UserManagement/Components/Controls/Users/UserMultiSelector/UserMultiSelector";
import StaticAuthorizationResources from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";

interface IUserMultiSelectDialogProps extends IModalComponentParams<IUserMultiSelectDialogResult>, IUserMultiSelectDialogParams {
}

/** @screen */
@State.observer
export default class UserMultiSelectDialog extends React.Component<IUserMultiSelectDialogProps> {

    @State.observable.ref public userIds: UserId[] = null;
    
    @State.action.bound
    public setUserIds(userIds: UserId[]) {
        this.userIds = userIds;
    }

    public componentDidMount() {
        this.setUserIds(this.props.alreadySelectedIds);
    }

    public componentDidUpdate(prevProps: IUserMultiSelectDialogProps) {
        if (prevProps.alreadySelectedIds !== this.props.alreadySelectedIds) {
            this.setUserIds(this.props.alreadySelectedIds);
        }
    }

    @State.bound
    private onClose() {
        this.props.onClose({selectedUserIds: this.userIds});
    }

    public render() {
        return (
            <HisUi.ContextAwareModal
                closeOnOutsideClick
                title={this.props.title}
                automationId="__selectUsersModal"
                onClose={this.onClose}
                isOpen
            >
                <Ui.Modal.Body>
                    <UserMultiSelector
                        selectedIds={this.userIds}
                        onChange={this.setUserIds}
                        addButtonText={StaticAuthorizationResources.Common.Button.Select}
                        removeButtonText={StaticAuthorizationResources.Common.Button.Remove}
                        currentUserId={this.props.currentUserId}
                    />
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Modal.Button text={StaticAuthorizationResources.Common.Button.Close} closeModalOnClick float="right" automationId="__closeButton" />
                </Ui.Modal.Footer>
            </HisUi.ContextAwareModal>
        );
    }
}