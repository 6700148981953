import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import HunCareReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/HunCareReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";

interface IContagiousPatientReportOutcomeTypeSelectBoxDependencies {
    careReferenceDataStore: HunCareReferenceDataStore;

}

interface IContagiousPatientReportOutcomeTypeSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IContagiousPatientReportOutcomeTypeSelectBoxDependencies;
}

@State.observer
class ContagiousPatientReportOutcomeTypeSelectBox extends React.Component<IContagiousPatientReportOutcomeTypeSelectBoxProps> {
    private get dependencies() {
        return this.props._dependencies;
    }

    private get extensibleEnumStore() {
        return this.dependencies.careReferenceDataStore.contagiousPatientReportOutcomeTypes;
    }
    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    ContagiousPatientReportOutcomeTypeSelectBox,
    new DependencyAdapter<IContagiousPatientReportOutcomeTypeSelectBoxProps, IContagiousPatientReportOutcomeTypeSelectBoxDependencies>(c => ({
        careReferenceDataStore: c.resolve("HunCareReferenceDataStore")
    }))
);