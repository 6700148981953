import React from "react";
import * as Ui from "@CommonControls";
import AppointmentScheduleDefinition from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentScheduleDefinition";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PlanningPeriod from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/PlanningPeriod";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import EditPlanningPeriodModalParams, { IEditPlanningPeriodModalResult } from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/EditPlanningPeriodModal/EditPlanningPeriodModalParams";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import _ from "@HisPlatform/Common/Lodash";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import TimePhaseId from "@Primitives/TimePhaseId.g";
import moment from "moment";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import TimePhaseType from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseType";

interface IAppointmentScheduleDefinitionPlanningPeriodToolBarPanelDependencies {
    localizationService: ILocalizationService;
}

interface IAppointmentScheduleDefinitionPlanningPeriodToolBarPanelProps {
    _dependencies?: IAppointmentScheduleDefinitionPlanningPeriodToolBarPanelDependencies;
    _modalService?: IModalService;
    store: AppointmentScheduleDefinition;
    onValidateAsync: () => Promise<IClientValidationResult[]>;
}

@State.observer
class AppointmentScheduleDefinitionPlanningPeriodToolBarPanel extends React.Component<IAppointmentScheduleDefinitionPlanningPeriodToolBarPanelProps> {
    private get localizationService() {
        return this.props._dependencies.localizationService;
    }

    @State.computed
    private get nonDeletedPlanningPeriods() {
        return this.props.store.planningPeriods.filter(item => !item.isDeleted);
    }

    @State.computed
    private get selectBoxItems() {
        const res = State.observable(this.nonDeletedPlanningPeriods.map(item => ({
            value: item,
            text: `${this.localizationService.localizeDate(item.interval?.from)} - ${this.localizationService.localizeDate(item.interval?.to)}`
        }) as ISelectBoxItem));
        return res;
    }

    @State.bound
    private async createAsync() {
        const newItem = new PlanningPeriod();
        newItem.takeSnapshot();
        this.props.store.addPlanningPeriod(newItem);
        const result = await this.showEditPlanningPeriodModalAsync(newItem);
        if (result.result !== "save") {
            this.props.store.removePlanningPeriod(newItem);
        } else {
            this.props.store.setSelectedPlanningPeriod(newItem);
        }
    }

    @State.action.bound
    private async updateAsync() {
        const original = _.cloneDeep(this.props.store?.selectedPlanningPeriod);
        const result = await this.showEditPlanningPeriodModalAsync(this.props.store.selectedPlanningPeriod);
        State.runInAction(() => {

            if (result.result === "delete") {
                this.props.store.selectedPlanningPeriod.delete();
                this.props.store.setSelectedPlanningPeriod(_.nth(this.nonDeletedPlanningPeriods, -1));
            } else if (result.result === "cancel") {
                this.props.store.selectedPlanningPeriod.setInterval(original.interval);
            }
        });
    }

    @State.bound
    private async copyPlanningPeriodAsync() {
        const unixTimesForIntervalEnds = this.props.store?.planningPeriods.map(i => i.interval.to.toUnix());
        const lastIntervalEndUnixTime = Math.max(...unixTimesForIntervalEnds);
        const lastIntervalEndDate = LocalDate.createFromMoment(moment.unix(lastIntervalEndUnixTime));

        const copy = _.cloneDeep(this.props.store?.selectedPlanningPeriod);
        copy.id = null;
        copy.setInterval(new LocalDateRange(lastIntervalEndDate.plusDays(1), lastIntervalEndDate.plusMonths(12)));

        let temporaryTimePhaseId = -100;
        copy.timePhases = copy.timePhases.filter(i => i.type === TimePhaseType.Recurring);
        copy.timePhases.forEach(i => {
            i.isNew = true;
            i.id = new TimePhaseId((temporaryTimePhaseId--).toString());
            i.takeSnapshot();
        });

        copy.takeSnapshot();
        this.props.store.addPlanningPeriod(copy);

        const result = await this.showEditPlanningPeriodModalAsync(copy, true);
        if (result.result !== "save") {
            this.props.store.removePlanningPeriod(copy);
        } else {
            this.props.store.setSelectedPlanningPeriod(copy);
        }
    }

    @State.bound
    private async showEditPlanningPeriodModalAsync(itemToEdit: PlanningPeriod, isCopy: boolean = false) {
        return await this.props._modalService.showDialogAsync<IEditPlanningPeriodModalResult>(new EditPlanningPeriodModalParams(itemToEdit, isCopy, this.props.store.validationResults, this.props.onValidateAsync));
    }

    @State.bound
    private update() {
        if (this.props.store.selectedPlanningPeriod) {
            dispatchAsyncErrors(this.updateAsync(), this);
        }
    }

    @State.bound
    private create() {
        dispatchAsyncErrors(this.createAsync(), this);
    }

    @State.action.bound
    private delete() {
        if (this.props.store.selectedPlanningPeriod) {
            this.props.store.selectedPlanningPeriod.delete();
            this.props.store.setSelectedPlanningPeriod(_.nth(this.nonDeletedPlanningPeriods, -1));
        }
    }

    public render() {
        return (
            <ValidationBoundary
                validationResults={this.props.store?.validationResults || []}
                entityTypeName={"AppointmentScheduleDefinition"}
                onValidateAsync={this.props.onValidateAsync}
            >
                <Ui.Flex style={{ marginTop: "0px" }}>
                    <Ui.Flex.Item>
                        <Ui.SelectBox
                            items={this.selectBoxItems}
                            value={this.props.store?.selectedPlanningPeriod}
                            onChange={this.props.store?.setSelectedPlanningPeriod}
                            size="compact"
                            newItemText={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.PlanningPeriod.AddNewText}
                            onNewItemClick={this.create}
                            clearable={false}
                            propertyIdentifier="PlanningPeriods"
                            automationId="planningPeriods"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item>
                        <Ui.Button iconName="pen" onClick={this.update} automationId="updateButton" />
                        <Ui.Button
                            iconName="documentsPlus"
                            automationId="__copyButton"
                            tooltipContent={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.PlanningPeriod.Copy}
                            disabled={!this.props.store?.selectedPlanningPeriod}
                            onClickAsync={this.copyPlanningPeriodAsync} />
                        <Ui.Button iconName="trash" visualStyle="negative-standard" onClick={this.delete} automationId="deleteButton" />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </ValidationBoundary>
        );
    }
}

export default connect(
    AppointmentScheduleDefinitionPlanningPeriodToolBarPanel,
    new DependencyAdapter<IAppointmentScheduleDefinitionPlanningPeriodToolBarPanelProps, IAppointmentScheduleDefinitionPlanningPeriodToolBarPanelDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService")
    })),
    new HisModalServiceAdapter()
);
