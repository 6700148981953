import CareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CareActivityStore";
import CareActivityDischargeDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityDischargeData/CareActivityDischargeDataStore";
import PatientAllergyIntoleranceListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceListStore";
import CareActivityBaseData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareActivityBaseData";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";

export default class CareAndPatientContextData {
    constructor(
        public readonly patient: PatientAdministrativeData,
        public readonly patientAllergyIntoleranceList: PatientAllergyIntoleranceListStore,
        public readonly careActivity: CareActivityStore,
        public readonly baseData: CareActivityBaseData,
        public readonly dischargeData: CareActivityDischargeDataStore
    ) {}
}