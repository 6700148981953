/* auto-inject-disable */
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import { GetTypeNameWithPrefix } from "@HunSocialSecurityPlugin/PluginHelper";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/Proxy.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import _ from "@HisPlatform/Common/Lodash";
import CareActivityId from "@Primitives/CareActivityId.g";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import PerformanceStatementCareActivity from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/PerformanceStatementCareActivity";
import PerformanceStatementCareActivityStoreMapper from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter//PerformanceStatementCareActivityStoreMapper";
import PerformanceStatementCareActivityStatus from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/PerformanceStatementCareActivityStatus";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";
import LargeDataToken from "@Primitives/LargeDataToken.g";
import PerformanceStatementCareActivityStatusType from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementCareActivityStatusType.g";

@Di.injectable()
export default class PerformanceStatementCareActivityApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("IPerformanceStatementClient")) private readonly apiClient: Proxy.IPerformanceStatementClient,
        @Di.inject("PerformanceStatementCareActivityStoreMapper") private readonly performanceStatementCareActivityStoreMapper: PerformanceStatementCareActivityStoreMapper
    ) {
        super();
    }

    @State.bound
    public CreatePerformanceStatementCareActivityPdfAsync(careActivityId: CareActivityId, isPermissionCheckOnly?: boolean): Promise<SimpleStore<LargeDataToken>> {
        return this.processOperationAsync(
            new SimpleStore<LargeDataToken>(),
            async target => {
                const response = await this.apiClient.createPerformanceStatementCareActivityExcelCommandAsync(
                    CreateRequestId(),
                    new Proxy.CreatePerformanceStatementCareActivityExcelControllerDto({
                        careActivityId: careActivityId
                    }),
                    isPermissionCheckOnly
                );
                
                target.operationInfo = createOperationInfo(response);
                target.value = response.contentId;
            }
        );
    }

    @State.bound
    public CreateEuPerformanceStatementCareActivityPdfAsync(careActivityId: CareActivityId): Promise<SimpleStore<LargeDataToken>> {
        return this.processOperationAsync(
            new SimpleStore<LargeDataToken>(),
            async target => {
                const response = await this.apiClient.createEuPerformanceStatementCareActivityExcelCommandAsync(
                    CreateRequestId(),
                    new Proxy.CreateEuPerformanceStatementCareActivityExcelControllerDto({
                        careActivityId: careActivityId
                    })
                );
                
                target.operationInfo = createOperationInfo(response);
                target.value = response?.contentId;
            }
        );
    }

    @State.bound
    public getPerformanceStatementCareActivityByIdAsync(careActivityId: CareActivityId) {
        return this.processOperationAsync(
            new PerformanceStatementCareActivity(),
            async target => {
                const response = await this.apiClient.getPerformanceStatementCareActivityByIdQueryAsync(
                    CreateRequestId(),
                    careActivityId.value
                );

                this.performanceStatementCareActivityStoreMapper.applyToStore(target, response.performanceStatementCareActivityDto);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public setPerformanceStatementCareActivityManualStatusAsync(careActivityId: CareActivityId, rowVersion: RowVersion, reportValidityDate: YearMonth) {
        return this.processOperationAsync(
            new PerformanceStatementCareActivity(),
            async target => {
                const response = await this.apiClient.setPerformanceStatementCareActivityManualStatusCommandAsync(
                    CreateRequestId(),
                    new Proxy.SetPerformanceStatementCareActivityManualStatusControllerDto({
                        careActivityId: careActivityId,
                        rowVersion: rowVersion,
                        reportValidityDate: reportValidityDate
                    })
                );

                if (response.isPersisted) {
                    this.performanceStatementCareActivityStoreMapper.applyToStore(target, response.performanceStatementCareActivity);
                }
                
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);              
            }
        );
    }

    @State.bound
    public validatePerformanceStatementCareActivityManualStatusAsync(careActivityId: CareActivityId, rowVersion: RowVersion, reportValidityDate: YearMonth) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const result = await this.apiClient.setPerformanceStatementCareActivityManualStatusCommandAsync(
                    CreateRequestId(),
                    new Proxy.SetPerformanceStatementCareActivityManualStatusControllerDto({
                        careActivityId: careActivityId,
                        rowVersion: rowVersion,
                        reportValidityDate: reportValidityDate,
                        isValidateOnly: true
                    })
                );

                target.operationInfo = createOperationInfo(result);
                target.value = mapValidationResults(result.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    @State.bound
    public setPerformanceStatementCareActivityManualEuStatusAsync(careActivityId: CareActivityId, rowVersion: RowVersion, reportValidityDate: YearMonth, type: PerformanceStatementCareActivityStatusType) {
        return this.processOperationAsync(
            new PerformanceStatementCareActivity(),
            async target => {
                const response = await this.apiClient.setPerformanceStatementCareActivityManualEuStatusCommandAsync(
                    CreateRequestId(),
                    new Proxy.SetPerformanceStatementCareActivityManualEuStatusControllerDto({
                        careActivityId: careActivityId,
                        rowVersion: rowVersion,
                        reportValidityDate: reportValidityDate,
                        type: type
                    })
                );

                if (response.isPersisted) {
                    this.performanceStatementCareActivityStoreMapper.applyToStore(target, response.performanceStatementCareActivity);
                }
                
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);              
            }
        );
    }

    @State.bound
    public validatePerformanceStatementCareActivityManualEuStatusAsync(careActivityId: CareActivityId, rowVersion: RowVersion, reportValidityDate: YearMonth, type: PerformanceStatementCareActivityStatusType) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const result = await this.apiClient.setPerformanceStatementCareActivityManualEuStatusCommandAsync(
                    CreateRequestId(),
                    new Proxy.SetPerformanceStatementCareActivityManualEuStatusControllerDto({
                        careActivityId: careActivityId,
                        rowVersion: rowVersion,
                        reportValidityDate: reportValidityDate,
                        type: type,
                        isValidateOnly: true
                    })
                );

                target.operationInfo = createOperationInfo(result);
                target.value = mapValidationResults(result.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    @State.bound
    public setPerformanceStatementCareActivityManualNeakStatusAndMonthAsync(careActivityId: CareActivityId, rowVersion: RowVersion, reportValidityDate: YearMonth, type: PerformanceStatementCareActivityStatusType) {
        return this.processOperationAsync(
            new PerformanceStatementCareActivity(),
            async target => {
                const response = await this.apiClient.setPerformanceStatementCareActivityManualNeakStatusAndMonthCommandAsync(
                    CreateRequestId(),
                    new Proxy.SetPerformanceStatementCareActivityManualNeakStatusAndMonthControllerDto({
                        careActivityId: careActivityId,
                        rowVersion: rowVersion,
                        reportValidityDate: reportValidityDate,
                        type: type
                    })
                );

                if (response.isPersisted) {
                    this.performanceStatementCareActivityStoreMapper.applyToStore(target, response.performanceStatementCareActivity);
                }
                
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);              
            }
        );
    }

    @State.bound
    public validatePerformanceStatementCareActivityManualNeakStatusAndMonthAsync(careActivityId: CareActivityId, rowVersion: RowVersion, reportValidityDate: YearMonth, type: PerformanceStatementCareActivityStatusType) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const result = await this.apiClient.setPerformanceStatementCareActivityManualNeakStatusAndMonthCommandAsync(
                    CreateRequestId(),
                    new Proxy.SetPerformanceStatementCareActivityManualNeakStatusAndMonthControllerDto({
                        careActivityId: careActivityId,
                        rowVersion: rowVersion,
                        reportValidityDate: reportValidityDate,
                        type: type,
                        isValidateOnly: true
                    })
                );

                target.operationInfo = createOperationInfo(result);
                target.value = mapValidationResults(result.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    @State.bound
    public getPerformanceStatementCareActivityStatusHistoryByIdAsync(careActivityId: CareActivityId) {
        return this.processOperationAsync(
            new SimpleStore<PerformanceStatementCareActivityStatus[]>(),
            async target => {
                const response = await this.apiClient.getNeakPerformanceStatementCareActivityStatusHistoryByIdQueryAsync(
                    CreateRequestId(),
                    careActivityId.value
                );

                target.value = response.performanceStatementCareActivityStatusHistory.map(i =>
                    this.performanceStatementCareActivityStoreMapper.mapPerformanceStatementCareActivityStatus(i)
                );
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getEuPerformanceStatementCareActivityStatusHistoryByIdAsync(careActivityId: CareActivityId) {
        return this.processOperationAsync(
            new SimpleStore<PerformanceStatementCareActivityStatus[]>(),
            async target => {
                const response = await this.apiClient.getEuPerformanceStatementCareActivityStatusHistoryByIdQueryAsync(
                    CreateRequestId(),
                    careActivityId.value
                );

                target.value = response.performanceStatementCareActivityStatusHistory.map(i =>
                    this.performanceStatementCareActivityStoreMapper.mapPerformanceStatementCareActivityStatus(i)
                );
                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}