import { useMemo, useCallback } from "react";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { ConstructorType } from "@Toolkit/CommonWeb/Reflection/Reflection";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import { useFormPanelStore } from "./FormPanel/FormPanelStoreProvider";
import ReferencedEntityArrayFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityArrayFormFieldData";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

export function useFormExtensibleEnumIdField<TId extends IStringEntityId>(formDataContent: any, entityPath: string, idCtor: ConstructorType<IStringEntityId>): [TId, (id: TId) => void] {
    const formPanelStore = useFormPanelStore();

    const field = useMemo(() => getField(formDataContent, entityPath) as any, [formDataContent, entityPath]);

    const value = useMemo(() => isNullOrUndefined(field?.value) ? null : new idCtor(field.value), [field?.value]);
    const valueSetter = useCallback(State.action((id: IStringEntityId) => {
        field.value = isNullOrUndefined(id) ? null : id.value;
        formPanelStore.props.onChange?.(entityPath, field.value, "set");
    }), [formDataContent, formPanelStore.props.onChange]);

    return [value as TId, valueSetter];
}

export function useFormTextField(formDataContent: any, entityPath: string): [string, (value: string) => void] {
    const formPanelStore = useFormPanelStore();

    const field = useMemo(() => getField(formDataContent, entityPath) as any, [formDataContent, entityPath]);

    const value = useMemo(() => field?.value, [field?.value]);
    const valueSetter = useCallback(State.action((textValue: string) => {
        field.value = textValue;
        formPanelStore.props.onChange?.(entityPath, field.value, "set");
    }), [formDataContent, formPanelStore.props.onChange]);

    return [value as string, valueSetter];
}

export function useFormCompositeArrayField(formDataContent: any, entityPath: string): IObservableArray<object> {
    const field = useMemo(() => getField(formDataContent, entityPath) as any, [formDataContent, entityPath]);
    const value = useMemo(() => field?.value, [field?.value]);
    return value;
}

export function useFormBooleanField(formDataContent: any, entityPath: string): [boolean, (value: boolean) => void] {
    const formPanelStore = useFormPanelStore();

    const field = useMemo(() => getField(formDataContent, entityPath) as any, [formDataContent, entityPath]);

    const value = useMemo(() => field?.value, [field?.value]);
    const valueSetter = useCallback(State.action((booleanValue: boolean) => {
        field.value = booleanValue;
        formPanelStore.props.onChange?.(entityPath, field.value, "set");
    }), [formDataContent, formPanelStore.props.onChange]);

    return [value as boolean, valueSetter];
}

export function useFormDateField(formDataContent: any, entityPath: string): [LocalDate, (value: LocalDate) => void] {
    const formPanelStore = useFormPanelStore();

    const field = useMemo(() => getField(formDataContent, entityPath) as any, [formDataContent, entityPath]);

    const value = useMemo(() => field?.value, [field?.value]);
    const valueSetter = useCallback(State.action((dateValue: LocalDate) => {
        if (!isNullOrUndefined(field)) {
            field.value = dateValue;
        }
        formPanelStore.props.onChange?.(entityPath, field.value, "set");
    }), [formDataContent, formPanelStore.props.onChange]);

    return [value as LocalDate, valueSetter];
}

export function useFormEntityIdField<TId extends IStringEntityId>(formDataContent: any, entityPath: string, idCtor: ConstructorType<IStringEntityId>): [TId, (id: TId) => void] {
    const formPanelStore = useFormPanelStore();

    const field = useMemo(() => getField(formDataContent, entityPath) as any, [formDataContent, entityPath]);

    const value = useMemo(() => isNullOrUndefined(field?.value) ? null : new idCtor(typeof field.value === "string" ? field.value : field.value.toString()), [field?.value]);
    const valueSetter = useCallback(State.action((id: IStringEntityId) => {
        field.value = isNullOrUndefined(id) ? null : parseInt(id.value, 10);
        formPanelStore.props.onChange?.(entityPath, field.value, "set");
    }), [formDataContent, formPanelStore.props.onChange]);

    return [value as TId, valueSetter];
}

export function useFormEntityIdArrayField<TId extends IStringEntityId>(formDataContent: any, entityPath: string, idCtor: ConstructorType<IStringEntityId>): [TId[], (ids: TId[]) => void] {
    const formPanelStore = useFormPanelStore();

    const field = useMemo(() => getField<ReferencedEntityArrayFormFieldData>(formDataContent, entityPath), [formDataContent, entityPath]);

    const value = useMemo(() => field?.value.map(i => {
        return isNullOrUndefined(i) ? null : new idCtor(typeof i === "string" ? i : i.toString());
    }), [field?.value]);
    const valueSetter = useCallback(State.action((ids: IStringEntityId[]) => {
        field.value = isNullOrUndefined(ids) ? [] : ids?.map(id => isNullOrUndefined(id) ? null : parseInt(id.value, 10));
        formPanelStore.props.onChange?.(entityPath, field.value, "set");
    }), [formDataContent, formPanelStore.props.onChange]);

    return [value as TId[], valueSetter];
}

export function useFormEntityIdentifierField(formDataContent: any, entityPath: string): [Identifier, (identifier: Identifier) => void] {
    const formPanelStore = useFormPanelStore();

    const field = useMemo(() => getField(formDataContent, entityPath) as any, [formDataContent, entityPath]);

    const value = useMemo(() => {
        if (isNullOrUndefined(field?.value)) { return null; }

        const identifierParts = (field.value as string).split("|");
        return new Identifier(new IdentifierSystemId(identifierParts[0]), identifierParts[1]);
    }, [field?.value]);

    const valueSetter = useCallback(State.action((id: Identifier) => {
        field.value = isNullOrUndefined(id) ? null : `${id.identifierSystemId.value}|${id.value}`;
        formPanelStore.props.onChange?.(entityPath, field.value, "set");
    }), [formDataContent, formPanelStore.props.onChange]);

    return [value, valueSetter];
}


export function getEntityFieldValue<TId extends IStringEntityId>(formDataContent: any, entityPath: string, idCtor: ConstructorType<IStringEntityId>): TId {
    const field = getField(formDataContent, entityPath) as any;
    const value = isNullOrUndefined(field.value) ? null : new idCtor(typeof field.value === "string" ? field.value : field.value.toString());

    return value as TId;
}

export function getExtensibleEnumFieldValue<TId extends IStringEntityId>(formDataContent: any, entityPath: string, idCtor: ConstructorType<IStringEntityId>) {
    const field = getField(formDataContent, entityPath) as any;
    const value = isNullOrUndefined(field?.value) ? null : new idCtor(field.value);

    return value as TId;
}

export function getDateFieldValue(formDataContent: any, entityPath: string) : LocalDate {
    const field = getField(formDataContent, entityPath) as any;
    return field?.value;
}

export function getTextFieldValue(formDataContent: any, entityPath: string) : string {
    const field = getField(formDataContent, entityPath) as any;
    return field?.value;
}

export function getBooleanFieldValue(formDataContent: any, entityPath: string) : boolean {
    const field = getField(formDataContent, entityPath) as any;
    return field?.value;
}

export function getNumberFieldValue(formDataContent: any, entityPath: string) : number {
    const field = getField(formDataContent, entityPath) as any;
    return field?.value;
}