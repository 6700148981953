import ClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidator";
import StaticHunEHealthInfrastructureWebAppResources from "@HunEHealthInfrastructurePlugin/StaticResources/StaticHunEHealthInfrastructureWebAppResources";

export default class EhiOrganizationUnitSelectorValidator extends ClientSideValidator {

    constructor() {
        super({
            OrganizationUnitSelector: {
                organizationUnit: {
                    isRequired: true
                }
            }
        }, { shouldBeFilled: StaticHunEHealthInfrastructureWebAppResources.Validator.ShouldBeFilled }, true);
        
    }
}