import ConditionId from "@Primitives/ConditionId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ConditionAssignmentBase from "./ConditionAssignmentBase";

export default class ReferencedConditionAssignment extends ConditionAssignmentBase  {
    @State.observable.ref public conditionId: ConditionId = null;

    constructor(conditionId: ConditionId) {
        super();
        this.conditionId = conditionId;
    }
}