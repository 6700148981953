import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import WeekParity from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/WeekParity";
import React from "react";

interface IWeekParitySelectorProps extends IUniversalEnumSelectorBaseProps {
    value?: WeekParity;
    onChange?: (newValue: WeekParity) => void;
}


const WeekParitySelector: React.FC<IWeekParitySelectorProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumOrigin="client"
        enumName={"WeekParity"}
        enumType={WeekParity}
    />
);

export default WeekParitySelector;