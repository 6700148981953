
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import FormFieldDependencyDialogStore from "./FormFieldDependencyDialogStore";

const { 
    ContextComponent: FormFieldDependencyDialogStoreContext, 
    StoreProvider: FormFieldDependencyDialogStoreProvider, 
    useStore: useFormFieldDependencyDialogStore,
    provideStore: provideFormFieldDependencyDialogStore,
} = createPanelStoreProvider(FormFieldDependencyDialogStore);

export {
    FormFieldDependencyDialogStoreContext,
    FormFieldDependencyDialogStoreProvider,
    useFormFieldDependencyDialogStore,
    provideFormFieldDependencyDialogStore
};