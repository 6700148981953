import React, { useMemo, useCallback } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import { TextBox, NumberBox, DateTimePicker, SelectBox, CheckBox } from "@CommonControls";
import StaticFormEngineResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import moment from "moment";
import TokenDefaultValue from "@Toolkit/FormEngine/Model/Layout/TokenDefaultValue";
import FormFieldSelector from "@HisPlatform/BoundedContexts/FormEngine/Components/FormFieldSelector/FormFieldSelector";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import IFormFieldReference from "@HisPlatform/BoundedContexts/FormEngine/Components/FormFieldSelector/IFormFieldReference";
import { ITokenDefaultValueOption } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStore";
import StringFormDataElement from "@Toolkit/FormEngine/Model/Schema/StringFormDataElement";
import NumberFormDataElement from "@Toolkit/FormEngine/Model/Schema/NumberFormDataElement";
import DateTimeFormDataElement from "@Toolkit/FormEngine/Model/Schema/DateTimeFormDataElement";
import BooleanFormDataElement from "@Toolkit/FormEngine/Model/Schema/BooleanFormDataElement";

interface ITokenDefaultValueSelectBoxItem extends ISelectBoxItem {
    store: ITokenDefaultValueOption;
}

function DefaultValueEditor() {
    const store = useFormLayoutEditorStore();

    const tokenDefaultValueItems = useMemo(() => store.tokenDefaultValues.map(t => ({
        text: t.displayName,
        value: `${t.symbol}_${t.displayAttributePath}`,
        store: t
    } as ITokenDefaultValueSelectBoxItem)), [store.tokenDefaultValues]);

    const selectedTokenDefaultValue = useMemo(() => {
        if (!(store.selectedEditor.defaultValue instanceof TokenDefaultValue)) {
            return null;
        }
        return `${store.selectedEditor.defaultValue.symbol}_${store.selectedEditor.defaultValue.displayAttributePath}`;

    }, [store.selectedEditor.defaultValue]);

    const selectedFormField = useMemo(() => store.selectedEditor.defaultValue instanceof TokenDefaultValue
        ? {
            formDefinitionName: store.selectedEditor.defaultValue.providerParameterSettings?.FormDefinitionName,
            formDataElementName: store.selectedEditor.defaultValue.providerParameterSettings?.FormDataElementName,
            formDataElementType: store.selectedEditor.defaultValue.providerParameterSettings?.FormDataElementType,
            schemaVersion: store.selectedEditor.defaultValue.providerParameterSettings?.SchemaVersion,
        } as IFormFieldReference
        : null, [store.selectedEditor.defaultValue]);

    const definitionIdsToExclude = useMemo(() => [store.id], [store.id]);

    const setDefaultTokenValue = useCallback((strVal: string) => {
        const tdvi = tokenDefaultValueItems.find(t => t.value === strVal)?.store;

        if (!!tdvi) {
            store.selectedEditor.setDefaultValue(new TokenDefaultValue(tdvi.symbol, tdvi.displayAttributePath, null));
        }
    }, [store.selectedEditor, tokenDefaultValueItems]);

    if (store.selectedEditor.defaultValue instanceof TokenDefaultValue) {
        return (
            <>
                <SelectBox
                    label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.TokenDefaultValueLabel}
                    value={selectedTokenDefaultValue}
                    items={tokenDefaultValueItems}
                    onChange={setDefaultTokenValue}
                    automationId="EditorDefaultValue_TokenSymbol" />

                {store.selectedEditor.defaultValue.symbol === "DynamicFormDataElement" && (
                    <FormFieldSelector
                        label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.FormTokenDefaultValueLabel}
                        automationId="SelectedFormField_SelectBox"
                        onChange={store.selectFormFieldDefaultValueReference}
                        value={selectedFormField}
                        excludedFormDefinitionIds={definitionIdsToExclude}
                    />
                )}
            </>);
    }

    if (store.selectedDataElement instanceof BooleanFormDataElement) {
        return (
            <CheckBox
                label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.DefaultValueLabel}
                value={store.selectedDataElement.defaultValue as boolean}
                onChange={store.selectedDataElement.setDefaultValue}
                automationId="EditorDefaultValue_CheckBox"
            />
        );
    }

    if (store.selectedDataElement instanceof StringFormDataElement) {
        return (
            <TextBox
                label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.DefaultValueLabel}
                value={store.selectedDataElement.defaultValue as string}
                onChange={store.selectedDataElement.setDefaultValue}
                automationId="EditorDefaultValue_TextBox"
            />
        );
    }

    if (store.selectedDataElement instanceof NumberFormDataElement) {
        return (
            <NumberBox
                label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.DefaultValueLabel}
                value={store.selectedDataElement.defaultValue as number}
                onChange={store.selectedDataElement.setDefaultValue}
                automationId="EditorDefaultValue_NumberBox"
            />
        );
    }

    if (store.selectedDataElement instanceof DateTimeFormDataElement) {
        return (
            <DateTimePicker
                label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.DefaultValueLabel}
                value={store.selectedDataElement.defaultValue as moment.Moment}
                onChange={store.selectedDataElement.setDefaultValue}
                automationId="EditorDefaultValue_DateTimePicker"
            />
        );
    }

    return null;
}

export default State.observer(DefaultValueEditor);