import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import { UniversalEnumSelector } from "@CommonControls";
import ServiceRequestState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestState.g";

interface IServiceRequestStatusSelectBoxProps extends ISelectBoxBaseProps {
    value?: ServiceRequestState;
    onChange?: (newValue: ServiceRequestState) => void;
}


const ServiceRequestStatusSelectBox: React.FC<IServiceRequestStatusSelectBoxProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumName={"ServiceRequestState"}
        enumOrigin="server"
        enumType={ServiceRequestState}
        displayMode="selectBox"
    />
);

export default ServiceRequestStatusSelectBox;