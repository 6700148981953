import React from "react";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IModalProps } from "@CommonControls/Modal";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";

interface IContextAwareModalProps extends IModalProps {
    _showHeader?: boolean;
    children?: React.ReactNode;
}

const renderHeader = () => {
    return (
        <HisUi.OutpatientHeader
            showReducedData
            isInModal />
    );
};


const ContextAwareModal: React.SFC<IContextAwareModalProps> = (props) => {

    return State.useObserver(() => (
        <Ui.Modal
            {...props}
            renderContextHeader={props._showHeader ? renderHeader : undefined}
            closeButton={!props._showHeader && props.closeButton}
        />
    ));
};

export default connect(
    ContextAwareModal,
    new PatientContextAdapter<IContextAwareModalProps>(c => ({
        _showHeader: !!c?.patientId
    }))
);