import React, { useMemo } from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PatientContext, { PatientReactContext } from "@HisPlatform/Model/DomainModel/PatientContext/PatientContext";
import PatientId from "@Primitives/PatientId.g";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientLoader from "@HisPlatform/Model/DomainModel/PatientContext/PatientLoader";
import PatientAllergyIntoleranceApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/PatientAllergyIntolerance/PatientAllergyIntoleranceApiAdapter";
import HiddenLoadingIndicator from "@CommonControls/LoadingIndicator/HiddenLoadingIndicator";
import PermissionScopeProvider from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/PermissionScopeProvider";
import { FrontendActionPermissionScope } from "@Toolkit/ReactClient/ActionProcessing/FrontendActionPermissionScope";
import PatientApiAdapter2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/PatientApiAdapter2";

interface IPatientContextProviderDependencies {
    patientApiAdapter: PatientApiAdapter2;
    patientAllergyIntoleranceApiAdapter: PatientAllergyIntoleranceApiAdapter;
    patientLoader: PatientLoader;
}

interface IPatientContextProviderProps {
    _dependencies?: IPatientContextProviderDependencies;

    /** Leave this empty to use PatientId from CareActivityContext. */
    patientId?: PatientId;
    children?: React.ReactNode;
}

@State.observer
class PatientContextProvider extends React.Component<IPatientContextProviderProps> {

    private get patientLoader() { return this.props._dependencies.patientLoader; }

    @State.computed private get isNew() {
        return this.props.patientId === null;
    }

    @State.observable.ref private _isLoading = true;
    @State.computed private get isLoading() {
        return !this.isNew && (this.patientContext.isLoading || this._isLoading);
    }

    @State.computed private get permissionScopes() {

        if (this.isLoading) {
            return [];
        }

        return [
            new FrontendActionPermissionScope("PatientId", [this.props.patientId?.value ?? "new"])
        ];
    }

    private patientContext = new PatientContext(
        this.props._dependencies.patientApiAdapter,
        this.props._dependencies.patientAllergyIntoleranceApiAdapter,
        this.patientLoader
    );

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IPatientContextProviderProps) {
        if (!ValueWrapper.equals(prevProps.patientId, this.props.patientId)) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    public componentWillUnmount() {
        this.patientContext?.dispose();
    }

    @State.loadingState("_isLoading")
    private async loadAsync() {
        if (!this.isNew) {
            await this.patientContext.loadAsync(this.props.patientId);
            await this.patientLoader.loadPatientRelatedReferenceDataAsync(this.patientContext.patient, this.patientContext.patientAllergyIntoleranceList);
        }
    }

    public render() {
        return (
            <PatientReactContext.Provider value={this.patientContext}>
                <PermissionScopeProvider scopes={this.permissionScopes}>
                    {(!this.isLoading || this.isNew) && this.props.children}
                    {this.isLoading && <HiddenLoadingIndicator />}
                </PermissionScopeProvider>
            </PatientReactContext.Provider>
        );
    }
}

export default connect(
    PatientContextProvider,
    new DependencyAdapter<IPatientContextProviderProps, IPatientContextProviderDependencies>(c => ({
        patientApiAdapter: c.resolve("PatientApiAdapter2"),
        patientAllergyIntoleranceApiAdapter: c.resolve("PatientAllergyIntoleranceApiAdapter"),
        patientLoader: c.resolve("PatientLoader")
    })),
    new CareActivityContextAdapter<IPatientContextProviderProps>((c, p) => ({
        patientId: p.patientId === undefined ? c?.patientId : null
    }))
);