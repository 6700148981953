import { useErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import React, { useCallback, useEffect, useState } from "react";

export default function ApplicationInitializer(props: React.PropsWithChildren<any>) {

    const { authorizationService } = useDependencies(c => ({
        authorizationService: c.resolve<AuthorizationService>("AuthorizationService"),
    }));

    const asyncInitializer = useCallback(async () => {
        await authorizationService.loadPermissionsAsync();
    }, []);

    const errorDispatcher = useErrorDispatcher();

    const [isInitialized, setInitialized] = useState(false);

    useEffect(() => {
        asyncInitializer()
            .catch(errorDispatcher)
            .finally(() => setInitialized(true));
    }, []);

    if (!isInitialized) {
        return null;
    }

    return (
        <>
            {props.children}
        </>
    );
}