import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import TargetDiseaseAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/TargetDiseaseAssignmentBase";
import CustomTargetDiseaseAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/CustomTargetDiseaseAssignment";
import VaccineDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/VaccineDetail";
import MedicalConditionHistoryItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemBase";

export default class ImmunizationHistoryItem extends MedicalConditionHistoryItemBase {
    @State.observable.ref public immunizationDate: LocalDate;
    @State.observable.ref public targetDiseaseAssignment: TargetDiseaseAssignmentBase;
    @State.observable.ref public vaccineDetail: VaccineDetail;

    @State.action.bound
    public setImmunizationDate(newValue: LocalDate) {
        this.immunizationDate = newValue;
    }

    @State.computed public get targetDiseaseValue() {
        if (!this.targetDiseaseAssignment) {
            return null;
        }

        if (this.targetDiseaseAssignment instanceof CustomTargetDiseaseAssignment) {
            return this.targetDiseaseAssignment.name;
        }

        return null;
    }

    @State.action.bound
    public setTargetDiseaseAssignment(newValue: TargetDiseaseAssignmentBase) {
        this.targetDiseaseAssignment = newValue;
    }

    @State.action.bound
    public setVaccineDetail(newValue: VaccineDetail) {
        this.vaccineDetail = newValue;
    }
}
