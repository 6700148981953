exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DialogService_button-container_1RYG8 {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding-left: 2px;\n  padding-right: 14px;\n}\n", ""]);

// exports
exports.locals = {
	"button-container": "DialogService_button-container_1RYG8",
	"buttonContainer": "DialogService_button-container_1RYG8"
};