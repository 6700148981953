import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Scheduling/Api/Proxy.g";
import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import { CareLocation } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/CareLocation";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import SuspectedDiagnosis from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/SuspectedDiagnosis";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";

type ResponseType = Proxy.ServiceRequestDto
    | Proxy.ModifyServiceRequestWithDeleteAppointmentCommandResponse
    | Proxy.CreateServiceRequestWithAppointmentCommandResponse
    | Proxy.ModifyServiceRequestWithCreateAppointmentCommandResponse
    | Proxy.ModifyServiceRequestWithUpdateAppointmentCommandResponse;

@Di.injectable()
export default class SchedulingServiceRequestStoreMapper extends EntityStoreMapper<Proxy.ServiceRequestDto, ServiceRequestStore, ResponseType>  {
    protected storeEntityIdType = ServiceRequestId;

    protected entityDtoSelector(response: ResponseType) {
        if (response instanceof Proxy.ServiceRequestDto) {
            return response;
        } else if (
            response instanceof Proxy.ModifyServiceRequestWithDeleteAppointmentCommandResponse ||
            response instanceof Proxy.CreateServiceRequestWithAppointmentCommandResponse ||
            response instanceof Proxy.ModifyServiceRequestWithCreateAppointmentCommandResponse ||
            response instanceof Proxy.ModifyServiceRequestWithUpdateAppointmentCommandResponse) {
            return response.serviceRequest;
        }
        return null;
    }

    protected applyToStoreCore(target: ServiceRequestStore, response: any) {
        const entity = this.entityDtoSelector(response);

        if (entity === null) {
            target.isNull = true;
            return;
        }

        target.id = entity.id;
        target.serviceRequestIdentifier = entity.serviceRequestIdentifier;

        target.serviceRequestDefinitionVersion = new EntityVersionSelector<ServiceRequestDefinitionId>(
            entity.serviceRequestDefinitionVersionSelector.entityId,
            entity.serviceRequestDefinitionVersionSelector.validOn
        );

        target.starterCareActivityId = entity.starterCareActivityId;
        target.executingCareActivityId = entity.executingCareActivityId;
        target.targetDoctorId = entity.targetDoctorId;
        target.requesterDoctorId = entity.requesterDoctorId;
        target.clinicalQuestion = entity.clinicalQuestion;
        target.createdAt = entity.createdAt;
        target.remark = entity.remark;
        target.targetCareLocation = this.getLocation(entity.targetCareLocation);
        target.state = entity.state;
        target.referralLocation = entity.referral === null ? null : this.getLocation(entity.referral);
        target.referralCareIdentifier = entity.referralCareIdentifier;
        target.executingDoctorId = entity.executingDoctorId;
        target.possibleActions = entity.possibleActions;
        target.direction = entity.direction;
        target.patientId = entity.patientId;

        target.suspectedDiagnosisList =
            entity.suspectedDiagnosisList &&
            State.observable(entity.suspectedDiagnosisList.map(item => this.mapSuspectedDiagnosis(item)));
        target.requestedServices =
            entity.requestedServices &&
            State.observable(entity.requestedServices.map((item, index) => this.mapRequestedService(item, index)));

        target.extensionData = entity.extensionData;
    }

    private getLocation(location: Proxy.CareLocationDto): CareLocation {
        if (isNullOrUndefined(location)) {
            return null;
        }
        if ((location as Proxy.ExternalCareLocationDto).externalLocationSelector) {
            return new ExternalCareLocation(new EntityVersionSelector<ExternalLocationId>(
                (location as Proxy.ExternalCareLocationDto).externalLocationSelector.entityId,
                (location as Proxy.ExternalCareLocationDto).externalLocationSelector.validOn
            ));
        } else if ((location as Proxy.InternalCareLocationDto).pointOfCareId) {
            return new InternalCareLocation((location as Proxy.InternalCareLocationDto).pointOfCareId);
        }
        throw new Error("Unknown CareLocation");
    }

    private mapSuspectedDiagnosis(dto: Proxy.SuspectedDiagnosisDto): SuspectedDiagnosis {
        const result = new SuspectedDiagnosis();
        result.id = dto.id;
        result.lateralityId = dto.lateralityId;
        result.conditionId = dto.conditionVersionSelector.entityId;
        return result;
    }

    private mapRequestedService(dto: Proxy.RequestedServiceDto, index: number): RequestedServiceStore {
        const result = new RequestedServiceStore();

        result.index = index + 1;
        result.id = dto.id;
        result.requestedServiceIdentifier = dto.requestedServiceIdentifier;
        result.expectedCompletionTime = dto.expectedCompletionTime;
        result.submitTime = dto.submitTime;

        const medicalServiceId = dto.medicalServiceVersionSelector.entityId;
        result.medicalServiceVersionSelector = new EntityVersionSelector<MedicalServiceId>(
            medicalServiceId,
            dto.medicalServiceVersionSelector.validOn
        );

        result.lateralityId = dto.lateralityId;
        result.priority = dto.priority;
        result.state = dto.state;
        result.possibleActions = dto.possibleActions && dto.possibleActions;
        result.attachedObservations = dto.attachedObservations;
        result.isCompleteDiagnosticReportAllowed = dto.isCompleteDiagnosticReportAllowed;

        return result;
    }
}
