import React, {useCallback} from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import {IDataGridColumnBaseProps} from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import PropertyBase from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyBase";
import BoolProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/BoolProperty";
import NumericProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/NumericProperty";
import NumericRangeProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/NumericRangeProperty";
import {BoolPropertyEditor} from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PropertyGroupEditableColumn/BoolPropertyEditor";
import {ScalarPropertyEditor} from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PropertyGroupEditableColumn/ScalarPropertyEditor";
import {NumericPropertyEditor} from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PropertyGroupEditableColumn/NumericPropertyEditor";
import {NumericRangePropertyEditor} from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PropertyGroupEditableColumn/NumericRangePropertyEditor";


interface IPropertyGroupEditableColumnProps extends IDataGridColumnBaseProps {
}


const PropertyGroupEditableColumn: React.FC<IPropertyGroupEditableColumnProps> = (props: IPropertyGroupEditableColumnProps) => {

    const cellValueRenderer = useCallback((_: any, row: PropertyBase) => {
        if (row instanceof BoolProperty) {
            return (
                <BoolPropertyEditor row={row}/>
            );
        } else if (row instanceof NumericProperty) {
            return (
                <NumericPropertyEditor row={row}/>
            );
        } else if (row instanceof NumericRangeProperty) {
            return (
                <NumericRangePropertyEditor row={row}/>
            );
        } else {
            return (
                <ScalarPropertyEditor row={row}/>
            );
        }
    }, []);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={cellValueRenderer}
        />
    );
};

export default PropertyGroupEditableColumn;
