import { runInAction } from "mobx";




function loadingStateWrapper(loadingStateKey: string, originalFunction: any) {
    return async function () {
        runInAction(() => {
            this[loadingStateKey] = true;
        });

        let result: any;

        try {
            // eslint-disable-next-line prefer-rest-params
            result = await originalFunction.apply(this, [...arguments]);
        } finally {
            runInAction(() => {
                this[loadingStateKey] = false;
            });
        }

        return result;
    };
}

function boundLoadingState(loadingStateKey: string = "isLoading") {
    return function (target: any, key: string, descriptor: PropertyDescriptor) {

        if (typeof descriptor.value !== "function") {
            throw new TypeError(`@loadingState decorator can only be applied to async methods.`);
        }

        const fn = loadingStateWrapper(loadingStateKey, descriptor.value);
        let definingProperty = false; // IE workaround: avoid infinity loop

        return {
            configurable: true,
            get() {
                // eslint-disable-next-line no-prototype-builtins
                if (definingProperty || this === target.prototype || this.hasOwnProperty(key) || typeof fn !== "function") {
                    return fn;
                }

                const boundFn = fn.bind(this);
                definingProperty = true;
                Object.defineProperty(this, key, {
                    configurable: true,
                    get() {
                        return boundFn;
                    },
                    set() {
                        throw new Error("Class functions should not be overwritten.");
                    }
                });
                definingProperty = false;
                return boundFn;
            },
            set() {
                throw new Error("Class functions should not be overwritten.");
            }
        };
    };
}

const loadingState = function (loadingStateKey: string = "isLoading") {
    return function (target: any, key: string, descriptor: PropertyDescriptor) {

        if (typeof descriptor.value !== "function") {
            throw new TypeError(`@loadingState decorator can only be applied to methods.`);
        }

        const fn = loadingStateWrapper(loadingStateKey, descriptor.value);
        descriptor.value = fn;

        return descriptor;
    };
};

loadingState.bound = boundLoadingState;

export {
    loadingState,
    boundLoadingState
};