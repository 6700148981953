import React from "react";
import { IFormEditorComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import { TextBox } from "@CommonControls";
import StringFormDataElement from "@Toolkit/FormEngine/Model/Schema/StringFormDataElement";


interface IFormTextBoxProps extends IFormEditorComponentProps {

}

function FormTextBox(props: IFormTextBoxProps) {
    
    const dataElement = props.dataElement as StringFormDataElement;

    return (
        <TextBox
            {...props}
            multiline={dataElement?.isLong ?? false}            
        />
    ); 
}

export default FormTextBox;