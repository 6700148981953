import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SizeMeasurer, { ISize } from "@Toolkit/ReactClient/Components/SizeMeasurer/SizeMeasurer";
import _ from "@HisPlatform/Common/Lodash";
import { isNullOrUndefined, emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Styles from "./TreeGrid.less";
import { ITreeGridFooterProps } from "./ITreeGridProps";

export interface ITableFrameColumn {
    width: number;
    widthMode: "pixel" | "percent";
    title: string;
    isVisible?: boolean;
    key?: number;
    style?: React.CSSProperties;
    displayValueGetter?: string | number | ((entry: any, onEditStart: () => void) => React.ReactNode);
    editValueGetter?: string | number | ((entry: any, onEditEnd: () => void) => React.ReactNode);
    automationId?: string;
}

export interface IRenderedTableFrameColumn extends ITableFrameColumn {
    renderedWidth: number;
}

export interface ITableFrameProps {
    columns: ITableFrameColumn[];
    fixedHeight?: string | number;
    children: React.ReactNode | ((columns: IRenderedTableFrameColumn[]) => React.ReactNode);
    onColumnsChange?: (columns: IRenderedTableFrameColumn[]) => void;
    scrollBarWidth?: number;
    automationId?: string;

    footer?: React.ReactElement<ITreeGridFooterProps> | ((props: ITreeGridFooterProps) => React.ReactNode);
    hasBackButton?: boolean;
    onBack?: () => void;
    backButtonText?: string;
}

@State.observer
export default class TableFrame extends React.Component<ITableFrameProps> {

    @State.observable.ref private fullWidth: number = null;
    @State.observable.ref private propsColumns: ITableFrameColumn[] = emptyArray;
    private columnsReactionDisposer: () => void = null;

    public static defaultProps: Partial<ITableFrameProps> = {
        scrollBarWidth: 10
    };

    @State.action.bound
    private setSize = _.debounce((size: ISize) => {
        State.runInAction(() => {
            this.fullWidth = size.width - this.props.scrollBarWidth;
        });

        if (this.props.onColumnsChange) {
            this.props.onColumnsChange(this.columns);
        }
    }, 10);

    @State.computed
    private get columns() {
        const fullWidthWithoutFixed = this.fullWidth -
            this.propsColumns
                .filter(c => c.widthMode === "pixel")
                .map(c => c.width)
                .reduce((c, sum) => sum + c, 0);

        return this.propsColumns.map((c, index) => ({
            ...c,
            key: isNullOrUndefined(c.key) ? index : c.key,
            renderedWidth: c.widthMode === "pixel" ? c.width : Math.round(fullWidthWithoutFixed * (c.width / 100))
        } as IRenderedTableFrameColumn));
    }

    @State.computed
    private get correction() {
        const allColumnWidths = this.columns.reduce((sum, col) => sum + col.renderedWidth, 0);
        return this.fullWidth - allColumnWidths;
    }

    public componentDidMount(): void {
        State.runInAction(() => {
            this.propsColumns = this.props.columns;
        });

        this.columnsReactionDisposer = State.reaction(() => this.columns, cols => {
            if (this.props.onColumnsChange) {
                this.props.onColumnsChange(this.columns);
            }
        });
    }

    public componentDidUpdate(prevProps: ITableFrameProps) {
        if (prevProps.columns !== this.props.columns) {
            State.runInAction(() => {
                this.propsColumns = this.props.columns;
            });
        }
    }

    public componentWillUnmount(): void {
        this.columnsReactionDisposer();
    }

    public render() {
        const columns = this.columns;
        return (
            <>
                <SizeMeasurer onResize={this.setSize}>
                    <div style={{ width: "100%" }} />
                </SizeMeasurer>
                {this.fullWidth !== null && this.renderContent(columns)}
            </>
        );
    }

    private renderContent(columns: IRenderedTableFrameColumn[]) {
        return (
            <div style={{ height: this.props.fixedHeight }} data-automation-id={this.props.automationId || undefined}>
                <table className={Styles.headerTable}>
                    <thead>
                        <tr>
                            {columns.map(c => (
                                <th key={c.key} style={{ ...c.style, width: c.renderedWidth }} data-automation-id={"__col_" + c.automationId}>{c.title}</th>
                            ))}
                            <th key="correction" style={{ width: this.correction }} />
                        </tr>
                    </thead>
                </table>
                {this.renderChildren(columns)}
                <table className={Styles.footerTable}>
                    <tfoot>
                        {this.renderFooter()}
                    </tfoot>
                </table>
            </div>
        );
    }
    private renderFooter() {
        if (isNullOrUndefined(this.props.footer)) {
            return null;
        }

        const footerProps = {
            colSpan: this.props.columns.length + 1,
            hasBackButton: this.props.hasBackButton,
            onBack: this.props.onBack,
            backButtonText: this.props.backButtonText
        } as ITreeGridFooterProps;

        if (typeof this.props.footer === "function") {
            return this.props.footer(footerProps);
        }

        return React.cloneElement(this.props.footer, footerProps);
    }

    private renderChildren(columns: IRenderedTableFrameColumn[]) {
        if (typeof this.props.children === "function") {
            return (this.props.children as ((columns: IRenderedTableFrameColumn[]) => React.ReactNode))(columns);
        }

        return this.props.children;
    }
}
