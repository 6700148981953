import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityId from "@Primitives/CareActivityId.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import CoverageEligibilityCheckResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CheckCoverageEligibility/CoverageEligibilityCheckResult";
import CoverageEligibilityCheckAvailability from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CheckCoverageEligibility/CoverageEligibilityCheckAvailability";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import * as Ui from "@CommonControls";
import PatientId from "@Primitives/PatientId.g";
import CoverageEligibilityCheckLed from "./CoverageEligibilityCheckLed";
import CoverageCheckResultNotification from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Notifications/CoverageCheckResultNotification";
import CoverageEligibilityCheckResultNotificationHandler from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Notifications/CoverageEligibilityCheckResultNotificationHandler";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";

interface ICoverageEligibilityCheckerDependencies {
    hunCareApiAdapter: EhiCareApiAdapter;
    localizationService: ILocalizationService;
}

interface ICoverageEligibilityCheckerProps {
    _dependencies?: ICoverageEligibilityCheckerDependencies;
    careActivityId: CareActivityId;
    patientId: PatientId;
    identifierValue: string;
    label?: string;
    doNotRunAutomatically?: boolean;
    mode?: "normal" | "widget";
}

@State.observer
class CoverageEligibilityChecker extends React.Component<ICoverageEligibilityCheckerProps> {

    @State.observable.ref private coverageEligibilityCheckResult?: CoverageEligibilityCheckResult = null;
    @State.observable.ref private coverageEligibilityCheckAvailability: CoverageEligibilityCheckAvailability = null;
    @State.observable.ref private checkedAt: LocalDate = null;

    public componentDidMount() {
        this.props.doNotRunAutomatically !== true && dispatchAsyncErrors(this.loadAsync(), this);
    }

    private async loadAsync() {
        if (this.props.identifierValue) {
            const response = await this.props._dependencies.hunCareApiAdapter.getCoverageEligibilityCheckAsync(
                this.props.careActivityId?.value === "new" ? null : this.props.careActivityId,
                this.props.patientId,
                this.props.identifierValue
            );
            this.setLedState(response.value.coverageEligibilityCheckResult, response.value.coverageEligibilityCheckAvailability, response.value.checkedAt);
        }
    }

    @State.bound
    private onNotificationReceived(notification: CoverageCheckResultNotification) {
        if (this.props.identifierValue && notification?.patientIdentifier === this.props.identifierValue) {
            this.setLedState(notification.coverageEligibilityCheckResult, notification.coverageEligibilityCheckAvailability, LocalDate.createFromMoment(notification.checkedAt));
        }
    }

    @State.action.bound
    private setLedState(
        coverageEligibilityCheckResult: CoverageEligibilityCheckResult,
        coverageEligibilityCheckAvailability: CoverageEligibilityCheckAvailability,
        checkedAt: LocalDate
    ) {
        this.coverageEligibilityCheckResult = coverageEligibilityCheckResult;
        this.coverageEligibilityCheckAvailability = coverageEligibilityCheckAvailability;
        this.checkedAt = checkedAt;
    }

    @State.bound
    private async refreshAsync() {
        await this.loadAsync();
    }

    public render() {
        if (!this.props.identifierValue ||
            (this.props.identifierValue && this.coverageEligibilityCheckAvailability === CoverageEligibilityCheckAvailability.Expired)) {
            return null;
        }

        return this.props.mode === "widget" ? this.renderWidget() : this.renderNormal();
    }

    private renderNormal() {
        return (
            <>
                <CoverageEligibilityCheckResultNotificationHandler onMessageReceived={this.onNotificationReceived} />
                <Ui.Flex spacing="none" outerSpacing="none" innerSpacing="none" verticalSpacing="none" noWrap >
                    <Ui.Flex.Item>
                        <CoverageEligibilityCheckLed
                            coverageEligibilityCheckAvailability={this.coverageEligibilityCheckAvailability}
                            coverageEligibilityCheckResult={this.coverageEligibilityCheckResult}
                            checkedAt={this.checkedAt}
                            label={this.props.label}
                            automationId="coverageEligibilityCheckLed"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item style={{ marginTop: "auto", marginBottom: 3 }}>
                        <Ui.Button iconName="sync" 
                            size="compact" 
                            visualStyle="flat"
                            onClickAsync={this.refreshAsync}
                            automationId="refreshButton" />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </>
        );
    }

    private renderWidget() {
        return (
            <>
                <CoverageEligibilityCheckResultNotificationHandler onMessageReceived={this.onNotificationReceived} />
                <Ui.Flex spacing="none" outerSpacing="none" innerSpacing="none" verticalSpacing="none" xsJustify="between" noWrap>
                    <Ui.Flex.Item>
                        {this.renderInitialMessage()}
                        <CoverageEligibilityCheckLed
                            coverageEligibilityCheckAvailability={this.coverageEligibilityCheckAvailability}
                            coverageEligibilityCheckResult={this.coverageEligibilityCheckResult}
                            checkedAt={this.checkedAt}
                            label={this.props.label}
                            automationId="coverageEligibilityCheckLed"
                            visualMode="dark"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item style={{ marginTop: "auto", marginBottom: 3 }}>
                        <Ui.Button iconName="sync" 
                            size="compact" 
                            visualMode="dark"
                            onClickAsync={this.refreshAsync}
                            automationId="refreshButton" />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </>
        );
    }

    private renderInitialMessage() {
        if (isNullOrUndefined(this.coverageEligibilityCheckAvailability)) {
            const message = this.props._dependencies.localizationService.localizeWebAppResource("Care.Widgets.CoverageEligibilityCheck.InitMessage");
            return (<div>{message}</div>);
        }
        return null;
    }
}

export default connect(
    CoverageEligibilityChecker,
    new DependencyAdapter<ICoverageEligibilityCheckerProps, ICoverageEligibilityCheckerDependencies>(c => ({
        hunCareApiAdapter: c.resolve("EhiCareApiAdapter"),
        localizationService: c.resolve("ILocalizationService")
    }))
);