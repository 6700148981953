import { useScreenNavigationContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import { FrontendActionPermissionScope } from "@Toolkit/ReactClient/ActionProcessing/FrontendActionPermissionScope";
import { usePermissionScopes } from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/PermissionScopeProvider";
import React, { useCallback, useMemo } from "react";
import _ from "@HisPlatform/Common/Lodash";
import Styles from "@Toolkit/ReactClient/Components/CommonControls/SideMenu/SideMenu.less";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import Icon, { iconNameType } from "@CommonControls/Icon";
import { SmallValidationBadge } from "@CommonControls";
import IValidationState from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationState";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { ICommonControlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import { useHisActionDispatcher } from "@HisPlatform/Common/FrontendActions/HisActionDispatcher";
import ActionDescriptor from "./ActionDescriptor";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import { useDashboardContext } from "@CommonControls/Dashboard/DashboardContext";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export interface IActionBoundSideMenuItemProps extends ICommonControlProps {
    actionDescriptor: ActionDescriptor;
    automationId: string;
    text?: string;
    iconName?: iconNameType;

    callContextParams?: any;

    scopes?: FrontendActionPermissionScope[];
    permissionDeniedStyle?: "disabled" | "invisible";

    isOpenByDefault?: boolean;
    disabled?: boolean;

    propertyPathRegexPattern?: string;
    validationState?: IValidationState;

    children?: React.ReactNode;
}

function ActionBoundSideMenuItem(props: IActionBoundSideMenuItemProps) {

    const { authorizationService, notificationService, localizationService } = useDependencies(c => ({
        authorizationService: c.resolve<AuthorizationService>("AuthorizationService"),
        notificationService: c.resolve<INotificationService>("INotificationService"),
        localizationService: c.resolve<IToolkitLocalizationService>("IToolkitLocalizationService"),
    }));

    const contextScopes = usePermissionScopes();
    const isPermitted = useMemo(() => {
        const scopes = _.uniqBy([...contextScopes, ...props.actionDescriptor.requiredPermissionScopes, ...(props.scopes ?? [])], s => s.type);
        return authorizationService.hasPermissionFor(props.actionDescriptor.action.id, ...scopes);
    }, [props.actionDescriptor, props.scopes, contextScopes]);

    const hisActionDispatcher = useHisActionDispatcher();
    const showScreenActionNavigationContext = useScreenNavigationContext();

    let isOpen: boolean = props.isOpenByDefault ?? true;
    const hasChildren = !!props.children;
    const dashboardContext = useDashboardContext();

    const click = useCallback(() => {

        if (!isPermitted) {
            notificationService.error(localizationService.staticResources.common.operationNotPermitted);
            return;
        }

        if (props.disabled) {
            return;
        }

        if (hasChildren) {
            isOpen = !isOpen;
        } else {
            dispatchAsyncErrors(hisActionDispatcher.dispatchAsync(props.actionDescriptor.action), this);
        }
    }, [props.actionDescriptor, showScreenActionNavigationContext, isOpen]);

    const displaySettings = useMemo(() => hisActionDispatcher.getDisplaySettings(props.actionDescriptor.action), [props.actionDescriptor]);

    const isActive = useMemo(() => {
        if (showScreenActionNavigationContext.currentPrimaryScreen?.action?.id.value === props.actionDescriptor?.action?.id.value) {
            return true;
        } else {
            return false;
        }
    }, [props.actionDescriptor, showScreenActionNavigationContext.currentPrimaryScreen]);

    const classNames = useMemo(() => {
        const disabled = props.disabled || (!isPermitted && props.permissionDeniedStyle === "disabled");
        const classes = new CompositeClassName(props.className);
        classes.addIf(!disabled, Styles.itemContainer);
        classes.addIf(disabled, Styles.itemContainerDisabled);
        classes.addIf(isActive && !disabled, Styles.itemContainerActive);
        classes.addIf(dashboardContext.mode === "small" && !props.children, Styles.smallSidebar);
        return classes.classNames;
    }, [props.disabled, isActive, props.disabled, props.permissionDeniedStyle, isPermitted, dashboardContext.mode]);

    const childrenContainerClassName = useMemo(() => {
        const classes = new CompositeClassName(Styles.itemChildContainer);
        classes.addIf(isOpen === false, Styles.itemChildContainerClosed);
        return classes.classNames;
    }, [isOpen]);

    const openIconClassName = useMemo(() => {
        const classes = new CompositeClassName(Styles.openIcon, Styles.icon);
        classes.addIf(isOpen === false, Styles.openIconClosed);
        return classes.classNames;
    }, [isOpen]);

    return (!isPermitted && props.permissionDeniedStyle === "invisible")
        ? <></>
        : (
            <>
                <div
                    className={classNames}
                    onClick={click}
                    data-automation-id={props.automationId || undefined}
                    title={props.text ?? displaySettings?.displayName}
                >
                    <div style={{ flex: "1" }}>
                        {(props.iconName || displaySettings?.iconName) && (
                            <Icon
                                iconName={props.iconName ?? displaySettings?.iconName}
                                visualStyle="white"
                                size="compact"
                                className={Styles.mainIcon} />
                        )}
                        {dashboardContext.mode === "normal" && (
                            <label>{props.text ?? displaySettings?.displayName}</label>
                        )}
                    </div>
                    <div>
                        {props.propertyPathRegexPattern && (
                            <SmallValidationBadge
                                hideIfNoErrors
                                propertyPathRegexPattern={props.propertyPathRegexPattern}
                                className={Styles.validationBadge} />
                        )}
                        {props.validationState && (
                            <SmallValidationBadge
                                hideIfNoErrors
                                severity={props.validationState.severity}
                                numberOfProblems={props.validationState.numberOfProblems}
                                className={Styles.validationBadge} />
                        )}
                        {!isPermitted && (
                            <span>
                                <Icon iconName="shield" automationId="shield" />
                            </span>
                        )}
                        {hasChildren && (
                            <Icon
                                iconName="chevronDown"
                                className={openIconClassName}
                                visualStyle="white"
                                size="compact" />
                        )}
                    </div>
                </div>
                {hasChildren && <div className={childrenContainerClassName}>
                    {props.children}
                </div>}
            </>
        );
}

export default State.observer(ActionBoundSideMenuItem);