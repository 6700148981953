import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IMasterDetailState from "@CommonControls/Layout/IMasterDetailState";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import * as Ui from "@CommonControls";
import ServiceRequestDefinitionColumn2 from "@HisPlatform/BoundedContexts/Care/Components/Controls/ServiceRequestManagement/ServiceRequestDefinitionColumn2/ServiceRequestDefinitionColumn2";
import CareLocationColumn2 from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/CareLocationColumn2/CareLocationColumn2";
import { ExternalLocationVersionSelector } from "@Primitives/ExternalLocationId.g";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import PractitionerColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PractitionerColumn/PractitionerColumn";
import ServiceRequestStatusColumn2 from "@HisPlatform/BoundedContexts/Care/Components/Controls/ServiceRequestManagement/ServiceRequestStatusColumn2/ServiceRequestStatusColumn2";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";

interface IInsertServiceRequestResultGridProps extends IHasMasterDetailState {
    serviceRequestList: ServiceRequestStore[];
    externalLocationVersionSelectors: ExternalLocationVersionSelector[];
}

@State.observer
export default class InsertServiceRequestResultGrid extends React.Component<IInsertServiceRequestResultGridProps> {
    private dataSource: InMemoryDataGridDataSource = new InMemoryDataGridDataSource(() => this.props.serviceRequestList);

    @State.computed
    private get masterDetailState() { return this.props._masterDetailState as IMasterDetailState<ServiceRequestStore>; }

    @State.computed
    private get selectedId() { return this.masterDetailState.selectedItem; }

    @State.bound
    private setSelectedId(item: ServiceRequestStore) {
        return this.masterDetailState?.onSelectedItemChange(item);
    }

    public render() {
        const resources = StaticCareResources.CareRegister.CareActivityTextBlockListPanel.InsertServiceRequestResult.Columns;
        return (
            <Ui.DataGrid
                isLoading={isNullOrUndefined(this.dataSource)}
                dataSource={this.dataSource}
                selectedRow={this.selectedId}
                onSelectedRowChange={this.setSelectedId}
                automationId="_serviceRequestList"
                rowHeight={"40px"}
                fixedLayout
                fixedHeight="100%"
                generateInitialFilterStore
                isSelectable
                changeOnMount
                hasHeader={true}
                statePersisterKey="serviceRequests"

            >
                <ServiceRequestDefinitionColumn2
                    header={StaticCareResources.ServiceRequestManagement.ServiceRequestsList.ServiceRequestDefinitionField}
                    dataGetter={"serviceRequestDefinitionVersion"}
                    isFilterable
                    automationId="Definition"
                />
                <CareLocationColumn2
                    header={StaticCareResources.ServiceRequestManagement.ServiceRequestsList.ExecutingLocationField}
                    dataGetter={"targetCareLocation"}
                    isFilterable
                    automationId="TargetLocation"
                    externalLocationVersionSelectors={this.props.externalLocationVersionSelectors}
                />
                <DataGridDateTimeColumn
                    header={StaticCareResources.ServiceRequestManagement.ServiceRequestsList.ExpectedCompletionTime}
                    dataGetter={"expectedCompletionTime"}
                    automationId="ExpectedCompletionTime"
                />
                <PractitionerColumn
                    header={resources.TargetDoctorColumn}
                    dataGetter={"targetDoctorId"}
                    isFilterable
                    automationId="TargetDoctor"
                    isCodeBold
                />
                <ServiceRequestStatusColumn2
                    header={StaticCareResources.ServiceRequestManagement.ServiceRequestsList.StatusField}
                    dataGetter={"state"}
                    nonMatchingChildrenInfoFieldName={"nonMatchingChildrenInfo"}
                    automationId="ServiceRequestState"
                />
            </Ui.DataGrid>
        );
    }
}