import ClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidator";
import StaticHunEHealthInfrastructureWebAppResources from "@HunEHealthInfrastructurePlugin/StaticResources/StaticHunEHealthInfrastructureWebAppResources";

export default class EhiDigitalPrescriptionListFilterValidator extends ClientSideValidator {
    constructor() {
        super({
            Filter: {
                ehiPrescriptionSearchStartDate: {
                    isRequired: true
                },
                ehiPrescriptionSearchMonths: {
                    isRequired: true
                },
                ehiPrescriptionSearchMode: {
                    isRequired: true
                },
                ehiPrescriptionIdentifier: {
                    isRequired: true
                }
            }
        }, {shouldBeFilled: StaticHunEHealthInfrastructureWebAppResources.Validator.ShouldBeFilled}, true);
    }
}