import React from "react";
import * as HisUi from "@HisPlatformControls";
import DialogContainer from "@Toolkit/ReactClient/Services/Implementation/DialogService/DialogContainer";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientId from "@Primitives/PatientId.g";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";

interface IHisPlatformDialogContainerDependencies {
}

export interface IHisPlatformDialogContainerProps {
    _dependencies?: IHisPlatformDialogContainerDependencies;
    _patientId?: PatientId;
}

const renderContextHeader = () => (
    <HisUi.OutpatientHeader
        showReducedData
        isInModal
    />
);


const HisPlatformDialogContainer: React.FC<IHisPlatformDialogContainerProps> = (props) => (
    <DialogContainer
        renderContextHeader={props._patientId ? renderContextHeader : undefined}
        closeButton={props._patientId ? false : true}
        isHeaderPrimary={props._patientId ? false : true}
    />
);

export default connect(
    State.observer(HisPlatformDialogContainer),
    new DependencyAdapter<IHisPlatformDialogContainerProps, IHisPlatformDialogContainerDependencies>(c => ({
    })),
    new PatientContextAdapter<IHisPlatformDialogContainerProps>(c => ({
        _patientId: c?.patientId
    }))
);
