exports = module.exports = require("../../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PatientAppointmentsBody_container_1sqwZ {\n  font-family: PragatiNarrow;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  white-space: nowrap;\n  width: fit-content;\n}\n.PatientAppointmentsBody_container_1sqwZ p {\n  margin: 0px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n.PatientAppointmentsBody_icon_znTBx {\n  min-width: 16px;\n  top: -1px;\n  margin-right: 8px;\n}\n.PatientAppointmentsBody_item-card_k5FtO {\n  background: #F4F7FC;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: stretch;\n  padding: 3px 7px;\n  border-radius: 3px;\n  margin: 5px 0 3px 0;\n}\n.PatientAppointmentsBody_date-field_6h6ZL {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  margin-right: 16px;\n  width: 130px;\n}\n.PatientAppointmentsBody_location-field_LSxFu {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  margin-right: 16px;\n  width: 100px;\n}\n.PatientAppointmentsBody_service-field_5d0S5 {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  margin-right: 16px;\n  width: 250px;\n  max-width: 250px;\n  flex-grow: 1;\n}\n.PatientAppointmentsBody_practitioner-field_2C_1O {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  margin-right: 16px;\n  width: 175px;\n  flex-grow: 1;\n}\n.PatientAppointmentsBody_item-field_DqR1P {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  margin-right: 16px;\n}\n.PatientAppointmentsBody_text-bold_2gokD {\n  font-family: PragatiNarrowBold;\n}\n.PatientAppointmentsBody_doctor-code_1JpgK {\n  font-family: PragatiNarrowBold;\n  min-width: fit-content;\n}\n", ""]);

// exports
exports.locals = {
	"container": "PatientAppointmentsBody_container_1sqwZ",
	"icon": "PatientAppointmentsBody_icon_znTBx",
	"item-card": "PatientAppointmentsBody_item-card_k5FtO",
	"itemCard": "PatientAppointmentsBody_item-card_k5FtO",
	"date-field": "PatientAppointmentsBody_date-field_6h6ZL",
	"dateField": "PatientAppointmentsBody_date-field_6h6ZL",
	"location-field": "PatientAppointmentsBody_location-field_LSxFu",
	"locationField": "PatientAppointmentsBody_location-field_LSxFu",
	"service-field": "PatientAppointmentsBody_service-field_5d0S5",
	"serviceField": "PatientAppointmentsBody_service-field_5d0S5",
	"practitioner-field": "PatientAppointmentsBody_practitioner-field_2C_1O",
	"practitionerField": "PatientAppointmentsBody_practitioner-field_2C_1O",
	"item-field": "PatientAppointmentsBody_item-field_DqR1P",
	"itemField": "PatientAppointmentsBody_item-field_DqR1P",
	"text-bold": "PatientAppointmentsBody_text-bold_2gokD",
	"textBold": "PatientAppointmentsBody_text-bold_2gokD",
	"doctor-code": "PatientAppointmentsBody_doctor-code_1JpgK",
	"doctorCode": "PatientAppointmentsBody_doctor-code_1JpgK"
};