import CareActivityId from "@Primitives/CareActivityId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";

export function getSingleDocumentDto(serviceRequestId: ServiceRequestId, careActivityId: CareActivityId, documentTypeCode?: string, isPrimary?: boolean, isAutomatic?: boolean) {
    return new Proxy.GetSingleDocumentForServiceRequestControllerDto({
        serviceRequestId: serviceRequestId,
        careActivityId: careActivityId,
        documentTypeCode: documentTypeCode,
        isAutomatic: isAutomatic,
        isPrimary: isPrimary
    });
}