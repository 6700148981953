import AppointmentInvalidationReason from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentInvalidationReason.g";
import { IAppointmentSubjectStore } from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/IAppointmentSubjectStore";

export default class BookSlotDialogSettings {
    constructor(
        public readonly service: IAppointmentSubjectStore,
        public readonly readOnly: boolean = false,
        public readonly referralReadOnly: boolean = false,
        public readonly invalidationReason: AppointmentInvalidationReason = null,
        public readonly isEdit: boolean = false
    ) {
    }
}
