exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PractitionerEhiLogin_modal-body_3qJ_r {\n  color: #676a6c;\n}\n.PractitionerEhiLogin_ehi-token_22m_g {\n  font-weight: bold;\n}\n.PractitionerEhiLogin_expiry_2ysRZ {\n  font-size: 14px;\n  color: #676a6c;\n  padding-left: 6px;\n}\n.PractitionerEhiLogin_logout_3ty7i {\n  font-size: 16px;\n  padding-left: 6px;\n}\n.PractitionerEhiLogin_logged-In_2YEt5 {\n  font-size: 16px;\n  padding-left: 22px;\n}\n.PractitionerEhiLogin_eeszt-icon_NROYl {\n  position: relative;\n  top: -10px;\n}\n.PractitionerEhiLogin_green-dot_2fP8o {\n  position: absolute;\n  width: 6px;\n  height: 6px;\n  background-color: #3ff430;\n  border-radius: 3px;\n  left: 31px;\n  top: 5px;\n}\n.PractitionerEhiLogin_bold_1mbqf {\n  font-family: 'PragatiNarrowBold';\n  color: #4f5b72;\n}\n.PractitionerEhiLogin_label_2lM9t {\n  color: #8695a7;\n  font-weight: normal;\n  line-height: 22px;\n}\n", ""]);

// exports
exports.locals = {
	"modal-body": "PractitionerEhiLogin_modal-body_3qJ_r",
	"modalBody": "PractitionerEhiLogin_modal-body_3qJ_r",
	"ehi-token": "PractitionerEhiLogin_ehi-token_22m_g",
	"ehiToken": "PractitionerEhiLogin_ehi-token_22m_g",
	"expiry": "PractitionerEhiLogin_expiry_2ysRZ",
	"logout": "PractitionerEhiLogin_logout_3ty7i",
	"logged-In": "PractitionerEhiLogin_logged-In_2YEt5",
	"loggedIn": "PractitionerEhiLogin_logged-In_2YEt5",
	"eeszt-icon": "PractitionerEhiLogin_eeszt-icon_NROYl",
	"eesztIcon": "PractitionerEhiLogin_eeszt-icon_NROYl",
	"green-dot": "PractitionerEhiLogin_green-dot_2fP8o",
	"greenDot": "PractitionerEhiLogin_green-dot_2fP8o",
	"bold": "PractitionerEhiLogin_bold_1mbqf",
	"label": "PractitionerEhiLogin_label_2lM9t"
};