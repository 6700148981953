import CareActivityId from "@Primitives/CareActivityId.g";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import PatientId from "@Primitives/PatientId.g";
import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";

export interface IEpisodeOfCareDialogParams extends IModalParams {
    patientId: PatientId;
    careActivityId?: CareActivityId;
}
 
export interface IEpisodeOfCareDialogResult {
    newId: EpisodeOfCareId;
}
 
export default class EpisodeOfCareDialogParams implements IEpisodeOfCareDialogParams {
 
    public static type = "EpisodeOfCareDialog";
    public get type() { return EpisodeOfCareDialogParams.type; }

    constructor(public patientId: PatientId, public careActivityId?: CareActivityId) {
    }
}