import { iconNameType } from "@CommonControls/Icon";
import IChildItemDisplaySettings from "@Toolkit/ReactClient/DynamicNavigation/IChildItemDisplaySettings";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import ITopLevelDisplaySettings from "@Toolkit/ReactClient/DynamicNavigation/ITopLevelItemDisplaySettings";

export default class NavMenuGroupItem implements INavMenuItem {
    public readonly children: INavMenuItem[] = [];
    
    private constructor(
        public readonly identifier: string,
        public readonly parentIdentifier: string,
        public readonly automationId: string,
        public readonly side?: "left" | "right",
        public readonly text?: string,
        public readonly iconName?: iconNameType,
        public readonly order?: number
    ) {
    }

    public static createChild (
        identifier: string,
        parentIdentifier: string,
        displaySettings: IChildItemDisplaySettings      
    ) {
        return new NavMenuGroupItem(identifier, parentIdentifier, displaySettings.automationId, null, displaySettings.text, displaySettings.iconNameType, displaySettings.order);
    }

    public static createTopLevel (
        identifier: string,
        displaySettings: ITopLevelDisplaySettings
    ) {
        return new NavMenuGroupItem(identifier, null, displaySettings.automationId, displaySettings.side, displaySettings.text, displaySettings.iconNameType, displaySettings.order);
    }
}
