import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import NDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanel";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import PatientId from "@Primitives/PatientId.g";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import ConditionStatementId from "@Primitives/ConditionStatementId.g";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IMedicalConditionReferenceDataResolver from "@HisPlatform/BoundedContexts/Care/Services/Definition/MedicalConditionReferenceDataResolver/IMedicalConditionReferenceDataResolver";
import ConditionStatementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ConditionStatement/ConditionStatementApiAdapter";
import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import MedicalConditionType from "@Primitives/MedicalConditionType";
import IMedicalConditionSynchronizationExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IMedicalConditionSynchronizationExtensionPointProps";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import FormExtensionRegistryAdapter from "@PluginInterface/FormExtension/FormExtensionRegistryAdapter";
import ExtensionStore from "@PluginInterface/Common/ExtensionStore";
import ILockingNotificationService from "@HisPlatform/BoundedContexts/Locking/Services/Definition/ILockingNotificationService";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import IMedicalConditionLastSynchronizationSubtitleExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IMedicalConditionLastSynchronizationSubtitleExtensionPointProps";
import ConditionStatementClinicalStatus from "@HisPlatform/BoundedContexts/Care/Api/MedicalCondition/ConditionStatement/Enum/ConditionStatementClinicalStatus.g";

interface IFormerConditionStatementListPanelDependencies {
    worklistApiAdapter: WorklistApiAdapter;
    dialogService: IDialogService;
    notificationService: INotificationService;
    conditionStatementApiAdapter: ConditionStatementApiAdapter;
    medicalConditionReferenceDataResolver: IMedicalConditionReferenceDataResolver;
    lockingNotificationService: ILockingNotificationService;
    userContext: UserContext;
}

interface IFormerConditionStatementListPanelProps {
    _dependencies?: IFormerConditionStatementListPanelDependencies;
    _patientId?: PatientId;
    onBack: () => void;
    onSelectedConditionStatementIdChange: (conditionStatementId: ConditionStatementId) => void;
    useCaseState: INDataUseCaseState;
    selectedRowId: string;
    onChange: (selectedRowId: string, useCaseState: INDataUseCaseState) => void;
    _formExtension?: IFormExtension<any>;

    onCloseDetail: () => void;
}

@State.observer
class FormerConditionStatementListPanel extends React.Component<IFormerConditionStatementListPanelProps> {
    @State.observable.ref private worklistDefinition: IWorklistDefinition = null;
    @State.computed private get hasNewRow() { return this.props.selectedRowId === "new"; }

    private get patientId() { return this.props._patientId; }
    private get userContext() { return this.props._dependencies.userContext; }

    private readonly extensionStore = new ExtensionStore();

    private readonly refreshListEvent = new TypedAsyncEvent();

    private readonly medicalConditionSynchronizationExtensionEvent = new TypedAsyncEvent();

    public componentDidMount() {
        dispatchAsyncErrors(this.initializePanelAsync(), this);
    }

    @State.bound
    private async initializePanelAsync() {
        const resp = await this.props._dependencies.worklistApiAdapter.getConditionStatementBoundWorklistDefinition(this.patientId, this.userContext.id, true);
        State.runInAction(() => {
            this.worklistDefinition = resp.value;
        });
    }

    @State.action.bound
    private setPanelProps(useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) {
        return {
            clinicalStatus: ConditionStatementClinicalStatus.Inactive,
            onConditionStatementCreatedAsync: this.conditionStatementCreatedAsync,
            onCloseDetailAsync: this.closeDetailAsync,
            onRefreshListAsync: this.refreshListAsync
        };
    }

    @State.action.bound
    private async closeDetailAsync() {
        await this.refreshListAsync();
        this.props.onCloseDetail?.();
    }
    
    @State.computed
    private get synchronizationExtensionProps() {
        return {
            medicalConditionType: MedicalConditionType.FormerConditionStatement,
            patientId: this.patientId,
            refreshListAsync: this.refreshListAsync,
            extensionStore: this.extensionStore,
            medicalConditionSynchronizationExtensionEvent: this.medicalConditionSynchronizationExtensionEvent
        } as IMedicalConditionSynchronizationExtensionPointProps;
    }

    @State.computed
    public get renderSynchronizationExtension() {
        if (this.props.useCaseState && this.props.useCaseState.displayMode === UseCaseDisplayMode.MasterDetail) {
            return (<></>);
        }
        return (            
            <HisPlatformExtensionPoint
                type="medicalConditionSynchronizationExtension"
                extensionProps={this.synchronizationExtensionProps}>
                <></>
            </HisPlatformExtensionPoint>
        );
    }

    @State.action.bound
    private  renderSynchronizationErrorHandlerExtension() {
        return (
            <HisPlatformExtensionPoint
                type="medicalConditionSynchronizationErrorHandlerExtension"
                extensionProps={this.synchronizationExtensionProps}
            >
                <></>
            </HisPlatformExtensionPoint>
        );
    }

    @State.action.bound
    private  renderLastSynchronizationSubtitleExtension() {
        return (
            <HisPlatformExtensionPoint
                type={"medicalConditionLastSynchronizationSubtitleExtension"}
                extensionProps={{
                    patientId: this.patientId,
                    medicalConditionType: MedicalConditionType.FormerConditionStatement,
                    medicalConditionSynchronizationExtensionEvent: this.medicalConditionSynchronizationExtensionEvent
                } as IMedicalConditionLastSynchronizationSubtitleExtensionPointProps }
            >
                <></>
            </HisPlatformExtensionPoint>
        );
    }

    @State.action.bound
    private async refreshListAsync() {
        await this.refreshListEvent.emitAsync();
    }

    @State.action.bound
    private async conditionStatementCreatedAsync(id: ConditionStatementId) {
        await this.refreshListAsync();
        this.props.onSelectedConditionStatementIdChange?.(id);
    }

    public render() {
        return (
            <>
                <SingleLayout>
                    <NDataPanel
                        definition={this.worklistDefinition}
                        onChange={this.props.onChange}
                        useCaseState={this.props.useCaseState}
                        selectedRowId={this.props.selectedRowId}
                        hasNewRow={this.hasNewRow}
                        iconName="text_template_past"
                        disableDetailStrictMode
                        refreshListEvent={this.refreshListEvent}
                        onGetPanelProps={this.setPanelProps}
                        beforeGlobalActionsToolbar={this.renderSynchronizationExtension}
                        defaultExtraFilterVisibility={true}
                        extraFilter={this.renderSynchronizationErrorHandlerExtension()}
                        masterSubtitle={this.renderLastSynchronizationSubtitleExtension()}
                    />
                </SingleLayout>
            </>
        );
    }
}

export default connect(
    FormerConditionStatementListPanel,
    new DependencyAdapter<IFormerConditionStatementListPanelProps, IFormerConditionStatementListPanelDependencies>(c => ({
        worklistApiAdapter: c.resolve("WorklistApiAdapter"),
        dialogService: c.resolve<IDialogService>("IDialogService"),
        notificationService: c.resolve<INotificationService>("INotificationService"),
        conditionStatementApiAdapter: c.resolve<ConditionStatementApiAdapter>("ConditionStatementApiAdapter"),
        medicalConditionReferenceDataResolver: c.resolve("IMedicalConditionReferenceDataResolver"),
        lockingNotificationService: c.resolve("ILockingNotificationService"),
        userContext: c.resolve("UserContext")
    })),
    new PatientContextAdapter<IFormerConditionStatementListPanelProps>(c => ({
        _patientId: c.patientId,
    })),
    new FormExtensionRegistryAdapter("ManageMedicalCondition")
);
