import React from "react";
import Contact from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/Contact";
import * as Ui from "@CommonControls";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IContactPanelProps {
    contact: Contact;
    index: number;
}

@State.observer
export default class ContactPanel extends React.Component<IContactPanelProps> {
    public render() {
        const contact = this.props.contact;

        if (!contact) {
            return "";
        }

        return (
            <Ui.TextBox
                value={contact.value}
                onChange={contact.setValue}
                propertyIdentifier="Value"
                automationId="contactTextBox"
            />
        );
    }
}