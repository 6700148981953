import IEhiCovidSelfExaminationStatus from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidSelfExaminationResultPanel/IEhiCovidSelfExaminationStatus";
import IEhiStructuredDocumentMetaData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidSelfExaminationResultPanel/IEhiStructuredDocumentMetaData";
import IEhiStructuredDocumentBloodPressure from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidSelfExaminationResultPanel/IEhiStructuredDocumentBloodPressure";
import IEhiStructuredDocumentQuantity from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidSelfExaminationResultPanel/IEhiStructuredDocumentQuantity";
import IEhiCovidSelfExaminationResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidSelfExaminationResultPanel/IEhiCovidSelfExaminationResult";
import Di from "@Di";
import EhiCovidSelfExaminationGeneralStatusId from "@Primitives/EhiCovidSelfExaminationGeneralStatusId.g";
import EhiCovidBreathingStatusId from "@Primitives/EhiCovidBreathingStatusId.g";
import EhiCovidSymptomStatusId from "@Primitives/EhiCovidSymptomStatusId.g";
import EhiCovidUrineAmountId from "@Primitives/EhiCovidUrineAmountId.g";
import EhiCovidSelfExaminationTimeCodeId from "@Primitives/EhiCovidSelfExaminationTimeCodeId.g";
import moment from "moment";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

@Di.injectable()
export default class EhiCovidSelfExaminationStoreMapper {
    public mapExtensionData(extensionData: any): IEhiCovidSelfExaminationResult {
        return {
            executedAt: moment(extensionData["ExecutedAt"].toString()),
            timeCodeId: EhiCovidSelfExaminationTimeCodeId.fromJS(extensionData["TimeCodeId"]),
            submittedBy: extensionData["SubmittedBy"],
            status: this.mapStatus(extensionData["Status"]),
            metaData: this.mapMetaData(extensionData["MetaData"])
        } as IEhiCovidSelfExaminationResult;
    }

    private mapStatus(status: any): IEhiCovidSelfExaminationStatus {
        return isNullOrUndefined(status) ? null : {
            generalStatusChangeId: EhiCovidSelfExaminationGeneralStatusId.fromJS(status["GeneralStatusChangeId"]),
            generalStatusId: EhiCovidSelfExaminationGeneralStatusId.fromJS(status["GeneralStatusId"]),
            respiratoryRate: this.mapQuantity(status["RespiratoryRate"]),
            breathingStatusId: EhiCovidBreathingStatusId.fromJS(status["BreathingStatusId"]),
            bloodPressure: this.mapBloodPressure(status["BloodPressure"]),
            pulse: this.mapQuantity(status["Pulse"]),
            bodyTemperature: this.mapQuantity(status["BodyTemperature"]),
            coughSymptomStatusId: EhiCovidSymptomStatusId.fromJS(status["CoughSymptomStatusId"]),
            soreThroatSymptomStatusId: EhiCovidSymptomStatusId.fromJS(status["SoreThroatSymptomStatusId"]),
            bloodSugar: this.mapQuantity(status["BloodSugar"]),
            muscleOrJointPainSymptomStatusId: EhiCovidSymptomStatusId.fromJS(status["MuscleOrJointPainSymptomStatusId"]),
            headacheSymptomStatusId: EhiCovidSymptomStatusId.fromJS(status["HeadacheSymptomStatusId"]),
            vomitSymptomStatusId: EhiCovidSymptomStatusId.fromJS(status["VomitSymptomStatusId"]),
            diarrheaSymptomStatusId: EhiCovidSymptomStatusId.fromJS(status["DiarrheaSymptomStatusId"]),
            urineAmountId: EhiCovidUrineAmountId.fromJS(status["UrineAmountId"]),
            knownChronicConditionStatusId: EhiCovidSymptomStatusId.fromJS(status["KnownChronicConditionStatusId"]),
            notes: status["Notes"]
        } as IEhiCovidSelfExaminationStatus;
    }

    private mapMetaData(metaData: any): IEhiStructuredDocumentMetaData {
        return isNullOrUndefined(metaData) ? null : {
            contentSpec: metaData["ContentSpec"],
            contentCategory: metaData["ContentCategory"],
            contentType: metaData["ContentType"],
            contentRm: metaData["ContentRm"],
            contentDescription: metaData["ContentDescription"]
                } as IEhiStructuredDocumentMetaData;
    }

    private mapBloodPressure(bloodPressure: any): IEhiStructuredDocumentBloodPressure {
        return isNullOrUndefined(bloodPressure) ? null : {
            systolic: this.mapQuantity(bloodPressure["Systolic"]),
            diastolic: this.mapQuantity(bloodPressure["Diastolic"])
        
        } as IEhiStructuredDocumentBloodPressure;
    }

    private mapQuantity(quantity: any): IEhiStructuredDocumentQuantity {
        return isNullOrUndefined(quantity) ? null : {
            value: quantity["Value"],
            measurementUnit: quantity["MeasurementUnit"],
            comparator: quantity["Comparator"]
        } as IEhiStructuredDocumentQuantity;
    }
}