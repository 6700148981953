import DependencyContainer from "@DiContainer";
import DynamicValueConverter from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ValueConverters/DynamicValueConverter";
import "./TemporaryDataConfiguration";
import "./PersonalizationConfiguration";
import "./WorklistConfiguration";
import PersonalizationApiAdapter from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/Personalization/PersonalizationApiAdapter";
import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import ProductivityBoundedContext from "@HisPlatform/BoundedContexts/Productivity/ProductivityBoundedContext";

DependencyContainer
    .bind("IBoundedContext")
    .to(ProductivityBoundedContext);

DependencyContainer
    .bind("DynamicValueConverter")
    .to(DynamicValueConverter);

DependencyContainer
    .bind("PersonalizationApiAdapter")
    .to(PersonalizationApiAdapter);