import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalConditionItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionItemBase";
import PregnancyStatementId from "@Primitives/PregnancyStatementId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

export default class PregnancyStatementStore extends MedicalConditionItemBase<PregnancyStatementId> {
    @State.observable public expectedAt: LocalDate;   

    constructor(isNew: boolean) {
        super(isNew);
    }

    @State.action.bound
    public setExpectedAt(newValue: LocalDate) {
        this.expectedAt = newValue;
    }
}