import { useFormEntityIdField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { ICustomBlockRegistryItem, IFormCustomBlockComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React, { useEffect, useState } from "react";
import * as Ui from "@CommonControls";
import StaticResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import PatientId from "@Primitives/PatientId.g";
import PatientApiAdapter2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/PatientApiAdapter2";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import { useErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";

function PatientInfoBoxFormCustomBlock(props: IFormCustomBlockComponentProps) {
    const [sourcePatientId, _] = useFormEntityIdField<PatientId>(props.form.data.Content, "SourcePatientId", PatientId);

    const { apiAdapter } = useDependencies(c => ({
        apiAdapter: c.resolve<PatientApiAdapter2>("PatientApiAdapter2")
    }));

    const [nameInfo, setNameInfo] = useState<string>();

    const dispatcher = useErrorDispatcher();

    useEffect(() => {
        (async () => {
            if (sourcePatientId) {
                const data = await apiAdapter.getPatientNameAndIdentifierAsync(sourcePatientId);
                const nameInfo = `${data.result.identifier} - ${data.result.name.familyName} ${data.result.name.givenName1}`;
                setNameInfo(nameInfo);
            }
            else {
                setNameInfo(null);
            }
        })()
            .catch(dispatcher);
    }, [sourcePatientId?.value]);

    return (
        <Ui.TextBox
            value={nameInfo}
            label={StaticResources.PatientRegister.PatientInfoBox.Label}
            propertyIdentifier="PatientInfoBox"
            automationId="patientInfoBox"
            isReadOnly={true}
        />
    );
}

export const PatientInfoBoxRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Patient info box",
    dataElementsFactory: () => [],
    componentType: State.observer(PatientInfoBoxFormCustomBlock)
};

export default PatientInfoBoxFormCustomBlock;
