import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import AppointmentScheduleDefinition from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentScheduleDefinition";

interface ISchedulingServiceColumnDependencies {
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
}

interface ISchedulingServiceColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: ISchedulingServiceColumnDependencies;
    displayScheduleDefinitionName?: boolean;
}

const getDisplayValue = (service: SchedulingServiceSubjectStore, appointmentScheduleDefinition: AppointmentScheduleDefinition, displayScheduleDefinitionName: boolean) => {
    if (displayScheduleDefinitionName && !!appointmentScheduleDefinition) {
        return <><b>{service.code}</b>{` ${service.name} - ${appointmentScheduleDefinition.name}`}</>;
    }

    return <><b>{service.code}</b>{` - ${service.name}`}</>;
};

export function schedulingServiceColumnCellRenderer(schedulingReferenceDataStore: SchedulingReferenceDataStore, value: SchedulingServiceId, displayScheduleDefinitionName: boolean) {
    const service = schedulingReferenceDataStore.schedulingServices.get(value);
    const scheduleDefinition = service ? schedulingReferenceDataStore.appointmentScheduleDefinitions.items.find(a => a.ownedSchedulingServices.some(o => o.value === value.value)) : null;
    return service && getDisplayValue(service, scheduleDefinition, displayScheduleDefinitionName);
}

const SchedulingServiceColumn: React.FC<ISchedulingServiceColumnProps> = (props: ISchedulingServiceColumnProps) => {

    const valueRenderer = useCallback((value: SchedulingServiceId) => {
        return schedulingServiceColumnCellRenderer(props._dependencies.schedulingReferenceDataStore, value, props.displayScheduleDefinitionName);
    }, []);

    return (
        <DataGridColumn
            {...props}
            onRenderCellValue={valueRenderer}
        />
    );
};

export default connect(
    SchedulingServiceColumn,
    new DependencyAdapter<ISchedulingServiceColumnProps, ISchedulingServiceColumnDependencies>(c => ({
        schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore"),
    }))
);
