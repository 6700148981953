import React from "react";
import * as Ui from "@CommonControls";
import ErrorBoundary from "@Toolkit/ReactClient/Components/ErrorBoundary/ErrorBoundary";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import BusinessErrorBase from "@HisPlatform/Model/DomainModel/BusinessError/BusinessErrorBase";

interface IHisErrorBoundaryDependencies {
    notificationService: INotificationService;
}


interface IHisErrorBoundaryProps {
    _dependencies?: IHisErrorBoundaryDependencies;
    children?: React.ReactNode;
    onRenderError?: (err: Error, reload: () => void) => React.ReactNode;
}


const HisErrorBoundary: React.FC<IHisErrorBoundaryProps> = props => {
    return (
        <ErrorBoundary
            onRenderError={props.onRenderError || renderError}
        >
            {props.children}
        </ErrorBoundary>
    );
};

const renderError = (err: Error, reload: () => void) => {
    const error = (err as any)?.error && (err as any).error instanceof BusinessErrorBase ? (err as any).error : err;
    const tooltipContent = (
        <>
            <b>{StaticWebAppResources.Common.Messages.TechnicalInformations}</b><br />
            {StaticWebAppResources.Common.Messages.Type} {error.message ?? error.name}<br />
            {error.stack}
        </>
    );

    return (
        <>
            <Ui.Message type="danger">
                <Ui.Flex>
                    <Ui.Flex.Item>
                        <Ui.Icon iconName="exclamation" size="large" visualStyle="error" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item>
                        <h3 style={{ margin: 0 }}>{StaticWebAppResources.Common.Messages.Header}</h3>
                        <span>{StaticWebAppResources.Common.Messages.Informing}</span>
                        {error && <Ui.InfoButton tooltipMaxWidth="100%" tooltipContent={tooltipContent} />}
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex xsJustify="center">
                    <Ui.Flex.Item>
                        <Ui.Button iconName="sync" onClick={reload} text={StaticWebAppResources.Common.Button.Reload} visualStyle="link" automationId="reloadButton" />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.Message>
            <div data-automation-id="tooltip-error-message" style={{ display: "none" }}>
                {tooltipContent}
            </div>
        </>
    );
};

export default connect(
    HisErrorBoundary,
    new DependencyAdapter<IHisErrorBoundaryProps, IHisErrorBoundaryDependencies>(c => ({
        notificationService: c.resolve("INotificationService")
    }))
);


export function withHisErrorBoundary<TProps>(Component: React.ComponentClass<TProps> | React.FC<TProps>) {
    return (props: TProps) => (
        <HisErrorBoundary>
            <Component {...props} />
        </HisErrorBoundary>
    );
}

export {
    renderError
};