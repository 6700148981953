import * as React from "react";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import HisErrorBoundary from "@HisPlatformControls/HisErrorBoundary/HisErrorBoundary";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import State from "@Toolkit/ReactClient/Common/StateManaging";

import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PatientContextProvider from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextProvider";
import CareActivityContextProvider from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextProvider";
import CareActivityId from "@Primitives/CareActivityId.g";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import ShowCareActivityStateChangeNotPossibleClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/ShowCareActivityStateChangeNotPossibleClientSideAction";
import CareActivityStatusChangeNotPossibleDialogParams, {
    ICareActivityStatusChangeNotPossibleDialogResult
} from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityStatusChangeNotPossiblePanel/CareActivityStatusChangeNotPossibleDialogParams";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import Action from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/Action";
import DisplayPrintoutContentAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/DisplayPrintoutContentAction";
import ExecuteClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ExecuteClientSideAction";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import InputDataClientSideAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/ClientSideActions/InputDataClientSideAction";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IWorklistItemDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistItemDefinition";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ModalServiceAdapter from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAdapter";
import RequestedServicesFilter from "@HisPlatform/BoundedContexts/Care/Components/Panels/Worklist/CareActivityWorklist/Filters/RequestedServicesFilter";
import { getIconNameByHealthcareProfessions } from "@HisPlatform/BoundedContexts/Organization/Components/Helpers/IconTypeNameHelper";
import { RowId, IRowBody } from "@CommonControls/DataGrid/IDataGridProps";
import RequestedServicesFilterStore from "@HisPlatform/BoundedContexts/Care/Components/Panels/Worklist/CareActivityWorklist/Filters/RequestedServicesFilterStore";
import _ from "@HisPlatform/Common/Lodash";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import DocumentApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/DocumentApiAdapter";
import DocumentPreviewModalParams from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Modals/DocumentPreviewModal/DocumentPreviewModalParams";
import DocumentId from "@Primitives/DocumentId.g";
import MostRelevantServicesFilter from "./Filters/MostRelevantServicesFilter";
import MostRelevantServicesFilterStore from "./Filters/MostRelevantServicesFilterStore";
import CantCloseCareActivityError from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CantCloseCareActivityError";
import CantCloseCareActivityDialogParams, { ICantCloseCareActivityDialogResult, CantCloseCareActivityDialogNavigateTo } from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CantCloseCareActivityPanel/CantCloseCareActivityDialogParams";
import StateChangeBlockingData from "@Primitives/StateChangeBlockingData.g";
import { getRowIndicatorStyle } from "@HisPlatform/BoundedContexts/Care/Components/Panels/Worklist/CareActivityWorklist/RowIndicatorProvider";
import NDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanel";
import { DashboardLayout } from "@HisPlatformControls";
import HisUseCaseIdentifierSetter from "@HisPlatform/Components/HisUseCaseIdentifierSetter";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import INDataRow from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataRow";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import INDataAction from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataAction";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import { ICareActivityWorklistCommonService } from "@HisPlatform/Services/Definition/CareActivityWorklistCommonService/ICareActivityWorklistCommonService";
import IWorklistAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistAction";
import ActionPlacement from "@Primitives/ActionPlacement";
import WorklistActionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistActionType.g";
import WorklistCareActivityMenu from "@HisPlatform/BoundedContexts/Care/Components/Panels/Worklist/CareActivityWorklist/WorklistCareActivityMenu";
import WorklistDefinitionReferenceDataStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/WorklistDefinition/WorklistDefinitionReferenceDataStore";

interface ICareActivityWorklistPanelDependencies {
    useCaseRegistry: IUseCaseRegistry;
    notificationService: INotificationService;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    documentApiAdapter: DocumentApiAdapter;
    careActivityWorklistCommonService: ICareActivityWorklistCommonService;
    worklistDefinitionReferenceDataStore: WorklistDefinitionReferenceDataStore;
}

interface ICareActivityWorklistPanelProps {
    _dependencies?: ICareActivityWorklistPanelDependencies;
    _modalService?: IModalService;

    worklistLocalId: string;
    selectedRowId?: string;
    useCase?: INDataUseCaseState;
    onUseCaseChange?: (rowId: RowId, useCaseState: INDataUseCaseState) => void;

    navigateByAction?: (careActivityId: CareActivityId, navigateTo: StateChangeBlockingData) => void;
    cantCloseCareActivityNavigateByAction?: (careActivityId: CareActivityId, navigateTo: CantCloseCareActivityDialogNavigateTo) => void;
}

@State.observer
class CareActivityWorklistPanel extends React.Component<ICareActivityWorklistPanelProps> {

    // #region dependencies
    private get modalService() {
        return this.props._modalService;
    }

    private get notificationService() {
        return this.props._dependencies.notificationService;
    }

    private get worklistDefinitionReferenceDataStore() {
        return this.props._dependencies.worklistDefinitionReferenceDataStore;
    }

    // #endregion

    private refreshAsyncEvent = new TypedAsyncEvent();
    private requestedServicesFilterStore: RequestedServicesFilterStore = null;
    private mostRelevantServicesFilterStore: MostRelevantServicesFilterStore = null;

    @State.observable.ref private itemDefinitions: IWorklistItemDefinition[] = null;
    @State.observable.ref private rightClickedRow: INDataRow | null = null;

    @State.computed private get rightClickedActions(): IWorklistAction[] | null {
        return this.rightClickedRow?.__actions.filter(val => (val.placement === ActionPlacement.Context || val.placement === ActionPlacement.Both) && val.worklistActionType !== WorklistActionType.Batch) ?? null;
    }

    @State.computed private get worklistDefinition() {
        return this.worklistDefinitionReferenceDataStore.getByLocalId(this.props.worklistLocalId);
    }

    @State.computed private get iconName() {
        return getIconNameByHealthcareProfessions(this.worklistDefinition.healthcareProfessionIds.map(this.props._dependencies.organizationReferenceDataStore.healthCareProfession.get));
    }

    @State.bound
    private handleCareActivityAndDocumentError() {
        this.notificationService.error(StaticCareResources.PatientRegister.PatientDocuments.Dialog.CareActivityAndDocumentError);
        return true;
    }

    @State.bound
    private handleCantCloseCareActivityError(err: CantCloseCareActivityError) {
        dispatchAsyncErrors(this.modalService.showDialogAsync<ICantCloseCareActivityDialogResult>(
            new CantCloseCareActivityDialogParams(
                err.careActivityId,
                err.validationResult
            )
        ).then(result => {
            if (!isNullOrUndefined(result) && !isNullOrUndefined(result.navigateTo)) {
                this.props.cantCloseCareActivityNavigateByAction(err.careActivityId, result.navigateTo);
            }
        }), this);

        return true;
    }

    @State.action.bound
    private onFilterStoreCreatedAsync(filterStore: any) {
        this.requestedServicesFilterStore = new RequestedServicesFilterStore(filterStore);
        this.mostRelevantServicesFilterStore = new MostRelevantServicesFilterStore(filterStore);
        return Promise.resolve();
    }

    @State.bound
    private getExtendedFilterDescriptors() {
        return RequestedServicesFilterStore.getFilterDescriptors().concat(MostRelevantServicesFilterStore.getFilterDescriptors());
    }

    @State.action.bound
    private showCareActivityStateChangeNotPossible(action: ShowCareActivityStateChangeNotPossibleClientSideAction) {
        dispatchAsyncErrors(this.modalService.showDialogAsync<ICareActivityStatusChangeNotPossibleDialogResult>(
            new CareActivityStatusChangeNotPossibleDialogParams(
                action.careActivityId,
                action.activityReferenceResourceId,
                action.reasons,
                action.title
            )
        ).then(result => {
            if (!!result?.navigateTo) {
                this.props.navigateByAction(action.careActivityId, result.navigateTo);
            }
        }), this);
    }

    @State.bound
    private async showDocumentPreviewModalAsync(documentId: DocumentId) {
        await this.modalService.showModalAsync(new DocumentPreviewModalParams(documentId));
    }

    @State.action.bound
    private async processInputDataClientSideActionAsync(clientSideAction: InputDataClientSideAction) {
        return await this.props._dependencies.careActivityWorklistCommonService.performClientSideActionAsync(clientSideAction, this.props._modalService);
    }

    @State.bound
    private initializeFilter(filterStore: any, itemDefinitions: IWorklistItemDefinition[]) {
        if (!filterStore) {
            return;
        }
        const wentUnderCareAtDef = itemDefinitions.filter(d => d.attributeName === "CareActivityBased_MostRelevantDate");
        if (wentUnderCareAtDef && isNullOrUndefined(filterStore["CareActivityBased_MostRelevantDate"])) {
            const setter = filterStore["set_CareActivityBased_MostRelevantDate"];
            if (setter) {
                setter(new LocalDateRange(LocalDate.today(), LocalDate.today().plusDays(90)));
            }
        }
    }

    @State.bound
    private renderExtraFilter() {
        if (this.itemDefinitions?.some(item => item.attributeType === "RequestedServiceDto[]")) {
            return (
                <RequestedServicesFilter filterStore={this.requestedServicesFilterStore} />
            );
        }
        if (this.itemDefinitions?.some(item => item.attributeType === "CareRequestedAndMedicalServiceDto[]")) {
            return (
                <MostRelevantServicesFilter filterStore={this.mostRelevantServicesFilterStore} />
            );
        }
        return (<></>);
    }

    @State.bound
    private renderRowBody(row: INDataRow, rowId: RowId, rowIndex: number, rowBodyItemDefinition: IWorklistItemDefinition): IRowBody {
        return this.props._dependencies.careActivityWorklistCommonService.renderRowBody(row, rowId, rowIndex, rowBodyItemDefinition);
    }

    private clearFilterStores() {
        if (this.requestedServicesFilterStore) {
            this.requestedServicesFilterStore.clearFilters();
        }

        if (this.mostRelevantServicesFilterStore) {
            this.mostRelevantServicesFilterStore.clearFilters();
        }
    }

    public render() {
        if (isNullOrUndefined(this.worklistDefinition)) {
            return null;
        }

        return (
            <DashboardLayout>
                <HisUseCaseIdentifierSetter value="CareRegister_DailyPatientList" />
                <HisErrorBoundary>
                    <SingleLayout>
                        <NDataPanel
                            key={this.worklistDefinition.localId}
                            hasRefreshButton
                            definition={this.worklistDefinition}
                            selectedRowId={this.props.selectedRowId}
                            useCaseState={this.props.useCase}
                            onChange={this.props.onUseCaseChange}
                            disableDetailStrictMode
                            onRenderDetail={this.renderDetail}
                            onGetExtendedFilterDescriptors={this.getExtendedFilterDescriptors}
                            onRenderRowBody={this.renderRowBody}
                            onFilterStoreCreatedAsync={this.onFilterStoreCreatedAsync}
                            getRowIndicatorStyle={getRowIndicatorStyle}
                            iconName={this.iconName}
                            extraFilter={this.renderExtraFilter()}
                            onPerformClientSideActionAsync={this.performClientSideActionAsync}
                            onPerformActionAsync={this.performActionAsync}
                            refreshListEvent={this.refreshAsyncEvent}
                            onClearFilter={this.clearFilterStores}
                            onInitializeFilter={this.initializeFilter}
                            onDefinitionLoaded={this.setDefinition}
                            onRowRightClick={this.openRowCareMenu}
                        />
                    </SingleLayout>
                    <BusinessErrorHandler.Register
                        businessErrorName="CareDocumentDoesNotExistForGivenCareActivityAndDocumentType"
                        handler={this.handleCareActivityAndDocumentError} />
                    <BusinessErrorHandler.Register businessErrorName="CantCloseCareActivityError" handler={this.handleCantCloseCareActivityError} />

                    {this.rightClickedActions && (
                        <WorklistCareActivityMenu
                            row={this.rightClickedRow}
                            onClose={this.closeRowCareMenu}
                            onActionProcessed={this.actionProcessed}
                            onPerformClientSideActionAsync={this.performClientSideActionAsync}
                        />
                    )}

                </HisErrorBoundary>
            </DashboardLayout >
        );
    }

    @State.action.bound
    private openRowCareMenu(row: INDataRow, rowId: RowId, rowIndex: number) {
        this.rightClickedRow = row;
    }

    @State.action.bound
    private closeRowCareMenu() {
        this.rightClickedRow = null;
    }

    @State.bound
    private actionProcessed() {
        dispatchAsyncErrors(this.refreshAsyncEvent.emitAsync(), this);
    }

    @State.action.bound
    private setDefinition(_globalActions: INDataAction[], itemDefinitions: IWorklistItemDefinition[]) {
        this.itemDefinitions = itemDefinitions;
    }

    @State.action.bound
    private async performClientSideActionAsync(clientSideAction: ClientSideActionDto) {
        if (clientSideAction instanceof InputDataClientSideAction) {
            return await this.processInputDataClientSideActionAsync(clientSideAction);
        } 
        else if (clientSideAction instanceof ShowCareActivityStateChangeNotPossibleClientSideAction) {
            this.showCareActivityStateChangeNotPossible(clientSideAction);
            return null;
        }
        else {
            throw new Error(`Cannot process client side action of type ${clientSideAction.constructor.name}`);
        }
    }

    @State.bound
    private async performActionAsync(action: Action) {

        if (action instanceof DisplayPrintoutContentAction && !!action.documentId) {
            await this.showDocumentPreviewModalAsync(action.documentId);
            return true;
        }

        if (action instanceof ExecuteClientSideAction && action.action instanceof ShowCareActivityStateChangeNotPossibleClientSideAction) {
            this.showCareActivityStateChangeNotPossible(action.action);
            return true;
        }

        return false;
    }

    @State.bound
    private renderDetail(detailContent: React.ReactNode) {
        const careActivityId = this.props.useCase?.useCaseArguments?.find(a => a.value instanceof CareActivityId);

        return careActivityId ? (
            <CareActivityContextProvider careActivityId={careActivityId.value}>
                <PatientContextProvider>
                    {detailContent}
                </PatientContextProvider>
            </CareActivityContextProvider>
        ) : detailContent;
    }

}

export default connect(
    CareActivityWorklistPanel,
    new DependencyAdapter<ICareActivityWorklistPanelProps, ICareActivityWorklistPanelDependencies>(c => ({
        userContext: c.resolve("UserContext"),
        useCaseRegistry: c.resolve("IUseCaseRegistry"),
        notificationService: c.resolve("INotificationService"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        documentApiAdapter: c.resolve("DocumentApiAdapter"),
        careActivityWorklistCommonService: c.resolve("ICareActivityWorklistCommonService"),
        worklistDefinitionReferenceDataStore: c.resolve("WorklistDefinitionReferenceDataStore"),
    })),
    new ModalServiceAdapter()
);
