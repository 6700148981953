import React from "react";
import * as Ui from "@CommonControls";
import WeekOfMonthSelector from "./WeekOfMonthSelector";
import WeekParitySelector from "./WeekParitySelector";
import { DayOfWeekSelector } from "./DayOfWeekSelector";
import RecurrenceFrequency from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/RecurrenceFrequency";
import RecurrenceFrequencySelector from "./RecurrenceFrequencySelector";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import WeekParity from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/WeekParity";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import TimePhaseInterval from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/TimePhaseInterval";
import DayOfWeek from "@HisPlatform/BoundedContexts/Scheduling/Api/Configuration/Enum/DayOfWeek.g";
import WeekOfMonth from "@HisPlatform/BoundedContexts/Scheduling/Api/Configuration/Enum/WeekOfMonth.g";

interface IRecurringTimePhasePanelviewProps {
    interval: TimePhaseInterval;
    setTimePhaseInterval: (from: TimeOfDay, to: TimeOfDay, isFinal: boolean) => void;

    recurrenceFrequency: RecurrenceFrequency;
    setRecurrenceFrequency: (value: RecurrenceFrequency) => void;

    weeksOfMonth: WeekOfMonth[];
    setWeeksOfMonth: (value: WeekOfMonth[]) => void;

    weekParity: WeekParity;
    setWeekParity: (value: WeekParity) => void;

    daysOfWeek: DayOfWeek[];
    setDaysOfWeek: (value: DayOfWeek[]) => void;
}


const RecurringTimePhasePanelView: React.FC<IRecurringTimePhasePanelviewProps> = props => {
    const resources = StaticSchedulingResources.TimePhaseEditorDialog.RecurringTimePhasePanel;
    return (
        <ValidationBoundary
            pathPrefix="RecurringOccurrence.Expression"
            validateOnMount
        >
            <Ui.Flex>
                <Ui.Flex.Item xs={6}>
                    <Ui.TimeRangePicker
                        propertyIdentifier="Interval.From,To"
                        label={resources.Interval}
                        onTimePickerChange={props.setTimePhaseInterval}
                        valueFrom={props.interval?.start}
                        valueTo={props.interval?.end}
                        automationId="timePhaseIntervalPicker"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={6}>
                    <RecurrenceFrequencySelector
                        propertyIdentifier="Element"
                        label={resources.Frequency}
                        value={props.recurrenceFrequency}
                        onChange={props.setRecurrenceFrequency}
                        displayMode="selectBox"
                        clearable={false}
                        automationId="recurrenceFrequencySelector"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Flex>
                <Ui.Flex.Item xs={6}>
                    {props.recurrenceFrequency === RecurrenceFrequency.Monthly ?
                        <WeekOfMonthSelector
                            propertyIdentifier="Element"
                            label={resources.WeekOfMonth}
                            value={props.weeksOfMonth}
                            onChange={props.setWeeksOfMonth}
                            orderItems={false}
                            multiSelect
                            displayMode="checkBoxButton"
                            direction="row"
                            checkBoxGroupProps={{ justifyItems: "evenly" }}
                            automationId="weeksOfMonthSelector"
                        /> :
                        <WeekParitySelector
                            propertyIdentifier="Element"
                            label={resources.WeekParity}
                            value={props.weekParity}
                            onChange={props.setWeekParity}
                            displayMode="selectBox"
                            clearable={false}
                            automationId="weekParitySelector"
                        />
                    }
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={6}>
                    <DayOfWeekSelector
                        propertyIdentifier="Element"
                        label={resources.DaysOfWeek}
                        value={props.daysOfWeek}
                        onChange={props.setDaysOfWeek}
                        orderItems={false}
                        multiSelect
                        displayValue="shorthand"
                        displayMode="checkBoxButton"
                        direction="row"
                        checkBoxGroupProps={{ justifyItems: "evenly" }}
                        automationId="daysOfWeekSelector"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        </ValidationBoundary>
    );
};
export default RecurringTimePhasePanelView;