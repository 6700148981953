import ExtendedFilterStoreBase from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ExtendedFilterStoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import { ICondition } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistFilterCondition";
import { createFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterHelpers";
import { IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import StringComparisonType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/StringComparisonType.g";

export default class MostRelevantServicesFilterStore extends ExtendedFilterStoreBase {

    constructor(
        filterStore: IFilterStore
    ) {
        super(filterStore);
    }

    @State.computed public get entityIds() {
        const services = this.getFilter("CareActivityBased_MostRelevantServices");
        return services;
    }
    
    @State.action.bound
    public setEntityIds(newValue: Array<MedicalServiceId | SchedulingServiceId>) {
        this.setFilter("CareActivityBased_MostRelevantServices", newValue);
    }

    @State.action.bound
    public clearFilters() {
        this.setFilter("CareActivityBased_MostRelevantServices", "");
    }

    @State.bound
    public static getFilterDescriptors() {
        const descriptors: IDataGridFilterDescriptor[] = [];

        const descriptor = createFilterDescriptor(
            "CareActivityBased_MostRelevantServices",
            {
                serialize: (value: any) => {
                    return this.toStringRepresentation(value);
                },
                deserialize: (value: string) => {
                    return this.fromStringRepresentation(value);
                }
            } as IDataGridFilterValueSerializer<any>,
            WorklistConditionType.String,
            null,
            this.customConverter);
        descriptors.push(descriptor);

        return descriptors;
    }

    private static customConverter(value: Array<MedicalServiceId | SchedulingServiceId>): ICondition {
        const stringValues = value.map(i => {
            if (i instanceof MedicalServiceId) {
                return "M-" + i.value;
            } else if (i instanceof SchedulingServiceId) {
                return "S-" + i.value;
            } else {
                return "";
            }
        });

        return {
            type: WorklistConditionType.String,
            comparisonType: StringComparisonType.Contains,
            value: stringValues.join(";")
        };
    }

    private static toStringRepresentation(entityIds: Array<MedicalServiceId | SchedulingServiceId>) {
        const stringValues = entityIds.map(i => {
            if (i instanceof MedicalServiceId) {
                return "M-" + i.value;
            } else if (i instanceof SchedulingServiceId) {
                return "S-" + i.value;
            } else {
                return "";
            }
        });

        return stringValues.join(";");
    }

    private static fromStringRepresentation(value: string) {
        const rawIds = value.split(";");

        return rawIds.map(i => {
            const idParts = i.split("-");
            if (idParts[0] === "M") {
                return new MedicalServiceId(idParts[1]);
            } else {
                return new SchedulingServiceId(idParts[1]);
            }
        });
    }
}
