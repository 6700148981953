import React from "react";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import * as Ui from "@CommonControls";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import CareActivityId from "@Primitives/CareActivityId.g";
import PatientId from "@Primitives/PatientId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import { ActionDispatcherAdapter } from "@Toolkit/ReactClient/ActionProcessing/ActionDispatcher";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import CareActivityBaseData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareActivityBaseData";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import ShowReadOnlyPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowReadOnlyPatientScreenAction.g";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";

interface ICurativeSupplyPassAlertCardDependencies {
    routingStore: GlobalRoutingStore;
}

interface ICurativeSupplyPassAlertCardProps {
    _dependencies?: ICurativeSupplyPassAlertCardDependencies;
    _careActivityId?: CareActivityId;
    _careActivityBaseData?: CareActivityBaseData;
    _patientId?: PatientId;
    _patient?: PatientAdministrativeData;
    _actionDispather?: IActionDispatcher;
}

@State.observer
class CurativeSupplyPassAlertCard extends React.Component<ICurativeSupplyPassAlertCardProps> {

    private get routingStore() { return this.props._dependencies.routingStore; }
    private get documents() { return this.props._patient?.patientDocuments; }

    @State.computed private get isCareActivityDataAvailable() {
        return !!this.props._careActivityId;
    }

    @State.computed private get referenceDate() {
        if (!this.isCareActivityDataAvailable) {
            return DateTimeService.now();
        }
        const wentUnderCareAt = this.props._careActivityBaseData?.wentUnderCareAt;
        return !isNullOrUndefined(wentUnderCareAt) ? wentUnderCareAt : DateTimeService.now();
    }

    @State.bound
    private navigateToPatientData() {
        this.props._actionDispather.dispatchAsync(new ShowReadOnlyPatientScreenAction(ScreenDisplayMode.Full , this.props._patientId));
    }

    private get patientHasValidCurativeSupplyPass() {
        return this.documents.some(d => d.identifierSystemId.value === "CurativeSupplyPass" && d.isDocumentValidAtTime(this.referenceDate));
    }

    public render() {
        if (!this.patientHasValidCurativeSupplyPass) {
            return <></>;
        }

        return (
            <Ui.AlertCard
                label={StaticHunEHealthInfrastructureCareResources.CurativeSupplyPassInfo.PatientHasCurativeSupplyPass}
                visualStyle="info"
                innerIconName="info"
                onClick={this.navigateToPatientData}
            />
        );
    }
}

export default connect(
    CurativeSupplyPassAlertCard,
    new DependencyAdapter<ICurativeSupplyPassAlertCardProps, ICurativeSupplyPassAlertCardDependencies>(c => ({
        routingStore: c.resolve("GlobalRoutingStore")
    })),
    new CareActivityContextAdapter<ICurativeSupplyPassAlertCardProps>(c => ({
        _careActivityBaseData: c?.baseData,
        _careActivityId: c?.careActivityId
    })),
    new PatientContextAdapter<ICurativeSupplyPassAlertCardProps>(c => ({
        _patientId: c.patientId,
        _patient: c.patient
    })),
    new ActionDispatcherAdapter()
);