import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import PackagingUnitId from "@Primitives/PackagingUnitId.g";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";

interface IPackagingUnitSelectorDependencies {
    medicationRequestReferenceDataStore: MedicationRequestReferenceDataStore;
}

interface IPackagingUnitSelectorProps extends ISelectBoxBaseProps {
    _dependencies?: IPackagingUnitSelectorDependencies;
}

/** @screen */
@State.observer
class PackagingUnitSelector extends React.Component<IPackagingUnitSelectorProps> {

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    private async loadAsync() {
        await this.props._dependencies.medicationRequestReferenceDataStore.packagingUnits.ensureAllLoadedAsync();
    }

    private get items(): Array<ISelectBoxItem<PackagingUnitId>> {
        return this.props._dependencies.medicationRequestReferenceDataStore.packagingUnits.items.map(i => ({
            text: i.latinName,
            value: i.id,
            longDisplayValue: i.latinName
        } as ISelectBoxItem<PackagingUnitId>)) || [];
    }

    public render() {
        return (
            <Ui.SelectBox
                {...this.props}
                items={this.items}
            />
        );
    }
}

export default connect(
    PackagingUnitSelector,
    new DependencyAdapter<IPackagingUnitSelectorProps, IPackagingUnitSelectorDependencies>(c => ({
        medicationRequestReferenceDataStore: c.resolve("MedicationRequestReferenceDataStore")
    }))
);