import Di from "@Di";
import DeviceUseStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/DeviceUseStatementStore";
import DeviceUseStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/DeviceUseStatementHistoryItem";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import ReferencedDeviceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/ReferencedDeviceAssignment";
import DeviceDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/DeviceUseStatement/DeviceDetail";
import DeviceUseStatementId from "@Primitives/DeviceUseStatementId.g";
import moment from "moment";
import UsageDetail from "./UsageDetail";

@Di.injectable()
export default class DeviceUseStatementFactory {

    public createNewStore() {
        const store = new DeviceUseStatementStore(true);
        store.id = new DeviceUseStatementId("new");
        store.extendedDeviceName = "";
        store.deviceAssignment = new ReferencedDeviceAssignment(null);
        store.historyItems.push(this.createNewHistoryItem());
        store.currentItemIndex = 0;

        return store;
    }

    private createNewHistoryItem() {
        const historyItem = new DeviceUseStatementHistoryItem();

        historyItem.isNew = true;
        historyItem.versionNumber = 1;
        historyItem.index = 0;
        historyItem.createdAt = moment(moment.now());
        historyItem.historyItemDetail = new MedicalConditionHistoryItemDetail();
        historyItem.deviceDetail = new DeviceDetail("", "", "", "");
        historyItem.usageDetail = new UsageDetail(LocalDate.today(), LocalDate.today());

        return historyItem;
    }
}
