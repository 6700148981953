import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import { IDashboardsClient, GetDashboardControllerDto } from "@HisPlatform/BoundedContexts/Dashboards/Api/Proxy.g";
import Di from "@Di";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import IDashboardWidget from "@HisPlatform/BoundedContexts/Dashboards/ApplicationLogic/Model/Dashboards/IDashboardWidget";
import IDashboard from "@HisPlatform/BoundedContexts/Dashboards/ApplicationLogic/Model/Dashboards/IDashboard";
import PointOfCareId from "@Primitives/PointOfCareId.g";

@Di.injectable()
export default class DashboardsApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IDashboardsClient") private readonly apiClient: IDashboardsClient
    ) {
        super();
    }

    public getDashboardAsync(useCaseIdentifier: UseCaseIdentifier, pointOfCareId: PointOfCareId): Promise<SimpleStore<IDashboard>> {
        return this.processOperationAsync(
            new SimpleStore<IDashboard>(),
            async target => {
                const response = await this.apiClient.getDashboardQueryAsync(
                    CreateRequestId(),
                    new GetDashboardControllerDto(
                        {
                            useCaseIdentifier: useCaseIdentifier,
                            pointOfCareId: pointOfCareId
                        })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.dashboard && {
                    id: response.dashboard.id,
                    widgets: response.dashboard.widgets.map(w => ({
                        widgetName: w.widgetName,
                        configuration: w.configuration
                    } as IDashboardWidget))
                };
            }
        );
    }
}