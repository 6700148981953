import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { VaccineCodeSelectorItem } from "@HisPlatformControls/VaccineCodeSelector/VaccineCodeSelectorItem";
import * as HisUi from "@HisPlatformControls";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import VaccinesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ReferenceData/VaccinesApiAdapter";
import IVaccine from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IVaccine";
import IMedicalConditionReferenceDataResolver from "@HisPlatform/BoundedContexts/Care/Services/Definition/MedicalConditionReferenceDataResolver/IMedicalConditionReferenceDataResolver";

interface IVaccineCodeSelectorDependencies {
    vaccinesApiAdapter: VaccinesApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
    medicalConditionReferenceDataResolver: IMedicalConditionReferenceDataResolver;
}

interface IVaccineCodeSelectorProps extends ICodeSelectorCommonProps<VaccineCodeSelectorItem> {
    _dependencies?: IVaccineCodeSelectorDependencies;
}

@State.observer
class VaccineCodeSelector extends React.Component<IVaccineCodeSelectorProps> {

    private get dependencies() { return this.props._dependencies; }
    private get apiAdapter() { return this.dependencies.vaccinesApiAdapter; }
    private get referenceDataStore() { return this.dependencies.careReferenceDataStore.vaccines; }
    private get medicalConditionReferenceDataResolver() { return this.dependencies.medicalConditionReferenceDataResolver; }

    @State.bound
    public async getDisplayValueAsync(value: VaccineCodeSelectorItem) {
        return await this.medicalConditionReferenceDataResolver.getVaccineNameAsync(value);
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const results = await this.apiAdapter.searchVaccines(
            text,
            10
        );

        if (results.operationWasSuccessful) {
            const vaccines = results.value.map(x => {
                const textOfQuickSearch = `${x.name} (${x.code})`;
                if (x.id) {
                    return new VaccineCodeSelectorItem({
                        id: x.id,
                        text: textOfQuickSearch
                    });
                }
                return null;
            });

            await this.referenceDataStore.ensureLoadedAsync(vaccines.map(x => x.id));
            return vaccines;
        }
        return null;
    }

    @State.bound
    private addNewItem(text: string): VaccineCodeSelectorItem {
        return new VaccineCodeSelectorItem({ text: text });
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: IVaccine[]) {
        const customOrdering = {
            direction: "asc",
            columnId: "name"
        } as IOrderingState;

        const results = await this.apiAdapter.complexSearchVaccines(
            filterText,
            customOrdering,
            paging);

        await this.referenceDataStore.ensureLoadedAsync(results.value.items.map(value => value.id));

        return results.value;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: VaccineCodeSelectorItem) {
        this.props.onChange({ id: item.id });
    }

    @State.bound
    private onComplexSearchMultiSelect(items: IVaccine[]) {
        this.props.onChange(items.map(item => ({ id: item.id, text: item.code })));
    }
    public render() {
        const props = {
            ...this.props,
            getDisplayValueAsync: this.getDisplayValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: StaticCareResources.MedicalCondition.Vaccines.Title,
            codeGetter: "code",
            nameGetter: "name",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect,
            hideAllgroup: true
        };
        return (
            <HisUi.UniversalCodeSelector
                {...props}
                isCreatable
                addNewItem={this.addNewItem}
            />
        );
    }
}

export default connect(
    VaccineCodeSelector,
    new DependencyAdapter<IVaccineCodeSelectorProps, IVaccineCodeSelectorDependencies>((container) => {
        return {
            vaccinesApiAdapter: container.resolve("VaccinesApiAdapter"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            medicalConditionReferenceDataResolver: container.resolve("IMedicalConditionReferenceDataResolver"),
        };
    })
);
