import { IEhiDocumentsFilterStore } from "./EhiDocumentsMasterDetailPanel";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiDocumentTypeId from "@Primitives/EhiDocumentTypeId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import { IMedworksDocumentsFilterStore } from "./MedworksDocumentsMasterDetailPanel";
import { VoidSyncEvent } from "ts-events";
import _ from "@HisPlatform/Common/Lodash";

export default class PatientDocumentsFilterStore implements IEhiDocumentsFilterStore, IMedworksDocumentsFilterStore {

    public onChangeDebounced = new VoidSyncEvent();

    @State.observable.ref public ehiDocumentTypeId: EhiDocumentTypeId = null;
    @State.observable.ref public ehiDocumentQueryIsUrgent: boolean = null;
    @State.observable.ref public isEmergency: boolean = null;
    @State.observable.ref public emergencyReason: string = null;
    @State.observable.ref public dateRange: LocalDateRange = null;

    @State.action.bound
    public setEhiDocumentTypeId(newId: EhiDocumentTypeId) {
        this.ehiDocumentTypeId = newId;
        this.debounceChange();
    }

    @State.action.bound
    public setEhiDocumentQueryIsUrgent(newValue: boolean) {
        this.ehiDocumentQueryIsUrgent = newValue;
        this.debounceChange();
    }

    @State.action.bound
    public setIsEmergency(newValue: boolean) {
        this.isEmergency = newValue;
        this.debounceChange();
    }

    @State.action.bound
    public setEmergencyReason(reason: string) {
        this.emergencyReason = reason;
        this.debounceChange();
    }

    @State.action.bound
    public setDateRange(newValue: LocalDateRange) {
        this.dateRange = newValue;
        this.debounceChange();
    }

    private debounceChange = _.debounce(() => {
        this.onChangeDebounced.post();
    }, 1000);
}