import DependencyContainer from "@DiContainer";
import PointOfCareRoleApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Actions/PointOfCareRoleApiAdapter";
import AvailablePointOfCareIdListApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Actions/AvailablePointOfCareIdListApiAdapter";

DependencyContainer
    .bind("PointOfCareRoleApiAdapter")
    .to(PointOfCareRoleApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("AvailablePointOfCareIdListApiAdapter")
    .to(AvailablePointOfCareIdListApiAdapter)
    .inSingletonScope();
