import IWidgetRegistry from "@PluginInterface/Dashboard/IWidgetRegistry";
import WidgetName from "@Primitives/WidgetName.g";
import CalculatorWidget from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/CalculatorWidget/CalculatorWidget";
import WeatherWidget from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/Weather/WeatherWidget";
import IcardioProcessWidget from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/ICardioProcess/ICardioProcess";
import EHRWidget from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/EHRWidget/EHRWidget";
import ScalarValueChartWidget from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/ScalarValueChartWidget/ScalarValueChartWidget";
import StateStaticticsWidget from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/StateStatisticsWidget/StateStaticticsWidget";
import IcardioProcessWidgetState from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/ICardioProcess/ICardioProcessWidgetState";
import DiagnosesFrequencyWidget from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/DiagnosesFrequencyWidget/DiagnosesFrequencyWidget";
import ComorbidityWidget from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/ComorbidityWidget/ComorbidityWidget";
import RssReaderWidget from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/RssReaderWidget/RssReaderWidget";
import CareActivitiesByInsurerOrganizationWidget from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/CareActivitiesByInsurerOrganizationWidget/CareActivitiesByInsurerOrganizationWidget";
import CareActivitiesByDurationWidget from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/CareActivitiesByDurationWidget/CareActivitiesByDurationWidget";
import GrossNeakIncomeWidget from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/GrossNeakIncomeWidget/GrossNeakIncomeWidget";
import RelevantServicesWidget from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/RelevantServicesWidget/RelevantServicesWidget";

export default function configureHisPlatformWidgets(widgetRegistry: IWidgetRegistry) {
    widgetRegistry.register(new WidgetName("BMICalculator"), CalculatorWidget);
    widgetRegistry.register(new WidgetName("Weather"), WeatherWidget);
    widgetRegistry.register(new WidgetName("IcardioProcess"), IcardioProcessWidget, () => new IcardioProcessWidgetState());
    widgetRegistry.register(new WidgetName("RssReader"), RssReaderWidget);
    widgetRegistry.register(new WidgetName("EHRWidget"), EHRWidget);
    widgetRegistry.register(new WidgetName("ScalarValueChartWidget"), ScalarValueChartWidget);
    widgetRegistry.register(new WidgetName("StateStatisticsWidget"), StateStaticticsWidget);
    widgetRegistry.register(new WidgetName("DiagnosesFrequencyWidget"), DiagnosesFrequencyWidget);
    widgetRegistry.register(new WidgetName("ComorbidityWidget"), ComorbidityWidget);
    widgetRegistry.register(new WidgetName("CareActivitiesByInsurerOrganizationWidget"), CareActivitiesByInsurerOrganizationWidget);
    widgetRegistry.register(new WidgetName("CareActivitiesByDurationWidget"), CareActivitiesByDurationWidget);
    widgetRegistry.register(new WidgetName("GrossNeakIncomeWidget"), GrossNeakIncomeWidget);
    widgetRegistry.register(new WidgetName("RelevantServicesWidget"), RelevantServicesWidget);
}
