import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import IIdentifierDisplayExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IIdentifierDisplayExtensionPointProps";
import WellKnownReferenceCodes from "@HunEHealthInfrastructurePlugin/Common/WellKnownReferenceCodes";


@Di.injectable()
export default class HunSocialSecurityIdentifierDisplayExtensionComponentService implements IExtensionComponentService<IIdentifierDisplayExtensionPointProps> {

    public isVisible(props: IIdentifierDisplayExtensionPointProps) {
        return props.identifier.identifierSystemId.value === WellKnownReferenceCodes.DocumentIdentifierSystems.HunSocialSecurityNumber;
    }

    public shouldRerunIsVisible(props: IIdentifierDisplayExtensionPointProps, prevProps: IIdentifierDisplayExtensionPointProps) {
        return !ValueWrapper.equals(props.identifier?.identifierSystemId, prevProps?.identifier?.identifierSystemId) ||
            props.identifier?.value !== prevProps?.identifier?.value;
    }
}