import AsserterType from "@Primitives/AsserterType";
import PractitionerId from "@Primitives/PractitionerId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class MedicalConditionHistoryItemDetail {
    @State.observable public asserter: PractitionerId;
    @State.observable public asserterType: AsserterType;
    @State.observable.ref public notes: string;
    @State.observable public verifier: PractitionerId;

    @State.action.bound 
    public setAsserter(newValue: PractitionerId) {
        this.asserter = newValue;
    }

    @State.action.bound 
    public setAsserterType(newValue: AsserterType) {
        this.asserterType = newValue;
    }

    @State.action.bound 
    public setNotes(newValue: string) {
        this.notes = newValue;
    }

    @State.action.bound 
    public setVerifier(newValue: PractitionerId) {
        this.verifier = newValue;
    }
}
