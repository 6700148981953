import IObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/IObservation";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import * as BffProxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import ScalarObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/ScalarObservation";
import TextualObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/TextualObservation";
import CategoryObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/CategoryObservation";
import Quantity from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/Quantity";
import Unit from "@Toolkit/CommonWeb/Model/Unit";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Category from "@Toolkit/CommonWeb/Model/Category";

export function getObservation(dto: Proxy.ObservationDto | BffProxy.ObservationDto): IObservation {
    if (dto instanceof Proxy.ScalarObservationDto || dto instanceof BffProxy.ScalarObservationDto) {
        return new ScalarObservation(
            dto.observationId,
            dto.observationDefinitionId,
            dto.rowVersion,
            dto.patientId,
            dto.careActivityId,
            dto.remarks,
            isNullOrUndefined(dto.quantity) ? null : new Quantity(dto.quantity.amount.value, new Unit(dto.quantity.units.name, dto.quantity.units.shortCode)),
            dto.customLowReferenceBound?.value,
            dto.customHighReferenceBound?.value,
            dto.rawValue);
    }

    if (dto instanceof Proxy.TextualObservationDto || dto instanceof BffProxy.TextualObservationDto) {
        return new TextualObservation(
            dto.observationId,
            dto.observationDefinitionId,
            dto.rowVersion,
            dto.patientId,
            dto.careActivityId,
            dto.contentId
        );
    }

    if (dto instanceof Proxy.CategoryObservationDto || dto instanceof BffProxy.CategoryObservationDto) {
        return new CategoryObservation(
            dto.observationId,
            dto.observationDefinitionId,
            dto.rowVersion,
            dto.patientId,
            dto.careActivityId,
            dto.categories.map(i => new Category(i.key, i.value))
            );            
    }

    throw new TypeError("Invalid type for Proxy.ObservationDto!");
}
