import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import IMasterDetailState from "@CommonControls/Layout/IMasterDetailState";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import IDocumentSnippet from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentSnippet/IDocumentSnippet";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";

interface IDocumentSnippetSelectorGridProps extends IHasMasterDetailState {
    documentSnippets: IDocumentSnippet[];
}

@State.observer
export default class DocumentSnippetSelectorGrid extends React.Component<IDocumentSnippetSelectorGridProps> {

    @State.observable.ref private dataSource = new InMemoryDataGridDataSource<IDocumentSnippet>(() => this.props.documentSnippets);

    @State.computed
    private get masterDetailState() { return this.props._masterDetailState as IMasterDetailState<IDocumentSnippet>; }

    @State.computed
    private get selectedId() { return this.masterDetailState.selectedItem; }

    @State.bound
    private setSelectedId(item: IDocumentSnippet) {
        return this.masterDetailState?.onSelectedItemChange(item);
    }
    private codeFilter(value: string) {
        return value;
    }

    public render() {
        const resources = StaticDocumentManagementResources.DocumentSnippetSelectorModal;
        return (
            <Ui.DataGrid
                dataSource={this.dataSource}
                actionsColumn={false}
                rowHeight={50}
                isSelectable
                selectedRow={this.selectedId}
                onSelectedRowChange={this.setSelectedId}
                fixedLayout
                fixedHeight="100%"
                changeOnMount
                automationId="documentSnippetSelectorGrid_dataGrid">
                <DataGridColumn
                    id={0}
                    dataGetter={"code"}
                    header={resources.ColumnHeaders.Code}
                    isFilterable
                    isOrderable
                    clientSideFilterableValueGetter={this.codeFilter}
                    clientSideOrderableValueGetter={"code"}
                />
                <DataGridColumn
                    id={1}
                    dataGetter={"name"}
                    clientSideOrderableValueGetter={"name"}
                    header={resources.ColumnHeaders.Name}
                    isFilterable
                    isOrderable />
                <DataGridColumn
                    id={2}
                    header={resources.ColumnHeaders.GroupName}
                    dataGetter={"groupName"}
                    isFilterable
                    isOrderable
                    clientSideOrderableValueGetter={"groupName"} />
            </Ui.DataGrid>
        );
    }
}