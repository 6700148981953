import DependencyContainer from "@DiContainer";
import CommonReferenceDataExtensibleEnumApiAdapter from "@AssecoMedPlugin/BoundedContexts/CommonReferenceData/ApplicationLogic/ApiAdapter/CommonReferenceData/CommonReferenceDataExtensibleEnumApiAdapter";
import * as CommonReferenceDataProxy from "@AssecoMedPlugin/BoundedContexts/CommonReferenceData/Api/Proxy.g";
import CommonReferenceDataDataStore from "@AssecoMedPlugin/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import { GetTypeNameWithPrefix } from "@AssecoMedPlugin/PluginHelper";

DependencyContainer
    .bind(GetTypeNameWithPrefix("CommonReferenceDataExtensibleEnumApiAdapter"))
    .to(CommonReferenceDataExtensibleEnumApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind(GetTypeNameWithPrefix("CommonReferenceDataDataStore"))
    .to(CommonReferenceDataDataStore)
    .inSingletonScope();

DependencyContainer
    .bind(GetTypeNameWithPrefix("ICommonReferenceDataClient"))
    .to(CommonReferenceDataProxy.CommonReferenceDataClient);