import React, { useMemo, useCallback } from "react";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { CheckBox } from "@CommonControls";
import RuleTypeId from "@Primitives/RuleTypeId.g";

function ShouldBeFilledValidationRulePropertyPanel() {

    const store = useFormLayoutEditorStore();

    const rule = useMemo(() => store.selectedEditorValidationRules.find(vr => vr.ruleType === RuleTypeId.ShouldBeFilled.value), [store.selectedEditorValidationRules]);
    const setRequired = useCallback((required: boolean) => {       
        if (required) {
            store.addValidationRule({
                entityPath: store.selectedEditor.dataReference,
                ruleType: RuleTypeId.ShouldBeFilled.value,
                ruleParameters: {}
            }, store.selectedEditor.parentDataReferences);
        } else {
            store.removeValidationRule(rule);
        }
    }, [rule, store.selectedEditor.dataReference]);

    return (
        <CheckBox label="Kötelező" value={!!rule} onChange={setRequired} automationId="IsFieldRequired_CheckBox" />
    );
}

export default State.observer(ShouldBeFilledValidationRulePropertyPanel);