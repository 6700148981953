import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import MedicalServiceFinancingStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/MedicalServiceFinancingStore";
import FinancedServiceId from "@Primitives/FinancedServiceId.g";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import _ from "@HisPlatform/Common/Lodash";
import FinancedServiceWithQuantityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/FinancedServiceWithQuantityStore";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import { IValidationProblemProcessor } from "@Toolkit/CommonWeb/Validation/ValidationProblemProcessorService";
import moment from "moment";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";

interface IRelatedCareActivity {
    wentUnderCareAt: moment.Moment;
    pointOfCareId: PointOfCareId;
    serviceId: FinancedServiceId;
}

export class ExcludeRuleValidationProblemProcessor implements IValidationProblemProcessor<MedicalServiceFinancingStore> {
    public ruleId = "ShouldBeCompliantWithFinancedServiceExcludeRules";
    public isRuleHighlightable = true;

    constructor(
        private financeReferenceDataStore: FinanceReferenceDataStore,
        private organizationReferenceDataStore: OrganizationReferenceDataStore,
        private localizationService: ILocalizationService
    ) { }

    private getServiceId(problem: IClientValidationProblem) {
        const serviceIdValue = _.get(problem.parameters, "PerformedServiceExcludeRuleViolationParametersDto.ServiceId.Value");
        return serviceIdValue ? new FinancedServiceId(serviceIdValue.toString()) : null;
    }

    private getRelatedCareActivity(problem: IClientValidationProblem): IRelatedCareActivity {
        const careActivity = _.get(problem.parameters, "PerformedServiceExcludeRuleViolationParametersDto.RelatedCareActivity");
        const serviceIdValue = careActivity?.Service?.Value?.toString();
        const pointOfCareIdValue = careActivity?.PointOfCare?.Value?.toString();
        return careActivity ? {
            pointOfCareId: pointOfCareIdValue ? new PointOfCareId(pointOfCareIdValue) : null,
            wentUnderCareAt: moment(careActivity?.WentUnderCareAt),
            serviceId: serviceIdValue ? new FinancedServiceId(serviceIdValue) : null
        } : null;
    }

    private getRelatedServiceIds(problem: IClientValidationProblem) {
        const ids = _.get(problem.parameters, "PerformedServiceExcludeRuleViolationParametersDto.RelatedServiceIds") as any[];
        return ids?.map(i => new FinancedServiceId(i?.Value?.toString())) || [];
    }

    public mapValidationProblem(problem: IClientValidationProblem, items: MedicalServiceFinancingStore[]) {
        const serviceId = this.getServiceId(problem);
        const relatedServiceIds = this.getRelatedServiceIds(problem);
        if (serviceId) {
            return items.filter(i => i.financedServicesWithQuantities.some((j: FinancedServiceWithQuantityStore) => {
                return ValueWrapper.equals(serviceId, j.financedServiceId.id) || relatedServiceIds.some(k => ValueWrapper.equals(k, j.financedServiceId.id));
            }));
        }
        return [];
    }

    public renderInfo(problem: IClientValidationProblem) {
        const careActivity = this.getRelatedCareActivity(problem);

        let pointOfCareCode = "";
        let serviceCode = "";

        if (careActivity?.pointOfCareId) {
            const pointOfCare = this.organizationReferenceDataStore.allPointsOfCareMap.get(careActivity.pointOfCareId);
            pointOfCareCode = pointOfCare.shorthand;
        }

        if (careActivity?.serviceId) {
            const service = this.financeReferenceDataStore.financedServiceMap.get({id: careActivity.serviceId, validOn: null});
            serviceCode = service?.code.value;
        }

        return careActivity && formatStringWithObjectParams(StaticCareResources.OutpatientWorkflow.PerformedServicesStep.OtherCareActivityFormat, {
            OtherServiceCode: serviceCode,
            WentUnderCareAt: this.localizationService.localizeDateTime(careActivity.wentUnderCareAt),
            PointOfCareCode: pointOfCareCode
        });
    }
}