import React, { useCallback } from "react";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import Icon from "@CommonControls/Icon";
import CheckBox from "@CommonControls/CheckBox";


const DataGridBooleanColumn: React.FC<IDataGridColumnBaseProps> = props => {

    const { children, ...columnProps } = props;

    const renderCellValue = useCallback((value: any, row: any, rowId: any, rowIndex: number, isUnderEditing: boolean, dataSetter: (newValue: any) => void) => {
        if (isUnderEditing) {
            const automationId = `checkbox_column_${props.id}_row_${rowId}`;
            return <CheckBox automationId={automationId} value={value} onChange={dataSetter} align={props.cellTextAlign} />;
        } else {
            return <Icon iconName={(value === true || value === "true") ? "checkedBox" : "uncheckedBox"} style={{marginLeft: "5px"}} />;
        }
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<boolean | null>) => {
        return <CheckBox {...filterProps} canBeNull />;
    }, []);

    return (
        <DataGridColumn
            {...columnProps}
            onRenderCellValue={renderCellValue}
            onRenderFilter={filterRenderer}
            showHint={false}
        />
    );
};

export default DataGridBooleanColumn;