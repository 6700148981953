import React, { useMemo, useCallback } from "react";
import { GroupBox } from "@CommonControls";
import FormLayoutAnyBlockElement from "./FormLayoutAnyBlock";
import FormLayoutGroupBlockStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutGroupBlockStore";
import { useFormPanelStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanelStoreProvider";
import { useFormLayoutContext, FormLayoutContextProvider } from "@Toolkit/FormEngine/Panels/FormLayoutContext";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { findParentDataElementCollectionFromArray } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/DataElementHelpers";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import { getEmptyFormFieldDataFor, hasVisibleItemInTreeBelow, getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import CompositeFormDataElement from "@Toolkit/FormEngine/Model/Schema/CompositeFormDataElement";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import FormSchemaReference from "@Toolkit/FormEngine/Model/Schema/FormSchemaReference";

export function FormLayoutGroupBlock(props: { block: FormLayoutGroupBlockStore, cultureCode: string, schemaReference: FormSchemaReference }) {
    const store = useFormPanelStore();
    const definition = store.definition.get();
    const layoutContext = useFormLayoutContext();

    const compositeDataReferences = isNullOrUndefined(layoutContext?.compositeDataReferences) ? null : layoutContext?.compositeDataReferences;
    const parentCompositeDataReference = isNullOrUndefined(compositeDataReferences) ? null : compositeDataReferences.join(".");
    const compositeDataReference = isNullOrUndefined(parentCompositeDataReference)
        ? props.block.dataReference
        : parentCompositeDataReference + "." + props.block.dataReference;

    const dataElement = useMemo(() => {
        if (isNullOrUndefined(props.block.dataReference)) {
            return null;
        } else {
            const parentDataElementCollection =
                findParentDataElementCollectionFromArray(definition.rootSchema.getLatestVersionOrNull()?.dataElements, compositeDataReferences);
            return parentDataElementCollection.find(de => de.name === props.block.dataReference);
        }
    }, [definition, props.block]);

    const listPanelItemEditor = useCallback((_, index) => {
        return (
            <FormLayoutContextProvider dataReference={props.block.dataReference + `[${index}]`}>
                {props.block.content.map((b, idx) => <FormLayoutAnyBlockElement cultureCode={props.cultureCode} block={b} key={idx} schemaReference={props.schemaReference} />)}
            </FormLayoutContextProvider>
        );
    }, [props.block, dataElement]);

    const createNewItem = useCallback(() => {
        const formFieldData: FormFieldDataBase[] = [];
        getEmptyFormFieldDataFor((dataElement as CompositeFormDataElement).formDataElements, formFieldData);
        return Promise.resolve(State.createObservableShallowArray(formFieldData));
    }, []);

    const addNewItem = useCallback((newItem: any) => {
        State.runInAction(() => {
            ((getField(store.props.form.data.Content, compositeDataReference) as any).value as unknown as IObservableArray<any>).push(newItem);
        });

        store.props.onChange(compositeDataReference, newItem, "add");
    }, []);

    let actualFieldVisible: boolean = true;
    if (!isNullOrUndefined(props.block.dataReference) && !isNullOrUndefined(compositeDataReference)) {
        const actualField = getField(store.props.form.data.Content, compositeDataReference);
        actualFieldVisible = actualField.isVisible;
    }

    const getItemKey = useCallback((item: any, index: number) => {
        const formField = Array.isArray(item) && item.length > 0
            ? item[0] as FormFieldDataBase
            : item as FormFieldDataBase;
        return `${formField.fieldName}_${index}_${formField.Guid}`;
    }, []);

    const onRemoveItem = useCallback((item: any) => {
        State.runInAction(() => {
            ((getField(store.props.form.data.Content, compositeDataReference) as any).value as unknown as IObservableArray<any>).remove(item);
        });
        store.props.onChange(compositeDataReference, item, "remove");
    }, []);

    const isVisible = actualFieldVisible && hasVisibleItemInTreeBelow(props.block, store.props.form.data.Content, parentCompositeDataReference);
    if (!isVisible) {
        return (
            <></>
        );
    }

    if (dataElement === null) {
        return (
            <InnerGroupBox block={props.block} cultureCode={props.cultureCode} schemaReference={props.schemaReference} />
        );
    } else if (dataElement.isArray) {
        return (
            <ListPanel
                title={props.block.multiLingualDisplayName.getWithCurrentCultureCodeOrWithDefaultCultureCode(store.cultureCodeProvider.cultureCode)}
                onCreateNewAsync={createNewItem}
                onAddNewItem={addNewItem}
                renderItemEditor={listPanelItemEditor}
                actionButtonsOrientation="horizontal"
                items={(getField(store.props.form.data.Content, compositeDataReference) as any).value as unknown as IObservableArray<any>}
                onDeleteItemConfirmationAsync={() => Promise.resolve(true)}
                noItemsMessage=""
                alwaysEdit
                propertyIdentifier={dataElement.name}
                isCompactEmptyState
                style={{ flex: "1 1 0" }}
                automationId={dataElement.name}
                getItemKey={getItemKey}
                onRemoveItem={onRemoveItem}
            />
        );
    } else {
        return (
            <FormLayoutContextProvider dataReference={props.block.dataReference}>
                <ValidationBoundary pathPrefix={dataElement.name}>
                    <InnerGroupBox block={props.block} cultureCode={props.cultureCode} schemaReference={props.schemaReference} />
                </ValidationBoundary>
            </FormLayoutContextProvider>
        );
    }
}

interface IInnerGroupBoxProps {
    block: FormLayoutGroupBlockStore;
    cultureCode: string;
    schemaReference: FormSchemaReference;
}

const InnerGroupBox = State.observer((props: IInnerGroupBoxProps) => {
    return (
        <GroupBox title={props.block.multiLingualDisplayName.getWithCurrentCultureCodeOrWithDefaultCultureCode(props.cultureCode)} style={{ flex: "1 1 0" }}>
            {props.block.content.map((b, idx) => <FormLayoutAnyBlockElement cultureCode={props.cultureCode} block={b} key={idx} schemaReference={props.schemaReference} />)}
        </GroupBox>
    );
});

export default State.observer(FormLayoutGroupBlock);