import { toast as toastify, ToastType, ToastPosition, Slide, Zoom, cssTransition } from "react-toastify";
import Di from "@Di";
import config from "@Config";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import Toast from "@Toolkit/ReactClient/Services/Implementation/ToastifyNotificationService/Toast";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import React from "react";
import * as moment from "moment";


const Transition = cssTransition({
    enter: "zoomIn",
    exit: "fadeOut",
    duration: [250, 500]
});

export interface INotification {
    text: string,
    severity: string,
    timestamp: number,
}

(window as any).Notifications = new Array<INotification>();

@Di.injectable()
export default class ToastifyNotificationService implements INotificationService {

    constructor(
        @Di.inject("IToolkitLocalizationService") private readonly toolkitLocalizationService: IToolkitLocalizationService
    ) {
    }

    public success(text: string): void {
        this.showToast(text, "success" as ToastType);
    }

    public info(text: string): void {
        this.showToast(text, "info" as ToastType);
    }

    public warning(text: string): void {
        this.showToast(text, "warning" as ToastType);
    }

    public error(text: string): void {
        this.showToast(text, "error" as ToastType);
    }

    public showSaveResult(
        isSaved: boolean,
        hasWarning?: boolean,
        successfullySavedMessage?: string,
        savedWithWarningsMessage?: string,
        notSavedBecauseOfErrorsMessage?: string
    ): void {

        const resources = this.toolkitLocalizationService.staticResources.notifications;
        if (isSaved) {
            if (hasWarning) {
                this.warning(savedWithWarningsMessage || resources.savedWithWarnings);
            } else {
                this.success(successfullySavedMessage || resources.successfullySaved);
            }
        } else {
            this.error(resources.notSavedBecauseOfErrors || notSavedBecauseOfErrorsMessage);
        }
    }

    public showSavedSuccessfully(isSaved?: boolean, successfullySavedMessage?: string): void {
        if (isSaved === undefined || isSaved === true) {
            this.success(successfullySavedMessage ||
                this.toolkitLocalizationService.staticResources.notifications.successfullySaved);
        }
    }

    public showCannotSaveBecauseOfErrors(notSavedBecauseOfErrorsMessage?: string): void {
        this.error(notSavedBecauseOfErrorsMessage ||
            this.toolkitLocalizationService.staticResources.notifications.notSavedBecauseOfErrors);
    }

    private showToast(text: string, type: ToastType) {
        this.addLog({ text: text, severity: type, timestamp: DateTimeService.now().valueOf()} as INotification);    
        
        if (!text) {
            throw new Error("Toast text should be a non-empty string");
        }

        toastify(<Toast toastType={type} text={text} />, {
            autoClose: config.toastAutoClearTimeout,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            position: "top-center" as ToastPosition,
            hideProgressBar: true,
            transition: Transition,
            type,
            closeButton: false
        });
    }

    private addLog(notification: INotification): void{
        if ((window as any).Notifications.length === 10) {
            (window as any).Notifications.shift();
        }
        (window as any).Notifications.push(notification);
    }

}
