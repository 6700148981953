import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";

export interface IPrescriptionToEhiDialogResult {
    resultCode?: "digital" | "paper";
}
export interface IPrescriptionToEhiDialogParams extends IModalParams {
    ehiPrescriptionError?: string;
}

export default class PrescriptionToEhiDialogParams implements IPrescriptionToEhiDialogParams {

    public static type = "PrescriptionToEhiDialog";
    public get type() { return PrescriptionToEhiDialogParams.type; }
    constructor(
        public readonly ehiPrescriptionError?: string
    ) { }

}
