import React from "react";
import { ITokenSettingsPanelPropsBase } from "@PluginInterface/BoundedContexts/DocumentManagement/TokenSettingsPanelRegistry/ITokenSettingsPanelRegistry";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import IFormEngineApiAdapter from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import _ from "@HisPlatform/Common/Lodash";
import FormDefinitionDescriptor from "@Toolkit/FormEngine/Model/FormDefinitionDescriptor";
import IFormFieldReference from "@HisPlatform/BoundedContexts/FormEngine/Components/FormFieldSelector/IFormFieldReference";
import FormFieldSelector from "@HisPlatform/BoundedContexts/FormEngine/Components/FormFieldSelector/FormFieldSelector";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";

interface IFormTokenPanelDependencies {
    formEngineApiAdapter: IFormEngineApiAdapter;
    cultureCodeProvider: ICurrentCultureProvider;
}

interface IProviderParameterSettings {
    FormDefinitionName: string;
    FormDataElementName: string;
    FormDataElementType: string;
    SchemaVersion: number;
}

interface IFormTokenPanelProps extends ITokenSettingsPanelPropsBase<Proxy.DynamicTokenValueFormatterSettingsDto, IProviderParameterSettings> {
    _dependencies?: IFormTokenPanelDependencies;
}

interface IItem {
    value: IFormFieldReference;
    formDataElementType: string;
    label: string;
    schemaVersion: number;
}

/** @screen */
@State.observer
class FormTokenPanel extends React.Component<IFormTokenPanelProps> {

    @State.observable.ref private descriptors: FormDefinitionDescriptor[] = [];

    @State.computed private get selected() {
        return {
            formDefinitionName: this.props.providerParameterSettings?.FormDefinitionName,
            formDataElementName: this.props.providerParameterSettings?.FormDataElementName,
            formDataElementType: this.props.providerParameterSettings?.FormDataElementType,
            schemaVersion: this.props.providerParameterSettings?.SchemaVersion
        } as IFormFieldReference;
    }

    @State.computed private get selectedExtended() {
        return this.getExtendedValue(this.selected);
    }

    @State.action.bound
    private select(value: IFormFieldReference) {
        const extendedValue = this.getExtendedValue(value);

        this.props.onSettingsChange(new Proxy.DynamicTokenValueFormatterSettingsDto({
            formatterType: extendedValue.formDataElementType,
            dateTimeFormatString: this.props.tokenFormatterSettings.dateTimeFormatString,
        }), {
            FormDefinitionName: value.formDefinitionName,
            FormDataElementName: value.formDataElementName,
            FormDataElementType: value.formDataElementType,
            SchemaVersion: extendedValue.schemaVersion
        });

        this.props.onUpdateTokenDisplayName?.(extendedValue.label);
    }

    private getExtendedValue(value: IFormFieldReference): IItem {
        const descriptor = this.descriptors.find(d => d.formDefinitionName === value.formDefinitionName);
        const dataElement = descriptor?.formDataElementDescriptors.find(de => de.formDataElementName === value.formDataElementName);

        if (!dataElement) {
            return null;
        }

        return {
            value,
            formDataElementType: dataElement.formDataElementType,
            label: dataElement.multiLingualLabel.getWithCurrentCultureCodeOrWithDefaultCultureCode(this.props._dependencies.cultureCodeProvider.cultureCode),
            schemaVersion: descriptor.schemaVersion
        } as IItem;
    }

    public render() {
        return (
            <>
                <FormFieldSelector
                    automationId="FormDataElementDescriptor_SelectBox"
                    value={this.selected}
                    onChange={this.select}
                    onLoaded={this.setDescriptors}
                />
                {this.selectedExtended?.formDataElementType === "DateTime" &&
                    <Ui.TextBox
                        label={StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.DateFormat}
                        value={this.props.tokenFormatterSettings?.dateTimeFormatString ?? ""}
                        placeholder="yyyy-MM-dd HH:mm:ss"
                        onChange={this.setFormatString}
                        automationId="dateTimeFormatTextBox" />}
            </>
        );
    }

    @State.action.bound
    private setDescriptors(descriptors: FormDefinitionDescriptor[]) {
        this.descriptors = descriptors;
    }

    @State.bound
    private setFormatString(value?: string) {

        if (!this.selected || !this.selectedExtended) {
            return;
        }

        this.props.onSettingsChange(new Proxy.DynamicTokenValueFormatterSettingsDto({
            formatterType: this.selectedExtended.formDataElementType,
            dateTimeFormatString: value
        }), {
            FormDefinitionName: this.selected.formDefinitionName,
            FormDataElementName: this.selected.formDataElementName,
            FormDataElementType: this.selected.formDataElementType,
            SchemaVersion: this.selectedExtended.schemaVersion
        });
    }
}

export default connect(
    FormTokenPanel,
    new DependencyAdapter<IFormTokenPanelProps, IFormTokenPanelDependencies>(c => ({
        formEngineApiAdapter: c.resolve<IFormEngineApiAdapter>("IFormEngineApiAdapter"),
        cultureCodeProvider: c.resolve<ICurrentCultureProvider>("ICurrentCultureProvider")
    }))
);