import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import ComplexSchedulingServiceFilter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ComplexSchedulingServiceFilter";
import moment from "moment";
import ScheduleMode from "@Primitives/ScheduleMode";

export default class SchedulePlan {
    @State.observable.ref public from: moment.Moment = null;
    @State.observable.ref public to: moment.Moment = null;
    @State.observable public scheduleMode: ScheduleMode = ScheduleMode.Earliest;
    @State.observable.ref public schedulingServiceFilters: IObservableArray<ComplexSchedulingServiceFilter> = State.observable([]);
    
    @State.action.bound
    public setFrom(from: moment.Moment) {
        if (!this.from && !this.to && !!from){
            this.setTo(moment(from).add(1, "months"));
        }

        this.from = from;
    }

    @State.action.bound
    public setTo(to: moment.Moment) {
        this.to = to;
    }

    @State.action.bound
    public setScheduleMode(scheduleMode: ScheduleMode) {
        this.scheduleMode = scheduleMode;
    }

    @State.action.bound
    public removeSchedulingServiceFilter(identifier: ComplexSchedulingServiceFilter) {
        this.schedulingServiceFilters.remove(identifier);
    }
}
