import React from "react";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import PerformanceStatementCareActivityFilterStore from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityWorklistPanel/Filters/PerformanceStatementCareActivityFilterStore";
import ExtendedPerformanceStatementCareActivityFilter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCareActivityWorklistPanel/Filters/ExtendedPerformanceStatementCareActivityFilter";
import NDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanel";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import INDataRow from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataRow";
import { IRowBody, RowId } from "@CommonControls/DataGrid/IDataGridProps";
import IWorklistItemDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistItemDefinition";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";

interface IPerformanceStatementCareActivityWorklistPanelView {
    worklistDefinition: IWorklistDefinition;
    filterStore: PerformanceStatementCareActivityFilterStore;
    onFilterStoreCreatedAsync?: (filterStore: any) => Promise<void>;
    onGetExtendedFilterDescriptors?: () => IDataGridFilterDescriptor[];
    onChange: (rowId: React.ReactText, useCaseState: INDataUseCaseState) => void;
    useCaseState: INDataUseCaseState;
    selectedRowId: string;
    getPanelProps: any;
    onRenderRowBody: (row: INDataRow, rowId: RowId, rowIndex: number, rowBodyItemDefinition: IWorklistItemDefinition) => IRowBody;
    onInitializeFilter?: (filterStore: any, itemDefinitions: IWorklistItemDefinition[]) => void;

    multiActionActivityReference: string;
    onMultiActionActivityReferenceChange: (value: string) => void;
}


const PerformanceStatementCareActivityWorklistPanelView: React.FC<IPerformanceStatementCareActivityWorklistPanelView> = (props) => {

    const renderExtendedSearch = () => {
        return (
            <ExtendedPerformanceStatementCareActivityFilter
                filterStore={props.filterStore}
                isVisible={true}
            />
        );
    };

    return props.worklistDefinition && (
        <NDataPanel
            definition={props.worklistDefinition}
            extraFilter={props.filterStore && props.useCaseState?.displayMode !== UseCaseDisplayMode.MasterDetail && renderExtendedSearch()}
            defaultExtraFilterVisibility={true}
            onRenderRowBody={props.onRenderRowBody}
            onFilterStoreCreatedAsync={props.onFilterStoreCreatedAsync}
            onGetExtendedFilterDescriptors={props.onGetExtendedFilterDescriptors}
            onChange={props.onChange}
            defaultPageSize={10}
            useCaseState={props.useCaseState}
            selectedRowId={props.selectedRowId}
            hasDetailFooter={true}
            onGetPanelProps={props.getPanelProps}
            disableDetailStrictMode
            hasRefreshButton
            onInitializeFilter={props.onInitializeFilter}
            deferredActionTaskType="PerformanceStatementCareActivityWorklist"
            multiActionActivityReference={props.multiActionActivityReference}
            onMultiActionActivityReferenceChange={props.onMultiActionActivityReferenceChange}
        />
    );
};

export default State.observer(PerformanceStatementCareActivityWorklistPanelView);
