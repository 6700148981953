exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OncologyDataContainer_data-container_i3fLb {\n  background-color: #505c71;\n  padding: 6px 0 6px 15px;\n  font-family: PragatiNarrow;\n  font-size: 14px;\n  color: #f4f7fc;\n  line-height: 1.2;\n  margin-bottom: 2px;\n}\n.OncologyDataContainer_data-container_i3fLb b {\n  font-family: PragatiNarrowBold;\n  font-size: 14px;\n  margin: 0;\n  line-height: 1;\n}\n.OncologyDataContainer_open-button_2rkJ5 {\n  margin-right: 15px;\n}\n", ""]);

// exports
exports.locals = {
	"data-container": "OncologyDataContainer_data-container_i3fLb",
	"dataContainer": "OncologyDataContainer_data-container_i3fLb",
	"open-button": "OncologyDataContainer_open-button_2rkJ5",
	"openButton": "OncologyDataContainer_open-button_2rkJ5"
};