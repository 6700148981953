exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PrescriptionsToBeCreated_outer-container_1p_rq {\n  background-color: #ddf1f7;\n  padding: 10px;\n}\n.PrescriptionsToBeCreated_inner-container_1fM7u {\n  border: 1px solid #8fd1e6;\n  border-radius: 2px;\n}\n", ""]);

// exports
exports.locals = {
	"outer-container": "PrescriptionsToBeCreated_outer-container_1p_rq",
	"outerContainer": "PrescriptionsToBeCreated_outer-container_1p_rq",
	"inner-container": "PrescriptionsToBeCreated_inner-container_1fM7u",
	"innerContainer": "PrescriptionsToBeCreated_inner-container_1fM7u"
};