import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import PrescriptionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionApiAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import textualPrescriptionTemplateStandaloneRouteDefinitions from "@HunEHealthInfrastructurePlugin/UseCases/PrescriptionsUseCase/TextualPrescriptionTemplateRoutes";

interface IHunEHealthInfrastructureSettingsMenuDependencies {
    prescriptionApiAdapter: PrescriptionApiAdapter;
    globalRoutingStore: GlobalRoutingStore;
}

interface IHunEHealthInfrastructureSettingsMenuProps {
    _dependencies?: IHunEHealthInfrastructureSettingsMenuDependencies;
}

@State.observer
class HunEHealthInfrastructureSettingsMenu extends React.Component<IHunEHealthInfrastructureSettingsMenuProps> {

    private get prescriptionApiAdapter() { return this.props._dependencies.prescriptionApiAdapter; }
    private get globalRoutingStore() { return this.props._dependencies.globalRoutingStore; }

    @State.bound
    private navigateToFactory<TParams>(route: RouteDefinition<TParams>, params: TParams = null, queryParameters?: object) {
        return () => {
            this.globalRoutingStore.push(route.makeRoute(params, queryParameters));
        };
    }

    private get permissionCheckedOperations(): any {
        const res = {};

        res["CreateTextualPrescriptionTemplate"] = async () => {
            await this.prescriptionApiAdapter.createTextualPrescriptionTemplatePermissionCheckAsync();
        };

        return res;
    }
    public render() {
        return (
            <PermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                <Ui.NavBar.Item
                    onClick={this.navigateToFactory(textualPrescriptionTemplateStandaloneRouteDefinitions.textualPrescriptionTemplates)}
                    permissionCheckOperationNames="CreateTextualPrescriptionTemplate"
                    permissionDeniedStyle="disabled"
                    automationId="textualPrescriptionTemplates" >
                    {StaticHunSocialSecurityMedicationRequestResources.TextualPrescriptionTemplatesPanel.MasterTitle}
                </Ui.NavBar.Item>
            </PermissionCheckContextProvider>
        );
    }
}

export default connect(
    HunEHealthInfrastructureSettingsMenu,
    new DependencyAdapter<IHunEHealthInfrastructureSettingsMenuProps, IHunEHealthInfrastructureSettingsMenuDependencies>(c => ({
        prescriptionApiAdapter: c.resolve("PrescriptionApiAdapter"),
        globalRoutingStore: c.resolve("GlobalRoutingStore")
    }))
);