import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import IValidationResultSummaryLocalizator from "@CommonControls/ValidationResultSummary/IValidationResultSummaryLocalizator";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import Di from "@Di";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import * as DiContainer from "@DiContainer";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IToolkitStaticResources from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitStaticResources";
import moment from "moment";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import Age from "@Toolkit/CommonWeb/Model/Age";
import ILocalizedObject from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalization";
import ResourceId from "@Primitives/ResourceId.g";
import IName from "@Toolkit/CommonWeb/Model/IName";
import NameStore from "@Primitives/NameStore";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Money from "@Toolkit/CommonWeb/Model/Money";

@Di.injectable()
export default class ToolkitLocalizationService implements IToolkitLocalizationService {

    @DiContainer.lazyResolve("ILocalizationService") private localizationService: ILocalizationService;

    @State.bound
    public getValidationResultSummaryLocalizator(): IValidationResultSummaryLocalizator {
        return {
            moreButtonHideLabel: StaticWebAppResources.ValidationResultSummary.MoreButtonHideLabel,
            moreButtonShowLabel: StaticWebAppResources.ValidationResultSummary.MoreButtonShowLabel,
            getTitle: (err: number, warn: number, validText?: string) => this.localizationService.localizeValidationResultSummaryMessage(err, warn, validText),
            getValidationMessage: (resId: any) => this.localizationService.localizeValidationMessage(resId)
        };
    }

    public staticResources: IToolkitStaticResources = {
        fileUpload: {
            placeholder: StaticWebAppResources.ToolkitControls.FileUpload.Placeholder,
        },
        button: {
            addNew: StaticWebAppResources.Common.Button.Add,
            tooltips: {
                showPassword: StaticWebAppResources.Common.Button.Tooltips.ShowPassword
            },
            back: StaticWebAppResources.Common.Button.Back,
            cancel: StaticWebAppResources.Common.Button.Cancel,
            delete: StaticWebAppResources.Common.Button.Delete,
            save: StaticWebAppResources.Common.Button.Save,
            changeHistory: StaticWebAppResources.Common.Button.ChangeHistory,
            edit: StaticWebAppResources.Common.Button.Edit
        },
        dateRangePicker: {
            labels: {
                from: StaticWebAppResources.DateRangePicker.From,
                to: StaticWebAppResources.DateRangePicker.To,
                allDay: StaticWebAppResources.DateRangePicker.AllDay,
                today: StaticWebAppResources.DateRangePicker.Today,
                yesterday: StaticWebAppResources.DateRangePicker.Yesterday,
                todayAndYesterday: StaticWebAppResources.DateRangePicker.TodayAndYesterday,
                last7Days: StaticWebAppResources.DateRangePicker.Last7Days,
                last30Days: StaticWebAppResources.DateRangePicker.Last30Days,
                thisMonth: StaticWebAppResources.DateRangePicker.ThisMonth,
                lastMonth: StaticWebAppResources.DateRangePicker.LastMonth
            },
            messages: {
                validToIsBeforeValidFromError: StaticWebAppResources.DateRangePicker.ValidToIsBeforeValidFromError
            }
        },
        separatedTimeRangePicker: {
            labels: {
                from: StaticWebAppResources.SeparatedTimeRangePicker.From,
                to: StaticWebAppResources.SeparatedTimeRangePicker.To,
            }
        },
        dataGrid: {
            numberOfRows: StaticWebAppResources.DataGrid.NumberOfRows,
            itemsPerPage: StaticWebAppResources.DataGrid.ItemLabel,
            lastRefreshedAt: StaticWebAppResources.DataGrid.LastRefreshedAt,
            moreButtons: {
                refresh: StaticWebAppResources.DataGrid.MoreButtons.Refresh,
                extraFiltersVisible: StaticWebAppResources.DataGrid.MoreButtons.ExtraFiltersVisible,
                clearFilters: StaticWebAppResources.DataGrid.MoreButtons.ClearFilters,
                print: StaticWebAppResources.DataGrid.MoreButtons.Print
            }
        },
        common: {
            error: StaticWebAppResources.Common.Label.Error,
            operationNotPermitted: StaticWebAppResources.Common.ToastMessage.UnauthorizedDataAccess,
            unauthorizedOperation: StaticWebAppResources.Common.ToastMessage.UnauthorizedOperation,
            navigateAwayDialogQuestion: StaticWebAppResources.Common.DialogMessage.OkToNavigateAndLoseChanges,
            navigateAwayDialogTitle: StaticWebAppResources.Common.DialogTitle.ConfirmationTitle
        },
        notifications: {
            notSavedBecauseOfErrors: StaticWebAppResources.Common.ToastMessage.NotSavedBecauseOfErrors,
            savedWithWarnings: StaticWebAppResources.Common.ToastMessage.SavedWithWarnings,
            successfullySaved: StaticWebAppResources.Common.ToastMessage.SaveSuccessful
        },
        selectBox: {
            newItem: StaticWebAppResources.ToolkitControls.SelectBox.NewItem,
            startTypingToSearch: StaticWebAppResources.ToolkitControls.SelectBox.StartTypingToSearch
        },
        largeMenu: {
            searchboxPlaceholder: StaticWebAppResources.Common.Label.EnterTextToSearch
        },
        scheduler: {
            noItemsMessage: "MISSING in ToolkitLocalizationService.ts"
        },
    };

    public localizeDateTime(dateTime: moment.Moment, showSeconds?: boolean, useTodayString?: boolean, useNbsp?: boolean): string {
        return this.localizationService.localizeDateTime(dateTime, showSeconds, useNbsp, useTodayString);
    }

    public localizeDate(dateString: LocalDate): string {
        return this.localizationService.localizeDate(dateString);
    }

    public localizeAge(value: Age) {
        return this.localizationService.localizeAge(value);
    }

    public localizeEnum(enumValueName: string, typeName: string): ILocalizedObject {
        return this.localizationService.localizeEnum(enumValueName, typeName);
    }

    public localizeReferenceDataWithDefault(resourceId: ResourceId, defaultValue: string) {
        return this.localizationService.localizeReferenceDataWithDefault(resourceId, defaultValue);
    }

    public localizeMoney(value: Money): string {
        return this.localizationService.localizeMoney(value);
    }

    public localizeNameMonogram(name: IName | NameStore): string {
        switch (this.localizationService.currentCultureProvider.cultureCode) {
            case "hu-HU":
                if (name instanceof NameStore || "givenName1" in name) {
                    return getFirstLetters(name.familyName, name.givenName1);
                }
                return getFirstLetters(name.familyName, name.givenName);
            case "en-US":
            case "sk-SK":
            case "cs-CZ":
                if (name instanceof NameStore || "givenName1" in name) {
                    return getFirstLetters(name.givenName1, name.familyName);
                }
                return getFirstLetters(name.givenName, name.familyName);
        }
        throw new Error("Current culture code is out of range");

        function getFirstLetters(...parts: string[]) {
            return parts.filter(p => !isNullOrEmptyString(p)).reduce((ret, part) => ret += part.substring(0, 1), "");
        }
    }
}