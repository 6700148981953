import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import IPatientsCareActivityListButtonsExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientsCareActivityListButtonsExtensionPointProps";

@Di.injectable()
export default class PatientsCareActivityListButtonsExtensionComponentService implements IExtensionComponentService<IPatientsCareActivityListButtonsExtensionPointProps> {

    public isVisible(props: IPatientsCareActivityListButtonsExtensionPointProps) {
        return props.row?.careActivitySource && props.row.careActivitySource.value === "HunEHealthInfrastructure";
    }

    public shouldRerunIsVisible(props: IPatientsCareActivityListButtonsExtensionPointProps, prevProps: IPatientsCareActivityListButtonsExtensionPointProps) {
        return props.row?.careActivitySource?.value !== prevProps?.row?.careActivitySource.value;
    }
}