import Command from "@Toolkit/CommonWeb/CommandProcessing/Definition/Command";
import { ILogEntry } from "./WebWorkerLogger";

export default class SendLogCommand extends Command {

    constructor(
        originator: string,
        public readonly data: ILogEntry) {
        super("SendLogCommand", originator);
    }
}