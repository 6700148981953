import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import { ScreenStateContextAdapter, ScreenStateContextStore } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import { provideServiceRequestScreenStore, useServiceRequestScreenStore } from "./ServiceRequestScreenStoreProvider";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import CareActivityId from "@Primitives/CareActivityId.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientId from "@Primitives/PatientId.g";
import FormExtensionRegistryAdapter from "@PluginInterface/FormExtension/FormExtensionRegistryAdapter";
import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import ActionBoundButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundButton";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import ShowServiceRequestScreenAction from "@HisPlatform/Packages/ServiceRequest/FrontendActions/ShowServiceRequestScreenAction.g";
import ShowCreateNewServiceRequestScreenAction from "@HisPlatform/Packages/ServiceRequest/FrontendActions/ShowCreateNewServiceRequestScreenAction.g";
import CareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CareActivityStore";
import CareActivityBaseData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareActivityBaseData";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import ReferralDataPageBox from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestPanel/ReferralDataPageBox";
import TargetDataPageBox from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestPanel/TargetDataPageBox";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";

export interface IServiceRequestScreenProps extends IScreenPropsBase<ShowServiceRequestScreenAction | ShowCreateNewServiceRequestScreenAction> {
    _screenState?: ScreenStateContextStore;
    _modalService?: IModalService;
    _careActivity?: CareActivityStore;
    _careActivityId?: CareActivityId;
    _careActivityBaseData?: CareActivityBaseData;
    _patientId?: PatientId;
    _patient?: PatientAdministrativeData;
    _formExtension?: IFormExtension<ServiceRequestStore>;
}

function ServiceRequestScreen(props: IServiceRequestScreenProps) {
    const store = useServiceRequestScreenStore();

    if (!props._patientId || !props._patient || !store.serviceRequestDefinition || !store.serviceRequest || store.isLoading) {
        return null;
    }

    return (
        <Screen
            title={`${store.serviceRequestDefinition?.shortName} ${StaticWebAppResources.NewServiceRequestPage.PageTitle} ${store.serviceRequest?.serviceRequestIdentifier ? `(${store.serviceRequest?.serviceRequestIdentifier})` : ""}`}
            subTitle={`${StaticWebAppResources.NewServiceRequestPage.CreatedAt}: ${store.serviceRequestCreatedAt}`}
            toolbar={!store.vIsReadOnly &&
                <>
                    {store.canAcquireLock && !store.isAdministration && <HisUi.LockIndicatorComponent
                            locked={!store.canAcquireLock}
                            lockedBy={store.lockInfo?.preventingLockUserId}
                            onEditClickedAsync={store.openForEditAsync}
                            permissionDeniedStyle="invisible"
                            style={{ alignContent: "right" }}
                        />
                    }
                    {store.isRenewAllowed && !store.vIsReadOnly &&
                        <ActionBoundButton
                            actionDescriptor={store.saveServiceRequestAction}
                            text={StaticWebAppResources.NewServiceRequestPage.Renew}
                            visualStyle="primary"
                            onExecuteAsync={store.renewServiceRequestAsync}
                            automationId="__renewRequest" />
                    }
                    {store.isDeleteAllowed && !store.vIsReadOnly &&
                        <ActionBoundButton
                            actionDescriptor={store.saveServiceRequestAction}
                            iconName="trash"
                            visualStyle="negative"
                            onExecuteAsync={store.deleteServiceRequestAsync}
                            automationId="__deleteRequest" />
                    }
                    {store.isEditingAllowed && (
                        <>
                            <ActionBoundButton
                                actionDescriptor={store.saveServiceRequestAction}
                                iconName="save"
                                text={StaticWebAppResources.NewServiceRequestPage.SaveRequest}
                                onExecuteAsync={store.onSaveAsync}
                                automationId="__saveRequest" />
                            <ActionBoundButton
                                actionDescriptor={store.saveServiceRequestAction}
                                iconName="paper_plane"
                                visualStyle="primary"
                                text={StaticWebAppResources.NewServiceRequestPage.SaveAndSendRequest}
                                onExecuteAsync={store.onSaveAndSubmitAsync}
                                automationId="__saveAndSendRequest" />
                        </>
                    )}
                </>}>
            <ValidationBoundary
                entityTypeName="Appointment,ServiceRequest"
                validationResults={store.validationResults}
                onValidateAsync={store.onValidateAsync}
                validateAllEvent={store.validateAllEvent}>
                <ReadOnlyContext.Provider
                    value={store.vIsReadOnly || !store.isEditingAllowed}>
                    <HisPlatformExtensionPoint
                        extensionProps={store.headerExtensionPanelProps}
                        type="ServiceRequestHeader">
                        <></>
                    </HisPlatformExtensionPoint>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6} style={{ paddingRight: "10px" }} automationId="__referralData">
                            <Ui.GroupBox title={StaticWebAppResources.NewServiceRequestPage.ReferralData}>
                                {!store.isLoading
                                    ? (
                                        <ReferralDataPageBox {...store.referralDataViewProps}/>
                                    )
                                    : <></>
                                }
                            </Ui.GroupBox>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6} automationId="__executionData">
                            <Ui.GroupBox title={StaticWebAppResources.NewServiceRequestPage.ExecutionalData}>
                                <TargetDataPageBox {...store.targetDataViewProps} />
                            </Ui.GroupBox>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.GroupBox title={StaticWebAppResources.NewServiceRequestPage.SuspectedDiagnoses}>
                        {!store.isSuspectedDiagnosisLoading ?
                            <HisUi.SuspectedDiagnosisListPanel
                                items={store.serviceRequest.suspectedDiagnosisList}
                                readonly={store.serviceRequest.suspectedDiagnosisListReadOnly}
                            /> : <></>}
                    </Ui.GroupBox>
                    <HisPlatformExtensionPoint
                        extensionProps={store.referralExtensionPanelProps}
                        type="ServiceRequestReferralData">
                        <></>
                    </HisPlatformExtensionPoint>
                    {!!store.medicalServiceIds?.length && <Ui.GroupBox title={StaticWebAppResources.NewServiceRequestPage.RequestedServices}>
                        <Ui.ValidationResultSummary
                            propertyPath="RequestedServices"
                        />
                        <HisUi.RequestedServiceListPanel
                            selectedItems={store.medicalServiceItems}
                            availableMedicalServices={store.medicalServiceIds}
                            isAvailable={store.canAddNewMedicalService}
                            availablePanels={store.serviceRequestDefinition.medicalServicePanelIds}
                            selectedPanels={store.serviceRequest.selectedMedicalServicePanelIds}
                            currentPatientGenderId={store.currentPatientGenderId}
                        />
                    </Ui.GroupBox>}
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <Ui.GroupBox title={StaticWebAppResources.NewServiceRequestPage.AdditionalData}>
                                <Ui.TextBox
                                    label={StaticWebAppResources.NewServiceRequestPage.AdditionalData}
                                    isReadOnly
                                    autoResize
                                    multiline
                                    multilineMaxLineCount={6}
                                    propertyIdentifier="AdditionalData"
                                    automationId="__additionalData"
                                    autoResizeHeight={store.textBoxHeight}
                                    onHeightChange={store.onTextBoxHeightChange}
                                />
                            </Ui.GroupBox>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={8}>
                            <Ui.GroupBox title={StaticWebAppResources.NewServiceRequestPage.ExecutionNotes}
                                automationId="__executionNotesPanel">
                                <Ui.Flex>
                                    <Ui.Flex.Item xs={6}>
                                        <HisPlatformExtensionPoint
                                            type="ServiceRequestClinicalQuestion"
                                            extensionProps={store.clinicalQuestionExtensionPanelProps}>
                                            <Ui.TextBox
                                                label={StaticWebAppResources.NewServiceRequestPage.ExecutionNotesLabels.ClinicalQuestion}
                                                value={store.clinicalQuestionValue}
                                                onChange={store.onClinicalQuestionChange}
                                                autoResize
                                                multiline
                                                multilineMaxLineCount={6}
                                                disabled={!store.isClinicalQuestionAllowed}
                                                propertyIdentifier="ClinicalQuestion"
                                                automationId="__clinicalQuestion"
                                                autoResizeHeight={store.textBoxHeight}
                                                onHeightChange={store.onTextBoxHeightChange}
                                            />
                                        </HisPlatformExtensionPoint>
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={6}>
                                        <Ui.TextBox
                                            label={StaticWebAppResources.NewServiceRequestPage.ExecutionNotesLabels.Remark}
                                            value={store.remarkValue}
                                            onChange={store.onRemarkChange}
                                            autoResize
                                            multiline
                                            multilineMaxLineCount={6}
                                            propertyIdentifier="Remark"
                                            automationId="__remark"
                                            autoResizeHeight={store.textBoxHeight}
                                            onHeightChange={store.onTextBoxHeightChange}
                                        />
                                    </Ui.Flex.Item>
                                </Ui.Flex>
                            </Ui.GroupBox>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </ReadOnlyContext.Provider>
            </ValidationBoundary>
            <BusinessErrorHandler.Register businessErrorName="CareActivityIsAlreadyAServiceRequestExecutingCareActivityError" handler={store.handleCareActivityIsAlreadyAServiceRequestExecutingCareActivityError} />
            <State.Reaction inspector={store.checkAppointmentRequired} reaction={store.setAppointmentStore} />
            <State.Reaction inspector={store.checkSuspectedDiagnosesList} reaction={store.setAppointmentReferralDiagnoses} />
            <State.Reaction inspector={store.checkRequesterDoctor} reaction={store.setAppointmentReferralDoctorId} />
            <NavigateAwayHook isEnabled onNavigateAwayAsync={store.navigateAwayAsync} />
        </Screen>
    );
}

export default connect<IServiceRequestScreenProps>(
    provideServiceRequestScreenStore(ServiceRequestScreen),
    new ScreenStateContextAdapter(),
    new HisModalServiceAdapter(),
    new CareActivityContextAdapter<IServiceRequestScreenProps>(ctx => ({
        _careActivity: ctx.careActivity,
        _careActivityBaseData: ctx.baseData,
        _careActivityId: ctx.careActivityId
    })),
    new PatientContextAdapter<IServiceRequestScreenProps>(c => ({
        _patientId: c.patientId,
        _patient: c.patient
    })),
    new FormExtensionRegistryAdapter("ManageServiceRequest")
);