import { fromByteArray, toByteArray } from "base64-js";
import StringBase64 from "js-base64";

const Base64Converter = {
    fromByteArray, 
    toByteArray,

    fromString: (plainText: string) => StringBase64.Base64.encode(plainText),
    toString: (base64Encoded: string) => StringBase64.Base64.decode(base64Encoded) 
};

export default Base64Converter;