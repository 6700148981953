import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import CareActivityId from "@Primitives/CareActivityId.g";
import PerformanceStatementCareActivityStatus from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/PerformanceStatementCareActivityStatus";

export default class PerformanceStatementCareActivity extends EntityStoreBase<CareActivityId> implements IDirtyChecked {

    public actualNeakStatus: PerformanceStatementCareActivityStatus = new PerformanceStatementCareActivityStatus();
    public actualEuStatus: PerformanceStatementCareActivityStatus = new PerformanceStatementCareActivityStatus();
    
    private _dirtyChecker = new StoreDirtyChecker();

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "isNew",
        "operationInfo"
    ];

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }
}