import Di from "@Di";
import Command from "@Toolkit/CommonWeb/CommandProcessing/Definition/Command";
import CommandMessage from "@Toolkit/CommonWeb/CommandProcessing/Definition/CommandMessage";
import ICommandFactory from "@Toolkit/CommonWeb/CommandProcessing/Definition/ICommandFactory";
import CreatePrescriptionCommand from "./CreatePrescriptionCommand";

@Di.injectable()
export default class CreatePrescriptionCommandFactory implements ICommandFactory {
    get commandType(): string {
        return "CreatePrescriptionCommand";
    }

    /** {@inheritdoc} */
    public createFromMessage(message: CommandMessage): Command {
        return new CreatePrescriptionCommand(message.originator, message.data.substanceName);
    }
}