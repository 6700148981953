import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Proxy.g";
import Di from "@Di";
import EntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/EntityStoreMapper";
import PerformanceReportScorePlanId from "@Primitives/PerformanceReportScorePlanId.g";
import PerformanceReportScorePlanStore from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportScorePlanStore";
import PlannedScoreItemStore from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PlannedScoreItemStore";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import PointOfCareId from "@Primitives/PointOfCareId.g";

type ResponseType = Proxy.GetPerformanceReportScorePlanByIdQueryResponse | Proxy.CreatePerformanceReportScorePlanCommandResponse | Proxy.UpdatePerformanceReportScorePlanCommandResponse;

@Di.injectable()
export default class PerformanceReportScorePlanStoreMapper extends EntityStoreMapper<Proxy.PerformanceReportScorePlanDto, PerformanceReportScorePlanStore, ResponseType> {
    
    constructor(
        validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }

    protected storeEntityIdType = PerformanceReportScorePlanId;

    protected entityDtoSelector(response: ResponseType) {
        return response.performanceReportScorePlan;
    }

    protected applyToStoreCore(target: PerformanceReportScorePlanStore, response: ResponseType): void {
        if (!response.performanceReportScorePlan) {
            return;
        }

        const entity = this.entityDtoSelector(response);
        target.planDate = entity.planDate;
        target.financingClassId = entity.financingClassId;
        target.plannedScoreItems = entity.plannedScoreItems.map(this.mapPlannedScoreItem);
    }
    
    private mapPlannedScoreItem(dto: Proxy.PlannedScoreItemDto): PlannedScoreItemStore {
        const result = new PlannedScoreItemStore();
        result.pointOfCareId = new PointOfCareId(dto.organizationUnitId.value);
        result.plannedScore = dto.plannedScore;
        return result;
    }
}