exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AppointmentBaseDataColumn_container_1l6L5 {\n  font-family: PragatiNarrow;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  white-space: nowrap;\n  width: fit-content;\n}\n.AppointmentBaseDataColumn_container_1l6L5 p {\n  margin: 0px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n.AppointmentBaseDataColumn_icon_230pi {\n  min-width: 16px;\n  top: -1px;\n  margin-right: 8px;\n}\n.AppointmentBaseDataColumn_date-field_33nRA {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  margin-top: 5px;\n  margin-bottom: 5px;\n}\n.AppointmentBaseDataColumn_location-field_2nLJt {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  margin-top: 5px;\n  margin-bottom: 5px;\n  max-width: 35%;\n  margin-right: 15px;\n}\n.AppointmentBaseDataColumn_service-field_1gvnA {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  margin-top: 5px;\n  margin-bottom: 5px;\n  max-width: 215px;\n  flex-grow: 1;\n}\n.AppointmentBaseDataColumn_practitioner-field_3qoG8 {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  margin-top: 5px;\n  margin-bottom: 5px;\n  max-width: 55%;\n  flex-grow: 1;\n}\n.AppointmentBaseDataColumn_item-field_3dWHd {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  margin-top: 5px;\n  margin-bottom: 5px;\n}\n.AppointmentBaseDataColumn_text-bold_hXhXo {\n  font-family: PragatiNarrowBold;\n}\n.AppointmentBaseDataColumn_doctor-code_3Z3kF {\n  font-family: PragatiNarrowBold;\n  min-width: fit-content;\n}\n.AppointmentBaseDataColumn_service-text_12odO {\n  max-width: 70%;\n}\n", ""]);

// exports
exports.locals = {
	"container": "AppointmentBaseDataColumn_container_1l6L5",
	"icon": "AppointmentBaseDataColumn_icon_230pi",
	"date-field": "AppointmentBaseDataColumn_date-field_33nRA",
	"dateField": "AppointmentBaseDataColumn_date-field_33nRA",
	"location-field": "AppointmentBaseDataColumn_location-field_2nLJt",
	"locationField": "AppointmentBaseDataColumn_location-field_2nLJt",
	"service-field": "AppointmentBaseDataColumn_service-field_1gvnA",
	"serviceField": "AppointmentBaseDataColumn_service-field_1gvnA",
	"practitioner-field": "AppointmentBaseDataColumn_practitioner-field_3qoG8",
	"practitionerField": "AppointmentBaseDataColumn_practitioner-field_3qoG8",
	"item-field": "AppointmentBaseDataColumn_item-field_3dWHd",
	"itemField": "AppointmentBaseDataColumn_item-field_3dWHd",
	"text-bold": "AppointmentBaseDataColumn_text-bold_hXhXo",
	"textBold": "AppointmentBaseDataColumn_text-bold_hXhXo",
	"doctor-code": "AppointmentBaseDataColumn_doctor-code_3Z3kF",
	"doctorCode": "AppointmentBaseDataColumn_doctor-code_3Z3kF",
	"service-text": "AppointmentBaseDataColumn_service-text_12odO",
	"serviceText": "AppointmentBaseDataColumn_service-text_12odO"
};