import React from "react";
import Style from "./AlertCardContainer.less";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import { IAlertCardProps } from "@CommonControls/AlertCard";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class AlertCardContainer extends React.Component {

    private containerDiv: HTMLDivElement;
    private adjustCardsCounter = 0;

    @State.observable.ref private alertCardCollapseStates: boolean[];
    @State.observable private isOpen = false;

    constructor(props: undefined) {
        super(props);

        this.alertCardCollapseStates = React.Children.map(this.props.children, () => false);
    }

    public componentDidMount() {
        this.collapseLastIfOverflow();
    }

    public componentDidUpdate() {
        this.collapseLastIfOverflow();
    }

    @State.action
    private collapseLastIfOverflow() {
        State.runInAction(() => {
            if (this.containerDiv.scrollWidth > this.containerDiv.clientWidth) {
                if (++this.adjustCardsCounter > this.alertCardCollapseStates.length + 1) {
                    return;
                }
                const modifiedArray = Array.from(this.alertCardCollapseStates);
                const lastFalse = modifiedArray.lastIndexOf(false);
                modifiedArray.splice(lastFalse, 1, true);
                this.alertCardCollapseStates = modifiedArray;
            } else {
                this.adjustCardsCounter = 0;
            }
        });
    }

    @State.action.bound
    private mouseOverHandler() {
        this.isOpen = true;
        this.alertCardCollapseStates = React.Children.map(this.props.children, () => false);
    }

    @State.action.bound
    private mouseLeaveHandler() {
        this.isOpen = false;
    }

    public render() {

        const classes = new CompositeClassName(Style.dropdown);
        classes.addIf(this.isOpen, Style.dropdownOpen);

        return (            
            <div className={Style.alertcardsContainer}>
                <div className={classes.classNames} ref={(me) => this.containerDiv = me} onMouseEnter={this.mouseOverHandler} onMouseLeave={this.mouseLeaveHandler}>
                    {React.Children.map(this.props.children, (child: React.ReactElement<IAlertCardProps>, index: number) => {
                        return React.cloneElement(child, {
                            collapsed: this.alertCardCollapseStates[index]
                        });
                    })}
                </div>
            </div>
        );
    }
}