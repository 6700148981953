import React, { useContext } from "react";
import ModalServiceReactContext from "@Toolkit/ReactClient/Components/ModalService/ModalServiceContext";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityContext, { CareActivityReactContext } from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContext";
import PatientContext, { PatientReactContext } from "@HisPlatform/Model/DomainModel/PatientContext/PatientContext";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";


const HisPlatformModalRenderer: React.FC = () => {
    const modalContext = useContext(ModalServiceReactContext);

    return State.useObserver(() => {
        const activeModals = Array.from(modalContext.activeModals.values());
        return (
            <>
                {activeModals.map((m, index) => {
                    const ModalComponent = m.component;
                    const hisParams: { _patientContext?: PatientContext, _careActivityContext?: CareActivityContext } =
                        m.params as any;

                    return (
                        <CareActivityReactContext.Provider key={m.id} value={hisParams._careActivityContext}>
                            <PatientReactContext.Provider value={hisParams._patientContext}>
                                <ModalComponent
                                    {...m.params}
                                    onClose={m.onClose}
                                    index={index}
                                />
                            </PatientReactContext.Provider>
                        </CareActivityReactContext.Provider>
                    );
                })}
            </>
        );
    });
};

export default HisPlatformModalRenderer;