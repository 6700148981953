import React from "react";
import * as Ui from "@CommonControls";
import TelecomUseSelectBox from "@HisPlatform/BoundedContexts/CommonReferenceData/Components/Controls/TelecomUseSelectBox";
import PractitionerTelecomStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerTelecomStore";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";

interface IPractitionerTelecomViewProps {
    telecomStore: PractitionerTelecomStore;
    index: number;
}


const PractitionerTelecomView: React.FC<IPractitionerTelecomViewProps> = props =>
    (
        <>
            <Ui.Flex>
                <Ui.Flex.Item xs={6}>
                    <TelecomUseSelectBox
                        label={StaticUserManagementResources.UserPanel.Label.TelecomUse}
                        value={props.telecomStore.telecomUseId}
                        onChange={props.telecomStore.setTelecomUseId}
                        propertyIdentifier="UseId"
                        automationId="useId"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={6}>
                    {props.telecomStore.isEmail && (
                        <Ui.TextBox
                            label={StaticUserManagementResources.UserPanel.Label.Email}
                            value={props.telecomStore.value}
                            onChange={props.telecomStore.setValue}
                            propertyIdentifier="Value"
                            automationId="email"
                        />
                    )}
                    {props.telecomStore.isPhone && (
                        <Ui.PhoneTextBox
                            label={StaticUserManagementResources.UserPanel.Label.PhoneNumber}
                            value={props.telecomStore.value}
                            onChange={props.telecomStore.setValue}
                            propertyIdentifier="Value"
                            automationId="phoneNumber"
                        />
                    )}
                </Ui.Flex.Item>
            </Ui.Flex>
        </>
    );

export default State.observer(PractitionerTelecomView);
