import Mousetrap from "mousetrap";
import React, { useEffect } from "react";

interface IShortcutProps {
    keys: string | string[];
    onPressed: () => void;
}

export default function Shortcut(props: IShortcutProps) {
    useEffect(() => {
        Mousetrap.bind(props.keys, () => {
            props.onPressed();
        });

        return () => {
            Mousetrap.unbind(props.keys);
        };
    }, []);

    return null as any;
}