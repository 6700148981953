import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { ITelemetrySessionMeasurementsDialogParams, ITelemetrySessionMeasurementsDialogResult } from "./TelemetrySessionMeasurementsDialogParams";
import Modal from "@CommonControls/Modal";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CloseTelemetrySessionMeasurementsDialogCommand from "@AssecoMedPlugin/Packages/Care/OperationProcessing/CloseTelemetrySessionMeasurementsDialogCommand";
import IMessagingSubscription from "@Toolkit/CommonWeb/CommandProcessing/Definition/IMessagingSubscription";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import IMessagingSubscriptionManager from "@Toolkit/CommonWeb/CommandProcessing/Definition/IMessagingSubscriptionManager";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import StaticAssecoMedWebAppResources from "@AssecoMedPlugin/StaticResources/StaticAssecoMedWebAppResources";

export interface ITelemetrySessionMeasurementsDialogDependencies {
    messagingSubscriptionManager: IMessagingSubscriptionManager;
}

interface ITelemetrySessionMeasurementsDialogProps extends IModalComponentParams<ITelemetrySessionMeasurementsDialogResult>, ITelemetrySessionMeasurementsDialogParams {
    _dependencies?: ITelemetrySessionMeasurementsDialogDependencies;
}

function TelemetrySessionMeasurementsDialog(props: ITelemetrySessionMeasurementsDialogProps) {
    const startSessionUrl = formatStringWithObjectParams(props.url, { patientId: props.externalPatientId });
    const [messagingSubscription, setMessagingSubscription] = useState<IMessagingSubscription | null>(null);
    const messagingSubscriptionManager = useMemo(() => props._dependencies.messagingSubscriptionManager, [props]);
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        const messagingSubscription = messagingSubscriptionManager.subscribeWithAccessor(() => iframeRef.current.contentWindow);

        messagingSubscription.registerCallback<CloseTelemetrySessionMeasurementsDialogCommand>("CloseTelemetrySessionMeasurementsDialogCommand", command => {
            props.onClose({
                isCancelled: command.isCancelled
            } as ITelemetrySessionMeasurementsDialogResult);
            return Promise.resolve();
        });

        setMessagingSubscription(messagingSubscription);

        return () => {
            if (!!messagingSubscription) {
                messagingSubscription.dispose();
            }
            if (iframeRef.current) {
                iframeRef.current.onload = null;
            }
        };
    }, []);

    const cancel = useCallback(() => {
        props.onClose({
            isCancelled: true
        } as ITelemetrySessionMeasurementsDialogResult);
    }, []);

    return (
        <Modal isOpen title={StaticAssecoMedWebAppResources.TelemetrySessionScreen.Title} closeButton onClose={cancel} size="fullscreen">
            <iframe
                id={"EmbeddedTelemetrySessionScreen"}
                src={startSessionUrl}
                ref={iframeRef}
                style={{ width: "100%", height: "100%", border: "none" }}
                allow="*"
            />
        </Modal>
    );
}

export default connect<ITelemetrySessionMeasurementsDialogProps>(
    TelemetrySessionMeasurementsDialog,
    new DependencyAdapter<ITelemetrySessionMeasurementsDialogProps, ITelemetrySessionMeasurementsDialogDependencies>(c => ({
        messagingSubscriptionManager: c.resolve("IMessagingSubscriptionManager")
    }))
);