exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ServiceObservationsPanel_requested_3gUAZ {\n  background-color: #E8E8E8;\n}\n.ServiceObservationsPanel_created_3RN6x {\n  background-color: #E8E8E8;\n}\n.ServiceObservationsPanel_under-recording_KB7nK {\n  background-color: #e8e8e8;\n}\n.ServiceObservationsPanel_submitted_29UAx {\n  background-color: #e8e8e8;\n}\n.ServiceObservationsPanel_registered-as-received_1Sh4j {\n  background-color: #82d4ed;\n}\n.ServiceObservationsPanel_activity-completed_iVO6t {\n  background-color: #ddc00d;\n}\n.ServiceObservationsPanel_non-validated-completed_2oGSg {\n  background-color: #fe8443;\n}\n.ServiceObservationsPanel_validated-completed_3RJO5 {\n  background-color: #21c313;\n}\n.ServiceObservationsPanel_deleted_2kQ6s {\n  background-color: #ce5374;\n}\n", ""]);

// exports
exports.locals = {
	"requested": "ServiceObservationsPanel_requested_3gUAZ",
	"created": "ServiceObservationsPanel_created_3RN6x",
	"under-recording": "ServiceObservationsPanel_under-recording_KB7nK",
	"underRecording": "ServiceObservationsPanel_under-recording_KB7nK",
	"submitted": "ServiceObservationsPanel_submitted_29UAx",
	"registered-as-received": "ServiceObservationsPanel_registered-as-received_1Sh4j",
	"registeredAsReceived": "ServiceObservationsPanel_registered-as-received_1Sh4j",
	"activity-completed": "ServiceObservationsPanel_activity-completed_iVO6t",
	"activityCompleted": "ServiceObservationsPanel_activity-completed_iVO6t",
	"non-validated-completed": "ServiceObservationsPanel_non-validated-completed_2oGSg",
	"nonValidatedCompleted": "ServiceObservationsPanel_non-validated-completed_2oGSg",
	"validated-completed": "ServiceObservationsPanel_validated-completed_3RJO5",
	"validatedCompleted": "ServiceObservationsPanel_validated-completed_3RJO5",
	"deleted": "ServiceObservationsPanel_deleted_2kQ6s"
};