import React from "react";
import * as Ui from "@CommonControls";
import Styles from "./BMICalculator.less";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import FormattedText from "@CommonControls/FormattedText/FormattedText";

interface IResultViewProps {
    result: number;
    category: string;
    reset: () => void;
}


const ResultView: React.FC<IResultViewProps> = props => {
    const resources = StaticProductivityResources.Widgets.BMICalculator;
    return (
        <div>
            <Ui.Flex smJustify="center">
                <Ui.Flex.Item className={Styles.result}>
                    BMI: <span data-automation-id="resultText">{props.result}</span>
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Flex smJustify="center">
                <Ui.Flex.Item>
                    <FormattedText p_result={props.result + ""}>{resources.ResultText}</FormattedText>:<br />
                    <span className={Styles.resultCategory} data-automation-id="resultCategoryText">{props.category}</span>
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Flex smJustify="center">
                <Ui.Flex.Item>
                    <Ui.Button text={resources.Reset} onClick={props.reset} visualMode="dark" visualStyle="secondary" automationId="resetButton" />
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Flex smJustify="center">
                <Ui.Flex.Item xs={11}>
                    {resources.Footer}
                </Ui.Flex.Item>
            </Ui.Flex>
        </div>
    );
};

export default ResultView;