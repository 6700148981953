import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import CloneableLockingEntityStoreBase from "@Toolkit/CommonWeb/Model/CloneableLockingEntityStoreBase";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import SuspectedDiagnosis from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/SuspectedDiagnosis";
import State, {IObservableArray} from "@Toolkit/ReactClient/Common/StateManaging";
import {CareLocation} from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/CareLocation";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import {isNullOrUndefined, arrayIsNullOrEmpty} from "@Toolkit/CommonWeb/NullCheckHelpers";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import moment, {Moment} from "moment";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import PractitionerId from "@Primitives/PractitionerId.g";
import PatientId from "@Primitives/PatientId.g";
import ServiceRequestAction from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestAction.g";
import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";
import MedicalServicePriority from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/MedicalServices/Enum/MedicalServicePriority.g";
import ServiceRequestState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestState.g";
import RequestedServiceState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceState.g";
import SpecimenTypeId from "@Primitives/SpecimenTypeId.g";
import MedicalServicePanelId from "@Primitives/MedicalServicePanelId.g";

export default class ServiceRequestStore extends CloneableLockingEntityStoreBase<ServiceRequestStore, ServiceRequestId> {
    @State.observable.ref public serviceRequestIdentifier: string;
    @State.observable.ref public serviceRequestDefinitionVersion: IEntityVersionSelector<ServiceRequestDefinitionId>;
    @State.observable.ref public starterCareActivityId: CareActivityId;
    @State.observable.ref public executingCareActivityId: CareActivityId;
    @State.observable.ref public targetDoctorId?: PractitionerId;
    @State.observable.ref public requesterDoctorId?: PractitionerId;
    @State.observable.ref public createdAt?: moment.Moment;
    @State.observable public clinicalQuestion?: string;
    @State.observable public remark?: string;
    @State.observable public referralCareIdentifier: string;
    @State.observable.ref public referralLocation?: CareLocation;
    @State.observable.ref public executingDoctorId?: PractitionerId = null;
    @State.observable.ref public possibleActions: ServiceRequestAction[];
    @State.observable.ref public direction: ServiceRequestDirection | null = null;
    @State.observable.ref public patientId: PatientId;
    @State.observable.ref public explicitExpectedCompletionTime: moment.Moment;
    @State.observable.ref public explicitPriority: MedicalServicePriority;
    @State.observable.ref public specimenTypeId?: SpecimenTypeId;
    @State.observable.ref public specimenCollectedAt?: moment.Moment;
    public selectedMedicalServicePanelIds = State.observable<MedicalServicePanelId>([]);

    public isNull = false;

    public isExternal: boolean;
    @State.observable.ref public targetExternalLocationVersionSelector?: EntityVersionSelector<ExternalLocationId>;
    @State.observable.ref public targetPointOfCareId?: PointOfCareId;

    @State.observable.ref public extensionData: any = null;

    @State.computed
    public get expectedCompletionTime() {
        return arrayIsNullOrEmpty(this.requestedServices) ? this.explicitExpectedCompletionTime : this.requestedServices[0].expectedCompletionTime;
    }

    public get medicalServicePriority() {
        return arrayIsNullOrEmpty(this.requestedServices) ? this.explicitPriority : this.requestedServices[0].priority;
    }

    @State.computed
    public get targetCareLocation(): CareLocation {
        if (this.isExternal) {
            return isNullOrUndefined(this.targetExternalLocationVersionSelector) || isNullOrUndefined(this.targetExternalLocationVersionSelector.id) ?
                null :
                new ExternalCareLocation(this.targetExternalLocationVersionSelector);
        } else {
            return isNullOrUndefined(this.targetPointOfCareId) ?
                null :
                new InternalCareLocation(this.targetPointOfCareId);
        }
    }

    public set targetCareLocation(careLocation: CareLocation) {
        if (!isNullOrUndefined(careLocation)) {
            if (careLocation instanceof ExternalCareLocation) {
                this.isExternal = true;
                this.targetPointOfCareId = null;
                this.targetExternalLocationVersionSelector = careLocation.externalLocationSelector;
            } else if (careLocation instanceof InternalCareLocation) {
                this.isExternal = false;
                this.targetExternalLocationVersionSelector = null;
                this.targetPointOfCareId = careLocation.pointOfCareId;
            } else {
                throw new Error("Unknown CareLocation type.");
            }
        }
    }

    public suspectedDiagnosisList = State.observable<SuspectedDiagnosis>([]);
    @State.observable public suspectedDiagnosisListReadOnly = false;
    public requestedServices = State.observable<RequestedServiceStore>([]);
    @State.observable.ref public state: ServiceRequestState;

    @State.action.bound
    public setServiceRequestDefinitionVersion(newValue: IEntityVersionSelector<ServiceRequestDefinitionId>) {
        this.serviceRequestDefinitionVersion = newValue;
    }

    @State.action.bound
    public setStarterCareActivityId(careActivityId: CareActivityId) {
        this.starterCareActivityId = careActivityId;
    }

    @State.action.bound
    public setTargetDoctorId(doctorId: PractitionerId) {
        this.targetDoctorId = doctorId;
    }

    @State.action.bound
    public setRequesterDoctorId(doctorId: PractitionerId) {
        this.requesterDoctorId = doctorId;
    }

    @State.action.bound
    public setClinicalQuestion(newValue: string) {
        this.clinicalQuestion = newValue;
    }

    @State.action.bound
    public setRemark(newValue: string) {
        this.remark = newValue;
    }

    @State.action.bound
    public setTargetExternalLocationVersionSelector(newValue: ExternalLocationId) {
        this.targetExternalLocationVersionSelector = this.targetExternalLocationVersionSelector.withId(newValue);
    }

    @State.action.bound
    public setTargetPointOfCareId(newValue: PointOfCareId) {
        this.targetPointOfCareId = newValue;
    }

    @State.action.bound
    public setSuspectedDiagnosisList(newValue: IObservableArray<SuspectedDiagnosis>) {
        this.suspectedDiagnosisList = newValue;
    }

    @State.action.bound
    public setSuspectedDiagnosisListReadOnly(newValue: boolean) {
        this.suspectedDiagnosisListReadOnly = newValue;
    }

    @State.action.bound
    public setRequestedServices(newValue: IObservableArray<RequestedServiceStore>) {
        this.requestedServices = newValue;
    }

    @State.action.bound
    public setSelectedMedicalServicePanelIds(newValue: IObservableArray<MedicalServicePanelId>) {
        this.selectedMedicalServicePanelIds = newValue;
    }

    @State.action.bound
    public setReferralCareIdentifier(careIdentifier: string) {
        this.referralCareIdentifier = careIdentifier;
    }

    @State.action.bound
    public setCreatedAt(createdAt: Moment) {
        this.createdAt = createdAt;
    }

    @State.action.bound
    public setExecutingDoctorId(doctorId: PractitionerId) {
        this.executingDoctorId = doctorId;
    }

    @State.action.bound
    public setExpectedCompletionTime(newValue: moment.Moment) {
        this.explicitExpectedCompletionTime = newValue;
    }

    @State.action.bound
    public setPriority(newValue: MedicalServicePriority) {
        this.explicitPriority = newValue;
    }

    @State.action.bound
    public setSpecimenTypeId(specimenTypeId: SpecimenTypeId) {
        this.specimenTypeId = specimenTypeId;
    }

    @State.action.bound
    public setSpecimenCollectedAt(collectedAt: Moment) {
        this.specimenCollectedAt = collectedAt;
    }

    @State.computed
    public get nonMatchingChildrenInfo() {
        return {
            all: this.requestedServices.slice().length,
            nonMatchingCount: this.getNonMatchingStateRequestedServiceCount()
        };
    }

    @State.bound
    public getNonMatchingStateRequestedServiceCount() {
        return this.requestedServices.slice().filter(rs => !this.statesAreEqual(this.state, rs.state)).length;
    }

    @State.action.bound
    public updatePanelSelection(panelId: MedicalServicePanelId, isSelected: boolean): void {
        if (isSelected) {
            this.selectedMedicalServicePanelIds.push(panelId);
        } else {
            this.selectedMedicalServicePanelIds.remove(panelId);
        }
    }

    private statesAreEqual(serviceRequestState: ServiceRequestState, requestedServiceState: RequestedServiceState) {
        return serviceRequestState.valueOf() === requestedServiceState.valueOf() // this relies on matching items in both enums
            || serviceRequestState === ServiceRequestState.Deleted
            && (requestedServiceState === RequestedServiceState.DeletedByExecutorSideAfterCompletion
                || requestedServiceState === RequestedServiceState.DeletedByExecutorSideBeforeCompletion
                || requestedServiceState === RequestedServiceState.DeletedByRequesterSide);
    }

    public get isValid(): boolean {
        return super.isValid && this.requestedServices.every(i => i.isValid) && this.suspectedDiagnosisList.every(i => i.isValid);
    }

    @State.action.bound
    public setExtensionData(newValue: any) {
        this.extensionData = newValue;
    }

    protected get vPropertyNamesExcludedFromDirtyCheck(): string[] {
        return [
            "_dirtyChecker",
            "operationInfo",
            "hasValidationError",
            "hasValidationWarning",
            "propertyNamesExcludedFromDirtyCheck",
            "extensionData",
            "explicitExpectedCompletionTime",
            "explicitPriority"
        ];
    }
}
