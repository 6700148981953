import React from "react";
import { CheckBox, Button } from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import IMedicalConditionSynchronizationExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IMedicalConditionSynchronizationExtensionPointProps";
import StaticHunEHealthInfrastructureWebAppResources from "@HunEHealthInfrastructurePlugin/StaticResources/StaticHunEHealthInfrastructureWebAppResources";
import EhiImmunizationApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiImmunizationApiAdapter";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IEhiMedicalConditionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/IEhiMedicalConditionApiAdapter";
import MedicalConditionType from "@Primitives/MedicalConditionType";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import EhiEmergencyDialogParams from "@HunEHealthInfrastructurePlugin/Common/Components/EhiEmergencyDialog/EhiEmergencyDialogParams";
import { formatReactStringWithLineBreaks } from "@Toolkit/ReactClient/Common/LocalizedStrings";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import IEhiError from "@HunEHealthInfrastructurePlugin/Common/Model/IEhiError";
import EhiAutonomyDisabilityStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiAutonomyDisabilityStatementApiAdapter";
import EhiCurrentConditionStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiCurrentConditionStatementApiAdapter";
import EhiFormerConditionStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiFormerConditionStatementApiAdapter";
import EhiPregnancyStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiPregnancyStatementApiAdapter";
import EhiDeviceUseStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiDeviceUseStatementApiAdapter";
import EhiMedicationStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiMedicationStatementApiAdapter";
import EhiProcedureStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiProcedureStatementApiAdapter";
import EhiMedicalConditionService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Services/EhiMedicalConditionService";
import EhiMedicalConditionServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Services/EhiMedicalConditionServiceFactory";
import EhiServiceCallStatus from "@HunEHealthInfrastructurePlugin/Model/EhiServiceCallStatus";
import EhiRiskAssessmentApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiRiskAssessmentApiAdapter";
import EhiMedicalConditionSynchronizationExtensionStore from "./EhiMedicalConditionSynchronizationExtensionStore";
import EhiTherapyRecommendationApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiTherapyRecommendationApiAdapter";
import EhiPatientAllergyIntoleranceApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiPatientAllergyIntoleranceAdapter";
import EhiPatientBloodTypeApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiPatientBloodTypeApiAdapter";
import EhiMedicalAlertStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiMedicalAlertStatementApiAdapter";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import IBusinessError from "@Toolkit/CommonWeb/Model/IBusinessError";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";

interface IEhiMedicalConditionSynchronizationExtensionPanelDependencies {
    dialogService: IDialogService;
    modalService: IModalService;
    ehiImmunizationApiAdapter: EhiImmunizationApiAdapter;
    ehiAutonomyDisabilityStatementApiAdapter: EhiAutonomyDisabilityStatementApiAdapter;
    ehiPregnancyStatementApiAdapter: EhiPregnancyStatementApiAdapter;
    ehiMedicationStatementApiAdapter: EhiMedicationStatementApiAdapter;
    ehiCurrentConditionStatementApiAdapter: EhiCurrentConditionStatementApiAdapter;
    ehiFormerConditionStatementApiAdapter: EhiFormerConditionStatementApiAdapter;
    ehiDeviceUseStatementApiAdapter: EhiDeviceUseStatementApiAdapter;
    ehiProcedureStatementApiAdapter: EhiProcedureStatementApiAdapter;
    ehiMedicalConditionServiceFactory: EhiMedicalConditionServiceFactory;
    ehiRiskAssessmentApiAdapter: EhiRiskAssessmentApiAdapter;
    ehiPatientAllergyIntoleranceApiAdapter: EhiPatientAllergyIntoleranceApiAdapter;
    ehiTherapyRecommendationApiAdapter: EhiTherapyRecommendationApiAdapter;
    ehiPatientBloodTypeApiAdapter: EhiPatientBloodTypeApiAdapter;
    ehiMedicalAlertStatementApiAdapter: EhiMedicalAlertStatementApiAdapter;
    notificationService: INotificationService;
}

interface IEhiMedicalConditionSynchronizationExtensionPanelProps extends IMedicalConditionSynchronizationExtensionPointProps {
    _dependencies?: IEhiMedicalConditionSynchronizationExtensionPanelDependencies;
}

@State.observer
export class EhiMedicalConditionSynchronizationExtensionPanel extends React.Component<IEhiMedicalConditionSynchronizationExtensionPanelProps> {
    @State.observable private isEmergency: boolean = false;

    private get dialogService() { return this.props._dependencies.dialogService; }
    private get modalService() { return this.props._dependencies.modalService; }
    private get medicalConditionType() { return this.props.medicalConditionType; }
    private get patientId() { return this.props.patientId; }

    private ehiMedicalConditionService: EhiMedicalConditionService = null;

    private get currentApiAdapter(): IEhiMedicalConditionApiAdapter {
        if (this.medicalConditionType === MedicalConditionType.Immunization) {
            return this.props._dependencies.ehiImmunizationApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.AutonomyDisabilityStatement) {
            return this.props._dependencies.ehiAutonomyDisabilityStatementApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.PregnancyStatement) {
            return this.props._dependencies.ehiPregnancyStatementApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.MedicationStatement) {
            return this.props._dependencies.ehiMedicationStatementApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.CurrentConditionStatement) {
            return this.props._dependencies.ehiCurrentConditionStatementApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.FormerConditionStatement) {
            return this.props._dependencies.ehiFormerConditionStatementApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.DeviceUseStatement) {
            return this.props._dependencies.ehiDeviceUseStatementApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.ProcedureStatement) {
            return this.props._dependencies.ehiProcedureStatementApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.RiskAssessment) {
            return this.props._dependencies.ehiRiskAssessmentApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.PatientAllergyIntolerance) {
            return this.props._dependencies.ehiPatientAllergyIntoleranceApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.TherapyRecommendation) {
            return this.props._dependencies.ehiTherapyRecommendationApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.PatientBloodType) {
            return this.props._dependencies.ehiPatientBloodTypeApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.MedicalAlertStatement) {
            return this.props._dependencies.ehiMedicalAlertStatementApiAdapter;
        }

        return null;
    }

    public componentDidMount() {
        this.ehiMedicalConditionService = this.props._dependencies.ehiMedicalConditionServiceFactory.getEhiMedicalConditionService(this.currentApiAdapter);
    }

    @State.action.bound
    private setEhiErrors(newValue: IEhiError[]) {
        const extensionStore =  this.props.extensionStore.getOrAddExtensionStore("ehiMedicalConditionSynchronizationExtensionStore", () => new EhiMedicalConditionSynchronizationExtensionStore());
        extensionStore.setEhiErrors(newValue);
    }

    @State.action.bound
    private setIsEmergency(newValue: boolean) {
        this.isEmergency = newValue;
    }

    @State.action.bound
    private async synchronizeAsync() {
        let selfDeterminationAccessOverrideReason = "";

        const ehiHasDesynchronizedItemsCallResult = await this.ehiMedicalConditionService.hasDesynchronizedItemsAsync(this.patientId);

        if (ehiHasDesynchronizedItemsCallResult.ehiServiceCallStatus === EhiServiceCallStatus.Success &&
            ehiHasDesynchronizedItemsCallResult.result &&
            ehiHasDesynchronizedItemsCallResult.result.operationWasSuccessful) {

            if (isNullOrUndefined(ehiHasDesynchronizedItemsCallResult.result.value)) {
                return;
            }

            if (this.isEmergency) {
                const dialogResult = await this.modalService.showDialogAsync<string>(new EhiEmergencyDialogParams());
                if (!isNullOrEmptyString(dialogResult)) {
                    selfDeterminationAccessOverrideReason = dialogResult;
                } else {
                    return;
                }
            }

            let callSynchronize = true;
            let deleteLocalEntities = false;

            if (ehiHasDesynchronizedItemsCallResult.result.value.value === true) {
                const dialogResult = await this.dialogService.yesNoCancel(
                    StaticHunEHealthInfrastructureCareResources.EhiMedicalConditionMessages.CanSynchronizeDialogTitle,
                    formatReactStringWithLineBreaks(StaticHunEHealthInfrastructureCareResources.EhiMedicalConditionMessages.CanSynchronizeDialogMessage));

                if (dialogResult.resultCode === DialogResultCode.Cancel) {
                    callSynchronize = false;
                } else {
                    deleteLocalEntities = dialogResult.resultCode === DialogResultCode.No;
                }
            }

            if (callSynchronize) {
                const ehiSynchronizeItemsCallResult = await this.ehiMedicalConditionService.synchronizeItemsAsync(this.patientId, deleteLocalEntities, this.isEmergency, selfDeterminationAccessOverrideReason);

                if (ehiSynchronizeItemsCallResult.ehiServiceCallStatus === EhiServiceCallStatus.Success &&
                    ehiSynchronizeItemsCallResult.result &&
                    ehiSynchronizeItemsCallResult.result.operationWasSuccessful) {
                        this.setEhiErrors(ehiSynchronizeItemsCallResult.result.value.errors);
                }
            }

            await this.props.medicalConditionSynchronizationExtensionEvent.emitAsync();
            await this.props.refreshListAsync();
        }
    }

    @State.action.bound
    private handleNoEhiCompatibleIdentifierFoundError(error: IBusinessError): boolean {
        this.props._dependencies.notificationService.error(StaticHunEHealthInfrastructureCareResources.CommonEhiMessage.NoEhiCompatibleIdentifierFoundError);
        return true;
    }

    public render() {
        return (
            <>
                <BusinessErrorHandler.Register businessErrorName="NoEhiCompatibleIdentifierFoundError" handler={this.handleNoEhiCompatibleIdentifierFoundError} />
                <div style={{ paddingBottom: 9, paddingRight: 4 }}>
                    <CheckBox
                        {...this.props}
                        label={StaticHunEHealthInfrastructureWebAppResources.EmergencyCheckBoxLabel}
                        onChange={this.setIsEmergency}
                        value={this.isEmergency}
                        automationId="isEmergencyCheckBox"
                    />
                </div>
                <div>
                    <Button
                        text={StaticHunEHealthInfrastructureWebAppResources.MedicalConditionEhiQueryButtonText}
                        onClickAsync={this.synchronizeAsync}
                        automationId="queryButton"
                        visualStyle="standard"
                        iconName="sync"
                    />
                </div>
            </>
        );
    }
}

export default connect(
    EhiMedicalConditionSynchronizationExtensionPanel,
    new DependencyAdapter<IEhiMedicalConditionSynchronizationExtensionPanelProps, IEhiMedicalConditionSynchronizationExtensionPanelDependencies>(c => ({
        dialogService: c.resolve<IDialogService>("IDialogService"),
        modalService: c.resolve<IModalService>("IModalService"),
        ehiImmunizationApiAdapter: c.resolve<EhiImmunizationApiAdapter>("EhiImmunizationApiAdapter"),
        ehiAutonomyDisabilityStatementApiAdapter: c.resolve<EhiAutonomyDisabilityStatementApiAdapter>("EhiAutonomyDisabilityStatementApiAdapter"),
        ehiPregnancyStatementApiAdapter: c.resolve<EhiPregnancyStatementApiAdapter>("EhiPregnancyStatementApiAdapter"),
        ehiMedicationStatementApiAdapter: c.resolve<EhiMedicationStatementApiAdapter>("EhiMedicationStatementApiAdapter"),
        ehiCurrentConditionStatementApiAdapter: c.resolve<EhiCurrentConditionStatementApiAdapter>("EhiCurrentConditionStatementApiAdapter"),
        ehiFormerConditionStatementApiAdapter: c.resolve<EhiFormerConditionStatementApiAdapter>("EhiFormerConditionStatementApiAdapter"),
        ehiDeviceUseStatementApiAdapter: c.resolve<EhiDeviceUseStatementApiAdapter>("EhiDeviceUseStatementApiAdapter"),
        ehiProcedureStatementApiAdapter: c.resolve<EhiProcedureStatementApiAdapter>("EhiProcedureStatementApiAdapter"),
        ehiMedicalConditionServiceFactory: c.resolve<EhiMedicalConditionServiceFactory>("EhiMedicalConditionServiceFactory"),
        ehiRiskAssessmentApiAdapter: c.resolve<EhiRiskAssessmentApiAdapter>("EhiRiskAssessmentApiAdapter"),
        ehiPatientAllergyIntoleranceApiAdapter: c.resolve<EhiPatientAllergyIntoleranceApiAdapter>("EhiPatientAllergyIntoleranceApiAdapter"),
        ehiTherapyRecommendationApiAdapter: c.resolve<EhiTherapyRecommendationApiAdapter>("EhiTherapyRecommendationApiAdapter"),
        ehiPatientBloodTypeApiAdapter: c.resolve<EhiPatientBloodTypeApiAdapter>("EhiPatientBloodTypeApiAdapter"),
        ehiMedicalAlertStatementApiAdapter: c.resolve<EhiMedicalAlertStatementApiAdapter>("EhiMedicalAlertStatementApiAdapter"),
        notificationService: c.resolve<INotificationService>("INotificationService")
    }))
);
