import { IFormLogicExecutor } from "@HisPlatform/Services/Definition/FormLogicRegistry/IFormLogicRegistry";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import IFormLogic from "@Toolkit/FormEngine/Model/IFormLogic";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import _ from "@HisPlatform/Common/Lodash";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import { ChangeAction } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";

export default class FormLogicExecutor implements IFormLogicExecutor {

    constructor(
        private readonly form: IForm,
        private readonly showScreenAction: ShowScreenFrontendActionBase,
        private readonly formLogicList: IFormLogic[],
        private readonly dataElements: FormDataElementBase[],
        private readonly onLogicStarted?: () => void,
        private readonly onLogicFinished?: () => void
    ) { }

    public async formLoadedAsync(): Promise<void> {
        try {
            this.onLogicStarted?.();
            for (const formLogic of this.formLogicList) {
                await formLogic.onFormLoadedAsync?.(this.form, this.showScreenAction, this.dataElements,);
            }
        }
        finally {
            this.onLogicFinished?.();
        }
    }

    public async fieldChangedAsync(
        action: ChangeAction,
        propertyIdentifier?: string,
        newValue?: any
    ): Promise<void> {
        try {
            this.onLogicStarted?.();
            for (const formLogic of this.formLogicList) {
                await formLogic.onFieldChangedAsync?.(this.form, this.showScreenAction, this.dataElements, propertyIdentifier, newValue, action);
                await formLogic.executeAsync?.(this.form, this.showScreenAction, this.dataElements);
            }
        }
        finally {
            this.onLogicFinished?.();
        }
    }
}
