import React from "react";
import Styles from "./NavBar.less";

export default class NavBarItemGroup extends React.PureComponent<any> {

    public render() {
        return (
            <div className={Styles.itemGroup}>
                {this.props.children}
            </div>
        );
    }
}