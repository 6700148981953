exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SingleAccordion_no-text-selection_3Sjh2 {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.SingleAccordion_header_2KnRG {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n  display: \"flex\";\n  align-items: center;\n  cursor: pointer;\n}\n.SingleAccordion_header-title_lzyb_ {\n  width: calc(100% - 16px);\n}\n.SingleAccordion_caret-icon_1wLkP {\n  margin: 0 auto;\n  -webkit-transition-duration: 0.2s;\n  -moz-transition-duration: 0.2s;\n  -o-transition-duration: 0.2s;\n  transition-duration: 0.2s;\n  -webkit-transition-property: -webkit-transform;\n  -moz-transition-property: -moz-transform;\n  -o-transition-property: -o-transform;\n  transition-property: transform;\n}\n.SingleAccordion_caret-icon-rotated_TqiIK {\n  -webkit-transition-duration: 0.2s;\n  -moz-transition-duration: 0.2s;\n  -o-transition-duration: 0.2s;\n  transition-duration: 0.2s;\n  -webkit-transition-property: -webkit-transform;\n  -moz-transition-property: -moz-transform;\n  -o-transition-property: -o-transform;\n  transition-property: transform;\n  -webkit-transform: rotate(90deg);\n  -ms-transform: rotate(90deg);\n  transform: rotate(90deg);\n}\n.SingleAccordion_title_2OzMh {\n  display: inline;\n  padding: 0px;\n  margin: 0px;\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "SingleAccordion_no-text-selection_3Sjh2",
	"noTextSelection": "SingleAccordion_no-text-selection_3Sjh2",
	"header": "SingleAccordion_header_2KnRG",
	"header-title": "SingleAccordion_header-title_lzyb_",
	"headerTitle": "SingleAccordion_header-title_lzyb_",
	"caret-icon": "SingleAccordion_caret-icon_1wLkP",
	"caretIcon": "SingleAccordion_caret-icon_1wLkP",
	"caret-icon-rotated": "SingleAccordion_caret-icon-rotated_TqiIK",
	"caretIconRotated": "SingleAccordion_caret-icon-rotated_TqiIK",
	"title": "SingleAccordion_title_2OzMh"
};