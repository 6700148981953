import RequestStatus from "./RequestStatus";
import LockInfo from "./LockInfo";
import IOperationInfo from "./IOperationInfo";
import IBusinessError from "@Toolkit/CommonWeb/Model/IBusinessError";

export default class LockAcquirerOperationInfo implements IOperationInfo {

    public get requestStatus(): RequestStatus { return RequestStatus.Success; }
    public get hasTechnicalError(): boolean { return false; }
    public get businessError(): IBusinessError { return null; }

    constructor(
        public readonly isPersisted: boolean,
        public readonly lockInfo?: LockInfo,
        public readonly operationName?: string,
        public readonly operationId?: string
    ) {}
}