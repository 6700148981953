exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FloatingPanel_panel_1AWP2 {\n  position: fixed;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  z-index: 1010;\n  transition: all 0.3s ease-out;\n  border-bottom: 4px solid rgba(0, 0, 0, 0.6);\n  border-right: 4px solid rgba(0, 0, 0, 0.6);\n  border-left: 4px solid rgba(0, 0, 0, 0.6);\n  border-top-left-radius: 10px;\n  border-top-right-radius: 10px;\n}\n.FloatingPanel_panel_1AWP2 .FloatingPanel_header_1OITP {\n  background-color: rgba(0, 0, 0, 0.6);\n  color: #ffffff;\n  flex: 0 0 auto;\n  padding: 8px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n.FloatingPanel_panel_1AWP2 .FloatingPanel_header_1OITP button {\n  background: none;\n  border: none;\n  color: #ffffff;\n  margin-right: 4px;\n  width: 28px;\n  height: 23px;\n  cursor: pointer;\n}\n.FloatingPanel_panel_1AWP2 .FloatingPanel_header_1OITP button:hover {\n  background-color: rgba(255, 255, 255, 0.4);\n}\n.FloatingPanel_panel_1AWP2.FloatingPanel_maximized_2RGOT {\n  border-bottom: 10px solid rgba(0, 0, 0, 0.8);\n  border-right: 10px solid rgba(0, 0, 0, 0.8);\n  border-left: 10px solid rgba(0, 0, 0, 0.8);\n}\n.FloatingPanel_panel_1AWP2.FloatingPanel_maximized_2RGOT .FloatingPanel_header_1OITP {\n  background-color: rgba(0, 0, 0, 0.8);\n}\n.FloatingPanel_panel_1AWP2 .FloatingPanel_body_1ogtX {\n  flex: 1;\n  background-color: white;\n}\n", ""]);

// exports
exports.locals = {
	"panel": "FloatingPanel_panel_1AWP2",
	"header": "FloatingPanel_header_1OITP",
	"maximized": "FloatingPanel_maximized_2RGOT",
	"body": "FloatingPanel_body_1ogtX"
};