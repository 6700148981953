import React, { useCallback } from "react";
import * as Ui from "@CommonControls";
import OrganizationUnitTreeNode from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnitTreeNode";

interface IActionsViewProps {
    node: OrganizationUnitTreeNode;
    addingNew: boolean;

    onDeleteAsync: (node: OrganizationUnitTreeNode) => Promise<void>;
    onAddChildAsync: (node: OrganizationUnitTreeNode) => Promise<void>;
}


const ActionsView: React.FC<IActionsViewProps> = props => {

    const addChildHandlerAsync = useCallback(async () => {
        await props.onAddChildAsync(props.node);
    }, [props.onAddChildAsync, props.node]);

    const deleteHandler = useCallback(async () => {
        await props.onDeleteAsync(props.node);
    }, [props.onDeleteAsync, props.node]);

    const canBeDeleted = props.node.parentId;

    if (props.node.definitionId && props.node.definitionId.value === "new") {
        return null;
    }

    const isNewNode = props.node.organizationUnitId && props.node.organizationUnitId.value === "new";

    return (
        <Ui.Flex xsJustify={"end"}>
            <Ui.Flex.Item xs={6}>
                {canBeDeleted && !isNewNode && (
                    <Ui.Button
                        iconName="trash"
                        visualStyle="negative-standard"
                        stopPropagation={{ leftClick: true }}
                        size="compact"
                        onClickAsync={deleteHandler}
                        automationId={`${props.node.code}_DeleteButton`}
                    />
                )}
            </Ui.Flex.Item>
            <Ui.Flex.Item xs={6}>
                {!props.node.isLeaf && !isNewNode && (
                    <Ui.Button
                        iconName="plus"
                        stopPropagation={{ leftClick: true }}
                        size="compact"
                        onClickAsync={addChildHandlerAsync}
                        automationId={`${props.node.code}_AddButton`}
                    />
                )}
            </Ui.Flex.Item>

        </Ui.Flex>
    );
};

export default ActionsView;