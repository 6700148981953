import State from "@Toolkit/ReactClient/Common/StateManaging";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import SchedulingServiceFilterType from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/SchedulingServiceFilterType.g";

export default class SchedulingServiceFilter {

    @State.observable.ref public schedulingServiceId: SchedulingServiceId = null;
    @State.observable.ref public practitionerId: PractitionerId = null;
    @State.observable.ref public pointOfCareId: PointOfCareId = null;
    @State.observable public practitionerFilterType: SchedulingServiceFilterType = SchedulingServiceFilterType.Mandatory;
    @State.observable public pointOfCareFilterType: SchedulingServiceFilterType = SchedulingServiceFilterType.Mandatory;
    
    @State.action.bound
    public setSchedulingServiceId(schedulingServiceId: SchedulingServiceId) {
        this.schedulingServiceId = schedulingServiceId;
    }

    @State.action.bound
    public setPractitionerId(practitionerId: PractitionerId) {
        this.practitionerId = practitionerId;
    }

    @State.action.bound
    public setPractitionerFilterType(practitionerFilterType: SchedulingServiceFilterType) {
        this.practitionerFilterType = practitionerFilterType;
    }

    @State.action.bound
    public setPointOfCareId(pointOfCareId: PointOfCareId) {
        this.pointOfCareId = pointOfCareId;
    }

    @State.action.bound
    public setPointOfCareFilterType(pointOfCareFilterType: SchedulingServiceFilterType) {
        this.pointOfCareFilterType = pointOfCareFilterType;
    }
}
