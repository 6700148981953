import VaccineId from "@Primitives/VaccineId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import VaccineAssignmentBase from "./VaccineAssignmentBase";

export default class ReferencedVaccineAssignment extends VaccineAssignmentBase  {
    @State.observable.ref public vaccineId: VaccineId = null;

    constructor(vaccineId: VaccineId) {
        super();
        this.vaccineId = vaccineId;
    }
}