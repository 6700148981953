import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import { createNestedRoutes } from "@Toolkit/ReactClient/Routing/CreateNestedRoutes";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import IOutpatientTreatmentRouteParams from "@HisPlatform/Application/Routes/IOutpatientTreatmentRouteParams";
import { IStandaloneDocumentListRouteParams } from "@HisPlatform/Components/Pages/OutpatientTreatment/IStandaloneDocumentRouteParams";
import { IStandaloneServiceRequestRouteParams } from "@HisPlatform/Components/Panels/ServiceRequestPanel/Routing/Standalone/StandaloneServiceRequestRouteParams";
import { IStandaloneTextBlockListRouteParams } from "./StandaloneTreatmentPages/TextBlockListPage/IStandaloneTextBlockListRouteParams";
import IFormRouteParams from "./IFormRouteParams";


const OutpatientTreatmentRoutes = createNestedRoutes(ApplicationRoutes.careActivity, {
    baseData: new RouteDefinition<IOutpatientTreatmentRouteParams>("/baseData"),
    discharge: new RouteDefinition<IOutpatientTreatmentRouteParams>("/discharge"),
    performedServiceList: new RouteDefinition<IOutpatientTreatmentRouteParams>("/performedServiceList"),
    documentList: new RouteDefinition<IStandaloneDocumentListRouteParams>("/documentList/:selectedDocumentId?"),
    servicesRequests: new RouteDefinition<IStandaloneServiceRequestRouteParams>("/serviceRequests/:filter/:mode/:selectedId?/:useCase?"),
    diagnosisList: new RouteDefinition<IOutpatientTreatmentRouteParams>("/diagnosisList"),
    textBlocks: new RouteDefinition<IStandaloneTextBlockListRouteParams>("/textBlocks/:textBlockTypeId?"),
    form: new RouteDefinition<IFormRouteParams>("/forms/:mode?/:selectedId?/:useCase?"),
    ratFormCareActivityBased: new RouteDefinition<IFormRouteParams>("/forms/:selectedId?/:useCase?"),
});

export default OutpatientTreatmentRoutes;
