import Di from "@Di";
import ImmunizationStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/ImmunizationStore";
import ImmunizationHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/ImmunizationHistoryItem";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import CustomTargetDiseaseAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/CustomTargetDiseaseAssignment";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import ReferencedVaccineAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/ReferencedVaccineAssignment";
import VaccineDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/VaccineDetail";
import ImmunizationId from "@Primitives/ImmunizationId.g";
import moment from "moment";

@Di.injectable()
export default class ImmunizationFactory {

    public createNewStore() {
        const store = new ImmunizationStore(true);
        store.id = new ImmunizationId("new");
        store.vaccineAssignment = new ReferencedVaccineAssignment(null);
        store.historyItems.push(this.createNewHistoryItem());
        store.currentItemIndex = 0;

        return store;
    }

    private createNewHistoryItem() {
        const historyItem = new ImmunizationHistoryItem();

        historyItem.isNew = true;
        historyItem.versionNumber = 1;
        historyItem.index = 0;
        historyItem.createdAt = moment(moment.now());
        historyItem.immunizationDate = LocalDate.today();
        historyItem.targetDiseaseAssignment = new CustomTargetDiseaseAssignment("");
        historyItem.historyItemDetail = new MedicalConditionHistoryItemDetail();
        historyItem.vaccineDetail = new VaccineDetail("", "");

        return historyItem;
    }
}
