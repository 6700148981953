import IDocumentFragment from "./IDocumentFragment";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDocumentEditorController from "@CommonControls/DocumentEditor/IDocumentEditor";

export interface IDocumentContentStore {
    readonly forEditor: IDocumentEditorContentStore;

    setContent(content: string): void;
    clearContent(): void;
    getContent(): string;
    getContentAsync(): Promise<string>;
    getContentAsTextAsync(): Promise<string>;
    getContentAsDocumentFragmentAsync(): Promise<IDocumentFragment>;
    getSelectedContentAsTextAsync(): Promise<string>;
    getTokenContentAsTextAsync(id: string): Promise<string>;
    getSelectedContentAsDocumentFragmentAsync(): Promise<IDocumentFragment>;
    getTokenContentAsDocumentFragmentAsync(id: string): Promise<IDocumentFragment>;
    isTextSelectedAsync(): Promise<boolean>;
    appendTextAsync(text: string): Promise<void>;
    appendFragmentAsync(fragment: IDocumentFragment): Promise<void>;
    appendTokenAsync(id: string, displayName: string): Promise<void>;
    updateTokenDisplayNameAsync(id: string, displayName: string): Promise<void>;
    getTokensAsync(): Promise<string[]>;
    removeTokenAsync(id: string): Promise<void>;
    selectTokenAsync(id: string): Promise<void>;
}

export interface IDocumentEditorContentStore extends IDocumentContentStore {
    attach(documentEditor: IDocumentEditorController, isAlreadyLoaded: boolean): void;
    detach(refreshContent: boolean): void;
    editorLoaded(): void;
}

export default class DocumentContentStore implements IDocumentEditorContentStore {

    private controller: IDocumentEditorController = null;
    private isEditorLoaded = false;
    private editorLoadedPromiseResolver: () => void = null;
    private content: string = null;

    private constructor() { }

    public static create(): IDocumentContentStore {
        return new DocumentContentStore();
    }

    public get forEditor(): IDocumentEditorContentStore {
        return this;
    }

    public attach(controller: IDocumentEditorController, isAlreadyLoaded: boolean) {

        if (!!this.controller) {
            throw new Error("Editor is already attached to this DocumentContentStore.");
        }

        this.controller = controller;

        if (isAlreadyLoaded) {
            this.editorLoaded();
        }
    }

    @State.bound
    public editorLoaded() {
        setTimeout(() => {
            this.isEditorLoaded = true;
            this.writeContentToEditor();
            this.editorLoadedPromiseResolver?.();
        }, 150);
    }

    @State.bound
    public detach(refreshContent: boolean) {
        if (refreshContent) {
            this.readContentFromEditor();
        }

        this.isEditorLoaded = false;
        this.controller = null;
    }

    public setContent(content: string) {
        this.content = content;
        this.writeContentToEditor();
    }

    public clearContent() {
        this.content = null;
        this.writeContentToEditor();
    }

    public getContent() {
        this.readContentFromEditor();
        return this.content;
    }

    public async getContentAsync(): Promise<string> {
        await this.waitForEditorAsync();
        return this.getContent();
    }

    public async getContentAsTextAsync(): Promise<string> {
        await this.waitForEditorAsync();
        return await this.controller.getContentAsTextAsync();
    }

    public async getTokenContentAsTextAsync(id: string): Promise<string> {
        await this.waitForEditorAsync();
        return this.controller.getTokenContentAsText(id);
    }

    public async getContentAsDocumentFragmentAsync(): Promise<IDocumentFragment> {
        await this.waitForEditorAsync();
        return this.controller.getContentAsDocumentFragment();
    }

    public async getTokenContentAsDocumentFragmentAsync(id: string): Promise<IDocumentFragment> {
        await this.waitForEditorAsync();
        return this.controller.getTokenContentAsDocumentFragment(id);
    }

    public async getSelectedContentAsTextAsync(): Promise<string> {
        await this.waitForEditorAsync();
        return this.controller.getSelectedContentAsText();
    }

    public async getSelectedContentAsDocumentFragmentAsync(): Promise<IDocumentFragment> {
        await this.waitForEditorAsync();
        return this.controller.getSelectedContentAsDocumentFragment();
    }

    public async isTextSelectedAsync(): Promise<boolean> {
        await this.waitForEditorAsync();
        return this.controller.isTextSelected();
    }

    public async appendTextAsync(text: string): Promise<void> {
        await this.waitForEditorAsync();
        this.controller.appendText(text);
    }

    public async appendFragmentAsync(fragment: IDocumentFragment): Promise<void> {
        await this.waitForEditorAsync();
        this.controller.appendFragment(fragment);
    }

    public async appendTokenAsync(id: string, displayName: string): Promise<void> {
        await this.waitForEditorAsync();
        this.controller.appendToken(id, displayName);
    }

    public async updateTokenDisplayNameAsync(id: string, displayName: string): Promise<void> {
        await this.waitForEditorAsync();
        this.controller.updateTokenDisplayName(id, displayName);
    }

    public async getTokensAsync(): Promise<string[]> {
        await this.waitForEditorAsync();
        return this.controller.getTokens();
    }

    public async removeTokenAsync(id: string): Promise<void> {
        await this.waitForEditorAsync();
        this.controller.removeToken(id);
    }

    public async selectTokenAsync(id: string): Promise<void> {
        await this.waitForEditorAsync();
        this.controller.selectToken(id);
    }

    private waitForEditorAsync() {
        if (this.isEditorLoaded) {
            return Promise.resolve();
        }

        return new Promise((resolve: (value: void) => void, reject: (reason: any) => void) => {
            const timeout = setTimeout(() => {
                if (!this.isEditorLoaded) {
                    reject(new Error("DocumentContentStore operation timed out."));
                }
            }, 60000);

            if (this.isEditorLoaded) {
                clearTimeout(timeout);
                resolve();
            } else {

                this.editorLoadedPromiseResolver = () => {
                    clearTimeout(timeout);
                    resolve();
                };
            }
        });
    }

    @State.bound
    private readContentFromEditor() {
        if (!!this.controller && this.isEditorLoaded) {
            this.content = this.controller.getContent();
        }
    }

    @State.bound
    private writeContentToEditor() {
        if (!!this.controller && this.isEditorLoaded) {
            if (this.content === null) {
                this.controller.clearContent();
                this.readContentFromEditor();
            } else {
                this.controller.setContent(this.content);
            }
        }
    }
}