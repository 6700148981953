import React, { useCallback } from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DocumentManagementReferenceDataStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentManagementReferenceDataStore";
import DocumentTypeSelectBox from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Controls/DocumentTypeSelectBox/DocumentTypeSelectBox";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import { commonMenuMaxWidth } from "@CommonControls/SelectBox/SelectBox";

interface IDocumentTypeColumnDependencies {
    referenceDataStore: DocumentManagementReferenceDataStore;
}

interface IDocumentTypeColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IDocumentTypeColumnDependencies;
}


const DocumentTypeColumn: React.SFC<IDocumentTypeColumnProps> = (props: IDocumentTypeColumnProps) => {

    const valueRenderer = useCallback((value: DocumentTypeId) => {
        const item = isNullOrUndefined(value) ? null : props._dependencies.referenceDataStore.documentTypeMap.get(value);

        if (isNullOrUndefined(item)) {
            return null;
        }

        return item.name;
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<DocumentTypeId>) => {
        return <DocumentTypeSelectBox {...filterProps} maxMenuWidth={commonMenuMaxWidth} hoverOnlyIndicatorIcons />;
    }, []);

    return (
        <DataGridColumn
            width={50}
            {...props}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
        />
    );
};

const filterValueSerializer: IDataGridFilterValueSerializer<DocumentTypeId> = {
    deserialize: (value: string) => {
        if (!value) {
            return null;
        }

        return new DocumentTypeId(value);
    },
    serialize: (value: DocumentTypeId) => {
        return value.value;
    }
};

export default connect(
    DocumentTypeColumn,
    new DependencyAdapter<IDocumentTypeColumnProps, IDocumentTypeColumnDependencies>(c => ({
        referenceDataStore: c.resolve("DocumentManagementReferenceDataStore"),
    }))
);