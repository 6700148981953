import { IModalContext } from "./ModalContext";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class ModalStore {
    @State.observable public isOpen = false;

    constructor(
        private readonly onOpen: () => void,
        private readonly onClose: () => void,
    ) { }

    @State.action.bound
    public open() {
        this.isOpen = true;
        this.onOpen();
    }

    @State.action.bound
    public close() {
        this.isOpen = false;
        this.onClose();
    }

    public context: IModalContext = {
        close: () => this.close()
    };
}