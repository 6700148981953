import React from "react";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IMedicationInfoModalParams } from "./MedicationInfoModalParams";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import MedicationInfoPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/MedicationInfoPanel/MedicationInfoPanel";

interface IMedicationInfoModalProps extends IModalComponentParams, IMedicationInfoModalParams {
}

@State.observer
export default class MedicationInfoModal extends React.Component<IMedicationInfoModalProps> {

    public render() {
        return (
            <Ui.Modal
                title={this.props.medication.name}
                isOpen
                closeButton
                size="fullscreen"
                onClose={this.close}>
                <Ui.Modal.Body>
                    <MedicationInfoPanel medicationId={this.props.medication.id} />
                </Ui.Modal.Body>
            </Ui.Modal>
        );
    }

    @State.bound
    private close() {
        this.props.onClose(null);
    }
}