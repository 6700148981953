import FormFieldDataBase from "./FormFieldDataBase";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";

export default class ReferencedEntityFormFieldData extends FormFieldDataBase {
    public isArray: boolean = false;
    @State.observable.ref public value: number = null;
    @State.observable.ref public filters: IObservableArray<FilterBase> = State.createObservableShallowArray([]);

    constructor(fieldName: string, isReadonly: boolean, isVisible: boolean, filters: IObservableArray<FilterBase>, value: number) {
        super(fieldName, isReadonly, isVisible);

        this.value = value;
        if (filters) {
            this.filters = filters;
        }
    }
}