import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";

export function getGetActiveServiceRequestDefinitionVersionsControllerDto(validOn: LocalDate): Proxy.GetActiveServiceRequestDefinitionVersionsControllerDto {
    return new Proxy.GetActiveServiceRequestDefinitionVersionsControllerDto({
        validOn: validOn
    });
}

export function mapGetServiceRequestDefinitionVersionsBySelectorsControllerDto(selectors: Array<IEntityVersionSelector<ServiceRequestDefinitionId>>): Proxy.GetServiceRequestDefinitionVersionsBySelectorsControllerDto {
    const selectorDtos = {
        selectors: selectors.map(item => {
            return new Proxy.EntityVersionSelectorOfServiceRequestDefinitionId(
                {
                    entityId: item.id,
                    validOn: item.validOn || LocalDate.today()
                } as Proxy.IEntityVersionSelectorOfServiceRequestDefinitionId
            );
        })
    } as Proxy.IGetServiceRequestDefinitionVersionsBySelectorsControllerDto;

    return new Proxy.GetServiceRequestDefinitionVersionsBySelectorsControllerDto(selectorDtos);
}