import State from "@Toolkit/ReactClient/Common/StateManaging";
import moment from "moment";
import UserId from "@Primitives/UserId.g";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";
import PerformanceStatementCareActivityStatusType from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementCareActivityStatusType.g";
import PerformanceStatementCareActivityStatusReason from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementCareActivityStatusReason.g";

export default class PerformanceStatementCareActivityStatus {

    @State.observable.ref public type: PerformanceStatementCareActivityStatusType;
    @State.observable.ref public validAt: YearMonth;
    public modifiedAt: moment.Moment;
    public modifiedBy: UserId;
    public reason: PerformanceStatementCareActivityStatusReason;

    @State.action.bound 
    public setType(type: PerformanceStatementCareActivityStatusType) {
        this.type = type;
    }

    @State.action.bound 
    public setValidAt(validAt: YearMonth) {
        this.validAt = validAt;
    }
}