import React from "react";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import ComplexSearchSingleSelect from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchSingleSelect";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import { IComplexSearchItemGroup } from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchPanel";
import ContextAwareModal from "@HisPlatformControls/ContextAwareModal";
import ComplexSearchMultiSelect from "./ComplexSearchMultiSelect";
import EntityCollectionStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/Personalization/EntityCollectionStore";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import StaticWebAppResources from "@StaticResources";
import Tab from "@CommonControls/Tab";
import TabPane from "@CommonControls/TabPane";
import Styles from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchPanelView.less";
import ComplexSearchFilterStore from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchFilterStore";
import { DataGridLoadType, IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import SearchAndNewItemPanel from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/SearchAndNewItemPanel";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import IColumnDescriptor from "./IColumnDescriptor";

interface IComplexSearchPanelView<TRecord> {
    dataSource: IPagedItems<TRecord>;
    filterStore: ComplexSearchFilterStore;
    groupDataSource: IPagedItems<EntityCollectionStore>;
    isMultiSelect: boolean;

    // singleSelect
    singleSelect: (row: any) => void;

    // multiSelect
    selectedItems: IObservableArray<TRecord>;
    onMultiSelectSave: () => void;
    getEntitiesByIds: (id: IStringEntityId[]) => TRecord[];

    complexSearchTitle: string;
    complexSearchOpen: boolean;
    onComplexSearchClose: () => void;
    createNewItemAsync: (code: string, name: string) => Promise<void>;
    createNewItemButtonText: string;

    selectedGroup: IComplexSearchItemGroup;
    relevantGroups: IComplexSearchItemGroup[];
    setSelectedGroup: (value: string) => void;
    codeGetter: string;
    nameGetter: string;
    getNameValueString: (value: any) => string;
    customNameRender: (value: any, row: TRecord) => React.ReactNode;
    paging: IPagingState;
    ordering: IOrderingState;
    onChangeAsync: (type: DataGridLoadType, paging: IPagingState, ordering: IOrderingState | IOrderingState[], filter: any, columns: IDataGridColumnProps[]) => Promise<void>;
    isLoading: boolean;
    selectableItemsSearchPlaceHolder: string;
    selectedItemsSearchPlaceHolder: string;

    showFavoritesColumn: boolean;
    isFavorite: (value: TRecord) => boolean;
    setFavorite: (value: TRecord) => void;

    // create new item
    showCreateNewItem: boolean;
    setShowCreateNewItem: (value: boolean) => void;
    createNewItemCodePlaceHolder: string;
    createNewItemNamePlaceHolder: string;
    createNewItemCodePropertyIdentifier: string;
    createNewItemNamePropertyIdentifier: string;
    createNewItemValidationEntityName: string;
    onValidateAllAsync: (code: string, name: string) => Promise<IClientValidationResult[]>;
    validationResults: IClientValidationResult[];

    automationId: string;
    columnDescriptors?: IColumnDescriptor[];
    newItemPermissionIdentifier?: string;
}

@State.observer
export default class ComplexSearchPanelView<TRecord> extends React.Component<IComplexSearchPanelView<TRecord>> {

    @State.bound
    private renderGroupNameWithIcon(value: IComplexSearchItemGroup) {
        return (
            <>
                {value.icon && <Ui.Icon iconName={value.icon} size="large" style={{ marginRight: "4px", top: "-1px" }}
                    visualStyle="secondary" />}
                {value.localizedName}
            </>
        );
    }

    @State.bound
    private renderFilterBar() {
        if (this.props.relevantGroups?.length || this.props.createNewItemAsync) {
            return (
                <div className={Styles.flexContainer}>
                    {this.props.relevantGroups?.length &&
                        <div className={this.props.createNewItemAsync ? Styles.groupsContainerWithNewItem : Styles.groupsContainer}>
                            <Tab
                                noContent
                                activeIndex={this.props.selectedGroup.name}
                                onSelectedIndexChange={this.props.setSelectedGroup}
                                className={Styles.tabPaneContainer}
                                visualStyle="pageBox"
                            >
                                {
                                    this.props.relevantGroups.map((item, key) =>
                                        <TabPane
                                            name={item.name}
                                            key={key}
                                            title={this.renderGroupNameWithIcon(item)}
                                            className={Styles.tabPane}
                                            automationId={item.name+"_tab"}
                                        />
                                    )
                                }
                            </Tab>
                        </div>
                    }
                    {this.props.createNewItemAsync &&
                        <div className={Styles.newItemContainer}>
                            <Ui.CheckBox value={this.props.showCreateNewItem}
                                onChange={this.props.setShowCreateNewItem}
                                align="right"
                                displayMode="toggle-button"
                                permissionCheckOperationNames={this.props.newItemPermissionIdentifier}
                                permissionDeniedStyle="disabled"
                                toggleButtonProps={
                                    {
                                        iconName: "plus",
                                        text: this.props.createNewItemButtonText,
                                        iconVisualStyle: "secondary",
                                        automationId: "newItemButton"
                                    }
                                }
                                style={{ marginTop: "3px", paddingRight: "12px" }}
                                automationId="showCreateNewItemCheckBox"
                            />
                        </div>
                    }
                </div>
            );
        }

        return (<></>);
    }

    @State.bound
    private renderSearchPanel() {
        if (this.props.showCreateNewItem) {
            return (<></>);
        }

        return this.props.isMultiSelect
            ? (
                <ComplexSearchMultiSelect<TRecord>
                    dataSource={this.props.dataSource}
                    selectedItems={this.props.selectedItems}
                    codeGetter={this.props.codeGetter}
                    nameGetter={this.props.nameGetter}
                    complexSearchRenderNameCell={this.props.customNameRender || this.props.getNameValueString}
                    showFavoritesColumn={this.props.showFavoritesColumn}
                    isFavorite={this.props.isFavorite}
                    setFavorite={this.props.setFavorite}
                    displayType={this.props.selectedGroup?.name === "Group" ? "entityGroup" : "entity"}
                    groupDataSource={this.props.groupDataSource}
                    getEntitiesByIds={this.props.getEntitiesByIds}
                    paging={this.props.paging}
                    onChangeAsync={this.props.onChangeAsync}
                    isLoading={this.props.isLoading}
                    groupName={this.props.selectedGroup?.name}
                />
            )
            : (
                <ComplexSearchSingleSelect<TRecord>
                    dataSource={this.props.dataSource}
                    filterStore={this.props.filterStore}
                    onRowClick={this.props.singleSelect}
                    automationId={this.props.automationId}
                    codeGetter={this.props.codeGetter}
                    nameGetter={this.props.nameGetter}
                    complexSearchRenderNameCell={this.props.customNameRender || this.props.getNameValueString}
                    showFavoritesColumn={this.props.showFavoritesColumn}
                    isFavorite={this.props.isFavorite}
                    setFavorite={this.props.setFavorite}
                    groupName={this.props.selectedGroup?.name}
                    paging={this.props.paging}
                    onChangeAsync={this.props.onChangeAsync}
                    ordering={this.props.ordering}
                    isLoading={this.props.isLoading}
                    onComplexSearchClose={this.props.onComplexSearchClose}
                    columnDescriptors={this.props.columnDescriptors}
                />
            );
    }

    @State.bound
    private renderModalBody() {
        return (
            <Ui.ToolbarLayout
                topToolbarHeight="auto"
                topToolbarClassName={Styles.toolbar}
                topToolbar={(
                    <>
                        {this.renderFilterBar()}
                        <SearchAndNewItemPanel filterStore={this.props.filterStore}
                            isMultiSelect={this.props.isMultiSelect}
                            mode={this.props.showCreateNewItem ? "newItem" : "filter"}
                            createNewItemAsync={this.props.createNewItemAsync}
                            createNewItemCodePlaceHolder={this.props.createNewItemCodePlaceHolder}
                            createNewItemNamePlaceHolder={this.props.createNewItemNamePlaceHolder}
                            createNewItemCodePropertyIdentifier={this.props.createNewItemCodePropertyIdentifier}
                            createNewItemNamePropertyIdentifier={this.props.createNewItemNamePropertyIdentifier}
                            createNewItemValidationEntityName={this.props.createNewItemValidationEntityName}
                            onValidateAllAsync={this.props.onValidateAllAsync}
                            validationResults={this.props.validationResults}
                            selectableItemsSearchPlaceHolder={this.props.selectableItemsSearchPlaceHolder}
                            selectedItemsSearchPlaceHolder={this.props.selectedItemsSearchPlaceHolder}
                        />
                    </>
                )}
                body={this.renderSearchPanel()}
            />
        );
    }

    public render() {
        return (
            <ContextAwareModal
                title={this.props.complexSearchTitle}
                isOpen={this.props.complexSearchOpen}
                onClose={this.props.onComplexSearchClose}
                closeOnOutsideClick
                automationId={this.props.automationId + "__modal"}
                size={this.props.isMultiSelect ? "normal" : "compact"}
                fixedHeight={this.props.relevantGroups && this.props.relevantGroups.length ? "705px" : "660px"}
                topMost
            >
                <Ui.Modal.Body noGap>
                    {this.renderModalBody()}
                </Ui.Modal.Body>
                {this.props.isMultiSelect && <Ui.Modal.Footer
                    left={
                        <Ui.Button onClick={this.props.onComplexSearchClose}
                            visualStyle="link"
                            text={StaticWebAppResources.Common.Button.Cancel}
                            automationId="cancelButton" />}
                    right={
                        <Ui.Button
                            onClick={this.props.onMultiSelectSave}
                            visualStyle="primary"
                            text={StaticWebAppResources.Common.Button.Ok}
                            automationId="addButton" />}
                />}
            </ContextAwareModal>);
    }
}
