import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import Di from "@Di";
import TokenApiAdapter from "@HisPlatform/BoundedContexts/TokenBasedDataGathering/ApplicationLogic/ApiAdapter/Token/TokenApiAdapter";
import IToken from "@HisPlatform/BoundedContexts/TokenBasedDataGathering/ApplicationLogic/Model/Token/IToken";
import Guid from "@Toolkit/CommonWeb/Guid";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import FormLogicToken from "@Toolkit/FormEngine/Model/FormLogicToken";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IFormLogicTokenEditorDialogParams, IFormLogicTokenEditorDialogResult } from "./FormLogicTokenEditorDialogParams";
import FormLogicTokenStore from "./FormLogicTokenStore";

@Di.injectable()
export default class FormLogicTokenEditorDialogStore extends PanelStoreBase<IModalComponentParams<IFormLogicTokenEditorDialogResult> & IFormLogicTokenEditorDialogParams> {
    @State.observable.ref public formLogicTokensStore = State.createObservableShallowArray<FormLogicTokenStore>([]);
    @State.observable.ref public tokens: Array<ISelectBoxItem<IToken>>;

    constructor(
        @Di.inject("TokenApiAdapter") private tokenApiAdapter: TokenApiAdapter
    ) {
        super();
    }

    @State.action.bound
    public addNewItem(item: FormLogicTokenStore) {
        this.formLogicTokensStore.push(item);
    }

    public createNewItem() {
        return new FormLogicTokenStore(
            Guid.newGuid(),
            null,
            null
        );
    }

    @State.action.bound
    public removeValue(item: FormLogicTokenStore) {
        this.formLogicTokensStore.remove(item);
    }

    public readonly loadAsync = this.async(async () => {
        const tokens = await this.tokenApiAdapter.getAllTokensAsync();
        this.props.formLogicTokens.forEach((flt) => {
            const token = tokens.value.find(t => t.name === flt.tokenName);

            if (token) {
                State.runInAction(() => {
                    this.formLogicTokensStore.push(new FormLogicTokenStore(Guid.newGuid(), token, flt.settings));
                });
            }
        });

        State.runInAction(() => {
            this.tokens = tokens.value.map(t => {
                return {
                    text: t.description === "None" ? t.name : t.description,
                    value: t
                } as ISelectBoxItem<IToken>;
            });
        });
    });

    @State.action.bound
    public save() {
        this.props.onClose({
            formLogicTokens: this.formLogicTokensStore
                .filter(flt => flt.token?.name === "DynamicFormDataElement" ? !isNullOrUndefined(flt?.settings) : !isNullOrUndefined(flt.token))
                .map(flt => new FormLogicToken(flt.token.name, flt.settings)),
        });
    }

}