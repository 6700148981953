import React from "react";
import { IDataGridFooterProps } from "@CommonControls/DataGrid/IDataGridProps";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import Styles from "./DataGrid.less";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import DataGridCompactFooter from "@CommonControls/DataGrid/DataGridCompactFooter";

interface IDataGridDefaultFooterDependencies {
    toolkitLocalizationService: IToolkitLocalizationService;
}

type IDataGridDefaultFooterProps = IDataGridFooterProps & { _dependencies?: IDataGridDefaultFooterDependencies };


const DataGridDefaultFooter: React.FC<IDataGridDefaultFooterProps> = props => {

    if (!!props.isCompact) {
        return <DataGridCompactFooter {...props} />;
    }

    return (
        <tr>
            <td colSpan={props.colSpan} className={Styles.defaultFooter}>
                <Ui.Flex xsJustify={props.hasBackButton ? "between" : "end"} spacing="none" innerSpacing="none" outerSpacing="none" verticalSpacing="none" style={{ alignItems: "baseline" }}>
                    {props.hasBackButton && <Ui.Flex.Item>
                        <Ui.Button automationId="dataGridFooterBackButton" size="compact" text={props.backButtonText || props._dependencies.toolkitLocalizationService.staticResources.button.back} visualStyle="link" onClick={props.onBack} />
                    </Ui.Flex.Item>}
                    <Ui.Flex.Item>
                        <Ui.Flex spacing="none" innerSpacing="none" outerSpacing="none" verticalSpacing="none">
                            <Ui.Flex.Item className={Styles.dataStats} automationId="totalRowCount">
                                {formatString(props._dependencies.toolkitLocalizationService.staticResources.dataGrid.numberOfRows, props.totalRowCount)}
                            </Ui.Flex.Item>
                            {props.totalRowCount > 5 && (
                                <Ui.Flex.Item>
                                    {props.pager}
                                </Ui.Flex.Item>
                            )}
                        </Ui.Flex>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </td>
        </tr>
    );
};

export default connect(
    DataGridDefaultFooter,
    new DependencyAdapter<IDataGridDefaultFooterProps, IDataGridDefaultFooterDependencies>(c => ({
        toolkitLocalizationService: c.resolve("IToolkitLocalizationService"),
    }))
);