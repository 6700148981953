import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IPropertyListItem from "@CommonControls/PropertyList/IPropertyListItem";
import IExternalPrescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IExternalPrescription";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import EhiPatientIdentifierTypeId from "@Primitives/EhiPatientIdentifierTypeId.g";
import _ from "@HisPlatform/Common/Lodash";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import IEhiMedicationFormula from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IEhiMedicationFormula";
import IEhiSingleMedication from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IEhiSingleMedication";
import Dosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/Dosage";
import FrequencyBasedDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/FrequencyBasedDosage";
import DosageInterval from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/DosageInterval";
import ScheduledDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/ScheduledDosage";
import TextualDosage from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/TextualDosage";
import {formatStringWithObjectParams} from "@Toolkit/CommonWeb/Formatters";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";
import ExternalPrescriptionDetailViewBase from "./ExternalPrescriptionDetailViewBase";
import IName from "@Toolkit/CommonWeb/Model/IName";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import MedicationInfoModalParams from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/MedicationInfoModal/MedicationInfoModalParams";
import MedicationsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicationsApiAdapter";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import formatCodeName from "@HisPlatformControls/CodeNameFormatter/CodeNameFormatter";

interface IExternalPrescriptionDetailViewProps {
    _dependencies?: IExternalPrescriptionDetailViewDependencies;
    externalPrescription: IExternalPrescription;
}

interface IExternalPrescriptionDetailViewDependencies {
    localizationService?: ILocalizationService;
    practitionerApiAdapter?: PractitionerApiAdapter;
    ehiCareApiAdapter?: EhiCareApiAdapter;
    modalService?: IModalService;
    notificationService?: INotificationService;
    medicationsApiAdapter?: MedicationsApiAdapter;
}
const DEFAULT_PROPERTY_LIST_HEADER_WIDTH = "40%";

@State.observer
class ExternalPrescriptionDetailView extends ExternalPrescriptionDetailViewBase<IExternalPrescriptionDetailViewProps> {
    
    private readonly showProductInfoCallbackFactory = (tttIdentifier: string) => () => this.showProductDetailsAsync(tttIdentifier);

    @State.computed
    protected get prescription(): IExternalPrescription {
        return this.props.externalPrescription;
    }

    protected get localizationService(): ILocalizationService {
        return this.props._dependencies.localizationService;
    }

    protected get practitionerApiAdapter(): PractitionerApiAdapter {
        return this.props._dependencies.practitionerApiAdapter;
    }

    protected get ehiCareApiAdapter(): EhiCareApiAdapter {
        return this.props._dependencies.ehiCareApiAdapter;
    }

    protected get practitionerName(): IName {
        return {
            familyName: this.prescription.practitioner.lastName,
            givenName: this.prescription.practitioner.firstName,
            prefix: this.resources.Common.PractitionerPrefix
        } as IName;
    }

    @State.computed
    private get product() {
        return this.prescription.products[0];
    }

    private get localResources() {
        return this.resources.RegularPrescription;
    }

    @State.bound
    private renderPatientProperties() {
        return [{
            name: this.resources.Common.PatientProperties.Properties.Name,
            value: this.prescription.patient.name
        }, {
            name: this.resources.Common.PatientProperties.Properties.IdentifierType,
            value: formatCodeName(this.prescription.extensionData.EhiPatientIdentifierType, this.localizationService.localizeEnumId(new EhiPatientIdentifierTypeId(this.prescription.extensionData.EhiPatientIdentifierType)).Name)
        }, {
            name: this.resources.Common.PatientProperties.Properties.Identifier,
            value: this.prescription.patient.identifier
        }, {
            name: this.resources.Common.PatientProperties.Properties.BirthDate,
            value: this.localizationService.localizeDate(this.prescription.patient.birthDate)
        }, {
            name: this.resources.Common.PatientProperties.Properties.Address,
            value: this.prescription.patient.address
        }] as IPropertyListItem[];
    }

    @State.bound
    private renderOrganizationProperties() {
        return [{
            name: this.resources.Common.OrganizationProperties.Properties.Practitioner,
            value: this.practitioner
        }, {
            name: this.resources.Common.OrganizationProperties.Properties.Organization,
            value: this.organization
        }, {
            name: this.resources.Common.OrganizationProperties.Properties.OrganizationUnit,
            value: this.organizationUnit
        }] as IPropertyListItem[];
    }

    @State.bound
    private renderPrescriptionProperties() {
        return [{
                name: this.resources.Common.PrescriptionProperties.Properties.Status,
                value: this.localizationService.localizeEnumId(this.prescription.status).Name
            }, {
                name: this.resources.Common.PrescriptionProperties.Properties.Condition,
                value: formatCodeName(this.prescription.condition.code, this.prescription.condition.name)
            }, {
                name: this.resources.Common.PrescriptionProperties.Properties.SubsidyClaimType,
                value: formatCodeName(this.product.ehiMedicationSubsidyClaimTypeId.value, this.localizationService.localizeEnumId(this.product.ehiMedicationSubsidyClaimTypeId).Name)
            }, {
                name: this.localResources.PrescriptionProperties.Properties.IsRecurrent,
                value: this.localizationService.localizeBoolean(this.prescription.isRecurrent)
            }, {
                name: this.localResources.EventProperties.Properties.CareIdentifier,
                value: this.prescription.careIdentifier
            }, {
                name: this.localResources.EventProperties.Properties.PractitionerRecommendationDoctor,
                value: this.practitionerRecommendationDoctor
            }, {
                name: this.resources.Common.PrescriptionProperties.Properties.PrescribedOn,
                value: this.localizationService.localizeDate(this.prescription.prescribedOn)
            }, {
                name: this.resources.Common.PrescriptionProperties.Properties.Validity,
                value: `${this.localizationService.localizeDate(this.prescription.validity.from)} - ${this.localizationService.localizeDate(this.prescription.validity.to)}`,
            }, {
                name: this.resources.Common.PrescriptionProperties.Properties.Type,
                value: this.localizationService.localizeEnumId(this.product.ehiMedicationTypeId).Name
            }, {
                name: this.resources.Common.PrescriptionProperties.Properties.Category,
                value: formatCodeName(this.prescription.ehiPrescriptionCategoryId.value, this.localizationService.localizeEnumId(this.prescription.ehiPrescriptionCategoryId).Name)
            }, {
                name: this.resources.Common.PrescriptionProperties.Properties.Type,
                value: formatCodeName(this.prescription.ehiPrescriptionTypeId.value, this.localizationService.localizeEnumId(this.prescription.ehiPrescriptionTypeId).Name)
            }, {
                name: this.resources.Common.PrescriptionProperties.Properties.Identifier,
                value: `${this.prescription.identifier}`
            },
    ] as IPropertyListItem[];
    }

    private renderProductName(product: IEhiSingleMedication) {
        return (
            <Ui.Flex xsVerticalAlign="middle">
            <Ui.Flex.Item>{product.medicationName}</Ui.Flex.Item>
                <Ui.Flex.Item grow={true} horizontalContentAlignment="right">
                    <Ui.Button
                        iconName="info"
                        onClick={this.showProductInfoCallbackFactory(product.productId)}
                        size="compact"
                        automationId="MedicationEquipmentPrescriptDetails_ShowProductInfoButton" />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    @State.bound
    private renderMedicationProperties() {
        
        const properties: IPropertyListItem[] = [];
        if (["4", "5"].includes(this.product.ehiMedicationTypeId.value)) {
            const medicationFormula = this.product as IEhiMedicationFormula;
            properties.push({
                name: this.localResources.MedicationProperties.Properties.Medication,
                value: medicationFormula.formulaName
            }, {
                name: this.localResources.MedicationProperties.Properties.IsSubstitutable,
                value: this.localizationService.localizeBoolean(this.product.isSubstitutable)
            }, {
                name: this.localResources.MedicationProperties.Properties.TextAmount,
                value: this.product.textAmount
            }, {
                name: this.localResources.MedicationProperties.Properties.Content,
                value: medicationFormula.content
            }, {
                name: this.localResources.MedicationProperties.Properties.Preparation,
                value: medicationFormula.preparation
            }, {
                name: this.localResources.UsageRecommendationProperties.Properties.DosageType,
                value: this.localizationService.localizeEnumId(this.product.ehiDosageTypeId).Name
            }, {
                name: this.localResources.UsageRecommendationProperties.Properties.Dosage,
                value: this.renderDosage(medicationFormula.dosageDto)
            }, {
                name: this.localResources.UsageRecommendationProperties.Properties.DaysSupplied,
                value: medicationFormula.daysSupplied
            }, {
                name: this.localResources.UsageRecommendationProperties.Properties.PractitionerInstruction,
                value: medicationFormula.practitionerInstruction
            }, {
                name: this.localResources.UsageRecommendationProperties.Properties.Overdose,
                value: medicationFormula.isOverdose
            }, {
                name: this.localResources.UsageRecommendationProperties.Properties.Instructions,
                value: medicationFormula.notes
            });
        } else {
            const singleMedication = this.product as IEhiSingleMedication;
            
            properties.push({
                name: this.localResources.MedicationProperties.Properties.Medication,
                value: this.renderProductName(singleMedication)
            }, {
                name: this.localResources.MedicationProperties.Properties.IsSubstitutable,
                value: this.localizationService.localizeBoolean(singleMedication.isSubstitutable)
            }, {
                name: this.localResources.MedicationProperties.Properties.Substance,
                value: formatCodeName(singleMedication.substanceCode, singleMedication.substanceName)
            }, {
                name: this.localResources.MedicationProperties.Properties.Efficacy,
                value: singleMedication.efficacy
            }, {
                name: this.localResources.MedicationProperties.Properties.Amount,
                value: singleMedication.amount
            }, {
                name: this.localResources.MedicationProperties.Properties.TextAmount,
                value: singleMedication.textAmount
            }, {
                name: this.localResources.UsageRecommendationProperties.Properties.DosageType,
                value: this.localizationService.localizeEnumId(this.product.ehiDosageTypeId).Name
            }, {
                name: this.localResources.UsageRecommendationProperties.Properties.Dosage,
                value: this.renderDosage(singleMedication.dosageDto)
            }, {
                name: this.localResources.UsageRecommendationProperties.Properties.DaysSupplied,
                value: singleMedication.daysSupplied
            }, {
                name: this.localResources.UsageRecommendationProperties.Properties.PractitionerInstruction,
                value: singleMedication.instruction
            }, {                
                name: this.localResources.UsageRecommendationProperties.Properties.Overdose,
                value: singleMedication.isOverdose                
            }, {
                name: this.localResources.UsageRecommendationProperties.Properties.Instructions,
                value: singleMedication.notes
            });     
        }
    
        return properties;   
    }

    @State.bound
    private renderDosage(dosage: Dosage) {
        if (!dosage) {
            return "";
        }
        if (dosage instanceof FrequencyBasedDosage) {
            const intervalMultiplierText = dosage.intervalMultiplier > 1 ? dosage.intervalMultiplier + " " : "";
            return `${intervalMultiplierText}${this.localizationService.localizeEnum(DosageInterval[dosage.interval], "DosageInterval")?.Name} ${dosage.frequency}x${dosage.amount}`;
        } else if (dosage instanceof ScheduledDosage) {
            return formatStringWithObjectParams(this.localResources.UsageRecommendationProperties.Properties.ScheduledDosage, {
                morningAmount: dosage.morningAmount.toString(),
                noonAmount: dosage.noonAmount.toString(),
                eveningAmount: dosage.eveningAmount.toString(),
                beforeSleepAmount: dosage.beforeSleepAmount.toString()
            });
        } else if (dosage instanceof TextualDosage) {
            return dosage.dosageInstructions;
        } else {
            throw new Error("Dosage descendant not recognized.");
        }
    }
    
    public render() {
        return !!this.prescription && (
            <>
                <Ui.Flex style={{paddingLeft: "20px"}}>
                    <Ui.Flex.Item xs={12}> 
                        <Ui.GroupBox title={this.localResources.MedicationProperties.Title}>
                            <Ui.PropertyList  hidePropertyIfNoValue hideIfEmpty nameWidth={DEFAULT_PROPERTY_LIST_HEADER_WIDTH} hasColonAfterNames properties={this.renderMedicationProperties()}/>
                        </Ui.GroupBox>
                    </Ui.Flex.Item>                    
                    <Ui.Flex.Item xs={12}>
                        <Ui.GroupBox title={this.localResources.PrescriptionProperties.Title}>
                            <Ui.PropertyList hidePropertyIfNoValue hideIfEmpty nameWidth={DEFAULT_PROPERTY_LIST_HEADER_WIDTH} hasColonAfterNames properties={this.renderPrescriptionProperties()}/>
                        </Ui.GroupBox>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={12}>
                        <Ui.GroupBox title={this.resources.Common.PatientProperties.Title}>
                            <Ui.PropertyList hidePropertyIfNoValue hideIfEmpty nameWidth={DEFAULT_PROPERTY_LIST_HEADER_WIDTH} hasColonAfterNames properties={this.renderPatientProperties()}/>
                        </Ui.GroupBox>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={12}>
                        <Ui.GroupBox title={this.resources.Common.OrganizationProperties.Title}>
                            <Ui.PropertyList hidePropertyIfNoValue hideIfEmpty nameWidth={DEFAULT_PROPERTY_LIST_HEADER_WIDTH} hasColonAfterNames properties={this.renderOrganizationProperties()}/>
                        </Ui.GroupBox>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </>
        );
    }

    @State.action
    private async showProductDetailsAsync(tttIdentifier: string) {
        const identifier = new Identifier(new IdentifierSystemId("TTT"), tttIdentifier);
        const medicationVersions =  await this.props._dependencies.medicationsApiAdapter.getMedicationVersionsByIdentifiersAsync([identifier], this.prescription.prescribedOn);
        const firstMedicationVersion = medicationVersions.value.entries().next().value[1][0];
        if (!firstMedicationVersion) {
            this.props._dependencies.notificationService.error(this.resources.MedicationEquipment.Errors.ProductNotFound);
            return Promise.resolve();
        }

        return this.props._dependencies.modalService.showModalAsync(new MedicationInfoModalParams(firstMedicationVersion));
    }
}

export default connect(
    ExternalPrescriptionDetailView,
    new DependencyAdapter<IExternalPrescriptionDetailViewProps, IExternalPrescriptionDetailViewDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
        practitionerApiAdapter: c.resolve("PractitionerApiAdapter"),
        modalService: c.resolve("IModalService"),
        medicationsApiAdapter: c.resolve("MedicationsApiAdapter"),
        notificationService: c.resolve("INotificationService"),
        ehiCareApiAdapter: c.resolve("EhiCareApiAdapter")
    }))
);