import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import * as Styles from "./ValidationResultBody.less";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import CareDataType from "./CareDataType";
import _ from "@HisPlatform/Common/Lodash";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";

interface IValidationResultBodyDependencies {
    localizationService: ILocalizationService;
}

interface IValidationResultBodyProps {
    _dependencies?: IValidationResultBodyDependencies;
    rawJsonValue: any;
}


@State.observer
class ValidationResultBody extends React.Component<IValidationResultBodyProps> {
    @State.observable.ref private typesWithErrors: CareDataType[] = [];

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: IValidationResultBodyProps) {
        if (this.props.rawJsonValue !== prevProps.rawJsonValue) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    private loadAsync() {
        const validationResult = this.props.rawJsonValue;
        
        const careActivityBaseDataErrors = this.getErrorsFor(validationResult, "CareActivityBaseData");
        const diagnosisErrors = this.getErrorsFor(validationResult, "DiagnosisList");
        const performedServiceErrors = this.getErrorsFor(validationResult, "PerformedServiceList");
        const dischargeDataErrors = this.getErrorsFor(validationResult, "CareActivityDischargeData");
        const documentTypeErrors = this.getErrorsFor(validationResult, "CareActivity").filter(i => i.propertyPath === "DocumentTypes");
        const textBlockErrors = this.getErrorsFor(validationResult, "CareActivity").filter(i => i.propertyPath === "TextBlock");

        const typesWithErrors = [];

        if (careActivityBaseDataErrors.length > 0) {
            typesWithErrors.push(CareDataType.ClinicalData);
        }
        if (diagnosisErrors.length > 0) {
            typesWithErrors.push(CareDataType.Diagnoses);
        }
        if (performedServiceErrors.length > 0) {
            typesWithErrors.push(CareDataType.PerformedServices);
        }
        if (dischargeDataErrors.length > 0) {
            typesWithErrors.push(CareDataType.DischargeData);
        }
        if (documentTypeErrors.length > 0) {
            typesWithErrors.push(CareDataType.DocumentTypes);
        }
        if (textBlockErrors.length > 0) {
            typesWithErrors.push(CareDataType.CareDocuments);
        }

        this.setTypesWithErrors(typesWithErrors);
        return Promise.resolve();
    }

    @State.action.bound
    private setTypesWithErrors(typesWithErrors: CareDataType[]) {
        this.typesWithErrors = typesWithErrors;
    }

    private getErrorsFor(validationResults: IClientValidationResult[], entity: "CareActivityBaseData" | "DiagnosisList" | "PerformedServiceList" | "CareActivityDischargeData" | "CareActivity") {
        return _.flatten(validationResults.filter(i => i.entityName === entity).map(i => i.problems)).filter(i => i.severity === "error");
    }

    @State.bound
    private getMessage(): string {
        const messages = this.typesWithErrors.map(i => {
            const localized = this.props._dependencies.localizationService.localizeEnum(CareDataType[i], "CareDataType");
            return localized.Name;
        });

        return messages.join(", ");
    }

    public render() {
        return this.typesWithErrors.length > 0 && this.getMessage() && (
            <div className={Styles.rowBody}>
                {this.getMessage()}
            </div>
        );
    }
}

export default connect(
    ValidationResultBody,
    new DependencyAdapter<IValidationResultBodyProps, IValidationResultBodyDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService")
    }))
);
