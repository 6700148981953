import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicationStatementSubjectAssignmentBase from "./MedicationStatementSubjectAssignmentBase";
import InternationalNonproprietaryNameId from "@Primitives/InternationalNonproprietaryNameId.g";

export default class ReferencedInternationalNonproprietaryNameAssignment extends MedicationStatementSubjectAssignmentBase  {
    @State.observable.ref public internationalNonproprietaryNameId: InternationalNonproprietaryNameId = null;

    constructor(internationalNonproprietaryNameId: InternationalNonproprietaryNameId) {
        super();
        this.internationalNonproprietaryNameId = internationalNonproprietaryNameId;
    }
}