exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CareActivityStateIcon_main_sBug2 {\n  display: inline-block;\n  width: 22px;\n  height: 22px;\n  padding: 0;\n  line-height: 22px;\n}\n.CareActivityStateIcon_upper-index_1X1s_ {\n  position: relative;\n  width: 16px;\n  height: 16px;\n  top: -12px;\n  left: -4px;\n  line-height: 16px;\n}\n.CareActivityStateIcon_state-admitted_1jJQt {\n  background-color: #ddc00d;\n  color: white;\n}\n.CareActivityStateIcon_state-waiting_IIQ1f {\n  background-color: #fe8443;\n  color: white;\n}\n.CareActivityStateIcon_state-admission-deleted_9b-IO {\n  background-color: #e8e8e8;\n  color: #4F5B72;\n}\n.CareActivityStateIcon_state-deleted_xPjZf {\n  background-color: #8695a7;\n  color: white;\n}\n.CareActivityStateIcon_state-did-not-attend_20Q6t {\n  background-color: #B3B3B3;\n  color: white;\n}\n.CareActivityStateIcon_state-in-progress_3d_vw {\n  background-color: #21c313;\n  color: white;\n}\n.CareActivityStateIcon_state-suspended_2O3DW {\n  background-color: #ce5374;\n  color: white;\n}\n.CareActivityStateIcon_state-discharged__QO-w {\n  background-color: #82d4ed;\n  color: white;\n}\n.CareActivityStateIcon_state-closed_1Ot-0 {\n  background-color: #0082aa;\n  color: white;\n}\n", ""]);

// exports
exports.locals = {
	"main": "CareActivityStateIcon_main_sBug2",
	"upper-index": "CareActivityStateIcon_upper-index_1X1s_",
	"upperIndex": "CareActivityStateIcon_upper-index_1X1s_",
	"state-admitted": "CareActivityStateIcon_state-admitted_1jJQt",
	"stateAdmitted": "CareActivityStateIcon_state-admitted_1jJQt",
	"state-waiting": "CareActivityStateIcon_state-waiting_IIQ1f",
	"stateWaiting": "CareActivityStateIcon_state-waiting_IIQ1f",
	"state-admission-deleted": "CareActivityStateIcon_state-admission-deleted_9b-IO",
	"stateAdmissionDeleted": "CareActivityStateIcon_state-admission-deleted_9b-IO",
	"state-deleted": "CareActivityStateIcon_state-deleted_xPjZf",
	"stateDeleted": "CareActivityStateIcon_state-deleted_xPjZf",
	"state-did-not-attend": "CareActivityStateIcon_state-did-not-attend_20Q6t",
	"stateDidNotAttend": "CareActivityStateIcon_state-did-not-attend_20Q6t",
	"state-in-progress": "CareActivityStateIcon_state-in-progress_3d_vw",
	"stateInProgress": "CareActivityStateIcon_state-in-progress_3d_vw",
	"state-suspended": "CareActivityStateIcon_state-suspended_2O3DW",
	"stateSuspended": "CareActivityStateIcon_state-suspended_2O3DW",
	"state-discharged": "CareActivityStateIcon_state-discharged__QO-w",
	"stateDischarged": "CareActivityStateIcon_state-discharged__QO-w",
	"state-closed": "CareActivityStateIcon_state-closed_1Ot-0",
	"stateClosed": "CareActivityStateIcon_state-closed_1Ot-0"
};