import React from "react";
import { ITokenSettingsPanelPropsBase } from "@PluginInterface/BoundedContexts/DocumentManagement/TokenSettingsPanelRegistry/ITokenSettingsPanelRegistry";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Proxy from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Proxy.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import IFormEngineApiAdapter from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import { SelectBox } from "@CommonControls";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import AggregateRootSchema from "@Toolkit/FormEngine/Model/Schema/AggregateRootSchema";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";
import ReferencedAggregateFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedAggregateFormDataElement";
import CompositeFormDataElement from "@Toolkit/FormEngine/Model/Schema/CompositeFormDataElement";
import ISelectBoxGroup from "@CommonControls/SelectBox/ISelectBoxGroup";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";

interface IAggregateTokenPanelDependencies {
    formEngineApiAdapter: IFormEngineApiAdapter;
    localizationService: ILocalizationService;
}

interface IProviderParameterSettings {
    AggregateName: string;
    FormDataElementName: string;
}

interface IAggregateTokenPanelProps extends ITokenSettingsPanelPropsBase<Proxy.DynamicTokenValueFormatterSettingsDto, IProviderParameterSettings> {
    _dependencies?: IAggregateTokenPanelDependencies;
}

interface IAggregateItem {
    aggregateName: string,
    name: string,
    resourceKey: string,
    element: FormDataElementBase
}

/** @screen */
@State.observer
class AggregateTokenPanel extends React.Component<IAggregateTokenPanelProps> {

    @State.observable.ref private selectBoxOptions: Array<ISelectBoxGroup<IAggregateItem>> = [];

    public componentDidMount() {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }

    @State.computed private get selectedElement() {
        if (isNullOrUndefined(this.props.providerParameterSettings)) {
            return null;
        }

        return this.selectBoxOptions?.flatMap(o => o.options).find(i =>
            i.value.aggregateName == this.props.providerParameterSettings.AggregateName &&
            i.value.name == this.props.providerParameterSettings.FormDataElementName)?.value;
    }

    private async initializeAsync() {
        const response = await this.props._dependencies.formEngineApiAdapter.getAllAggregateRootSchemasAsync();

        State.runInAction(() => this.selectBoxOptions = this.getSelectBoxOptions(response.value));
    }

    private getSelectBoxOptions(schemas: AggregateRootSchema[]) {
        const groups: Array<ISelectBoxGroup<IAggregateItem>> = [];

        for (const schema of schemas) {
            const items: Array<ISelectBoxItem<IAggregateItem>> = [];

            this.getItems(schema.aggregateName, "", schema.formDataElements, schemas, items);

            const group = {
                label: this.props._dependencies.localizationService.localizeWebAppResource("Templating.Aggregate." + schema.aggregateName),
                options: items
            } as ISelectBoxGroup<IAggregateItem>;

            groups.push(group);
        }

        return groups;
    }

    private getItems(
        aggregateName: string,
        prefix: string,
        formDataElements: FormDataElementBase[],
        schemas: AggregateRootSchema[],
        items: Array<ISelectBoxItem<IAggregateItem>>) {

        for (const element of formDataElements) {
            if (element instanceof ReferencedAggregateFormDataElement) {
                const referencedSchema = schemas.find(i => i.aggregateName === element.aggregateName);
                this.getItems(aggregateName, prefix === "" ? element.name : prefix + "." + element.name, referencedSchema.formDataElements, schemas, items);
            } else if (element instanceof CompositeFormDataElement) {
                this.getItems(aggregateName, prefix === "" ? element.name : prefix + "." + element.name, element.formDataElements, schemas, items);
            } else {
                const resourceKey = prefix === ""
                ? "Templating.AggregateProperty." + aggregateName + "." + element.name
                : "Templating.AggregateProperty." + aggregateName + "." + prefix + "." + element.name;

                items.push({
                    text: this.props._dependencies.localizationService.localizeWebAppResource(resourceKey),
                    value: {
                        aggregateName: aggregateName,
                        name: prefix === "" ? element.name : prefix + "." + element.name,
                        element: element,
                        resourceKey: resourceKey
                    }
                });
            }
        }
    }

    @State.action.bound
    private selectElement(value: IAggregateItem) {
        this.props.onSettingsChange(new Proxy.DynamicTokenValueFormatterSettingsDto({
            formatterType: value.element.formDataElementType,
            dateTimeFormatString: this.props.tokenFormatterSettings.dateTimeFormatString,
        }), {
            AggregateName: value.aggregateName,
            FormDataElementName: value.name
        });

        this.props.onUpdateTokenDisplayName?.(this.props._dependencies.localizationService.localizeWebAppResource(value.resourceKey));
    }

    @State.bound
    private setFormatString(value?: string) {

        if (!this.selectedElement) {
            return;
        }

        this.props.onSettingsChange(new Proxy.DynamicTokenValueFormatterSettingsDto({
            formatterType: this.selectedElement.element.formDataElementType,
            dateTimeFormatString: value
        }), {
            AggregateName: this.selectedElement.aggregateName,
            FormDataElementName: this.selectedElement.name
        });
    }

    public render() {
        return (
            <>
                <SelectBox
                    automationId="AggregateElement_SelectBox"
                    items={this.selectBoxOptions}
                    value={this.selectedElement}
                    onChange={this.selectElement}
                />
                {this.selectedElement?.element.formDataElementType === "DateTime" &&
                    <Ui.TextBox
                        label={StaticDocumentManagementResources.TemplateManagement.TokenFormatterSettingsPanel.DateFormat}
                        value={this.props.tokenFormatterSettings?.dateTimeFormatString ?? ""}
                        placeholder="yyyy-MM-dd HH:mm:ss"
                        onChange={this.setFormatString}
                        automationId="dateTimeFormatTextBox" />}
            </>
        );
    }
}

export default connect(
    AggregateTokenPanel,
    new DependencyAdapter<IAggregateTokenPanelProps, IAggregateTokenPanelDependencies>(c => ({
        formEngineApiAdapter: c.resolve<IFormEngineApiAdapter>("IFormEngineApiAdapter"),
        localizationService: c.resolve<ILocalizationService>("ILocalizationService")
    }))
);