//#region imports
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import { isNullOrUndefined, arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ServiceRequestPanelView from "./ServiceRequestPanelView";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import ServiceRequestDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestDefinition";
import ServiceProviderProfileApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/ServiceProviderProfileApiAdapter";
import { IReferralDataPageBoxProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestPanel/ReferralDataPageBox";
import { ITargetDataPageBoxProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestPanel/TargetDataPageBox";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import MedicalServicePriority from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/MedicalServices/Enum/MedicalServicePriority.g";
import moment, { Moment } from "moment";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import _ from "@HisPlatform/Common/Lodash";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import SuspectedDiagnosis from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/SuspectedDiagnosis";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import CareActivityBaseDataApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityBaseData/CareActivityBaseDataApiAdapter";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import CareActivityIsAlreadyAServiceRequestExecutingCareActivityError from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/CareActivityIsAlreadyAServiceRequestExecutingCareActivityError";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import DiagnosisListApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/DiagnosisList/DiagnosisListApiAdapter";
import DiagnosisRoleId from "@Primitives/DiagnosisRoleId.g";
import DiagnosisStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisStore";
import { IPanelWithButtonPortalProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/IPanelWithButtonPortalProps";
import PanelController from "@Toolkit/ReactClient/Components/PanelController";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import IServiceRequestDataService from "@HisPlatform/BoundedContexts/Care/Services/Definition/ServiceRequestManagement/IServiceRequestDataService";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import EntityLockState from "@Toolkit/CommonWeb/ApiAdapter/EntityLockState";
import BusinessErrorHandler from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import CareActivityId from "@Primitives/CareActivityId.g";
import CareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CareActivityStore";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PatientId from "@Primitives/PatientId.g";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import ServiceProviderProfile from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/ServiceProviderProfile";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import ServiceRequestAppointmentModalParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestAppointmentModal/ServiceRequestAppointmentModalParams";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import SchedulingApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingApiAdapter";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import ServiceRequestWithAppointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ServiceRequestWithAppointment";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import ServiceRequestSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ServiceRequestSubjectStore";
import ReferralStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/ReferralStore";
import IServiceRequestReferralExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestReferralExtensionPointProps";
import ExtensionController from "@HisPlatform/Components/HisPlatformExtensionPoint/ExtensionController";
import IServiceRequestClinicalQuestionExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestClinicalQuestionExtensionPointProps";
import PropertyGroup from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyGroup";
import DynamicPropertiesApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/DynamicProperties/DynamicPropertiesApiAdapter";
import FormExtensionRegistryAdapter from "@PluginInterface/FormExtension/FormExtensionRegistryAdapter";
import IFormExtension from "@PluginInterface/FormExtension/IFormExtension";
import IServiceRequestHeaderExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestHeaderExtensionPointProps";
import IClientSessionService from "@HisPlatform/Services/Definition/ClientSession/IClientSessionService";
import RequestedServiceId from "@Primitives/RequestedServiceId.g";
import MomentHelper from "@Toolkit/CommonWeb/MomentHelper";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import CareActivityBaseData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareActivityBaseData";
import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";
import PatientAdministrativeData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PatientRegister/Patient/PatientAdministrativeData";
import RequestedServiceAction from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceAction.g";
import ServiceRequestAction from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestAction.g";
import ServiceRequestState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestState.g";
import AppointmentStatus from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentStatus.g";
import ProblemSeverity from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ProblemSeverity";

//#endregion
interface IServiceRequestPanelDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    serviceProviderProfileApiAdapter: ServiceProviderProfileApiAdapter;
    careActivityBaseDataApiAdapter: CareActivityBaseDataApiAdapter;
    dialogService: IDialogService;
    notificationService: INotificationService;
    localizationService: ILocalizationService;
    diagnosisListApiAdapter: DiagnosisListApiAdapter;
    serviceRequestDataService: IServiceRequestDataService;
    lockingApiAdapter: LockingApiAdapter;
    careActivityApiAdapter: CareActivityApiAdapter2;
    schedulingApiAdapter: SchedulingApiAdapter;
    structureApiAdapter: StructureApiAdapter;
    dynamicPropertiesApiAdapter: DynamicPropertiesApiAdapter;
    clientSessionService: IClientSessionService;
    dateTimeFormatProvider: IDateTimeFormatProvider;
}

interface IServiceRequestPanelProps extends IPanelWithButtonPortalProps {
    _dependencies?: IServiceRequestPanelDependencies;
    _careActivityId?: CareActivityId;
    _careActivity?: CareActivityStore;
    _careActivityBaseData?: CareActivityBaseData;
    _patientId?: PatientId;
    _patient?: PatientAdministrativeData;
    _modalService?: IModalService;
    _formExtension?: IFormExtension<ServiceRequestStore>;
    _isCareBaseDataUnauthorized?: boolean;

    serviceRequestId: ServiceRequestId;
    serviceRequestDefinitionVersionSelector: IEntityVersionSelector<ServiceRequestDefinitionId>;
    serviceRequestDefinitionDirection: ServiceRequestDirection | null;
    onRenewed: (newId: ServiceRequestId) => void;
    onNewCreated?: (newId: ServiceRequestId) => void;
    onSaved?: (newId: ServiceRequestId) => void;
    onClose: () => void;
}

/** @screen */
@State.observer
class ServiceRequestPanel extends React.Component<IServiceRequestPanelProps> {
    //#region dependencies
    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }
    private get organizationReferenceDataStore() { return this.props._dependencies.organizationReferenceDataStore; }
    private get serviceRequestDataService() { return this.props._dependencies.serviceRequestDataService; }
    private get serviceProviderProfileApiAdapter() { return this.props._dependencies.serviceProviderProfileApiAdapter; }
    private get diagnosisListApiAdapter() { return this.props._dependencies.diagnosisListApiAdapter; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get localizationService() { return this.props._dependencies.localizationService; }
    private get dialogService() { return this.props._dependencies.dialogService; }
    private get modalService() { return this.props._modalService; }
    private get lockingApiAdapter() { return this.props._dependencies.lockingApiAdapter; }
    private get careActivityApiAdapter() { return this.props._dependencies.careActivityApiAdapter; }
    private get schedulingApiAdapter() { return this.props._dependencies.schedulingApiAdapter; }
    private get structureApiAdapter() { return this.props._dependencies.structureApiAdapter; }
    private get dynamicPropertiesApiAdapter() { return this.props._dependencies.dynamicPropertiesApiAdapter; }
    private get dateTimeFormatProvider() { return this.props._dependencies.dateTimeFormatProvider; }

    //#endregion

    @State.observable.ref private serviceRequestDefinition: ServiceRequestDefinition = null;

    @State.observable.ref private medicalServiceIds: Array<IEntityVersionSelector<MedicalServiceId>> = null;

    @State.observable.ref private serviceRequestStore: ServiceRequestStore = null;
    @State.observable.ref private appointmentStore: Appointment = null;

    @State.observable.ref private availablePriorities: MedicalServicePriority[] = null;
    @State.observable.ref private priority: MedicalServicePriority = null;
    @State.observable.ref private previousPriority: MedicalServicePriority = null;
    @State.observable.ref private targetCompletionDate: moment.Moment = DateTimeService.now();
    @State.observable.ref private previousTargetCompletionDate: moment.Moment = DateTimeService.now();
    @State.observable.ref private serviceProviderProfile: ServiceProviderProfile = null;
    @State.observable private isSuspectedDiagnosisLoading: boolean = false;
    @State.observable private isLoading: boolean = false;
    @State.observable private isDeleteAllowed: boolean = false;
    @State.observable private isEditingAllowed: boolean = false;
    @State.observable private isRenewAllowed: boolean = false;
    @State.observable private isSubmitAllowed: boolean = false;
    @State.observable private textBoxHeight: string = "auto";
    private disposables: IDisposable[] = [];
    @State.observable.ref private currentCareActivity: CareActivityStore = null;
    private referralExtensionController = new ExtensionController();
    private requestingPointOfCareDynamicProperties: PropertyGroup[] = [];

    private readonly validateAllEvent = new TypedEvent();

    @State.observable.ref private tempExtensionData: any = null;

    @State.computed
    private get extensionData() {
        if (!isNullOrUndefined(this.tempExtensionData)) {
            return this.tempExtensionData;
        }
        return this.serviceRequestStore?.extensionData;
    }

    @State.action.bound
    private setServiceRequestStore(serviceRequestStore: ServiceRequestStore) {
        this.serviceRequestStore = serviceRequestStore;
    }

    @State.action.bound
    private setTempExtensionData(tempExtensionData: any) {
        this.tempExtensionData = tempExtensionData;
    }

    @State.bound
    private setTempServiceRequestExtensionData(extensionData: any) {
        this.props._dependencies.clientSessionService.setTempData("serviceRequestExtensionData", extensionData);
    }

    @State.computed
    private get canAddNewMedicalService() { return !isNullOrUndefined(this.priority) && !isNullOrUndefined(this.serviceRequestStore.targetCareLocation); }

    @State.computed
    private get isDirty() {
        return this.serviceRequestStore.isDirty() ||
            this.priority !== this.previousPriority ||
            !MomentHelper.areEquals(this.targetCompletionDate, this.previousTargetCompletionDate) ||
            this.referralExtensionController.isDirty();
    }

    @State.computed
    private get isAdministration() {
        return this.serviceRequestStore.executingCareActivityId &&
            ValueWrapper.equals(this.props._careActivityId, this.serviceRequestStore.executingCareActivityId);
    }

    @State.computed
    private get isExternalCareLocation() {
        if (isNullOrUndefined(this.props.serviceRequestDefinitionDirection)) {
            return this.serviceRequestStore.targetCareLocation instanceof ExternalCareLocation;
        } else {
            return this.props.serviceRequestDefinitionDirection === ServiceRequestDirection.InternalToExternal;
        }
    }

    @State.computed
    private get isAppointmentRequired() {
        if (isNullOrUndefined(this.serviceProviderProfile) ||
            isNullOrUndefined(this.serviceRequestStore) ||
            this.serviceRequestStore.isExternal
        ) {
            return false;
        }
        const executionCapabilities = this.serviceRequestStore.requestedServices
            .map(rs => this.serviceProviderProfile.GetMedicalServiceExecutionCapabilityByMedicalServiceId(rs.medicalServiceVersionSelector.id)
            );
        return executionCapabilities.some(e => e?.appointmentNeeded);
    }

    @State.computed
    private get isAppointmentEditingAllowed() {
        return (
            !!this.appointmentStore &&

            (this.appointmentStore.isNew ||
                this.appointmentStore.status === AppointmentStatus.Booked) &&

            this.isEditingAllowed &&
            this.serviceRequestStore.isMutable
        );
    }

    @State.computed
    private get serviceRequestAsSchedulingService() {
        const store = new ServiceRequestSubjectStore();
        store.id = this.serviceRequestStore.id;
        store.setDefinitionId(this.serviceRequestDefinition.id);
        store.setCode(this.serviceRequestDefinition.code.value);
        store.setName(this.serviceRequestDefinition.shortName);
        store.setDurationInMinutes(this.getServiceRequestDuration.get());

        return store;
    }

    @State.computed
    private get permissionCheckedOperations() {
        const res = {};

        const fakeServiceRequestStore = new ServiceRequestStore();
        fakeServiceRequestStore.id = this.props.serviceRequestId;
        fakeServiceRequestStore.rowVersion = new RowVersion(-1);
        fakeServiceRequestStore.setServiceRequestDefinitionVersion({ id: new ServiceRequestDefinitionId("1"), validOn: LocalDate.today() });
        fakeServiceRequestStore.setStarterCareActivityId(this.props._careActivityId);
        fakeServiceRequestStore.direction = this.serviceRequestStore?.direction ?? ServiceRequestDirection.InternalToInternal;

        if (this.props._careActivityId) {
            res["CreateNew"] = () => this.props._dependencies.serviceRequestDataService.checkPermissionForCreateServiceRequestAsync(fakeServiceRequestStore);

            if (this.props.serviceRequestId) {
                res["DeleteDraft"] = () => this.props._dependencies.serviceRequestDataService.checkPermissionForDeleteDraftServiceRequestAsync(this.props.serviceRequestId, this.props._careActivityId);
                res["Renew"] = () => this.props._dependencies.serviceRequestDataService.checkPermissionForRenewSubmittedServiceRequestAsync(this.props.serviceRequestId, this.props._careActivityId);
                res["Modify"] = () => this.props._dependencies.serviceRequestDataService.checkPermissionForModifyServiceRequestAsync(fakeServiceRequestStore, this.props._careActivityId);
            }
        }

        return res;
    }

    private get isServiceRequestEmpty() {
        return !this.serviceRequestDefinition?.medicalServiceIds.length;
    }

    @State.action.bound
    private initializeExtensionData() {
        this.serviceRequestStore?.setExtensionData({});
    }

    @State.computed
    private get referralExtensionPanelProps() {
        return {
            serviceRequest: this.serviceRequestStore,
            patientId: this.props._patientId,
            careActivityId: this.props._careActivityId,
            extensionController: this.referralExtensionController,
            initializeExtensionData: this.initializeExtensionData,
            externalLocationId: this.serviceRequestStore?.targetExternalLocationVersionSelector?.id,
            requestingPointOfCareDynamicProperties: this.requestingPointOfCareDynamicProperties,
            serviceRequestDefinition: this.serviceRequestDefinition,
            onExtensionDataChange: this.serviceRequestStore.setExtensionData,
            executingPointOfCareId: this.serviceRequestStore?.targetPointOfCareId
        } as IServiceRequestReferralExtensionPointProps;
    }

    @State.computed
    private get headerExtensionPanelProps() {
        return {
            extensionData: this.extensionData,
            initializeExtensionData: this.initializeExtensionData
        } as IServiceRequestHeaderExtensionPointProps;
    }

    @State.computed
    private get clinicalQuestionExtensionPanelProps() {
        const referralPointOfCare = this.getReferralPointOfCare();

        return {
            serviceRequest: this.serviceRequestStore,
            textBoxHeight: this.textBoxHeight,
            onTextBoxHeightChange: this.onTextBoxHeightChange,
            pointOfCareId: referralPointOfCare ?? this.currentCareActivity?.pointOfCareId ?? this.props._careActivity?.pointOfCareId,
            requestingPointOfCareDynamicProperties: this.requestingPointOfCareDynamicProperties,
        } as IServiceRequestClinicalQuestionExtensionPointProps;
    }

    private getServiceRequestDuration = State.promisedComputed(null, async () => {
        if (this.serviceRequestStore.targetCareLocation && !arrayIsNullOrEmpty(this.serviceRequestStore.requestedServices)) {
            const profile = await this.serviceProviderProfileApiAdapter.getServiceProviderProfileByLocationAsync(this.serviceRequestStore.targetCareLocation);

            const medicalServiceDurations = this.serviceRequestStore.requestedServices.map(s => profile.value.GetMedicalServiceExecutionCapabilityByMedicalServiceId(s.id).durationInMinutes);
            const result = medicalServiceDurations.length ?
                _.sum(medicalServiceDurations) : null;
            return result;
        }
        return null;
    });

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync, !isNullOrUndefined(this.props.serviceRequestId) ? false : this.props._isCareBaseDataUnauthorized);
    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IServiceRequestPanelProps) {
        if (
            !ValueWrapper.equals(prevProps.serviceRequestId, this.props.serviceRequestId) ||
            !EntityVersionSelector.areEquals(prevProps.serviceRequestDefinitionVersionSelector, this.props.serviceRequestDefinitionVersionSelector) ||
            prevProps.serviceRequestDefinitionDirection !== this.props.serviceRequestDefinitionDirection
        ) {
            dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
        }
    }

    public componentWillUnmount() {
        this.disposables.forEach(d => d.dispose());
    }

    @State.boundLoadingState()
    private async loadAsync(forceReleaseLock: boolean = false) {
        const serviceRequestResponse = await this.getServiceRequestAsync(forceReleaseLock);
        await this.ensureAllLoadedAsync(serviceRequestResponse);
        this.processServiceRequestResponse(serviceRequestResponse);
        await this.loadServiceProviderProfileAsync();

        if (this.serviceRequestStore.isNew) {
            const careActivityStore = await this.careActivityApiAdapter.loadByIdAsync(this.serviceRequestStore.starterCareActivityId);
            State.runInAction(() => {
                this.currentCareActivity = careActivityStore;
            });
        }

        State.runInAction(() => {
            this.tempExtensionData = this.props._dependencies.clientSessionService.getAndClearTempData("serviceRequestExtensionData");
        });

        await this.loadDynamicPropertiesAsync();

        if (this.isAppointmentRequired) {
            const appointmentResponse = await this.getAppointmentAsync();
            await this.processAppointmentResponseAsync(appointmentResponse);
        }
    }

    private async getServiceRequestAsync(forceReleaseLock: boolean) {
        let response: ServiceRequestStore;

        if (!isNullOrUndefined(this.props.serviceRequestId)) {
            if (forceReleaseLock) {
                response =
                    await this.serviceRequestDataService.getServiceRequestByIdWithForceLockAsync(
                        this.props.serviceRequestId,
                        this.props._careActivityId);
            } else {
                response = await this.serviceRequestDataService.getServiceRequestByIdAsync(
                    this.props.serviceRequestId,
                    this.props._careActivityId,
                    true,
                    false);
            }
            this.resetCompletionDate(response.expectedCompletionTime);
        } else {
            response = await this.createEmptyServiceRequestAsync(this.props.serviceRequestDefinitionVersionSelector);
        }
        return response;
    }

    private async createEmptyServiceRequestAsync(serviceRequestDefinitionVersion: IEntityVersionSelector<ServiceRequestDefinitionId>) {
        const serviceRequest = new ServiceRequestStore(true);
        serviceRequest.direction = this.props.serviceRequestDefinitionDirection;
        serviceRequest.isExternal = this.props.serviceRequestDefinitionDirection === ServiceRequestDirection.InternalToExternal;
        if (serviceRequest.isExternal) {
            serviceRequest.targetExternalLocationVersionSelector = new EntityVersionSelector(null, LocalDate.today());
        }

        if (this.props.serviceRequestDefinitionDirection === ServiceRequestDirection.ExternalToInternal) {
            serviceRequest.requesterDoctorId = this.props._careActivityBaseData.referral.referralDoctorId;
            serviceRequest.targetDoctorId = this.props._careActivityBaseData.primaryParticipant;
        } else {
            serviceRequest.requesterDoctorId = this.props._careActivityBaseData.primaryParticipant;
        }

        serviceRequest.setServiceRequestDefinitionVersion(serviceRequestDefinitionVersion);
        serviceRequest.setStarterCareActivityId(this.props._careActivityId);
        serviceRequest.setReferralCareIdentifier(this.props._careActivityBaseData.primaryCareIdentifier);
        serviceRequest.setCreatedAt(DateTimeService.now());

        await this.initializeSuspectedDiagnosisListAsync(serviceRequest);
        return serviceRequest;
    }

    @State.action
    private async initializeSuspectedDiagnosisListAsync(serviceRequest: ServiceRequestStore) {
        if (!this.props._careActivityId) {
            return;
        }

        this.isSuspectedDiagnosisLoading = true;

        const recorderDiagnosisStore = await this.diagnosisListApiAdapter.getDiagnosisListAsync(this.props._careActivityId, false);

        const diagnosisRoleOrderMap = {
            [DiagnosisRoleId.Discharge.value]: 0,
            [DiagnosisRoleId.Comorbity.value]: 1,
            [DiagnosisRoleId.Admission.value]: 2,
        };

        let observableSuspectedDiagnosisList: IObservableArray<SuspectedDiagnosis> = State.observable<SuspectedDiagnosis>([]);
        if (!isNullOrUndefined(recorderDiagnosisStore.diagnoses)) {
            const suspectedDiagnosisList = _
                .sortBy<DiagnosisStore>(recorderDiagnosisStore.diagnoses, r => diagnosisRoleOrderMap[r.use.value])
                .slice(0, 4)
                .map(i => {
                    const diagnosis = new SuspectedDiagnosis();
                    diagnosis.lateralityId = i.lateralityId;
                    diagnosis.conditionId = i.conditionVersionSelector.id;
                    return diagnosis;
                });
            observableSuspectedDiagnosisList = State.observable<SuspectedDiagnosis>(suspectedDiagnosisList);
        }

        State.runInAction(() => {
            serviceRequest.setSuspectedDiagnosisList(observableSuspectedDiagnosisList);

            if (serviceRequest.suspectedDiagnosisList.length === 0) {
                serviceRequest.suspectedDiagnosisList.push(new SuspectedDiagnosis());
            }

            this.isSuspectedDiagnosisLoading = false;
        });
    }

    private async ensureAllLoadedAsync(serviceRequest: ServiceRequestStore) {
        if (serviceRequest.isExternal) {
            if (serviceRequest.targetExternalLocationVersionSelector) {
                await this.organizationReferenceDataStore.externalLocationStore.ensureLoadedAsync([serviceRequest.targetExternalLocationVersionSelector]);
            }
        } else {
            if (serviceRequest.targetPointOfCareId) {
                await this.organizationReferenceDataStore.allPointsOfCareMap.ensureLoadedAsync([serviceRequest.targetPointOfCareId]);
            }
        }
        if (serviceRequest.referralLocation instanceof ExternalCareLocation) {
            await this.organizationReferenceDataStore.externalLocationStore.ensureLoadedAsync([serviceRequest.referralLocation.externalLocationSelector]);
        } else if (serviceRequest.referralLocation instanceof InternalCareLocation) {
            await this.organizationReferenceDataStore.allPointsOfCareMap.ensureLoadedAsync([serviceRequest.referralLocation.pointOfCareId]);
        }
        const definition = await this.careReferenceDataStore.serviceRequestDefinition.getOrLoadAsync(serviceRequest.serviceRequestDefinitionVersion);
        await this.organizationReferenceDataStore.externalLocationStore.ensureLoadedAsync(definition.externalLocations);
    }

    private async loadDynamicPropertiesAsync() {
        const referralPointOfCare = this.getReferralPointOfCare();

        const pointOfCareId = referralPointOfCare ?? this.currentCareActivity?.pointOfCareId ?? this.props._careActivity?.pointOfCareId;
        const organizationUnitId = new OrganizationUnitId(pointOfCareId.value);
        const response = await this.dynamicPropertiesApiAdapter.getEditablePropertiesOfOrganizationUnitAsync(organizationUnitId);
        State.runInAction(() => {
            this.requestingPointOfCareDynamicProperties = response.value;
        });
    }

    private getReferralPointOfCare() {
        const referralPointOfCare = this.serviceRequestStore.referralLocation instanceof InternalCareLocation
            ? this.serviceRequestStore.referralLocation?.pointOfCareId
            : null;

        return referralPointOfCare;
    }

    @State.action
    private processServiceRequestResponse(serviceRequestResponse: ServiceRequestStore, keepLock: boolean = false, withValidation: boolean = false) {

        if (serviceRequestResponse.operationInfo && serviceRequestResponse.operationInfo.businessError instanceof CareActivityIsAlreadyAServiceRequestExecutingCareActivityError) {
            this.props._dependencies.dialogService.ok(
                StaticWebAppResources.NewServiceRequestPage.CareActivityIsAlreadyAServiceRequestExecutingCareActivityError,
                StaticWebAppResources.NewServiceRequestPage.NotifyError);
            return;
        }

        if (withValidation && !serviceRequestResponse.isPersistedByOperationInfo) {
            if (serviceRequestResponse.validationResults) {
                this.serviceRequestStore.validationResults = serviceRequestResponse.validationResults;
            }
            return;
        }

        if (serviceRequestResponse.serviceRequestDefinitionVersion) {
            this.serviceRequestDefinition = this.careReferenceDataStore.serviceRequestDefinition.get(serviceRequestResponse.serviceRequestDefinitionVersion);
        }

        const lockInfo = keepLock && this.serviceRequestStore.lockInfo;
        this.setServiceRequestStore(serviceRequestResponse);
        if (lockInfo) {
            this.serviceRequestStore.lockInfo = lockInfo;
        }

        this.serviceRequestStore.takeSnapshot();

        if (serviceRequestResponse.requestedServices && serviceRequestResponse.requestedServices.length > 0) {
            this.resetPriority(serviceRequestResponse.requestedServices[0].priority);

            if (!this.isAppointmentRequired) {
                this.resetCompletionDate(serviceRequestResponse.requestedServices[0].expectedCompletionTime);
            }
        } else {
            this.resetPriority(serviceRequestResponse.medicalServicePriority);
        }

        const isModifyAllowed = this.serviceRequestStore.possibleActions && this.serviceRequestStore.possibleActions.some(j => j === ServiceRequestAction.Modify);
        if (isModifyAllowed && this.serviceRequestStore.isNew) {
            this.initializeDefaultRequesterDoctorAndExpectedCompletionTimeIfNeeded();
        }

        this.isEditingAllowed = this.serviceRequestStore.possibleActions?.some(j => j === ServiceRequestAction.Modify) || this.serviceRequestStore.isNew;
        this.isDeleteAllowed = this.serviceRequestStore.possibleActions?.some(j => j === ServiceRequestAction.Delete) ?? false;
        this.isRenewAllowed = this.serviceRequestStore.possibleActions?.some(j => j === ServiceRequestAction.Renew) ?? false;
        this.isSubmitAllowed = this.serviceRequestStore.possibleActions?.some(j => j === ServiceRequestAction.Submit) || this.serviceRequestStore.isNew;

        this.setTempExtensionData(null);
    }

    private async getAppointmentAsync() {
        const appointmentResponse = await this.schedulingApiAdapter.tryGetAppointmentBySubjectServiceRequestIdAsync(this.serviceRequestStore.id);
        return appointmentResponse;
    }

    @State.action.bound
    private async processAppointmentResponseAsync(appointmentResponse: Appointment) {
        if (!appointmentResponse.isPersistedByOperationInfo) {
            this.appointmentStore.validationResults = appointmentResponse.validationResults;
        } else if (appointmentResponse.isNew) {
            await this.setNewAppointmentStoreAsync();
        } else {
            State.runInAction(() => {
                this.appointmentStore = appointmentResponse;
            });
        }
        this.resetCompletionDate(this.appointmentStore.intervalFrom);
    }

    @State.action.bound
    private resetCompletionDate(date: Moment) {
        this.targetCompletionDate = date;
        this.previousTargetCompletionDate = date;
    }

    private resetPriority(priority: MedicalServicePriority) {
        this.previousPriority = priority;
        dispatchAsyncErrors(this.changePriorityAsync(priority), this);
    }

    @State.bound
    private async saveAsync(shouldSubmitServiceRequest: boolean = false) {
        let isPersisted = false;

        if (this.serviceRequestStore) {
            this.setPriorityAndExpectedCompletionTimeOfServices(this.serviceRequestStore.requestedServices);

            if (!this.isAppointmentRequired) {
                isPersisted = await this.saveWithoutAppointmentAsync(shouldSubmitServiceRequest);
            } else {
                isPersisted = await this.saveWithAppointmentAsync(shouldSubmitServiceRequest);
            }
        }
        return isPersisted;
    }

    @State.bound
    private async saveWithoutAppointmentAsync(shouldSubmitServiceRequest: boolean) {
        let isPersisted = false;
        let response: ServiceRequestStore;

        if (!this.serviceRequestStore.isPersistedByOperationInfo) {
            response =
                await this.serviceRequestDataService.createServiceRequestAsync(
                    this.serviceRequestStore,
                    shouldSubmitServiceRequest,
                    !shouldSubmitServiceRequest,
                    this.isServiceRequestEmpty);
            this.setTempServiceRequestExtensionData(response.extensionData);
        } else if (!this.appointmentStore?.id) {
            response =
                await this.serviceRequestDataService.modifyServiceRequestAsync(
                    this.serviceRequestStore,
                    shouldSubmitServiceRequest,
                    this.props._careActivityId,
                    this.serviceRequestStore.lockInfo.lockId,
                    false,
                    this.isServiceRequestEmpty);
        } else {
            response =
                await this.schedulingApiAdapter.modifyServiceRequestWithDeleteAppointment(
                    this.props._careActivityId,
                    this.serviceRequestStore,
                    shouldSubmitServiceRequest,
                    this.appointmentStore.id,
                    this.appointmentStore.rowVersion,
                    this.serviceRequestStore.lockInfo.lockId,
                    false
                );
        }
        this.processServiceRequestResponse(response, true, true);
        isPersisted = response.isPersistedByOperationInfo;

        if (response.hasValidationError) {
            State.runInAction(() => {
                this.serviceRequestStore.validationResults = response.validationResults;
            });
        }

        this.notificationService.showSaveResult(response.isPersistedByOperationInfo, this.serviceRequestStore.hasValidationWarning);

        if (shouldSubmitServiceRequest && this.serviceRequestStore.state === ServiceRequestState.UnderRecording) {
            this.notificationService.error(StaticCareResources.ServiceRequestManagement.Messages.SubmitFailedBecauseErrors);
        }

        return isPersisted;
    }

    @State.bound
    private async saveWithAppointmentAsync(shouldSubmitServiceRequest: boolean) {
        let isPersisted = false;
        let response: SimpleStore<ServiceRequestWithAppointment>;

        if (!this.serviceRequestStore.isPersistedByOperationInfo) {
            response =
                await this.schedulingApiAdapter.createServiceRequestWithAppointment(
                    this.serviceRequestStore,
                    shouldSubmitServiceRequest,
                    !shouldSubmitServiceRequest,
                    this.appointmentStore,
                    this.isServiceRequestEmpty
                );
            this.setTempServiceRequestExtensionData(response.value.serviceRequest.extensionData);
        } else if (this.appointmentStore.isNew) {
            response =
                await this.schedulingApiAdapter.modifyServiceRequestWithCreateAppointment(
                    this.props._careActivityId,
                    this.serviceRequestStore,
                    shouldSubmitServiceRequest,
                    this.appointmentStore,
                    this.serviceRequestStore.lockInfo?.lockId,
                    false
                );
        } else {
            response =
                await this.schedulingApiAdapter.modifyServiceRequestWithUpdateAppointment(
                    this.props._careActivityId,
                    this.serviceRequestStore,
                    shouldSubmitServiceRequest,
                    this.appointmentStore,
                    this.serviceRequestStore.lockInfo?.lockId,
                    false
                );
        }
        this.processServiceRequestResponse(response.value.serviceRequest, true, true);
        await this.processAppointmentResponseAsync(response.value.appointment);
        isPersisted = response.isPersistedByOperationInfo;

        this.notificationService.showSaveResult(response.isPersistedByOperationInfo, this.serviceRequestStore.hasValidationWarning || this.appointmentStore.hasValidationWarning);

        if (shouldSubmitServiceRequest && this.serviceRequestStore.state === ServiceRequestState.UnderRecording) {
            this.notificationService.error(StaticCareResources.ServiceRequestManagement.Messages.SubmitFailedBecauseErrors);
        }
        if (!arrayIsNullOrEmpty(response.value.problems)) {
            for (const problem of response.value.problems) {
                const message = StaticWebAppResources.NewServiceRequestPage[problem.type] ?? problem.type;
                switch (problem.severity) {
                    case ProblemSeverity.Info:
                        this.notificationService.info(message);
                        break;
                    case ProblemSeverity.Warning:
                        this.notificationService.warning(message);
                        break;
                    default:
                        this.notificationService.error(message);
                        break;
                }
            }
        }

        return isPersisted;
    }

    @State.bound
    private async saveAndEmitEventAsync(shouldSubmitServiceRequest: boolean) {
        const isNew = this.serviceRequestStore.isNew;
        const isSaved = await this.saveAsync(shouldSubmitServiceRequest);
        if (isSaved) {
            if (isNew) {
                this.props.onNewCreated?.(this.serviceRequestStore.id);
            } else {
                this.props.onSaved?.(this.serviceRequestStore.id);
            }
        }
        return isSaved;
    }

    @State.bound
    private async deleteServiceRequestAsync() {
        const dialogResult =
            await this.props._dependencies.dialogService.yesNo(
                StaticWebAppResources.Common.DialogTitle.ConfirmationTitle,
                StaticWebAppResources.NewServiceRequestPage.ServiceRequestDeleteConfirmation);

        if (dialogResult.resultCode === DialogResultCode.Yes) {
            let hasErrors = false;
            if (this.serviceRequestStore.state === ServiceRequestState.UnderRecording) {
                hasErrors = await this.deleteDraftServiceRequestAsync();
            } else {
                hasErrors = await this.executeDeleteForRequestedServicesAsync();
            }

            this.props.onSaved?.(this.serviceRequestStore.id);
            if (!hasErrors) {
                this.props.onClose?.();
            }
        }
    }

    @State.bound
    private async deleteDraftServiceRequestAsync() {
        if (!!this.appointmentStore?.id) {
            const deleteRequestAndAppointmentResponse =
                await this.schedulingApiAdapter.deleteDraftServiceRequestWithAppointmentAsync(
                    this.appointmentStore.id,
                    this.appointmentStore.rowVersion,
                    this.serviceRequestStore.id,
                    this.props._careActivityId,
                    this.serviceRequestStore.rowVersion,
                    this.serviceRequestStore.lockInfo.lockId,
                    true
                );
            this.serviceRequestStore.lockInfo = deleteRequestAndAppointmentResponse.value.serviceRequest.lockInfo; // always NULL
            this.serviceRequestStore.takeSnapshot();
        } else {
            const deleteRequestResponse =
                await this.serviceRequestDataService.deleteDraftServiceRequestAsync(
                    this.serviceRequestStore.id,
                    this.props._careActivityId,
                    this.serviceRequestStore.rowVersion,
                    this.serviceRequestStore.lockInfo.lockId,
                    true);
            this.serviceRequestStore.lockInfo = deleteRequestResponse.lockInfo; // always NULL
            this.serviceRequestStore.takeSnapshot();
        }

        this.notificationService.showSavedSuccessfully();
        return false;
    }

    @State.bound
    private async executeDeleteForRequestedServicesAsync() {
        const extensionDataArray =
            await this.props._formExtension.invokeCallbackAsync<any>("ExtendServiceRequestForDelete", this.serviceRequestStore);

        let extensionDataForDelete = {};
        for (const extensionData of extensionDataArray) {
            extensionDataForDelete = { ...extensionDataForDelete, ...extensionData };
        }

        const result = await
            this.serviceRequestDataService.executeRequestedServiceActionsAsync(
                this.props._careActivityId,
                this.serviceRequestStore.id,
                this.serviceRequestStore.rowVersion,
                this.serviceRequestStore.requestedServices?.length ? this.serviceRequestStore.requestedServices.map(i => ({
                    id: i.id,
                    action: RequestedServiceAction.DeleteForRequester
                })) : [{
                    id: new RequestedServiceId("0"),
                    action: RequestedServiceAction.DeleteForRequester
                }],
                this.serviceRequestStore.lockInfo,
                true,
                extensionDataForDelete);

        this.setServiceRequestStore(result);
        this.serviceRequestStore.lockInfo = result.lockInfo; // always NULL
        this.serviceRequestStore.takeSnapshot();

        const hasErrorsArray = await this.props._formExtension.invokeCallbackAsync<boolean>("CheckServiceRequestForErrors", this.serviceRequestStore);

        this.notificationService.showSaveResult(result.isPersistedByOperationInfo);

        if (hasErrorsArray?.length) {
            return true;
        } else {
            return !result.isPersistedByOperationInfo;
        }
    }

    private setPriorityAndExpectedCompletionTimeOfServices(services: RequestedServiceStore[]) {
        services.forEach(s => {
            s.setPriority(this.priority);
            s.setExpectedCompletionTime(this.targetCompletionDate);
        });
        if (this.isServiceRequestEmpty) {
            this.serviceRequestStore.setExpectedCompletionTime(this.targetCompletionDate);
            this.serviceRequestStore.setPriority(this.priority);
        }
    }

    @State.action.bound
    private openForEditAsync() {
        return this.loadAsync(true);
    }

    @State.bound
    public async renewServiceRequestAsync() {
        const extensionDataArray =
            await this.props._formExtension.invokeCallbackAsync<any>("ExtendServiceRequestForDelete", this.serviceRequestStore);

        let extensionDataForDelete = {};
        for (const extensionData of extensionDataArray) {
            extensionDataForDelete = { ...extensionDataForDelete, ...extensionData };
        }

        let serviceRequestStore;
        if (this.appointmentStore?.id) {
            const response = await this.schedulingApiAdapter.renewSubmittedServiceRequestWithAppointment(
                this.serviceRequestStore.id,
                this.props._careActivityId,
                this.serviceRequestStore.rowVersion,
                true,
                this.serviceRequestStore.lockInfo.lockId,
                extensionDataForDelete,
                false,
                this.appointmentStore.id,
                StaticWebAppResources.NewServiceRequestPage.AppointmentCancellationNote
            );
            serviceRequestStore = await this.serviceRequestDataService.mapServiceRequestAsync(response);
        } else {
            serviceRequestStore = await this.serviceRequestDataService.renewSubmittedServiceRequestAsync(
                this.serviceRequestStore.id,
                this.props._careActivityId,
                this.serviceRequestStore.rowVersion,
                true,
                this.serviceRequestStore.lockInfo.lockId,
                extensionDataForDelete);
        }

        this.setTempServiceRequestExtensionData(serviceRequestStore.extensionData);

        if (serviceRequestStore.operationWasSuccessful) {
            this.notificationService.info(StaticWebAppResources.NewServiceRequestPage.ServiceRequestRenew);

            this.props.onRenewed(serviceRequestStore.id);
        }
    }

    @State.bound
    private setAppointmentStore() {
        if (this.isAppointmentRequired) {
            if (!this.appointmentStore) {
                this.setNewAppointmentStore();
            }
            this.changeTargetCompletionDate(this.appointmentStore?.intervalFrom);
        } else if (!!this.appointmentStore) {
            this.deleteAppointmentStore();
            this.changeTargetCompletionDate(DateTimeService.now());
        }
    }

    @State.action.bound
    private deleteAppointmentStore() {
        this.appointmentStore = null;
    }

    @State.action.bound
    private setNewAppointmentStore() {
        dispatchAsyncErrors(this.setNewAppointmentStoreAsync(), this);
    }

    @State.action.bound
    private async setNewAppointmentStoreAsync() {
        const newAppointment = new Appointment(true);

        newAppointment.patientId = this.props._patientId;
        newAppointment.setPointOfCareId(this.serviceRequestStore.targetPointOfCareId);
        newAppointment.setSubjectService(this.serviceRequestAsSchedulingService.id);

        const newReferralData = await this.getNewAppointmentReferralDataAsync();
        newAppointment.referral = newReferralData;

        State.runInAction(() => {
            this.appointmentStore = newAppointment;
        });
    }

    @State.bound
    private async getNewAppointmentReferralDataAsync() {
        const newReferralData = new ReferralStore();

        newReferralData.setCreatedAt(LocalDate.createFromMoment(this.serviceRequestStore.createdAt));
        newReferralData.setReferralCareIdentifier(this.serviceRequestStore.referralCareIdentifier);
        newReferralData.setDoctorId(this.serviceRequestStore.requesterDoctorId);
        this.setDiagnosesForReferral(newReferralData);

        const newReferralLocation = await this.getAppointmentReferralLocationAsync();
        newReferralData.setWorkPlace(newReferralLocation);

        return newReferralData;
    }

    @State.bound
    private async getAppointmentReferralLocationAsync() {
        const organizationUnitId = new OrganizationUnitId((this.currentCareActivity?.pointOfCareId ?? this.props._careActivity.pointOfCareId).value);
        const externalLocationIds = await this.structureApiAdapter.getExternalLocationsOfOrganizationUnitsAsync([organizationUnitId], LocalDate.today());
        const referralLocationId = externalLocationIds.value.length ? externalLocationIds.value[0] : null;
        return referralLocationId;
    }

    @State.bound
    private setAppointmentReferralDoctorId() {
        if (!!this.appointmentStore?.referral) {
            this.appointmentStore.referral.setDoctorId(this.serviceRequestStore.requesterDoctorId);
        }
    }

    @State.bound
    private setAppointmentReferralDiagnoses() {
        if (!!this.appointmentStore?.referral) {
            this.setDiagnosesForReferral(this.appointmentStore.referral);
        }
    }
    private setDiagnosesForReferral(referral: ReferralStore) {
        referral.setReferralDiagnosisId1(
            this.serviceRequestStore.suspectedDiagnosisList.length ?
                this.serviceRequestStore.suspectedDiagnosisList[0].conditionId : null);
        referral.setReferralDiagnosisId2(
            this.serviceRequestStore.suspectedDiagnosisList.length && this.serviceRequestStore.suspectedDiagnosisList.length > 1 ?
                this.serviceRequestStore.suspectedDiagnosisList[1].conditionId : null);
    }

    @State.action
    private initializeDefaultRequesterDoctorAndExpectedCompletionTimeIfNeeded() {
        if (this.serviceRequestStore.requestedServices.every(i => isNullOrUndefined(i.expectedCompletionTime))) {
            this.serviceRequestStore.requestedServices.forEach(i => i.setExpectedCompletionTime(DateTimeService.now()));
            this.targetCompletionDate = this.getTargetCompletionDate();
        }
        if (isNullOrUndefined(this.serviceRequestStore.requesterDoctorId)) {
            this.serviceRequestStore.requesterDoctorId = this.getDefaultRequesterDoctor();
        }
        requestAnimationFrame(() => {
            this.validateAllEvent.emit();
        });
    }

    private getTargetCompletionDate() {
        if (!this.isAppointmentRequired) {
            return this.serviceRequestStore.requestedServices[0].expectedCompletionTime;
        } else {
            return this.appointmentStore?.intervalFrom;
        }
    }

    private getDefaultRequesterDoctor() {
        if (this.serviceRequestStore.direction === ServiceRequestDirection.ExternalToInternal) {
            return this.props._careActivityBaseData.referral.referralDoctorId;
        } else {
            return this.props._careActivityBaseData.primaryParticipant;
        }
    }

    @State.bound
    private setTargetPointOfCareId(newValue: PointOfCareId) {
        this.serviceRequestStore.setTargetPointOfCareId(newValue);
        if (isNullOrUndefined(newValue)) {
            this.setAvailablePriorities(null);
            this.changePriorityAsync(null);
        } else {
            dispatchAsyncErrors(this.loadServiceProviderProfileAsync(), this);
        }
    }

    @State.bound
    private setTargetExternalLocationVersionSelector(newValue: ExternalLocationId) {
        this.serviceRequestStore.setTargetExternalLocationVersionSelector(newValue);
        if (isNullOrUndefined(newValue) || isNullOrUndefined(newValue.value)) {
            this.setAvailablePriorities(null);
            this.changePriorityAsync(null);
        } else {
            dispatchAsyncErrors(this.loadServiceProviderProfileAsync(), this);
        }
    }

    @State.action.bound
    private async loadServiceProviderProfileAsync() {
        if (this.serviceRequestStore.targetCareLocation) {
            const result = await this.serviceProviderProfileApiAdapter.getServiceProviderProfileByLocationAsync(this.serviceRequestStore.targetCareLocation);
            if (result.operationWasSuccessful) {
                this.setServiceProviderProfile(result.value);
                this.setAvailablePriorities(result.value.getAvailablePriorities());

                if (!isNullOrUndefined(this.priority)) {
                    State.runInAction(() => {
                        const idsByPriority = result.value.getMedicalServiceIdsByPriority(this.priority);
                        const filteredIds = this.serviceRequestDefinition.medicalServiceIds.filter(i => idsByPriority.some(j => ValueWrapper.equals(i, j)));

                        this.medicalServiceIds = filteredIds.map(i => new EntityVersionSelector(i, LocalDate.today()));
                    });
                }
            }
            if (isNullOrUndefined(this.priority)) {
                if (this.availablePriorities.some(i => i === MedicalServicePriority.Normal)) {
                    this.resetPriority(MedicalServicePriority.Normal);
                }
            }
        }
    }

    @State.action
    private setServiceProviderProfile(serviceProviderProfile: ServiceProviderProfile) {
        this.serviceProviderProfile = serviceProviderProfile;
    }

    @State.action.bound
    private setAvailablePriorities(availablePriorities: MedicalServicePriority[]) {
        this.availablePriorities = availablePriorities;
    }

    @State.action.bound
    private changeTargetCompletionDate(from: moment.Moment) {
        this.targetCompletionDate = from;
    }

    @State.action.bound
    private async changePriorityAsync(priority: MedicalServicePriority) {
        this.priority = priority;
        await this.loadServiceProviderProfileAsync();
    }

    @State.bound
    private handleCareActivityIsAlreadyAServiceRequestExecutingCareActivityError() {
        this.notificationService.error(StaticWebAppResources.NewServiceRequestPage.CareActivityIsAlreadyAServiceRequestExecutingCareActivityError);
        return true;
    }

    @State.bound
    public async unloadAsync(): Promise<boolean> {
        if (this.serviceRequestStore) {
            if (this.isDirty) {
                const dialogResult = await this.dialogService.confirmIfNotSaved(StaticCareResources.Common.Dialog.ConfirmationTitle, StaticCareResources.Common.Dialog.NavigateBeforeSaveQuestion);
                if (dialogResult.resultCode === DialogResultCode.Yes) {
                    return await this.saveAsync(true);
                } else if (dialogResult.resultCode === DialogResultCode.No) {
                    if (this.serviceRequestStore.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld) {
                        await this.lockingApiAdapter.releaseLockAsync(this.serviceRequestStore.lockInfo.lockId);
                        this.serviceRequestStore.releaseLock();
                    }
                    return true;
                } else {
                    return false;
                }
            } else {
                if (this.serviceRequestStore.lockInfo?.lockState === EntityLockState.LockingRequiredAndLockHeld) {
                    await this.lockingApiAdapter.releaseLockAsync(this.serviceRequestStore.lockInfo.lockId);
                    this.serviceRequestStore.releaseLock();
                }
                return true;
            }
        }
        return true;
    }

    @State.action.bound
    private onTextBoxHeightChange(newValue: string) {
        const height1 = parseInt(this.textBoxHeight, 10);
        const height2 = parseInt(newValue, 10);

        const array: number[] = [];
        if (!Number.isNaN(height1)) {
            array.push(height1);
        }
        if (!Number.isNaN(height2)) {
            array.push(height2);
        }

        if (array.length) {
            const height = Math.max(...array);
            this.textBoxHeight = height + "px";
        }

    }

    @State.action.bound
    private async onValidateAsync() {
        if (this.isLoading) { return []; }
        this.setPriorityAndExpectedCompletionTimeOfServices(this.serviceRequestStore.requestedServices);
        let validationResults: IClientValidationResult[];
        if (this.isAppointmentRequired && !!this.appointmentStore) {
            const response = await this.schedulingApiAdapter.validateServiceRequestWithAppointment(
                this.serviceRequestStore,
                this.appointmentStore,
                this.isServiceRequestEmpty
            );
            validationResults = response.value.validationResults;
        } else {
            const response = await this.serviceRequestDataService.validateServiceRequestAsync(
                this.serviceRequestStore,
                this.isServiceRequestEmpty);
            validationResults = response;
        }
        return validationResults;
    }

    @State.action.bound
    private async onValidateAppointmentAsync() {
        const response = await this.schedulingApiAdapter.validateAsync(
            this.appointmentStore
        );
        return response.value;
    }

    @State.action.bound
    private openAppointmentModal() {
        this.modalService.showModal(
            new ServiceRequestAppointmentModalParams(
                this.appointmentStore,

                this.serviceRequestAsSchedulingService,
                this.serviceRequestStore.targetPointOfCareId,

                this.props._patient.baseData.name,

                this.onValidateAppointmentAsync,
                this.setAppointment,
                this.setTargetDoctorId
            )
        );
    }

    @State.action.bound
    private setAppointment(appointment: Appointment) {
        this.appointmentStore = appointment;
        this.targetCompletionDate = this.appointmentStore.intervalFrom;
    }

    @State.action.bound
    private setTargetDoctorId() {
        this.serviceRequestStore.targetDoctorId = this.appointmentStore.practitionerIds[0];
    }

    @State.bound private checkAppointmentRequired() { return this.isAppointmentRequired; }
    @State.bound private checkSuspectedDiagnosesList() { return this.serviceRequestStore.suspectedDiagnosisList.map(d => d.conditionId); }
    @State.bound private checkRequesterDoctor() { return this.serviceRequestStore.requesterDoctorId; }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }

        if (!this.props._patientId || !this.props._patient) {
            return null;
        }

        if (!this.serviceRequestDefinition || !this.serviceRequestStore || this.isLoading) {
            return null;
        }

        const referralPointOfCare = this.getReferralPointOfCare();

        const referralDataViewProps: IReferralDataPageBoxProps = {
            careIdentifier: this.serviceRequestStore.referralCareIdentifier,
            serviceRequestDefinitionDirection: this.serviceRequestStore.direction,
            requesterDoctorId: this.serviceRequestStore.requesterDoctorId,
            onRequesterDoctorIdChange: this.serviceRequestStore.setRequesterDoctorId,
            referralCreationDateTime: this.serviceRequestStore.createdAt,
            requesterPointOfCareId: referralPointOfCare ?? this.currentCareActivity?.pointOfCareId ?? this.props._careActivity?.pointOfCareId,
            referral: this.serviceRequestStore.referralLocation
        };

        const targetDataViewProps: ITargetDataPageBoxProps = {
            availableExternalLocationVersionSelectors: this.isExternalCareLocation ? this.serviceRequestDefinition.externalLocations : null,
            availablePointOfCareIds: this.isExternalCareLocation ? null : this.serviceRequestDefinition.internalLocationsAsPointOfCareIds,
            externalLocationVersionSelector: this.serviceRequestStore.targetExternalLocationVersionSelector,
            isExternalCareLocation: this.isExternalCareLocation,
            onExternalLocationVersionSelectorChange: this.setTargetExternalLocationVersionSelector,
            pointOfCareId: this.serviceRequestStore.targetPointOfCareId,
            onPointOfCareIdChange: this.setTargetPointOfCareId,
            targetCompletionAt: this.targetCompletionDate,
            onTargetCompletionDateChange: this.changeTargetCompletionDate,
            targetDoctorId: this.serviceRequestStore.targetDoctorId,
            onTargetDoctorIdChange: this.serviceRequestStore.setTargetDoctorId,
            priority: this.priority,
            onPriorityChange: this.changePriorityAsync,
            availablePriorities: this.availablePriorities,
            readOnlyMode: false,
            isTargetCareLocationEditingAllowed: !this.isEditingAllowed || this.serviceRequestStore.isNew,

            isAppointmentRequired: this.isAppointmentRequired,
            onOpenAppointmentModal: this.isAppointmentEditingAllowed ? this.openAppointmentModal : null,
            dateTimeFormatProvider: this.dateTimeFormatProvider
        };

        const localizedCreatedAt = this.localizationService.localizeDateTime(this.serviceRequestStore.createdAt);

        return (
            <PermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                <BusinessErrorHandler.Register businessErrorName="CareActivityIsAlreadyAServiceRequestExecutingCareActivityError" handler={this.handleCareActivityIsAlreadyAServiceRequestExecutingCareActivityError} />
                <State.Reaction inspector={this.checkAppointmentRequired} reaction={this.setAppointmentStore} />
                <State.Reaction inspector={this.checkSuspectedDiagnosesList} reaction={this.setAppointmentReferralDiagnoses} />
                <State.Reaction inspector={this.checkRequesterDoctor} reaction={this.setAppointmentReferralDoctorId} />
                <NavigateAwayHook onNavigateAwayAsync={this.unloadAsync} />
                <ServiceRequestPanelView
                    {...referralDataViewProps}
                    {...targetDataViewProps}

                    serviceRequestDefinition={this.serviceRequestDefinition}

                    isSuspectedDiagnosisLoading={this.isSuspectedDiagnosisLoading}
                    isLoading={this.isLoading}
                    diagnosisItems={this.serviceRequestStore.suspectedDiagnosisList}
                    medicalServiceIds={this.medicalServiceIds}
                    medicalServiceItems={this.serviceRequestStore.requestedServices}
                    selectedMedicalServicePanels={this.serviceRequestStore.selectedMedicalServicePanelIds}
                    onPanelSelectionChanged={this.serviceRequestStore.updatePanelSelection}
                    serviceRequest={this.serviceRequestStore}

                    clinicalQuestionValue={this.serviceRequestStore.clinicalQuestion}
                    onClinicalQuestionChange={this.serviceRequestStore.setClinicalQuestion}
                    isClinicalQuestionAllowed={this.serviceRequestDefinition.clinicalQuestionAllowed}
                    remarkValue={this.serviceRequestStore.remark}
                    onRemarkChange={this.serviceRequestStore.setRemark}
                    onSaveServiceRequestAsync={this.saveAndEmitEventAsync}
                    onDeleteServiceRequestAsync={this.deleteServiceRequestAsync}
                    canAddNewMedicalService={this.canAddNewMedicalService}

                    isAdministration={this.isAdministration}
                    isRenewAllowed={this.isRenewAllowed}
                    isEditingAllowed={this.isEditingAllowed}
                    isDeleteAllowed={this.isDeleteAllowed}
                    isSubmitAllowed={this.isSubmitAllowed}
                    onRenewServiceRequestAsync={this.renewServiceRequestAsync}
                    onClose={this.props.onClose}
                    openForEditAsync={this.openForEditAsync}
                    serviceRequestIdentifier={this.serviceRequestStore.serviceRequestIdentifier}
                    serviceRequestCreatedAt={localizedCreatedAt}
                    isMutable={this.serviceRequestStore.isMutable}

                    currentPatientGenderId={this.props._patient?.baseData.genderId}
                    hidePortalButtons={this.props.hidePortalButtons}

                    textBoxHeight={this.textBoxHeight}
                    onTextBoxHeightChange={this.onTextBoxHeightChange}
                    onValidateAsync={this.onValidateAsync}
                    validateAllEvent={this.validateAllEvent}

                    referralExtensionPanelProps={this.referralExtensionPanelProps}
                    headerExtensionPanelProps={this.headerExtensionPanelProps}
                    clinicalQuestionExtensionPanelProps={this.clinicalQuestionExtensionPanelProps}
                />
            </PermissionCheckContextProvider>
        );
    }
}

export default connect(
    ServiceRequestPanel,
    new DependencyAdapter<IServiceRequestPanelProps, IServiceRequestPanelDependencies>((container) => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            serviceProviderProfileApiAdapter: container.resolve("ServiceProviderProfileApiAdapter"),
            diagnosisListApiAdapter: container.resolve("DiagnosisListApiAdapter"),
            careActivityBaseDataApiAdapter: container.resolve("CareActivityBaseDataApiAdapter"),
            dialogService: container.resolve("IDialogService"),
            notificationService: container.resolve("INotificationService"),
            localizationService: container.resolve("ILocalizationService"),
            serviceRequestDataService: container.resolve("IServiceRequestDataService"),
            lockingApiAdapter: container.resolve("LockingApiAdapter"),
            careActivityApiAdapter: container.resolve("CareActivityApiAdapter2"),
            schedulingApiAdapter: container.resolve("SchedulingApiAdapter"),
            structureApiAdapter: container.resolve("StructureApiAdapter"),
            dynamicPropertiesApiAdapter: container.resolve("DynamicPropertiesApiAdapter"),
            clientSessionService: container.resolve("IClientSessionService"),
            dateTimeFormatProvider: container.resolve("IDateTimeFormatProvider")
        };
    }),
    new CareActivityContextAdapter<IServiceRequestPanelProps>(ctx => ({
        _careActivity: ctx.careActivity,
        _careActivityBaseData: ctx.baseData,
        _careActivityId: ctx.careActivityId,
        _isCareBaseDataUnauthorized: !ctx.isAuthorizedForCareActivityBaseData
    })),
    new PatientContextAdapter<IServiceRequestPanelProps>(c => ({
        _patientId: c.patientId,
        _patient: c.patient
    })),
    new HisModalServiceAdapter(),
    new FormExtensionRegistryAdapter("ManageServiceRequest")
);