import React from "react";
import _ from "@HisPlatform/Common/Lodash";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import ShowDiagnosisScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowDiagnosisScreenAction.g";
import DiagnosisListPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/DiagnosisListPanel/DiagnosisListPanel";
import { useScreenStateContext } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ShowReadOnlyDiagnosisScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowReadOnlyDiagnosisScreenAction.g";

export interface IDiagnosisListScreenProps extends IScreenPropsBase<ShowDiagnosisScreenAction | ShowReadOnlyDiagnosisScreenAction> {
}

function DiagnosisListScreen(props: IDiagnosisListScreenProps) {

    const screenState = useScreenStateContext();

    return (
        <DiagnosisListPanel
            onBack={screenState.cancelled}
            readonly={props.action instanceof ShowReadOnlyDiagnosisScreenAction}
        />
    );
}

export default State.observer(DiagnosisListScreen);