import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import CareActivityStoreMapper from "./CareActivityStoreMapper";
import CareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivity/CareActivityStore";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import StatusChangeReasonId from "@Primitives/StatusChangeReasonId.g";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import PossibleAction from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/PossibleAction";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import GenderId from "@Primitives/GenderId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";

@Di.injectable()
export default class CareActivityApiAdapter2 extends ApiAdapterBase {
    constructor(
        @Di.inject("ICareRegisterClient") private readonly careRegisterClient: Proxy.ICareRegisterClient,
        @Di.inject("IActionsClient") private readonly actionsClient: Proxy.IActionsClient,
        @Di.inject("CareActivityStoreMapper") private readonly careActivityStoreMapper: CareActivityStoreMapper
    ) {
        super();
    }

    public loadByIdAsync(id: CareActivityId) {
        return this.processOperationAsync(
            new CareActivityStore(),
            async target => {
                const response = await this.careRegisterClient.getCareActivityQueryAsync(
                    CreateRequestId(),
                    id.value
                );
                this.careActivityStoreMapper.applyToStore(target, response);
            }
        );
    }

    public loadByIdsAsync(ids: CareActivityId[]) {
        return this.processOperationAsync(
            new SimpleStore<CareActivityStore[]>(),
            async target => {
                const requestString = ids.map(i => i.value).join(";");

                const response = await this.careRegisterClient.getCareActivitiesByIdsQueryAsync(
                    CreateRequestId(),
                    requestString
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.careActivities.map(i => {
                    const careActivityStore = new CareActivityStore();
                    careActivityStore.id = i.id;
                    careActivityStore.pointOfCareId = i.pointOfCareId;
                    careActivityStore.patientId = i.patientId;
                    return careActivityStore;
                });
            }
        );
    }

    public getPossibleActionsAsync(ids: Array<{ careActivityId: CareActivityId, serviceRequestId?: ServiceRequestId }>) {
        return this.processOperationAsync(
            new SimpleStore<PossibleAction[]>(),
            async target => {
                const response = await this.actionsClient.getPossibleActionsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetPossibleActionsControllerDto({
                        careItemIdentifiers: ids.map(i => new Proxy.CareItemIdentifierDto({
                            careActivityId: i.careActivityId,
                            serviceRequestId: i.serviceRequestId
                        }))
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.possibleActions.
                    map(i => new PossibleAction(i.identifier.careActivityId, i.identifier.serviceRequestId, i.actions));
            }
        );
    }

    public patientArrivedAsync(careActivityId: CareActivityId) {
        return this.changeCareActivityStateAsync(
            async () => {
                const result = await this.careRegisterClient.patientArrivedCommandAsync(CreateRequestId(), new Proxy.PatientArrivedControllerDto({
                    careActivityId: careActivityId
                }));
                return result;
            }
        );
    }

    public revokePatientArrivedAsync(careActivityId: CareActivityId, statusChangeReasonId: StatusChangeReasonId, additionalText: string) {
        return this.changeCareActivityStateAsync(
            async () => {
                const result = await this.careRegisterClient.revokePatientArrivedCommandAsync(CreateRequestId(), new Proxy.RevokePatientArrivedControllerDto({
                    careActivityId: careActivityId,
                    statusChangeReasonId: statusChangeReasonId,
                    additionalText: additionalText
                }));
                return result;
            }
        );
    }

    public patientDidNotAppearAsync(careActivityId: CareActivityId) {
        return this.changeCareActivityStateAsync(
            async () => {
                const result = await this.careRegisterClient.didNotAppearCommandAsync(CreateRequestId(), new Proxy.DidNotAppearControllerDto({
                    careActivityId: careActivityId
                }));
                return result;
            }
        );
    }

    public revokePatientDidNotAppearAsync(careActivityId: CareActivityId, statusChangeReasonId: StatusChangeReasonId, additionalText: string) {
        return this.changeCareActivityStateAsync(
            async () => {
                const result = await this.careRegisterClient.revokeDidNotAppearCommandAsync(CreateRequestId(), new Proxy.RevokeDidNotAppearControllerDto({
                    careActivityId: careActivityId,
                    statusChangeReasonId: statusChangeReasonId,
                    additionalText: additionalText
                }));
                return result;
            }
        );
    }

    public deleteCareActivityAsync(careActivityId: CareActivityId, statusChangeReasonId: StatusChangeReasonId, additionalText: string) {
        return this.changeCareActivityStateAsync(
            async () => {
                const result = await this.careRegisterClient.deleteCareActivityCommandAsync(CreateRequestId(), new Proxy.DeleteCareActivityControllerDto({
                    careActivityId: careActivityId,
                    statusChangeReasonId: statusChangeReasonId,
                    additionalText: additionalText
                }));
                return result;
            }
        );
    }

    public deleteAdmissionAsync(careActivityId: CareActivityId, statusChangeReasonId: StatusChangeReasonId, additionalText: string) {
        return this.changeCareActivityStateAsync(
            async () => {
                const result = await this.careRegisterClient.deleteAdmissionCommandAsync(CreateRequestId(), new Proxy.DeleteCareActivityControllerDto({
                    careActivityId: careActivityId,
                    statusChangeReasonId: statusChangeReasonId,
                    additionalText: additionalText
                }));
                return result;
            }
        );
    }

    public closeCareActivityAsync(careActivityId: CareActivityId) {
        return this.changeCareActivityStateAsync(
            async () => {
                const result = await this.careRegisterClient.closeCareActivityCommandAsync(CreateRequestId(), new Proxy.CloseCareActivityControllerDto({
                    careActivityId: careActivityId
                }));
                return result;
            }
        );
    }

    public revokeDeleteCareActivityAsync(careActivityId: CareActivityId, statusChangeReasonId: StatusChangeReasonId, additionalText: string) {
        return this.changeCareActivityStateAsync(
            async () => {
                const result = await this.careRegisterClient.revokeDeleteCareActivityCommandAsync(CreateRequestId(), new Proxy.RevokeDeleteCareActivityControllerDto({
                    careActivityId: careActivityId,
                    statusChangeReasonId: statusChangeReasonId,
                    additionalText: additionalText
                }));
                return result;
            }
        );
    }

    public revokeDischargePatientAsync(careActivityId: CareActivityId, statusChangeReasonId: StatusChangeReasonId, additionalText: string) {
        return this.changeCareActivityStateAsync(
            async () => {
                const result = await this.careRegisterClient.revokeDischargePatientCommandAsync(CreateRequestId(), new Proxy.RevokeDischargePatientControllerDto({
                    careActivityId: careActivityId,
                    statusChangeReasonId: statusChangeReasonId,
                    additionalText: additionalText
                }));
                return result;
            }
        );
    }

    public suspendCareActivityAsync(careActivityId: CareActivityId) {
        return this.changeCareActivityStateAsync(
            async () => {
                const result = await this.careRegisterClient.suspendActivityCommandAsync(CreateRequestId(), new Proxy.SuspendActivityControllerDto({
                    careActivityId: careActivityId
                }));
                return result;
            }
        );
    }

    public reopenCareActivityAsync(careActivityId: CareActivityId, statusChangeReasonId: StatusChangeReasonId, additionalText: string) {
        return this.changeCareActivityStateAsync(
            async () => {
                const result = await this.careRegisterClient.reopenCareActivityCommandAsync(CreateRequestId(), new Proxy.ReopenCareActivityControllerDto({
                    careActivityId: careActivityId,
                    statusChangeReasonId: statusChangeReasonId,
                    additionalText: additionalText
                }));
                return result;
            }
        );
    }

    public revokeCallPatientAsync(careActivityId: CareActivityId, statusChangeReasonId: StatusChangeReasonId, additionalText: string) {
        return this.changeCareActivityStateAsync(
            async () => {
                const result = await this.careRegisterClient.revokeCallPatientCommandAsync(CreateRequestId(), new Proxy.RevokeCallPatientControllerDto({
                    careActivityId: careActivityId,
                    statusChangeReasonId: statusChangeReasonId,
                    additionalText: additionalText
                }));
                return result;
            }
        );
    }

    public revokeSuspendCareActivityAsync(careActivityId: CareActivityId) {
        return this.changeCareActivityStateAsync(
            async () => {
                const result = await this.careRegisterClient.revokeSuspendActivityCommandAsync(CreateRequestId(), new Proxy.RevokeSuspendActivityControllerDto({
                    careActivityId: careActivityId
                }));
                return result;
            }
        );
    }

    public async getPointOfCareIdsForOrganizationUnitIdAsync(organizationUnitId: OrganizationUnitId) {
        const res = await this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const result = await this.careRegisterClient.getPointOfCareIdsForOrganizationUnitQueryAsync(CreateRequestId(), organizationUnitId.value);
                if (!isNullOrUndefined(result.pointOfCareIds)) {
                    target.value = result.pointOfCareIds.map(val => new PointOfCareId(val.value));
                }
                target.operationInfo = createOperationInfo(result);
            }
        );
        return res;
    }

    public async changeCareActivityStateAsync<TResponse>(apiAction: () => Promise<TResponse>) {
        const res = await this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const result = await apiAction();
                target.operationInfo = createOperationInfo(result);
            }
        );
        return res;
    }

    public getcareActivityStateStatisticsAsync(pointOfCareId: PointOfCareId, date: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<Array<{ state: CareActivityState, count: number }>>(),
            async target => {
                const result = await this.careRegisterClient.getCareActivityStateStatisticsQueryAsync(CreateRequestId(), new Proxy.GetCareActivityStateStatisticsControllerDto({
                    date: date,
                    pointOfCareId: pointOfCareId
                }));
                target.operationInfo = createOperationInfo(result);
                target.value = result && result.results && result.results.map(r => {
                    return {
                        state: r.careActivityState,
                        count: r.count
                    };
                });
            }
        );
    }

    public getFinishedCareActivityGenderStatisticsAsync(pointOfCareId: PointOfCareId, date: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<Array<{ genderId: GenderId, count: number }>>(),
            async target => {
                const result = await this.careRegisterClient.getFinishedCareActivityGenderStatisticsQueryAsync(CreateRequestId(), new Proxy.GetFinishedCareActivityGenderStatisticsControllerDto({
                    date: date,
                    pointOfCareId: pointOfCareId
                }));
                target.operationInfo = createOperationInfo(result);
                target.value = result && result.results && result.results.map(r => {
                    return {
                        genderId: r.genderId,
                        count: r.count
                    };
                });
            }
        );
    }

    public async checkCareActivityOperationPermissionsAsync(careActivityId: CareActivityId) {
        return await this.processOperationAsync(
            new SimpleStore<Proxy.PatientCareActivityRelatedPermissionsDto>(),
            async target => {
                const result = await this.careRegisterClient.getPatientsCareActivityRelatedPermissionQueryAsync(CreateRequestId(), careActivityId.value);

                target.value = result.permissions;
                target.operationInfo = createOperationInfo(result);
            }
        );
    }

    @State.bound
    public closeCareActivitiesAsync(period: LocalDateRange, pointOfCareIds: PointOfCareId[]) {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {
                const response = await this.careRegisterClient.closeCareActivitiesCommandAsync(
                    CreateRequestId(),
                    new Proxy.CloseCareActivitiesControllerDto({
                        period: period,
                        pointOfCareIds: pointOfCareIds
                    })
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}
