import React, { useCallback, useMemo } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FormEditorRegistry, { IFormEditorComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import { useFormPanelStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanelStoreProvider";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ReferencedExtensibleEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedExtensibleEnumFormDataElement";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";


interface IFormExtensibleEnumSelectorProps extends IFormEditorComponentProps {

}

function FormExtensibleEnumSelector(props: IFormExtensibleEnumSelectorProps) {

    const { formEditorRegistry } = useDependencies(c => ({
        formEditorRegistry: c.resolve<FormEditorRegistry>("FormEditorRegistry")
    }));
    const dataElement = (props.dataElement as ReferencedExtensibleEnumFormDataElement);
    const editor = formEditorRegistry.tryGetExtensibleEnumEditorComponent(dataElement.extensibleEnum);

    const valueObj = useMemo(() => {
        if (dataElement.isArray) {
            return isNullOrUndefined(props.value) && editor ? State.createObservableShallowArray([]) : State.createObservableShallowArray(props.value.map((i: any) => new editor.entityIdCtor(`${i}`)));
        } else {
            return isNullOrUndefined(props.value) || !editor ? null : new editor.entityIdCtor(`${props.value}`);
        }
    }, [props.value, editor]);

    const change = useCallback((value: IStringEntityId | IStringEntityId[] | string | string[]) => {
        if (Array.isArray(value)) {
            if (value.length === 0) {
                props.onChange(value);
            }
            if (typeof value[0] === "string") {
                props.onChange(value);
            } else {
                props.onChange((value as []).map((i: IStringEntityId) => i.value));
            }
        } else {
            if (typeof value === "string") {
                props.onChange(value);
            } else {
                props.onChange(value?.value);
            }
        }
    }, [props.onChange]);

    return React.createElement(
        editor.componentType,
        { ...editor.props, ...props, onChange: change, value: valueObj, multiSelect: dataElement.isArray }
    );

}

export default State.observer(FormExtensibleEnumSelector);