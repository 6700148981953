exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AlertCardContainer_no-text-selection_FCb9v {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.AlertCardContainer_alertcards-container_2C37X {\n  position: relative;\n  background-color: #0082aa;\n  height: 48px;\n  width: 100%;\n}\n.AlertCardContainer_dropdown_34kAO {\n  background-color: #0082aa;\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: fit-content;\n  max-height: 48px;\n  display: flex;\n  overflow-x: hidden;\n  overflow-y: hidden;\n  z-index: 1004;\n  transition: max-height 1s ease;\n}\n.AlertCardContainer_dropdown--open_2YwQF {\n  max-height: 200px;\n  flex-wrap: wrap;\n  border-radius: 0px 0px 2px 2px;\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "AlertCardContainer_no-text-selection_FCb9v",
	"noTextSelection": "AlertCardContainer_no-text-selection_FCb9v",
	"alertcards-container": "AlertCardContainer_alertcards-container_2C37X",
	"alertcardsContainer": "AlertCardContainer_alertcards-container_2C37X",
	"dropdown": "AlertCardContainer_dropdown_34kAO",
	"dropdown--open": "AlertCardContainer_dropdown--open_2YwQF",
	"dropdownOpen": "AlertCardContainer_dropdown--open_2YwQF"
};