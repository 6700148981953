import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import EntityPermissionStore from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/DataAccessControl/EntityPermissionStore";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import EntityProtectionLevelSelector from "@HisPlatform/BoundedContexts/Authorization/Components/Controls/EntityProtectionLevelSelector/EntityProtectionLevelSelector";
import StaticAuthorizationResources from "@HisPlatform/BoundedContexts/Authorization/StaticResources/StaticAuthorizationResources";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Styles from "./AccessControlModal.less";
import UserId from "@Primitives/UserId.g";
import UserGroupId from "@Primitives/UserGroupId.g";
import UserListStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/UserListStore";
import UserGroupListStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroupListStore";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import UserMultiSelectDialogParams, { IUserMultiSelectDialogResult } from "./Modals/UserMultiSelectDialog/UserMultiSelectDialogParams";
import UserGroupMultiSelectDialogParams, { IUserGroupMultiSelectDialogResult } from "./Modals/UserGroupMultiSelectDialog/UserGroupMultiSelectDialogParams";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";

interface IAccessControlModalViewProps {
    _modalService?: IModalService;

    permissionStore: EntityPermissionStore;
    isDisabled: boolean;
    isOriginalEmpty: boolean;
    usersStore: UserListStore;
    userGroupsStore: UserGroupListStore;
    currentUserId: UserId;
    otherValidationProblems: IClientValidationProblem[];

    onDisableAccessControlAsync: () => Promise<void>;
    onCancelAsync: () => Promise<void>;
    onSaveAsync: () => Promise<void>;
}

@State.observer
class AccessControlModalView extends React.Component<IAccessControlModalViewProps> {

    public render() {

        if (!this.props.permissionStore) {
            return null;
        }

        return (
            <HisUi.ContextAwareModal
                isOpen
                title={StaticWebAppResources.DailyPatientList.DataAccessControlListTitle}
                automationId="__accessControlModal"
                iconName="cog"
                onClose={this.props.onCancelAsync}>
                <Ui.Modal.Body>
                    <ReadOnlyContext.Provider value={this.props.isDisabled}>
                        <ValidationBoundary
                            validationResults={this.props.permissionStore.validationResults}
                            entityTypeName="AccessControlList"
                        >
                            <Ui.Flex>
                                <Ui.Flex.Item>
                                    <EntityProtectionLevelSelector
                                        label={StaticAuthorizationResources.AccessControlList.Label.EntityProtectionLevel}
                                        value={this.props.permissionStore.accessControlList.entityProtectionLevel}
                                        onChange={this.props.permissionStore.accessControlList.setEntityProtectionLevel}
                                        automationId="__protectionLevelRadioButtonGroup"
                                        propertyIdentifier="EntityProtectionLevel"
                                    />
                                </Ui.Flex.Item>
                            </Ui.Flex>
                            <ReadOnlyContext.Provider value={isNullOrUndefined(this.props.permissionStore.accessControlList.entityProtectionLevel)}>
                                <Ui.Flex>
                                    <Ui.Flex.Item xs={6} className={Styles.rightsPanel} automationId="__accessRightTable">
                                        {this.renderObjectPermissionTable("Access")}
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={6} className={Styles.rightsPanel} automationId="__setPermissionRightTable">
                                        {this.renderObjectPermissionTable("SetPermissions")}
                                    </Ui.Flex.Item>
                                </Ui.Flex>
                                <Ui.ValidationResultSummary
                                    results={this.props.otherValidationProblems}
                                    displayMode="list"
                                />
                            </ReadOnlyContext.Provider>
                        </ValidationBoundary>
                    </ReadOnlyContext.Provider>
                </Ui.Modal.Body>

                <Ui.Modal.Footer>
                    <Ui.Button text={StaticAuthorizationResources.AccessControlList.Button.TurnOffAccessControl}
                        onClickAsync={this.props.onDisableAccessControlAsync}
                        float="left"
                        disabled={this.props.isOriginalEmpty}
                        tooltipPosition="right"
                        tooltipContent={this.props.isOriginalEmpty ? StaticAuthorizationResources.AccessControlList.Dialog.NoAccessControlToDisable : undefined}
                        automationId="__disableAccessControlButton" />
                    <Ui.Button text={StaticAuthorizationResources.Common.Button.Cancel}
                        onClick={this.props.onCancelAsync}
                        float="right"
                        automationId="__cancelButton" />
                    <Ui.SaveButton
                        onClickAsync={this.props.onSaveAsync}
                        float="right"
                        visualStyle="primary"
                        automationId="__saveButton"
                    />
                </Ui.Modal.Footer>
            </HisUi.ContextAwareModal>
        );
    }

    @State.bound
    private async selectUsersAsync(permissionId: "Access" | "SetPermissions") {
        const alreadySelectedUserIds = this.props.permissionStore.getUserIdsByObjectPermission(permissionId);
        const dialogResult = await this.props._modalService.showDialogAsync<IUserMultiSelectDialogResult>(
            new UserMultiSelectDialogParams(
                this.props.currentUserId,
                alreadySelectedUserIds,
                StaticAuthorizationResources.AccessControlList.ObjectPermissions[permissionId])
        );
        this.props.permissionStore.setUserEntriesByObjectPermission(permissionId, dialogResult.selectedUserIds);
    }

    @State.bound
    private onSelectUserClickHandlerFactory(permissionId: "Access" | "SetPermissions") {
        return () => {
            dispatchAsyncErrors(this.selectUsersAsync(permissionId), this);
        };
    }

    @State.bound
    private async selectUserGroupsAsync(permissionId: "Access" | "SetPermissions") {
        const alreadySelectedUserGroupIds = this.props.permissionStore.getUserGroupIdsByObjectPermission(permissionId);
        const dialogResult = await this.props._modalService.showDialogAsync<IUserGroupMultiSelectDialogResult>(
            new UserGroupMultiSelectDialogParams(alreadySelectedUserGroupIds,
            StaticAuthorizationResources.AccessControlList.ObjectPermissions[permissionId])
        );
        this.props.permissionStore.setUserGroupEntriesByObjectPermission(permissionId, dialogResult.selectedUserGroupIds);
    }

    @State.bound
    private onSelectUserGroupClickHandlerFactory(permissionId: "Access" | "SetPermissions") {
        return () => {
            dispatchAsyncErrors(this.selectUserGroupsAsync(permissionId), this);
        };
    }

    private renderObjectPermissionTable(permissionId: "Access" | "SetPermissions") {
        return (
            <>
                <h2>{StaticAuthorizationResources.AccessControlList.ObjectPermissions[permissionId]}</h2>
                <div>
                    <Ui.Table fixedHeight="160px" scrollable automationId="__userList">
                        <tbody>
                            {this.props.permissionStore.getUserIdsByObjectPermission(permissionId).map((id) => {
                                return (
                                    <tr key={id?.value}>
                                        <td>
                                            {this.renderUser(id)}
                                        </td>
                                    </tr>
                                );
                            })}
                            {this.props.permissionStore.getUserGroupIdsByObjectPermission(permissionId).map((id) => {
                                return (
                                    <tr key={id?.value}>
                                        <td>
                                            {this.renderGroup(id)}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Ui.Table>
                </div>
                <Ui.Button
                    text={StaticAuthorizationResources.AccessControlList.Button.Users}
                    automationId="__selectUsersButton"
                    onClick={this.onSelectUserClickHandlerFactory(permissionId)}
                />

                <Ui.Button
                    text={StaticAuthorizationResources.AccessControlList.Button.UserGroups}
                    automationId="__selectUserGroupsButton"
                    onClick={this.onSelectUserGroupClickHandlerFactory(permissionId)}
                />
            </>
        );
    }

    private renderUser(userId: UserId) {
        const user = this.props.usersStore.getUserById(userId) || null;
        return user && user.displayName || "";
    }

    private renderGroup(groupId: UserGroupId) {
        const group = this.props.userGroupsStore.getGroupById(groupId) || null;
        return group && (group.name + " (" + StaticAuthorizationResources.AccessControlList.Group + ")") || "";
    }
}

export default connect(
    AccessControlModalView,
    new HisModalServiceAdapter<IAccessControlModalViewProps>()
);