import Di from "@Di";
import IPatientCareActivitiesTabRegistry from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabRegistry";
import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import React, { ReactNode } from "react";
import IPatientCareActivitiesTabComponentService from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabComponentService";
import IPatientCareActivitiesTabProps from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabProps";
import CareActivityId from "@Primitives/CareActivityId.g";
import DocumentId from "@Primitives/DocumentId.g";
import moment from "moment";

@Di.injectable()
export default class PatientCareActivitiesTabRegistry implements IPatientCareActivitiesTabRegistry {

    constructor(@Di.inject("IContainerService") private containerService: IContainerService) {
    }

    private readonly tabMap =
        new Map<string, {
            tabName: string,
            order: number,
            componentServiceName: string,
            content: React.ComponentType<IPatientCareActivitiesTabProps>,
        }>();

    public register(tabName: string, order: number, componentServiceName: string, content: React.ComponentType<IPatientCareActivitiesTabProps>): void {

        if (this.tabMap.has(tabName)) {
            this.tabMap[tabName] = { tabName: tabName, order: order, componentServiceName: componentServiceName, content: content };
        } else {
            this.tabMap.set(tabName, { tabName: tabName, order: order, componentServiceName: componentServiceName, content: content });
        }
    }

    public getAllComponentServices(): Array<{ tabName: string, order: number, service: IPatientCareActivitiesTabComponentService; }> {

        const result: { tabName: string, order: number, service: IPatientCareActivitiesTabComponentService }[] = [];
        this.tabMap.forEach((value, key) => {
            result.push({ tabName: key, order: value.order, service: this.containerService.resolve(value.componentServiceName) });
        });

        return result;
    }

    public getAllComponents(careActivityId: CareActivityId, documentId: DocumentId, beginningOfCare: moment.Moment, dischargedAt: moment.Moment, inModalView: boolean): Array<{ tabName: string, content: ReactNode }> {

        const result: { tabName: string; content: ReactNode; }[] = [];
        this.tabMap.forEach((value, key) => {

            result.push({
                tabName: key,
                content: React.createElement<IPatientCareActivitiesTabProps>(value.content, {
                    careActivityId: careActivityId,
                    documentId: documentId,
                    beginningOfCare: beginningOfCare,
                    dischargedAt: dischargedAt,
                    inModalView: inModalView
                })
            });
        });

        return result;
    }
}