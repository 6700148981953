import FormDataElementBase from "./FormDataElementBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import TokenDefaultValue from "@Toolkit/FormEngine/Model/Layout/TokenDefaultValue";

export default class StringFormDataElement extends FormDataElementBase {

    @State.observable.ref public isLong: boolean;

    constructor(
        name: string,
        isArray: boolean,
        defaultValue: string | TokenDefaultValue,
        isReadOnly: boolean,
        isVisible: boolean,
        isLong: boolean
    ) {
        super(name, isArray, defaultValue, isReadOnly, isVisible);
        this.isLong = isLong;
    }

    @State.action.bound
    public setIsLong(isLong: boolean) {
        this.isLong = isLong;
    }

    public formDataElementType = "String";
}