import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import * as Ui from "@CommonControls";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { emptyObject, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PractitionerId from "@Primitives/PractitionerId.g";
import IDoctor from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IDoctor";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import AsyncMessageQueue from "@Toolkit/CommonWeb/AsyncMessageQueue";

interface IPractitionerSelectBoxDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    localizationService: ILocalizationService;
}

interface IPractitionerSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: IPractitionerSelectBoxDependencies;
    explicitIds?: PractitionerId[];
    defaultId?: PractitionerId;
    shouldSelectOnlyItem?: boolean;
}

@State.observer
class PractitionerSelectBox extends React.Component<IPractitionerSelectBoxProps> {

    private readonly loadQueue = new AsyncMessageQueue(this.loadAsync);

    private get referenceDataStore() {
        return this.props._dependencies.organizationReferenceDataStore;
    }

    @State.observable.ref private items: Array<ISelectBoxItem<PractitionerId>> = null;

    public componentDidMount() {
        dispatchAsyncErrors(this.loadQueue.enqueueAndProcessAsync(emptyObject), this);
    }

    public componentDidUpdate(prevProps: IPractitionerSelectBoxProps) {
        if (prevProps.explicitIds !== this.props.explicitIds || prevProps.shouldSelectOnlyItem !== this.props.shouldSelectOnlyItem) {
            dispatchAsyncErrors(this.loadQueue.enqueueAndProcessAsync(emptyObject), this);
        }
    }

    @State.bound
    private async loadAsync() {
        const explicitIds = !isNullOrUndefined(this.props.explicitIds) ? [...this.props.explicitIds] : null;

        if (!isNullOrUndefined(explicitIds)) {
            await this.referenceDataStore.practitionerMap.ensureLoadedAsync(explicitIds);
            this.initializeExplicitItems(explicitIds);
        } else {
            await this.referenceDataStore.practitionerMap.ensureAllLoadedAsync();
            this.initializeAllItems();
        }
    }

    @State.action
    private initializeExplicitItems(explicitIds: PointOfCareId[]) {
        this.items = this.referenceDataStore.practitionerMap.items
            .filter(i => explicitIds.some(s => ValueWrapper.equals(s, i.id)))
            .map(this.toSelectBoxItem);
        this.selectItem();
    }

    @State.action
    private initializeAllItems() {
        this.items = this.referenceDataStore.practitionerMap.items
            .map(this.toSelectBoxItem);
        this.selectItem();
    }

    private selectItem() {
        if (!isNullOrUndefined(this.props.defaultId)) {
            this.props.onChange(this.props.defaultId.value); 
        } else if (this.props.shouldSelectOnlyItem && this.items.length === 1) {
            this.props.onChange(this.items[0].value);
        }
    }

    private displayName(item: PractitionerStore) {
        return this.props._dependencies.localizationService.localizePersonName(item.baseData.name);
    }

    @State.bound
    private toSelectBoxItem(item: PractitionerStore) {
        return {
            text: this.displayName(item),
            code: item.code, 
            value: item.id
        } as ISelectBoxItem<PractitionerId>;
    }

    @State.bound
    private renderValue(props: any) {
        return !!props.data.code ? <><b>{props.data.code}</b>{` ${props.data.text}`}</> : <>{`${props.data.text}`}</>;
    }

    public render() {
        return (
            <Ui.SelectBox
                {...this.props}
                loading={!this.items}
                items={this.items}
                customValueRenderer={this.renderValue}
            />
        );
    }
}

export default connect(
    PractitionerSelectBox,
    new DependencyAdapter<IPractitionerSelectBoxProps, IPractitionerSelectBoxDependencies>(container => {
        return {
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            localizationService: container.resolve("ILocalizationService")
        };
    })
);
