import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import DischargeReasonId from "@Primitives/DischargeReasonId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import IForwardingNeededAtDischarge from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/IForwardingNeededAtDischarge";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class ForwardingNeededAtDischargeApiAdapter extends ApiAdapterBase {
    constructor(@Di.inject("ICareRegisterClient") private apiClient: Proxy.ICareRegisterClient) {
        super();
    }

    @State.bound
    public getByIdsAsync(ids: DischargeReasonId[]) {
        return this.processOperationAsync(
            new SimpleStore<IForwardingNeededAtDischarge[]>(),
            async (target) => {
                const response = await this.apiClient.getForwardingNeededAtDischargeDataByIdsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetForwardingNeededAtDischargeDataByIdsControllerDto({
                        ids: ids
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.forwardingData.map(i => {
                    return {
                        id: i.id,
                        isForwardingNeeded: i.isForwardingNeeded
                    };
                });
            }
        );
    }
}