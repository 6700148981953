import { IPagingState, IOrderingState, DataGridLoadType } from "@CommonControls/DataGrid/IDataGridProps";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import PatientsCareActivitiesApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/PatientCareActivities/PatientsCareActivitiesApiAdapter";
import Di from "@Di";
import PatientId from "@Primitives/PatientId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PractitionerId from "@Primitives/PractitionerId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import _ from "@HisPlatform/Common/Lodash";
import DataGridDataSourceBase from "@CommonControls/DataGrid/DataSource/DataGridDataSourceBase";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import ListQueryOptions from "@Toolkit/CommonWeb/Model/ListQueryOptions";
import CareActivityDocumentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDocument/CareActivityDocumentApiAdapter";
import IPatientsCareActivitiesFilterStore from "@PluginInterface/BoundedContexts/Care/CareRegister/PatientsCareActivities/IPatientsCareActivitiesFilterStore";
import EhiPatientsCareActivitiesFilterStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiPatientsCareActivitiesListFilter/EhiPatientsCareActivitiesFilterStore";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IPatientCareActivitiesDataSource from "@PluginInterface/BoundedContexts/Care/CareRegister/PatientsCareActivities/IPatientCareActivitiesDataSource";
import CareActivitySource from "@Primitives/CareActivitySource.g";
import ConditionId from "@Primitives/ConditionId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import DiagnosisRoleId from "@Primitives/DiagnosisRoleId.g";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import EhiHealthcareProviderOrganizationUnitId from "@Primitives/EhiHealthcareProviderOrganizationUnitId.g";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import EhiCareServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareServiceFactory";
import EhiCareService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Services/EhiCareService";
import EhiServiceCallStatus from "@HunEHealthInfrastructurePlugin/Model/EhiServiceCallStatus";
import EhiUserType from "@HunEHealthInfrastructurePlugin/Model/EhiUserType";
import ApiBusinessError from "@Toolkit/CommonWeb/ApiAdapter/ApiBusinessError";
import NoEhiCompatibleIdentifierFoundError from "@HunEHealthInfrastructurePlugin/Model/NoEhiCompatibleIdentifierFoundError";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import DataLoadError from "@CommonControls/DataGrid/DataSource/DataLoadError";

@Di.injectable()
export default class EhiPatientsCareActivitiesDataSource extends DataGridDataSourceBase<PatientsCareActivityStore, IPatientsCareActivitiesFilterStore> implements IPatientCareActivitiesDataSource {

    
    public __dataGridDataSource: true = true;

    public patientId: PatientId;

    @State.observable public isLoading: boolean = false;
    @State.observable public isPermissionFiltered: boolean = false;

    @State.observable.ref public extensionData: any = null;

    private internalExtensionData: any = {};
    
    @State.observable.ref private _ehiCareService: EhiCareService = null;

    @State.action.bound
    public setExtensionData(newValue: any) {
        this.extensionData = newValue;
    }

    @State.action.bound
    public setIsPermissionFiltered(newValue: boolean) {
        this.isPermissionFiltered = newValue;
    }

    @State.computed
    public get ehiCareService() {
        return this._ehiCareService;
    }

    @State.bound
    private clearCachedOrganizationUnitId() {
        if (!isNullOrUndefined(this.internalExtensionData
            && !isNullOrUndefined(this.internalExtensionData["OrganizationUnitId"]))) {
            this.internalExtensionData["OrganizationUnitId"] = null;
        }
    }

    constructor(
        @Di.inject("PatientsCareActivitiesApiAdapter") private patientsCareActivitiesApiAdapter: PatientsCareActivitiesApiAdapter,
        @Di.inject("CareActivityDocumentApiAdapter") private careDocumentApiAdapter: CareActivityDocumentApiAdapter,
        @Di.inject("OrganizationReferenceDataStore") private organizationReferenceDataStore: OrganizationReferenceDataStore,
        @Di.inject("CareReferenceDataStore") private careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("HunEhiCareReferenceDataStore") private hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore,
        @Di.inject("EhiCareServiceFactory") private ehiCareServiceFactory: EhiCareServiceFactory,
        @Di.inject("error") onErrorAsync: (error: @Di.inject("Promise") DataLoadError) => Promise<void>
    ) {
        super(onErrorAsync);

        const filterStore = new EhiPatientsCareActivitiesFilterStore();

        this.setState(
            { totalCount: 0, items: [] },
            { currentPage: 0, pageSize: 10 },
            { columnId: "carePeriod", direction: "desc" },
            filterStore
        );

        this.filterStore.onChangeDebounced.attach(() => {
            dispatchAsyncErrors(this.refreshAsync(), null);
        });

        State.reaction(() => filterStore.careActivitySource, careActivitySource => {
            this.setState({ totalCount: 0, items: [] }, { currentPage: 0, pageSize: 10 });
        });

        this._ehiCareService = this.ehiCareServiceFactory.getEhiCareService();
    }

    @State.bound
    public async clearExtensionDataCacheAndReloadAsync() {
        this.clearCachedOrganizationUnitId();
        await this.refreshAsync();
    }

    @State.bound
    public async refreshAsync() {
        await this.onChangeAsync("changed", this.paging, this.ordering as IOrderingState, this.filterStore, []);
    }

    @State.boundLoadingState("isLoading")
    protected async onChangeCoreAsync(type: DataGridLoadType, paging: IPagingState, ordering: IOrderingState, filterStore: IPatientsCareActivitiesFilterStore, columns: IDataGridColumnProps[]) {
        if (!this.patientId) {
            return;
        }

        const queryOptions = paging && ListQueryOptions.create({
            currentPage: paging.currentPage,
            pageSize: paging.pageSize,
            orderBy: ordering.columnId && [{ ascending: ordering.direction === "asc", fieldName: ordering.columnId as string }]
        });

        try {
            if (isNullOrUndefined(filterStore.careActivitySource) || filterStore.careActivitySource.value === "HunEHealthInfrastructure") {
                const ehiServiceCallStatus = await this._ehiCareService.setEhiAccessDataToExtensionDataAsync(this.patientId, this.internalExtensionData, EhiUserType.Doctor);
                if (ehiServiceCallStatus !== EhiServiceCallStatus.Success) {
                    return;
                }
            }

            const extensionData = filterStore.getExtensionData();
            if (!!extensionData) {
                _.merge(this.internalExtensionData, extensionData);
            }
            
            const data = await this.patientsCareActivitiesApiAdapter.searchPatientCareActivities(
                this.patientId,
                filterStore.episodeOfCareIds,
                filterStore.practitionerIds,
                filterStore.pointOfCareIds,
                filterStore.dateRange || new LocalDateRange(),
                filterStore.careActivitySource,
                filterStore.careTypeIds,
                filterStore.careActivityStates,
                filterStore.careIdentifier,
                queryOptions,
                this.internalExtensionData
            );

            if (data.operationWasSuccessful) {
                await this.loadPrimaryDocumentsInfoAsync(data.value.results);
                await this.loadReferenceDataAsync(data.value.results);

                this.setExtensionData(data.value.extensionData);

                this.setState({
                    items: data.value.results,
                    totalCount: data.value.totalCount
                }, paging, ordering, filterStore);
                this.setIsPermissionFiltered(data.value.isFiltered);
            }
        } catch (err) {
            if (err instanceof ApiBusinessError && err.error instanceof NoEhiCompatibleIdentifierFoundError) {
                throw new DataLoadError(
                    err.error.name,
                    StaticHunEHealthInfrastructureCareResources.CommonEhiMessage.NoEhiCompatibleIdentifierFoundError
                );
            }

            throw err;
        }
    }

    @State.bound
    private async loadPrimaryDocumentsInfoAsync(items: PatientsCareActivityStore[]) {
        for (const i of items) {
            if (i.careActivityId) {
                const doc = await this.careDocumentApiAdapter.getSingleDocumentForCareActivityAsync(i.careActivityId, null, true, false);

                if (doc.value?.id) {
                    i.primaryDocumentId = doc.value.id;
                }
            }
        }
    }

    private isCareActivityFromEhi(item: PatientsCareActivityStore) {
        return ValueWrapper.equals(item.careActivitySource, new CareActivitySource("HunEHealthInfrastructure"));
    }

    @State.bound
    private async loadReferenceDataAsync(items: PatientsCareActivityStore[]) {
        if (!items) {
            return;
        }

        const practitionerIds: PractitionerId[] = [];
        const externalLocationIds: Array<IEntityVersionSelector<ExternalLocationId>> = [];
        const pointOfCareIds: PointOfCareId[] = [];
        const conditions: Array<IEntityVersionSelector<ConditionId>> = [];
        const ehiHealthcareProviderOrganizationUnitIds: EhiHealthcareProviderOrganizationUnitId[] = [];

        items.forEach(item => {
            if (item.primaryParticipantId) {
                practitionerIds.push(item.primaryParticipantId);
            }
            if (item.organizationUnitId && item.organizationUnitId) {
                pointOfCareIds.push(item.organizationUnitId);
            }
            if (item.referralDoctorId) {
                practitionerIds.push(item.referralDoctorId);
            }
            if (item.referralWorkplaceId) {
                externalLocationIds.push(item.referralWorkplaceId);
            }
            
            if (this.isCareActivityFromEhi(item)) {
                if (!isNullOrUndefined(item.extensionData?.ConditionId)) {
                    const conditionId = ConditionId.fromJS(item.extensionData.ConditionId);
                    conditions.push({ id: conditionId, validOn: LocalDate.today() });
                }
                if (!isNullOrUndefined(item.extensionData?.CareOrganizationUnitId)) {
                    const careOrgUnit = EhiHealthcareProviderOrganizationUnitId.fromJS(item.extensionData.CareOrganizationUnitId);
                    ehiHealthcareProviderOrganizationUnitIds.push(careOrgUnit);
                }
                if (!isNullOrUndefined(item.extensionData?.ReferralOrganizationUnitId)) {
                    const referralOrgUnit = EhiHealthcareProviderOrganizationUnitId.fromJS(item.extensionData.ReferralOrganizationUnitId);
                    ehiHealthcareProviderOrganizationUnitIds.push(referralOrgUnit);
                }
            } else {
                const condition = item.diagnoses?.find(i => ValueWrapper.equals(i.use, DiagnosisRoleId.Discharge))?.conditionVersionSelector;
                if (!isNullOrUndefined(condition)) {
                    conditions.push(condition);
                }
            }
        });

        await Promise.all([
            this.organizationReferenceDataStore.doctorMap.ensureLoadedAsync(practitionerIds),
            this.organizationReferenceDataStore.practitionerMap.ensureLoadedAsync(practitionerIds),
            this.organizationReferenceDataStore.externalLocationStore.ensureLoadedAsync(externalLocationIds),
            this.organizationReferenceDataStore.allPointsOfCareMap.ensureLoadedAsync(pointOfCareIds),
            this.careReferenceDataStore.condition.ensureLoadedAsync(conditions),
            this.hunEhiCareReferenceDataStore.ehiHealthcareProviderOrganizationUnits.ensureLoadedAsync(ehiHealthcareProviderOrganizationUnitIds)
        ]);
        let healthcareProfessionIds = _.flatten(pointOfCareIds.map(item => this.organizationReferenceDataStore.allPointsOfCareMap.get(item).healthcareProfessionIds));
        healthcareProfessionIds = _.uniqBy(healthcareProfessionIds, item => item.value);
        await this.organizationReferenceDataStore.healthCareProfession.ensureLoadedAsync(healthcareProfessionIds);
    }
}
