import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import UserManagementDataProviderStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/UserManagementDataProviderStore";
import UserId from "@Primitives/UserId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import { wrappedValuesAreEquals } from "@HisPlatform/Common/ValueWrapperHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import UserWithIdentifiers from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/UserWithIdentifiers";
import { iconNameType } from "@CommonControls/Icon";
import _ from "@HisPlatform/Common/Lodash";
import { IMultiEntitySelectorPublicProps } from "@CommonControls/MultiEntitySelectorBase";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";

interface IUsersWithIdentifiersMultiSelectorDependencies {
    userManagementDataProviderStore: UserManagementDataProviderStore;
}

interface IUsersWithIdentifiersMultiSelectorProps extends IMultiEntitySelectorPublicProps<UserId, UserWithIdentifiers> {
    _dependencies?: IUsersWithIdentifiersMultiSelectorDependencies;
    selectedUserIds: UserId[];
    setUserIds: (userIds: UserId[]) => void;
}

@State.observer
class UsersWithIdentifiersMultiSelector extends React.Component<IUsersWithIdentifiersMultiSelectorProps> {

    private userMap = new InMemoryDataGridDataSource(() => {
        return this.dependencies.userManagementDataProviderStore.usersWithIdentifiers.items.filter(
            item => !this.props.selectedUserIds.some(id => wrappedValuesAreEquals(id, item.id)));
    });

    private selectedUsers = new InMemoryDataGridDataSource(() => {
        return this.dependencies.userManagementDataProviderStore.usersWithIdentifiers.items.filter(
            item => this.props.selectedUserIds.some(id => wrappedValuesAreEquals(id, item.id)));
    });

    private get dependencies() {
        return this.props._dependencies;
    }

    @State.bound
    private addUser(user: UserWithIdentifiers) {
        const userIds = this.props.selectedUserIds.concat(user.id);
        this.props.setUserIds(userIds);
    }

    @State.bound
    private removeUser(user: UserWithIdentifiers) {
        const userIds = this.props.selectedUserIds.except([user.id], (one, other) => wrappedValuesAreEquals(one, other));
        this.props.setUserIds(userIds);
    }

    public render() {
        return (
            <Ui.MultiEntitySelectorBase
                {...this.props}
                orientation="horizontal"
                pageSize={10}
                renderColumns={this.renderAllItemsColumn}
                renderSelectedColumns={this.renderChosenItemsColumn}
                allItemsDataSource={this.userMap}
                selectedItemsDataSource={this.selectedUsers}
                onAdded={this.addUser}
                onRemoved={this.removeUser}
                generateInitialFilterStore
                fixedHeight="calc(100vh - 347px)"
                changeOnMount

            />
        );
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.reloadAsync(), this);
    }

    @State.bound
    private async reloadAsync() {
        await this.dependencies.userManagementDataProviderStore.usersWithIdentifiers.ensureAllLoadedAsync();
    }

    @State.bound
    private renderDisplayName(value: any, row: UserWithIdentifiers) {
        const identifiers = row.identifiers.length ? _.join([row.user.loginName].concat(row.identifiers), ", ") : row.user.loginName;
        return (
            <>
                <Ui.Icon iconName={row.iconName as iconNameType} style={{ marginRight: 5 }} />
                {`${row.displayName} (${identifiers})`}
            </>);
    }

    @State.action.bound
    private renderAllItemsColumn() {
        return this.renderColumns(this.props.allItemsTableTitle ? this.props.allItemsTableTitle : "");
    }

    @State.action.bound
    private renderChosenItemsColumn() {
        return this.renderColumns(this.props.chosenItemsTableTitle ? this.props.chosenItemsTableTitle : "");
    }

    private getUserFilter(value: any, row: UserWithIdentifiers) {
        const result = `${row.displayName}, ${row.identifiersWithLoginNameString}`;
        return result;
    }

    @State.bound
    private renderColumns(header: string) {
        return (
            <DataGridColumn
                dataGetter={"displayName"}
                onRenderCellValue={this.renderDisplayName}
                header={header}
                clientSideOrderableValueGetter={"displayName"}
                clientSideFilterableValueGetter={this.getUserFilter}
                isFilterable
                isOrderable
                width="100%"
                id={0}
            />
        );
    }
}

export default connect(
    UsersWithIdentifiersMultiSelector,
    new DependencyAdapter<IUsersWithIdentifiersMultiSelectorProps, IUsersWithIdentifiersMultiSelectorDependencies>(container => {
        return {
            userManagementDataProviderStore: container.resolve("UserManagementDataProviderStore")
        };
    })
);
