import Di from "@Di";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import moment from "moment";
import RiskAssessmentStore from "./RiskAssessmentStore";
import RiskAssessmentId from "@Primitives/RiskAssessmentId.g";
import ReferencedLifeStyleRiskAssignment from "./ReferencedLifeStyleRiskAssignment";
import RiskAssessmentHistoryItem from "./RiskAssessmentHistoryItem";
import RiskDetail from "./RiskDetail";

@Di.injectable()
export default class RiskAssessmentFactory {

    public createNewStore() {
        const store = new RiskAssessmentStore(true);
        store.id = new RiskAssessmentId("new");
        store.riskAssignment = new ReferencedLifeStyleRiskAssignment(null);
        store.historyItems.push(this.createNewHistoryItem());
        store.currentItemIndex = 0;

        return store;
    }

    private createNewHistoryItem() {
        const historyItem = new RiskAssessmentHistoryItem();

        historyItem.isNew = true;
        historyItem.versionNumber = 1;
        historyItem.index = 0;
        historyItem.createdAt = moment(moment.now());        
        historyItem.historyItemDetail = new MedicalConditionHistoryItemDetail();
        historyItem.riskDetail = new RiskDetail("", "");

        return historyItem;
    }
}
