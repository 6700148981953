import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/CommonReferenceData/Api/Proxy.g";
import {CreateRequestId} from "@HisPlatform/Common/RequestHelper";
import Di from "@Di";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import {arrayIsNullOrEmpty} from "@Toolkit/CommonWeb/NullCheckHelpers";
import _ from "@HisPlatform/Common/Lodash";
import {createOperationInfo} from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import {buildQueryStringArray} from "@Toolkit/CommonWeb/QueryStringBuilder";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import {IIdentifierSystem} from "@HisPlatform/BoundedContexts/CommonReferenceData/Components/Controls/IdentifierSystemSelectBox/IIdentifierSystem";
import IdentifierSystem from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/IdentifierSystem";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class IdentifierSystemApiAdapter extends ApiAdapterBase {
    constructor(@Di.inject("ICommonReferenceDataClient") private client: Proxy.ICommonReferenceDataClient) {
        super();
    }

    @State.bound
    public getAllIdentifierSystemIdsAsync(): Promise<SimpleStore<IdentifierSystemId[]>> {
        return this.processOperationAsync(
            new SimpleStore<IdentifierSystemId[]>(),
            async target => {
                const result = await this.client.getAllIdentifierSystemIdsQueryAsync(CreateRequestId());
                target.value = result && result.ids || [];
                target.operationInfo = createOperationInfo(result);
            }
        );
    }

    @State.bound
    public getAllIdentifierSystemsAsync(): Promise<SimpleStore<IdentifierSystem[]>> {
        return this.processOperationAsync(
            new SimpleStore<IdentifierSystem[]>(),
            async target => {
                const result = await this.client.getAllIdentifierSystemsQueryAsync(CreateRequestId());
                target.value = result && result.identifierSystems.map(item => {
                    const system = new IdentifierSystem();
                    system.id = item.id;
                    system.identifierTypeId = item.identifierTypeId;
                    return system;
                }) || [];
                target.operationInfo = createOperationInfo(result);
            }
        );
    }

    @State.bound
    public getIdentifierSystemsByIdsAsync(ids: IdentifierSystemId[]): Promise<SimpleStore<IIdentifierSystem[]>> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {

                if (arrayIsNullOrEmpty(ids)) {
                    target.value = [];
                }

                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value));

                const response = await this.client.getIdentifierSystemsByIdsQueryAsync(CreateRequestId(), buildQueryStringArray(normalizedIds));

                target.operationInfo = createOperationInfo(response);
                target.value = response.identifierSystems.map(c => {
                    return {
                        id: c.id,
                        identifierTypeId: c.identifierTypeId,
                        validationRegex: c.validationRegex
                    } as IIdentifierSystem;
                });
            }
        );
    }
}
