import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import IDocumentFragment from "@CommonControls/DocumentEditor/IDocumentFragment";

export interface IDocumentSnippetSelectorDialogParams extends IModalParams {
    displayMode?: "inline" | "modal";
    documentSnippetCategoryId?: TextBlockTypeId;

}
export interface IDocumentSnippetSelectorDialogResult {
    selected: IDocumentFragment;
}
export default class DocumentSnippetSelectorDialogParams implements IDocumentSnippetSelectorDialogParams {

    public static type = "DocumentSnippetSelectorDialogParams";
    public get type() { return DocumentSnippetSelectorDialogParams.type; }

    constructor(
        public readonly displayMode?: "inline" | "modal",
        public readonly documentSnippetCategoryId?: TextBlockTypeId

    ) { }

}