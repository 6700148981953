import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCases from "@Primitives/UseCases";
import IUseCaseDescriptor2 from "@PluginInterface/UseCases/IUseCaseDescriptor2";
import AppointmentScheduleDefinitionId from "@Primitives/AppointmentScheduleDefinitionId.g";
import AppointmentScheduleDefinitionDetailPanel, {IAppointmentScheduleDefinitionDetailPanelProps} from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/Detail/AppointmentScheduleDefinitionDetailPanel";

export default function configureAppointmentScheduleDefinitionUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2<IAppointmentScheduleDefinitionDetailPanelProps>(
        {
            identifiers: [UseCases.editAppointmentScheduleDefinition],
            component: AppointmentScheduleDefinitionDetailPanel,
            mapUseCaseArgumentsToProps: args => {
                return {
                    appointmentScheduleDefinitionId: args.find(a => a.value instanceof AppointmentScheduleDefinitionId).value
                };
            }
        } as IUseCaseDescriptor2<IAppointmentScheduleDefinitionDetailPanelProps>
    );
}
