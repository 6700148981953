import React from "react";
import { IReportingClient } from "@HisPlatform/BoundedContexts/Reporting/Api/Proxy.g";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import PatientContext from "@HisPlatform/Model/DomainModel/PatientContext/PatientContext";
import IWidgetComponentProps from "@PluginInterface/Dashboard/IWidgetComponentProps";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ConditionId from "@Primitives/ConditionId.g";
import DiagnosisId from "@Primitives/DiagnosisId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import * as Ui from "@CommonControls";
import DiagnosisFrequencyDataContainer from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/DiagnosesFrequencyWidget/DiagnosisFrequencyDataContainer";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import PatientId from "@Primitives/PatientId.g";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import { VoidSyncEvent } from "ts-events";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import ReportingApiAdapter from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/ReportingApiAdapter";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";

interface IDiagnosesFrequencyWidgetDependencies {
    reportingApiAdapter: ReportingApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
    localizationService: ILocalizationService;
}

interface IDiagnosesFrequencyWidgetProps extends IWidgetComponentProps {
    _dependencies?: IDiagnosesFrequencyWidgetDependencies;
    _patientId?: PatientId;
    _diagnosisListChangedEvent?: VoidSyncEvent;
}

export interface IDiagnosisFrequencyData {
    diagnosis: string;
    count: number;
    firstOccurrenceAt: string;
    lastOccurrenceAt: string;
}

/** @screen */
@State.observer
class DiagnosesFrequencyWidget extends React.Component<IDiagnosesFrequencyWidgetProps> {

    private get reportingApiAdapter() { return this.props._dependencies.reportingApiAdapter; }
    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }
    private get localizationService() { return this.props._dependencies.localizationService; }

    @State.observable.ref private dataSource: IDiagnosisFrequencyData[] = null;
    private ids: DiagnosisId[] = null;

    public componentDidMount() {
        this.loadSync();
        this.props._diagnosisListChangedEvent?.attach(this.loadSync);
    }

    public componentWillUnmount() {
        this.props._diagnosisListChangedEvent?.detach(this.loadSync);
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);

    @State.bound
    private loadSync() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.action.bound
    public async loadAsync() {
        await State.when(() => !!this.props._patientId);
        const response = await this.reportingApiAdapter.getDiagnosesFrequencyByPatientIdQueryAsync(this.props._patientId.value);

        this.ids = response.value.dtos.map(d => d.diagnosisId);
        await this.loadConditionsAsync(this.ids);

        State.runInAction(() => {
            this.dataSource = response.value.dtos.map(d => {
                return {
                    diagnosis: this.getDiagnosisDisplayValue(d.diagnosisId),
                    count: d.count,
                    firstOccurrenceAt: this.localizationService.localizeDate(LocalDate.createFromMoment(d.firstOccurrenceAt)),
                    lastOccurrenceAt: this.localizationService.localizeDate(LocalDate.createFromMoment(d.lastOccurrenceAt))
                } as IDiagnosisFrequencyData;
            });
        });
    }

    @State.bound
    private async loadConditionsAsync(ids: DiagnosisId[]) {
        const versionSelectors = ids.map(i => {
            return {
                id: i,
                validOn: LocalDate.today()
            } as IEntityVersionSelector<ConditionId>;
        });
        await this.careReferenceDataStore.condition.ensureLoadedAsync(versionSelectors);
    }

    @State.bound
    private getDiagnosisDisplayValue(diagnosisId: DiagnosisId) {
        const version = this.careReferenceDataStore.condition.get({
            id: diagnosisId,
            validOn: LocalDate.today()
        } as IEntityVersionSelector<ConditionId>);

        if (isNullOrUndefined(version)) {
            return "N/A";
        }

        return version.code + " - " + version.name;
    }

    public render() {

        return (
            <Ui.Dashboard.Widget
                name={this.props.name}
                isCloseByDefault={this.props.configuration && this.props.configuration.isCloseByDefault}
                icon={"notesMedicalSolid"}
                isCollapsible
                title={StaticProductivityResources.Widgets.DiagnosesFrequencyWidget.Title}
                containerHeight={this.props.configuration.InitialLayout.Height}
                automationId="DiagnosesFrequencyWidget"
            >
                <>
                    {this.initialLoadPanelAsync.isUnauthorizedAccess ?
                        <UnauthorizedAccessContent visualStyle="dark" /> :
                        <div style={{ padding: "0px", margin: "0px" }}>
                            {this.dataSource && this.dataSource.map(d => {
                                return (
                                    <DiagnosisFrequencyDataContainer
                                        key={d.diagnosis}
                                        data={d}
                                    />
                                );
                            })}
                        </div>
                    }
                </>
            </Ui.Dashboard.Widget>
        );
    }
}

export default connect(
    withLoadingBoundary(DiagnosesFrequencyWidget),
    new DependencyAdapter<IDiagnosesFrequencyWidgetProps, IDiagnosesFrequencyWidgetDependencies>(c => ({
        reportingApiAdapter: c.resolve("ReportingApiAdapter"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService")
    })),
    new PatientContextAdapter<IDiagnosesFrequencyWidgetProps>(c => ({
        _patientId: c.patientId
    })),
    new CareActivityContextAdapter((c, props) => ({
        _diagnosisListChangedEvent: c.diagnosisListChangedEvent
    }))
);