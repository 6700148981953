import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import PractitionerId from "@Primitives/PractitionerId.g";
import { DisplayMode } from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareColumn/PointOfCareColumn";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import AsyncMessageQueue from "@Toolkit/CommonWeb/AsyncMessageQueue";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { emptyObject, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import _ from "@HisPlatform/Common/Lodash";
import * as Ui from "@CommonControls";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import IPointOfCare from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/IPointOfCare";
import BookSlotDialogPractitionerSelectBox from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/BookAppointmentDialog/BookSlotDialogPractitionerSelectBox";

interface IBookSlotDialogPointOfCareSelectBoxDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IBookSlotDialogPointOfCareSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: IBookSlotDialogPointOfCareSelectBoxDependencies;
    explicitIds?: PointOfCareId[];
    reselectOnUpdate?: boolean;
    displayMode?: DisplayMode;
}

@State.observer
class BookSlotDialogPointOfCareSelectBox extends React.Component<IBookSlotDialogPointOfCareSelectBoxProps> {
    private readonly loadQueue = new AsyncMessageQueue(this.loadAsync);
    private readonly reloadQueue = new AsyncMessageQueue(this.reloadAsync);

    private get referenceDataStore() {
        return this.props._dependencies.organizationReferenceDataStore;
    }

    @State.observable.ref private items: Array<ISelectBoxItem<PointOfCareId>> = null;

    public componentDidMount() {
        dispatchAsyncErrors(this.loadQueue.enqueueAndProcessAsync(emptyObject), this);
    }

    public componentDidUpdate(prevProps: IBookSlotDialogPointOfCareSelectBoxProps) {
        if (!_.isEqual(prevProps.explicitIds, this.props.explicitIds)) {
            dispatchAsyncErrors(this.reloadQueue.enqueueAndProcessAsync(emptyObject), this);
        }
    }

    @State.bound
    private async loadAsync() {
        await this.initializeAsync();
        this.selectFirstItem();
    }

    @State.bound
    private async reloadAsync() {
        await this.initializeAsync();
        if (this.props.reselectOnUpdate && !this.items.some(i => _.isEqual(i.value, this.props.value))) {
            this.selectFirstItem();
        }
    }

    @State.bound
    private async initializeAsync() {
        const explicitIds = !isNullOrUndefined(this.props.explicitIds) ? [...this.props.explicitIds] : [];
        await this.referenceDataStore.pointOfCareMap.ensureLoadedAsync(explicitIds);
        this.initializeItems();
    }

    @State.action
    private initializeItems() {
        this.items = this.referenceDataStore.pointOfCareMap.items
            .filter(i => this.props.explicitIds.some(s => ValueWrapper.equals(s, i.id)))
            .map(this.toSelectBoxItem);
    }
    
    @State.bound
    private toSelectBoxItem(item: IPointOfCare) {
        return {
            text: this.getItemText(item),
            value: item.id
        } as ISelectBoxItem<PointOfCareId>;
    }

    @State.bound
    private getItemText(item: IPointOfCare) {
        switch (this.props.displayMode) {
            case "name":
                return item.name;
            case "shorthand-name":
                return `${item.shorthand} - ${item.name}`;
            case "shorthand":
            default:
                return item.shorthand;
        }
    }

    @State.bound
    private selectFirstItem() {
        if (this.items.length > 1) {
            this.props.onChange(this.items[0].value);
        }
    }
    public render() {
        return (
            <Ui.SelectBox
                {...this.props}
                loading={!this.items}
                items={this.items}
                autoSelectSingleItem
            />
        );
    }
}

export default connect(
    BookSlotDialogPointOfCareSelectBox,
    new DependencyAdapter<IBookSlotDialogPointOfCareSelectBoxProps, IBookSlotDialogPointOfCareSelectBoxDependencies>(c => {
        return {
            organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore")
        };
    })
);
