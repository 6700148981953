import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import React, { useCallback } from "react";
import * as Ui from "@CommonControls";
import Styles from "./ValidationResultSummary.less";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ResourceId from "@Primitives/ResourceId.g";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import memoizeOne from "memoize-one";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";

interface IValidationResultSummaryViewProps {
    results: IClientValidationProblem[];
    title: string;
    displayCount?: number;
    maxDisplayCount?: number;
    errorCount: number;
    warningCount: number;
    moreButtonHideLabel: string;
    moreButtonShowLabel: string;
    isOpen: boolean;
    setIsOpen: () => void;
    displayMode?: "summary" | "list";
    validText?: string;

    highlightedProblem?: IClientValidationProblem;
    setHighlightedProblem?: (id: IClientValidationProblem) => void;
    highlightableRules?: string[];
    infoRenderer?: (id: IClientValidationProblem) => React.ReactNode;

    selectedTabIndex: number;
    setSelectedTabIndex: (newValue: number) => void;

    messageLocalizator: (value: ResourceId) => string;
}

const renderListItems = (validationProblem: IClientValidationProblem, index: number, props: IValidationResultSummaryViewProps) => {
    const validationResultId = new ResourceId(validationProblem.message);
    const localizedMessage = validationProblem.rawMessage ?? props.messageLocalizator(validationResultId);
    const message = localizedMessage === "(missing)" && !!validationProblem.rawMessage ? validationProblem.message : localizedMessage;
    const parameters = validationProblem.resolvedParameters || validationProblem.parameters;

    const highlightSetter = props.setHighlightedProblem && memoizeOne(() => props.setHighlightedProblem(validationProblem));

    const infoTooltipContent = props?.infoRenderer?.(validationProblem);

    return (
        <li key={index} className={validationProblem === props.highlightedProblem ? Styles.bold : null}>
            {formatStringWithObjectParams(message, parameters)}
            {!!infoTooltipContent && <Ui.InfoButton tooltipContent={infoTooltipContent} tooltipMaxWidth="530px" />}
            {props.setHighlightedProblem && props.highlightableRules?.some(i => i === validationProblem?.ruleId) && <a onClick={highlightSetter}>{StaticWebAppResources.ValidationResultSummary.HighlightProblem}</a>}
        </li>
    );
};

const renderMoreButton = (props: IValidationResultSummaryViewProps) => {
    const text = props.isOpen ? props.moreButtonHideLabel : props.moreButtonShowLabel;
    const iconName = props.isOpen ? "chevronUp" : "chevronDown";
    return (
        <Ui.Button
            className={Styles.moreButton}
            text={text}
            iconName={iconName}
            visualStyle="link"
            onClick={props.setIsOpen}
            automationId="moreButton"
        />
    );
};

const renderSummaryBody = (props: IValidationResultSummaryViewProps, items: IClientValidationProblem[]) => {

    let resultSection: IClientValidationProblem[] = items;

    if (!isNullOrUndefined(props.displayCount) && !isNullOrUndefined(props.maxDisplayCount)) {
        const calculatedMaxValue = items.length < props.maxDisplayCount ? items.length : props.maxDisplayCount;
        const calculatedDisplayCount = items.length < props.displayCount ? items.length : props.displayCount;

        const calculateMax = items.length > props.maxDisplayCount ? items.slice(0, calculatedMaxValue) : items;
        resultSection = props.isOpen ? calculateMax : items.slice(0, calculatedDisplayCount);
    }

    return (
        <div className={Styles.errorsContainer}>
            <ul className={Styles.noPadding}>
                {resultSection.map((r, i) => renderListItems(r, i, props))}
            </ul>
            {props.displayCount < items.length
                && props.displayCount < props.maxDisplayCount
                && renderMoreButton(props)}
        </div>
    );
};


const ValidationResultSummaryView: React.SFC<IValidationResultSummaryViewProps> = (props) => {
    const hasError = props.errorCount > 0;
    const hasWarning = props.warningCount > 0;

    const classes = new CompositeClassName(Styles.container);
    const tabClassName = new CompositeClassName();
    const tabContentClassName = new CompositeClassName(Styles.tabContent);

    if (hasError || hasWarning) {
        if (hasError && !hasWarning) {
            classes.add(Styles.error);
            tabClassName.add(Styles.error);
            tabContentClassName.add(Styles.error);
        } else if (hasWarning && !hasError) {
            classes.add(Styles.warning);
            tabClassName.add(Styles.warning);
            tabContentClassName.add(Styles.warning);
        } else { // hasError && hasWarning
            classes.add(Styles.error);
            if (props.selectedTabIndex === 0) {
                tabClassName.add(Styles.error);
                tabContentClassName.add(Styles.error);
            } else {
                tabClassName.add(Styles.warning);
                tabContentClassName.add(Styles.warning);
            }
        }

        if (props.displayMode === "list") {
            return (
                <div className={classes.classNames}>
                    <div className={Styles.errorsContainer}>
                        <ul className={Styles.noPadding}>
                            {props.results.map((r, i) => renderListItems(r, i, props))}
                        </ul>
                    </div>
                </div>
            );
        } else if (props.displayMode === "summary") {

            return (
                <Ui.Tab
                    tabsGrow={false}
                    contentClassName={tabContentClassName.classNames}
                    activeIndex={props.selectedTabIndex}
                    onSelectedIndexChange={props.setSelectedTabIndex}>
                    {hasError && <Ui.TabPane className={Styles.error} title={<SpanWithIcon visualStyle="error" iconName="invalid_outlined_circle">{StaticWebAppResources.ValidationResultSummary.Errors}</SpanWithIcon>} automationId="errorTab">
                        {renderSummaryBody(props, props.results.filter(i => i.severity === "error"))}
                    </Ui.TabPane>}
                    {hasWarning && <Ui.TabPane className={Styles.warning} title={<SpanWithIcon visualStyle="warning" iconName="warning">{StaticWebAppResources.ValidationResultSummary.Warnings}</SpanWithIcon>} automationId="warningTab">
                        {renderSummaryBody(props, props.results.filter(i => i.severity === "warning"))}
                    </Ui.TabPane>}
                </Ui.Tab>
            );
        }
    }
        
    return null;
};

export default State.observer(ValidationResultSummaryView);