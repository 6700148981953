export default class Money {
    constructor(
        public readonly amount: number,
        public readonly currencyCode?: string
    ) { }

    public static areEquals(left: Money, right: Money): boolean {
        if ((left === null || left === undefined) && (right === null || right === undefined)) {
            return true;
        }

        return left.amount === right.amount && left.currencyCode === right.currencyCode;
    }
}