import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import { getAddTherapyRecommendationControllerDto, getUpdateTherapyRecommendationControllerDto, getDeleteTherapyRecommendationControllerDto, getDeleteTherapyRecommendationHistoryItemControllerDto } from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/TherapyRecommendationDtoMapper";
import PatientId from "@Primitives/PatientId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import TherapyRecommendationStoreMapper from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/TherapyRecommendationStoreMapper";
import TherapyRecommendationId from "@Primitives/TherapyRecommendationId.g";
import TherapyRecommendationStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/TherapyRecommendation/TherapyRecommendationStore";
import { GetTypeNameWithPrefix } from "@HunEHealthInfrastructurePlugin/PluginHelper";

/* auto-inject-disable */
@Di.injectable()
export default class TherapyRecommendationApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("ITherapyRecommendationClient")) private apiClient: Proxy.ITherapyRecommendationClient,
        @Di.inject("TherapyRecommendationStoreMapper") private readonly storeMapper: TherapyRecommendationStoreMapper
    ) {
        super();
    }

    public getByIdAsync(therapyRecommendationId: TherapyRecommendationId, requestLock: boolean): Promise<TherapyRecommendationStore> {
        return this.processOperationAsync(
            new TherapyRecommendationStore(false),
            async target => {
                const response = await this.apiClient.getTherapyRecommendationByIdCommandAsync(
                    CreateRequestId(),
                    new Proxy.GetTherapyRecommendationByIdControllerDto({ therapyRecommendationId: therapyRecommendationId, requestLock: requestLock })
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public addTherapyRecommendationAsync(store: TherapyRecommendationStore, patientId: PatientId, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new TherapyRecommendationStore(false),
            async target => {
                const response = await this.apiClient.addTherapyRecommendationCommandAsync(
                    CreateRequestId(),
                    getAddTherapyRecommendationControllerDto(store, patientId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public updateTherapyRecommendationAsync(store: TherapyRecommendationStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new TherapyRecommendationStore(false),
            async target => {
                const response = await this.apiClient.updateTherapyRecommendationCommandAsync(
                    CreateRequestId(),
                    getUpdateTherapyRecommendationControllerDto(store, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deleteTherapyRecommendationHistoryItemAsync(store: TherapyRecommendationStore, versionNumber: number, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new TherapyRecommendationStore(false),
            async target => {
                const response = await this.apiClient.deleteTherapyRecommendationHistoryItemCommandAsync(
                    CreateRequestId(),
                    getDeleteTherapyRecommendationHistoryItemControllerDto(store, versionNumber, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deleteTherapyRecommendationAsync(store: TherapyRecommendationStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new SimpleStore<any>(),
            async target => {
                const response = await this.apiClient.deleteTherapyRecommendationCommandAsync(
                    CreateRequestId(),
                    getDeleteTherapyRecommendationControllerDto(store),
                    isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public validateAsync(store: TherapyRecommendationStore, patientId: PatientId) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response =
                    store.isNew ?
                        await this.apiClient.addTherapyRecommendationCommandAsync(CreateRequestId(), getAddTherapyRecommendationControllerDto(store, patientId, true)) :
                        await this.apiClient.updateTherapyRecommendationCommandAsync(CreateRequestId(), getUpdateTherapyRecommendationControllerDto(store, true, store.lockInfo?.lockId, false));

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    public async checkPermissionForAddNewItemAsync(store: TherapyRecommendationStore, patientId: PatientId): Promise<TherapyRecommendationStore> {
        return await this.addTherapyRecommendationAsync(store, patientId, true);
    }

    public async checkPermissionForUpdateItemAsync(store: TherapyRecommendationStore): Promise<TherapyRecommendationStore> {
        return await this.updateTherapyRecommendationAsync(store, true);
    }

    public async checkPermissionForDeleteItemAsync(store: TherapyRecommendationStore): Promise<SimpleStore> {
        return await this.deleteTherapyRecommendationAsync(store, true);
    }
}
