import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import SideMenu from "@CommonControls/SideMenu";
import SideMenuItem from "@CommonControls/SideMenu/SideMenuItem";
import PatientRoutes from "@HisPlatform/Components/Pages/Patient/PatientRoutes";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import PatientEHRRoutes from "@HisPlatform/Components/Pages/Patient/PatientEHRRoutes";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import PatientId from "@Primitives/PatientId.g";
import IEhiMedicalConditionMenuItemExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IEhiMedicalConditionMenuItemExtensionPointProps";
import ActionBoundSideMenuItem from "@Toolkit/ReactClient/ActionProcessing/ActionBoundSideMenuItem";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import PatientAppointmentRoutes from "@HisPlatform/Components/Pages/Patient/PatientAppointmentRoutes";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import ShowNewPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowNewPatientScreenAction.g";
import ShowPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientScreenAction.g";
import ShowPatientEpisodeOfCareListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowPatientEpisodeOfCareListScreenAction.g";
import ShowImmunizationListScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowImmunizationListScreenAction.g";
import ShowPatientRelatedDocumentListScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientRelatedDocumentListScreenAction.g";
import config from "@Config";
import Dashboard from "@CommonControls/Dashboard";
import PatientWidget from "@HisPlatform/Components/Widgets/PatientWidget/PatientWidget";
import { DashboardMode } from "@CommonControls/Dashboard/DashboardMode";
import Styles from "./PatientMainPage.less";
import IPrescriptionsSideMenuItemExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPrescriptionsSideMenuItemExtensionPointProps";
import IPatientInvoicesSideMenuItemExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientInvoicesSideMenuItemExtensionPointProps";

interface IPatientMainMenuDependencies {
    routingController: GlobalRoutingStore;
}

interface IPatientMainMenuProps {
    _dependencies?: IPatientMainMenuDependencies;
    _isFemale?: boolean;
    _patientId?: PatientId;
    _hasPatientContext?: boolean;
    _isNotValidatedPatient?: boolean;
    currentlyScrolledElementId: string;
    isNew: boolean;
}

@State.observer
class PatientMainMenu extends React.Component<IPatientMainMenuProps> {

    @State.observable.ref private mode: DashboardMode = null;

    @State.computed
    private get medicalConditionMenuItemExtensionProp() {
        return {
            isNew: this.props.isNew,
            patientId: this.props._patientId,
            routingController: this.props._dependencies.routingController
        } as IEhiMedicalConditionMenuItemExtensionPointProps;
    }

    @State.computed
    public get showEpisodeOfCareListScreenAction() {
        return new ActionDescriptor(
            new ShowPatientEpisodeOfCareListScreenAction(
                ScreenDisplayMode.Full,
                this.props._patientId),
            null,
            []
        );
    }

    @State.computed
    public get showImmunizationListScreenAction() {
        return new ActionDescriptor(
            new ShowImmunizationListScreenAction(
                ScreenDisplayMode.Full,
                this.props._patientId),
            null,
            []
        );
    }

    @State.computed
    public get showPatientScreenAction() {
        return new ActionDescriptor(
            new ShowPatientScreenAction(
                ScreenDisplayMode.Full,
                this.props._patientId,
                null),
            null,
            []
        );
    }

    @State.computed
    public get showNewPatientScreenAction() {
        return new ActionDescriptor(
            new ShowNewPatientScreenAction(
                ScreenDisplayMode.Full),
            null,
            []
        );
    }

    @State.computed
    public get showPatientRelatedDocumentListScreenAction() {
        return new ActionDescriptor(
            new ShowPatientRelatedDocumentListScreenAction(
                ScreenDisplayMode.Full,
                this.props._patientId,
                false),
            null,
            []
        );
    }

    @State.computed
    public get currentRouteDefinition() {
        return this.props._dependencies.routingController.currentRoute?.definition;
    }

    public componentDidMount() {
        this.setupMode();
    }

    @State.bound
    public navigateToPatientsSurgeries() {
        if (!this.props._patientId) {
            return;
        }

        this.props._dependencies.routingController.push(
            PatientRoutes.surgeryForPatient.makeRoute({
                selectedId: "null",
                patientId: this.props._patientId.value,
                appointmentId: "null",
                mode: "read-write",
                useCase: null
            })
        );
    }

    @State.bound
    public navigateToDeviceUseStatements() {
        if (!this.props._patientId) {
            return;
        }

        this.props._dependencies.routingController.push(
            PatientRoutes.deviceUseStatement.makeRoute({
                patientId: this.props._patientId.value,
                appointmentId: "null",
                mode: "read-write"
            })
        );
    }

    @State.bound
    public navigateToPregnancyStatements() {
        if (!this.props._patientId) {
            return;
        }

        this.props._dependencies.routingController.push(
            PatientRoutes.pregnancyStatement.makeRoute({
                patientId: this.props._patientId.value,
                appointmentId: "null",
                mode: "read-write"
            })
        );
    }

    @State.bound
    public navigateToMedicationStatements() {
        if (!this.props._patientId) {
            return;
        }

        this.props._dependencies.routingController.push(
            PatientRoutes.medicationStatement.makeRoute({
                patientId: this.props._patientId.value,
                appointmentId: "null",
                mode: "read-write"
            })
        );
    }

    @State.bound
    public navigateToRiskAssessments() {
        if (!this.props._patientId) {
            return;
        }

        this.props._dependencies.routingController.push(
            PatientRoutes.riskAssessment.makeRoute({
                patientId: this.props._patientId.value,
                appointmentId: "null",
                mode: "read-write"
            })
        );
    }

    @State.bound
    public navigateToAutonomyDisabilityStatements() {
        if (!this.props._patientId) {
            return;
        }

        this.props._dependencies.routingController.push(
            PatientRoutes.autonomyDisabilityStatement.makeRoute({
                patientId: this.props._patientId.value,
                appointmentId: "null",
                mode: "read-write"
            })
        );
    }

    @State.bound
    public navigateToMedicalAlertStatements() {
        if (!this.props._patientId) {
            return;
        }

        this.props._dependencies.routingController.push(
            PatientRoutes.medicalAlertStatement.makeRoute({
                patientId: this.props._patientId.value,
                appointmentId: "null",
                mode: "read-write"
            })
        );
    }

    @State.bound
    private navigateToPatientsCareActivities() {

        this.props._dependencies.routingController.push(PatientEHRRoutes.careActivities.makeRoute({ patientId: this.props._patientId.value, mode: "read-only", appointmentId: "null" }));
    }

    @State.bound
    private navigateToPatientsDocuments() {

        this.props._dependencies.routingController.push(PatientEHRRoutes.documents.makeRoute({ patientId: this.props._patientId.value, mode: "read-only", appointmentId: "null" }));
    }

    @State.bound
    private navigateToPatientsServiceRequests() {

        this.props._dependencies.routingController.push(PatientEHRRoutes.serviceRequests.makeRoute({ patientId: this.props._patientId.value, mode: "read-only", appointmentId: "null" }));
    }

    @State.bound
    private navigateToPatientsPrescriptions() {

        this.props._dependencies.routingController.push(PatientEHRRoutes.prescriptions.makeRoute({ patientId: this.props._patientId.value, mode: "read-only", appointmentId: "null" }));
    }

    @State.bound
    public navigateToPatientAllergyIntolerances() {
        if (!this.props._patientId) {
            return;
        }

        this.props._dependencies.routingController.push(
            PatientRoutes.patientAllergyIntolerance.makeRoute({
                patientId: this.props._patientId.value,
                appointmentId: "null",
                mode: "read-write"
            })
        );
    }

    @State.bound
    public navigateToImmunizations() {
        if (!this.props._patientId) {
            return;
        }

        this.props._dependencies.routingController.push(
            PatientRoutes.immunization.makeRoute({
                patientId: this.props._patientId.value,
                appointmentId: "null",
                mode: "read-write"
            })
        );
    }

    @State.bound
    public navigateToCurrentConditionStatements() {
        if (!this.props._patientId) {
            return;
        }

        this.props._dependencies.routingController.push(
            PatientRoutes.conditionStatement.makeRoute({
                patientId: this.props._patientId.value,
                appointmentId: "null",
                mode: "read-write",
                clinicalStatus: "active",
                elementId: "currentConditionStatements"
            })
        );
    }

    @State.bound
    public navigateToFormerConditionStatements() {
        if (!this.props._patientId) {
            return;
        }

        this.props._dependencies.routingController.push(
            PatientRoutes.conditionStatement.makeRoute({
                patientId: this.props._patientId.value,
                appointmentId: "null",
                mode: "read-write",
                clinicalStatus: "inactive",
                elementId: "formerConditionStatements"
            })
        );
    }

    @State.bound
    public navigateToProcedureStatements() {
        if (!this.props._patientId) {
            return;
        }

        this.props._dependencies.routingController.push(
            PatientRoutes.procedureStatement.makeRoute({
                patientId: this.props._patientId.value,
                appointmentId: "null",
                mode: "read-write"
            })
        );
    }

    @State.bound
    public navigateToPatientsAppointments() {

        this.props._dependencies.routingController.push(
            PatientAppointmentRoutes.appointmentList.makeRoute({
                patientId: this.props._patientId.value,
                appointmentId: "null",
                mode: "read-write"
            })
        );
    }

    @State.action
    private setMode(mode: DashboardMode) {
        this.mode = mode;
        localStorage.setItem("dashboard_mode", this.mode);
    }

    @State.action.bound
    private toggleMode() {
        this.setMode(this.mode === "small" ? "normal" : "small");
    }

    private setupMode() {
        let localMode = localStorage.getItem("dashboard_mode");
        if (!localMode) {
            localStorage.setItem("dashboard_mode", "normal");
            localMode = "normal";
        }
        this.setMode(localMode as DashboardMode);
    }

    public render() {

        const prescriptionsSideMenuItemExtensionPointProps: IPrescriptionsSideMenuItemExtensionPointProps = {
            isActive: this.currentRouteDefinition === PatientEHRRoutes.prescriptions,
            text: StaticWebAppResources.DailyPatientList.Label.MenuItem.EHR.Prescriptions,
            onClick: this.navigateToPatientsPrescriptions,
            disabled: this.props.isNew
        };

        const patientInvoicesSideMenuItemExtensionPointProps: IPatientInvoicesSideMenuItemExtensionPointProps = {
            isNew: this.props.isNew,
            patientId: this.props._patientId
        };

        return (
            <Dashboard orientation="vertical" mode={this.mode} onToggleMode={this.toggleMode} noGap>
                <PatientWidget key="patientWidget" />
                {(this.props._hasPatientContext) && <div className={Styles.separator}></div>}

                <ActionBoundSideMenuItem
                    iconName="person"
                    text={StaticWebAppResources.PatientBaseData.Menu.PatientData}
                    automationId="__administrativeData"
                    actionDescriptor={this.props.isNew ? this.showNewPatientScreenAction : this.showPatientScreenAction} />

               { // removed whole Eprofil from side menu HISP-13059
               false && <SideMenuItem iconName="healthcare" text={StaticWebAppResources.PatientBaseData.Menu.MedicalConditions} automationId="__medicalConditions">

                    <SideMenuItem
                        isActive={this.currentRouteDefinition === PatientRoutes.patientAllergyIntolerance}
                        automationId="__allergyIntolerance"
                        iconName="allergy"
                        text={StaticWebAppResources.PatientBaseData.Menu.Allergies}
                        onClick={this.navigateToPatientAllergyIntolerances}
                        disabled={this.props.isNew}
                        permissionCheckOperationNames="ViewPatientAllergyIntolerances"
                        permissionDeniedStyle="disabled"
                    />
                    <SideMenuItem
                        isActive={this.currentRouteDefinition === PatientRoutes.medicalAlertStatement}
                        automationId="__medicalAlertStatement"
                        iconName="warning"
                        text={StaticWebAppResources.PatientBaseData.Menu.MedicalAlertStatements}
                        onClick={this.navigateToMedicalAlertStatements}
                        disabled={this.props.isNew}
                        permissionCheckOperationNames="ViewPatientMedicalConditions"
                        permissionDeniedStyle="disabled"
                    />
                    <ActionBoundSideMenuItem
                        automationId="__immunization"
                        actionDescriptor={this.showImmunizationListScreenAction}
                        disabled={this.props.isNew}
                    />
                    <SideMenuItem
                        isActive={this.currentRouteDefinition === PatientRoutes.procedureStatement}
                        automationId="__procedureStatement"
                        iconName="editService"
                        text={StaticWebAppResources.PatientBaseData.Menu.ProcedureStatements}
                        onClick={this.navigateToProcedureStatements}
                        disabled={this.props.isNew}
                        permissionCheckOperationNames="ViewPatientMedicalConditions"
                        permissionDeniedStyle="disabled"
                    />
                    <SideMenuItem
                        isActive={this.currentRouteDefinition === PatientRoutes.autonomyDisabilityStatement}
                        automationId="__autonomyDisabilityStatement"
                        iconName="psychiatry"
                        text={StaticWebAppResources.PatientBaseData.Menu.AutonomyDisabilityStatements}
                        onClick={this.navigateToAutonomyDisabilityStatements}
                        disabled={this.props.isNew}
                        permissionCheckOperationNames="ViewPatientMedicalConditions"
                        permissionDeniedStyle="disabled"
                    />
                    <SideMenuItem
                        isActive={this.currentRouteDefinition === PatientRoutes.deviceUseStatement}
                        automationId="__deviceUseStatement"
                        iconName="heartbeat"
                        text={StaticWebAppResources.PatientBaseData.Menu.DeviceUseStatements}
                        onClick={this.navigateToDeviceUseStatements}
                        disabled={this.props.isNew}
                        permissionCheckOperationNames="ViewPatientMedicalConditions"
                        permissionDeniedStyle="disabled"
                    />
                    <SideMenuItem
                        isActive={this.currentRouteDefinition === PatientRoutes.conditionStatement && this.props.currentlyScrolledElementId === "currentConditionStatements"}
                        automationId="__currentConditionStatement"
                        iconName="text_template"
                        text={StaticWebAppResources.PatientBaseData.Menu.CurrentConditionStatements}
                        onClick={this.navigateToCurrentConditionStatements}
                        disabled={this.props.isNew}
                        permissionCheckOperationNames="ViewPatientMedicalConditions"
                        permissionDeniedStyle="disabled"
                    />
                    <SideMenuItem
                        isActive={this.currentRouteDefinition === PatientRoutes.conditionStatement && this.props.currentlyScrolledElementId === "formerConditionStatements"}
                        automationId="__formerConditionStatement"
                        iconName="text_template_past"
                        text={StaticWebAppResources.PatientBaseData.Menu.FormerConditionStatements}
                        onClick={this.navigateToFormerConditionStatements}
                        disabled={this.props.isNew}
                        permissionCheckOperationNames="ViewPatientMedicalConditions"
                        permissionDeniedStyle="disabled"
                    />
                    {this.props._isFemale && <SideMenuItem
                        isActive={this.currentRouteDefinition === PatientRoutes.pregnancyStatement}
                        automationId="__pregnancyStatement"
                        iconName="pregnant"
                        text={StaticWebAppResources.PatientBaseData.Menu.PregnancyStatements}
                        onClick={this.navigateToPregnancyStatements}
                        disabled={this.props.isNew}
                        permissionCheckOperationNames="ViewPatientMedicalConditions"
                        permissionDeniedStyle="disabled"
                    />}
                    <SideMenuItem
                        isActive={this.currentRouteDefinition === PatientRoutes.medicationStatement}
                        automationId="__medicationStatement"
                        iconName="pills"
                        text={StaticWebAppResources.PatientBaseData.Menu.MedicationStatements}
                        onClick={this.navigateToMedicationStatements}
                        disabled={this.props.isNew}
                        permissionCheckOperationNames="ViewPatientMedicalConditions"
                        permissionDeniedStyle="disabled"
                    />
                    <SideMenuItem
                        isActive={this.currentRouteDefinition === PatientRoutes.riskAssessment}
                        automationId="__riskAssessment"
                        iconName="patientWalking"
                        text={StaticWebAppResources.PatientBaseData.Menu.RiskAssessments}
                        onClick={this.navigateToRiskAssessments}
                        disabled={this.props.isNew}
                        permissionCheckOperationNames="ViewPatientMedicalConditions"
                        permissionDeniedStyle="disabled"
                    />
                    <HisPlatformExtensionPoint
                        type="medicalConditionMenuItemExtension"
                        extensionProps={this.medicalConditionMenuItemExtensionProp}>
                        <></>
                    </HisPlatformExtensionPoint>
                </SideMenuItem>
                }

                <SideMenuItem
                    isActive={this.currentRouteDefinition === PatientRoutes.appointmentList}
                    automationId="__appointments"
                    iconName="appointments"
                    text={StaticWebAppResources.PatientBaseData.Menu.Appointments}
                    onClick={this.navigateToPatientsAppointments}
                    disabled={this.props.isNew}
                    permissionCheckOperationNames="ViewPatientAppointments"
                    permissionDeniedStyle="disabled"
                />
                <ActionBoundSideMenuItem
                    automationId="__episodesOfCare"
                    actionDescriptor={this.showEpisodeOfCareListScreenAction}
                    disabled={this.props.isNew}
                />
                <ActionBoundSideMenuItem
                    automationId="__patientDocuments"
                    actionDescriptor={this.showPatientRelatedDocumentListScreenAction}
                    disabled={this.props.isNew}
                />
                <HisPlatformExtensionPoint
                    type="patientInvoicesPatientMainMenuItem"
                    extensionProps={patientInvoicesSideMenuItemExtensionPointProps}
                />
                <SideMenuItem
                    iconName="heartbeat"
                    isActive={this.currentRouteDefinition && this.currentRouteDefinition.parent === PatientRoutes.patientEhr}
                    text={StaticWebAppResources.DailyPatientList.Label.MenuItem.EHR.SubMenuTitle}
                    automationId="__eHR"
                >
                    <SideMenuItem
                        iconName="patientLife"
                        isActive={this.currentRouteDefinition === PatientEHRRoutes.careActivities}
                        text={StaticWebAppResources.DailyPatientList.Label.MenuItem.EHR.PatientsCareActivities}
                        onClick={this.navigateToPatientsCareActivities}
                        disabled={this.props.isNew}
                        automationId="__careActivities"
                        permissionCheckOperationNames="ViewPatientCareActivities"
                        permissionDeniedStyle="disabled"
                    />
                    <SideMenuItem
                        iconName="editDocument"
                        isActive={this.currentRouteDefinition === PatientEHRRoutes.documents}
                        text={StaticWebAppResources.DailyPatientList.Label.MenuItem.EHR.Documents}
                        onClick={this.navigateToPatientsDocuments}
                        disabled={this.props.isNew}
                        permissionCheckOperationNames="ViewPatientCareActivities"
                        permissionDeniedStyle="disabled"
                        automationId="__documents2"
                    />
                    <SideMenuItem
                        iconName="stethoscope"
                        isActive={this.currentRouteDefinition === PatientEHRRoutes.serviceRequests}
                        text={StaticWebAppResources.DailyPatientList.Label.MenuItem.EHR.RequestedServices}
                        onClick={this.navigateToPatientsServiceRequests}
                        disabled={this.props.isNew}
                        permissionCheckOperationNames="ViewPatientCareActivities"
                        permissionDeniedStyle="disabled"
                        automationId="__requestedServices"
                    />

                    <HisPlatformExtensionPoint
                        type="prescriptionsSideMenuItem"
                        extensionProps={prescriptionsSideMenuItemExtensionPointProps}
                    />
                     
                    {config.testFeatures.surgeryEnabled && <SideMenuItem
                        iconName="pills"
                        isActive={this.currentRouteDefinition === PatientRoutes.surgeryForPatient}
                        text={StaticWebAppResources.DailyPatientList.Label.MenuItem.Surgeries}
                        onClick={this.navigateToPatientsSurgeries}
                        disabled={this.props.isNew}
                        permissionCheckOperationNames="ViewPatientCareActivities"
                        permissionDeniedStyle="disabled"
                        automationId="__surgery"
                    />}
                </SideMenuItem>
            </Dashboard>
        );
    }
}

export default connect(
    PatientMainMenu,
    new DependencyAdapter<IPatientMainMenuProps, IPatientMainMenuDependencies>(c => ({
        routingController: c.resolve("GlobalRoutingStore"),
    })),
    new PatientContextAdapter<IPatientMainMenuProps>(c => ({
        _isFemale: c.patient?.baseData.isFemale,
        _patientId: c.patient?.id,
        _hasPatientContext: true,
        _isNotValidatedPatient: c.patient?.isDataCleansingNeeded ?? true
    }))
);
