import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import DependencyContainer from "@DiContainer";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import IServiceRequestDataService from "@HisPlatform/BoundedContexts/Care/Services/Definition/ServiceRequestManagement/IServiceRequestDataService";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import Di from "@Di";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import { appointmentBaseDataDtoConverter } from "@HisPlatform/BoundedContexts/Scheduling/NDataValueConverters/AppointmentBaseDataDtoConverter";
import { appointmentSubjectBaseDtoConverter } from "@HisPlatform/BoundedContexts/Scheduling/NDataValueConverters/AppointmentSubjectBaseDtoConverter";
import AppointmentStatusColumnData from "@HisPlatform/BoundedContexts/Scheduling/Components/AppointmentStatusColumn/AppointmentStatusColumnData";
import React from "react";
import PractitionerScheduleEntryTypeColumn from "@HisPlatform/BoundedContexts/Scheduling/Components/PractitionerScheduleEntryTypeColumn/PractitionerScheduleEntryTypeColumn";
import AppointmentStatusColumn from "@HisPlatform/BoundedContexts/Scheduling/Components/AppointmentStatusColumn/AppointmentStatusColumn";
import AppointmentBaseDataColumn from "@HisPlatform/BoundedContexts/Scheduling/Components/AppointmentBaseDataColumn/AppointmentBaseDataColumn";
import AppointmentSubjectColumn from "@HisPlatform/BoundedContexts/Scheduling/Components/AppointmentSubjectColumn/AppointmentSubjectColumn";
import AppointmentScheduleDefinitionColumn from "@HisPlatform/BoundedContexts/Scheduling/Components/AppointmentScheduleDefinitionColumn/AppointmentScheduleDefinitionColumn";
import AppointmentInvalidationReasonColumn from "@HisPlatform/BoundedContexts/Scheduling/Components/AppointmentInvalidationReasonColumn/AppopintmentInvalidationReasonColumn";
import AppointmentDateColumn from "@HisPlatform/BoundedContexts/Scheduling/Components/AppointmentDateColumn/AppointmentDateColumn";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import CannotDeleteAppointmentScheduleDefinitionError from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/CannotDeleteAppointmentScheduleDefinitionError";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";

@Di.injectable()
export default class SchedulingBoundedContext implements IBoundedContext {

    public initializeAsync(): Promise<void> {
        return Promise.resolve();
    }

    public configureNData(configurator: INDataAttributeConfigurator) {
        configurator.registerValueConverter("AppointmentBaseDataDto", appointmentBaseDataDtoConverter);
        configurator.registerValueConverter("AppointmentStatusDto", (rawValue: any) => {
            return new AppointmentStatusColumnData(rawValue.AppointmentStatus, rawValue.CancellationReasonNote);
        });
        configurator.registerValueConverter("AppointmentSubjectBaseDto", appointmentSubjectBaseDtoConverter);

        configurator.registerColumn("PractitionerScheduleEntryType", <PractitionerScheduleEntryTypeColumn />);
        configurator.registerColumn("AppointmentStatusDto", <AppointmentStatusColumn />);
        configurator.registerColumn("AppointmentBaseDataDto", <AppointmentBaseDataColumn />);
        configurator.registerColumn("AppointmentSubjectBaseDto", <AppointmentSubjectColumn />);
        configurator.registerColumn("AppointmentInvalidationReason", <AppointmentInvalidationReasonColumn />);
        configurator.registerColumn("AppointmentScheduleDefinitionId", <AppointmentScheduleDefinitionColumn />);
        configurator.registerColumnWithName("AppointmentScheduleEntryBased_DateInterval", <AppointmentDateColumn />);
    }

    public configureReferenceDataLoaders(referenceDataLoader: IReferenceDataLoader) {

        referenceDataLoader.register("AppointmentBaseDataDto", async (refs: any[]) => {
            const practitionerIdsToLoad = refs.map(s => s.practitionerId);
            const pointOfCareIdsToLoad = refs.map(s => s.pointOfCareId);

            const organizationReferenceDataStore: OrganizationReferenceDataStore = DependencyContainer.get("OrganizationReferenceDataStore");

            await organizationReferenceDataStore.practitionerMap.ensureLoadedAsync(practitionerIdsToLoad);
            await organizationReferenceDataStore.pointOfCareMap.ensureLoadedAsync(pointOfCareIdsToLoad);
            await loadSubjectServiceForAppointmentsAsync(refs);
        });

        referenceDataLoader.register("AppointmentSubjectBaseDto", async (refs: any[]) => {
            await loadSubjectServiceForAppointmentsAsync(refs);
        });

        const loadSubjectServiceForAppointmentsAsync = async (refs: any[]) => {
            const schedulingReferenceDatStore: SchedulingReferenceDataStore = DependencyContainer.get("SchedulingReferenceDataStore");
            const serviceRequestDataService: IServiceRequestDataService = DependencyContainer.get("IServiceRequestDataService");
            const careReferenceDataStore: CareReferenceDataStore = DependencyContainer.get("CareReferenceDataStore");

            const schedulingServiceIdsToLoad = refs.filter(r => r.subjectType === "SchedulingService").map(r => r.subject);
            await schedulingReferenceDatStore.schedulingServices.ensureLoadedAsync(schedulingServiceIdsToLoad);

            const serviceRequestRefsToLoad = refs.filter(r => r.subjectType === "ServiceRequest");

            const serviceRequests = await Promise.all(serviceRequestRefsToLoad.map(sr =>
                serviceRequestDataService.getServiceRequestByIdAsync(sr.subject.id, null, false, false)));

            const serviceRequestDefinitionVersionsToLoad = serviceRequests.map(sr => {
                const ref = serviceRequestRefsToLoad.find(r => r.subject.id.value === sr.id.value);
                ref.serviceRequestDefinitionId = sr.serviceRequestDefinitionVersion;
                ref.serviceRequestIdentifier = sr.serviceRequestIdentifier;

                return sr.serviceRequestDefinitionVersion;
            });

            await careReferenceDataStore.serviceRequestDefinition.ensureLoadedAsync(serviceRequestDefinitionVersionsToLoad);
        };
    }

    public configureMapper(mapper: IMapperConfigurator) {
        mapper.registerByName("CannotDeleteAppointmentScheduleDefinitionError", (businessError: any) => {
            const schedulingServiceIdsInUse = businessError.SchedulingServiceIdsInUse.map((i: any) => SchedulingServiceId.fromJS(i));
            return new CannotDeleteAppointmentScheduleDefinitionError(schedulingServiceIdsInUse);
        });
    }
}