import PatientId from "@Primitives/PatientId.g";
import * as CareProxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import PregnancyStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PregnancyStatement/PregnancyStatementStore";
import PregnancyStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PregnancyStatement/PregnancyStatementHistoryItem";
import { getHistoryItemDetailDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicalConditionDtoMapper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import CustomPregnancyOutcomeAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PregnancyStatement/CustomPregnancyOutcomeAssignment";
import ReferencedPregnancyOutcomeAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/PregnancyStatement/ReferencedPregnancyOutcomeAssignment";

export function getAddPregnancyStatementControllerDto(source: PregnancyStatementStore, patientId: PatientId, isValidateOnly: boolean) {
    return new CareProxy.AddPregnancyStatementControllerDto({
        patientId: patientId,
        expectedAt: source.expectedAt,
        historyItemDtos: [getPregnancyStatementHistoryItemDto(source.latestHistoryItem as PregnancyStatementHistoryItem)],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
    });
}

export function getUpdatePregnancyStatementControllerDto(source: PregnancyStatementStore, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.UpdatePregnancyStatementControllerDto({
        pregnancyStatementId: source.id,
        historyItemDtos: [getPregnancyStatementHistoryItemDto(source.latestHistoryItem as PregnancyStatementHistoryItem)],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeletePregnancyStatementHistoryItemControllerDto(source: PregnancyStatementStore, versionNumber: number, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.DeletePregnancyStatementHistoryItemControllerDto({
        pregnancyStatementId: source.id,
        versionNumbers: [versionNumber],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeletePregnancyStatementControllerDto(store: PregnancyStatementStore) {
    return new CareProxy.DeletePregnancyStatementControllerDto({
        pregnancyStatementId: store.id,
        lockId: store.lockInfo?.lockId,
        extensionData: store.extensionData,
        isValidateOnly: false,
        releaseLockIfSuccessful: true
    });
}

function getPregnancyStatementHistoryItemDto(historyItem: PregnancyStatementHistoryItem) {
    return new CareProxy.PregnancyStatementHistoryItemDto({
        versionNumber: historyItem.versionNumber,
        index: historyItem.index,
        createdAt: historyItem.createdAt,
        historyItemDetail: getHistoryItemDetailDto(historyItem.historyItemDetail),
        pregnancyOutcomeAssignment: getPregnancyOutcomeAssignmentDto(historyItem),
        extensionData: historyItem.extensionData
    });
}

function getPregnancyOutcomeAssignmentDto(source: PregnancyStatementHistoryItem) {
    let dto;
    const pregnancyOutcomeAssignment = source.pregnancyOutcomeAssignment;

    if (pregnancyOutcomeAssignment instanceof CustomPregnancyOutcomeAssignment) {
        dto = new CareProxy.CustomPregnancyOutcomeDto({
            textualObservationDto: new CareProxy.TextualObservationDto({
                patientId: pregnancyOutcomeAssignment.textualObservation.patientId,
                rowVersion: pregnancyOutcomeAssignment.textualObservation.rowVersion,
                careActivityId: pregnancyOutcomeAssignment.textualObservation.careActivityId,
                observationDefinitionId: pregnancyOutcomeAssignment.textualObservation.observationDefinitionId,
                observationId: pregnancyOutcomeAssignment.textualObservation.observationId,
                observedAt: source.createdAt,
                observerBy: source.historyItemDetail.asserter,
                contentId: pregnancyOutcomeAssignment.textualObservation.careContentId,
                stringContent: pregnancyOutcomeAssignment.textualObservation.contentStore.getContent()
            })
        });
    } else if (pregnancyOutcomeAssignment instanceof ReferencedPregnancyOutcomeAssignment) {
        dto = new CareProxy.ReferencedPregnancyOutcomeDto({
            categoryObservationDto: new CareProxy.CategoryObservationDto({
                patientId: pregnancyOutcomeAssignment.categoryObservation?.patientId,
                rowVersion: pregnancyOutcomeAssignment.categoryObservation?.rowVersion,
                careActivityId: pregnancyOutcomeAssignment.categoryObservation?.careActivityId,
                observationDefinitionId: pregnancyOutcomeAssignment.categoryObservation?.observationDefinitionId,
                observationId: pregnancyOutcomeAssignment.categoryObservation?.observationId,
                observedAt: source.createdAt,
                observerBy: source.historyItemDetail.asserter,
                categories: pregnancyOutcomeAssignment.categoryObservation?.categories?.map(c => new CareProxy.Category({ key: c.key, value: c.value }))
            })
        });
    }

    return dto;
}