import IComponentAdapter from "@Toolkit/ReactClient/Components/Connect/IComponentAdapter";
import PatientContext, { PatientReactContext } from "@HisPlatform/Model/DomainModel/PatientContext/PatientContext";
import { emptyObject } from "@Toolkit/CommonWeb/NullCheckHelpers";

export default class PatientContextAdapter<TProps> implements IComponentAdapter<TProps> {
    
    public get usedContexts(): Array<React.Context<any>> {
        return [PatientReactContext];
    }

    constructor(
        private readonly mapStateToProps: (careActivityContext: PatientContext, props: TProps) => Partial<TProps>
    ) {}

    public getMappedProps(props: TProps, contextValues: Map<React.Context<any>, any>): Partial<TProps> {
        const patientContext = contextValues.get(PatientReactContext) as PatientContext;
        if (!patientContext) {
            return emptyObject;
        }
        return this.mapStateToProps(patientContext, props);
    }
}