import Di from "@Di";
import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/Care/Api/Proxy.g";
import ContagiousPatientReport from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReport";
import ContagiousPatientReportId from "@Primitives/ContagiousPatientReportId.g";
import Address from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/Address";
import { mapEhiErrors } from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/EhiErrorHelper";
import OnsetReportData from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientOnsetReport";

type ResponseType = Proxy.GetContagiousPatientReportByIdCommandResponse | Proxy.UpdateContagiousPatientReportCommandResponse;

@Di.injectable()
export default class HunCareContagiousPatientReportStoreMapper extends LockingEntityStoreMapper<Proxy.ContagiousPatientReportItemDto, ContagiousPatientReport, ResponseType> {
    protected storeEntityIdType = ContagiousPatientReportId;

    protected vGetStoreIdentifier() {
        return "ContagiousPatientReport";
    }

    protected applyToStoreCore(target: ContagiousPatientReport, response: ResponseType): void {
        const ehiErrors = response instanceof Proxy.UpdateContagiousPatientReportCommandResponse ? response.ehiErrors : [];
        this.applyToOnsetReportItem(target, response.contagiousPatientReportItem, ehiErrors);
    }

    private applyToOnsetReportItem(target: ContagiousPatientReport, response: Proxy.ContagiousPatientReportItemDto, ehiErrors: Proxy.EhiErrorDto[]) {
        if (!response) {
            return;
        }
        
        target.id = response.contagiousPatientReportId;
        target.type = response.type;
        target.patientId = response.patientId;
        target.conditionId = response.conditionId;
        target.rowVersion = response.rowVersion;
        target.recordingPointOfCareId = response.recordingPointOfCareId;
        target.recordingCareActivityId = response.recordingCareActivityId;
        target.possibleActions = response.possibleActions;
        target.ehiErrors = mapEhiErrors(ehiErrors);
        target.state = response.state;

        if (response.onsetReportData) {
            target.onsetData.hospitalId = response.onsetReportData.hospitalId;
            target.onsetData.hospitalizationDate = response.onsetReportData.hospitalizationDate;
            target.onsetData.onsetDate = response.onsetReportData.onsetDate;
            target.onsetData.nursingLocationTypeId = response.onsetReportData.nursingLocationTypeId;
            target.onsetData.occupationId = response.onsetReportData.occupationId;
            target.onsetData.recordingPractitionerId = response.onsetReportData.recordingPractitionerId;
            target.onsetData.workPlaceName = response.onsetReportData.workplaceData?.name;
            target.onsetData.workPlaceLocation = new Address(
                response.onsetReportData.workplaceData?.address?.countryId,
                response.onsetReportData.workplaceData?.address?.settlement,
                response.onsetReportData.workplaceData?.address?.zipCode,
                response.onsetReportData.workplaceData?.address?.addressLine
            );
            target.onsetData.onsetLocation = new Address(
                response.onsetReportData.onsetLocation?.countryId,
                response.onsetReportData.onsetLocation?.settlement,
                response.onsetReportData.onsetLocation?.zipCode,
                response.onsetReportData.onsetLocation?.addressLine
            );
        }

        if (response.endReportData) {
            target.endReportData.contagiousPatientOnsetReportId = response.endReportData.contagiousPatientOnsetReportId;
            target.endReportData.endDate = response.endReportData.endDate;
            target.endReportData.endDiagnosisId = response.endReportData.endDiagnosisId;
            target.endReportData.outcomeTypeId = response.endReportData.endOutcomeTypeId;
        }
    }
}

export function applyToRelevantData(
    target: {reportData: OnsetReportData, id?: ContagiousPatientReportId},
    response: Proxy.GetRelevantContagiousPatientOnsetReportForNewEndReportQueryResponse) {
    target.reportData.hospitalId = response.onsetReportData.hospitalId;
    target.reportData.hospitalizationDate = response.onsetReportData.hospitalizationDate;
    target.reportData.nursingLocationTypeId = response.onsetReportData.nursingLocationTypeId;
    target.reportData.occupationId = response.onsetReportData.occupationId;
    target.reportData.onsetDate = response.onsetReportData.onsetDate;
    target.reportData.onsetLocation = new Address(
        response.onsetReportData.onsetLocation?.countryId,
        response.onsetReportData.onsetLocation?.settlement,
        response.onsetReportData.onsetLocation?.zipCode,
        response.onsetReportData.onsetLocation?.addressLine
    );
    target.reportData.recordingPointOfCareId = response.onsetReportData.recordingPractitionerId;
    target.reportData.recordingPractitionerId = response.onsetReportData.recordingPractitionerId;
    target.reportData.workPlaceLocation = new Address(
        response.onsetReportData.workplaceData.address?.countryId,
        response.onsetReportData.workplaceData.address?.settlement,
        response.onsetReportData.workplaceData.address?.zipCode,
        response.onsetReportData.workplaceData.address?.addressLine,
    );

    target.id = response.contagiousPatientReportId;
}
