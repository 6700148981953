import TelecomUseId from "@Primitives/TelecomUseId.g";
import TelecomTypeId from "@Primitives/TelecomTypeId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import PractitionerTelecomId from "@Primitives/PractitionerTelecomId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class PractitionerTelecomStore extends EntityStoreBase<PractitionerTelecomId> {

    constructor(telecomTypeId: TelecomTypeId = null) {
        super();
        this.telecomTypeId = telecomTypeId;
    }

    public telecomTypeId: TelecomTypeId = null;

    public get isEmail() {
        return this.telecomTypeId && this.telecomTypeId.value === TelecomTypeId.Email.value;
    }

    public get isPhone() {
        return this.telecomTypeId && this.telecomTypeId.value === TelecomTypeId.Phone.value;
    }

    public get isEmpty() {
        return (!this.telecomUseId || !this.telecomUseId.value) && (!this.value);
    }

    @State.observable.ref public telecomUseId: TelecomUseId = null;
    @State.observable public value: string = "";
    
    @State.action.bound
    public setTelecomUseId(telecomUseId: TelecomUseId) {
        this.telecomUseId = telecomUseId;
    }

    @State.action.bound
    public setValue(newValue: string) {
        this.value = newValue;
    }

    public static isPhone(store: PractitionerTelecomStore) {
        return store.isPhone;
    }

    public static isEmail(store: PractitionerTelecomStore) {
        return store.isEmail;
    }
}