import Di from "@Di";
import AutonomyDisabilityStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/AutonomyDisabilityStatement/AutonomyDisabilityStatementHistoryItem";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import ReferencedAutonomyDisabilityAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/AutonomyDisabilityStatement/ReferencedAutonomyDisabilityAssignment";
import AutonomyDisabilityStatementId from "@Primitives/AutonomyDisabilityStatementId.g";
import AutonomyDisabilityStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/AutonomyDisabilityStatement/AutonomyDisabilityStatementStore";
import moment from "moment";

@Di.injectable()
export default class AutonomyDisabilityStatementFactory {

    public createNewStore() {
        const store = new AutonomyDisabilityStatementStore(true);
        store.id = new AutonomyDisabilityStatementId("new");
        store.autonomyDisabilityAssignment = new ReferencedAutonomyDisabilityAssignment(null);
        store.historyItems.push(this.createNewHistoryItem());
        store.currentItemIndex = 0;

        return store;
    }

    private createNewHistoryItem() {
        const historyItem = new AutonomyDisabilityStatementHistoryItem();

        historyItem.isNew = true;
        historyItem.versionNumber = 1;
        historyItem.index = 0;
        historyItem.createdAt = moment(moment.now());
        historyItem.description = "";
        historyItem.historyItemDetail = new MedicalConditionHistoryItemDetail();

        return historyItem;
    }
}