import React from "react";
import Styles from "./PageTitle.less";
import PageTitleLabel from "@CommonControls/PageTitle/PageTitleLabel";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";

interface IPageTitleProps {
    title: React.ReactNode;
    padded?: boolean;
    unlimitedWidth?: boolean;
    widthMode?: "limited" | "unlimited" | "fullwidth";
}

export default class PageTitle extends React.PureComponent<IPageTitleProps> {

    public static defaultProps: Partial<IPageTitleProps> = {
        padded: true,
        widthMode: "limited"
    };

    
    public static Label = PageTitleLabel;

    public render() {
        const containerClassNames = new CompositeClassName(Styles.container);
        containerClassNames.addIf(this.props.padded, Styles.containerPadded);
        containerClassNames.addIf(!this.props.unlimitedWidth, Styles.limitedWidth);
        containerClassNames.addIf(this.props.widthMode === "limited", Styles.limitedWidth);
        containerClassNames.addIf(this.props.widthMode === "fullwidth", Styles.fullWidth);
        return (
            <div className={containerClassNames.classNames}>
                <div className={Styles.title}>{this.props.title}</div>
                {this.props.children && (
                    <div className={Styles.labelContainer}>
                        <div className={Styles.separator} />
                        {this.props.children}
                    </div>
                )}
            </div>
        );
    }
}