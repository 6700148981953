import { createNestedRoutes } from "@Toolkit/ReactClient/Routing/CreateNestedRoutes";
import PatientRoutes from "./PatientRoutes";
import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import IPatientAppointmentListRouteParams from "@HisPlatform/Application/Routes/IPatientAppointmentListRouteParams";


const PatientAppointmentRoutes = createNestedRoutes(PatientRoutes.appointmentList, {
    appointmentList: new RouteDefinition<IPatientAppointmentListRouteParams>("/:useCase?"),
});

export default PatientAppointmentRoutes;