import React from "react";
import * as Ui from "@CommonControls";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiEReferralCovidData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralCovidData";
import EhiEReferralCovidExaminationTypeSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralCovidExaminationTypeSelectBox";
import EhiEReferralCovidExaminationTypeId from "@Primitives/EhiEReferralCovidExaminationTypeId.g";
import EhiEReferralCovidContactExaminationReasonSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralCovidContactExaminationReasonSelectBox";
import EhiEReferralCovidScreeningExaminationReasonSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralCovidScreeningExaminationReasonSelectBox";
import {CountrySelector} from "@HisPlatformControls";
import EhiEReferralCovidContactExaminationReasonId from "@Primitives/EhiEReferralCovidContactExaminationReasonId.g";
import EhiEReferralCovidScreeningExaminationReasonId from "@Primitives/EhiEReferralCovidScreeningExaminationReasonId.g";

interface IEpidemiologicalDataViewViewProps {
    store: EhiEReferralCovidData;
}

@State.observer
export default class EpidemiologicalDataView extends React.Component<IEpidemiologicalDataViewViewProps> {

    private renderExtraData() {
        if (this.props.store.examinationTypeId?.value === EhiEReferralCovidExaminationTypeId.Sickness.value) {
            return (
                <Ui.DatePicker value={this.props.store.sicknessStartFrom}
                               onChange={this.props.store.setSicknessStartFrom}
                               label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.SicknessStartFrom}
                               propertyIdentifier="SicknessStartFrom"
                               automationId="__sicknessStartFrom"/>
            );
        } else if (this.props.store.examinationTypeId?.value === EhiEReferralCovidExaminationTypeId.Contact.value) {
            return (
                <EhiEReferralCovidContactExaminationReasonSelectBox value={this.props.store.contactExaminationReasonId}
                                                                    onChange={this.props.store.setContactExaminationReasonId}
                                                                    label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.ContactExaminationReasonId}
                                                                    propertyIdentifier="ContactExaminationReasonId"
                                                                    automationId="__contactExaminationReasonId"
                />
            );
        } else if (this.props.store.examinationTypeId?.value === EhiEReferralCovidExaminationTypeId.Screening.value) {
            return (
                <EhiEReferralCovidScreeningExaminationReasonSelectBox value={this.props.store.screeningExaminationReasonId}
                                                                      onChange={this.props.store.setScreeningExaminationReasonId}
                                                                      label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.ScreeningExaminationReasonId}
                                                                      propertyIdentifier="ScreeningExaminationReasonId"
                                                                      automationId="__screeningExaminationReasonId"
                />
            );
        } else {
            return (<></>);
        }
    }

    private renderReason() {
        if (this.props.store.examinationTypeId?.value === EhiEReferralCovidExaminationTypeId.Contact.value && 
            this.props.store.contactExaminationReasonId?.value === EhiEReferralCovidContactExaminationReasonId.Other.value) {
            return (
                <Ui.TextBox value={this.props.store.contactExaminationOtherReasonJustification}
                            onChange={this.props.store.setContactExaminationOtherReasonJustification}
                            label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.ContactExaminationOtherReasonJustification}
                            propertyIdentifier="ContactExaminationOtherReasonJustification"
                            automationId="__contactExaminationOtherReasonJustification"
                />
            );
        } else if (this.props.store.examinationTypeId?.value === EhiEReferralCovidExaminationTypeId.Screening.value && 
            this.props.store.screeningExaminationReasonId?.value === EhiEReferralCovidScreeningExaminationReasonId.Other.value) {
            return (
                <Ui.TextBox value={this.props.store.screeningExaminationOtherReasonJustification}
                            onChange={this.props.store.setScreeningExaminationOtherReasonJustification}
                            label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.ScreeningExaminationOtherReasonJustification}
                            propertyIdentifier="ScreeningExaminationOtherReasonJustification"
                            automationId="__screeningExaminationOtherReasonJustification"
                />
            );
        } else {
            return (<></>);
        }
    }

    public render() {
        if (!this.props.store) {
            return null;
        }
        return (
            <Ui.GroupBox
                title={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.EpidemiologicalDataTitle}>
                <Ui.Flex>
                    <Ui.Flex.Item sm={4}>
                        <EhiEReferralCovidExaminationTypeSelectBox value={this.props.store.examinationTypeId}
                                                                   onChange={this.props.store.setExaminationTypeId}
                                                                   label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.ExaminationTypeId}
                                                                   propertyIdentifier="ExaminationTypeId"
                                                                   automationId="__examinationTypeId"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={4}>
                        {this.renderExtraData()}
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={4}>
                        <></>
                    </Ui.Flex.Item>
                </Ui.Flex>
                {this.renderReason()}
                <Ui.Flex>
                    <Ui.Flex.Item sm={4}>
                        <Ui.CheckBox value={this.props.store.stayAbroadInTheLastTwoWeeks}
                                     onChange={this.props.store.setStayAbroadInTheLastTwoWeeks}
                                     label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.StayAbroadInTheLastTwoWeeks}
                                     propertyIdentifier="StayAbroadInTheLastTwoWeeks"
                                     verticalAlign="inlineInput"
                                     automationId="__stayAbroadInTheLastTwoWeeks"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={4}>
                        {
                            this.props.store.stayAbroadInTheLastTwoWeeks &&
                            <CountrySelector value={this.props.store.countryOfResidenceAbroad}
                                             onChange={this.props.store.setCountryOfResidenceAbroad}
                                             label={StaticHunEHealthInfrastructureCareResources.EReferralCovidPanel.CountryOfResidenceAbroad}
                                             propertyIdentifier="CountryOfResidenceAbroad"
                                             automationId="__countryOfResidenceAbroad"
                            />
                        }
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={4}>
                        <></>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.GroupBox>
        );
    }
}
