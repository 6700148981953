import React from "react";
import INDataAction from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataAction";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import NDataPrimaryActionButton from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPrimaryActionButton";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import INDataUseCaseState from "./INDataUseCaseState";
import WorklistActionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistActionType.g";
import ItemVisibility from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/ItemVisibility.g";

interface INDataGlobalActionsProps {
    actions: INDataAction[];
    currentBatchActivityReference: string;
    onActionAsync: (rowId: string, actionToken: string, argumentItems: { useCaseDisplayMode?: UseCaseDisplayMode }, worklistActionType: WorklistActionType, activityReference: string, clientSideAction?: ClientSideActionDto) => Promise<void>;
    _activityRegistry?: IActivityRegistry;
    useCaseState?: INDataUseCaseState;
}


const NDataGlobalActions: React.FC<INDataGlobalActionsProps> = props => {
    if (arrayIsNullOrEmpty(props.actions)) {
        return null;
    }

    const isDetailOpen = props.useCaseState?.displayMode === UseCaseDisplayMode.MasterDetail;

    return (
        <div>
            {props.actions.map(it => {
                if (isDetailOpen) {
                    if (it.displayAwareVisibility === ItemVisibility.Detail || it.displayAwareVisibility === ItemVisibility.MasterDetail) {
                        return renderActionButton(it, props, isDetailOpen);
                    } else {
                        return (<></>);
                    }
                } else {
                    if (it.displayAwareVisibility === ItemVisibility.Master || it.displayAwareVisibility === ItemVisibility.MasterDetail) {
                        return renderActionButton(it, props, isDetailOpen);
                    } else {
                        return (<></>);
                    }
                }
            })}
        </div>
    );
};

function renderActionButton(it: INDataAction, props: React.PropsWithChildren<INDataGlobalActionsProps>, cellMode: boolean): JSX.Element {
    return (
        <NDataPrimaryActionButton
            disabled={!it.isEnabled}
            key={it.titleResourceId.value}
            titleResourceId={it.titleResourceId}
            clientSideAction={it.clientSideAction}
            commandToken={it.commandToken}
            onActionAsync={props.onActionAsync}
            activityReference={it.activityReference}
            cellMode={cellMode}
            automationId={"__" + it.activityReference}
            useCaseDisplayMode={it.useCaseDisplayMode}
            rowId={null}
            size="compact"
            worklistActionType={it.worklistActionType}
            _activityRegistry={props._activityRegistry}
            currentBatchActivityReference={props.currentBatchActivityReference} />
    );
}

export default NDataGlobalActions;