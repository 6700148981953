import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import NeakPerformanceStatementApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/NeakPerformanceStatementApiAdapter";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import NeakPerformanceStatementId from "@Primitives/NeakPerformanceStatementId.g";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import StaticHunSocialSecurityPerformanceStatementResources from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/StaticResources/StaticHunSocialSecurityPerformanceStatementResources";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessUseCaseFrame from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessUseCaseFrame";
import EuPerformanceStatementApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/ApiAdapter/EuPerformanceStatementApiAdapter";
import EuPerformanceStatementId from "@Primitives/EuPerformanceStatementId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IErrorListProcessLogDialogDependencies {
    neakPerformanceStatementApiAdapter: NeakPerformanceStatementApiAdapter;
    euPerformanceStatementApiAdapter: EuPerformanceStatementApiAdapter;
}

export interface IErrorListProcessLogDialogProps {
    _dependencies?: IErrorListProcessLogDialogDependencies;
    neakPerformanceStatementId?: NeakPerformanceStatementId;
    euPerformanceStatementId?: EuPerformanceStatementId;
}

/** @screen */
@State.observer
class ErrorListProcessLogDialog extends React.Component<IErrorListProcessLogDialogProps> {

    @State.observable private processLog = "";
    @State.observable.ref private closeEvent = new TypedEvent<boolean>();

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.bound
    private async loadAsync() {
        let result = null;
        if (!isNullOrUndefined(this.props.neakPerformanceStatementId)) {
            result = (await this.props._dependencies.neakPerformanceStatementApiAdapter.getNeakErrorListById(this.props.neakPerformanceStatementId)).processLog;
        } else if (!isNullOrUndefined(this.props.euPerformanceStatementId)) {
            result = (await this.props._dependencies.euPerformanceStatementApiAdapter.getEuErrorListById(this.props.euPerformanceStatementId)).processLog;
        }

        if (result != null)
            this.setProcessLog(result);
    }

    @State.action.bound
    private close() {
        this.closeEvent.emit(false);
    }

    @State.action.bound
    public setProcessLog(processLog: string) {
        this.processLog = processLog;
    }

    public render() {
        const resources = StaticHunSocialSecurityPerformanceStatementResources.ErrorListProcessLogDialog;

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessUseCaseFrame title={resources.Title} />;
        }

        return (
            this.processLog && (
                <UseCaseFrame
                    modalCloseEvent={this.closeEvent}
                    title={resources.Title}
                    modalSize="tiny"
                    rightFooter={<Ui.Button text={StaticWebAppResources.Common.Button.Close} onClick={this.close} visualStyle="link" automationId="closeButton" />}
                >
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <Ui.TextBox
                                value={this.processLog}
                                propertyIdentifier="ProcessLog"
                                multiline
                                multilineMinLineCount={10}
                                multilineMaxLineCount={20}
                                clearable={false}
                                automationId="processLogTextBox"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </UseCaseFrame>
            )
        );
    }
}

export default connect(
    ErrorListProcessLogDialog,
    new DependencyAdapter<IErrorListProcessLogDialogProps, IErrorListProcessLogDialogDependencies>(c => ({
        neakPerformanceStatementApiAdapter: c.resolve("NeakPerformanceStatementApiAdapter"),
        euPerformanceStatementApiAdapter: c.resolve("EuPerformanceStatementApiAdapter"),
    }))
);
