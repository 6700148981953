import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { createStaticResourceDictionary } from "@Toolkit/ReactClient/Common/LocalizedStrings";
import IHunEHealthInfrastructureMedicationRequestResources from "./IHunEHealthInfrastructureMedicationRequestResources";

const resources = createStaticResourceDictionary<IHunEHealthInfrastructureMedicationRequestResources>();

export function loadStaticMedicationRequestResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    resources.load(localizationService.getResourceGroup("WebApp").MedicationRequest);
}

export default resources as IHunEHealthInfrastructureMedicationRequestResources;