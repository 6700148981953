import Di from "@Di";
import IPatientCareActivitiesTabComponentService from "@HisPlatform/BoundedContexts/Care/Services/Definition/PatientCareActivitiesTabRegistry/IPatientCareActivitiesTabComponentService";
import CareActivityId from "@Primitives/CareActivityId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";

@Di.injectable()
export default class ServiceRequestTabComponentService implements IPatientCareActivitiesTabComponentService {

    public isTabAllowedAsync(careActivityId: CareActivityId, organizationUnitId: OrganizationUnitId): Promise<boolean> {
        return Promise.resolve(true);
    }
}