import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import EhiCovidStatusReportFilterStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidStatusReportWorklistPanel/EhiCovidStatusReportFilterStore";
import ExtendedEhiCovidStatusReportFilter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidStatusReportWorklistPanel/Filters/ExtendedEhiCovidStatusReportFilter";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import NDataPanel from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataPanel";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";

interface IEhiCovidStatusReportWorklistPanelView {
    worklistDefinition: IWorklistDefinition;
    filterStore: EhiCovidStatusReportFilterStore;
    selectedRowId: string;
    useCaseState: INDataUseCaseState;
    hasNewRow: boolean;

    onChange: (rowId: string, useCaseState: INDataUseCaseState) => void;

    onFilterStoreCreatedAsync?: (filterStore: any) => Promise<void>;
    onGetExtendedFilterDescriptors?: () => IDataGridFilterDescriptor[];
    onBack?: () => void;
    onGetPanelProps?: (useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) => object;
    refreshListEvent?: TypedAsyncEvent;
    title?: string;
    hideMasterToolbar: boolean;
}


const EhiCovidStatusReportWorklistPanelView: React.FC<IEhiCovidStatusReportWorklistPanelView> = (props) => {

    const renderExtendedSearch = () => {
        return (
            <ExtendedEhiCovidStatusReportFilter
                filterStore={props.filterStore}
                isVisible={true}
            />
        );
    };

    return props.worklistDefinition && (
        <SingleLayout>
            <NDataPanel
                extraFilter={props.filterStore && props.useCaseState?.displayMode !== UseCaseDisplayMode.MasterDetail && renderExtendedSearch()}
                defaultExtraFilterVisibility={true}
                definition={props.worklistDefinition}
                defaultPageSize={10}
                selectedRowId={props.selectedRowId}
                useCaseState={props.useCaseState}
                onChange={props.onChange}
                onFilterStoreCreatedAsync={props.onFilterStoreCreatedAsync}
                onGetExtendedFilterDescriptors={props.onGetExtendedFilterDescriptors}
                onBack={props.onBack}
                disableDetailStrictMode
                masterTitle={props.title}
                hasNewRow={props.hasNewRow}
                onGetPanelProps={props.onGetPanelProps}
                refreshListEvent={props.refreshListEvent}
                hasPrintButton={false}
                hideMasterToolbar={props.hideMasterToolbar}
            />
        </SingleLayout>
    );
};

export default State.observer(EhiCovidStatusReportWorklistPanelView);