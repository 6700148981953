import React from "react";
import * as Ui from "@CommonControls";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import CheckBox from "@CommonControls/CheckBox";
import PractitionerProfessionalExamStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerProfessionalExamStore";
import ProfessionalExamSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/ProfessionalExamSelectBox/ProfessionalExamSelectBox";

interface IPractitionerProfessionsViewProps {
    profession: PractitionerProfessionalExamStore;
    index: number;
}


const PractitionerProfessionView: React.FC<IPractitionerProfessionsViewProps> = props =>
    (
        <>
            <Ui.Flex>
                <Ui.Flex.Item xs={8}>
                    <ProfessionalExamSelectBox
                        value={props.profession.professionalExamId}
                        onChange={props.profession.setProfessionalExamId}
                        label={StaticUserManagementResources.UserPanel.Label.ProfessionId}
                        propertyIdentifier="ProfessionalExamId"
                        automationId="professionalExamId"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={4}>
                    <CheckBox
                        label={StaticUserManagementResources.UserPanel.Label.IsExpired}
                        value={props.profession.isExpired}
                        onChange={props.profession.setIsExpired}
                        propertyIdentifier="IsExpired"
                        verticalAlign="inlineInput"
                        automationId="isExpired"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        </>
    );

export default State.observer(PractitionerProfessionView);
