import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class NameStore {

    @State.observable public familyName: string = null;
    @State.observable public givenName1: string = null;
    @State.observable public givenName2: string = null;
    @State.observable public prefix: string = null;
    @State.observable public postfix: string = null;


    @State.action.bound
    public setFamilyName(newValue: string) {
        this.familyName = newValue;
    }

    @State.action.bound
    public setGivenName1(newValue: string) {
        this.givenName1 = newValue;
    }

    @State.action.bound
    public setGivenName2(newValue: string) {
        this.givenName2 = newValue;
    }

    @State.action.bound
    public setNamePrefix(newValue: string) {
        this.prefix = newValue;
    }

    @State.action.bound
    public setNamePostfix(newValue: string) {
        this.postfix = newValue;
    }

    @State.action
    public clear(): any {
        this.familyName = null;
        this.givenName1 = null;
        this.givenName2 = null;
        this.prefix = null;
        this.postfix = null;
    }

    public static create(init: {
        familyName?: string,
        givenName1?: string,
        givenName2?: string,
        postfix?: string
        prefix?: string
    }) {
        const ns = new NameStore();
        ns.familyName = init.familyName;
        ns.givenName1 = init.givenName1;
        ns.givenName2 = init.givenName2;
        ns.postfix = init.postfix;
        ns.prefix = init.prefix;
        return ns;
    }
}