import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { createStaticResourceDictionary } from "@Toolkit/ReactClient/Common/LocalizedStrings";
import ISurgeryResources from "./ISurgeryResources";


const StaticSurgeryResources = createStaticResourceDictionary<ISurgeryResources>();

export function loadStaticSurgeryResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    StaticSurgeryResources.load(localizationService.getResourceGroup("WebApp").Surgery);
}

export default StaticSurgeryResources;