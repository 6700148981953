import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Productivity/Api/Proxy.g";
import PatientId from "@Primitives/PatientId.g";
import WorklistDefinitionCache from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/Worklist/WorklistDefinitionCache";
import UserId from "@Primitives/UserId.g";
import { BoundWorklistDefinitionDto, IResponseBase } from "@HisPlatform/BoundedContexts/Productivity/Api/Proxy.g";

@Di.injectable()
export default class HunEHealthWorklistApiAdapter extends ApiAdapterBase {    

    constructor(
        @Di.inject("IHunEHealthWorklistPackageClient") private readonly apiClient: Proxy.IHunEHealthWorklistPackageClient,
        @Di.inject("WorklistDefinitionCache") private readonly worklistDefinitionCache: WorklistDefinitionCache
    ) {
        super();
    }

    private getWorklistDefinitionAsync(key: string, getter: () => Promise<IResponseBase & { definition?: BoundWorklistDefinitionDto }>) {
        return this.worklistDefinitionCache.getWorklistDefinitionAsync(key, getter);
    }

    public getEhiCovidStatusReportBoundWorklistDefinitionAsync() {
        return this.getWorklistDefinitionAsync(
            "EhiCovidStatusReportBoundWorklist",
            () => this.apiClient.getEhiCovidStatusReportBoundWorklistDefinitionQueryAsync(CreateRequestId()));
    }

    public getTherapyRecommendationBoundWorklistDefinitionAsync(patientId: PatientId, userId: UserId) {
        return this.getWorklistDefinitionAsync(
            `TherapyRecommendationBoundWorklist_${patientId.value}_${userId.value}`,
            () => this.apiClient.getTherapyRecommendationBoundWorklistDefinitionQueryAsync(CreateRequestId(), patientId.value));
    }

    public getPatientBloodTypeBoundWorklistDefinitionAsync(patientId: PatientId, userId: UserId) {
        return this.getWorklistDefinitionAsync(
            `PatientBloodTypeBoundWorklist_${patientId.value}_${userId.value}`,
            () => this.apiClient.getPatientBloodTypeBoundWorklistDefinitionQueryAsync(CreateRequestId(), patientId.value));
    }

    public getPermanentPrescriptionBoundWorklistDefinition() {
        return this.getWorklistDefinitionAsync(
            "PermanentPrescriptionBoundWorklist",
            () => this.apiClient.getPermanentPrescriptionBoundWorklistDefinitionQueryAsync(CreateRequestId()));
    }
}
