import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MedicalServiceId from "@Primitives/MedicalServiceId.g";
import EhiEReferralId from "@Primitives/EhiEReferralId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import CareActivityId from "@Primitives/CareActivityId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import moment from "moment";
import GenderId from "@Primitives/GenderId.g";
import NameStore from "@Primitives/NameStore";
import EhiEReferralPatientIdentifierTypeId from "@Primitives/EhiEReferralPatientIdentifierTypeId.g";
import EhiEReferralServiceTypeId from "@Primitives/EhiEReferralServiceTypeId.g";
import EhiEReferralReasonId from "@Primitives/EhiEReferralReasonId.g";
import EhiEReferralTypeId from "@Primitives/EhiEReferralTypeId.g";
import EhiEReferralOrganizationTypeId from "@Primitives/EhiEReferralOrganizationTypeId.g";
import EhiEReferralProfessionCodeId from "@Primitives/EhiEReferralProfessionCodeId.g";
import EhiEReferralTravelExpenseTypeId from "@Primitives/EhiEReferralTravelExpenseTypeId.g";
import EhiEReferralStatusId from "@Primitives/EhiEReferralStatusId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import CountryId from "@Primitives/CountryId.g";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";

export default class CareActivityEReferralStore extends EntityStoreBase<CareActivityId> {
    @State.observable.ref public ehiEReferralId: EhiEReferralId;
    @State.observable.ref public name: NameStore;
    @State.observable.ref public genderCode: string;
    @State.observable.ref public genderId: GenderId;
    @State.observable.ref public birthDate: LocalDate;
    @State.observable.ref public mothersName: NameStore;
    @State.observable.ref public patientIdentifierTypeCode: string;
    @State.observable.ref public patientIdentifierTypeId: EhiEReferralPatientIdentifierTypeId;
    @State.observable.ref public patientIdentifierValue: string;
    @State.observable.ref public countryId: CountryId;
    @State.observable.ref public country: string;
    @State.observable.ref public city: string;
    @State.observable.ref public street: string;
    @State.observable.ref public referralInstituteCode: string;
    @State.observable.ref public referralInstituteName: string;
    @State.observable.ref public referralLocationCode: string;
    @State.observable.ref public referralLocationName: string;
    @State.observable.ref public referralLocation: ExternalLocationId;
    @State.observable.ref public referralDoctorName: NameStore;
    @State.observable.ref public referralDoctorCode: string;
    @State.observable.ref public referralDoctor: PractitionerId;
    @State.observable.ref public careInstituteCode: string;
    @State.observable.ref public careInstituteName: string;
    @State.observable.ref public careLocationCode: string;
    @State.observable.ref public careLocationName: string;
    @State.observable.ref public createdAt: LocalDate;
    @State.observable.ref public validAt: LocalDate;
    @State.observable.ref public referralNote: string;
    @State.observable.ref public referralServiceTypeCode: string;
    @State.observable.ref public referralServiceTypeId: EhiEReferralServiceTypeId;
    @State.observable.ref public referralReasonCode: string;
    @State.observable.ref public referralReasonId: EhiEReferralReasonId;
    @State.observable.ref public referralDiagnosisCode: string;
    @State.observable.ref public referralDiagnosisDescription: string;
    @State.observable.ref public referralTypeCode: string;
    @State.observable.ref public referralTypeId: EhiEReferralTypeId;
    @State.observable.ref public referralInstituteTypeCode: string;
    @State.observable.ref public referralInstituteTypeId: EhiEReferralOrganizationTypeId;
    @State.observable.ref public isInAreaCare: boolean;
    @State.observable.ref public isUrgentReferral: boolean;
    @State.observable.ref public isPostponedInstituteChoice: boolean;
    @State.observable.ref public isPaperBased: boolean;
    @State.observable.ref public isOutOfOrder: boolean;
    @State.observable.ref public sickAllowanceIdentifier: string;
    @State.observable.ref public anamnesis: string;
    @State.observable.ref public concilium: string;
    @State.observable.ref public professionCode: string;
    @State.observable.ref public professionCodeId: EhiEReferralProfessionCodeId;
    @State.observable.ref public referralServiceCode: string;
    @State.observable.ref public referralServiceId: MedicalServiceId;
    @State.observable.ref public sicknessInDaysSinceStart: number;
    @State.observable.ref public travelExpenseTypeCode: string;
    @State.observable.ref public travelExpenseTypeId: EhiEReferralTravelExpenseTypeId;
    @State.observable.ref public travelVoucherIdentifier: string;
    @State.observable.ref public ehiIdentifier: string;
    @State.observable.ref public reportedAt: moment.Moment;
    @State.observable.ref public referralStatusCode: string;
    @State.observable.ref public referralStatusId: EhiEReferralStatusId;
    @State.observable.ref public lastChangeAt: moment.Moment;
    @State.observable.ref public referralCareIdentifier: string;
    @State.observable.ref public patientNotification: boolean;
    @State.observable.ref public doctorNotification: boolean;

    public static CreateFromJsonObject(source: any): CareActivityEReferralStore {
        const result = new CareActivityEReferralStore(true);

        if (!isNullOrUndefined(source)) {
            const eReferralDataRaw = source["HunEHealthInfrastructure_ReferralData"];

            if (!isNullOrUndefined(eReferralDataRaw)) {
                result.isNew = false;

                result.id = CareActivityId.fromJS(eReferralDataRaw.Id);
                result.ehiEReferralId = EhiEReferralId.fromJS(eReferralDataRaw.EhiEReferralId);
                result.referralServiceId = MedicalServiceId.fromJS(eReferralDataRaw.ReferralServiceId);
                result.createdAt = eReferralDataRaw.CreatedAt;
                result.referralDoctor = PractitionerId.fromJS(eReferralDataRaw.ReferralDoctor);
                result.referralLocation = ExternalLocationId.fromJS(eReferralDataRaw.ReferralLocation);
            }
        }

        return result;
    }
}