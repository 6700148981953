import React from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import CareActivityId from "@Primitives/CareActivityId.g";
import PanelController from "@Toolkit/ReactClient/Components/PanelController";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PractitionerRecommendationMasterDetailPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/PractitionerRecommendationListPanel/PractitionerRecommendationMasterDetailPanel";
import standaloneRouteDefinitions, { IPractitionerRecommendationStandaloneRouteParams } from "./PractitionerRecommendationRoutes";
import PractitionerRecommendationId from "@Primitives/PractitionerRecommendationId.g";
import { RecommendationType } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/PractitionerRecommendation/RecommendationType";
import IOutpatientTreatmentRouteParams from "@HisPlatform/Application/Routes/IOutpatientTreatmentRouteParams";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";

interface IPractitionerRecommendationStandaloneHostDependencies {
}

interface IPractitionerRecommendationStandaloneHostProps extends IRoutingFrameContentProps {
    _dependencies?: IPractitionerRecommendationStandaloneHostDependencies;
    _careActivityId?: CareActivityId;
}

class PractitionerRecommendationStandaloneHost extends React.Component<IPractitionerRecommendationStandaloneHostProps> {
    private controller: PanelController;

    @State.computed.valueWrapper
    private get careActivityId() {
        let careActivityId: CareActivityId = null;

        const careActivityIdString = (this.props.routingController.currentRoute.parameters as IOutpatientTreatmentRouteParams).careActivityId;
        if (careActivityIdString) {
            careActivityId = new CareActivityId(careActivityIdString);
        } else if (this.props._careActivityId) {
            careActivityId = this.props._careActivityId;
        }

        return careActivityId;
    }

    @State.computed.valueWrapper
    private get practitionerRecommendationId() {
        let id: PractitionerRecommendationId = null;

        const practitionerRecommendationId = (this.props.routingController.currentRoute.parameters as IPractitionerRecommendationStandaloneRouteParams).practitionerRecommendationId;
        if (practitionerRecommendationId) {
            id = new PractitionerRecommendationId(practitionerRecommendationId);
        }

        return id;
    }

    @State.computed
    private get recommendationType() {
        const typeAsString = (this.props.routingController.currentRoute.parameters as IPractitionerRecommendationStandaloneRouteParams).recommendationType;
        return typeAsString === RecommendationType.GY.toString() ? RecommendationType.GY : RecommendationType.GYSE;
    }

    @State.computed
    private get isReadonly() {
        return !!((this.props.routingController.currentRoute.parameters as IPractitionerRecommendationStandaloneRouteParams).readonly);
    }

    constructor(props: IRoutingFrameContentProps) {
        super(props);
        this.updateController();
    }

    public componentDidUpdate(prevProps: IRoutingFrameContentProps) {
        if (prevProps.routingController !== this.props.routingController) {
            this.updateController();
        }
    }

    private updateController() {
        this.controller = PanelController.createFromRoutingController(this.props.routingController);
    }

    @State.bound
    private navigateToPractitionerRecommendation(id: PractitionerRecommendationId, type: RecommendationType, readonly: boolean) {
        this.props.routingController.replace(standaloneRouteDefinitions.practitionerRecommendations.makeRoute({
            careActivityId: this.careActivityId.value,
            practitionerRecommendationId: id?.value || undefined,
            recommendationType: type?.toString(),
            readonly: readonly ? "readonly" : undefined
        }));
    }

    public render() {
        return (
            <PractitionerRecommendationMasterDetailPanel
                _careActivityId={this.careActivityId}
                practitionerRecommendationId={this.practitionerRecommendationId}
                recommendationType={this.recommendationType}
                onPractitionerRecommendationSelected={this.navigateToPractitionerRecommendation}
                readonly={this.isReadonly}
            />
        );
    }
}

export default connect(
    PractitionerRecommendationStandaloneHost,
    new DependencyAdapter<IPractitionerRecommendationStandaloneHostProps, IPractitionerRecommendationStandaloneHostDependencies>(container => {
        return {
        };
    }),
    new CareActivityContextAdapter<IPractitionerRecommendationStandaloneHostProps>(c => ({
        _careActivityId: c.careActivityId
    }))
);