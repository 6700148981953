import React, {  } from "react";
import Icon, { iconNameType } from "@CommonControls/Icon";
import Styles from "./PageBox.less";
import Button from "@CommonControls/Button";
import ViewContextProvider from "@Toolkit/ReactClient/Components/ViewContext/ViewContextProvider";

export interface IPageBox2HeaderProps {
    hasDetailCloseButton?: boolean;
    iconName?: iconNameType;
    title: string;
    subTitle?: React.ReactNode;
    toolbar?: React.ReactNode;
    automationId?: string;

    onCloseDetail?: () => void;
}


const PageBox2Header: React.FC<IPageBox2HeaderProps> = props => {
    return (
        <>
            <div className={Styles.header}>
                {props.hasDetailCloseButton && (
                    <div className={Styles.leftToolbar}>
                        <Button iconName="angleLeft" size="compact" onClick={props.onCloseDetail} automationId="closeDetail" />
                    </div>
                )}
                <div className={Styles.title}>
                    {props.iconName && (
                        <div className={Styles.iconContainer}>
                            <Icon iconName={props.iconName} />
                        </div>
                    )}
                    <h1 className={Styles.titleText} data-automation-id={`${props.automationId}_PageBoxHeaderTitle`}>
                        {props.title}
                        {props.subTitle && <span>{props.subTitle}</span>}
                    </h1>
                </div>
                <div className={Styles.rightToolbar}>
                    <ViewContextProvider
                        compactControlHeight
                    >
                        {props.toolbar}
                    </ViewContextProvider>
                </div>
            </div>
            <hr className={Styles.separator} />
        </>
    );
};

export default PageBox2Header;