import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ImmunizationId from "@Primitives/ImmunizationId.g";
import MedicalConditionItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionItemBase";

export default class ImmunizationStore extends MedicalConditionItemBase<ImmunizationId> {

    @State.observable public name: string;

    constructor(isNew: boolean) {
        super(isNew);
    }

    @State.action.bound
    public setName(newValue: string) {
        this.name = newValue;
    }
}