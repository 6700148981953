import { createNestedRoutes } from "@Toolkit/ReactClient/Routing/CreateNestedRoutes";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import IOutpatientTreatmentRouteParams from "@HisPlatform/Application/Routes/IOutpatientTreatmentRouteParams";

export interface IPermanentPrescriptionRouteParams extends IOutpatientTreatmentRouteParams {
    permanentPrescriptionId: string;
}

const standaloneRouteDefinitions = createNestedRoutes(ApplicationRoutes.careActivity, {
    permanentPrescriptions: new RouteDefinition<IPermanentPrescriptionRouteParams>("/permanentPrescriptions/:permanentPrescriptionId?")
});

export default standaloneRouteDefinitions;