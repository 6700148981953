import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import { IEhiOrganizationUnitSelectorDialogResult, IEhiOrganizationUnitSelectorDialogParams } from "./EhiOrganizationUnitSelectorDialogParams";
import ClientSideValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidationBoundary";
import EhiOrganizationUnitSelectorValidator from "./EhiOrganizationUnitSelectorValidator";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import EhiOrganizationUnitSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/Organization/Components/Controls/EhiOrganizationUnitSelector/EhiOrganizationUnitSelector";

interface IEhiOrganizationUnitSelectorDialogDependencies {
    practitionerApiAdapter: PractitionerApiAdapter;
    userContext: UserContext;
}

interface IEhiOrganizationUnitSelectorDialogProps extends IModalComponentParams<IEhiOrganizationUnitSelectorDialogResult>, IEhiOrganizationUnitSelectorDialogParams {
    _dependencies?: IEhiOrganizationUnitSelectorDialogDependencies;
}

@State.observer
export default class EhiOrganizationUnitSelectorDialog extends React.Component<IEhiOrganizationUnitSelectorDialogProps> {

    private validator = new EhiOrganizationUnitSelectorValidator();

    @State.observable private pointOfCareId: PointOfCareId = null;

    @State.action.bound
    private setPointOfCareId(newValue: PointOfCareId) {
        this.pointOfCareId = newValue;
    }

    public render() {
        return (
            <Ui.Modal isOpen size="tiny" title={StaticHunEHealthInfrastructureCareResources.EhiOrganizationUnitSelectorDialog.Title}>
                <Ui.Modal.Body>
                    {<>
                        <p>{StaticHunEHealthInfrastructureCareResources.EhiOrganizationUnitSelectorDialog.Message}</p>
                        <ClientSideValidationBoundary validator={this.validator} entityTypeName="OrganizationUnitSelector">
                            <EhiOrganizationUnitSelector
                                label={StaticHunEHealthInfrastructureCareResources.EhiOrganizationUnitSelectorDialog.SelectorLabel}
                                value={this.pointOfCareId}
                                onChange={this.setPointOfCareId}
                                displayMode={"shorthand-name"}
                                automationId={"ehiOrganizationUnitSelectorSelectBox"}
                                propertyIdentifier={"organizationUnit"}
                            />
                        </ClientSideValidationBoundary>
                    </>}
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Flex xsJustify="between">
                        <Ui.Flex.Item>
                            <Ui.Button
                                text={StaticHunEHealthInfrastructureCareResources.EhiOrganizationUnitSelectorDialog.CloseButton}
                                onClick={this.cancel}
                                automationId="ehiOrganizationUnitSelectorDialogCancelButton" />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item>
                            <Ui.Button
                                text={StaticHunEHealthInfrastructureCareResources.EhiOrganizationUnitSelectorDialog.ConfirmButton}
                                visualStyle="primary"
                                onClickAsync={this.okAsync}
                                automationId="ehiOrganizationUnitSelectorDialogConfirmButton" />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.Modal.Footer>
            </Ui.Modal>
        );
    }

    @State.bound
    private cancel() {
        this.props.onClose(null);
    }

    @State.bound
    private async okAsync() {
        await this.validator.validateAsync();
        if (this.validator.isValid()) {
            this.props.onClose({ organizationUnitId: new OrganizationUnitId(this.pointOfCareId.value) });
        }
    }

}