import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExtensibleEnumSelectBox, {IExtensibleEnumSelectBoxPropsBase} from "@HisPlatformControls/ExtensibleEnumSelectBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";

interface IDosageFormSelectBoxDependencies {
    referenceDataStore: CareReferenceDataStore;
}

interface IDosageFormSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IDosageFormSelectBoxDependencies;
}


@State.observer
class DosageFormSelectBox extends React.Component<IDosageFormSelectBoxProps> {
    private get dependencies() {
        return this.props._dependencies;
    }

    private get extensibleEnumStore() {
        return this.dependencies.referenceDataStore.dosageForm;
    }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                orderItems={true}
                extensibleEnumStore={this.extensibleEnumStore}
                {...this.props}
            />
        );
    }
}

export default connect(
    DosageFormSelectBox,
    new DependencyAdapter<IDosageFormSelectBoxProps, IDosageFormSelectBoxDependencies>((containerService) => {
        return {
            referenceDataStore: containerService.resolve("CareReferenceDataStore")
        };
    })
);
