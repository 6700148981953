// tslint:disable:variable-name
import CustomerScreenStore from "./CustomerScreenStore";
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import { editorScreenRenderFunc } from "@Toolkit/CommonWeb/PanelStore/EditorScreenRenderFunc";

const {
    ContextComponent: CustomerScreenStoreContext, 
    StoreProvider: CustomerScreenStoreProvider, 
    useStore: useCustomerScreenStore,
    provideStore: provideCustomerScreenStore,
} = createPanelStoreProvider<CustomerScreenStore>(CustomerScreenStore, null, editorScreenRenderFunc);

export {
    CustomerScreenStoreContext,
    CustomerScreenStoreProvider,
    useCustomerScreenStore,
    provideCustomerScreenStore
};