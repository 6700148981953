import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IServiceRequestDefinitionDialogProps } from "./ServiceRequestDefinitionDialog";
import ClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidator";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import IServiceRequestDefinitionDialogResult from "./IServiceRequestDefinitionDialogResult";
import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { ServiceRequestManagement } from "@HisPlatform/BoundedContexts/Care/Api/ApiConstraints.g";

@Di.injectable()
export default class ServiceRequestDefinitionDialogStore extends PanelStoreBase<IServiceRequestDefinitionDialogProps> {
    @State.observable.ref public definitionId: EntityVersionSelector<ServiceRequestDefinitionId> = null;
    @State.observable.ref public serviceRequestDefinitionDirection: ServiceRequestDirection = ServiceRequestDirection.InternalToInternal;

    public readonly clientSideValidator = new ClientSideValidator(ServiceRequestManagement, {
        shouldBeFilled: StaticCareResources.ServiceRequestManagement.ServiceRequestDefinitionDialog.Validation.Required
    });

    @State.bound
    public createServiceRequest() {
        if (!this.clientSideValidator.isValid()) {
            this.props._dependencies.notificationService.showCannotSaveBecauseOfErrors();
            return;
        }

        this.props.onClose({
            definitionId: this.definitionId,
            direction: this.serviceRequestDefinitionDirection
        } as IServiceRequestDefinitionDialogResult);
    }

    @State.action.bound
    public setDefinitionId(value: EntityVersionSelector<ServiceRequestDefinitionId>): void {
        this.definitionId = value;
    }

    @State.action.bound
    public setDefinitionDirection(value: ServiceRequestDirection): void {
        this.serviceRequestDefinitionDirection = value;
    }
}