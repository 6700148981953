import Di from "@Di";
import MasterDetailCrudPanelStoreBase from "@Toolkit/CommonWeb/Model/PanelStore/MasterDetailCrudPanelStoreBase";
import { IWorklistDefinitionMasterDetailPanelProps } from "./WorklistDefinitionMasterDetailPanel";
import WorklistDefinitionId from "@Primitives/WorklistDefinitionId.g";
import IWorklistDefinitionBaseInformation from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/WorklistDefinition/IWorklistDefinitionBaseInformation";
import WorklistDefinitionStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/WorklistDefinition/WorklistDefinitionStore";
import PagedItemStore from "@Toolkit/CommonWeb/Model/PagedItemStore";
import ISaveResult from "@Toolkit/CommonWeb/Model/PanelStore/ISaveResult";
import WorklistDefinitionApiAdapter from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/Worklist/WorklistDefinitionApiAdapter";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import WorklistType from "@Primitives/WorklistType.g";
import WorklistItemDefinitionStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/WorklistDefinition/WorklistItemDefinitionStore";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ValidationResultsBuilder, { IPropertyIdentifiers } from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationResultsBuilder";
import { IEntityConstraints } from "@Toolkit/ReactClient/Components/ValidationBoundary/IEntityConstraints";
import ClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidator";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import FormFieldModalParams, { IFormFieldModalResult } from "./FormFieldModalParams";
import WorklistDefinitionCache from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/ApiAdapter/Worklist/WorklistDefinitionCache";

@Di.injectable()
export default class WorklistDefinitionMasterDetailPanelStore extends MasterDetailCrudPanelStoreBase<IWorklistDefinitionMasterDetailPanelProps, WorklistDefinitionId, IWorklistDefinitionBaseInformation, WorklistDefinitionStore> {
    public readonly defaultColumnWidth = "50px";
    public defaultColumnWidthValidator: ClientSideValidator;

    @State.observable public rowUnderEditing: WorklistItemDefinitionStore = null;

    constructor(
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("WorklistDefinitionApiAdapter") private readonly worklistDefinitionApiAdapter: WorklistDefinitionApiAdapter,
        @Di.inject("WorklistDefinitionCache") private readonly worklistDefinitionCache: WorklistDefinitionCache,
    ) {
        super(notificationService);
    }

    @State.computed public get title() {
        return this.selectedDetail?.name;
    }

    protected getIdFromListItem(item: IWorklistDefinitionBaseInformation): WorklistDefinitionId { return item.id; }
    protected getIdFromDetailItem(detail: WorklistDefinitionStore): WorklistDefinitionId { return detail.id; }
    protected getSelectedIdFromProps(props: IWorklistDefinitionMasterDetailPanelProps): WorklistDefinitionId { return props.selectedId; }
    protected getModalService(props: IWorklistDefinitionMasterDetailPanelProps): IModalService { return props._modalService; }
    protected getSelectedIdMutatorFromProps(props: IWorklistDefinitionMasterDetailPanelProps): (value: WorklistDefinitionId) => void { return props.onSelectedIdChange; }
    protected getNewDetailItemAsync(): Promise<WorklistDefinitionStore> { return Promise.resolve(new WorklistDefinitionStore(this.getNewId(), "new", new WorklistType(""), WorklistItemDefinitionStore[0], 0)); }
    protected getNewId(): WorklistDefinitionId { return new WorklistDefinitionId("1"); }

    protected async loadListCoreAsync(): Promise<PagedItemStore<IWorklistDefinitionBaseInformation>> {
        const result = await this.worklistDefinitionApiAdapter.getWorklistDefinitionBaseInformationAsync();
        return new PagedItemStore(result.value);
    }

    protected async loadDetailCoreAsync(id: WorklistDefinitionId): Promise<WorklistDefinitionStore> {
        const worklistDefinition = (await this.worklistDefinitionApiAdapter.getWorklistDefinitionAsync(id)).value;
        const worklistItemDefinitions = (await this.worklistDefinitionApiAdapter.getWorklistItemDefinitionsAsync(worklistDefinition.worklistType)).value;

        this.defaultColumnWidthValidator = this.createDefaultWidthValidator(worklistItemDefinitions.length);

        const mergedWorklistItemDefinitions = worklistItemDefinitions.map(wid => {
            const element = worklistDefinition.worklistItemDefinitions.find(wid2 => wid.attributeReference.value === wid2.attributeReference.value);
            return element ? element : wid;
        });

        // form columns that are not present in the merged items
        worklistDefinition.worklistItemDefinitions.forEach(wid => {
            const element = mergedWorklistItemDefinitions.find(wid2 => wid.attributeReference.value === wid2.attributeReference.value);

            if (isNullOrUndefined(element)) {
                mergedWorklistItemDefinitions.push(wid);
            }
        });

        this.setDefaultColumnWidthIfEmpty(mergedWorklistItemDefinitions);

        return new WorklistDefinitionStore(
            worklistDefinition.id,
            worklistDefinition.name,
            worklistDefinition.worklistType,
            mergedWorklistItemDefinitions,
            worklistDefinition.rowVersion);
    }

    protected async saveCoreAsync(): Promise<ISaveResult<WorklistDefinitionId, WorklistDefinitionStore>> {
        if (!this.defaultColumnWidthValidator.isValid()) {
            this.notificationService.showCannotSaveBecauseOfErrors();
            return {
                id: this.selectedDetail.id,
                isPersisted: false,
                hasWarning: true
            };
        }

        const selectedWorklistItemDefinitions = this.selectedDetail.worklistItemDefinitions
            .filter(wid => wid.checkState.isChecked)
            .sort((wid, wid2) => wid.order - wid2.order);

        if (selectedWorklistItemDefinitions.length === 0) {
            return {
                id: this.selectedDetail.id,
                isPersisted: false
            };
        }

        for (let i = 0; i < selectedWorklistItemDefinitions.length; i++) {
            selectedWorklistItemDefinitions[i].order = i + 1;
        }

        this.worklistDefinitionCache.invalidateWorklistDefinitionCache();
        await this.worklistDefinitionApiAdapter.updateWorklistDefinitionAsync(this.selectedDetail.id, this.selectedDetail.rowVersion, selectedWorklistItemDefinitions);
        return {
            id: this.selectedDetail.id,
            isPersisted: true,
        };
    }

    @State.bound
    public async showAddFormFieldAsync() {
        const result = await this.getModalService(this.props).showDialogAsync<IFormFieldModalResult>(new FormFieldModalParams(null, this.selectedDetail));
        if (result?.updatedFormField) {
            this.selectedDetail.addWorklistItemDefinition(result.updatedFormField);
        }
    }

    @State.computed
    public get operationsToChecks() {
        const res = {};

        res["GetWorklistDefinitionBaseInformation"] = async () => {
            await this.worklistDefinitionApiAdapter.getWorklistDefinitionBaseInformationAsync(true);
        };

        res["GetWorklistDefinition"] = async () => {
            await this.worklistDefinitionApiAdapter.getWorklistDefinitionAsync(this.getNewId(), true);
        };

        if (this.selectedId) {
            res["GetWorklistItemDefinitions"] = async () => {
                await this.worklistDefinitionApiAdapter.getWorklistItemDefinitionsAsync(new WorklistType("new"), true);
            };

            res["UpdateWorklistDefinition"] = async () => {
                await this.worklistDefinitionApiAdapter.updateWorklistDefinitionAsync(
                    this.selectedDetail.id,
                    this.selectedDetail.rowVersion,
                    this.selectedDetail.worklistItemDefinitions,
                    true);
            };
        }

        return res;
    }

    private createDefaultWidthValidator(rowCount: number): ClientSideValidator {
        const validatorRoot: IEntityConstraints = { WorklistDefinitionDetail: {} };
        for (let i = 0; i < rowCount; i++) {
            validatorRoot.WorklistDefinitionDetail[`row_${i}_ColumnWidth`] = {
                isRequired: true,
                customEvaluators: [
                    (builder: ValidationResultsBuilder, propertyIdentifiers: IPropertyIdentifiers, value: any) => {
                        if (!/^\d+(?:%|px)$/.test(value)) {
                            builder.addProblem(
                                propertyIdentifiers,
                                "ShouldBeValidWidth",
                                "error",
                                null,
                                null,
                                StaticProductivityResources.Worklist.Definition.ErrorMessages.InvalidColumnWidth);
                        }
                    }
                ]
            };
        }
        return new ClientSideValidator(validatorRoot, null, true);
    }

    private setDefaultColumnWidthIfEmpty(worklistItemDefinitions: WorklistItemDefinitionStore[]) {
        worklistItemDefinitions.forEach(element => {
            if (isNullOrEmptyString(element.defaultColumnWidth)) {
                element.defaultColumnWidth = this.defaultColumnWidth;
            }
        });
    }
}