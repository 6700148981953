import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import AppointmentParticipantPanelView from "./AppointmentParticipantPanelView";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import _ from "@HisPlatform/Common/Lodash";
import AppointmentFilterStore from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/DoctorPointOfCareListPanel/AppointmentFilterStore";
import PractitionerId from "@Primitives/PractitionerId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import AppointmentScheduleSlotSeries from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentSchedule";
import { arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IAppointmentParticipantPanelDependencies {
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
}

interface IAppointmentParticipantPanelProps {
    _dependencies?: IAppointmentParticipantPanelDependencies;
    readonly: boolean;
    filterStore: AppointmentFilterStore;
    filtersChangedEvent: TypedAsyncEvent<void>;
    serviceRequestDefinitionId: ServiceRequestDefinitionId;
}

@State.observer
class AppointmentParticipantPanel extends React.Component<IAppointmentParticipantPanelProps> {
    @State.observable private isLoading: boolean;

    private get schedules() {
        return this.props._dependencies.schedulingReferenceDataStore.appointmentScheduleSlotSeries.items;
    }

    @State.action.bound
    private setLoadingState(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    @State.bound
    private setDoctorIds(values: PractitionerId[]) {
        this.props.filterStore.setDoctorIds(values);
        this.setFilterState();
    }

    @State.bound
    private setPointOfCareIds(values: PointOfCareId[]) {
        this.props.filterStore.setPointOfCareIds(values);
        this.setFilterState();
    }

    @State.bound
    private setSchedulingServiceId(value: SchedulingServiceId) {
        this.props.filterStore.setSchedulingServiceId(value);
        this.setFilterState();
    }

    @State.bound
    private setFilterState() {
        this.props.filtersChangedEvent.emitAsync();
    }

    @State.computed
    public get explicitIdsForSchedulingService() {
        const explicitPointOfCareIds: PointOfCareId[] = [];
        const explicitPractitionerIds: PractitionerId[] = [];
        const explicitSchedulingServiceIds: SchedulingServiceId[] = [];

        const selectedDoctors = this.props.filterStore.doctorIds;
        const selectedPointsOfCare = this.props.filterStore.pointOfCareIds;
        const selectedSchedulingService = this.props.filterStore.schedulingServiceId;

        // Selectable items of each filter are defined by the selected values of the other two filters as:
        // All schedules satisfying two filters should be selectable through the third one.
        // So if a schedule satisfies two filters,
        // its appropriate resources are added to the selectable items of the third one.
        this.schedules.forEach(s => {

            if ((arrayIsNullOrEmpty(selectedPointsOfCare) || this.intersects(s.locationParticipants, selectedPointsOfCare)) &&
                (arrayIsNullOrEmpty(selectedDoctors) || this.intersects(s.practitionerParticipants, selectedDoctors))) {
                const serviceIds = this.getAllowedServices(s);
                explicitSchedulingServiceIds.push(...serviceIds);
            }

            if ((arrayIsNullOrEmpty(selectedDoctors) || this.intersects(s.practitionerParticipants, selectedDoctors)) &&
            (isNullOrUndefined(selectedSchedulingService) || this.isAvailableForService(s, selectedSchedulingService))) {
                explicitPointOfCareIds.push(...s.locationParticipants);
            }

            if ((arrayIsNullOrEmpty(selectedPointsOfCare) || this.intersects(s.locationParticipants, selectedPointsOfCare)) &&
            (isNullOrUndefined(selectedSchedulingService) || this.isAvailableForService(s, selectedSchedulingService))) {
                explicitPractitionerIds.push(...s.practitionerParticipants);
            }
        });

        return {
            explicitPointOfCareIds: explicitPointOfCareIds,
            explicitPractitionerIds: explicitPractitionerIds,
            explicitSchedulingServiceIds: explicitSchedulingServiceIds
        };
    }

    @State.computed
    private get explicitIdsForServiceRequest() {
        const explicitPointOfCareIds: PointOfCareId[] = [];
        const explicitPractitionerIds: PractitionerId[] = [];

        const selectedDoctors = this.props.filterStore.doctorIds;
        const selectedPointsOfCare = this.props.filterStore.pointOfCareIds;

        // Service request definition cannot be changed from this panel, and should never be null on service request appointment modal.
        // All schedules available for the given service request definition and satisfying one filter should be selectable through the other.
        // So if a schedule is available for the given service request definition and satisfies one filter, 
        // its appropriate resources are added to the selectable items of the other.
        this.schedules.forEach(s => {
            if ((arrayIsNullOrEmpty(selectedDoctors) || this.intersects(s.practitionerParticipants, selectedDoctors)) &&
            (this.intersects(s.allowedServiceRequestDefinitions, [this.props.serviceRequestDefinitionId]))) {
                explicitPointOfCareIds.push(...s.locationParticipants);
            }

            if ((arrayIsNullOrEmpty(selectedPointsOfCare) || this.intersects(s.locationParticipants, selectedPointsOfCare)) &&
            (this.intersects(s.allowedServiceRequestDefinitions, [this.props.serviceRequestDefinitionId]))) {
                explicitPractitionerIds.push(...s.practitionerParticipants);
            }
        });

        return {
            explicitPointOfCareIds: explicitPointOfCareIds,
            explicitPractitionerIds: explicitPractitionerIds
        };
    }

    @State.computed
    private get explicitIds() {
        if (this.props.serviceRequestDefinitionId) {
            return this.explicitIdsForServiceRequest;
        }

        return this.explicitIdsForSchedulingService;
    }

    @State.bound
    private isAvailableForService(appointmentSchedule: AppointmentScheduleSlotSeries, serviceId: SchedulingServiceId) {
        const serviceIds = this.getAllowedServices(appointmentSchedule);
        if (serviceIds?.length > 0) {
            return this.intersects(serviceIds, [serviceId]);
        }
        return true;
    }

    @State.bound
    private intersectsOrEitherIsEmpty(first: IStringEntityId[], second: IStringEntityId[]) {
        if (!(first?.length > 0 && second?.length > 0)) {
            return true;
        }
        return this.intersects(first, second);
    }

    @State.bound
    private intersects(first: IStringEntityId[], second: IStringEntityId[]) {
        return !arrayIsNullOrEmpty(_.intersectionWith(first, second, ValueWrapper.equals));
    }

    @State.bound
    private getAllowedServices(schedule: AppointmentScheduleSlotSeries) {
        if (schedule?.allowedSchedulingServices?.length > 0) {
            return schedule.allowedSchedulingServices;
        }
        // if the allowed services are empty it means that the slotSeries allows every service
        // defined in the definitions
        const appointmentScheduleDefinition = this.props._dependencies.schedulingReferenceDataStore
            .appointmentScheduleDefinitions.get(schedule.appointmentScheduleDefinitionId);

        if (appointmentScheduleDefinition?.ownedSchedulingServices?.length > 0) {
            return appointmentScheduleDefinition.ownedSchedulingServices;
        }
        return [] as SchedulingServiceId[];
    }

    public render() {
        return (
            <AppointmentParticipantPanelView
                readonly={this.props.readonly}
                setLoadingState={this.setLoadingState}
                filterStore={this.props.filterStore}
                onDoctorIdsChanged={this.setDoctorIds}
                onPointOfCareIdsChanged={this.setPointOfCareIds}
                onSchedulingServiceChanged={this.setSchedulingServiceId}
                explicitIds={this.explicitIds}
                isServiceRequestSubject={!!this.props.serviceRequestDefinitionId}
            />
        );
    }
}

export default connect(
    AppointmentParticipantPanel,
    new DependencyAdapter<IAppointmentParticipantPanelProps, IAppointmentParticipantPanelDependencies>(c => ({
        schedulingReferenceDataStore: c.resolve("SchedulingReferenceDataStore")
    }))
);
