import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import PatientId from "@Primitives/PatientId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import { GetTypeNameWithPrefix } from "@HunEHealthInfrastructurePlugin/PluginHelper";
import IEhiMedicalConditionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/IEhiMedicalConditionApiAdapter";
import { mapEhiErrors } from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiErrorHelper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import EhiToken from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EhiToken";
import IEhiResult from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiResult";
import moment from "moment";

/* auto-inject-disable */
@Di.injectable()
export default class EhiMedicationStatementApiAdapter extends ApiAdapterBase implements IEhiMedicalConditionApiAdapter {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("IMedicationStatementClient")) private client: Proxy.IMedicationStatementClient,
    ) {
        super();
    }

    @State.bound
    public synchronizeItemsAsync(
        patientId: PatientId,
        ehiToken: EhiToken,
        organizationUnitId: OrganizationUnitId,
        deleteLocalEntities: boolean = false,
        isSelfDeterminationAccessOverride: boolean = false,
        selfDeterminationAccessOverrideReason: string = "",
    ) {
        return this.processOperationAsync(
            new SimpleStore<IEhiResult<any>>(),
            async target => {
                const response = await this.client.synchronizeMedicationStatementsCommandAsync(
                    CreateRequestId(),
                    new Proxy.SynchronizeMedicationStatementsControllerDto(
                        {
                            ehiTokenDto: new Proxy.EhiTokenDto(
                                {
                                    tokenValue: ehiToken.tokenValue,
                                    validFrom: ehiToken.validFrom,
                                    validTo: ehiToken.validTo
                                } as Proxy.IEhiTokenDto),
                            patientId: patientId,
                            organizationUnitId: organizationUnitId,
                            deleteLocalEntities: deleteLocalEntities,
                            isSelfDeterminationAccessOverride: isSelfDeterminationAccessOverride,
                            selfDeterminationAccessOverrideReason: selfDeterminationAccessOverrideReason
                        } as Proxy.ISynchronizeMedicationStatementsControllerDto)
                );

                target.value = {
                    value: null,
                    errors: mapEhiErrors(response.errors)
                };
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public hasDesynchronizedItemsAsync(patientId: PatientId, ehiToken: EhiToken, organizationUnitId: OrganizationUnitId) {
        return this.processOperationAsync(
            new SimpleStore<IEhiResult<boolean>>(),
            async target => {
                const response = await this.client.hasDesynchronizedMedicationStatementsQueryAsync(
                    CreateRequestId(),
                    new Proxy.HasDesynchronizedMedicationStatementsControllerDto(
                        {
                            ehiTokenDto: new Proxy.EhiTokenDto(
                                {
                                    tokenValue: ehiToken.tokenValue,
                                    validFrom: ehiToken.validFrom,
                                    validTo: ehiToken.validTo
                                } as Proxy.IEhiTokenDto),
                            patientId: patientId,
                            organizationUnitId: organizationUnitId
                        } as Proxy.IHasDesynchronizedMedicationStatementsControllerDto)
                );
                target.value = {
                    value: response.hasDesynchronizedItems,
                    errors: null
                };
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getLastSynchronizationDateAsync(patientId: PatientId): Promise<SimpleStore<moment.Moment>> {
        return this.processOperationAsync(
            new SimpleStore<moment.Moment>(),
            async target => {
                const response = await this.client.getMedicationStatementLastSynchronizationDateByPatientIdQueryAsync(
                    CreateRequestId(),
                    patientId.value
                );
                target.value = response.lastSynchronizationDate;
                target.operationInfo = createOperationInfo(response);
            }
        );
    }
}
