import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import {CreateRequestId} from "@HisPlatform/Common/RequestHelper";
import {createOperationInfo} from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import UserGroupId from "@Primitives/UserGroupId.g";
import UserId from "@Primitives/UserId.g";
import PointOfCareRoleAssignmentRule from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Actions/PointOfCareRoleAssignmentRule";

@Di.injectable()
export default class PointOfCareRoleApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IActionsClient") private readonly apiClient: Proxy.IActionsClient
    ) {
        super();
    }

    @State.bound
    public getRoleAssignmentRuleAsync(id: UserGroupId | UserId): Promise<SimpleStore<PointOfCareRoleAssignmentRule[]>> {
        return this.processOperationAsync(
            new SimpleStore<PointOfCareRoleAssignmentRule[]>(),
            async target => {

                const response = id instanceof UserGroupId
                    ? await this.apiClient.getPointOfCareRolesByUserGroupIdQueryAsync(CreateRequestId(), id.value)
                    : await this.apiClient.getPointOfCareRolesByUserIdQueryAsync(CreateRequestId(), id.value);

                target.operationInfo = createOperationInfo(response);
                target.value = response.pointOfCareRoles.map(item => new PointOfCareRoleAssignmentRule(item.roleId, item.pointOfCareIds, item.rowVersion));
            }
        );
    }

    @State.bound
    public setRoleAssignmentRuleOfUserGroupAsync(id: UserGroupId, roles: PointOfCareRoleAssignmentRule[]): Promise<SimpleStore<PointOfCareRoleAssignmentRule[]>> {
        return this.processOperationAsync(
            new SimpleStore<PointOfCareRoleAssignmentRule[]>(),
            async target => {

                const response = await this.apiClient.setPointOfCareRolesOfUserGroupCommandAsync(
                    CreateRequestId(),
                    new Proxy.SetPointOfCareRolesOfUserGroupControllerDto({
                        pointOfCareRoles: roles.map(item => new Proxy.PointOfCareRoleDto({
                            pointOfCareIds: item.pointOfCareIds,
                            roleId: item.roleId,
                            rowVersion: item.rowVersion
                        })),
                        userGroupId: id
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.pointOfCareRoles.map(item => new PointOfCareRoleAssignmentRule(item.roleId, item.pointOfCareIds, item.rowVersion));
            }
        );
    }

    @State.bound
    public setRoleAssignmentRuleOfUserAsync(id: UserId, roles: PointOfCareRoleAssignmentRule[]): Promise<SimpleStore<PointOfCareRoleAssignmentRule[]>> {
        return this.processOperationAsync(
            new SimpleStore<PointOfCareRoleAssignmentRule[]>(),
            async target => {

                const response = await this.apiClient.setPointOfCareRolesOfUserCommandAsync(
                    CreateRequestId(),
                    new Proxy.SetPointOfCareRolesOfUserControllerDto({
                        pointOfCareRoles: roles.map(item => new Proxy.PointOfCareRoleDto({
                            pointOfCareIds: item.pointOfCareIds,
                            roleId: item.roleId,
                            rowVersion: item.rowVersion
                        })),
                        userId: id
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.pointOfCareRoles.map(item => new PointOfCareRoleAssignmentRule(item.roleId, item.pointOfCareIds, item.rowVersion));
            }
        );
    }
}
