import ISearchParametersService from "@Toolkit/CommonWeb/SearchParametersService/Definition/ISearchParametersService";
import Di from "@Di";
import IHostRoutingAdapter from "@Toolkit/ReactClient/Routing/Abstractions/IHostRoutingAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

@Di.injectable()
export default class SearchParametersService implements ISearchParametersService {

    constructor(
        @Di.inject("IHostRoutingAdapter") private readonly hostRoutingAdapter: IHostRoutingAdapter
    ) { }

    public set(prefix: string, name: string, data: string): void {
        const sp = this.getSearchParams(prefix);
        sp.set(`${prefix}-${name}`, data);
        this.setSearchParams(prefix, sp);
    }

    public get(prefix: string, name: string): string {
        const sp = this.getSearchParams(prefix);
        if (name) {
            return sp.get(`${prefix}-${name}`);
        }
        
        return sp.get(`${prefix}`);
    }

    public remove(prefix: string, name: string): void {
        const sp = this.getSearchParams(prefix);
        const paramToRemove = name ? `${prefix}-${name}` : `${prefix}`;
        sp.delete(paramToRemove);
        this.setSearchParams(prefix, sp);
    }

    public setObject(prefix: string, name: string, obj: object, merge: boolean = false): void {
        const sp = this.getSearchParams(prefix);

        if (!merge) {
            const toDelete: string[] = [];
            sp.forEach((value, key) => {
                if (key.startsWith(`${prefix}-${name}`)) {
                    toDelete.push(key);
                }
            });
            toDelete.forEach(k => sp.delete(k));
        }

        Object.entries(obj).forEach(([key, val]) => {

            if (!isNullOrUndefined(val) && typeof val !== "boolean" && typeof val !== "number" && typeof val !== "string") {
                return;
            }

            if (!isNullOrUndefined(val) && (typeof val !== "string" || val.length > 0)) {
                sp.set(`${prefix}-${name}-${key}`, `${val}`);
            } else {
                sp.delete(`${prefix}-${name}-${key}`);
            }
        });

        this.setSearchParams(prefix, sp);
    }

    public getObject(prefix: string, name: string): object {
        const sp = this.getSearchParams(prefix);
        const ret = {};
        sp.forEach((value, key) => {
            const fullName = `${prefix}-${name}`;
            if (key.startsWith(fullName)) {
                ret[key.substr(fullName.length + 1)] = value;
            }
        });
        return ret;
    }

    private getSearchParams(prefix: string): IParamsMap {
        const sp = new URLSearchParams(this.hostRoutingAdapter.currentQueryString);

        const spHasPrefix = (() => {
            let result = false;
            sp.forEach((value, key) => {
                if (key.startsWith(prefix)) {
                    result = true;
                }
            });
            return result;
        })();

        if (spHasPrefix) {
            return sp;
        }

        const spFromSessionRaw = sessionStorage.getItem("datagrid-params-" + prefix);

        if (spFromSessionRaw) {
            const spFromSession = new Map<string, string>(JSON.parse(spFromSessionRaw));
            spFromSession.forEach((value, key) => {
                sp.set(key, value);
            });
        }

        return sp;
    }

    private setSearchParams(prefix: string, sp: IParamsMap): void {
        const map = new Map<string, string>();
        sp.forEach((value, key) => {
            if (key.startsWith(prefix)) {
                map.set(key, value);
            }
        });
        sessionStorage.setItem("datagrid-params-" + prefix, JSON.stringify([...map]));

        this.hostRoutingAdapter.replaceQueryString(sp.toString());
    }
}

interface IParamsMap {
    forEach(callbackFn: (value: string, key: string) => void): void;
    get(key: string): string;
    set(key: string, value: string): void;
    delete(key: string): void;
    toString(): string;
}
