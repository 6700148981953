import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IServiceRequestHeaderExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IServiceRequestHeaderExtensionPointProps";
import EhiErrors from "@HunEHealthInfrastructurePlugin/Common/Model/EhiErrors";
import EhiErrorSummary from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiErrorSummary";

interface IEhiErrorSummaryExtensionPanelDependencies {
}

interface IEhiErrorSummaryExtensionPanelProps extends IServiceRequestHeaderExtensionPointProps {
    _dependencies?: IEhiErrorSummaryExtensionPanelDependencies;
}

@State.observer
export class EhiErrorSummaryExtensionPanel extends React.Component<IEhiErrorSummaryExtensionPanelProps> {

    @State.computed
    private get ehiErrors() {
        return EhiErrors.createFromExtensionDto(this.props.extensionData);
    }

    public render() {
        return (
            <EhiErrorSummary
                errors={this.ehiErrors}
            />
        );
    }
}

export default connect(
    EhiErrorSummaryExtensionPanel,
    new DependencyAdapter<IEhiErrorSummaryExtensionPanelProps, IEhiErrorSummaryExtensionPanelDependencies>(c => {
        return {
        };
    })
);
