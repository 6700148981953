/* auto-inject-disable */
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import IFormEngineReferenceDataStore from "./IFormEngineReferenceDataStore";
import IFormEngineApiAdapter, { FormEngineApiAdapterFactorySymbol } from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import Di from "@Di";
import IFormToken from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/IFormToken";
import IFormEnumsInDefinition from "@Toolkit/FormEngine/Model/IFormEnumsInDefinition";

@Di.injectable()
export default class FormEngineReferenceDataStore implements IFormEngineReferenceDataStore {
    private readonly _formDefinitionPromises = new Map<string, Promise<IFormDefinition>>();
    private readonly _formDefinitions = new Map<string, IFormDefinition>();
    private _formEnumsInDefinitions: IFormEnumsInDefinition[] = null;
    private _formTokens: IFormToken[] = null;

    constructor(
        @Di.inject(FormEngineApiAdapterFactorySymbol) private readonly apiAdapterFactory: () => IFormEngineApiAdapter
    ) { }

    public async getOrLoadDefinitionsByIdsAsync(ids: FormDefinitionId[]): Promise<IFormDefinition[]> {
        return await Promise.all(ids.map(id => this.getOrLoadDefinitionByIdAsync(id)));
    }

    public async getOrLoadDefinitionByIdAsync(id: FormDefinitionId): Promise<IFormDefinition> {
        if (!id) {
            throw new Error("Argument null: 'id'.");
        }

        const formDefinition = this._formDefinitions.get(id.value);
        if(formDefinition) {
            return formDefinition;
        }

        const promise = this.fetchFormDefinitionByIdAsync(id);
        this._formDefinitionPromises.set(id.value, promise);
        return await promise;
    }

    public invalidateSingleDefinitionCache(id: FormDefinitionId): void {
        this._formDefinitionPromises.delete(id.value);
    }

    public getDefinitionById(id: FormDefinitionId): IFormDefinition {
        if (!id) {
            return null;
        }

        return this._formDefinitions.get(id.value) ?? null;
    }

    private async fetchFormDefinitionByIdAsync(id: FormDefinitionId): Promise<IFormDefinition> {
        const result = await this.apiAdapterFactory().getFormDefinitionDetailAsync(id);
        this._formDefinitions.set(id.value, result.value);
        return result.value;
    }

    public async getOrLoadAllTokensAsync(): Promise<IFormToken[]> {

        if (this._formTokens) {
            return this._formTokens;
        }

        const tokensResponse = await this.apiAdapterFactory().getAllFormTokensAsync();
        this._formTokens = tokensResponse.value;
        return this._formTokens;
    }

    public async getOrLoadAllFormEnumsAsync(): Promise<IFormEnumsInDefinition[]> {

        if (this._formEnumsInDefinitions) {
            return this._formEnumsInDefinitions;
        }

        const enums = await this.apiAdapterFactory().getAvailableFormEnumsAsync();
        this._formEnumsInDefinitions = enums.value;
        return this._formEnumsInDefinitions;
    }
}