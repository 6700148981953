import IToolkitStaticResources from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitStaticResources";
import DependencyContainer from "@DiContainer";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";


const StaticToolkitResources: IToolkitStaticResources = {} as any;

export function loadStaticToolkitResources() {
    const localizationService = DependencyContainer.get<IToolkitLocalizationService>("IToolkitLocalizationService");
    Object.assign(StaticToolkitResources, localizationService.staticResources);
}

export default StaticToolkitResources as IToolkitStaticResources;