import React from "react";
import Di from "@Di";
import IBoundedContext from "@HisPlatform/Common/IBoundedContext";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import PractitionerColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PractitionerColumn/PractitionerColumn";
import OrganizationUnitsColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/OrganizationUnitsColumn/OrganizationUnitsColumn";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import CantDeleteOrganizationUnitError from "./ApplicationLogic/ApiAdapter/Structure/CantDeleteOrganizationUnitError";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import PractitionerId from "@Primitives/PractitionerId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import DependencyContainer from "@DiContainer";
import OrganizationReferenceDataStore from "./ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import StaticOrganizationResources from "./StaticResources/StaticOrganizationResources";
import ExternalLocationTypesColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/ExternalLocationTypesColumn/ExternalLocationTypesColumn";
import FormPointOfCareSelectBox from "./Components/Controls/FormPointOfCareSelectBox/FormPointOfCareSelectBox";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import ExternalLocationCodeSelector from "@HisPlatform/BoundedContexts/Organization/Components/Controls/ExternalLocationCodeSelector";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import ExternalLocationColumn from "@HisPlatform/BoundedContexts/Organization/Components/Controls/ExternalLocationColumn/ExternalLocationColumn";
import FormDoctorCodeSelector from "@Toolkit/FormEngine/Panels/FormPanel/LayoutElements/FormDoctorCodeSelector/FormDoctorCodeSelector";
import { AssistantSelector } from "@HisPlatform/Components/HisPlatformControls";

@Di.injectable()
export default class OrganizationBoundedContext implements IBoundedContext {

    public initializeAsync(): Promise<void> {
        return Promise.resolve();
    }

    public configureNData?(configurator: INDataAttributeConfigurator): void {
        configurator.registerColumn("PractitionerId", <PractitionerColumn isCodeBold />);
        configurator.registerColumn("OrganizationUnitId", <OrganizationUnitsColumn />);
        configurator.registerColumn("ExternalLocationTypeId", <ExternalLocationTypesColumn />);
        configurator.registerColumn("ExternalLocationId", <ExternalLocationColumn displayMode="name" />);
    }

    public configureMapper(mapper: IMapperConfigurator) {
        mapper.registerByName("OrganizationUnitHasCareActivitiesError", _ => new CantDeleteOrganizationUnitError());
    }

    public configureFormEngine(registry: FormEditorRegistry): void {
        registry.registerEntityReferenceEditor(
            "Doctor",
            {
                displayName: StaticOrganizationResources.ReferenceData.Practitioner.FormEngineEntityReferenceDisplayName,
                componentType: FormDoctorCodeSelector,
                entityIdCtor: PractitionerId,
                loadReferenceDataAsync: (id: PractitionerId | PractitionerId[]) => {
                    if (Array.isArray(id)) {
                        DependencyContainer.get<OrganizationReferenceDataStore>("OrganizationReferenceDataStore").practitionerMap.ensureLoadedAsync(id);
                    } else {
                        DependencyContainer.get<OrganizationReferenceDataStore>("OrganizationReferenceDataStore").practitionerMap.ensureLoadedAsync([id]);
                    }
                    return Promise.resolve();
                }
            }
        );

        registry.registerEntityReferenceEditor(
            "Assistant",
            {
                displayName: StaticOrganizationResources.ReferenceData.Assistant.FormEngineEntityReferenceDisplayName,
                componentType: AssistantSelector,
                entityIdCtor: PractitionerId,
                loadReferenceDataAsync: (id: PractitionerId | PractitionerId[]) => {
                    if (Array.isArray(id)) {
                        DependencyContainer.get<OrganizationReferenceDataStore>("OrganizationReferenceDataStore").assistantMap.ensureLoadedAsync(id);
                    } else {
                        DependencyContainer.get<OrganizationReferenceDataStore>("OrganizationReferenceDataStore").assistantMap.ensureLoadedAsync([id]);
                    }
                    return Promise.resolve();
                }
            }
        );

        registry.registerEntityReferenceEditor(
            "PointOfCare",
            {
                displayName: StaticOrganizationResources.ReferenceData.PointOfCare.FormEngineEntityReferenceDisplayName,
                componentType: FormPointOfCareSelectBox,
                entityIdCtor: PointOfCareId,
                loadReferenceDataAsync: (id: PointOfCareId | PointOfCareId[]) => {
                    if (Array.isArray(id)) {
                        DependencyContainer.get<OrganizationReferenceDataStore>("OrganizationReferenceDataStore").pointOfCareMap.ensureLoadedAsync(id);
                    } else {
                        DependencyContainer.get<OrganizationReferenceDataStore>("OrganizationReferenceDataStore").pointOfCareMap.ensureLoadedAsync([id]);
                    }
                    return Promise.resolve();
                }
            }
        );

        registry.registerEntityReferenceEditor(
            "ExternalLocation",
            {
                displayName: StaticOrganizationResources.ReferenceData.ExternalLocation.FormEngineEntityReferenceDisplayName,
                componentType: ExternalLocationCodeSelector,
                entityIdCtor: ExternalLocationId,
                loadReferenceDataAsync: (id: ExternalLocationId | ExternalLocationId[]) => {
                    if (Array.isArray(id)) {
                        const entityVersionSelectors = id.map(i => ({ id: i, validOn: DateTimeService.today() }));
                        DependencyContainer.get<OrganizationReferenceDataStore>("OrganizationReferenceDataStore").externalLocationStore.ensureLoadedAsync(entityVersionSelectors);
                    } else {
                        DependencyContainer.get<OrganizationReferenceDataStore>("OrganizationReferenceDataStore").externalLocationStore.ensureLoadedAsync([{ id, validOn: DateTimeService.today() }]);
                    }
                    return Promise.resolve();
                }
            }
        );
    }

}