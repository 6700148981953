/* auto-inject-disable */

import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/Finance/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import AccidentTypeId from "@Primitives/AccidentTypeId.g";
import { GetTypeNameWithPrefix } from "@HunSocialSecurityPlugin/PluginHelper";
import HunFinancingClassId from "@Primitives/HunFinancingClassId.g";
import IdentifierTypeId from "@Primitives/IdentifierTypeId.g";
import FinancedServiceLimitConditionId from "@Primitives/FinancedServiceLimitConditionId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class HunFinanceExtensibleEnumAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject(GetTypeNameWithPrefix("IFinanceClient")) private readonly apiClient: Proxy.IFinanceClient
    ) {
        super();
    }

    @State.bound
    public getAccidentTypeIdsAsync(): Promise<SimpleStore<AccidentTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getAccidentTypeIdsQueryAsync(...args),
            response => response.accidentTypeIds
        );
    }

    @State.bound
    public getFinancingClassIdsAsync(): Promise<SimpleStore<HunFinancingClassId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getHunFinancingClassIdsQueryAsync(...args),
            response => response.hunFinancingClassIds
        );
    }

    @State.bound
    public getIdentifierTypeIdsAsync(): Promise<SimpleStore<IdentifierTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getIdentifierTypeIdsQueryAsync(...args),
            response => response.identifierTypeIds
        );
    }
    
    @State.bound
    public getFinancedServiceLimitConditionIdsAsync(): Promise<SimpleStore<FinancedServiceLimitConditionId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getFinancedServiceLimitConditionIdsQueryAsync(...args),
            response => response.financedServiceLimitConditionIds
        );
    }
}
