import WorklistItemDefinitionStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/WorklistDefinition/WorklistItemDefinitionStore";
import WorklistDefinitionStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/WorklistDefinition/WorklistDefinitionStore";

export interface IFormFieldModalResult {
    updatedFormField: WorklistItemDefinitionStore;
}

export interface IFormFieldModalParams {
    formField: WorklistItemDefinitionStore;
    worklistDefinition: WorklistDefinitionStore;
}

export default class FormFieldModalParams implements IFormFieldModalParams {
    public static type = "FormFieldModal";
    public get type() {
        return FormFieldModalParams.type;
    }

    constructor(
        public readonly formField: WorklistItemDefinitionStore,
        public readonly worklistDefinition: WorklistDefinitionStore
    ) {
    }
}
