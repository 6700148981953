import IDateTimeFormatConfiguration from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatConfiguration";


const HuDateTimeFormatConfiguration = {
    dateFormat: {
        normal: "YYYY. MM. DD.",
        parseFormats: ["YYYY. MM. DD.", "YYYY.MM.DD.", "YYYY.MM.DD", "YYYYMMDD", "MM/DD/YYYY"],
        partialDateRegex: {
            // eslint-disable-next-line no-useless-escape
            pattern: "^(?<year>[0-9]{4})\.(?<month>[0-9]{2})?\.?(?<day>[0-9]{2})?"
        }
    },
    dateTimeFormat: {
        normal: "YYYY. MM. DD. HH:mm:ss",
        withoutSeconds: "YYYY. MM. DD. HH:mm",
        parseFormats: ["YYYY. MM. DD. HH:mm", "YYYY.MM.DD.HH:mm", "YYYY.MM.DD. HH:mm", "YYYYMMDDHHmm", "YYYY. MM. DD. HH:mm:ss", "YYYY.MM.DD.HH:mm:ss", "YYYY.MM.DD. HH:mm:ss", "YYYYMMDDHHmmss"]
    },
    timeFormat: {
        normal: "HH:mm:ss",
        withoutSeconds: "HH:mm",
        parseFormats: ["HH:mm:ss", "HH:mm", "HHmmss", "HHmm"]
    },
    todayString: "ma",
    monthFormat: {
        normal: "YYYY. MM.",
        parseFormats: ["YYYY. MM.", "YYYY.MM.", "YYYY.MM", "YYYYMM", "MM.YYYY"]
    }
} as IDateTimeFormatConfiguration;

export default HuDateTimeFormatConfiguration;