import ILogoutHandlerExtension from "@PluginInterface/BoundedContexts/UserManagement/ILogoutHandlerExtension";
import IEhiTokenRepository from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/Repositories/IEhiTokenRepository";
import HunEHealthInfrastructureUserManagementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/HunEHealthInfrastructureUserManagementApiAdapter";
import Di from "@Di";

@Di.injectable()
export default class LogoutHandlerExtension implements ILogoutHandlerExtension {

    constructor(
        @Di.inject("IEhiTokenRepository") private ehiTokenRepository: IEhiTokenRepository,
        @Di.inject("HunEHealthInfrastructureUserManagementApiAdapter") private ehiUserManagementApiAdapter: HunEHealthInfrastructureUserManagementApiAdapter
    ) { }

    public async logoutAsync(): Promise<void> {
        this.ehiTokenRepository.clearToken();
        await this.ehiUserManagementApiAdapter.logoutAsync();
    }
}