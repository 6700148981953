import PatientId from "@Primitives/PatientId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";

export interface IServiceRequestAppointmentWarningDialogParams {
    patientId: PatientId;
    serviceRequestId: IEntityVersionSelector<ServiceRequestId>;
}

export interface IServiceRequestAppointmentWarningDialogResult {
    close: boolean;
}

export default class ServiceRequestAppointmentWarningDialogParams implements IServiceRequestAppointmentWarningDialogParams {
    public static type = "ServiceRequestAppointmentWarningDialog";

    public get type() { return ServiceRequestAppointmentWarningDialogParams.type; }

    constructor(
        public readonly patientId: PatientId,
        public readonly serviceRequestId: IEntityVersionSelector<ServiceRequestId>
    ) {

    }

}