import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import EhiCareApiAdapter from "./ApiAdapter/EhiCareApiAdapter";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import Di from "@Di";
import EhiEventTypeId from "@Primitives/EhiEventTypeId.g";
import EhiPatientIdentifierTypeId from "@Primitives/EhiPatientIdentifierTypeId.g";
import EhiDischargeReasonId from "@Primitives/EhiDischargeReasonId.g";
import EhiDocumentTypeId from "@Primitives/EhiDocumentTypeId.g";
import OncologyApiAdapter from "./ApiAdapter/OncologyApiAdapter";
import OncologyRegisterExaminationModeId from "@Primitives/OncologyRegisterExaminationModeId.g";
import OncologyRegisterTumorStatusId from "@Primitives/OncologyRegisterTumorStatusId.g";
import OncologyRegisterExtensionId from "@Primitives/OncologyRegisterExtensionId.g";
import OncologyRegisterLateralityId from "@Primitives/OncologyRegisterLateralityId.g";
import EhiEReferralStatusId from "@Primitives/EhiEReferralStatusId.g";
import EhiEReferralTypeId from "@Primitives/EhiEReferralTypeId.g";
import EhiEReferralReasonId from "@Primitives/EhiEReferralReasonId.g";
import EhiEReferralOrganizationTypeId from "@Primitives/EhiEReferralOrganizationTypeId.g";
import EhiEReferralTravelExpenseTypeId from "@Primitives/EhiEReferralTravelExpenseTypeId.g";
import EhiEReferralScheduleStatusId from "@Primitives/EhiEReferralScheduleStatusId.g";
import EhiEReferralProfessionCodeId from "@Primitives/EhiEReferralProfessionCodeId.g";
import TemporalReferenceDataStore from "@Toolkit/CommonWeb/TemporalData/TemporalReferenceDataStore";
import IEhiEReferralProfessionCodeVersion from "./Model/IEhiEReferralProfessionCodeVersion";
import EhiEReferralPatientIdentifierTypeId from "@Primitives/EhiEReferralPatientIdentifierTypeId.g";
import IEhiEReferralPatientIdentifierTypeVersion from "./Model/IEhiEReferralPatientIdentifierTypeVersion";
import EhiEReferralServiceTypeId from "@Primitives/EhiEReferralServiceTypeId.g";
import IEhiEReferralServiceTypeVersion from "./Model/IEhiEReferralServiceTypeVersion";
import EhiHealthcareProviderId from "@Primitives/EhiHealthcareProviderId.g";
import IEhiHealthcareProviderVersion from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IEhiHealthcareProviderVersion";
import EhiHealthcareProviderOrganizationUnitId from "@Primitives/EhiHealthcareProviderOrganizationUnitId.g";
import EhiHealthcareProviderOrganizationUnit from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiHealthcareProviderOrganizationUnit";
import EhiEReferralCovidExaminationSampleTypeId from "@Primitives/EhiEReferralCovidExaminationSampleTypeId.g";
import EhiEReferralCovidExaminationTypeId from "@Primitives/EhiEReferralCovidExaminationTypeId.g";
import EhiEReferralCovidContactExaminationReasonId from "@Primitives/EhiEReferralCovidContactExaminationReasonId.g";
import EhiEReferralCovidScreeningExaminationReasonId from "@Primitives/EhiEReferralCovidScreeningExaminationReasonId.g";
import EhiEReferralCovidSymptomsSeverityId from "@Primitives/EhiEReferralCovidSymptomsSeverityId.g";
import EhiEReferralCovidPreviousResultId from "@Primitives/EhiEReferralCovidPreviousResultId.g";
import EhiEReferralCovidQuickTestResultId from "@Primitives/EhiEReferralCovidQuickTestResultId.g";
import EhiEReferralQueryScenarioCodeId from "@Primitives/EhiEReferralQueryScenarioCodeId.g";
import EhiCovidBreathingStatusId from "@Primitives/EhiCovidBreathingStatusId.g";
import EhiCovidSymptomsSeverityId from "@Primitives/EhiCovidSymptomsSeverityId.g";
import EhiCovidSymptomStatusId from "@Primitives/EhiCovidSymptomStatusId.g";
import EhiCovidUrineAmountId from "@Primitives/EhiCovidUrineAmountId.g";
import EhiCovidVentilationStatusId from "@Primitives/EhiCovidVentilationStatusId.g";
import EhiSelfDeterminationId from "@Primitives/EhiSelfDeterminationId.g";
import EhiMedicalConditionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiMedicalConditionApiAdapter";
import TherapyId from "@Primitives/TherapyId.g";
import TherapiesApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/TherapiesApiAdapter";
import ITherapy from "./Model/ITherapy";
import EhiInpatientAdmissionTypeId from "@Primitives/EhiInpatientAdmissionTypeId.g";
import EhiInpatientAdmissionClassificationId from "@Primitives/EhiInpatientAdmissionClassificationId.g";
import EhiPatientFurtherTreatmentId from "@Primitives/EhiPatientFurtherTreatmentId.g";
import EhiEmergencyLevelClaimTypeId from "@Primitives/EhiEmergencyLevelClaimTypeId.g";
import EhiEmergencyExaminationClaimTypeId from "@Primitives/EhiEmergencyExaminationClaimTypeId.g";
import EhiCovidSelfExaminationTimeCodeId from "@Primitives/EhiCovidSelfExaminationTimeCodeId.g";
import EhiCovidSelfExaminationGeneralStatusId from "@Primitives/EhiCovidSelfExaminationGeneralStatusId.g";
import EhiStructuredDocumentContactTypeId from "@Primitives/EhiStructuredDocumentContactTypeId.g";
import RatDeviceId from "@Primitives/RatDeviceId.g";
import RatDeviceApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/RatDeviceApiAdapter";
import IRatDevice from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IRatDevice";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";

@Di.injectable()
export default class HunEhiCareReferenceDataStore extends ReferenceDataStoreBase {

    constructor(
        @Di.inject("EhiCareApiAdapter") private ehiCareApiAdapter: EhiCareApiAdapter,
        @Di.inject("ILocalizationService") private localizationService: ILocalizationService,
        @Di.inject("OncologyApiAdapter") private oncologyApiAdapter: OncologyApiAdapter,
        @Di.inject("EhiMedicalConditionApiAdapter") private medicalConditionApiAdapter: EhiMedicalConditionApiAdapter,
        @Di.inject("TherapiesApiAdapter") private therapiesApiAdapter: TherapiesApiAdapter,
        @Di.inject("RatDeviceApiAdapter") private ratDeviceApiAdapter: RatDeviceApiAdapter,        
        @Di.inject("IReferenceDataLoader") private readonly referenceDataLoader: IReferenceDataLoader
    ) {
        super();

        referenceDataLoader.register<RatDeviceId>(RatDeviceId.typeName, ids => this.ratDevices.ensureLoadedAsync(ids));
    }

    public euCardReplacementTypeMap = new ExtensibleEnumStore(this.ehiCareApiAdapter.getEuCardReplacementTypeIdsAsync, this.localizationService.localizeEnumId);
    public ehiEventTypes = new ExtensibleEnumStore<EhiEventTypeId>(this.ehiCareApiAdapter.loadEhiEventTypesAsync, this.localizationService.localizeEnumId);
    public ehiPatientIdentifierTypes = new ExtensibleEnumStore<EhiPatientIdentifierTypeId>(this.ehiCareApiAdapter.loadEhiPatientIdentifierTypesAsync, this.localizationService.localizeEnumId);
    public ehiDischargeReasons = new ExtensibleEnumStore<EhiDischargeReasonId>(this.ehiCareApiAdapter.loadEhiDischargeReasonsAsync, this.localizationService.localizeEnumId);
    public ehiDocumentTypes = new ExtensibleEnumStore<EhiDocumentTypeId>(this.ehiCareApiAdapter.loadEhiDocumentTypesAsync, this.localizationService.localizeEnumId);

    public ehiCareTypes = new ReferenceDataStore(this.ehiCareApiAdapter.loadEhiCareTypesByIdsAsync, this.ehiCareApiAdapter.loadAllEhiCareTypeIdsAsync);
    public therapies = new ReferenceDataStore<TherapyId, ITherapy>(this.therapiesApiAdapter.loadTherapiesAsync, this.therapiesApiAdapter.getAllIdsAsync);
    public ratDevices = new ReferenceDataStore<RatDeviceId, IRatDevice>(this.ratDeviceApiAdapter.loadRatDevicesAsync, this.ratDeviceApiAdapter.getAllIdsAsync);

    public oncologyRegisterExaminationModes = new ExtensibleEnumStore<OncologyRegisterExaminationModeId>(this.oncologyApiAdapter.loadOncologyRegisterExaminationModeIdsAsync, this.localizationService.localizeEnumId);
    public oncologyRegisterFurtherTreatment = new ExtensibleEnumStore<OncologyRegisterExaminationModeId>(this.oncologyApiAdapter.loadOncologyRegisterFurtherTreatmentIdsAsync, this.localizationService.localizeEnumId);
    public oncologyRegisterTumorStatus = new ExtensibleEnumStore<OncologyRegisterTumorStatusId>(this.oncologyApiAdapter.loadOncologyRegisterTumorStatusIdsAsync, this.localizationService.localizeEnumId);
    public oncologyRegisterExtension = new ExtensibleEnumStore<OncologyRegisterExtensionId>(this.oncologyApiAdapter.loadOncologyRegisterExtensionIdsAsync, this.localizationService.localizeEnumId);
    public oncologyRegisterLaterality = new ExtensibleEnumStore<OncologyRegisterLateralityId>(this.oncologyApiAdapter.loadOncologyRegisterLateralityIdsAsync, this.localizationService.localizeEnumId);
    public cancerClassifications = new ReferenceDataStore(this.oncologyApiAdapter.getCancerClassificationsByIdsAsync, this.oncologyApiAdapter.getAllCancerClassificationIdsAsync);

    public ehiEReferralStatuses = new ExtensibleEnumStore<EhiEReferralStatusId>(this.ehiCareApiAdapter.loadEhiEReferralStatusIdsAsync, this.localizationService.localizeEnumId);
    public ehiEReferralTypes = new ExtensibleEnumStore<EhiEReferralTypeId>(this.ehiCareApiAdapter.loadEhiEReferralTypeIdsAsync, this.localizationService.localizeEnumId);
    public ehiEReferralReasons = new ExtensibleEnumStore<EhiEReferralReasonId>(this.ehiCareApiAdapter.loadEhiEReferralReasonIdsAsync, this.localizationService.localizeEnumId);
    public ehiEReferralOrganizationTypes = new ExtensibleEnumStore<EhiEReferralOrganizationTypeId>(this.ehiCareApiAdapter.loadEhiEReferralOrganizationTypeIdsAsync, this.localizationService.localizeEnumId);
    public ehiEReferralTravelExpenseTypes = new ExtensibleEnumStore<EhiEReferralTravelExpenseTypeId>(this.ehiCareApiAdapter.loadEhiEReferralTravelExpenseTypeIdsAsync, this.localizationService.localizeEnumId);
    public ehiEReferralScheduleStatuses = new ExtensibleEnumStore<EhiEReferralScheduleStatusId>(this.ehiCareApiAdapter.loadEhiEReferralScheduleStatusIdsAsync, this.localizationService.localizeEnumId);

    public ehiEReferralCovidExaminationSampleTypes = new ExtensibleEnumStore<EhiEReferralCovidExaminationSampleTypeId>(this.ehiCareApiAdapter.loadEhiEReferralCovidExaminationSampleTypeIdsAsync, this.localizationService.localizeEnumId);
    public ehiEReferralCovidExaminationTypes = new ExtensibleEnumStore<EhiEReferralCovidExaminationTypeId>(this.ehiCareApiAdapter.loadEhiEReferralCovidExaminationTypeIdsAsync, this.localizationService.localizeEnumId);
    public ehiEReferralCovidContactExaminationReasons = new ExtensibleEnumStore<EhiEReferralCovidContactExaminationReasonId>(this.ehiCareApiAdapter.loadEhiEReferralCovidContactExaminationReasonIdsAsync, this.localizationService.localizeEnumId);
    public ehiEReferralCovidScreeningExaminationReasons = new ExtensibleEnumStore<EhiEReferralCovidScreeningExaminationReasonId>(this.ehiCareApiAdapter.loadEhiEReferralCovidScreeningExaminationReasonIdsAsync, this.localizationService.localizeEnumId);
    public ehiEReferralCovidSymptomsSeverities = new ExtensibleEnumStore<EhiEReferralCovidSymptomsSeverityId>(this.ehiCareApiAdapter.loadEhiEReferralCovidSymptomsSeverityIdsAsync, this.localizationService.localizeEnumId);
    public ehiEReferralCovidPreviousResults = new ExtensibleEnumStore<EhiEReferralCovidPreviousResultId>(this.ehiCareApiAdapter.loadEhiEReferralCovidPreviousResultIdsAsync, this.localizationService.localizeEnumId);
    public ehiEReferralCovidQuickTestResults = new ExtensibleEnumStore<EhiEReferralCovidQuickTestResultId>(this.ehiCareApiAdapter.loadEhiEReferralCovidQuickTestResultIdsAsync, this.localizationService.localizeEnumId);
    public ehiCovidBreathingStatuses = new ExtensibleEnumStore<EhiCovidBreathingStatusId>(this.ehiCareApiAdapter.loadEhiCovidBreathingStatusIdsAsync, this.localizationService.localizeEnumId);
    public ehiCovidSymptomsSeverities = new ExtensibleEnumStore<EhiCovidSymptomsSeverityId>(this.ehiCareApiAdapter.loadEhiCovidSymptomsSeverityIdsAsync, this.localizationService.localizeEnumId);
    public ehiCovidSymptomStatuses = new ExtensibleEnumStore<EhiCovidSymptomStatusId>(this.ehiCareApiAdapter.loadEhiCovidSymptomStatusIdsAsync, this.localizationService.localizeEnumId);
    public ehiCovidUrineAmounts = new ExtensibleEnumStore<EhiCovidUrineAmountId>(this.ehiCareApiAdapter.loadEhiCovidUrineAmountIdsAsync, this.localizationService.localizeEnumId);
    public ehiCovidVentilationStatuses = new ExtensibleEnumStore<EhiCovidVentilationStatusId>(this.ehiCareApiAdapter.loadEhiCovidVentilationStatusIdsAsync, this.localizationService.localizeEnumId);
    public ehiCovidSelfExaminationTimeCodes = new ExtensibleEnumStore<EhiCovidSelfExaminationTimeCodeId>(this.ehiCareApiAdapter.loadEhiCovidSelfExaminationTimeCodeIdsAsync, this.localizationService.localizeEnumId);
    public ehiCovidSelfExaminationGeneralStatusIds = new ExtensibleEnumStore<EhiCovidSelfExaminationGeneralStatusId>(this.ehiCareApiAdapter.loadEhiCovidSelfExaminationGeneralStatusIdsAsync, this.localizationService.localizeEnumId);
    public ehiStructuredDocumentContactTypes = new ExtensibleEnumStore<EhiStructuredDocumentContactTypeId>(this.ehiCareApiAdapter.loadEhiStructuredDocumentContactTypeIdsAsync, this.localizationService.localizeEnumId);

    public ehiEReferralQueryScenarioCodes = new ExtensibleEnumStore<EhiEReferralQueryScenarioCodeId>(this.ehiCareApiAdapter.loadEhiEReferralQueryScenarioCodeIdsAsync, this.localizationService.localizeEnumId);

    public ehiEReferralPatientIdentifierTypes = new TemporalReferenceDataStore<EhiEReferralPatientIdentifierTypeId, IEhiEReferralPatientIdentifierTypeVersion>(this.ehiCareApiAdapter.getEhiEReferralPatientIdentifierTypeVersionsBySelectorsAsync, this.ehiCareApiAdapter.getAllEhiEReferralPatientIdentifierTypeIdsAsync);
    public ehiEReferralProfessionCodes = new TemporalReferenceDataStore<EhiEReferralProfessionCodeId, IEhiEReferralProfessionCodeVersion>(this.ehiCareApiAdapter.getEhiEReferralProfessionCodeVersionsBySelectorsAsync, this.ehiCareApiAdapter.getAllEhiEReferralProfessionCodeIdsAsync);
    public ehiEReferralServiceTypes = new TemporalReferenceDataStore<EhiEReferralServiceTypeId, IEhiEReferralServiceTypeVersion>(this.ehiCareApiAdapter.getEhiEReferralServiceTypeVersionsBySelectorsAsync, this.ehiCareApiAdapter.getAllEhiEReferralServiceTypeIdsAsync);

    public ehiHealthcareProviders = new TemporalReferenceDataStore<EhiHealthcareProviderId, IEhiHealthcareProviderVersion>(this.ehiCareApiAdapter.getEhiHealthcareProviderVersionsBySelectorsAsync, this.ehiCareApiAdapter.getAllEhiHealthcareProviderIdsAsync);
    public ehiHealthcareProviderOrganizationUnits = new ReferenceDataStore<EhiHealthcareProviderOrganizationUnitId, EhiHealthcareProviderOrganizationUnit>(this.ehiCareApiAdapter.getEhiHealthcareProviderOrganizationUnitsByIdsAsync, this.ehiCareApiAdapter.getAllEhiHealthcareProviderOrganizationUnitIdsAsync);

    public ehiSelfDeterminations = new ExtensibleEnumStore<EhiSelfDeterminationId>(this.medicalConditionApiAdapter.loadEhiSelfDeterminationIdsAsync, this.localizationService.localizeEnumId);

    public ehiInpatientAdmissionTypes = new ExtensibleEnumStore<EhiInpatientAdmissionTypeId>(this.ehiCareApiAdapter.loadEhiInpatientAdmissionTypesAsync, this.localizationService.localizeEnumId);
    public ehiInpatientAdmissionClassifications = new ExtensibleEnumStore<EhiInpatientAdmissionClassificationId>(this.ehiCareApiAdapter.loadEhiInpatientAdmissionClassificationsAsync, this.localizationService.localizeEnumId);
    public ehiPatientFurtherTreatments = new ExtensibleEnumStore<EhiPatientFurtherTreatmentId>(this.ehiCareApiAdapter.loadEhiPatientFurtherTreatmentsAsync, this.localizationService.localizeEnumId);
    public ehiEmergencyLevelClaimTypes = new ExtensibleEnumStore<EhiEmergencyLevelClaimTypeId>(this.ehiCareApiAdapter.loadEhiEmergencyLevelClaimTypesAsync, this.localizationService.localizeEnumId);
    public ehiEmergencyExaminationClaimTypes = new ExtensibleEnumStore<EhiEmergencyExaminationClaimTypeId>(this.ehiCareApiAdapter.loadEhiEmergencyExaminationClaimTypesAsync, this.localizationService.localizeEnumId);
}
