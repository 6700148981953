import PractitionerRecommendationId from "@Primitives/PractitionerRecommendationId.g";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/Proxy.g";

export function getPractitionerDocumentDto(
    practitionerRecommendationId: PractitionerRecommendationId,
    documentTypeCode?: string,
    isPrimary?: boolean,
    isAutomatic?: boolean
) {
    return new Proxy.GetSingleDocumentForPractitionerRecommendationControllerDto({
        documentTypeCode: documentTypeCode,
        practitionerRecommendationId: practitionerRecommendationId,
        isPrimary: isPrimary,
        isAutomatic: isAutomatic
    });
}