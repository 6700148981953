import Di from "@Di";
import config from "@Config";
import IAuthenticationService from "@HisPlatform/Services/Definition/Authentication/IAuthenticationService";
import AccessToken from "@Toolkit/CommonWeb/Authentication/Definition/AccessToken";
import LoginResult from "@Toolkit/CommonWeb/Authentication/Definition/LoginResult";
import ITokenRepository from "@Toolkit/CommonWeb/Repositories/Definition/TokenRepository/ITokenRepository";
import IHeaderProviderService from "@HisPlatform/Services/Definition/HeaderProvider/IHeaderProviderService";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import moment from "moment";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import * as oauth from "oauth4webapi";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import sha256 from "crypto-js/sha256";
import Base64 from 'crypto-js/enc-base64Url';

@Di.injectable()
export default class AuthenticationService implements IAuthenticationService {

    private currentToken: AccessToken = null;
    private isTokenValidated: boolean = false;

    public onTriedLogin = new TypedEvent<LoginResult>();
    public onLogout = new TypedEvent();
    public onLoggingOutAsync = new TypedAsyncEvent();

    constructor(
        @Di.inject("ITokenRepository") private tokenRepository: ITokenRepository,
        @Di.inject("IHeaderProviderService") private readonly headerProviderService: IHeaderProviderService
    ) {

        const existingToken = this.tokenRepository.loadAccessToken();

        if (existingToken) {
            this.setCurrentToken(existingToken);
            this.isTokenValidated = false;
        }

    }

    private setCurrentToken(value: AccessToken) {
        this.currentToken = value;
        this.headerProviderService.setToken(value && value.tokenValue);
    }

    public async logOutAsync() {
        await this.onLoggingOutAsync.emitAsync();
        this.setUnauthenticated();
        this.onLogout.emit();
        
        const { oAuthServer, client } = await this.initializeOAuthAsync();
        if (oAuthServer.end_session_endpoint) {
            const url = new URL(oAuthServer.end_session_endpoint!);
            url.searchParams.set('client_id', client.client_id);
            url.searchParams.set('post_logout_redirect_uri', `${self.location.protocol}//${self.location.host}/logoutCallback`);
            window.location.href = url.toString();
        }        
    }

    public setUnauthenticated() {
        this.setCurrentToken(null);
        this.isTokenValidated = false;
        this.tokenRepository.clearAccessToken();
    }

    public async isLoggedInAsync(): Promise<boolean> {

        if (this.currentToken === undefined ||
            this.currentToken === null) {
            return false;
        }

        if (this.isTokenValidated === false) {
            const success = await this.renewTokenAsync();

            if (success === false) {
                return false;
            }
        }

        if (this.currentToken.expiresOn.isBefore(DateTimeService.now())) {
            await this.logOutAsync();
            return false;
        }

        return true;

    }

    public async renewTokenAsync(): Promise<boolean> {

        const { oAuthServer, client } = await this.initializeOAuthAsync();

        const response = await oauth.refreshTokenGrantRequest(oAuthServer, client, this.currentToken.refreshTokenValue);
        const result = await oauth.processRefreshTokenResponse(oAuthServer, client, response);

        if (result.error) {
            this.isTokenValidated = false;
            return false;
        }


        this.setCurrentToken(AccessToken.NowRequested(result.access_token as string, result.expires_in as number, result.refresh_token as string));
        this.isTokenValidated = true;
        return true;
    }

    public getCurrentToken(): string {
        if (this.currentToken === null) {
            return null;
        }
        return this.currentToken.tokenValue;
    }

    @State.bound
    public tokenWillBeExpiredIn(seconds: number): boolean {
        if (this.currentToken === null) {
            return true;
        }

        return moment().add(seconds, "seconds").isAfter(this.currentToken.expiresOn);
    }

    public async authenticateAsync(returnUrl: string): Promise<void> {
        const { oAuthServer, client } = await this.initializeOAuthAsync();

        const redirect_uri = `${self.location.protocol}//${self.location.host}/loginCallback`;

        if (oAuthServer.code_challenge_methods_supported?.includes('S256') !== true) {
            // No S256 PKCE support, error!
            throw new Error();
        }

        const code_verifier = oauth.generateRandomCodeVerifier();
        sessionStorage.setItem("auth-code-verifier", code_verifier);
        const code_challenge = await this.getSha256Async(code_verifier);
        const code_challenge_method = 'S256';

        const authorizationUrl = new URL(oAuthServer.authorization_endpoint!);
        authorizationUrl.searchParams.set('client_id', client.client_id);
        authorizationUrl.searchParams.set('code_challenge', code_challenge);
        authorizationUrl.searchParams.set('code_challenge_method', code_challenge_method);
        authorizationUrl.searchParams.set('redirect_uri', redirect_uri);
        authorizationUrl.searchParams.set('response_type', 'code');
        authorizationUrl.searchParams.set('scope', 'openid');
        authorizationUrl.searchParams.set('state', Base64Converter.fromString(returnUrl));
        window.location.href = authorizationUrl.toString();
    }

    private async getSha256Async(input: string): Promise<string> {
        if (window.crypto?.subtle) {
            return await oauth.calculatePKCECodeChallenge(input);
        }

        if (config.developerMode) {
            return Base64.stringify(sha256(input));
        }

        throw new Error("Cannot calculate SHA256 hash.");
    }

    public async processAuthenticationCallbackAsync(): Promise<{ returnUrl: string }> {

        const { oAuthServer, client } = await this.initializeOAuthAsync();

        const code_verifier = sessionStorage.getItem("auth-code-verifier");
        sessionStorage.removeItem("auth-code-verifier");
        const redirect_uri = `${self.location.protocol}//${self.location.host}/loginCallback`;

        const currentUrl: URL = new URL(window.location.href);
        const params = oauth.validateAuthResponse(oAuthServer, client, currentUrl, oauth.skipStateCheck);
        if (oauth.isOAuth2Error(params)) {
            console.log('error', params);
            throw new Error(); // Handle OAuth 2.0 redirect error?
        }

        const response = await oauth.authorizationCodeGrantRequest(
            oAuthServer,
            client,
            params,
            redirect_uri,
            code_verifier,
        );

        let challenges: oauth.WWWAuthenticateChallenge[] | undefined;
        if ((challenges = oauth.parseWwwAuthenticateChallenges(response))) {
            for (const challenge of challenges) {
                console.log('challenge', challenge);
            }
            throw new Error(); // Handle www-authenticate challenges as needed
        }

        const result = await oauth.processAuthorizationCodeOpenIDResponse(oAuthServer, client, response);
        if (oauth.isOAuth2Error(result)) {
            console.log('error', result);
            throw new Error(); // Handle OAuth 2.0 response body error
        }

        this.setCurrentToken(AccessToken.NowRequested(result.access_token, result.expires_in, result.refresh_token));
        this.isTokenValidated = true;
        this.onTriedLogin.emit(LoginResult.Success);

        return {
            returnUrl: Base64Converter.toString(params.get("state"))
        };
    }

    private oAuthServerPromise: Promise<oauth.AuthorizationServer> | null = null;

    private async initializeOAuthAsync(): Promise<{ oAuthServer: oauth.AuthorizationServer, client: oauth.Client }> {

        this.oAuthServerPromise ??= (async () => {
            const issuer = new URL(config.oAuth.issuerUrl);
            return await oauth
                .discoveryRequest(issuer)
                .then((response) => oauth.processDiscoveryResponse(issuer, response));
        })();

        return {
            oAuthServer: await this.oAuthServerPromise,
            client: {
                client_id: config.oAuth.clientId,
                token_endpoint_auth_method: 'none',
            }
        };
    }
}
