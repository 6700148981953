import Button, { IButtonProps } from "@CommonControls/Button";
import IShowScreenActionCallContextParams from "@HisPlatform/Services/Definition/ActionProcessing/IShowScreenActionCallContextParams";
import { useScreenNavigationContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import { FrontendActionPermissionScope } from "@Toolkit/ReactClient/ActionProcessing/FrontendActionPermissionScope";
import { usePermissionScopes } from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/PermissionScopeProvider";
import React, { useCallback, useMemo } from "react";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import useModalService from "@Toolkit/ReactClient/Components/ModalService/useModalService";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import { useHisActionDispatcher } from "@HisPlatform/Common/FrontendActions/HisActionDispatcher";
import { actionVisualStyleToButton } from "@Toolkit/ReactClient/ActionProcessing/ActionVisualStyle";

interface INDataRowFrontendActionButtonProps extends IButtonProps {
    descriptor: ActionDescriptor;
    scopes?: FrontendActionPermissionScope[];
    rowId: string;
}

export default function NDataRowFrontendActionButton(props: INDataRowFrontendActionButtonProps) {

    const { authorizationService } = useDependencies(c => ({
        authorizationService: c.resolve<AuthorizationService>("AuthorizationService")
    }));

    const action = props.descriptor.action;

    const contextScopes = usePermissionScopes();
    const isPermitted = useMemo(() => authorizationService.hasPermissionFor(
        action.id,
        ...[...props.descriptor.requiredPermissionScopes ?? [], ...contextScopes, ...(props.scopes ?? [])]
    ), [action.id, props.scopes]);

    const actionDispatcher = useHisActionDispatcher();

    const clickAsync = useCallback(async () => {
        let primaryScreenState: string = null;

        if (action instanceof ShowScreenFrontendActionBase) {
            switch (action.displayMode) {
                case ScreenDisplayMode.Detail:
                case ScreenDisplayMode.Modal:
                    primaryScreenState = props.rowId;
                    break;
            }

            await actionDispatcher.dispatchAsync(
                action,
                { primaryScreenState }
            );
        } else {
            await actionDispatcher.dispatchAsync(action);
        }

    }, [action, props.rowId, actionDispatcher]);

    const displaySettings = useMemo(() => actionDispatcher.getDisplaySettings(action), [action]);

    return (
        <Button
            iconName={displaySettings.iconName}
            tooltipContent={displaySettings.displayName}
            visualStyle={actionVisualStyleToButton(props.descriptor.presentation?.visualStyle)}
            disabled={props.descriptor.presentation?.isEnabled === false}
            {...props}
            isNotPermitted={!isPermitted}
            onClickAsync={clickAsync}
            stopPropagation
        />
    );
}