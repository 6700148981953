import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import * as Ui from "@CommonControls";
import IAdmitPatientAndCareActivityBaseDataScreenInfoBoxExtension from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IAdmitPatientAndCareActivityBaseDataScreenInfoBoxExtension";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";

const HasValidEhiIdentifierExtensionDataKey: string = "HasValidEhiIdentifier";

@State.observer
export default class AdmitPatientAndCareActivityBaseDataScreenInfoBoxExtension extends React.Component<IAdmitPatientAndCareActivityBaseDataScreenInfoBoxExtension> {
    public render() {
        const shouldRender = this.props.extensionData
                             && HasValidEhiIdentifierExtensionDataKey in this.props.extensionData
                             && this.props.extensionData[HasValidEhiIdentifierExtensionDataKey] === false;
        if (!shouldRender) {
            return null;
        }

        return (
            <Ui.InfoBox iconName="info_with_circle">
                {StaticHunEHealthInfrastructureCareResources.AdmitPatientAndCareActivityBaseDataScreen.InfoBox.MissingEhiPatientIdentifierMessage}
            </Ui.InfoBox>
        );
    }
}