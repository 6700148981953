import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import * as BffProxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import DiagnosisListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisListStore";
import DiagnosisStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisStore";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import ConditionId from "@Primitives/ConditionId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import Di from "@Di";

type TResponse = Proxy.GetDiagnosisListCommandResponse
                 | Proxy.UpdateDiagnosisListCommandResponse
                 | BffProxy.GetServiceRequestScreenDataCommandResponse;

@Di.injectable()
export default class DiagnosisListStoreMapper extends LockingEntityStoreMapper<Proxy.DiagnosisListDto, DiagnosisListStore, TResponse> {
    protected storeEntityIdType = CareActivityId;

    protected entityDtoSelector(response: TResponse) {
        return response.diagnosisList;
    }

    protected applyToStoreCore(target: DiagnosisListStore, response: TResponse) {
        const dto = response.diagnosisList;
        target.diagnoses = dto && dto.diagnosisList && dto.diagnosisList.map(this.mapDiagnosis);
    }
    
    private mapDiagnosis(dto: Proxy.DiagnosisDto): DiagnosisStore {
        const result = new DiagnosisStore();
        result.id = dto.id;
        result.conditionVersionSelector = new EntityVersionSelector<ConditionId>(dto.conditionVersion.entityId, dto.conditionVersion.validOn);
        result.lateralityId = dto.lateralityId;
        result.use = dto.use;
        result.extensionData = dto.extensionData;
        return result;
    }

    protected vGetStoreIdentifier() {
        return "DiagnosisListStore";
    }
}
