import React from "react";
import * as Ui from "@CommonControls";
import StaticConfig from "@Config";
import JiraInfoPanel from "./JiraInfoPanel";
import TfsInfoPanel from "./TfsInfoPanel";
import TraceLogPanel from "@HisPlatform/Components/Panels/TraceLogPanel/TraceLogPanel";
import useApplicationVersion from "@HisPlatform/BoundedContexts/Diagnostics/Hooks/useApplicationVersion";
import config from "@Config";

interface IApplicationInfoModalProps {
    isOpen: boolean;
    onClose: () => void;
}


const ApplicationInfoModal: React.FC<IApplicationInfoModalProps> = props => {

    const [version, _] = useApplicationVersion();

    return (
        <Ui.Modal isOpen={props.isOpen} onClose={props.onClose} title="MedWorkS" fixedHeight="75%" automationId="applicationInfoModal">
            <Ui.Modal.Body>
                <pre>
                    Verzia: {version}<br />
                    Prostredie: {StaticConfig.environmentName}<br />
                    Vydané o: {StaticConfig.releasedAt}<br />
                </pre>
                <Ui.Tab>
                    {/* {
                        config.testFeatures.technicalControlsEnabled && <Ui.TabPane title="Jira" automationId="jira">
                            <JiraInfoPanel />
                        </Ui.TabPane>
                    } */}
                    {
                        config.testFeatures.technicalControlsEnabled && <Ui.TabPane title="Tfs change" automationId="tfsChange">
                            <TfsInfoPanel />
                        </Ui.TabPane>
                    }
                    <Ui.TabPane title="Tracelog" automationId="tracelog">
                        <TraceLogPanel />
                    </Ui.TabPane>
                </Ui.Tab>

            </Ui.Modal.Body>
        </Ui.Modal>
    );
};

export default ApplicationInfoModal;