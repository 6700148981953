import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

export default class TreatmentDetail {
    @State.observable.ref public length: string;
    @State.observable.ref public startDate: LocalDate;

    constructor(length: string, startDate: LocalDate) {
        this.length = length;
        this.startDate = startDate;
    }

    @State.action.bound
    public setLength(newValue: string) {
        this.length = newValue;
    }

    @State.action.bound
    public setStartDate(newValue: LocalDate) {
        this.startDate = newValue;
    }    
}
