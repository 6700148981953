exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HeaderCareActivityCoverageData_separator-8_2TXcn {\n  margin-left: 8px;\n}\n", ""]);

// exports
exports.locals = {
	"separator-8": "HeaderCareActivityCoverageData_separator-8_2TXcn",
	"separator8": "HeaderCareActivityCoverageData_separator-8_2TXcn"
};