import AdmitPatientAndCareActivityBaseDataScreenFormOutpatientBaseFormLogic from "@HisPlatform/Packages/Care/FormLogics/AdmitPatientAndCareActivityBaseDataScreenFormOutpatientBaseFormLogic";
import EpisodeOfCareScreenFormBaseFormLogic from "@HisPlatform/Packages/Care/FormLogics/EpisodeOfCareScreenFormBaseFormLogic";
import DependencyContainer from "@DiContainer";
import PatientAdministrativeDataScreenFormLogic from "@HisPlatform/Packages/Patients/FormLogics/PatientAdministrativeDataScreenFormLogic";
import InvoiceScreenFormLogic from "@HisPlatform/Packages/Patients/FormLogics/InvoiceScreenFormLogic";
import CustomerScreenFormLogic from "@HisPlatform/Packages/Settings/FormLogics/CustomerScreenFormLogic";

DependencyContainer
    .bind("AdmitPatientAndCareActivityBaseDataScreenFormOutpatientBaseFormLogic")
    .to(AdmitPatientAndCareActivityBaseDataScreenFormOutpatientBaseFormLogic)
    .inTransientScope();

DependencyContainer
    .bind("EpisodeOfCareScreenFormBaseFormLogic")
    .to(EpisodeOfCareScreenFormBaseFormLogic)
    .inTransientScope();

DependencyContainer
    .bind("PatientAdministrativeDataScreenFormLogic")
    .to(PatientAdministrativeDataScreenFormLogic)
    .inTransientScope();

DependencyContainer
    .bind("InvoiceScreenFormLogic")
    .to(InvoiceScreenFormLogic)
    .inTransientScope();

DependencyContainer
    .bind("CustomerScreenFormLogic")
    .to(CustomerScreenFormLogic)
    .inTransientScope();