exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SidebarLayout_no-text-selection_1JmSd {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n.SidebarLayout_container_3QAE5 {\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n}\n.SidebarLayout_sidebar_23EoZ {\n  flex: 0 0 auto;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n}\n.SidebarLayout_body_2V2DV {\n  flex: 1;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n}\n", ""]);

// exports
exports.locals = {
	"no-text-selection": "SidebarLayout_no-text-selection_1JmSd",
	"noTextSelection": "SidebarLayout_no-text-selection_1JmSd",
	"container": "SidebarLayout_container_3QAE5",
	"sidebar": "SidebarLayout_sidebar_23EoZ",
	"body": "SidebarLayout_body_2V2DV"
};