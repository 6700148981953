import State from "@Toolkit/ReactClient/Common/StateManaging";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import RoutingFrame from "@Toolkit/ReactClient/Routing/RoutingFrame";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import StandaloneAppointmentRoutes from "@HisPlatform/Components/Pages/AppointmentPages/StandaloneAppointmentRoutes";
import StandaloneRegisteredPatientAppointmentsPage from "@HisPlatform/Components/Pages/AppointmentPages/StandaloneRegisteredPatientAppointmentsPage";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import StandaloneUnregisteredPatientAppointmentPage from "@HisPlatform/Components/Pages/AppointmentPages/StandaloneUnregisteredPatientAppointmentPage";
import StandAlonePractitionerAppointmentsPage from "@HisPlatform/Components/Pages/AppointmentPages/StandalonePractitionerAppointmentsPage";
import InvalidAppointmentsPage from "@HisPlatform/Components/Pages/AppointmentPages/InvalidAppointmentsPage";

interface IAppointmentsPageDependencies {
    applicationContext: ApplicationContext;
}

interface IAppointmentsPageProps extends IRoutingFrameContentProps {
    _dependencies?: IAppointmentsPageDependencies;
}

@State.observer
class AppointmentPage extends React.Component<IAppointmentsPageProps> {

    private get applicationContext() { return this.props._dependencies.applicationContext; }

    public render() {
        return (
            <RoutingFrame
                routeDefinitions={StandaloneAppointmentRoutes}
                fallbackRoute={StandaloneAppointmentRoutes.registeredPatientAppointments}
                parentRouteDefinition={ApplicationRoutes.appointments}
                onRoutingControllerChanged={this.setCurrentRoute}>
                <RoutingFrame.Case route={StandaloneAppointmentRoutes.registeredPatientAppointments} component={StandaloneRegisteredPatientAppointmentsPage} />
                <RoutingFrame.Case route={StandaloneAppointmentRoutes.unregisteredPatientAppointments} component={StandaloneUnregisteredPatientAppointmentPage}/>
                <RoutingFrame.Case route={StandaloneAppointmentRoutes.practitionerAppointments} component={StandAlonePractitionerAppointmentsPage} />
                <RoutingFrame.Case route={StandaloneAppointmentRoutes.invalidAppointments} component={InvalidAppointmentsPage} />
            </RoutingFrame>
        );
    }

    @State.action.bound
    private setCurrentRoute(newController: IRoutingController) {
        this.applicationContext.setCurrentUseCase(newController.currentRoute ? new UseCaseIdentifier(
            (() => {
                switch (newController.currentRoute.definition) {
                    case StandaloneAppointmentRoutes.registeredPatientAppointments:
                        return "PatientRegister_ViewAppointments";
                }
                return null;
            })()
        ) : null);
    }
}

export default connect(
    AppointmentPage,
    new DependencyAdapter<IAppointmentsPageProps, IAppointmentsPageDependencies>(c => ({
        applicationContext: c.resolve("ApplicationContext"),
    }))
);
