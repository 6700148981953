import React, { useCallback, useMemo, useState } from "react";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import ShowCareActivitiesForEpisodeOfCareScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivitiesForEpisodeOfCareScreenAction.g";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import { ScreenNavigationContextStore } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import CareActivitiesForEpisodeOfCareListScreenApiAdapter from "@HisPlatform/Packages/Care/Screens/CareActivitiesForEpisodeOfCareScreen/CareActivitiesForEpisodeOfCareListScreenApiAdapter";
import NDataScreen from "@HisPlatform/BoundedContexts/Productivity/Components/NDataScreen/NDataScreen";
import ExtensionPoint from "@Toolkit/ReactClient/Extensibility/ExtensionPoint/ExtensionPoint";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { ScreenStateContextAdapter } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import { HisActionDispatcherAdapter } from "@HisPlatform/Common/FrontendActions/HisActionDispatcher";
import ICareActivitiesForEpisodeOfCareScreenExtensionProps from "./ICareActivitiesForEpisodeOfCareScreenExtensionProps";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";

export interface ICareActivitiesForEpisodeOfCareScreenProps extends IScreenPropsBase<ShowCareActivitiesForEpisodeOfCareScreenAction> {
    _dependencies?: ICareActivitiesForEpisodeOfCareScreenDependencies;
    _screenNavigationContext?: ScreenNavigationContextStore;
    _actionDispatcher?: IActionDispatcher;
    _modalService?: IModalService;
}

interface ICareActivitiesForEpisodeOfCareScreenDependencies {
    routingController: GlobalRoutingStore;
    userContext: UserContext;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

function CareActivitiesForEpisodeOfCareScreen(props: ICareActivitiesForEpisodeOfCareScreenProps) {
    const { careActivitiesForEpisodeOfCareListScreenApiAdapter } = useDependencies(c => ({
        careActivitiesForEpisodeOfCareListScreenApiAdapter: c.resolve<CareActivitiesForEpisodeOfCareListScreenApiAdapter>("CareActivitiesForEpisodeOfCareListScreenApiAdapter")
    }));

    const [refreshListEvent, _] = useState(new TypedAsyncEvent());

    const getDynamicListAsync = useCallback(async (args: FrontendListParameters) => {
        const response = await careActivitiesForEpisodeOfCareListScreenApiAdapter.getCareActivitiesOfEpisodeOfCareListQueryAsync(
            props.action.patientId,
            props.action.episodeOfCareId,
            args);
        return response.result;
    }, []);

    const extensionProps = useMemo(() => {
        return {
            actionDispatcher: props._actionDispatcher,
            refreshListEvent: refreshListEvent
        } as ICareActivitiesForEpisodeOfCareScreenExtensionProps;
    }, [props.action, props._actionDispatcher]);

    return (
        <>
            <ExtensionPoint
                type="careActivitiesForEpisodeOfCareScreenExtension"
                extensionProps={extensionProps}
            />

            <NDataScreen
                onGetDynamicListAsync={getDynamicListAsync}
                refreshListEvent={refreshListEvent}
            />
        </>
    );
}

export default connect<ICareActivitiesForEpisodeOfCareScreenProps>(
    State.observer(CareActivitiesForEpisodeOfCareScreen),
    new ScreenStateContextAdapter(),
    new HisActionDispatcherAdapter<ICareActivitiesForEpisodeOfCareScreenProps>(),
    new HisModalServiceAdapter()
);