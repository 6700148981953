import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import Di from "@Di";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import SurgeryTypeDefinitionId from "@Primitives/SurgeryTypeDefinitionId.g";
import ISurgeryTypeDefinition from "./ISurgeryTypeDefinition";
import SurgeryApiAdapter from "@HisPlatform/BoundedContexts/Surgery/ApplicationLogic/ApiAdapter/SurgeryApiAdapter";

@Di.injectable()
export default class SurgeryReferenceDataStore extends ReferenceDataStoreBase {

    constructor(
        @Di.inject("SurgeryApiAdapter") private surgeryApiAdapter: SurgeryApiAdapter,
    ) {
        super();
    }

    public surgeryTypeDefinitions = new ReferenceDataStore<SurgeryTypeDefinitionId, ISurgeryTypeDefinition>(this.surgeryApiAdapter.loadSurgeryTypeDefinitionsAsync, this.surgeryApiAdapter.getAllIdsAsync);
}