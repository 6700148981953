import React from "react";
import * as Recharts from "recharts";
import { IChartProps } from "@CommonControls/Chart/IChartProps";

export interface IAreaChartProps extends IChartProps {
    areaSettings?: Array<{ dataKey: string; pointColor: string; lineColor: string; }>;
}


const AreaChart: React.FC<IAreaChartProps> = (props) => {

        return (
            <Recharts.AreaChart
                width={props.width}
                height={props.height}
                data={props.data}
                syncId={props.syncId}
                margin={props.margin}
            >
                <Recharts.CartesianGrid strokeDasharray="3 3" />
                <Recharts.XAxis dataKey={props.XAxisDataKey} />
                <Recharts.YAxis dataKey={props.YAxisDataKey} />
                {props.isTooltipEnabled && <Recharts.Tooltip />}
                {props.isLegendEnabled && <Recharts.Legend />}
                {props.areaSettings.map(s => {
                    return (
                        <Recharts.Area
                            key={s.dataKey.toString()}
                            type="monotone"
                            dataKey={s.dataKey}
                            stroke={s.lineColor}
                            fill={s.pointColor} />
                    );
                })}

            </Recharts.AreaChart>
        );
};

AreaChart.defaultProps = {
    areaSettings: [
        {
            dataKey: "value",
            pointColor: "#00b4ec",
            lineColor: "#00b4ec"
        }
    ]
};

export default AreaChart;