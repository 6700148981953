import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IReportingExtensionProps from "@PluginInterface/BoundedContexts/Reporting/IReportingExtensionProps";
import OutpatientActivityLogPanel from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Panels/ReportingPanel/OutpatientActivityLogPanel/OutpatientActivityLogPanel";
import PerformanceReportPanel from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Panels/ReportingPanel/PerformanceReportPanel/PerformanceReportPanel";
import FinancedServiceMinimumTimeReportPanel from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Panels/ReportingPanel/FinancedServiceMinimumTimeReportPanel/FinancedServiceMinimumTimeReportPanel";

@State.observer
export default class ReportingExtension extends React.Component<IReportingExtensionProps> {
    public render() {
        if (!this.props.definition) {
            return (<></>);
        }

        const identifier = this.props.definition.reportDefinitionIdentifier.value;

        return (
            <>
                {identifier === "HunSocialSecurity_OutpatientActivityLog" &&
                    <OutpatientActivityLogPanel definition={this.props.definition} />
                }
                {identifier === "HunSocialSecurity_PerformanceReport" &&
                    <PerformanceReportPanel
                        definition={this.props.definition}
                        onCancel={this.props.onCancel}
                        onNavigateTo={this.props.onNavigateTo} />
                }
                {identifier === "HunSocialSecurity_FinancedServiceMinimumTimeReport" &&
                    <FinancedServiceMinimumTimeReportPanel definition={this.props.definition} />}
            </>
        );
    }
}
