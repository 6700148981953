import EntityPermissionId from "@Primitives/EntityPermissionId.g";
import AccessControlList from "./AccessControlList";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import UserSubject from "./UserSubject";
import UserId from "@Primitives/UserId.g";
import AccessControlEntry from "./AccessControlEntry";
import { equals } from "@Toolkit/CommonWeb/EqualityHelper";
import UserGroupSubject from "./UserGroupSubject";
import UserGroupId from "@Primitives/UserGroupId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class EntityPermissionStore extends EntityStoreBase<EntityPermissionId> {
    public entityId: string;
    public entityType: string;

    public accessControlList: AccessControlList = new AccessControlList();
    public isCurrentUserAllowedToSetPermissions: boolean = false;

    public getUserIdsByObjectPermission(objectPermissionId: string) {
        const filteredEntries = this.accessControlList && this.accessControlList.entries
            .filter(entry => entry.permission.value === objectPermissionId && entry.subject instanceof UserSubject) || [];
        
        return filteredEntries.map(entry => (entry.subject as UserSubject).userId);
    }

    public getUserGroupIdsByObjectPermission(objectPermissionId: string) {
        const filteredEntries = this.accessControlList && this.accessControlList.entries
            .filter(entry => entry.permission.value === objectPermissionId && entry.subject instanceof UserGroupSubject) || [];
        
        return filteredEntries.map(entry => (entry.subject as UserGroupSubject).userGroupId);
    }

    public setUserEntriesByObjectPermission(objectPermissionId: string, userIds: UserId[]) {
        const existingUserIds = this.getUserIdsByObjectPermission(objectPermissionId);

        const newIds = userIds.except(existingUserIds, equals);
        const removedIds = existingUserIds.except(userIds, equals);

        const newEntries = newIds.map(userId => {
            return new AccessControlEntry(objectPermissionId, new UserSubject(userId));
        });

        const newList = this.accessControlList.entries
            .filter(entry => !this.isUserEntryRemoved(removedIds, entry, objectPermissionId))
            .concat(newEntries);
            
        this.accessControlList.setEntries(newList);
    }

    public setUserGroupEntriesByObjectPermission(objectPermissionId: string, userGroupIds: UserGroupId[]) {
        const existingUserIds = this.getUserGroupIdsByObjectPermission(objectPermissionId);

        const newIds = userGroupIds.except(existingUserIds, equals);
        const removedIds = existingUserIds.except(userGroupIds, equals);

        const newEntries = newIds.map(userGroupId => {
            return new AccessControlEntry(objectPermissionId, new UserGroupSubject(userGroupId));
        });

        const newList = this.accessControlList.entries
            .filter(entry => !this.isUserGroupEntryRemoved(removedIds, entry, objectPermissionId))
            .concat(newEntries);
            
        this.accessControlList.setEntries(newList);
    }

    private isUserEntryRemoved(removedIds: UserId[], entry: AccessControlEntry, objectPermissionId: string) {
        return entry.subject instanceof UserSubject && entry.permission.value === objectPermissionId
            && removedIds.some(id => id.value === (entry.subject as UserSubject).userId.value
        );
    }

    private isUserGroupEntryRemoved(removedIds: UserGroupId[], entry: AccessControlEntry, objectPermissionId: string) {
        return entry.subject instanceof UserGroupSubject && entry.permission.value === objectPermissionId
            && removedIds.some(id => id.value === (entry.subject as UserGroupSubject).userGroupId.value
        );
    }

    @State.action.bound
    public addUserIdIfNotContains(objectPermissionId: string, userId: UserId) {
        if (!this.getUserIdsByObjectPermission(objectPermissionId).some(id => id.value === userId.value)) {
            this.accessControlList.entries.push(new AccessControlEntry(
                objectPermissionId,
                new UserSubject(userId)
            ));
        }
    }
}