import { ICondition } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistFilterCondition";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";

export default class FrontendListParameters {
    constructor(
        public requestMode: Proxy.FrontendListRequestMode,
        public pageIndex: number,
        public pageSize: number,
        public orderBy: { fieldName: string, ascending: boolean },
        public filter: { [fieldName: string]: ICondition }) {
    }
}