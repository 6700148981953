import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class FormLogic {
    @State.observable public content: string;
    @State.observable public logicName: string;

    constructor(
        logicName: string,
        content: string,
    ) {
        this.logicName = logicName;
        this.content = content;
    }
    
    @State.action.bound
    public setFormLogicName(formLogicName: string) {
        this.logicName = formLogicName;
    }
}