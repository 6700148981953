import State from "@Toolkit/ReactClient/Common/StateManaging";

export abstract class EMedicationDosage {
}


export class TextualEMedicationDosage {
    @State.observable public instruction: string = "";
    
    @State.action.bound
    public setInstruction(instruction: string) {
        this.instruction = instruction;
    }
}


export class FrequencyBasedEMedicationDosage {
    @State.observable public amount: string = "";
    
    @State.action.bound
    public setAmount(amount: string) {
        this.amount = amount;
    }

    @State.observable public frequency: string = "";
    
    @State.action.bound
    public setFrequency(frequency: string) {
        this.frequency = frequency;
    }

    @State.observable public unit: string = null;
    
    @State.action.bound
    public setUnit(unit: string) {
        this.unit = unit;
    }
}
