exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EhiConditionCovidStatusColumn_positive-test-result_1nj__ {\n  fill: #ce5374;\n}\n.EhiConditionCovidStatusColumn_negative-test-result_2KUL2 {\n  fill: #21ba45;\n}\n", ""]);

// exports
exports.locals = {
	"positive-test-result": "EhiConditionCovidStatusColumn_positive-test-result_1nj__",
	"positiveTestResult": "EhiConditionCovidStatusColumn_positive-test-result_1nj__",
	"negative-test-result": "EhiConditionCovidStatusColumn_negative-test-result_2KUL2",
	"negativeTestResult": "EhiConditionCovidStatusColumn_negative-test-result_2KUL2"
};