import {
    ICondition,
    NumericFilterCondition
} from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistFilterCondition";
import {convertToString} from "@Toolkit/CommonWeb/ToString";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import _ from "@HisPlatform/Common/Lodash";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";
import StringComparisonType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/StringComparisonType.g";
import NumericComparisonType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/NumericComparisonType.g";

const createStringCondition = (value: any): ICondition => ({
    type: WorklistConditionType.String,
    comparisonType: StringComparisonType.Contains,
    value
});

const createPersonNameCondition = (value: any): ICondition => ({
    type: WorklistConditionType.PersonName,
    namePart: value
});

const createStringEnumCondition = (value: any): ICondition => ({
    type: WorklistConditionType.StringEnum,
    values: Array.isArray(value) ? (value as []).map(i => convertToString(i)) : [convertToString(value)]
});

const createStringEnumListCondition = (value: any): ICondition => ({
    type: WorklistConditionType.StringEnumList,
    values: _.uniqBy(value, (item: any) => item.name).map(item => ({
        name: item.name,
        values: value.filter((i: any) => i.name === item.name).map((i: any) => i.valueNumber)
    }))
});

const createIntEnumCondition = (value: any): ICondition => ({
    type: WorklistConditionType.IntEnum,
    values: Array.isArray(value) ? (value as []).map(i => i) : [value]
});

const createEntityIdCondition = (value: any): ICondition => ({
    type: WorklistConditionType.EntityId,
    values: Array.isArray(value) ? (value as []).map(i => (i as StringEntityId).value) : [(value as StringEntityId).value]
});

const createExtensibleEnumCondition = (value: any): ICondition => ({
    type: WorklistConditionType.ExtensibleEnum,
    values: Array.isArray(value) ? (value as []).map(i => (i as StringEntityId).value) : [(value as StringEntityId).value]
});

const createNumericCondition = (value: any): ICondition => (
    value instanceof NumericFilterCondition
    ? {
        type: WorklistConditionType.Numeric,
        value: value.value,
        comparisonType: value.comparisonType
    }
    : {
        type: WorklistConditionType.Numeric,
        value: value,
        comparisonType: NumericComparisonType.Equality
    }
);

const createBooleanCondition = (value: any): ICondition => ({
    type: WorklistConditionType.Boolean,
    value: value
});

const createDateCondition = (value: any): ICondition => ({
    type: WorklistConditionType.Date,
    value: value,

});

const createDateTimeCondition = (value: any): ICondition => ({
    type: WorklistConditionType.DateAndTime,
    value: value
});

const createYearMonthCondition = (value: any): ICondition => ({
    type: WorklistConditionType.YearMonth,
    value: value
});

export default {
    [WorklistConditionType.String]: createStringCondition,
    [WorklistConditionType.PersonName]: createPersonNameCondition,
    [WorklistConditionType.StringEnum]: createStringEnumCondition,
    [WorklistConditionType.IntEnum]: createIntEnumCondition,
    [WorklistConditionType.EntityId]: createEntityIdCondition,
    [WorklistConditionType.ExtensibleEnum]: createExtensibleEnumCondition,
    [WorklistConditionType.Numeric]: createNumericCondition,
    [WorklistConditionType.Date]: createDateCondition,
    [WorklistConditionType.DateAndTime]: createDateTimeCondition,
    [WorklistConditionType.YearMonth]: createYearMonthCondition,
    [WorklistConditionType.Boolean]: createBooleanCondition,
    [WorklistConditionType.StringEnumList]: createStringEnumListCondition
} as { [key in WorklistConditionType]: (value: any) => ICondition };
