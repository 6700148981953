import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IPerformedServiceExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPerformedServiceExtensionPointProps";
import { IDataGridColumnChildProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import MedicalServiceFinancingStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/MedicalServiceFinancingStore";
import PerformedServiceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/PerformedServiceDataStore";
import PerformedServiceClassificationId from "@Primitives/PerformedServiceClassificationId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PerformedServiceClassificationSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/Care/Components/Controls/PerformedServiceClassificationSelectBox/PerformedServiceClassificationSelectBox";
import HunCareReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/HunCareReferenceDataStore";
import InfoButton from "@CommonControls/InfoButton";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as ColumnStyles from "@CommonControls/DataGrid/Column/DataGridColumn.less";
import PerformedServiceId from "@Primitives/PerformedServiceId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";

interface IPerformedServiceClassificationColumnCellDependencies {
    careReferenceDataStore: HunCareReferenceDataStore;
}

interface IPerformedServiceClassificationColumnCellProps extends IDataGridColumnChildProps<MedicalServiceFinancingStore, any>, IPerformedServiceExtensionPointProps {
    _dependencies?: IPerformedServiceClassificationColumnCellDependencies;
}

@State.observer
class PerformedServiceClassificationColumnCell extends React.Component<IPerformedServiceClassificationColumnCellProps> {
    @State.observable.ref public store: PerformedServiceDataStore;
    private get actualRow() { return this.props.row; }

    @State.computed private get displayValue() {
        if (isNullOrUndefined(this.store.classification)) { return ""; }
        const classification = this.props._dependencies.careReferenceDataStore.performedServiceClassificationMap.get(this.store.classification);

        return classification && (
            <>
                {classification.displayValue.Shorthand}<InfoButton tooltipContent={classification.displayValue.Name} />
            </>
        ) || null;
    }

    public componentDidMount(): void {
        this.initialize();
    }

    @State.action.bound
    private initialize() {
        if (this.actualRow.financedServicesWithQuantities.length > 0) {
            if (this.actualRow.financedServicesWithQuantities[0].extensionData) {
                this.store = PerformedServiceDataStore.CreateFromJsonObject(
                    this.actualRow.financedServicesWithQuantities[0].extensionData);
            } else {
                this.store = new PerformedServiceDataStore();
                this.store.careActivityId = this.props.careActivityId;
                this.store.medicalServiceFinancingId = this.actualRow.id;
            }
        }
    }

    @State.action.bound
    private setClassification(newValue: PerformedServiceClassificationId) {
        this.store.classification = newValue;

        this.actualRow.financedServicesWithQuantities.forEach(i => {
            i.extensionData = {
                ...i.extensionData,
                ["HunSocialSecurity_PerformedServiceData"]: {
                    Id: this.store.id?.toJSON() || new PerformedServiceId("-1").toJSON(),
                    CareActivityId: this.store.careActivityId.toJSON(),
                    MedicalServiceFinancingId: this.store.medicalServiceFinancingId.toJSON(),
                    FinancedServiceId: i.financedServiceId.id.toJSON(),
                    PerformedServiceClassificationId: this.store.classification.toJSON(),
                    RowVersion: this.store.rowVersion?.toJSON() || new RowVersion(-1).toJSON(),
                }
            };
        });
    }

    public render() {
        if (isNullOrUndefined(this.store)) {
            return (<></>);
        }

        if (this.props.isUnderEditing) {
            return (
                <div className={ColumnStyles.inlineInputContainer}>
                    <PerformedServiceClassificationSelectBox
                        value={this.store.classification}
                        onChange={this.setClassification}
                        propertyIdentifier="PerformedServiceClassificationId"
                        automationId="performedServiceClassificationSelectBox"
                    />
                </div>
            );
        }

        return this.displayValue;
    }
}

export default connect(
    PerformedServiceClassificationColumnCell,
    new DependencyAdapter<IPerformedServiceClassificationColumnCellProps, IPerformedServiceClassificationColumnCellDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("HunCareReferenceDataStore")
        };
    })
);