import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import ContactTypeId from "@Primitives/ContactTypeId.g";
import JobPositionId from "@Primitives/JobPositionId.g";
import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ExternalLocationTypeId from "@Primitives/ExternalLocationTypeId.g";

@Di.injectable()
export default class OrganizationExtensibleEnumApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IStructureClient") private readonly structureClient: Proxy.IStructureClient,
        @Di.inject("IOrganizationReferenceDataClient") private readonly organizationReferenceDataClient: Proxy.IOrganizationReferenceDataClient,
        @Di.inject("IExternalClient") private readonly externalLocationReferenceDataClient: Proxy.IExternalClient
    ) {
        super();
    }

    @State.bound
    public loadContactTypeIdsAsync(): Promise<SimpleStore<ContactTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.structureClient.getContactTypeIdsQueryAsync(...args),
            response => response.contactTypeIds
        );
    }

    @State.bound
    public loadJobPositionsAsync(): Promise<SimpleStore<JobPositionId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.organizationReferenceDataClient.getJobPositionIdsQueryAsync(...args),
            response => response.jobPositionIds
        );
    }

    @State.bound
    public loadExternalLocationsAsync(): Promise<SimpleStore<ExternalLocationTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.externalLocationReferenceDataClient.getExternalLocationTypeIdsQueryAsync(...args),
            response => response.externalLocationTypeIds
        );
    }
}