import ApiBusinessError from "@Toolkit/CommonWeb/ApiAdapter/ApiBusinessError";
import { BusinessErrorHandlerContext } from "@Toolkit/ReactClient/Components/BusinessErrorHandler/BusinessErrorHandler";
import LoadingContextStore from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingContextStore";
import { useContext, useState } from "react";

export interface IReactComponent { setState: (setter: () => void) => void; props: any; }

export function dispatchAsyncErrors(promise: Promise<any | void>, reactComponent: IReactComponent, showLoadingIndicator: boolean = true) {
    const loadingContextStore = (reactComponent?.props?._dependencies?._loadingContextStore ?? reactComponent?.props?._loadingContextStore) as LoadingContextStore;
    const clearLoading = showLoadingIndicator ? loadingContextStore?.setLoadingStateWithClear?.() : null;
    return promise
        .finally(() => clearLoading?.())
        .catch(err => {
            if (reactComponent) {
                const handler = reactComponent.props?._dependencies?._businessErrorHandler ?? reactComponent.props?._businessErrorHandler;
                if (err instanceof ApiBusinessError && handler) {
                    if (!handler.handle(err.error)) {
                        reactComponent.setState(() => {
                            throw err;
                        });
                    }
                } else {
                    reactComponent.setState(() => {
                        throw err;
                    });
                }
            } else {
                throw err;
            }
        });
}

export function createAsyncErrorDispatcher(reactComponent: IReactComponent) {
    return (promise: Promise<any | void>) => {
        return dispatchAsyncErrors(promise, reactComponent);
    };
}

export function useErrorDispatcher() {
    const [, setState] = useState();
    const handler = useContext(BusinessErrorHandlerContext);

    return (err: Error) => {
        if (err instanceof ApiBusinessError && handler) {
            if (!handler.handle(err.error)) {
                setState(() => {
                    throw err;
                });
            }
        } else {
            setState(() => {
                throw err;
            });
        }
    };
}