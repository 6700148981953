import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ExtensibleEnumSelectBox, { IExtensibleEnumSelectBoxPropsBase } from "@HisPlatformControls/ExtensibleEnumSelectBox";
import HunCareReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/HunCareReferenceDataStore";

interface ICtMrExaminationRequestTypeSelectBoxDependencies {
    careReferenceDataStore: HunCareReferenceDataStore;
}

interface ICtMrExaminationRequestTypeSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: ICtMrExaminationRequestTypeSelectBoxDependencies;
}

@State.observer
class CtMrExaminationRequestTypeSelectBox extends React.Component<ICtMrExaminationRequestTypeSelectBoxProps> {

    private get dependencies() {
        return this.props._dependencies;
    }

    private get extensibleEnumStore() {
        return this.dependencies.careReferenceDataStore.ctMrExaminationRequestTypes;
    }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    CtMrExaminationRequestTypeSelectBox,
    new DependencyAdapter<ICtMrExaminationRequestTypeSelectBoxProps, ICtMrExaminationRequestTypeSelectBoxDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("HunCareReferenceDataStore")
        };
    })
);
