import React from "react";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";

interface IUnauthorizedAccessUseCaseFrameProps {
    title: string;
}

export default function UnauthorizedAccessUseCaseFrame(props: React.PropsWithChildren<IUnauthorizedAccessUseCaseFrameProps>) {

    return (
        <UseCaseFrame title={props.title}>
            <UnauthorizedAccessContent />
        </UseCaseFrame>
    );
}