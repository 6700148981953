import React from "react";
import { IFormEditorComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import { NumberBox } from "@CommonControls";
import NumberFormDataElement from "@Toolkit/FormEngine/Model/Schema/NumberFormDataElement";


interface IFormNumberBoxProps extends IFormEditorComponentProps {

}

function FormNumberBox(props: IFormNumberBoxProps) {

    const dataElement = props.dataElement as NumberFormDataElement;
    
    return (
        <NumberBox
            {...props}
            nullValue={null}
            integerOnly={dataElement?.isInteger ?? true}
        />
    ); 
}

export default FormNumberBox;