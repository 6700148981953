import NameStore from "@Primitives/NameStore";
import GenderId from "@Primitives/GenderId.g";

export default class PatientName {
    constructor(
        public readonly name: NameStore,
        public readonly isDead: boolean,
        public readonly genderId: GenderId,
        public readonly isUnregistered: boolean,
        public readonly isDataCleansingNeeded: boolean) {
    }
}
