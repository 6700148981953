import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import UserGroup from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroup";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import * as Ui from "@CommonControls";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import StaticWebAppResources from "@StaticResources";

interface IUserGroupListProps extends IHasMasterDetailState {
    dataSource: InMemoryDataGridDataSource;
    onBack(): void;
}

/** @screen */

const UserGroupList: React.FC<IUserGroupListProps> = (props) => {
    const rowId = (row: UserGroup) => row.id.value;

    const numberOfUsersOrder = (value: UserGroup) => value.numberOfUsers;
    const displayNameOrder = (value: UserGroup) => value.displayName;

    return (
        <Ui.DataGrid
            dataSource={props.dataSource}
            generateInitialFilterStore
            changeOnMount
            isSelectable
            fixedHeight="calc(100vh - 105px)"
            rowHeight={"39px"}
            onSelectedRowChange={props._masterDetailState.onSelectedItemChange}
            selectedRow={props._masterDetailState.selectedItem}
            rowId={rowId}
            rowComparer="rowId"
            hasBackButton
            backButtonText={StaticWebAppResources.Common.Button.Back}
            onBack={props.onBack}
            automationId="userGroupList_dataGrid"
        >
            <DataGridColumn
                id={0}
                dataGetter={"displayName"}
                header={StaticUserManagementResources.UserGroupManagementPanel.ColumnHeader.GroupName}
                width="70%"
                isFilterable
                isOrderable
                clientSideOrderableValueGetter={displayNameOrder}
            />
            <DataGridColumn
                id={1}
                dataGetter={"numberOfUsers"}
                header={StaticUserManagementResources.UserGroupManagementPanel.ColumnHeader.NumberOfUsers}
                width="30%"
                isOrderable
                clientSideOrderableValueGetter={numberOfUsersOrder}
            />
        </Ui.DataGrid>
    );
};

export default State.observer(UserGroupList);
