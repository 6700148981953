import { IFormCustomBlockComponentProps, ICustomBlockRegistryItem } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import PreviewFormDefinition from "@Toolkit/FormEngine/Model/PreviewFormDefinition";
import React, { useCallback } from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityId from "@Primitives/CareActivityId.g";
import PatientId from "@Primitives/PatientId.g";
import NumberFormDataElement from "@Toolkit/FormEngine/Model/Schema/NumberFormDataElement";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import { useFormEntityIdField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import CareActivityEhiEReferralPanelCore from "./CareActivityEhiEReferralPanelCore";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import EhiEReferralId from "@Primitives/EhiEReferralId.g";
import CareActivityEReferralStore from "@HunEHealthInfrastructurePlugin/Model/CareActivityEReferralStore";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import StringFormDataElement from "@Toolkit/FormEngine/Model/Schema/StringFormDataElement";

interface ICareActivityEhiEReferralPanelFormCustomBlockProps extends IFormCustomBlockComponentProps {
    _careActivityId: CareActivityId;
    _patientId: PatientId;
}

function _CareActivityEhiEReferralPanelFormCustomBlock(props: ICareActivityEhiEReferralPanelFormCustomBlockProps) {

    if (props.formDefinition instanceof PreviewFormDefinition) {
        // Preview mode
        return (
            <div>CareActivityEhiEReferralPanelFormCustomBlock</div>
        );
    }

    const selectEReferral = useCallback(State.action((eReferral: CareActivityEReferralStore) => {
        getField<TextFormFieldData>(props.form.data.Content, "EhiEReferralId").value = eReferral?.ehiEReferralId.value;
        props.form.rowVersions.set("eReferral", eReferral?.rowVersion);
    }), [props.form.data.Content]);    

    const [pointOfCareId, setPointOfCareId] = useFormEntityIdField<PointOfCareId>(props.form.data.Content, "PointOfCareId", PointOfCareId);
    const [ehiEReferralId, setEhiEReferralId] = useFormEntityIdField<EhiEReferralId>(props.form.data.Content, "EhiEReferralId", EhiEReferralId);

    return (
        <CareActivityEhiEReferralPanelCore
            careActivityId={props._careActivityId}
            patientId={props._patientId}
            isMutable={false}
            careActivityIsNew={true}
            pointOfCareId={pointOfCareId}
            onSelectEReferral={selectEReferral}
            selectedEhiEReferralId={ehiEReferralId}
        />
    );
}

const CareActivityEhiEReferralPanelFormCustomBlock = connect(
    State.observer(_CareActivityEhiEReferralPanelFormCustomBlock),
    new PatientContextAdapter<ICareActivityEhiEReferralPanelFormCustomBlockProps>(p => ({
        _patientId: p.patientId
    })),
    new CareActivityContextAdapter<ICareActivityEhiEReferralPanelFormCustomBlockProps>(p => ({
        _careActivityId: p.careActivityId
    }))
);


export const CareActivityEhiEReferralPanelRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Care Activity Ehi EReferral Panel",
    dataElementsFactory: () => [
        new StringFormDataElement("EhiEReferralId", false, null, false, true, true)
    ],
    componentType: CareActivityEhiEReferralPanelFormCustomBlock
};

export default CareActivityEhiEReferralPanelFormCustomBlock;