import DependencyContainer from "@DiContainer";
import ICardioFrameApiAdapter from "@ICardioIntegrationPlugin/Components/ICardioFrame/ICardioFrameApiAdapter";
import ICardioDecisionSupportAccessor from "@ICardioIntegrationPlugin/Services/ICardioDecisionSupportAccessor";
import IExternalDecisionSupportAccessor from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/ExternalDecisionSupport/IExternalDecisionSupportAccessor";

DependencyContainer
    .bind("ICardioFrameApiAdapter")
    .to(ICardioFrameApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("IExternalDecisionSupportAccessor")
    .to(ICardioDecisionSupportAccessor);