import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import LockingEntityStoreMapper from "@Toolkit/CommonWeb/ApiAdapter/LockingEntityStoreMapper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import PatientBloodTypeId from "@Primitives/PatientBloodTypeId.g";
import CategoryObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/CategoryObservation";
import TextualObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/TextualObservation";
import PatientBloodTypeStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/PatientBloodType/PatientBloodTypeStore";
import PatientBloodTypeHistoryItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Model/PatientBloodType/PatientBloodTypeHistoryItem";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { MedicalConditionHistoryItemDetailDto } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";

type ResponseType = Proxy.GetPatientBloodTypeByIdCommandResponse | Proxy.AddPatientBloodTypeCommandResponse | Proxy.UpdatePatientBloodTypeCommandResponse | Proxy.DeletePatientBloodTypeHistoryItemCommandResponse;

@Di.injectable()
export default class PatientBloodTypeStoreMapper extends LockingEntityStoreMapper<Proxy.PatientBloodTypeDto, PatientBloodTypeStore, ResponseType> {

    constructor(
        validationProblemParameterMappingService: ValidationProblemParameterMapperService
    ) {
        super(validationProblemParameterMappingService);
    }

    protected storeEntityIdType = PatientBloodTypeId;

    protected entityDtoSelector = (response: ResponseType) => {
        return response.patientBloodTypeDto;
    };

    protected applyToStoreCore(target: PatientBloodTypeStore, response: any) {
        const dto = this.entityDtoSelector(response);
        
        if (dto != null) {
            target.id = dto.id;
            target.categoryObservation = this.getCategoryObservation(dto);
            target.observedAt = LocalDate.createFromMoment(dto.categoryObservationDto?.observedAt);
            target.historyItems = State.observable(this.getHistoryItems(dto.historyItems));
            target.currentItemIndex = target.historyItems.length - 1;
            target.extensionData = dto.extensionData;
        }
    }

    private getHistoryItems(dtoArray: Proxy.PatientBloodTypeHistoryItemDto[]): PatientBloodTypeHistoryItem[] {
        return dtoArray.map(dto => this.getHistoryItem(dto)).sort((a, b) => a.versionNumber - b.versionNumber);
    }

    private getHistoryItem(dto: Proxy.PatientBloodTypeHistoryItemDto): PatientBloodTypeHistoryItem {
        const returnItem = new PatientBloodTypeHistoryItem();

        returnItem.historyItemDetail = this.getHistoryItemDetail(dto.historyItemDetail);
        returnItem.versionNumber = dto.versionNumber;
        returnItem.index = dto.index;
        returnItem.createdAt = dto.createdAt;
        returnItem.extensionData = dto.extensionData;
        returnItem.textualObservation = this.getTextualObservation(dto);
        returnItem.extensionData = dto.extensionData;

        return returnItem;
    }

    private getHistoryItemDetail(dto: MedicalConditionHistoryItemDetailDto): MedicalConditionHistoryItemDetail {
        const returnItem = new MedicalConditionHistoryItemDetail();

        returnItem.asserter = dto.asserter;
        returnItem.asserterType = dto.asserterType;
        returnItem.notes = dto.notes;
        returnItem.verifier = dto.verifier;

        return returnItem;
    }

    private getCategoryObservation(dto: Proxy.PatientBloodTypeDto) {
        return new CategoryObservation(
            dto.categoryObservationDto?.observationId,
            dto.categoryObservationDto?.observationDefinitionId,
            dto.categoryObservationDto?.rowVersion,
            dto.categoryObservationDto?.patientId,
            dto.categoryObservationDto?.careActivityId,
            dto.categoryObservationDto?.categories);
    }

    private getTextualObservation(dto: Proxy.PatientBloodTypeHistoryItemDto) {
        if (isNullOrUndefined(dto.textualObservationDto)) {
            return null;
        }

        return new TextualObservation
            (dto.textualObservationDto?.observationId,
                dto.textualObservationDto?.observationDefinitionId,
                dto.textualObservationDto?.rowVersion,
                dto.textualObservationDto?.patientId,
                dto.textualObservationDto?.careActivityId,
                dto.textualObservationDto?.contentId,
                dto.textualObservationDto?.stringContent);
    }
}