import React from "react";
import ReadOnlyContext from "./index";

export default class ReadOnlyNestedProvider extends React.PureComponent<{ value: boolean }> {
    public render() {
        return (
            <ReadOnlyContext.Consumer>
                {(isReadOnly) => (
                    <ReadOnlyContext.Provider value={isReadOnly || this.props.value}>
                        {this.props.children}
                    </ReadOnlyContext.Provider>
                )}
            </ReadOnlyContext.Consumer>
        );
    }
}