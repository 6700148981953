import React from "react";
import WorklistApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/Worklist/WorklistApiAdapter";
import DynamicValueConverter from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ValueConverters/DynamicValueConverter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import { IPagingState, IOrderingState, IRowIndicatorStyle, IRowBody, DataGridLoadType, RowId, IStatePersisterSettings, IEmptyStateSettings, IRowCheckState } from "@CommonControls/DataGrid/IDataGridProps";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import IWorklistItemDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistItemDefinition";
import INDataAction from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataAction";
import { isNullOrUndefined, isNullOrWhiteSpace } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ActionPlacement from "@Primitives/ActionPlacement";
import NDataGridView from "./NDataGridView";
import IWorklistDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistDefinition";
import INDataRow from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/NData/INDataRow";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import Action from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/Action";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import NDataColumns from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/NDataColumns";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { ICondition } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistFilterCondition";
import FilterConditionConverters from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/FilterConditionConverters";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import EventHandler from "@Toolkit/ReactClient/Components/EventHandler/EventHandler";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import NDataActionProcessor from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/NDataActionProcessor";
import newEmptyRow from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/NewEmptyRow";
import HisUseCaseHost from "@HisPlatform/Components/HisUseCaseHost/HisUseCaseHost";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import IWorklistData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IWorklistData";
import config from "@Config";
import { INDataBatchActionPanelProps } from "@HisPlatform/BoundedContexts/Productivity/Components/NDataGrid/INDataBatchActionPanelProps";
import { IDeferredActionItem } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IDeferredActionItem";
import IDeferredActionTaskStatus from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IDeferredActionTaskStatus";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import { formatReactString } from "@Toolkit/ReactClient/Common/LocalizedStrings";
import ClientSideActionDto from "@HisPlatform/Model/DomainModel/ClientSideAction/ClientSideActionDto";
import FrontendListRequestMode from "./FrontendListRequestMode";
import IDynamicListDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/IDynamicListDefinition";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import IFrontendListDataWithDefinition from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/IFrontendListDataWithDefinition";
import { HisActionDispatcherAdapter } from "@HisPlatform/Common/FrontendActions/HisActionDispatcher";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ListItemActionPlacement from "@HisPlatform/BoundedContexts/WebAppBackend/Api/FrontendLists/Enum/ListItemActionPlacement.g";
import ItemPlacement from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/ItemPlacement.g";
import WorklistActionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistActionType.g";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";

interface INDataGridDependencies {
    worklistApiAdapter: WorklistApiAdapter;
    notificationService: INotificationService;
    localizationService: ILocalizationService;
    fileSaverService: IFileSaverService;
    dynamicValueConverter: DynamicValueConverter;
    activityRegistry: IActivityRegistry;
    dialogService: IDialogService;
    cultureCodeProvider: ICurrentCultureProvider;
}

interface INDataGridProps {
    _dependencies?: INDataGridDependencies;
    _actionDispatcher?: IActionDispatcher;

    definition?: IWorklistDefinition;
    onGetDynamicListAsync?: (frontendListParameters: FrontendListParameters) => Promise<IFrontendListDataWithDefinition>;

    useCaseState?: INDataUseCaseState;
    selectedRowId?: string;
    hasNewRow?: boolean;
    extraFilter?: React.ReactNode;
    hasRefreshButton?: boolean;
    hasExtraFilterButton?: boolean;
    hasFilterClearButton?: boolean;
    hasBackButton?: boolean;
    onBack?: () => void;
    orderingEnabled?: boolean;
    defaultExtraFilterVisibility?: boolean;
    defaultPageSize?: number;
    defaultOrderingColumnName?: string;
    defaultOrderingDirection?: 'asc'|'desc';
    data?: IPagedItems<INDataRow>;
    rowBodyHeight?: React.ReactText;
    rowBodyPadding?: React.ReactText;
    detailTitle?: string;

    refreshListEvent?: TypedAsyncEvent;

    statePersisterSettings?: Partial<IStatePersisterSettings>;

    onDataLoaded?: (data: IPagedItems<INDataRow>, globalFrontendActions: ActionDescriptor[]) => void;
    getRowIndicatorStyle?: (row: INDataRow, rowId: string, rowIndex: number) => IRowIndicatorStyle;
    onChange?: (rowId: RowId, useCaseState: INDataUseCaseState) => void;
    onPerformActionAsync?: (action: Action, rowId: RowId) => Promise<boolean>;
    onPerformClientSideActionAsync?: (clientSideAction: ClientSideActionDto, rowId: RowId) => Promise<WorkListArgument | boolean>;
    onFilterStoreCreatedAsync?: (filterStore: any) => Promise<void>;
    onRenderRowBody?: (row: INDataRow, rowId: RowId, rowIndex: number, rowBodyItemDefinition: IWorklistItemDefinition) => IRowBody;
    onGetExtendedFilterDescriptors?: () => IDataGridFilterDescriptor[];
    onRowClick?: (row: INDataRow, rowId: React.ReactText, rowIndex: number) => void;
    onRowRightClick?: (row: INDataRow, rowId: React.ReactText, rowIndex: number) => void;
    onDefinitionLoaded?: (definition: IDynamicListDefinition) => void;
    onGetInlinePanelProps?: (useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) => object;
    fullHeight?: boolean;
    onClearFilter?: () => void;
    onInitializeFilter?: (filterStore: any, itemDefinitions: IWorklistItemDefinition[]) => void;
    onUnauthorized?: () => void;

    actionsOnLeftSide?: boolean;
    horizontalScroll?: boolean;
    emptyStateSettings?: IEmptyStateSettings;

    referenceDate?: LocalDate;
    titleOfPrintableContent?: string;

    deferredActionTaskType?: string;
    multiActionActivityReference?: string | null;
    onMultiActionActivityReferenceChange?: (value: string) => void;
    hasPrintButton?: boolean;
    containerStyle?: React.CSSProperties;
    alwaysVisibleExtraFilter?: React.ReactNode;
    showLoadingIndicator?: boolean;

    isDetailOpen?: boolean;
}

@State.observer
class NDataGrid extends React.Component<INDataGridProps> {
    //#region dependencies
    private get worklistApiAdapter() {
        return this.props._dependencies.worklistApiAdapter;
    }

    private get notificationService() {
        return this.props._dependencies.notificationService;
    }

    private get localizationService() {
        return this.props._dependencies.localizationService;
    }

    private get dynamicValueConverter() {
        return this.props._dependencies.dynamicValueConverter;
    }

    private get fileSaveService() {
        return this.props._dependencies.fileSaverService;
    }

    private get activityRegistry() {
        return this.props._dependencies.activityRegistry;
    }

    private get dialogService() {
        return this.props._dependencies.dialogService;
    }
    //#endregion

    public static defaultProps: Partial<INDataGridProps> = {
        rowBodyHeight: 53
    };

    @State.observable.ref private isActionLoading = false;
    @State.observable.ref private isDataLoading = true;
    @State.computed private get isLoading() {
        return this.isDataLoading || this.isActionLoading;
    }

    @State.observable.ref private paging: IPagingState = { currentPage: 0, pageSize: this.props.defaultPageSize ?? 10 };
    @State.observable.ref private ordering: IOrderingState = null;
    @State.observable.ref private filterStore: IFilterStore = null;
    @State.observable.ref private _items: IPagedItems<INDataRow> = null;

    @State.observable.ref private someItemsAreHidden: boolean = false;

    private readonly selectedBatchActionsByRowIds = State.createObservableShallowMap<RowId, { action: INDataAction, displayName: string }>();
    @State.observable.ref private runningDeferredTask: IDeferredActionTaskStatus | null = null;
    private autoRefreshDeferredTaskTimer: NodeJS.Timeout | null = null;
    @State.observable.ref private showOnlySelectedBatchActionRows = false;
    @State.observable.ref private multiActionItemActivityReference: string | null = null;
    private rowDisplayNameGetter: (row: INDataRow) => string = null;

    @State.observable.ref private definition: IDynamicListDefinition = null;

    @State.computed private get itemDefinitions(): IWorklistItemDefinition[] {
        return this.definition.itemDefinitions;
    }

    @State.computed private get items() {

        const items = this.props.data === undefined ? this._items : this.props.data;

        if (!this.props.hasNewRow) {
            return items;
        }

        return {
            items: !items ? [newEmptyRow] : [newEmptyRow, ...items.items],
            totalCount: items?.totalCount + 1
        };
    }

    @State.computed private get selectedRow() {
        if (isNullOrUndefined(this.props.selectedRowId)) {
            return null;
        }

        return this.items?.items.find(row => row.__id === this.props.selectedRowId);
    }

    @State.computed
    private get maxPrimaryButtonCount() {

        let btnCount = 0;
        if (this.props.hasPrintButton) { btnCount++; }
        if (this.props.hasRefreshButton) { btnCount++; }
        if (this.props.hasFilterClearButton) { btnCount++; }
        if (this.props.hasExtraFilterButton) { btnCount++; }

        return this.items?.items.reduce((max, item) => {
            const buttonCount =
                item.__actions
                    ? item.__actions.reduce((cnt, it) => (it.placement === ActionPlacement.Primary || it.placement === ActionPlacement.Both) ? cnt + 1 : cnt, 0)
                    : item.__actionDescriptors.reduce((cnt, it) => (it.presentation.placement === ListItemActionPlacement.InRow || it.presentation.placement === ListItemActionPlacement.InRowAndContextMenu) ? cnt + 1 : cnt, 0);
            return buttonCount > max ? buttonCount : max;
        }, btnCount);

    }

    @State.computed
    private get hasDefaultAction(): boolean {
        const firstItem = this.items?.items?.[0];
        return !isNullOrUndefined(firstItem?.__defaultAction) || firstItem?.__actionDescriptors?.some(ad => ad.presentation.isDefaultAction);
    }

    @State.computed
    private get rowBodyDefinition() {
        return this.itemDefinitions && this.itemDefinitions.find(d => d.placement === ItemPlacement.Body);
    }

    @State.computed private get isMultiActionMode() {
        return !isNullOrUndefined(this.props.multiActionActivityReference);
    }

    @State.computed private get areAllRowsSelected() {
        if (this.selectedBatchActionsByRowIds.size === this.items?.totalCount || this.selectedBatchActionsByRowIds.size === config.nData.batchAction.maxNumberOfCheckedRows) {
            return true;
        }

        if (this.selectedBatchActionsByRowIds.size === 0) {
            return false;
        }

        return null;
    }

    private readonly actionProcessor = new NDataActionProcessor(
        this.worklistApiAdapter,
        this.notificationService,
        this.localizationService,
        this.fileSaveService,
        (...args) => this.props.onPerformClientSideActionAsync?.(...args),
        (...args) => this.props.onPerformActionAsync?.(...args),
        this.reloadListAsync,
        (...args) => this.props.onChange?.(...args),
        () => this.props.selectedRowId,
        () => this.props.useCaseState,
        this.setActionLoadingState,
        (activityReference) => {
            alert(activityReference);
        }
    );

    @State.bound
    private async getWorklistDataPermissionCheckAsync() {
        if (this.props.definition) {
            await this.worklistApiAdapter.getWorklistDataByDefinitionIdPermissionCheck(this.props.definition);
        }
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadPanelAsync, this.getWorklistDataPermissionCheckAsync);
    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    constructor(props: INDataGridProps) {
        super(props);
        State.reaction(() => this.initialLoadPanelAsync.isUnauthorizedAccess, isUnauthorizedAccess => {
            if (isUnauthorizedAccess) {
                this.props.onUnauthorized?.();
            }
        });
    }

    public componentDidUpdate(prevProps: INDataGridProps) {
        if (
            prevProps.definition !== this.props.definition ||
            prevProps.referenceDate !== this.props.referenceDate ||
            prevProps.onGetDynamicListAsync !== this.props.onGetDynamicListAsync
        ) {
            dispatchAsyncErrors(
                (async () => { 
                await this.initialLoadPanelAsync(); 
                await this.reloadListAsync();
            })(), this);
        }

        if (prevProps.multiActionActivityReference !== this.props.multiActionActivityReference && !this.runningDeferredTask) {
            State.runInAction(() => {
                if (this.autoRefreshDeferredTaskTimer) {
                    clearInterval(this.autoRefreshDeferredTaskTimer);
                }
                this.showOnlySelectedBatchActionRows = false;
                this.multiActionItemActivityReference = null;
                this.runningDeferredTask = null;
                this.selectedBatchActionsByRowIds.clear();
            });
            dispatchAsyncErrors(this.reloadListAsync(), this);
        }
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }

        if (!this.definition) {
            return null;
        }

        return (
            <>
                <NDataGridView
                    activityRegistry={this.activityRegistry}
                    isLoading={this.isLoading}
                    dataSource={this.items}
                    filterStore={this.filterStore}
                    paging={this.paging}
                    ordering={this.ordering}
                    worklistUniqueIdentifier={this.definition.name}
                    extraFilter={this.isMultiActionMode ? this.renderBatchActionPanel() : this.props.extraFilter}
                    selectedRow={this.selectedRow}
                    someItemsAreHidden={this.someItemsAreHidden}
                    hasRefreshButton={this.props.hasRefreshButton}
                    hasExtraFilterButton={this.props.hasExtraFilterButton}
                    hasFilterClearButton={this.props.hasFilterClearButton}
                    defaultExtraFilterVisibility={this.props.defaultExtraFilterVisibility}
                    rowBodyHeight={this.props.rowBodyHeight}
                    rowBodyPadding={this.props.rowBodyPadding}
                    getRowIndicatorStyle={this.props.getRowIndicatorStyle}
                    onRenderRowBody={this.renderRowBody}
                    onGenerateFilterStore={this.props.onGetExtendedFilterDescriptors}
                    hasBackButton={this.props.hasBackButton}
                    onBack={this.props.onBack}
                    onGetRowCheckState={!!this.multiActionItemActivityReference && !this.runningDeferredTask ? this.getBatchActionRowCheckState : null}
                    showCheckAll={!!this.multiActionItemActivityReference && !this.runningDeferredTask}
                    checkAllValue={this.areAllRowsSelected}
                    onCheckAllValueChanged={this.selectAllOrNothing}
                    containerStyle={this.props.containerStyle}
                    columns={(
                        <NDataColumns
                            isDetailOpen={this.props.useCaseState?.displayMode === UseCaseDisplayMode.MasterDetail || this.props.isDetailOpen}
                            itemDefinitions={this.itemDefinitions}
                            numberOfMaxPrimaryButtons={this.maxPrimaryButtonCount}
                            hasExtraFilter={this.props.hasExtraFilterButton}
                            hasRefreshButton={this.props.hasRefreshButton}
                            hasPrintButton={this.props.hasPrintButton === undefined ? true : this.props.hasPrintButton}
                            orderingEnabled={this.props.orderingEnabled}
                            onActionAsync={this.actionProcessor.processActionAsync}
                            actionsOnLeftSide={this.props.actionsOnLeftSide}
                            referenceDate={this.props.referenceDate}
                            currentBatchActivityReference={this.multiActionItemActivityReference}
                        />
                    )}
                    statePersisterSettings={this.props.statePersisterSettings}
                    onChangeAsync={this.setListStateAsync}
                    onActionAsync={this.actionProcessor.processActionAsync}
                    onRowClick={!this.isMultiActionMode && this.handleRowClick}
                    onRowRightClick={this.props.onRowRightClick}
                    fullHeight={this.props.fullHeight}
                    horizontalScroll={this.props.horizontalScroll}
                    hidePointerCursorForRows={!this.hasDefaultAction}
                    emptyStateSettings={this.props.emptyStateSettings}
                    onClearFilter={this.props.onClearFilter}
                    onInitializeFilter={this.initializeFilter}
                    titleOfPrintableContent={this.props.titleOfPrintableContent}
                    onPrintDataFetchAsync={this.onPrintDataFetchAsync}
                    onRowChecked={this.checkRow}
                    hasPrintButton={this.props.hasPrintButton === undefined ? true : this.props.hasPrintButton}
                    alwaysVisibleExtraFilter={this.props.alwaysVisibleExtraFilter}
                    showLoadingIndicator={this.props.showLoadingIndicator}
                />
                {this.renderModalUseCase()}
                <EventHandler event={this.props.refreshListEvent} onFiredAsync={this.reloadListAsync} />
            </>
        );
    }

    private renderBatchActionPanel() {
        return this.props.useCaseState?.displayMode === UseCaseDisplayMode.Inline ? (
            <HisUseCaseHost
                frameType="None"
                useCaseIdentifier={this.props.useCaseState?.useCase}
                useCaseArguments={this.props.useCaseState?.useCaseArguments}
                onGetPanelProps={this.getBatchPanelProps}
            />
        ) : null;
    }

    @State.action.bound
    private selectAllOrNothing(value: boolean) {
        this.selectedBatchActionsByRowIds.clear();

        if (value) {
            if (this.items.totalCount > this.paging.pageSize) {
                dispatchAsyncErrors(this.selectAllAsync(), this);
            } else {
                this.checkRows(...this.items.items);
            }
        }
    }

    private async selectAllAsync() {
        try {
            State.runInAction(() => this.isDataLoading = true);

            const result = await this.getResultSetAsync(
                "explicit-refresh",
                { currentPage: 0, pageSize: config.nData.batchAction.maxNumberOfCheckedRows },
                this.ordering,
                this.filterStore,
                null
            );

            if (result.items.totalCount > config.nData.batchAction.maxNumberOfCheckedRows) {
                this.dialogService.ok(
                    StaticProductivityResources.Worklist.BatchAction.MaxCheckedRowsWarningTitle,
                    formatReactString(StaticProductivityResources.Worklist.BatchAction.MaxCheckedRowsWarningMessage, {
                        maxNumberOfCheckedRows: config.nData.batchAction.maxNumberOfCheckedRows
                    })
                );
            }

            const mappedItems = this.mapDataToRows(result);
            this.checkRows(...mappedItems);
        } finally {
            State.runInAction(() => this.isDataLoading = false);
        }
    }

    @State.action
    private checkRows(...rows: INDataRow[]) {
        rows.forEach(row => {
            const action = row.__actions.find(a => a.activityReference === this.multiActionItemActivityReference);

            if (!!action) {
                this.selectedBatchActionsByRowIds.set(row.__id, {
                    action,
                    displayName: this.rowDisplayNameGetter?.(row) ?? row.__id
                });
            }
        });
    }

    @State.bound
    private getBatchPanelProps(useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]): INDataBatchActionPanelProps {
        return {
            selectedActionByRowIds: this.selectedBatchActionsByRowIds,
            runningTask: this.runningDeferredTask,
            showOnlySelected: this.showOnlySelectedBatchActionRows,
            onStartTaskAsync: this.enqueueDeferredActionsAsync,
            onClearCompletedTaskAsync: this.clearCompletedDeferredActionTaskAsync,
            onShowOnlySelectedChange: this.setShowOnlySelectedBatchActionRows,
            onConfigure: this.configureMultiAction
        };
    }

    @State.action.bound
    private configureMultiAction(rowActivityReference: string, rowDisplayNameGetter: (row: INDataRow) => string) {
        this.selectedBatchActionsByRowIds.clear();
        this.multiActionItemActivityReference = rowActivityReference;
        this.rowDisplayNameGetter = rowDisplayNameGetter;
    }

    @State.action.bound
    private setShowOnlySelectedBatchActionRows(value: boolean) {
        this.showOnlySelectedBatchActionRows = value;
        dispatchAsyncErrors(this.reloadListAsync(), this);
    }

    @State.bound
    private async clearCompletedDeferredActionTaskAsync() {
        if (this.runningDeferredTask.isCompleted) {
            await this.worklistApiAdapter.clearDeferredActionTaskAsync(this.runningDeferredTask.id);
            this.clearDeferredTask();
        }
    }

    @State.bound
    private async enqueueDeferredActionsAsync(arg: WorkListArgument) {
        const taskResult = await this.actionProcessor.enqueueDeferredActionsAsync(
            this.props.deferredActionTaskType,
            Array.from(this.selectedBatchActionsByRowIds.keys()).map(key => {
                const item = this.selectedBatchActionsByRowIds.get(key);
                return {
                    token: item.action.commandToken,
                    displayName: item.displayName,
                    argument: arg,
                    rowId: key
                } as IDeferredActionItem;
            }),
            {
                useCase: this.props.useCaseState.useCase,
                useCaseArguments: this.props.useCaseState.useCaseArguments,
                multiActionActivityReference: this.props.multiActionActivityReference
            }
        );

        if (!taskResult?.value) {
            return;
        }

        const task = taskResult.value;
        this.setDeferredTask(task);
    }

    @State.bound
    private getBatchActionRowCheckState(row: INDataRow, rowId: RowId, rowIndex: number): IRowCheckState {
        return {
            isVisible: true,
            isChecked: this.selectedBatchActionsByRowIds.has(rowId),
            isDisabled: !row.__actions.find(a => a.activityReference === this.multiActionItemActivityReference)
        };
    }

    @State.action.bound
    private checkRow(isChecked: boolean, row: INDataRow, rowId: RowId, rowIndex: number) {
        if (isChecked) {
            this.selectedBatchActionsByRowIds.set(rowId, {
                action: row.__actions.find(a => a.activityReference === this.multiActionItemActivityReference),
                displayName: this.rowDisplayNameGetter?.(row) ?? `${rowId}`
            });
        } else {
            this.selectedBatchActionsByRowIds.delete(rowId);
        }
    }

    @State.bound
    private initializeFilter(filter: any) {
        this.props.onInitializeFilter?.(filter, this.itemDefinitions);
    }

    @State.bound
    private renderRowBody(row: INDataRow, rowId: string, rowIndex: number): IRowBody {
        if (rowId === this.props.selectedRowId && this.props.useCaseState?.displayMode === UseCaseDisplayMode.Inline) {
            return {
                showCells: true,

                content: (
                    <HisUseCaseHost
                        frameType="None"
                        useCaseIdentifier={this.props.useCaseState?.useCase}
                        useCaseArguments={this.props.useCaseState?.useCaseArguments}
                        onGetPanelProps={this.getInlinePanelProps}
                    />
                )
            };
        }

        return this.props.onRenderRowBody?.(row, rowId, rowIndex, this.rowBodyDefinition) ?? null;
    }

    private renderModalUseCase() {
        if (this.props.useCaseState?.displayMode === UseCaseDisplayMode.Modal) {
            return (
                <HisUseCaseHost
                    frameType="Modal"
                    useCaseIdentifier={this.props.useCaseState?.useCase}
                    useCaseArguments={this.props.useCaseState?.useCaseArguments}
                    onGetPanelProps={this.getInlinePanelProps}
                    onClose={this.clearUseCase}
                />
            );
        }
        return null;
    }

    @State.bound
    private getInlinePanelProps(useCaseIdentifier: UseCaseIdentifier, useCaseArguments: UseCaseArgument[]) {
        const props: any = this.props.onGetInlinePanelProps?.(useCaseIdentifier, useCaseArguments) ?? {};
        props.onClose = this.clearUseCase;
        props._isInlineDetail = true;
        return props;
    }

    @State.bound
    private clearUseCase(wasSuccessful: boolean = false) {
        this.props.onChange?.(null, null);

        if (wasSuccessful) {
            dispatchAsyncErrors(this.reloadListAsync(), this);
        }
    }

    @State.loadingState.bound("isDataLoading")
    private async reloadListAsync() {
        await this.setListStateAsync("changed", this.paging, this.ordering, this.filterStore, null);
    }

    @State.loadingState.bound("isDataLoading")
    private async loadPanelAsync() {
        if (!this.props.definition && !this.props.onGetDynamicListAsync) {
            return;
        }

        if (this.props.onGetDynamicListAsync) {
            const def = await this.props.onGetDynamicListAsync(new FrontendListParameters(FrontendListRequestMode.Definition, 1, 1, null, null));
            this.setListDefinition(def.definition);
        } else {

            const oldDef = await this.worklistApiAdapter.getWorklistByDefinitionId(this.props.definition);

            if (oldDef.operationWasSuccessful) {
                this.setListDefinition(oldDef.value);
            }
        }

        if (!!this.props.deferredActionTaskType) {
            await this.restoreDeferredTaskExecutionStatusAsync();
        }
    }

    private async restoreDeferredTaskExecutionStatusAsync() {
        const taskResult = await this.worklistApiAdapter.getDeferredActionTaskStatusAsync(this.props.deferredActionTaskType);

        if (!taskResult?.value) {
            this.clearDeferredTask();
            return;
        }

        const task = taskResult.value;
        this.setDeferredTask(task);
    }

    @State.action
    private setDeferredTask(task: IDeferredActionTaskStatus) {
        this.props.onChange?.(null, {
            displayMode: UseCaseDisplayMode.Inline,
            useCase: task.state.useCase,
            useCaseArguments: task.state.useCaseArguments
        });

        // Restore multiActionActivityReference
        this.props.onMultiActionActivityReferenceChange?.(task.state.multiActionActivityReference);

        this.runningDeferredTask = task;

        if (!task.isCompleted) {
            this.setAutoRefreshDeferredTaskTimer();
        }
    }

    @State.action
    private clearDeferredTask() {
        this.selectedBatchActionsByRowIds.clear();
        this.runningDeferredTask = null;
        this.multiActionItemActivityReference = null;
        this.props.onChange?.(null, null);
        this.props.onMultiActionActivityReferenceChange?.(null);
    }

    private setAutoRefreshDeferredTaskTimer() {
        this.autoRefreshDeferredTaskTimer = setInterval(() => {
            dispatchAsyncErrors((async () => {
                const taskResult = await this.worklistApiAdapter.getDeferredActionTaskStatusAsync(this.props.deferredActionTaskType);

                if (!taskResult?.value) {
                    clearInterval(this.autoRefreshDeferredTaskTimer);
                    this.autoRefreshDeferredTaskTimer = null;
                    return;
                }

                const task = taskResult.value;

                if (task.isCompleted) {
                    clearInterval(this.autoRefreshDeferredTaskTimer);
                    this.autoRefreshDeferredTaskTimer = null;
                }

                State.runInAction(() => {
                    this.runningDeferredTask = task;
                });

            })(), this);
        }, config.nData.batchAction.taskStatusPollingIntervalMs);
    }

    @State.action.bound
    private setListDefinition(definition: IDynamicListDefinition) {
        this.definition = definition;
        this.props.onDefinitionLoaded?.(definition);
    }

    @State.bound
    private async onPrintDataFetchAsync() {
        const result = await this.getResultSetAsync(
            "initial",
            { currentPage: 0, pageSize: config.dataGridPrintDataPageSize },
            this.ordering,
            this.filterStore,
            null);

        if (!isNullOrUndefined(result)) {
            const rows = this.mapDataToRows(result);
            return rows;
        }
        return null;
    }

    @State.action.bound
    private async getResultSetAsync(
        type: DataGridLoadType,
        paging: IPagingState,
        ordering: IOrderingState | IOrderingState[],
        filterStore: any,
        columns: IDataGridColumnProps[]
    ) {
        if (this.props.onFilterStoreCreatedAsync && type === "initial") {
            await this.props.onFilterStoreCreatedAsync(filterStore);
        }
        const filters = filterStore && Object.keys(filterStore).filter(key => !key.startsWith("set_") && !key.startsWith("__")).reduce((filter, fieldName) => {
            const condition = this.getFilterCondition(fieldName, filterStore[fieldName]);
            if (condition) {
                filter[fieldName] = condition;
            }
            return filter;
        }, {});

        const defaultOrdering = isNullOrUndefined(ordering) && !isNullOrUndefined(this.props.defaultOrderingColumnName) ? { columnId: this.props.defaultOrderingColumnName, 
            direction: this.props.defaultOrderingDirection ?? "asc" } as IOrderingState : null;
        const singleOrdering = isNullOrUndefined(ordering) ? defaultOrdering : (Array.isArray(ordering) ? ordering[0] : ordering);

        if (this.props.onGetDynamicListAsync) {

            const results = await this.props.onGetDynamicListAsync(
                new FrontendListParameters(FrontendListRequestMode.Data,
                    paging.currentPage,
                    paging.pageSize,
                    singleOrdering && {
                        ascending: singleOrdering.direction === "asc",
                        fieldName: singleOrdering.columnId as string
                    },
                    filters)
            );

            return results.data;

        } else {
            const results = await this.worklistApiAdapter.getWorklistDataByDefinitionId(
                this.props.definition.worklistToken,
                paging.currentPage,
                paging.pageSize,
                singleOrdering && {
                    ascending: singleOrdering.direction === "asc",
                    fieldName: singleOrdering.columnId as string
                },
                filters,
                this.props.referenceDate,
                this.isMultiActionMode && this.showOnlySelectedBatchActionRows ? Array.from(this.selectedBatchActionsByRowIds.keys()).map(i => `${i}`) : null
            );
            if (results.operationWasSuccessful) {
                return results.value;
            }
        }
        return null;
    }

    @State.loadingState.bound("isDataLoading")
    private async setListStateAsync(
        type: DataGridLoadType,
        paging: IPagingState,
        ordering: IOrderingState | IOrderingState[],
        filterStore: any,
        columns: IDataGridColumnProps[]
    ) {
        const results = await this.getResultSetAsync(type, paging, ordering, filterStore, columns);

        const defaultOrdering = isNullOrUndefined(ordering) && !isNullOrUndefined(this.props.defaultOrderingColumnName) ? { columnId: this.props.defaultOrderingColumnName,
             direction: this.props.defaultOrderingDirection ?? "asc" } as IOrderingState : null;
        const singleOrdering = isNullOrUndefined(ordering) ? defaultOrdering : (Array.isArray(ordering) ? ordering[0] : ordering);

        if (!isNullOrUndefined(results)) {

            const mappedItems = this.mapDataToRows(results);

            await this.dynamicValueConverter.loadReferenceDataAsync();

            const pagedItems: IPagedItems<INDataRow> = {
                items: mappedItems,
                totalCount: results.items.totalCount
            };

            if ((paging.currentPage * paging.pageSize) > pagedItems.totalCount) {
                await this.setListStateAsync(
                    type,
                    { pageSize: paging.pageSize, currentPage: 0 },
                    ordering,
                    filterStore,
                    columns
                );
                return;
            }

            this.initializePanel(
                pagedItems,
                paging,
                singleOrdering,
                filterStore,
                results.someItemsAreHidden,
                results.globalFrontendActions);
        }
    }

    @State.bound
    private mapDataToRows(results: IWorklistData) {
        return results.items.items.map(item => this.itemDefinitions.reduce((row, columnDef, index) => {
            row[columnDef.attributeName] = this.dynamicValueConverter.convert(item.attributes[index], columnDef.attributeType, columnDef.attributeName);
            row.__actions = item.actions;
            row.__actionDescriptors = item.actionDescriptors;
            row.__defaultAction = item.defaultAction;
            row.__id = item.id;
            row.__contextInfo = item.contextInfo;
            return row;
        }, {} as INDataRow));
    }

    @State.bound
    private getFilterCondition(fieldName: string, value: any): ICondition {
        if (isNullOrWhiteSpace(value)) {
            return undefined;
        }
        if (Array.isArray(value) && value.length === 0) {
            return undefined;
        }

        const itemDefinition = this.itemDefinitions.find(d => d.attributeName === fieldName);

        if (!itemDefinition) {
            if (this.props.onGetExtendedFilterDescriptors) {
                return this.getExtendedFilterConditionByDescriptor(fieldName, value, this.props.onGetExtendedFilterDescriptors());
            }
            throw new Error(`Cannot find item definition for field '${fieldName}'`);
        }

        if (this.props.onGetExtendedFilterDescriptors) {
            const descriptors = this.props.onGetExtendedFilterDescriptors();

            const extendedDescriptor = descriptors.find(i => i.id === fieldName);
            if (extendedDescriptor) {
                return this.getExtendedFilterConditionByDescriptor(fieldName, value, [extendedDescriptor]);
            }
        }

        const conditionConverter = FilterConditionConverters[itemDefinition.filterConditionType];

        if (!conditionConverter) {
            throw new Error(`Unknown item definition attribute type: ${itemDefinition.attributeType}`);
        }

        return conditionConverter(value);
    }

    @State.bound
    private getExtendedFilterConditionByDescriptor(fieldName: string, value: any, descriptors: IDataGridFilterDescriptor[]): ICondition {

        const descriptor = descriptors.find(i => i.id === fieldName);

        if (descriptor.customConverter) {
            return descriptor.customConverter(value);
        } else {
            const conditionConverter = FilterConditionConverters[descriptor.type];

            if (!conditionConverter) {
                throw new Error(`Unknown item definition attribute type: ${descriptor.type}`);
            }

            return conditionConverter(value);
        }
    }

    @State.bound
    private handleRowClick(row: INDataRow, rowId: React.ReactText, rowIndex: number) {
        if (!isNullOrUndefined(row.__defaultAction)) {
            dispatchAsyncErrors(
                this.actionProcessor.processDefaultActionAsync({
                    rowId: rowId.toString(),
                    actionToken: row.__defaultAction.commandToken,
                    clientSideAction: row.__defaultAction.clientSideAction,
                    worklistActionType: WorklistActionType.Other,
                    activityReference: null,
                    argumentItems: { useCaseDisplayMode: row.__defaultAction.useCaseDisplayMode },
                }, row.__defaultAction.unavailableMessageResourceId, row.__defaultAction.isAvailable),
                this
            );
        }

        const defaultFrontendAction = row.__actionDescriptors?.find(ad => ad.presentation.isDefaultAction);
        if (defaultFrontendAction) {
            dispatchAsyncErrors(this.dispatchRowDefaultActionAsync(defaultFrontendAction.action, rowId), this);
        }

        this.props.onRowClick?.(row, rowId, rowIndex);
    }

    private async dispatchRowDefaultActionAsync(action: FrontendActionBase, rowId: React.ReactText) {
        if (action instanceof ShowScreenFrontendActionBase) {
            let primaryScreenState: string | null = null;

            switch (action.displayMode) {
                case ScreenDisplayMode.Detail:
                case ScreenDisplayMode.Modal:
                    primaryScreenState = rowId.toString();
                    break;
            }

            await this.props._actionDispatcher.dispatchAsync(
                action,
                { primaryScreenState }
            );
        } else {
            await this.props._actionDispatcher.dispatchAsync(action);
        }
    }

    @State.action
    private initializePanel(
        items: IPagedItems<any>,
        paging: IPagingState,
        singleOrdering: IOrderingState,
        filterStore: any,
        someItemsAreHidden: boolean,
        globalFrontendActions: ActionDescriptor[]
    ) {
        this.props.onDataLoaded?.(items, globalFrontendActions);
        this._items = items;

        this.paging = paging;
        this.ordering = singleOrdering;
        this.someItemsAreHidden = someItemsAreHidden;

        this.filterStore = filterStore;
    }

    @State.action.bound
    private setActionLoadingState(isLoading: boolean) {
        this.isActionLoading = isLoading;
    }
}

export default connect(
    NDataGrid,
    new DependencyAdapter<INDataGridProps, INDataGridDependencies>(c => ({
        worklistApiAdapter: c.resolve("WorklistApiAdapter"),
        dynamicValueConverter: c.resolve("DynamicValueConverter"),
        localizationService: c.resolve("ILocalizationService"),
        notificationService: c.resolve("INotificationService"),
        dialogService: c.resolve("IDialogService"),
        fileSaverService: c.resolve("IFileSaverService"),
        activityRegistry: c.resolve("IActivityRegistry"),
        cultureCodeProvider: c.resolve("ICurrentCultureProvider")
    })),
    new HisActionDispatcherAdapter()
);
