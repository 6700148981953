import UseCaseFrameType from "@HisPlatform/Components/UseCaseFrame/UseCaseFrameType";
import React from "react";
import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";

export interface IUseCaseFrameContext {
    frameType: UseCaseFrameType;

    onClose: (wasSuccessful: boolean) => void;
}


const UseCaseFrameContext = React.createContext<IUseCaseFrameContext | null>(null);
export {
    UseCaseFrameContext
};