// tslint:disable:variable-name
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import ImmunizationListScreenStore from "./ImmunizationListScreenStore";

const { 
    ContextComponent: ImmunizationListScreenStoreContext, 
    StoreProvider: ImmunizationListScreenStoreProvider, 
    useStore: useImmunizationListScreenStore,
    provideStore: provideImmunizationListScreenStore,
} = createPanelStoreProvider(ImmunizationListScreenStore);

export {
    ImmunizationListScreenStoreContext,
    ImmunizationListScreenStoreProvider,
    useImmunizationListScreenStore,
    provideImmunizationListScreenStore
};