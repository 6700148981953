import React, { useMemo, useCallback } from "react";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EditableShouldNotBeShorterThanValidationRule from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/Validation/EditableShouldNotBeShorterThanValidationRule";
import { NumberBox } from "@CommonControls";
import RuleTypeId from "@Primitives/RuleTypeId.g";

function ShouldNotBeShorterThanValidationRulePropertyPanel() {

    const store = useFormLayoutEditorStore();

    const rule = useMemo(
        () => store.selectedEditorValidationRules.find(vr => vr.ruleType === RuleTypeId.ShouldNotBeShorterThan.value) as EditableShouldNotBeShorterThanValidationRule,
        [store.selectedEditorValidationRules]
    );
    const setMinLength = useCallback((value: number) => {

        if (value > 0) {
            if (rule) {
                rule.setMinLength(value);
            } else {
                store.addValidationRule(new EditableShouldNotBeShorterThanValidationRule(
                    store.selectedEditor.dataReference,
                    {
                        minLength: value
                    }
                ), store.selectedEditor.parentDataReferences);
            }
        } else {
            store.removeValidationRule(rule);
        }

    }, [rule, store.selectedEditor.dataReference]);

    return (
        <NumberBox label="Minimum hossz" automationId="FieldMinLength_NumberBox" value={rule?.minLength ?? 0} onChange={setMinLength} />
    );
}

export default State.observer(ShouldNotBeShorterThanValidationRulePropertyPanel);