import React from "react";
import PropertyGroup from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/PropertyGroup";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import ScalarProperty from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/ScalarProperty";
import StaticConfigurationResources from "@HisPlatform/BoundedContexts/Configuration/StaticResources/StaticConfigurationResources";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import PropertyGroupEditableColumn from "@HisPlatform/BoundedContexts/Configuration/Components/Controls/PropertyGroupEditableColumn/PropertyGroupEditableColumn";
import NumericRange from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/Model/DynamicProperties/NumericRange";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import Style from "./PropertyGroupGrid.less";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";

interface IPropertyGroupsGridDependencies {
    localizationService: ILocalizationService;
}

interface IPropertyGroupsGridProps {
    propertyGroup: PropertyGroup;
    filterValue: string;
    _dependencies?: IPropertyGroupsGridDependencies;
}

@State.observer
class PropertyGroupGrid extends React.Component<IPropertyGroupsGridProps> {

    private get filterValue() {
        return this.props.filterValue;
    }

    private get localizationService() {
        return this.props._dependencies.localizationService;
    }

    @State.computed
    private get visibleProperties() {
        return this.props.propertyGroup.properties.filter(x => x.isVisible);
    }

    private renderAsString(value: any) {
        if (value instanceof NumericRange) {
            return `${value.from} - ${value.to}`;
        }
        if (typeof value === "boolean") {
            return value ?
            StaticConfigurationResources.PropertyGroupsPanel.BoolPropertyValue.True :
            StaticConfigurationResources.PropertyGroupsPanel.BoolPropertyValue.False;
        }
        return `${value}`;
    }

    @State.bound
    private renderPropertyName(_: any, row: ScalarProperty) {
        const rowNameSelector = `${this.props.propertyGroup.name}.${row.name}`;
        let localizedName = this.localizationService.localizeProperty(rowNameSelector);

        if (!localizedName) {
            localizedName = rowNameSelector;
        }

        if (localizedName === rowNameSelector) {
            localizedName = localizedName.slice(localizedName.lastIndexOf(".") + 1);
        }

        const infoSelector = `${this.props.propertyGroup.name}.${row.name}_Info`;
        const localizedInfo = this.localizationService.localizeProperty(infoSelector);
        const showInfo = localizedInfo !== infoSelector && localizedInfo;

        return (
            <div className={Style.container}>
                <div className={Style.labelContainer} >
                    <Ui.HighlightableLabel
                        label={localizedName}
                        filterValue={this.filterValue}
                    />
                </div>
                <div className={Style.iconContainer} >
                    {showInfo && <Ui.InfoButton tooltipContent={localizedInfo} />}
                </div>
            </div>
        );
    }

    @State.bound
    private renderPropertyNameHint(row: any) {
        const rowNameSelector = `${this.props.propertyGroup.name}.${row.name}`;
        let localizedName = this.localizationService.localizeProperty(rowNameSelector);

        if (!localizedName) {
            localizedName = rowNameSelector;
        }

        if (localizedName === rowNameSelector) {
            localizedName = localizedName.slice(localizedName.lastIndexOf(".") + 1);
        }
        return localizedName;
    }

    private getPropertyGroupName() {
        const name = this.localizationService.localizeProperty(this.props.propertyGroup.name);
        return name ? name : this.props.propertyGroup.name;
    }

    public render() {
        return (
            <>
                <h3>{this.getPropertyGroupName()}</h3>
                <Ui.DataGrid
                    dataSource={this.visibleProperties}
                    footer={null}
                    rowId="name"
                    rowHeight={55}
                    actionsColumn={false}
                    fixedLayout
                    automationId="propertyGroupGrid_dataGrid"
                >
                    <DataGridColumn
                        dataGetter={"name"}
                        onRenderCellValue={this.renderPropertyName}
                        onRenderHintValue={this.renderPropertyNameHint}
                        header={StaticConfigurationResources.PropertyGroupsPanel.ColumnHeaders.Name}
                        width="40%"
                        showHint={false}
                    />
                    <PropertyGroupEditableColumn
                        dataGetter={this.getValue}
                        header={StaticConfigurationResources.PropertyGroupsPanel.ColumnHeaders.Value}
                        width="20%"
                        showHint={false} />
                </Ui.DataGrid>
            </>
        );
    }

    private getValue(row: ScalarProperty) {
        return row.isTemporal ?
            row.getValue(LocalDate.today()) :
            row.getValue();
    }
}

export default connect(
    PropertyGroupGrid,
    new DependencyAdapter<IPropertyGroupsGridProps, IPropertyGroupsGridDependencies>(container => {
        return {
            localizationService: container.resolve("ILocalizationService")
        };
    })
);
