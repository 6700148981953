import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import PermissionDefinitionBuilder from "@PluginInterface/OperationAccessControl/PermissionDefinitionBuilder";
import { PermissionDefinitions } from "@HisPlatform/PermissionDefinitions/PermissionDefinitions.g";
import { HunSocialSecurityPermissionDefinitions } from "@HunSocialSecurityPlugin/PermissionDefinitions/HunSocialSecurityPermissionDefinitions.g";
import PermissionScopeType from "@Primitives/PermissionScopeTypes";

export default function configurePerformanceStatementPermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry) {
    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(HunSocialSecurityPermissionDefinitions.PerformanceStatement.PerformanceStatement.Edit, PermissionDefinitions.Productivity.Worklist.ViewWorklists)
            .withPermissionScopesFor(
                PermissionDefinitions.Productivity.Worklist.ViewWorklists,
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistType, ["PerformanceStatementBased"])
            )
            .build()
    );

    permissionDefinitionRegistry.register(
        PermissionDefinitionBuilder.create(HunSocialSecurityPermissionDefinitions.PerformanceStatement.PerformanceStatement.EditItem, PermissionDefinitions.Productivity.Worklist.ViewWorklists)
            .withPermissionScopesFor(
                PermissionDefinitions.Productivity.Worklist.ViewWorklists,
                PermissionDefinitionBuilder.createPositivePermissionScope(PermissionScopeType.worklistType, ["PerformanceStatementCareActivityBased"])
            )
            .build()
    );
}