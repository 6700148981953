import AuthorizationReferenceDataStore from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/AuthorizationReferenceDataStore";
import RoleId from "@Primitives/RoleId.g";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import RoleSelectBox from "@HisPlatform/BoundedContexts/Authorization/Components/Controls/RoleSelectBox";
import Button from "@CommonControls/Button";
import { wrappedValuesAreEquals } from "@HisPlatform/Common/ValueWrapperHelpers";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import UserGroup from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroup";
import * as Styles from "./RoleEditorDialog.less";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import PointOfCareRoles from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Actions/PointOfCareRoles";
import UserHeader from "@HisPlatform/BoundedContexts/UserManagement/Components/Controls/UserHeader";
import { IRoleEditorDialogParams, IRoleEditorDialogResult } from "./RoleEditorDialogParams";
import RoleEditorDialogView from "./RoleEditorDialogView";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";

interface IRoleEditorDialogDependencies {
    authorizationReferenceDataStore: AuthorizationReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IRoleEditorDialogProps extends IModalComponentParams<IRoleEditorDialogResult>, IRoleEditorDialogParams {
    _dependencies?: IRoleEditorDialogDependencies;

}

@State.observer
class RoleEditorDialog extends React.Component<IRoleEditorDialogProps> {
    @State.observable.ref private selectedIds: RoleId[] = [];

    public componentDidMount(): void {
        this.initialize();
    }

    public componentDidUpdate(prevProps: IRoleEditorDialogProps): void {
        if ((!prevProps.pointOfCareRoles && this.props.pointOfCareRoles)
            || (this.props.pointOfCareRoles && !wrappedValuesAreEquals(prevProps.pointOfCareRoles.id, this.props.pointOfCareRoles.id))) {
            this.initialize();
        }
    }

    @State.bound
    private onSave() {
        this.props.onClose({ selectedIds: this.selectedIds });
    }
    @State.bound
    private onClose() {
        this.props.onCancel();
        this.props.onClose(null);
    }
    @State.action.bound
    private initialize() {
        this.selectedIds = this.props.pointOfCareRoles ? [].concat(this.props.pointOfCareRoles.roleIds) : [];
    }

    @State.action.bound
    private onRoleIdsChange(newIds: RoleId[]) {
        this.selectedIds = newIds;
    }

    public render() {
        return (
            <RoleEditorDialogView
                onSave={this.onSave}
                onClose={this.onClose}
                selectedIds={this.selectedIds}
                isNew={this.props.isNew}
                pointOfCareRoles={this.props.pointOfCareRoles}
                relatedEntity={this.props.relatedEntity}
                onRoleIdsChange={this.onRoleIdsChange}
                pointOfCareMap={this.props._dependencies.organizationReferenceDataStore.pointOfCareMap}
            />
        );
    }
}

export default connect(
    RoleEditorDialog,
    new DependencyAdapter<IRoleEditorDialogProps, IRoleEditorDialogDependencies>((container) => {
        return {
            authorizationReferenceDataStore: container.resolve("AuthorizationReferenceDataStore"),
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
        };
    })
);
