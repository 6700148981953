import React, { useCallback } from "react";
import { isNullOrUndefined, isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareLocationColumnFilter from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/CareLocationColumn/CareLocationColumnFilter";
import { ExternalLocationVersionSelector } from "@Primitives/ExternalLocationId.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { iconNameType } from "@CommonControls/Icon";
import * as Ui from "@CommonControls";
import { getIconNameByHealthcareProfessions } from "@HisPlatform/BoundedContexts/Organization/Components/Helpers/IconTypeNameHelper";
import { ExternalCareLocationDto, InternalCareLocationDto } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import PointOfCareId from "@Primitives/PointOfCareId.g";

interface ICareLocationColumnDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface ICareLocationColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: ICareLocationColumnDependencies;
}


const CareLocationColumn: React.FC<ICareLocationColumnProps> = (props) => {

    const { children, ...columnProps } = props;
    const organizationReferenceDataStore = props._dependencies.organizationReferenceDataStore;

    const valueRenderer = useCallback((value: any) => {
        if (!isNullOrUndefined(value)) {
            if (value instanceof InternalCareLocationDto) {
                const poc = organizationReferenceDataStore.allPointsOfCareMap.get(new PointOfCareId(value.pointOfCareId.value));
                if (!isNullOrUndefined(poc)) {
                    const iconName: iconNameType = getIconNameByHealthcareProfessions(poc.healthcareProfessionIds.map(props._dependencies.organizationReferenceDataStore.healthCareProfession.get));
                    return (
                        <>
                            {iconName &&
                                <Ui.Icon
                                    iconName={iconName}
                                    style={{ marginRight: 5 }}
                                />}
                            {poc.shorthand}
                        </>
                    );
                }
            } else if (value instanceof ExternalCareLocationDto) {
                const ext = organizationReferenceDataStore.externalLocationStore.get(new EntityVersionSelector(value.externalLocationSelector.entityId, value.externalLocationSelector.validOn));
                if (!isNullOrUndefined(ext)) {
                    return !isNullOrEmptyString(ext.shortName) ? ext.shortName : (ext.name ?? "");
                }
            } else {
                throw new Error("Unknown CareLocation type");
            }
        }
        return "";
    }, []);

    const hintRenderer = useCallback((value: any) => {
        if (!isNullOrUndefined(value)) {
            if (value instanceof InternalCareLocationDto) {
                const poc = organizationReferenceDataStore.allPointsOfCareMap.get(new PointOfCareId(value.pointOfCareId.value));
                if (!isNullOrUndefined(poc)) {
                    return !isNullOrEmptyString(poc.name) ? poc.name : (poc.shorthand ?? "");
                }
            } else if (value instanceof ExternalCareLocationDto) {
                const ext = organizationReferenceDataStore.externalLocationStore.get(new EntityVersionSelector(value.externalLocationSelector.entityId, value.externalLocationSelector.validOn));
                if (!isNullOrUndefined(ext)) {
                    return !isNullOrEmptyString(ext.name) ? ext.name : (ext.shortName ?? "");
                }
            }
        }
        return "";
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps) => (
        <CareLocationColumnFilter
            {...filterProps}
            hoverOnlyIndicatorIcons
        />
    ), []);

    return (
        <DataGridColumn
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            onRenderHintValue={hintRenderer}
        />
    );
};

export default connect(
    CareLocationColumn,
    new DependencyAdapter<ICareLocationColumnProps, ICareLocationColumnDependencies>(c => ({
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore")
    }))
);
