import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ICodeSelectorCommonProps from "@HisPlatform/Components/HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import CountryApiAdapter from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/ApiAdapter/CommonReferenceData/CountryApiAdapter";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import CountryId from "@Primitives/CountryId.g";
import * as HisUi from "@HisPlatformControls";

interface ICitizenshipSelectorDependencies {
    commonReferenceDataDataStore: CommonReferenceDataDataStore;
    countryApiAdapter: CountryApiAdapter;
}

interface ICitizenshipSelectorProps extends ICodeSelectorCommonProps<CountryId> {
    _dependencies?: ICitizenshipSelectorDependencies;
}

@State.observer
class CitizenshipSelector extends React.Component<ICitizenshipSelectorProps> {
    public render() {
        return (
            <HisUi.CountrySelector
                {...this.props}
                variant="citizenship"
            />
        );
    }
}

export default connect(
    CitizenshipSelector,
    new DependencyAdapter<ICitizenshipSelectorProps, ICitizenshipSelectorDependencies>((container) => {
        return {
            commonReferenceDataDataStore: container.resolve("CommonReferenceDataDataStore"),
            countryApiAdapter: container.resolve("CountryApiAdapter"),
        };
    })
);
