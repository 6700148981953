exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ExtendedEhiCovidStatusReportFilter_search-container_2eGYD {\n  background: #f4f7fc;\n  border: solid 1px #00b4ec;\n  padding: 5px 4px 8px;\n  margin: 8px 8px 0px;\n}\n.ExtendedEhiCovidStatusReportFilter_search-item_1LKAY {\n  padding: 0px 4px 4px;\n}\n.ExtendedEhiCovidStatusReportFilter_outer-container_1TPdR {\n  margin-top: 10px;\n}\n.ExtendedEhiCovidStatusReportFilter_outer-container_1TPdR label {\n  margin: 0px 4px;\n  font-family: PragatiNarrow;\n  font-size: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"search-container": "ExtendedEhiCovidStatusReportFilter_search-container_2eGYD",
	"searchContainer": "ExtendedEhiCovidStatusReportFilter_search-container_2eGYD",
	"search-item": "ExtendedEhiCovidStatusReportFilter_search-item_1LKAY",
	"searchItem": "ExtendedEhiCovidStatusReportFilter_search-item_1LKAY",
	"outer-container": "ExtendedEhiCovidStatusReportFilter_outer-container_1TPdR",
	"outerContainer": "ExtendedEhiCovidStatusReportFilter_outer-container_1TPdR"
};