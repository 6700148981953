import PropertyBase from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyBase";
import PropertyGroup from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyGroup";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

export interface IPropertyVersionsModalResult {
    updatedProperty: PropertyBase;
}

export interface IPropertyVersionsModalParams {
    property: PropertyBase;
    propertyGroup: PropertyGroup;
    validateVersionsAsync: (property: PropertyBase) => Promise<IClientValidationResult[]>;
}

export default class PropertyVersionsModalParams implements IPropertyVersionsModalParams {
    public static type = "PropertyVersionModal";
    public get type() {
        return PropertyVersionsModalParams.type;
    }

    constructor(
        public readonly property: PropertyBase,
        public readonly propertyGroup: PropertyGroup,
        public readonly validateVersionsAsync: (property: PropertyBase) => Promise<IClientValidationResult[]>
    ) {
    }
}
