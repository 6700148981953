import State from "@Toolkit/ReactClient/Common/StateManaging";
import ExtendedFilterStoreBase from "@HisPlatform/BoundedContexts/Productivity/Components/Worklist/ExtendedFilterStoreBase";
import { IDataGridFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterStoreGenerator";
import { IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { createFilterDescriptor } from "@CommonControls/DataGrid/Filter/FilterHelpers";
import { IFilterStore } from "@CommonControls/DataGrid/Filter/IFilterStore";
import NeakPerformanceStatementId from "@Primitives/NeakPerformanceStatementId.g";
import EuPerformanceStatementId from "@Primitives/EuPerformanceStatementId.g";
import WorklistConditionType from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/WorklistConditionType.g";

export default class ErrorListFilterStore extends ExtendedFilterStoreBase {

    constructor(
        filterStore: IFilterStore
    ) {
        super(filterStore);
    }

    public static neakPerformanceStatementIdName: string = "ErrorListBased_NeakPerformanceStatementId";
    public static euPerformanceStatementIdName: string = "ErrorListBased_EuPerformanceStatementId";

    @State.observable.ref public neakPerformanceStatementId: NeakPerformanceStatementId = null;
    @State.observable.ref public euPerformanceStatementId: EuPerformanceStatementId = null;
    
    @State.action.bound
    public setNeakPerformanceStatementId(newValue: NeakPerformanceStatementId) {        
        this.neakPerformanceStatementId = newValue;
        this.setFilter(ErrorListFilterStore.neakPerformanceStatementIdName, this.neakPerformanceStatementId);
    }

    @State.action.bound
    public setEuPerformanceStatementId(newValue: EuPerformanceStatementId) {        
        this.euPerformanceStatementId = newValue;
        this.setFilter(ErrorListFilterStore.euPerformanceStatementIdName, this.euPerformanceStatementId);
    }

    @State.action.bound
    public clearFilters() {
        this.neakPerformanceStatementId = null;
        this.euPerformanceStatementId = null;
    }

    @State.action.bound
    public clearAllFilters() {
        this.clearFilters();
        this.filterStore.__reset();
    }

    public static getFilterDescriptors() {
        const descriptors: IDataGridFilterDescriptor[] = [];

        const neakPerformanceStatementIdFilterValueSerializer: IDataGridFilterValueSerializer<NeakPerformanceStatementId> = {
            serialize: value => value.value,
            deserialize: (value: string) => (!value ? null : new NeakPerformanceStatementId(value))
        };

        const euPerformanceStatementIdFilterValueSerializer: IDataGridFilterValueSerializer<EuPerformanceStatementId> = {
            serialize: value => value.value,
            deserialize: (value: string) => (!value ? null : new EuPerformanceStatementId(value))
        };

        // Create descriptors
        const neakPerformanceStatementIdFilterDescriptor = createFilterDescriptor(this.neakPerformanceStatementIdName,
            neakPerformanceStatementIdFilterValueSerializer,
            WorklistConditionType.EntityId);

        const euPerformanceStatementIdFilterDescriptor = createFilterDescriptor(this.euPerformanceStatementIdName,
            euPerformanceStatementIdFilterValueSerializer,
            WorklistConditionType.EntityId);

        // Fill descriptors array
        descriptors.push(neakPerformanceStatementIdFilterDescriptor);
        descriptors.push(euPerformanceStatementIdFilterDescriptor);

        return descriptors;
    }
}
