import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IHunEHealthInfrastructureWebAppResources from "./IHunEHealthInfrastructureWebAppResources";
import { createStaticResourceDictionary } from "@Toolkit/ReactClient/Common/LocalizedStrings";

const resources = createStaticResourceDictionary<IHunEHealthInfrastructureWebAppResources>();

export function loadStaticHunEHealthInfrastructureWebAppResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    resources.load(localizationService.getResourceGroup("WebApp"));
}

export default resources as IHunEHealthInfrastructureWebAppResources;