import DependencyContainer from "@DiContainer";
import RoleApiAdapter from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/OperationAccessControl/RoleApiAdapter";
import RoleStoreMapper from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/OperationAccessControl/RoleStoreMapper";
import AccessRuleStoreMapper from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/OperationAccessControl/AccessRuleStoreMapper";
import AccessRuleApiAdapter from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/OperationAccessControl/AccessRuleApiAdapter";

DependencyContainer
    .bind("RoleApiAdapter")
    .to(RoleApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("RoleStoreMapper")
    .to(RoleStoreMapper)
    .inSingletonScope();

DependencyContainer
    .bind("AccessRuleApiAdapter")
    .to(AccessRuleApiAdapter)
    .inSingletonScope();

DependencyContainer
    .bind("AccessRuleStoreMapper")
    .to(AccessRuleStoreMapper)
    .inSingletonScope();