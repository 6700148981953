import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import MedicationVersion from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/Medications/MedicationVersion";

export interface IMedicationInfoModalParams extends IModalParams {
    medication: MedicationVersion;
}

export default class MedicationInfoModalParams implements IMedicationInfoModalParams {

    public static type = "MedicationInfoModalParams";
    public get type() { return MedicationInfoModalParams.type; }

    constructor(
        public readonly medication: MedicationVersion
    ) { }

}