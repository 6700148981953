import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class PreloadedReferenceDataStore<TId extends IStringEntityId, TStore extends { id: IStringEntityId }> {
    private loaderPromise: Promise<void> | null = null;
    @State.observable.ref protected map = new Map<string, TStore>();

    @State.computed public get items() {
        return Array.from(this.map.values());
    }

    constructor(
        private readonly loadAllAsync: () => Promise<TStore[]>,
    ) { }

    @State.bound
    public async ensureLoadedAsync() {
        this.loaderPromise ??= this.loadMapAsync();
        await this.loaderPromise;
    }

    public async getByIdAsync(id: TId): Promise<TStore> {
        await this.ensureLoadedAsync();
        return this.map.get(id.value);
    }

    public getById(id: TId): TStore {
        return this.map.get(id.value);
    }

    private async loadMapAsync() {
        const items = await this.loadAllAsync();
        State.runInAction(() => {
            this.map = new Map(items.map(i => [i.id.value, i]));
        });
    }
}