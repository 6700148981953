import React from "react";
import { FieldValidationResult, Icon, InfoButton, Button } from "@CommonControls";
import MaskedInput from "@asseco/react-maskedinput";
import { ICharsFormatters } from "./IInputmaskCore";
import Styles from "./TextBox.less";
import { iconNameType, iconVisualStyleType } from "@CommonControls/Icon";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import TextBoxGroup, { ITextBoxGroupProps } from "@CommonControls/TextBox/TextBoxGroup";
import { ICommonControlProps, getStandardHtmlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import { Tooltip } from "@Toolkit/ReactClient/Components/Tooltip";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import { isNullOrUndefined, arrayIsNullOrEmpty, emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ReadOnlyContextAdapter from "@Toolkit/ReactClient/Components/ReadOnlyContext/ReadOnlyContextAdapter";
import ValidationBoundaryAdapter from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryAdapter";
import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";
import EventHandler from "@Toolkit/ReactClient/Components/EventHandler/EventHandler";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ICommonEditorProps from "@CommonControls/ICommonEditorProps";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";

export interface ITextBoxProps extends ICommonControlProps, ICommonEditorProps<string> {
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onEnterKeyDown?: () => void;
    name?: string;
    id?: string;
    focusOnMount?: boolean;
    focusEvent?: TypedEvent;
    blurEvent?: TypedEvent;
    placeholder?: string;
    isPassword?: boolean;
    iconName?: iconNameType;
    mask?: string;
    formatCharacters?: ICharsFormatters;
    placeholderChar?: string;
    className?: string;
    required?: boolean;
    innerLabel?: { upperText: string, lowerText: string };
    label?: string;
    infoLabel?: string | React.ReactNode;
    style?: React.CSSProperties;
    size?: "compact" | "standard";
    embedded?: boolean;
    propertyIdentifier?: string;
    maxLength?: number;
    multiline?: boolean;
    multilineMinLineCount?: number;
    multilineMinHeight?: number;
    multilineMaxLineCount?: number;
    multilineMaxHeight?: number;
    textAlign?: "left" | "center" | "right";
    stopClickPropagation?: boolean;
    visualMode?: "dark" | "normal";
    disableAutofill?: boolean;
    showMultipleValidationProblems?: boolean;
    autoResize?: boolean;
    clearable?: boolean;
    valueOnClear?: any;
    autoResizeHeight?: string;
    onHeightChange?: (newValue: string) => void;
    forceShowValidationResults?: boolean;
}

interface ITextBoxCoreProps extends ITextBoxProps {
    validationContext: IValidationBoundaryStore;
}

@State.observer
export class TextBoxCore extends React.Component<ITextBoxCoreProps, undefined> {

    private inputHtmlElement: HTMLInputElement | HTMLTextAreaElement | MaskedInput = null;
    @State.observable.ref private showPasswordAsText = false;
    @State.observable private _autoResizeHeight: string = "auto";

    public static defaultProps: Partial<ITextBoxProps> = {
        size: "standard",
        disabled: false,
        required: false,
        multiline: false,
        autoResize: false,
        textAlign: "left",
        visualMode: "normal",
        disableAutofill: false,
        clearable: true,
        valueOnClear: null
    };

    constructor(props: ITextBoxCoreProps) {
        super(props);
        if (props.multiline === true && props.mask) {
            throw new Error("Multiline textbox can't be masked!");
        }
    }

    @State.bound
    private changeHandler(event: any) {
        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    }

    @State.bound
    private clearHandler() {
        if (this.props.onChange) {
            this.props.onChange(this.props.valueOnClear);
        }
    }

    @State.bound
    private rawChangeHandler(event: any) {
        if (this.props.onChange) {
            this.props.onChange(event.target.rawValue);
        }
    }

    private renderValidationResults(): React.ReactNode {
        if (this.props.forceShowValidationResults || !this.props.disabled) {
            return <FieldValidationResult problems={this.validationProblems} showMultipleProblems={this.props.showMultipleValidationProblems} />;
        }
        return null;
    }

    private nullToEmptyString(value: string) {
        return value === null || value === undefined ? "" : value;
    }

    @State.computed
    private get validationProblems() {
        if (this.props.validationContext) {
            const problems = this.props.validationContext.getValidationProblems(this.props.propertyIdentifier);
            return arrayIsNullOrEmpty(problems) ? emptyArray : problems;
        }
        return emptyArray;
    }

    @State.computed
    private get hasClearIcon() {
        return this.props.value && !this.props.disabled && !this.props.isReadOnly && this.props.clearable;
    }

    @State.computed
    private get validationResult() {
        return this.validationProblems.length === 0 ? null : this.validationProblems[0];
    }

    @State.computed
    private get autoResizeHeight() {
        return this.props.autoResizeHeight ?? this._autoResizeHeight;
    }

    private getClassName() {

        const classes = new CompositeClassName(Styles.textBoxContainer);

        classes.addIf(this.props.visualMode === "dark", Styles.darkMode);

        const validationProblemSeverity = this.validationResult && this.validationResult.severity;

        classes.addIf(!this.props.disabled && !this.props.isReadOnly && !validationProblemSeverity, Styles.normalEnabled);
        classes.addIf(this.props.size === "compact", Styles.compactSize);
        classes.addIf(this.isRequired, Styles.required);
        classes.addIf(this.props.textAlign === "center", Styles.pagerTextAlignCenter);
        classes.addIf(this.props.textAlign === "right", Styles.pagerTextAlignRight);

        if (!this.props.disabled && !this.props.isReadOnly && validationProblemSeverity !== null && validationProblemSeverity !== undefined) {
            classes.addIf(validationProblemSeverity === "warning", Styles.warning);
            classes.addIf(validationProblemSeverity === "error", Styles.error);
        }

        classes.addIf(this.props.disabled, Styles.disabled);
        classes.addIf(this.props.embedded, Styles.embedded);
        classes.addIf(this.props.isReadOnly, Styles.readonly);

        classes.addIf(this.props.multiline, Styles.multilineContainer);

        classes.add(this.props.className);

        return classes.classNames;
    }

    private getEditorClassName() {
        if (this.props.iconName && this.props.innerLabel) {
            throw new Error("Cannot use icon and inner label together.");
        }

        if (this.props.iconName) {
            return Styles.editorWithIcon;
        }

        if (this.props.innerLabel) {
            return Styles.editorRightAligned;
        }

        return undefined;
    }

    private getIconVisualStyle(): iconVisualStyleType {
        const validationProblemSeverity = this.validationResult && this.validationResult.severity;
        if (!this.props.disabled && validationProblemSeverity !== null && validationProblemSeverity !== undefined) {
            if (validationProblemSeverity === "warning") {
                return "warning";
            }
            if (validationProblemSeverity === "error") {
                return "error";
            }

        }
        if (this.props.visualMode === "dark") {
            return "dark";
        }
        return "secondary";
    }

    @State.bound
    private onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {

        if (this.props.onEnterKeyDown && event.key === "Enter") {
            this.props.onEnterKeyDown();
        }

        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    }

    private autoResize() {
        if (this.inputHtmlElement && this.inputHtmlElement instanceof HTMLTextAreaElement) {
            this.inputHtmlElement.style.height = "auto";

            if (this.props.autoResizeHeight && this.props.onHeightChange) {
                this.props.onHeightChange(this.inputHtmlElement.scrollHeight + "px");
            } else {
                this.setLocalAutoResizeHeight(this.inputHtmlElement.scrollHeight + "px");
            }

            this.inputHtmlElement.style.height = this.autoResizeHeight;

            const maxHeight = parseInt(this.inputHtmlElement.style.maxHeight, 10);
            if (Number.isNaN(maxHeight) || maxHeight > this.inputHtmlElement.scrollHeight) {
                this.inputHtmlElement.style.overflow = "hidden";
            } else {
                this.inputHtmlElement.style.overflow = "auto";
            }
        }
    }

    @State.action.bound
    private setLocalAutoResizeHeight(newValue: string) {
        this._autoResizeHeight = newValue;
    }

    @State.bound
    private focusLeave(e: any) {
        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
    }

    @State.bound
    private blur() {
        if (this.inputHtmlElement instanceof HTMLTextAreaElement || this.inputHtmlElement instanceof HTMLInputElement) {
            this.inputHtmlElement.blur();
        }
    }

    @State.bound
    private renderClearIndicator(clearHandler: any) {
        return (
            <Icon
                size="compact"
                iconName="remove"
                visualStyle={this.getIconVisualStyle()}
                disabled={this.props.disabled}
                style={{ top: 9, right: 4, position: this.props.multiline ? "absolute" : null }}
                onClick={clearHandler}
                automationId={this.props.automationId + "__clearButton"}
            />
        );
    }

    private get isRequired() {
        return !!(this.props.required || this.props.validationContext && this.props.validationContext.isRequired(this.props.propertyIdentifier));
    }

    @State.bound
    private clickHandler(e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (this.props.stopClickPropagation) {
            e.stopPropagation();
        }

        if (this.props.onClick) {
            this.props.onClick(e);
        }
    }

    @State.bound
    public onFocusHandler(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (this.props.disableAutofill && !this.props.isReadOnly) {
            (e.target as HTMLInputElement).readOnly = false;
        }

        if (this.props.onFocus) {
            this.props.onFocus(e);
        }
    }

    private renderInput() {

        const htmlProps = {
            name: this.props.name,
            id: this.props.id ?? this.props.automationId,
            placeholder: this.props.placeholder,
            onClick: this.clickHandler,
            onFocus: this.onFocusHandler,
            disabled: this.props.disabled,
            readOnly: this.props.disableAutofill ? true : this.props.isReadOnly,
            maxLength: this.props.maxLength,
            autoComplete: this.props.disableAutofill ? "off" : undefined,
        };

        const baseProps = {
            className: this.getEditorClassName(),
            onChange: this.changeHandler,
            ref: (me: any) => this.inputHtmlElement = me,
            onKeyDown: this.onKeyDownHandler,
            onFocus: this.onFocusHandler,
            onBlur: this.focusLeave,
            value: this.nullToEmptyString(this.props.value),
            required: this.isRequired
        };

        if (this.props.mask) {

            return (
                <MaskedInput
                    {...htmlProps}
                    {...baseProps}
                    mask={this.props.mask}
                    formatCharacters={this.props.formatCharacters}
                    placeholderChar={this.props.placeholderChar}
                    onRawChange={this.rawChangeHandler}
                    data-automation-id={this.props.automationId || undefined}
                />
            );

        } else if (this.props.multiline) {
            return (
                <textarea
                    {...htmlProps}
                    {...baseProps}
                    style={this.getMultilineStyle()}
                    data-automation-id={this.props.automationId || undefined}
                />
            );
        } else {
            return (
                <input
                    {...htmlProps}
                    {...baseProps}
                    type={this.props.isPassword && !this.showPasswordAsText ? "password" : "text"}
                    data-automation-id={this.props.automationId || undefined}
                />
            );
        }
    }

    private getMultilineStyle() {
        const style: React.CSSProperties = {
            minHeight: this.getTextareaMinHeight(),
            maxHeight: this.getTextareaMaxHeight(),
        };

        if (this.props.autoResize) {
            style.resize = "none";
            style.height = this.autoResizeHeight;
        }

        return style;
    }

    @State.bound
    public focus() {
        (this.inputHtmlElement as any).focus();
    }

    private renderInnerLabels() {
        if (this.props.innerLabel) {
            return (
                <div>
                    <div className={Styles.innerLabelTop}>{this.props.innerLabel.upperText}</div>
                    <div className={Styles.innerLabelBottom}>{this.props.innerLabel.lowerText}</div>
                </div>
            );
        }
        return null;
    }

    private getHeight() {
        if (this.props.multiline) {
            return undefined;
        } else {
            return this.props.size === "compact" ? 24 : 32;
        }
    }

    private getTextareaMinHeight() {
        if (isNullOrUndefined(this.props.multilineMinLineCount)) {
            return this.props.multilineMinHeight;
        } else {
            return this.props.multilineMinLineCount * (this.props.size === "compact" ? 24 : 32);
        }
    }

    private getTextareaMaxHeight() {
        if (isNullOrUndefined(this.props.multilineMaxLineCount)) {
            return this.props.multilineMaxHeight;
        } else {
            return this.props.multilineMaxLineCount * (this.props.size === "compact" ? 24 : 32);
        }
    }

    public render() {
        const htmlProps = getStandardHtmlProps(this.props, this.getClassName());
        const { id, ...htmlPropsWithoutId } = htmlProps;

        const style = {
            ...this.props.style,
            height: this.getHeight()
        };

        const labelClasses = new CompositeClassName(Styles.label);
        labelClasses.addIf(this.props.visualMode === "dark", Styles.darkLabel);

        return (
            <div className={Styles.outerContainer}>
                {this.props.infoLabel && <InfoButton tooltipContent={this.props.infoLabel} />} {this.props.label && <label htmlFor={this.props.id ?? this.props.automationId} className={labelClasses.classNames}>{this.props.label}{this.isRequired && <span className={Styles.requiredStar} />}</label>}
                <Tooltip
                    content={this.props.tooltipContent}
                    placement={this.props.tooltipPosition}
                    contentAlignment={this.props.tooltipTextAlign}>
                    <div {...htmlPropsWithoutId} style={style}>
                        {this.props.iconName ? <Icon iconName={this.props.iconName} visualStyle={this.getIconVisualStyle()} /> : null}
                        {this.renderInnerLabels()}
                        {this.renderInput()}
                        {this.props.children}
                        {this.hasClearIcon ? this.renderClearIndicator(this.clearHandler) : null}
                        {this.props.isPassword ?
                            <Button
                                iconName="show"
                                size="compact"
                                style={{ background: "none", border: "none", marginTop: 4 }}
                                onMouseDown={this.TogglePassword}
                                onMouseUp={this.TogglePassword}
                                tooltipContent={StaticWebAppResources.Common.Button.Tooltips.ShowPassword}
                                tooltipPosition="bottom"
                                automationId={this.props.automationId + "_togglePasswordButton"}
                            />
                            : null}
                    </div>
                </Tooltip>
                {this.renderValidationResults()}
                <EventHandler event={this.props.validationContext?.validateAllEvent} onFired={this.validate} />
                <EventHandler event={this.props.focusEvent} onFired={this.focus} />
                <EventHandler event={this.props.blurEvent} onFired={this.blur} />
            </div>
        );
    }

    @State.action.bound
    private TogglePassword() {
        this.showPasswordAsText = this.showPasswordAsText ? false : true;
    }

    public componentDidMount() {
        if (this.props.autoResize) {
            this.autoResize();
        }

        if (this.props.focusOnMount) {
            this.focus();
        }
    }

    public componentDidUpdate(prevProps: ITextBoxProps) {
        if (this.props.value !== prevProps.value) {
            this.validate();

            if (this.props.autoResize) {
                this.autoResize();
            }
        }
    }

    @State.bound
    private validate() {
        if (this.props.validationContext) {
            this.props.validationContext.changed(this.props.propertyIdentifier, this.props.value);
        }
    }
}


interface TextBox extends React.StatelessComponent<ITextBoxProps & React.ClassAttributes<TextBoxCore>> {
    Group?: React.ComponentType<ITextBoxGroupProps>;
}


const TextBox = connect(
    TextBoxCore,
    new ReadOnlyContextAdapter<ITextBoxCoreProps>((props, isReadOnly) => ({ isReadOnly: props.isReadOnly || isReadOnly })),
    new ValidationBoundaryAdapter<ITextBoxCoreProps>((props, validationStore) => ({ validationContext: validationStore }))
) as TextBox;

TextBox.Group = TextBoxGroup;

export default TextBox;
