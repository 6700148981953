import * as Ui from "@CommonControls";
import React from "react";
import {IScreenPropsBase} from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import {ScreenStateContextAdapter, ScreenStateContextStore} from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import MasterDetailScreen from "@Toolkit/ReactClient/Components/Screen/MasterDetailScreen";
import {provideCareActivityTextBlockListPanelScreenStore, useCareActivityTextBlockListPanelScreenStore} from "@HisPlatform/Packages/Care/Screens/CareActivityTextBlockListPanelScreen/CareActivityTextBlockListPanelScreenStoreProvider";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import CareActivityTextBlockGrid from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/CareActivityTextBlockListPanel/CareActivityTextBlockGrid";
import ShowCareActivityTextBlockListScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityTextBlockListScreenAction.g";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import {HisActionDispatcherAdapter} from "@HisPlatform/Common/FrontendActions/HisActionDispatcher";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import PatientId from "@Primitives/PatientId.g";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";
import CareActivityState from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareActivityState.g";

export interface ICareActivityTextBlockListPanelScreenProps extends IScreenPropsBase<ShowCareActivityTextBlockListScreenAction> {
    _screenState?: ScreenStateContextStore;
    _actionDispatcher?: IActionDispatcher;
    _patientId?: PatientId;
    _careActivityState?: CareActivityState
}

function CareActivityTextBlockListPanelScreen(props: ICareActivityTextBlockListPanelScreenProps) {
    const store = useCareActivityTextBlockListPanelScreenStore();

    return (
        <MasterDetailScreen
            title={StaticCareResources.CareRegister.CareActivityTextBlockListPanel.Title}
            onSecondaryCancelled={store.tryRemoveSelectedItem}
            onSecondarySavedExisting={store.reloadScreenDataAsync}
            onSecondarySavedNew={store.reloadScreenDataAsync}
            iconName="document"
            toolbar={
                <ReadOnlyContext.Provider value={props._careActivityState === CareActivityState.Closed}>
                    <Ui.Button
                        onClick={store.addTextBlockAsync}
                        text={StaticWebAppResources.Common.Button.Add}
                        iconName="plus"
                        permissionCheckOperationNames="SaveTextBlock"
                        permissionDeniedStyle="disabled"
                        automationId="addTextBlockIcon" />
                </ReadOnlyContext.Provider>
            }>
                <CareActivityTextBlockGrid
                    idList={store.idList}
                    mandatoryTextBlockTypes={store.mandatoryTextBlockTypes}
                    onSelectedRowChange={store.openDetailView}
                    onBack={props._screenState.cancelled}
                    selectedItem={store.selectedItem} />
        </MasterDetailScreen>
    );
}

export default connect<ICareActivityTextBlockListPanelScreenProps>(
    provideCareActivityTextBlockListPanelScreenStore(CareActivityTextBlockListPanelScreen),
    new ScreenStateContextAdapter(),
    new HisActionDispatcherAdapter<ICareActivityTextBlockListPanelScreenProps>(),
    new CareActivityContextAdapter<ICareActivityTextBlockListPanelScreenProps>(c => ({
        _patientId: c.careActivity?.patientId,
        _careActivityState: c.careActivity?.state
    }))
);