import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import { getNavigationMenuItems } from "@HisPlatform/Packages/Settings/Configuration/NavigationMenuItems";
import ShowDeveloperScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowDeveloperScreenAction.g";
import ShowDocumentTemplateManagementScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowDocumentTemplateManagementScreenAction.g";
import ShowFormDefinitionsScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowFormDefinitionsScreenAction.g";
import ShowInsurerOrganizationsScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowInsurerOrganizationsScreenAction.g";
import ShowWorklistDefinitionsScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowWorklistDefinitionsScreenAction.g";
import IScreenRegistry from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import NavigateToRouteActionProcessor from "@HisPlatform/Services/Implementation/ActionProcessing/NavigateToRouteActionProcessor";
import GlobalActionRegistry from "@Toolkit/ReactClient/ActionProcessing/GlobalActionRegistry";
import ShowCreateNewCustomerScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowCreateNewCustomerScreenAction.g";
import CustomerScreen from "@HisPlatform/Packages/Settings/Screens/Customer/CustomerScreen";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import { configureFrontendActionMappings } from "@HisPlatform/Packages/Settings/FrontendActions/MapperConfigurations.g";
import CustomerScreenFormLogic from "@HisPlatform/Packages/Settings/FormLogics/CustomerScreenFormLogic";
import IFormLogicRegistry from "@HisPlatform/Services/Definition/FormLogicRegistry/IFormLogicRegistry";

class SettingsPackageInitializer implements IPackageInitializer {

    public configureActionProcessor(registry: GlobalActionRegistry) {

        registry.register(
            ShowDeveloperScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => ApplicationRoutes.developer.makeRoute()));

        registry.register(
            ShowInsurerOrganizationsScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => ApplicationRoutes.insurerOrgs.makeRoute()));

        registry.register(
            ShowDocumentTemplateManagementScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => ApplicationRoutes.documentTemplate.makeRoute()));

        registry.register(
            ShowFormDefinitionsScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => ApplicationRoutes.formDefinitions.makeRoute()));

        registry.register(
            ShowWorklistDefinitionsScreenAction.id,
            NavigateToRouteActionProcessor.createFactory(() => ApplicationRoutes.worklistDefinitions.makeRoute()));
    }

    public configureDynamicNavigation(registry: IDynamicNavigationRegistry): void {
        getNavigationMenuItems().forEach(navigationItem => registry.register(navigationItem));
    }

    public configureScreens(registry: IScreenRegistry): void {
        registry.register<ShowCreateNewCustomerScreenAction>(
            ShowCreateNewCustomerScreenAction.id,
            {
                component: CustomerScreen
            } as any,
            {
                displayName: StaticFinanceResources.Customers.AddActionDisplayName,
                iconName: "plus"
            }
        );
    }

    public configureFormLogics(registry: IFormLogicRegistry): void {
        registry.register("CustomerScreenForm", "CustomerScreenFormLogic");
    }

    public configureMapper(configurator: IMapperConfigurator) {
        configureFrontendActionMappings(configurator);
    }
}

export default new SettingsPackageInitializer();