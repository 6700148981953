// tslint:disable:variable-name
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import HomeMenuStore from "./HomeMenuStore";

const { 
    ContextComponent: HomeMenuStoreContext, 
    StoreProvider: HomeMenuStoreProvider, 
    useStore: useHomeMenuStore,
    provideStore: provideHomeMenuStore,
} = createPanelStoreProvider(HomeMenuStore);

export {
    HomeMenuStoreContext,
    HomeMenuStoreProvider,
    useHomeMenuStore,
    provideHomeMenuStore
};