import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import InsurerOrganizationsApiAdapter from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/ApiAdapter/InsurerOrganizations/InsurerOrganizationsApiAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import InsurerOrganization from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/InsurerOrganizations/InsurerOrganization";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import InsurerOrganizationsListPanelView from "./InsurerOrganizationsListPanelView";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import InsurerOrganizationDetailPanelModalParams, { IInsurerOrganizationDetailPanelModalResult } from "@HisPlatform/BoundedContexts/Finance/Components/InsurerOrganizationModal/InsurerOrganizationDetailPanelModalParams";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";

interface IInsurerOrganizationsListPanelDependencies {
    insurerOrganizationsApiAdapter: InsurerOrganizationsApiAdapter;
    financeReferenceDataStore: FinanceReferenceDataStore;
    dialogService: IDialogService;
    notificationService: INotificationService;
}

interface IInsurerOrganizationsListPanelProps {
    _dependencies?: IInsurerOrganizationsListPanelDependencies;
    _modalService?: IModalService;
}

/** @screen */
@State.observer
class InsurerOrganizationsListPanel extends React.Component<IInsurerOrganizationsListPanelProps> {

    private get notificationService() { return this.props._dependencies.notificationService; }

    @State.observable.ref private organizations: InsurerOrganization[] = [];

    @State.observable.ref private selectedOrganizationId: InsurerOrganizationId = null;

    private readonly loadOrInitializeNewAsync = createInitialPanelLoader(this.loadAsync, this.apiAdapter.updateInsurerOrganizationPermissionCheckAsync);

    private get apiAdapter() {
        return this.props._dependencies.insurerOrganizationsApiAdapter;
    }
    @State.computed
    private get modalService() {
        return this.props._modalService;
    }

    @State.action.bound
    private setOrganizations(newValue: InsurerOrganization[]) {
        this.organizations = newValue;
    }

    @State.bound
    private editInsurerOrganization(orgId: InsurerOrganizationId) {
        this.setSelectedOrganizationId(orgId);
        this.showInsurerOrganizationDetailPanel();
    }

    @State.action.bound
    private setSelectedOrganizationId(newValue: InsurerOrganizationId) {
        this.selectedOrganizationId = newValue;
    }

    @State.action.bound
    private showInsurerOrganizationDetailPanel() {
        dispatchAsyncErrors(this.showInsurerOrganizationDetailPanelAsync(), this);
    }

    @State.bound
    private async showInsurerOrganizationDetailPanelAsync() {
        const result = await this.modalService.showDialogAsync<IInsurerOrganizationDetailPanelModalResult>(new InsurerOrganizationDetailPanelModalParams(
            this.selectedOrganizationId
        ));
        this.setSelectedOrganizationId(null);
        if (result?.operationWasSuccesful) {
            await this.loadAsync();
        }
    }

    @State.bound
    private async loadAsync() {
        const organizations = await this.apiAdapter.getInsurerOrganizationsAsync();
        await this.props._dependencies.financeReferenceDataStore.insurerOrganizationTypes.ensureLoadedAsync();
        this.setOrganizations(organizations.value);
    }

    @State.bound
    private deleteAsync(row: InsurerOrganization) {
        return async () => {
            if (row && row.id) {
                const answer = await this.props._dependencies.dialogService.yesNo(StaticWebAppResources.Common.DialogTitle.ConfirmationTitle, formatString(StaticFinanceResources.InsurerOganizations.DialogMessages.DeleteConfirmationMessage, row.name));
                if (answer.resultCode === DialogResultCode.Yes) {
                    const result = await this.props._dependencies.insurerOrganizationsApiAdapter.deleteInsurerOrganizationAsync(row.id, row.rowVersion);
                    this.notificationService.showSavedSuccessfully(result.operationWasSuccessful);
                    if (result.operationWasSuccessful) {
                        this.loadAsync();
                    }
                }
            }
        };
    }

    @State.bound
    private edit(row: InsurerOrganization) {
        return () => {
            this.editInsurerOrganization(row.id);
        };
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadOrInitializeNewAsync(), this);
    }

    public render() {

        if (this.loadOrInitializeNewAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }

        return (
            <InsurerOrganizationsListPanelView
                insurerOrganizationTypes={this.props._dependencies.financeReferenceDataStore.insurerOrganizationTypes}
                onDeleteAsync={this.deleteAsync}
                onEdit={this.edit}
                organizations={this.organizations}
                selectedOrganizationId={this.selectedOrganizationId}
                showInsurerOrganizationDetailPanel={this.showInsurerOrganizationDetailPanel}
            />
        );
    }
}

export default connect(
    InsurerOrganizationsListPanel,
    new DependencyAdapter<IInsurerOrganizationsListPanelProps, IInsurerOrganizationsListPanelDependencies>(container => {
        return {
            insurerOrganizationsApiAdapter: container.resolve("InsurerOrganizationsApiAdapter"),
            financeReferenceDataStore: container.resolve("FinanceReferenceDataStore"),
            dialogService: container.resolve("IDialogService"),
            notificationService: container.resolve("INotificationService"),
        };
    }),
    new HisModalServiceAdapter()
);