import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import { Modal } from "@CommonControls";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import StatusChangeBlockingData from "@Primitives/StatusChangeBlockingData";
import ResourceId from "@Primitives/ResourceId.g";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { ContextAwareModal } from "@HisPlatformControls";
import DocumentManagementReferenceDataStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/DocumentManagementReferenceDataStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { ICantCloseCareActivityDialogResult, ICantCloseCareActivityDialogParams, CantCloseCareActivityDialogNavigateTo } from "./CantCloseCareActivityDialogParams";
import _ from "@HisPlatform/Common/Lodash";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ValidationProblemParameterMapperService from "@Toolkit/CommonWeb/ApiAdapter/ValidationProblemParameterMapperService";
import CareActivityTextBlock from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityTextBlock/CareActivityTextBlock";
import PerformedServiceListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/PerformedServiceListStore";
import DiagnosisListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisListStore";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";

interface IStatusChangeBlockingDataInfo {
    statusChangeBlockingData: StatusChangeBlockingData;
    message?: string;
}

interface ICantCloseCareActivityPanelDependencies {
    localizationService: ILocalizationService;
    toolkitLocalizationService: IToolkitLocalizationService;
    validationProblemParameterMapperService: ValidationProblemParameterMapperService;
}

interface ICantCloseCareActivityPanelProps extends
    IModalComponentParams<ICantCloseCareActivityDialogResult>,
    ICantCloseCareActivityDialogParams {
    _dependencies?: ICantCloseCareActivityPanelDependencies;
}

/** @screen */
@State.observer
class CareActivityStatusChangeNotPossiblePanel extends React.Component<ICantCloseCareActivityPanelProps> {

    @State.observable.ref private statusChangeBlockingDataInfos: IStatusChangeBlockingDataInfo[] = [];

    private get dependencies() {
        return this.props._dependencies;
    }

    private get localizator() {
        return this.props._dependencies.toolkitLocalizationService.getValidationResultSummaryLocalizator();
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.action.bound
    private async loadAsync() {
        const careActivityBaseDataErrors = this.getErrorsFor("CareActivityBaseData");
        const diagnosisErrors = this.getErrorsFor("DiagnosisList");
        const performedServiceErrors = this.getErrorsFor("PerformedServiceList");
        const dischargeDataErrors = this.getErrorsFor("CareActivityDischargeData");
        const documentTypeErrors = this.getErrorsFor("CareActivity").filter(i => i.propertyPath === "DocumentTypes");
        const textBlockErrors = this.getErrorsFor("CareActivity").filter(i => i.propertyPath === "TextBlock");

        await this.resolveAllParametersAsync(performedServiceErrors,
            diagnosisErrors,
            textBlockErrors,
            documentTypeErrors);

        const blockingData = this.buildBlockingData(careActivityBaseDataErrors,
            diagnosisErrors,
            performedServiceErrors,
            dischargeDataErrors,
            documentTypeErrors,
            textBlockErrors);

        this.setStatusChangeBlockingDataInfos(blockingData);
    }

    private async resolveAllParametersAsync(performedServiceErrors: IClientValidationProblem[], diagnosisErrors: IClientValidationProblem[], textBlockErrors: IClientValidationProblem[], documentTypeErrors: IClientValidationProblem[]) {
        await this.resolveParametersAsync("PerformedServiceListStore", performedServiceErrors);
        await this.resolveParametersAsync("DiagnosisListStore", diagnosisErrors);
        await this.resolveParametersAsync("CareActivityTextBlock", textBlockErrors);
        await this.resolveParametersAsync("CareActivityDocument", documentTypeErrors);
    }

    private getErrorsFor(entity: "CareActivityBaseData" | "DiagnosisList" | "PerformedServiceList" | "CareActivityDischargeData" | "CareActivity") {
        return _.flatten(this.props.validationResult.filter(i => i.entityName === entity).map(i => i.problems)).filter(i => i.severity === "error");
    }

    private buildBlockingData(careActivityBaseDataErrors: IClientValidationProblem[],
        diagnosisErrors: IClientValidationProblem[],
        performedServiceErrors: IClientValidationProblem[],
        dischargeDataErrors: IClientValidationProblem[],
        documentTypeErrors: IClientValidationProblem[],
        textBlockErrors: IClientValidationProblem[]) {

        const blockingData = [];

        if (careActivityBaseDataErrors.length > 0) {
            blockingData.push({ statusChangeBlockingData: StatusChangeBlockingData.ClinicalData, message: this.getMessage(careActivityBaseDataErrors) });
        }
        if (diagnosisErrors.length > 0) {
            blockingData.push({ statusChangeBlockingData: StatusChangeBlockingData.Diagnoses, message: this.getMessage(diagnosisErrors) });
        }
        if (performedServiceErrors.length > 0) {
            blockingData.push({ statusChangeBlockingData: StatusChangeBlockingData.PerformedServices, message: this.getMessage(performedServiceErrors) });
        }
        if (dischargeDataErrors.length > 0) {
            blockingData.push({ statusChangeBlockingData: StatusChangeBlockingData.DischargeData, message: this.getMessage(dischargeDataErrors) });
        }
        if (documentTypeErrors.length > 0) {
            blockingData.push({ statusChangeBlockingData: StatusChangeBlockingData.DocumentTypes, message: this.getMessage(documentTypeErrors) });
        }
        if (textBlockErrors.length > 0) {
            blockingData.push({ statusChangeBlockingData: StatusChangeBlockingData.CareDocuments, message: this.getMessage(textBlockErrors) });
        }

        return blockingData;
    }

    @State.bound
    private async resolveParametersAsync(mapperIdentifier: string, errors: IClientValidationProblem[]) {
        for (const error of errors) {
            await this.dependencies.validationProblemParameterMapperService.resolveParametersAsync(mapperIdentifier, error);
        }
    }

    @State.action.bound
    private setStatusChangeBlockingDataInfos(statusChangeBlockingDataInfos: IStatusChangeBlockingDataInfo[]) {
        this.statusChangeBlockingDataInfos = statusChangeBlockingDataInfos;
    }

    @State.bound
    private navigateTo(navigateTo: CantCloseCareActivityDialogNavigateTo) {
        this.props.onClose({
            navigateTo
        });
    }

    @State.bound
    private cancel() {
        this.props.onClose(null);
    }

    @State.bound
    private navigationHandlerFactory(statusChangeBlockingData: StatusChangeBlockingData): () => void {
        switch (statusChangeBlockingData) {
            case StatusChangeBlockingData.CareDocuments:
                return () => {
                    this.navigateTo(CantCloseCareActivityDialogNavigateTo.TextBlocks);
                };
            case StatusChangeBlockingData.ClinicalData:
                return () => {
                    this.navigateTo(CantCloseCareActivityDialogNavigateTo.ClinicalData);
                };
            case StatusChangeBlockingData.DischargeData:
                return () => {
                    this.navigateTo(CantCloseCareActivityDialogNavigateTo.DischargeData);
                };
            case StatusChangeBlockingData.PerformedServices:
                return () => {
                    this.navigateTo(CantCloseCareActivityDialogNavigateTo.PerformedServices);
                };
            case StatusChangeBlockingData.Diagnoses:
                return () => {
                    this.navigateTo(CantCloseCareActivityDialogNavigateTo.Diagnoses);
                };
            case StatusChangeBlockingData.ServiceRequests:
                return () => {
                    this.navigateTo(CantCloseCareActivityDialogNavigateTo.ServiceRequests);
                };
            case StatusChangeBlockingData.ServiceRequestObservations:
                return () => {
                    this.navigateTo(CantCloseCareActivityDialogNavigateTo.ServiceRequestObservations);
                };
            case StatusChangeBlockingData.DocumentTypes:
                return () => {
                    this.navigateTo(CantCloseCareActivityDialogNavigateTo.CareDocuments);
                };
            default:
                throw new Error("CareActivityStatusChangeReason value not supported.");
        }

    }

    @State.bound
    private getMessage(problems: IClientValidationProblem[]): string {
        const messages = problems.map(i => {
            const validationResultId = new ResourceId(i.message);
            const localizedMessage = i.rawMessage ?? this.localizator.getValidationMessage(validationResultId);
            const message = localizedMessage === "(missing)" && !!i.rawMessage ? i.message : localizedMessage;
            const parameters = i.resolvedParameters || i.parameters;

            return formatStringWithObjectParams(message, parameters);
        });

        return messages.join(", ");
    }

    public render() {
        return (
            <ContextAwareModal onClose={this.cancel} isOpen title={StaticCareResources.CareRegister.CantCloseCareActivityPanel.Title} size="compact">
                <Modal.Body>
                    {StaticCareResources.CareRegister.CantCloseCareActivityPanel.Message}
                    <ul>
                        {this.statusChangeBlockingDataInfos.map((r, i) =>
                            <li key={i}>
                                <a onClick={this.navigationHandlerFactory(r.statusChangeBlockingData)}>
                                    {this.props._dependencies.localizationService.localizeEnum(StatusChangeBlockingData[r.statusChangeBlockingData], "StatusChangeBlockingData").Name}
                                </a>
                                {!!r.message && ` - ${r.message}`}
                            </li>
                        )}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Modal.Button closeModalOnClick visualStyle="primary" automationId="okButton" float="right">
                        {StaticWebAppResources.Common.DialogButton.Ok}
                    </Modal.Button>
                </Modal.Footer>
            </ContextAwareModal>
        );
    }
}

export default connect(
    CareActivityStatusChangeNotPossiblePanel,
    new DependencyAdapter<ICantCloseCareActivityPanelProps, ICantCloseCareActivityPanelDependencies>(container => {
        return {
            localizationService: container.resolve("ILocalizationService"),
            toolkitLocalizationService: container.resolve("IToolkitLocalizationService"),
            careActivityApiAdapter: container.resolve("CareActivityApiAdapter2"),
            referenceDataStore: container.resolve("DocumentManagementReferenceDataStore"),
            validationProblemParameterMapperService: container.resolve("ValidationProblemParameterMapperService")
        };
    })
);
