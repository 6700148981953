import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";


export interface IEhiEmergencyDialogParams extends IModalParams {
}

export default class EhiEmergencyDialogParams implements IEhiEmergencyDialogParams {

    public static type = "EhiEmergencyDialog";
    public get type() { return EhiEmergencyDialogParams.type; }
}