import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { buildQueryStringArray } from "@Toolkit/CommonWeb/QueryStringBuilder";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { mapToNameStore } from "@HisPlatform/Common/PersonNameMapper";
import _ from "@HisPlatform/Common/Lodash";
import PractitionerId from "@Primitives/PractitionerId.g";
import IDoctor from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IDoctor";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IAssistant from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IAssistant";
import { mapIdentifier, mapNameStore } from "./StoreMappers";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import PractitionerStoreMapper from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerStoreMapper";
import {
    getAddPractitionerDto,
    getUpdatePractitionerDto
} from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/DtoMappers";
import PractitionerIdentifierStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerIdentifierStore";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import { PersonName } from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import NameStore from "@Primitives/NameStore";
import UserId from "@Primitives/UserId.g";
import DoctorStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/DoctorStore";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import IncludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierFilter";
import ExcludeIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierFilter";
import IncludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/IncludeIdentifierSystemIdFilter";
import ExcludeIdentifierSystemIdFilter from "@Toolkit/CommonWeb/Model/Filtering/ExcludeIdentifierSystemIdFilter";
import CodeStartsWithFilter from "@Toolkit/CommonWeb/Model/Filtering/CodeStartsWithFilter";
import CodeDoesNotStartWithFilter from "@Toolkit/CommonWeb/Model/Filtering/CodeDoesNotStartWithFilter";
import ExplicitIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExplicitIdentifierFilter";
import PractitionerSelectorQueryOrderingFields from "@HisPlatform/BoundedContexts/Organization/Api/Practitioners/Enum/PractitionerSelectorQueryOrderingFields.g";
import PractitionerType from "@HisPlatform/BoundedContexts/Organization/Api/Practitioners/Enum/PractitionerType.g";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import UserInfoStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/UserInfoStore";

@Di.injectable()
export default class PractitionerApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IPractitionersClient") private readonly apiClient: Proxy.IPractitionersClient,
        @Di.inject("PractitionerStoreMapper") private readonly practitionerStoreMapper: PractitionerStoreMapper
    ) {
        super();
    }

    public async searchDoctorsAsync(isExternal?: boolean, valueToSearch?: string, maxResultCount?: number, filters?: FilterBase[]) {
        return await this.quickSearchPractitionersAsync(isExternal, PractitionerType.Doctor, valueToSearch, maxResultCount, filters);
    }

    public async searchAssistantsAsync(isExternal?: boolean, valueToSearch?: string, maxResultCount?: number, filters?: FilterBase[]) {
        return await this.quickSearchPractitionersAsync(isExternal, PractitionerType.Assistant, valueToSearch, maxResultCount, filters);
    }

    @State.bound
    public searchPractitionersAsync(practitionerType: PractitionerType, validOn: LocalDate, isExternal: boolean, name: string, identifier: string, combinedFilterText: string, ordering: IOrderingState, paging: IPagingState, practitionerId: PractitionerId, filters?: FilterBase[]) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<{ values: PractitionerStore[], totalCount: number, pageIndex: number }>(),
            async (target) => {
                const response = await this.apiClient.practitionerSelectorQueryAsync(
                    CreateRequestId(),
                    new Proxy.PractitionerSelectorControllerDto({
                        practitionerType: practitionerType,
                        validOn: validOn,
                        isExternal: isExternal,
                        name: name,
                        identifier: identifier,
                        combinedFilterText: combinedFilterText,
                        practitionerId: practitionerId,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfPractitionerSelectorQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfPractitionerSelectorQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: PractitionerSelectorQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new Proxy.QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 10
                            })
                        }),
                        filters: this.convertFiltersToProxyFilters(filters)
                    })
                );

                const mappedResults: PractitionerStore[] = [];

                for (const practitionerDto of response?.results.values || []) {
                    const newStore = new PractitionerStore();
                    this.practitionerStoreMapper.applyToStore(newStore, practitionerDto);
                    mappedResults.push(newStore);
                }

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    pageIndex: response.results && response.results.pageIndex,
                    values: mappedResults
                };
            }
        );
    }

    public quickSearchPractitionersAsync(isExternal?: boolean, practitionerType?: PractitionerType, valueToSearch?: string, maxResultCount?: number, filters?: FilterBase[]) {
        return this.processOperationAsync(
            new SimpleStore<PractitionerId[]>(),
            async target => {
                const response = await this.apiClient.searchForPractitionersQueryAsync(
                    CreateRequestId(),
                    new Proxy.SearchForPractitionersControllerDto({
                        isExternal: isExternal,
                        maxResultCount: maxResultCount,
                        practitionerType: practitionerType,
                        validOn: LocalDate.today(),
                        valueToSearch: valueToSearch,
                        filters: this.convertFiltersToProxyFilters(filters)
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.practitionerIdList;
            }
        );
    }

    @State.bound
    public getDoctorsByIdsAsync(ids: PractitionerId[]): Promise<SimpleStore<IDoctor[]>> {
        return this.processOperationAsync(
            new SimpleStore<IDoctor[]>(),
            async target => {

                if (arrayIsNullOrEmpty(ids)) {
                    target.value = [];
                }

                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => {
                    return id.value.toString();
                }));

                const response = await this.apiClient.getDoctorsByIdsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetDoctorsByIdsControllerDto({
                        ids: ids
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.doctors.map(d => {
                    return {
                        id: d.id,
                        name: this.mapName(d.baseData.name, d.identifiers),
                        code: (d as Proxy.DoctorDto).code
                    } as IDoctor;
                });
            }
        );
    }

    @State.bound
    public getPractitionerIdByDoctorCodeAsync(code: string): Promise<SimpleStore<PractitionerId>> {
        return this.processOperationAsync(
            new SimpleStore<PractitionerId>(),
            async target => {
                const response = await this.apiClient.getPractitionerIdByDoctorCodeQueryAsync(
                    CreateRequestId(),
                    code
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.practitionerId;
            }
        );
    }

    @State.bound
    private convertFiltersToProxyFilters(filters: FilterBase[]): Proxy.FilterBase[] {
        if (!filters) {
            return [];
        }

        return filters.map((filter: FilterBase) => this.mapFilterToProxyFilter(filter));
    }

    private mapFilterToProxyFilter(filter: FilterBase): Proxy.FilterBase {
        if (filter instanceof IncludeIdentifierFilter) {
            return new Proxy.IncludeIdentifierFilter({
                value: new Identifier(
                    new IdentifierSystemId(filter.value.identifierSystemId.value),
                    filter.value.value
                )
            });
        } else if (filter instanceof ExcludeIdentifierFilter) {
            return new Proxy.ExcludeIdentifierFilter({
                value: new Identifier(
                    new IdentifierSystemId(filter.value.identifierSystemId.value),
                    filter.value.value
                )
            });
        } else if (filter instanceof IncludeIdentifierSystemIdFilter) {
            return new Proxy.IncludeIdentifierSystemIdFilter({
                value: filter.value
            });
        } else if (filter instanceof ExcludeIdentifierSystemIdFilter) {
            return new Proxy.ExcludeIdentifierSystemIdFilter({
                value: filter.value
            });
        } else if (filter instanceof CodeStartsWithFilter) {
            return new Proxy.CodeStartsWithFilter({
                value: filter.value
            });
        } else if (filter instanceof CodeDoesNotStartWithFilter) {
            return new Proxy.CodeDoesNotStartWithFilter({
                value: filter.value
            });
        } else if (filter instanceof ExplicitIdentifierFilter) {
            return new Proxy.ExplicitIdentifierFilter({
                type: filter.type,
                value: filter.value
            });
        } else {
            throw new Error(`Unsupported filter: ${typeof filter}`);
        }
    }

    private mapName(baseNameStore: PersonName, identifiers: Proxy.PractitionerIdentifierDto[]) {
        if (!baseNameStore.familyName && !baseNameStore.givenName1 && !baseNameStore.prefix) {
            const filteredIdentifiers = identifiers.filter(x => x.name !== "");
            return mapToNameStore({ familyName: filteredIdentifiers[0].name } as NameStore);
        } else {
            return mapNameStore(baseNameStore);
        }
    }

    @State.bound
    public getAssistantsByIdsAsync(ids: PractitionerId[]): Promise<SimpleStore<IAssistant[]>> {
        return this.processOperationAsync(
            new SimpleStore(),
            async target => {

                if (arrayIsNullOrEmpty(ids)) {
                    target.value = [];
                }

                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value));

                const response = await this.apiClient.getPractitionersByIdsQueryAsync(
                    CreateRequestId(),
                    buildQueryStringArray(normalizedIds)
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.practitioners.map(a => {
                    return {
                        id: a.id,
                        name: this.mapName(a.baseData.name, a.identifiers)
                    } as IAssistant;
                });
            }
        );
    }

    @State.bound
    public tryGetPractitionerIdOfCurrentUserAsync(): Promise<SimpleStore<PractitionerId>> {
        return this.processOperationAsync(
            new SimpleStore<PractitionerId>(),
            async target => {
                const response = await this.apiClient.tryGetPractitionerIdOfCurrentUserQueryAsync(CreateRequestId());

                target.value = response.practitionerId;
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getCurrentUserInfoAsync(): Promise<UserInfoStore> {
        return this.processOperationAsync(
            new UserInfoStore(),
            async target => {
                const response = await this.apiClient.getCurrentUserInfoQueryAsync(CreateRequestId());

                target.displayName = response.displayName;
                target.practitionerId = response.practitionerId;
                target.identifiers = response.identifiers && response.identifiers.map(mapIdentifier);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getIdentifiersOfPractitionerAsync(practitionerId: PractitionerId, identifierSystemId?: IdentifierSystemId) {
        return this.processOperationAsync(
            new SimpleStore<PractitionerIdentifierStore[]>(),
            async target => {
                const response = await this.apiClient.getIdentifiersOfPractitionerQueryAsync(CreateRequestId(), new Proxy.GetIdentifiersOfPractitionerControllerDto({
                    practitionerId: practitionerId,
                    identifierSystemId: identifierSystemId
                }));

                target.value = response && response.identifiers && response.identifiers.map(mapIdentifier);

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getPractitionerByUserIdAsync(userId: UserId) {
        return this.processOperationAsync(
            new PractitionerStore(),
            async target => {
                const response = await this.apiClient.tryGetPractitionerByUserIdQueryAsync(CreateRequestId(), userId.value);
                if (response.practitionerDto) {
                    this.practitionerStoreMapper.applyToStore(target, response.practitionerDto);
                }
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getPractitionerByIdAsync(practitionerId: PractitionerId) {
        return this.processOperationAsync(
            new PractitionerStore(),
            async target => {
                const response = await this.apiClient.getPractitionersByIdsQueryAsync(CreateRequestId(), practitionerId.value);
                this.practitionerStoreMapper.applyToStore(target, response.practitioners[0]);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getPractitionersByIdsAsync(ids: PractitionerId[]): Promise<SimpleStore<PractitionerStore[]>> {
        return this.processOperationAsync(
            new SimpleStore<PractitionerStore[]>(),
            async target => {
                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value));
                const response = await this.apiClient.getPractitionersByIdsQueryAsync(CreateRequestId(),
                    buildQueryStringArray(normalizedIds));

                target.operationInfo = createOperationInfo(response);

                const mappedResults: PractitionerStore[] = [];

                for (const practitionerDto of response?.practitioners || []) {
                    const newStore = new PractitionerStore();
                    this.practitionerStoreMapper.applyToStore(newStore, practitionerDto);
                    mappedResults.push(newStore);
                }

                target.value = mappedResults;
            }
        );
    }

    @State.bound
    public getAllDoctorsAsync() {
        return this.processOperationAsync(
            new SimpleStore<PractitionerId[]>(),
            async target => {
                const response = await this.apiClient.getAllDoctorsQueryAsync(CreateRequestId());

                target.operationInfo = createOperationInfo(response);
                target.value = response.doctors;
            }
        );
    }

    @State.bound
    public getPractitionerIdsWithUserIdAsync() {
        return this.getPractitionerIdsAsync(false);
    }

    @State.bound
    public getPractitionerIdsAsync(isExternal: boolean) {
        return this.processOperationAsync(
            new SimpleStore<PractitionerId[]>(),
            async target => {
                const response = await this.apiClient.getPractitionerIdsQueryAsync(
                    CreateRequestId(),
                    isExternal.toString()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.ids;
            }
        );
    }

    @State.bound
    public validateAsync(practitioner: PractitionerStore, hasUser: boolean) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {

                let response = null;
                if (practitioner.isNew) {
                    const addDto = getAddPractitionerDto(practitioner, hasUser);
                    addDto.isValidateOnly = true;
                    response = await this.apiClient.addPractitionerCommandAsync(
                        CreateRequestId(),
                        addDto
                    );
                } else {
                    const dto = getUpdatePractitionerDto(practitioner, hasUser);
                    dto.isValidateOnly = true;
                    response = await this.apiClient.updatePractitionerCommandAsync(
                        CreateRequestId(),
                        dto
                    );
                }

                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public addPractitionerAsync(practitionerStore: PractitionerStore, hasUser: boolean) {
        return this.processOperationAsync(
            new PractitionerStore(),
            async target => {
                const dto = getAddPractitionerDto(practitionerStore, hasUser);

                const response = await this.apiClient.addPractitionerCommandAsync(CreateRequestId(), dto);

                this.practitionerStoreMapper.applyToStore(target, response.practitionerDto);
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.isNew = !response.isPersisted;
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public updatePractitionerAsync(practitionerStore: PractitionerStore, hasUser: boolean) {
        return this.processOperationAsync(
            new PractitionerStore(),
            async target => {
                const dto = getUpdatePractitionerDto(practitionerStore, hasUser);

                const response = await this.apiClient.updatePractitionerCommandAsync(CreateRequestId(), dto);
                this.practitionerStoreMapper.applyToStore(target, response.practitionerDto);
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getDoctorIdentifierSystemIdAsync() {
        return this.processOperationAsync(
            new SimpleStore<IdentifierSystemId>(),
            async target => {
                const result = await this.apiClient.getDoctorIdentifierSystemIdQueryAsync(CreateRequestId());
                target.value = result?.doctorIdentifierSystemId;
                target.operationInfo = createOperationInfo(result);
            }
        );
    }

    @State.bound
    public getPointOfCareIdsByPractitionerIdsAsync(practitionerIds: PractitionerId[]) {
        return this.processOperationAsync(
            new SimpleStore<PointOfCareId[]>(),
            async target => {
                const normalizedIds = _.uniq(practitionerIds.filter(id => !!id).map(id => id.value));
                const response = await this.apiClient.getOrganizationUnitAssignmentsByPractitionerIdsQueryAsync(
                    CreateRequestId(),
                    buildQueryStringArray(normalizedIds));

                target.operationInfo = createOperationInfo(response);
                target.value = response.organizationUnitAssignmentsDto.organizationUnitIds;
            }
        );
    }

    @State.bound
    public createDoctorAsync(code: string, name: string, isValidateOnly: boolean, isPermissionCheckOnly = false): Promise<SimpleStore<DoctorStore>> {
        return this.processOperationAsync(
            new SimpleStore<DoctorStore>(),
            async target => {
                const response = await this.apiClient.createDoctorCommandAsync(
                    CreateRequestId(),
                    new Proxy.CreateDoctorControllerDto({
                        code: code,
                        name: name,
                        isValidateOnly: isValidateOnly
                    }),
                    isPermissionCheckOnly
                );
                target.operationInfo = createOperationInfo(response);
                if (!isPermissionCheckOnly) {
                    const doctor = new DoctorStore();
                    doctor.id = response.doctor.id;
                    doctor.code = response.doctor.code;
                    doctor.name = mapNameStore(response.doctor.baseData.name);
                    doctor.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                    target.value = doctor;
                }
            }
        );
    }
}
