import UserId from "@Primitives/UserId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import UserGroupId from "@Primitives/UserGroupId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class User extends EntityStoreBase<UserId> implements IDirtyChecked {
    @State.observable public displayName: string = "";
    @State.observable public loginName: string = "";
    @State.observable public password: string = "";
    @State.observable public passwordAgain: string = "";
    @State.observable public isEnabled: boolean = false;
    @State.observable public isPasswordExpirable: boolean = false;
    @State.observable public isNewPassword: boolean = false;
    @State.observable.ref public userGroupIds: UserGroupId[] = [];

    constructor(id?: UserId,
                displayName?: string,
                loginName?: string,
                isEnabled?: boolean,
                isPasswordExpirable?: boolean,
                isNewPassword?: boolean,
                userGroupIds?: UserGroupId[],
                rowVersion?: RowVersion) {
        super();
        this.id = id;
        this.displayName = displayName;
        this.loginName = loginName;
        this.isEnabled = isEnabled;
        this.isPasswordExpirable = isPasswordExpirable;
        this.isNewPassword = isNewPassword;
        this.userGroupIds = userGroupIds;
        this.rowVersion = rowVersion;
    }

    @State.action.bound
    public setDisplayName(newValue: string) {
        this.displayName = newValue;
    }

    @State.action.bound
    public setLoginName(newValue: string) {
        this.loginName = newValue;
    }

    @State.action.bound
    public setPassword(newValue: string) {
        this.password = newValue;
    }

    @State.action.bound
    public setPasswordAgain(newValue: string) {
        this.passwordAgain = newValue;
    }

    @State.action.bound
    public setIsDisabled(newValue: boolean) {
        this.isEnabled = !newValue;
    }

    @State.action.bound
    public setIsPasswordNotExpiring(newValue: boolean) {
        this.isPasswordExpirable = !newValue;
    }

    @State.computed
    public get isPasswordEnabled() {
        return this.isNew || this.isNewPassword;
    }

    @State.computed
    public get isDisabled() {
        return !this.isEnabled;
    }

    @State.computed
    public get isPasswordNotExpiring() {
        return !this.isPasswordExpirable;
    }

    @State.action.bound
    public setIsNewPassword(newValue: boolean) {
        this.isNewPassword = newValue;

        if (this.isNewPassword) {
            this.password = "";
            this.passwordAgain = "";
        }
    }

    @State.action.bound
    public setUserGroupIds(newValue: UserGroupId[]) {
        this.userGroupIds = newValue;
    }

    @State.action.bound
    public copyUserGroupIds(newValue: UserGroupId[]) {
        this.userGroupIds = [].concat(_.union(this.userGroupIds, newValue));
    }

    private _dirtyChecker = new StoreDirtyChecker();

    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker",
        "isNew",
        "operationInfo",
        "validationResults",
        "displayName"
    ];

    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }

    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    }
}
