import IEntityStore from "@Toolkit/CommonWeb/Model/IEntityStore";
import StoreMapper from "./StoreMapper";
import IEntityDto from "./IEntityDto";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import { createOperationInfoWithValidationResult } from "./OperationInfo/OperationInfoHelper";
import { mapValidationResults as mapValidationResults, } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import ValidationProblemParameterMapperService from "./ValidationProblemParameterMapperService";
import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Di from "@Di";

// TResponse (the type of the response in which the entity is received) can be a joined type, but has to have an appropriate DtoSelector.
// By default (if neither TResponse or entityDtoSelector are specified), the response is assumed to be the entity dto.
@Di.injectable()
export default abstract class EntityStoreMapper<TEntityDto extends IEntityDto, TStore extends IEntityStore, TResponse = TEntityDto> extends StoreMapper<TStore> {
    protected abstract storeEntityIdType: new (value: string) => IStringEntityId;

    constructor(@Di.inject("ValidationProblemParameterMapperService") private validationProblemParameterMappingService: ValidationProblemParameterMapperService) { super(); }

    // Selects the entity Dto from the response type
    protected entityDtoSelector(response: TResponse): TEntityDto {
        return response as unknown as TEntityDto;
    }

    protected applyToEntityStore(target: TStore, response: TResponse) {
        (target as any).validationResults = !!response ? mapValidationResults((response as any).compositeValidationResult) : null;

        const entity = this.entityDtoSelector(response) as IEntityDto;
        if (entity) {
            if (isNullOrUndefined(target.id)) {
                target.id = entity.id ? new this.storeEntityIdType(entity.id.value + "") : null;
            }
            if (isNullOrUndefined(target.rowVersion)) {
                target.rowVersion = entity.rowVersion ? new RowVersion(entity.rowVersion.value) : null;
            }
        } else if (!target.validationResults) {
            console.warn("Entity dto could not be selected from response. Override EntityStoreMapper.entityDtoSelector.");
        }
    }

    protected applyOperationInfo(target: TStore, response: any): void {
        target.operationInfo = createOperationInfoWithValidationResult(
            response,
            target.hasValidationError,
            target.hasValidationWarning
        );
    }

    public applyToStore(target: TStore, response: any, isPermissionCheckOnly: boolean = false) {
        this.applyOperationInfo(target, response);
        if (isPermissionCheckOnly === false) {
            this.applyToStoreCore(target, response);
        }
        this.applyToEntityStore(target, response);
    }

    public async applyToStoreAndResolveValidationProblemsAsync(target: TStore, response: any, isPermissionCheckOnly: boolean = false) {
        this.applyToStore(target, response, isPermissionCheckOnly);
        this.collectValidationParameterReferenceData(target.validationResults);
        await this.loadCollectedValidationParameterReferenceDataAsync();
        this.resolveValidationProblemParameters(target);
    }

    protected abstract applyToStoreCore(target: TStore, response: any): void;

    protected vGetStoreIdentifier(): string {
        throw new Error("Not implemented!");
    }

    @State.bound
    protected collectValidationParameterReferenceData(validationResults: IClientValidationResult[]) {
        const problems = _.flatten(validationResults.map(i => i.problems));
        for (const problem of problems) {
            this.validationProblemParameterMappingService.collectValidationProblemParameterReferenceData(this.vGetStoreIdentifier(), problem);
        }
    }

    @State.bound
    protected async loadCollectedValidationParameterReferenceDataAsync() {
        await this.validationProblemParameterMappingService.loadCollectedValidationProblemParameterReferenceDataAsync(this.vGetStoreIdentifier());
    }

    @State.bound
    public resolveValidationProblemParameters(entity: IEntityStore) {
        for (const validationResult of entity.validationResults || []) {
            for (const validationProblem of validationResult.problems) {
                this.resolveValidationProblemParametersForItem(validationProblem);
            }
        }
    }

    @State.bound
    protected resolveValidationProblemParametersForItem(validationProblem: IClientValidationProblem) {
        this.validationProblemParameterMappingService.resolveValidationProblemParameters(this.vGetStoreIdentifier(), validationProblem);
    }
}