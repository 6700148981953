import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import IDiagnosisExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IDiagnosisExtensionPointProps";
import HunCareContagiousPatientReportApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ContagiousPatientReport/HunCareContagiousPatientReportApiAdapter";

@Di.injectable()
export default class DiagnosisExtensionComponentService implements IExtensionComponentService<IDiagnosisExtensionPointProps> {

    constructor(@Di.inject("HunCareContagiousPatientReportApiAdapter") private contagiousPatientReportApiAdapter: HunCareContagiousPatientReportApiAdapter) {
    }

    public async isVisibleAsync(props: IDiagnosisExtensionPointProps) {
        const isContagiousPatientReportNeeded =
            await this.contagiousPatientReportApiAdapter.isContagiousPatientReportNeededQueryAsync(
                props.patientId,
                props.careActivityId,
                props.diagnosisStore?.conditionVersionSelector?.id,
                props.diagnosisStore?.use,
                props.pointOfCareId);

        return isContagiousPatientReportNeeded;
    }

    public shouldRerunIsVisible(props: IDiagnosisExtensionPointProps, prevProps: IDiagnosisExtensionPointProps) {
        return !ValueWrapper.equals(props.diagnosisStore?.conditionVersionSelector?.id, prevProps?.diagnosisStore?.conditionVersionSelector?.id);
    }
}
