import React, { useMemo, useCallback, useState } from "react";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { IRowData } from "@CommonControls/DataGrid/ViewModel";
import { DataGridCell, ObserverDataGridCell } from "@CommonControls/DataGrid/DataGridCell";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Styles from "./DataGrid.less";
import _ from "@HisPlatform/Common/Lodash";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import { IRowCheckState, RowId, IRowBody, IRowIndicatorStyle } from "@CommonControls/DataGrid/IDataGridProps";
import DataGridCheckCell from "@CommonControls/DataGrid/DataGridCheckCell";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DataGridRowIndicatorCell from "@CommonControls/DataGrid/DataGridRowIndicatorCell";
import StopPropagationBoundary from "@CommonControls/StopPropagationBoundary";

export interface IDataGridRowProps {
    headers: IDataGridColumnProps[];
    row: IRowData;
    rowIndex: number;
    isSelected: boolean;
    isUnderEditing: boolean;
    colSpan: number;
    rowIndicator: IRowIndicatorStyle;
    hasRowIndicator: boolean;

    rowBody: string | ((row: any, rowId: string | number, rowIndex: number, isUnderEditing: boolean) => IRowBody);
    checkState?: IRowCheckState;
    onRenderRow: (props: React.HTMLProps<HTMLTableRowElement>, row: any, rowId: string | number, rowIndex: number, isUnderEditing: boolean) => React.ReactElement<React.HTMLAttributes<HTMLTableRowElement>>;
    onClick: (row: any, rowId: string | number, rowIndex: number, event: React.MouseEvent) => void;
    onRightClick: (row: any, rowId: RowId, rowIndex: number, event: React.MouseEvent) => void;
    onRowChecked?: (isChecked: boolean, row: any, rowId: RowId, rowIndex: number) => void;
    rowHeight?: string | number | ((row: any, rowId: RowId, rowIndex: number) => (string | number));
    rowBodyPadding?: React.ReactText;
    rowBodyHeight?: string | number;
    disableOnRowHover?: boolean;
    showSeparatorLines: boolean;
    hidePointerCursor?: boolean;
}


const DataGridRow: React.FC<IDataGridRowProps> = props => {

    const [isHovered, setHovered] = useState(false);

    const body: IRowBody = (() => {
        if (isNullOrUndefined(props.rowBody)) {
            return null;
        }

        if (typeof props.rowBody === "function") {
            return props.rowBody(props.row.data, props.row.id, props.rowIndex, props.isUnderEditing);
        }

        if (typeof props.rowBody === "string") {
            return _.get(props.row.data, props.rowBody);
        }

        throw new Error("Cannot get row's body.");

    })();

    const handleClick = useCallback((e: React.MouseEvent<HTMLTableRowElement>) => {
        e.persist();
        if (props.onClick) {
            props.onClick(props.row.data, props.row.id, props.rowIndex, e);
        }
    }, [props.row, props.rowIndex, props.onClick]);

    const handleRightClick = useCallback((e: React.MouseEvent<HTMLTableRowElement>) => {
        e.persist();
        if (props.onRightClick) {
            props.onRightClick(props.row.data, props.row.id, props.rowIndex, e);
        }
    }, [props.row, props.rowIndex, props.onRightClick]);

    const isAlternate = (props.rowIndex % 2) === 1;
    const isChecked = !!props.checkState && !props.checkState.isDisabled && props.checkState.isVisible && props.checkState.isChecked;

    const commonClassName = combineClasses(
        isAlternate && Styles.alternate,
        (props.isSelected || isChecked) && Styles.selected,
    );

    const height = useMemo(() => {
        if (typeof props.rowHeight === "function") {
            return props.rowHeight(props.row.data, props.row.id, props.rowIndex);
        }

        return props.rowHeight;
    }, [props.rowHeight]);

    const mouseEnter = useCallback(() => {
        setHovered(!props.disableOnRowHover);
    }, [isHovered, setHovered]);

    const mouseLeave = useCallback(() => {
        setHovered(false);
    }, [isHovered, setHovered]);

    const recordClassName = combineClasses(
        commonClassName,
        body && body.showCells && Styles.topRow,
        isHovered && Styles.hoveredRow,
        props.hasRowIndicator && props.rowIndicator?.rowBackgroundClassName,
        !props.showSeparatorLines && Styles.hideSeparatorLines,
        !props.hasRowIndicator && Styles.showSeparotorLines,
        props.hidePointerCursor && Styles.nonClickable,
        props.rowIndicator?.className
    );

    const bodyClassName = combineClasses(
        commonClassName,
        body && body.showCells && Styles.bottomRow,
        isHovered && Styles.hoveredRow,
        !props.showSeparatorLines && Styles.hideSeparatorLines,
        props.hidePointerCursor && Styles.nonClickable
    );

    const rowHeight = isNullOrUndefined(height) ? undefined : { height };
    const rowBackgroundColor = (props.hasRowIndicator && !isNullOrUndefined(props.rowIndicator?.rowBackgroundColor))
        ? { "--rowBackgroundColor": props.rowIndicator?.rowBackgroundColor } as React.CSSProperties
        : undefined;
    const rowBackgroundColorHover = (props.hasRowIndicator && !isNullOrUndefined(props.rowIndicator?.rowBackgroundColorHover))
        ? { "--rowBackgroundColorHover": props.rowIndicator?.rowBackgroundColorHover } as React.CSSProperties
        : undefined;
    const rowBackgroundColorSelected = (props.hasRowIndicator && !isNullOrUndefined(props.rowIndicator?.rowBackgroundColorSelected))
        ? { "--rowBackgroundColorSelected": props.rowIndicator?.rowBackgroundColorSelected } as React.CSSProperties
        : undefined;
    const rowBackgroundColorSelectedHover = (props.hasRowIndicator && !isNullOrUndefined(props.rowIndicator?.rowBackgroundColorSelectedHover))
        ? { "--rowBackgroundColorSelectedHover": props.rowIndicator?.rowBackgroundColorSelectedHover } as React.CSSProperties
        : undefined;

    return (
        <>
            {(!body || body.showCells) && props.onRenderRow({
                className: recordClassName,
                onClick: handleClick,
                onContextMenu: handleRightClick,
                style: { ...rowHeight, ...rowBackgroundColor, ...rowBackgroundColorHover, ...rowBackgroundColorSelected, ...rowBackgroundColorSelectedHover } as React.CSSProperties,
                onMouseEnter: mouseEnter,
                onMouseLeave: mouseLeave,
                children: (
                    <>
                        {props.hasRowIndicator && (
                            <DataGridRowIndicatorCell
                                rowData={props.row}
                                rowIndex={props.rowIndex}
                                rowIndicatorStyle={props.rowIndicator}
                            />
                        )}
                        {!isNullOrUndefined(props.checkState) && (
                            <DataGridCheckCell
                                row={props.row}
                                rowIndex={props.rowIndex}
                                onRowChecked={props.onRowChecked}
                                checkState={props.checkState}
                                isRowBody={false}
                            />
                        )}
                        {props.headers.map((column, columnIndex) => {
                            
                            const Cell = column.isObserver ? ObserverDataGridCell : DataGridCell;
                            const isFirstWithIndicator = props.hasRowIndicator && isNullOrUndefined(props.checkState) && columnIndex === 0;
                            return (
                                <Cell
                                    key={`${props.row.id}_${(isNullOrUndefined(column.id) ? columnIndex : column.id)}`}
                                    columnProps={column}
                                    row={props.row}
                                    rowIndex={props.rowIndex}
                                    isUnderEditing={props.isUnderEditing}
                                    hasLeftPadding={isFirstWithIndicator}
                                />
                            );
                        })}
                    </>
                )
            }, props.row.data, props.row.id, props.rowIndex, props.isUnderEditing)}
            {body && (
                <tr
                    className={bodyClassName}
                    onClick={handleClick}
                    onMouseEnter={mouseEnter}
                    onMouseLeave={mouseLeave}
                    onContextMenu={handleRightClick}
                    style={{ ...rowBackgroundColor, ...rowBackgroundColorHover, ...rowBackgroundColorSelected }}
                >
                    {props.hasRowIndicator && (
                        <DataGridRowIndicatorCell
                            rowData={props.row}
                            rowIndex={props.rowIndex}
                            rowIndicatorStyle={props.rowIndicator}
                        />
                    )}
                    {!isNullOrUndefined(props.checkState) && (
                        <DataGridCheckCell
                            row={props.row}
                            rowIndex={props.rowIndex}
                            onRowChecked={props.onRowChecked}
                            checkState={props.checkState}
                            isRowBody
                        />
                    )}
                    <td colSpan={props.colSpan} data-automation-id="__rowBody" style={{ height: props.rowBodyHeight, padding: props.rowBodyPadding }}>
                        <StopPropagationBoundary>
                            {body.content}
                        </StopPropagationBoundary>
                    </td>
                </tr>
            )}
        </>
    );
};

export interface ICheckableDataGridRowProps extends IDataGridRowProps {
    rowCheckState?: string | ((row: any, rowId: RowId, rowIndex: number) => IRowCheckState);
}


const CheckableDataGridRow: React.FC<ICheckableDataGridRowProps> = props => (
    <State.Observer>
        {() => {
            const checkState: IRowCheckState = (() => {
                if (isNullOrUndefined(props.rowCheckState)) {
                    return null;
                }

                return typeof props.rowCheckState === "function" ?
                    props.rowCheckState(props.row.data, props.row.id, props.rowIndex) :
                    _.get(props.row.data, props.rowCheckState);
            })();

            return <DataGridRow {...props} checkState={checkState} />;
        }}
    </State.Observer>
);

export {
    DataGridRow,
    CheckableDataGridRow
};
