import BoolProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/BoolProperty";
import NumericProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/NumericProperty";
import NumericRangeProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/NumericRangeProperty";
import StringProperty from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/StringProperty";
import * as Proxy from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import * as BffProxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import NumericRange from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/NumericRange";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import PropertyValueVersion from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyValueVersion";

type EditablePropertyType = Proxy.EditablePropertyDto | BffProxy.EditablePropertyDto;
type PropertyVersionType = Proxy.PropertyVersionDto | BffProxy.PropertyVersionDto;
type PropertyValueBaseType = Proxy.PropertyValueBase | BffProxy.PropertyValueBase;

export default function convertPropertyValue(propDto: EditablePropertyType) {

    const valueForType = propDto.effectiveValueVersions?.length > 0 ? propDto.effectiveValueVersions[0].value : propDto.defaultEffectiveValue;

    if (isNullOrUndefined(valueForType)) {
        return null;
    }

    if (valueForType instanceof Proxy.BoolPropertyValue || valueForType instanceof BffProxy.BoolPropertyValue) {
        return new BoolProperty(
            mapPropertyVersions<boolean>(propDto.versions),
            mapPropertyVersions<boolean>(propDto.effectiveValueVersions),
            propDto.isOverridable,
            propDto.isTemporal,
            propDto.name,
            propDto.propertyDefinitionId,
            propDto.originatingOrganizationUnitId,
            propDto.defaultValue && (propDto.defaultValue as Proxy.BoolPropertyValue).value,
            propDto.defaultEffectiveValue && (propDto.defaultEffectiveValue as Proxy.BoolPropertyValue).value,
            propDto.inputType
        );
    } else if (valueForType instanceof Proxy.NumericPropertyValue || valueForType instanceof BffProxy.NumericPropertyValue) {
        return new NumericProperty(
            mapPropertyVersions<number>(propDto.versions),
            mapPropertyVersions<number>(propDto.effectiveValueVersions),
            propDto.isOverridable,
            propDto.isTemporal,
            propDto.name,
            propDto.propertyDefinitionId,
            propDto.originatingOrganizationUnitId,
            propDto.defaultValue && (propDto.defaultValue as Proxy.NumericPropertyValue).value,
            propDto.defaultEffectiveValue && (propDto.defaultEffectiveValue as Proxy.NumericPropertyValue).value,
            propDto.inputType
        );
    } else if (valueForType instanceof Proxy.NumericRangePropertyValue || valueForType instanceof BffProxy.NumericRangePropertyValue) {
        return new NumericRangeProperty(
            mapPropertyVersions<NumericRange>(propDto.versions, value => convertToNumericRange((value as Proxy.NumericRangePropertyValue).value)),
            mapPropertyVersions<NumericRange>(propDto.effectiveValueVersions, value => convertToNumericRange((value as Proxy.NumericRangePropertyValue).value)),
            propDto.isOverridable,
            propDto.isTemporal,
            propDto.name,
            propDto.propertyDefinitionId,
            propDto.originatingOrganizationUnitId,
            propDto.defaultValue && convertToNumericRange((propDto.defaultValue as Proxy.NumericRangePropertyValue).value),
            propDto.defaultEffectiveValue && convertToNumericRange((propDto.defaultEffectiveValue as Proxy.NumericRangePropertyValue).value),
            propDto.inputType
        );
    } else if (valueForType instanceof Proxy.StringPropertyValue || valueForType instanceof BffProxy.StringPropertyValue) {
        return new StringProperty(
            mapPropertyVersions<string>(propDto.versions),
            mapPropertyVersions<string>(propDto.effectiveValueVersions),
            propDto.isOverridable,
            propDto.isTemporal,
            propDto.name,
            propDto.propertyDefinitionId,
            propDto.originatingOrganizationUnitId,
            propDto.defaultValue && (propDto.defaultValue as Proxy.StringPropertyValue).value,
            propDto.defaultEffectiveValue && (propDto.defaultEffectiveValue as Proxy.StringPropertyValue).value,
            propDto.inputType
        );
    } else {
        throw new Error(`Unsupported property type.`);
    }
}

export function mapPropertyVersions<T>(versions: PropertyVersionType[], valueConverter?: (value: PropertyValueBaseType) => T) {
    return versions.map(v => new PropertyValueVersion(valueConverter ? valueConverter(v.value) : (v.value as any).value, v.validFrom, v.validTo));
}

function convertToNumericRange(proxyValue: Proxy.NumericRange): NumericRange {
    return proxyValue ? new NumericRange(proxyValue.from, proxyValue.to) : new NumericRange(null, null);
}

export function convertToPropertyValueBase(value: any): any {
    if (typeof value === "boolean") {
        return new Proxy.BoolPropertyValue({value: value as boolean} as Proxy.IBoolPropertyValue);
    } else if (typeof value === "string") {
        return new Proxy.StringPropertyValue({value: value as string} as Proxy.IStringPropertyValue);
    } else if (typeof value === "number") {
        return new Proxy.NumericPropertyValue({value: value as number} as Proxy.INumericPropertyValue);
    } else if (value instanceof NumericRange) {
        return new Proxy.NumericRangePropertyValue({value: value as NumericRange} as Proxy.INumericRangePropertyValue);
    }

    return null;
}
