import React, { useCallback, useMemo } from "react";
import Styles from "./Pager.less";
import PagerButton from "@CommonControls/DataGrid/Paging/PagerButton";
import { IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import SelectBox from "@CommonControls/SelectBox";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ReadOnlyContext from "@Toolkit/ReactClient/Components/ReadOnlyContext";

interface IPagerDependencies {
    toolkitLocalizationService: IToolkitLocalizationService;
}

interface IPagerProps {
    _dependencies?: IPagerDependencies;
    pageCount: number;
    adjacentButtonsCount: number;
    pagingState: IPagingState;
    availablePageSizes: number[];

    onPagingStateChange?: (pagingState: IPagingState) => void;
}


const Pager: React.FC<IPagerProps> = props => {

    const adjacentCount = props.adjacentButtonsCount;
    const currentPage = props.pagingState.currentPage;

    const isPrevButtonEnabled = currentPage > 0;
    const isNextButtonEnabled = currentPage < props.pageCount - 1;

    const createAndAddButton = useCallback((buttonsArray: JSX.Element[], index: number) => {
        buttonsArray.push(
            <PagerButton
                key={index - 1}
                targetPage={index - 1}
                pagingState={props.pagingState}
                onPagingStateChange={props.onPagingStateChange}
            />
        );
    }, [props.pagingState, props.onPagingStateChange]);

    const setPageSize = useCallback((pageSize: number) => {
        if (props.onPagingStateChange) {
            props.onPagingStateChange({
                currentPage: 0,
                pageSize
            });
        }
    }, [props.pagingState]);

    const pageSizeOptions = useMemo(() => props.availablePageSizes.sort((a, b) => a - b).map(size => ({
        value: size,
        text: `${size}`
    } as ISelectBoxItem)), [props.availablePageSizes]);

    const pageButtons = useMemo(() => {

        const buttonsToRender: JSX.Element[] = [];
        const addedPages: number[] = [];

        const firstLeftAdjacent = currentPage + 1 - adjacentCount;
        const lastRightAdjacent = currentPage + 1 + adjacentCount;

        for (let i = 1; i <= props.pageCount; i++) {
            if (i === 1 || i === props.pageCount || i >= firstLeftAdjacent && i <= lastRightAdjacent) {
                createAndAddButton(buttonsToRender, i);
                addedPages.push(i);
            }
        }

        let indexModifier = 0;
        for (let i = 0; i < addedPages.length - 1; i++) {
            if (addedPages[i + 1] - addedPages[i] !== 1) {
                const emptySpaceIndex = i + 1 + indexModifier;
                buttonsToRender.splice(emptySpaceIndex, 0, <span key={"dots_" + indexModifier}>...</span>);
                indexModifier++;
            }
        }

        return buttonsToRender;
    }, [props.pagingState, currentPage, props.pageCount, createAndAddButton]);

    return (
        <ReadOnlyContext.Provider value={false}>
            <div className={Styles.rowFlex} data-automation-id="__pager">
                <div className={Styles.rowFlex} data-automation-id="__navigationButtonGroup">
                    <div>
                        <PagerButton
                            targetPage={0}
                            pagingState={props.pagingState}
                            iconName="angleDoubleLeft"
                            onPagingStateChange={props.onPagingStateChange}
                            automationId="__firstPageButton"
                            isDisabled={!isPrevButtonEnabled}
                        />
                        <PagerButton
                            targetPage={currentPage - 1}
                            pagingState={props.pagingState}
                            iconName="angleLeft"
                            onPagingStateChange={props.onPagingStateChange}
                            automationId="__prevPageButton"
                            isDisabled={!isPrevButtonEnabled}
                        />
                    </div>
                    <div>
                        {pageButtons}
                    </div>
                    <div>
                        <PagerButton
                            targetPage={currentPage + 1}
                            pagingState={props.pagingState}
                            iconName="angleRight"
                            onPagingStateChange={props.onPagingStateChange}
                            automationId="__nextPageButton"
                            isDisabled={!isNextButtonEnabled}
                        />
                        <PagerButton
                            targetPage={props.pageCount - 1}
                            pagingState={props.pagingState}
                            iconName="angleDoubleRight"
                            onPagingStateChange={props.onPagingStateChange}
                            automationId="__lastPageButton"
                            isDisabled={!isNextButtonEnabled}
                        />
                    </div>
                </div>
                <div className={Styles.rowFlex} data-automation-id="__pageSizeSelectBox">
                    <div className={Styles.pageSizeChooser}>
                        <SelectBox
                            size="compact"
                            items={pageSizeOptions}
                            value={props.pagingState.pageSize}
                            onChange={setPageSize}
                            clearable={false}
                            menuPlacement="top"
                            orderItems={false}
                            className={Styles.sizeSelect}
                            automationId="pageSizeSelectBox"
                        />
                    </div>
                    <div className={Styles.itemsLabel}>
                        {props._dependencies.toolkitLocalizationService.staticResources.dataGrid.itemsPerPage}
                    </div>
                </div>
            </div>
        </ReadOnlyContext.Provider >
    );
};

export default connect(
    Pager,
    new DependencyAdapter<IPagerProps, IPagerDependencies>(c => ({
        toolkitLocalizationService: c.resolve("IToolkitLocalizationService"),
    }))
);