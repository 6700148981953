import "./Tooltip.global.css";
import { cloneElement, useMemo, useRef, useState } from "react";
import {
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useRole,
  useDismiss,
  arrow
} from "@floating-ui/react-dom-interactions";
import { motion, AnimatePresence } from "framer-motion";
import React from "react";
import { mergeRefs } from "react-merge-refs";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export type Placement = "top"
                        | "top-start"
                        | "top-end"
                        | "right"
                        | "right-start"
                        | "right-end"
                        | "bottom"
                        | "bottom-start"
                        | "bottom-end"
                        | "left"
                        | "left-start"
                        | "left-end";

export type ContentAlignment = "left" | "center" | "right";

interface ITooltipProps {
    children: JSX.Element;
    content: string | HTMLElement;
    contentAlignment?: ContentAlignment;
    placement?: Placement;
    isSticky?: boolean;
    isDisabled?: boolean;
    isManual?: boolean;
}

export const Tooltip = (props: ITooltipProps) => {
    const [isOpen, setOpen] = useState(false);
    const arrowRef = useRef(null);

    const shouldDisplayTooltip = useMemo(
        () => props.isDisabled !== true && (isOpen || props.isSticky === true),
        [isOpen, props.isSticky, props.isDisabled]
    );

    const trySetOpen = (value: boolean) => {
        if (props.isManual !== true) {
            setOpen(value);
        }
    };

    const {
        x,
        y,
        reference,
        floating,
        strategy,
        context,
        placement,
        middlewareData: {
            arrow: { x: arrowX, y: arrowY } = {}
        }
    } = useFloating({
        placement: props.placement ?? "top",
        open: shouldDisplayTooltip,
        onOpenChange: trySetOpen,
        middleware: [
            offset(5),
            flip(),
            shift({ padding: 8 }),
            arrow({
                element: arrowRef
            }),
        ],
        whileElementsMounted: autoUpdate
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useHover(context, { delay: 500 }),
        useRole(context, { role: "tooltip" }),
        useDismiss(context)
    ]);

    const ref = useMemo(
        () => mergeRefs([reference, (props.children as any).ref]),
        [reference, props.children]
    );

    const staticSide = useMemo(
        () => ({
            top: 'bottom',
            right: 'left',
            bottom: 'top',
            left: 'right',
        }[placement.split('-')[0]]),
        [placement]
    );

    return (
        props.content
        ? <>
            {cloneElement(props.children, getReferenceProps({ ref, ...props.children.props }))}
            <AnimatePresence>
                {shouldDisplayTooltip && (
                    <motion.div
                        initial={{ opacity: 0, scale: 0.85 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: "spring", damping: 20, stiffness: 300 }}
                        {...getFloatingProps({
                            ref: floating,
                            className: "tooltip",
                            style: {
                                position: strategy,
                                top: y ?? 0,
                                left: x ?? 0,
                                textAlign: isNullOrUndefined(props.contentAlignment) ? "center" : props.contentAlignment
                            }
                        })}
                    >
                        <div
                            ref={arrowRef}
                            className="tooltip-arrow"
                            style={{
                                left: isNullOrUndefined(arrowX) ? "" : arrowX,
                                top: isNullOrUndefined(arrowY) ? "" : arrowY,
                                right: "",
                                bottom: "",
                                [staticSide]: "-4px"
                            }}>
                        </div>
                        {props.content}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
        : props.children
    );
};