import React, { useCallback } from "react";
import { IDataGridColumnChildProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import FinancedServiceWithQuantityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PerformedServices/FinancedServiceWithQuantityStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import Money from "@Toolkit/CommonWeb/Model/Money";
import { PatientServiceListItem } from "@HisPlatform/Packages/Patients/Components/BillableServicesPanel/PatientServiceList";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IPerformedServicesCostCellProps extends IDataGridColumnChildProps<any, any> {
    dataGetter: (row: any) => FinancedServiceWithQuantityStore[] | Money;
    useLocalizationService?: boolean;
}

const PerformedServicesCostCell: React.FC<IPerformedServicesCostCellProps> = props => {
    const { financeReferenceDataStore, localizationService } = useDependencies(c => ({
        financeReferenceDataStore: c.resolve<FinanceReferenceDataStore>("FinanceReferenceDataStore"),
        localizationService: c.resolve<ILocalizationService>("ILocalizationService"),
    }));

    const calculateMoneyValue = useCallback((financedServiceData: FinancedServiceWithQuantityStore[]) => {
        let amount = 0;
        financedServiceData.forEach(i => {
            const service = financeReferenceDataStore.financedServiceMap.get(i.financedServiceId);
    
            amount += (service.unitPrice.amount * i.quantity);
        });
    
        const services = financeReferenceDataStore.financedServiceMap.getAll(financedServiceData.map(i => i.financedServiceId));

        return new Money(amount, services[0]?.unitPrice?.currencyCode ?? null);
    }, []);

    let data = props.dataGetter(props.row);
    if (isNullOrUndefined(data)) {
        return <>n/a</>;
    }

    if (!(data instanceof Money)) {
        data = calculateMoneyValue(data);
    }

    if (data.amount === 0) return <>n/a</>;
    if (props.useLocalizationService === true) return <>{localizationService.localizeMoney(data)}</>;

    return <>{`${data.amount} ${data.currencyCode}`}</>;
};


export default PerformedServicesCostCell;
