import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { FinancedCareActivitiesCountDto, IReportingClient } from "@HisPlatform/BoundedContexts/Reporting/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import Di from "@Di";

@Di.injectable()
export default class CareActivitiesByInsurerOrganizationWidgetApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IReportingClient") private readonly reportingClient: IReportingClient,
        @Di.inject("StructureApiAdapter") private readonly structureApiAdapter: StructureApiAdapter
    ) {
        super();
    }

    public getNumberOfFinancedCareActivitiesByOrganizationIdsAsync(
        organizationUnitId: OrganizationUnitId,
        startDate: LocalDate,
        endDate: LocalDate) {
        return this.processOperationAsync(
            new SimpleStore<FinancedCareActivitiesCountDto[]>(),
            async target => {
                const getChildResponse = await this.structureApiAdapter.getChildrenByParentIdAsync(organizationUnitId);
                const children = getChildResponse.value;
                const hasChildren = children.length > 0;
                const response = await this.reportingClient.getNumberOfFinancedCareActivitiesByInsurerOrganizationsQueryAsync(
                    CreateRequestId(),
                    endDate.stringify(), hasChildren ? children.map(p => p.value).join(";") : organizationUnitId.value,
                    startDate.stringify());
                    
                target.operationInfo = createOperationInfo(response);
                target.value = response.dtos;
            }
        );
    }
}