import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import CountryApiAdapter from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/ApiAdapter/CommonReferenceData/CountryApiAdapter";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import CountryId from "@Primitives/CountryId.g";
import * as HisUi from "@HisPlatformControls";
import ICountry from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/ICountry";
import {IOrderingState, IPagingState} from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import StaticWebAppResources from "@StaticResources";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import {IUniversalCodeSelectorProps} from "@HisPlatformControls/UniversalCodeSelector";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface ICountrySelectorDependencies {
    commonReferenceDataDataStore: CommonReferenceDataDataStore;
    countryApiAdapter: CountryApiAdapter;
}

interface ICountrySelectorProps extends ICodeSelectorCommonProps<CountryId> {
    _dependencies?: ICountrySelectorDependencies;
    variant?: "country" | "citizenship";
}

@State.observer
class CountrySelector extends React.Component<ICountrySelectorProps> {

    public static defaultProps: Partial<ICountrySelectorProps> = {
        variant: "country",
        maxResultCount: 10
    };

    @State.bound
    private async getDisplayValueAsync(value: CountryId) {
        const item = await this.dependencies.commonReferenceDataDataStore.countryMap.getOrLoadAsync(value);
        if(isNullOrUndefined(item)) {
            return "";
        }
        return <><b>{item.isoAlpha3}</b>{` ${this.props.variant === "country" ? item.countryName : item.citizenshipName}`}</>;
    }

    @State.bound
    private async getTextValueAsync(value: CountryId) {
        const item = await this.dependencies.commonReferenceDataDataStore.countryMap.getOrLoadAsync(value);
        if(isNullOrUndefined(item)) {
            return "";
        }
        return `${item.isoAlpha3} ${this.props.variant === "country" ? item.countryName : item.citizenshipName}`;
    }

    @State.bound
    private async quickSearchAsync(text: string) {
        const result = await this.dependencies.countryApiAdapter.searchCountriesByTextAsync(
            text,
            this.props.maxResultCount,
            this.props.validOn
        );

        if (result.operationWasSuccessful) {
            await this.dependencies.commonReferenceDataDataStore.countryMap.ensureLoadedAsync(result.value);
            return result.value;
        }

        return null;
    }

    @State.bound
    private async complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: ICountry[]): Promise<IPagedItems<ICountry>> {
        let nameFilter = null;
        let citizenshipFilter = null;
        if (this.props.variant === "citizenship") {
            citizenshipFilter = filterText;
        } else {
            nameFilter = filterText;
        }
        const customOrdering = {
            columnId: "name",
            direction: "asc"
        } as IOrderingState;
        const results = await this.dependencies.countryApiAdapter.searchCountries(filterText, nameFilter, citizenshipFilter, LocalDate.today(), customOrdering, paging);

        return results.value;
    }

    @State.bound
    private onComplexSearchSingleSelect(item: ICountry) {
        this.props.onChange(item.id);
    }

    @State.bound
    private onComplexSearchMultiSelect(items: ICountry[]) {
        this.props.onChange(items.map(item => item.id));
    }

    public render() {

        const props = {
            ...this.props,
            quickSearchMinimumCharacters: 2,
            getDisplayValueAsync: this.getDisplayValueAsync,
            getTextValueAsync: this.getTextValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: true,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchModalTitle: StaticWebAppResources.CountrySelector.Title,
            codeGetter: "isoAlpha3",
            nameGetter: this.props.variant === "citizenship" ? "citizenshipName" : "countryName",
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect,
        } as IUniversalCodeSelectorProps<CountryId, ICountry>;

        return (
            <HisUi.UniversalCodeSelector
                {...props}
                automationId={this.props.automationId}
            />
        );
    }

    private get dependencies() {
        return this.props._dependencies;
    }
}

export default connect(
    CountrySelector,
    new DependencyAdapter<ICountrySelectorProps, ICountrySelectorDependencies>((container) => {
        return {
            commonReferenceDataDataStore: container.resolve("CommonReferenceDataDataStore"),
            countryApiAdapter: container.resolve("CountryApiAdapter"),
        };
    })
);
