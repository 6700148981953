import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import IActivityDescriptor from "@PluginInterface/UseCases/IActivityDescriptor";
import Di from "@Di";
import IActivityGroupDescriptor from "@PluginInterface/UseCases/IActivityGroupDescriptor";

@Di.injectable()
export default class ActivityRegistry implements IActivityRegistry {

    private readonly _map = new Map<string, IActivityDescriptor>();
    private readonly _groupMap = new Map<string, IActivityGroupDescriptor>();

    public register(descriptor: IActivityDescriptor): void {
        this._map.set(descriptor.reference, descriptor);
    }

    public registerAll(...descriptors: IActivityDescriptor[]): void {
        descriptors?.forEach(d => this.register(d));
    }
    
    public getByReference(reference: string): IActivityDescriptor {
        return this._map.get(reference);
    }

    public registerGroup(descriptor: IActivityGroupDescriptor): void {
        this._groupMap.set(descriptor.name, descriptor);
    }

    public registerAllGroups(...descriptors: IActivityGroupDescriptor[]): void {
        descriptors?.forEach(d => this.registerGroup(d));
    }

    public getGroupByName(name: string): IActivityGroupDescriptor {
        return this._groupMap.get(name);
    }

}