import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Styles from "./ExtendedEhiCovidStatusReportFilter.less";
import EhiCovidStatusReportFilterStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCovidStatusReportWorklistPanel/EhiCovidStatusReportFilterStore";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";

interface IExtendedEhiCovidStatusReportFilterProps {
    filterStore: EhiCovidStatusReportFilterStore;
    isVisible: boolean;
}


const ExtendedEhiCovidStatusReportFilter: React.FC<IExtendedEhiCovidStatusReportFilterProps> = (props) => (
    <>
        <div className={Styles.outerContainer}>
            <Ui.Flex>
                <Ui.Flex.Item xs={4} className={Styles.searchItem}>
                    <Ui.DateRangePicker
                        label={StaticHunEHealthInfrastructureCareResources.EhiCovidStatusReportWorklistPanel.Label.DateIntervalFilter}
                        value={props.filterStore && props.filterStore.dateInterval}
                        onChange={props.filterStore && props.filterStore.setDateInterval}
                        automationId="dateIntervalFilter"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={2} className={Styles.searchItem}>
                    <Ui.CheckBox
                        label={StaticHunEHealthInfrastructureCareResources.EhiCovidStatusReportWorklistPanel.Label.IncludeDeletedFilter}
                        value={props.filterStore && props.filterStore.includeDeleted}
                        onChange={props.filterStore && props.filterStore.setIncludeDeleted}
                        automationId="includeDeletedFilter"
                        displayMode={"switch"}
                        verticalAlign={"inlineInput"}
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        </div>
    </>
);

export default State.observer(ExtendedEhiCovidStatusReportFilter);
