import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import IMedicalConditionLastSynchronizationSubtitleExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IMedicalConditionLastSynchronizationSubtitleExtensionPointProps";
import IEhiMedicalConditionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/IEhiMedicalConditionApiAdapter";
import MedicalConditionType from "@Primitives/MedicalConditionType";
import EhiImmunizationApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiImmunizationApiAdapter";
import EhiAutonomyDisabilityStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiAutonomyDisabilityStatementApiAdapter";
import EhiPregnancyStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiPregnancyStatementApiAdapter";
import EhiMedicationStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiMedicationStatementApiAdapter";
import EhiCurrentConditionStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiCurrentConditionStatementApiAdapter";
import EhiFormerConditionStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiFormerConditionStatementApiAdapter";
import EhiDeviceUseStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiDeviceUseStatementApiAdapter";
import EhiProcedureStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiProcedureStatementApiAdapter";
import EhiMedicalConditionServiceFactory from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Services/EhiMedicalConditionServiceFactory";
import EhiRiskAssessmentApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiRiskAssessmentApiAdapter";
import EhiPatientAllergyIntoleranceApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiPatientAllergyIntoleranceAdapter";
import EhiTherapyRecommendationApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiTherapyRecommendationApiAdapter";
import EhiPatientBloodTypeApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiPatientBloodTypeApiAdapter";
import EhiMedicalAlertStatementApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/ApiAdapter/EhiMedicalAlertStatementApiAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import EhiMedicalConditionService from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/MedicalCondition/Services/EhiMedicalConditionService";
import EventHandler from "@Toolkit/ReactClient/Components/EventHandler/EventHandler";
import moment from "moment";
import Localized from "@CommonControls/Localized/Localized";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";

interface IEhiMedicalConditionLastSynchronizationSubtitleExtensionDependencies {
    ehiImmunizationApiAdapter: EhiImmunizationApiAdapter;
    ehiAutonomyDisabilityStatementApiAdapter: EhiAutonomyDisabilityStatementApiAdapter;
    ehiPregnancyStatementApiAdapter: EhiPregnancyStatementApiAdapter;
    ehiMedicationStatementApiAdapter: EhiMedicationStatementApiAdapter;
    ehiCurrentConditionStatementApiAdapter: EhiCurrentConditionStatementApiAdapter;
    ehiFormerConditionStatementApiAdapter: EhiFormerConditionStatementApiAdapter;
    ehiDeviceUseStatementApiAdapter: EhiDeviceUseStatementApiAdapter;
    ehiProcedureStatementApiAdapter: EhiProcedureStatementApiAdapter;
    ehiMedicalConditionServiceFactory: EhiMedicalConditionServiceFactory;
    ehiRiskAssessmentApiAdapter: EhiRiskAssessmentApiAdapter;
    ehiPatientAllergyIntoleranceApiAdapter: EhiPatientAllergyIntoleranceApiAdapter;
    ehiTherapyRecommendationApiAdapter: EhiTherapyRecommendationApiAdapter;
    ehiPatientBloodTypeApiAdapter: EhiPatientBloodTypeApiAdapter;
    ehiMedicalAlertStatementApiAdapter: EhiMedicalAlertStatementApiAdapter;
}


interface IEhiMedicalConditionLastSynchronizationSubtitleExtensionProps extends IMedicalConditionLastSynchronizationSubtitleExtensionPointProps {
    _dependencies?: IEhiMedicalConditionLastSynchronizationSubtitleExtensionDependencies;
}

@State.observer
class EhiMedicalConditionLastSynchronizationSubtitleExtension extends React.Component<IEhiMedicalConditionLastSynchronizationSubtitleExtensionProps> {

    @State.observable.ref private lastSynchronizationDate: moment.Moment = null;

    private get medicalConditionType() { return this.props.medicalConditionType; }
    private get patientId() { return this.props.patientId; }

    private ehiMedicalConditionService: EhiMedicalConditionService = null;

    private get currentApiAdapter(): IEhiMedicalConditionApiAdapter {
        if (this.medicalConditionType === MedicalConditionType.Immunization) {
            return this.props._dependencies.ehiImmunizationApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.AutonomyDisabilityStatement) {
            return this.props._dependencies.ehiAutonomyDisabilityStatementApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.PregnancyStatement) {
            return this.props._dependencies.ehiPregnancyStatementApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.MedicationStatement) {
            return this.props._dependencies.ehiMedicationStatementApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.CurrentConditionStatement) {
            return this.props._dependencies.ehiCurrentConditionStatementApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.FormerConditionStatement) {
            return this.props._dependencies.ehiFormerConditionStatementApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.DeviceUseStatement) {
            return this.props._dependencies.ehiDeviceUseStatementApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.ProcedureStatement) {
            return this.props._dependencies.ehiProcedureStatementApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.RiskAssessment) {
            return this.props._dependencies.ehiRiskAssessmentApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.PatientAllergyIntolerance) {
            return this.props._dependencies.ehiPatientAllergyIntoleranceApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.TherapyRecommendation) {
            return this.props._dependencies.ehiTherapyRecommendationApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.PatientBloodType) {
            return this.props._dependencies.ehiPatientBloodTypeApiAdapter;
        }
        if (this.medicalConditionType === MedicalConditionType.MedicalAlertStatement) {
            return this.props._dependencies.ehiMedicalAlertStatementApiAdapter;
        }

        return null;
    }

    @State.bound
    private async setLastSynchronizationDateAsync() {
        const dateStore = await this.ehiMedicalConditionService.getLastSynchronizationDateAsync(this.patientId);
        State.runInAction(() => {
            this.lastSynchronizationDate = dateStore.value;
        });
    }

    public componentDidMount() {
        this.ehiMedicalConditionService = this.props._dependencies.ehiMedicalConditionServiceFactory.getEhiMedicalConditionService(this.currentApiAdapter);
        dispatchAsyncErrors(this.setLastSynchronizationDateAsync(), this);
    }

    public render() {
        return (
            <>
                {StaticHunEHealthInfrastructureCareResources.MedicalCondition.Label.LastSynchronizationDate}: <Localized.DateTime value={this.lastSynchronizationDate} />
                <EventHandler event={this.props.medicalConditionSynchronizationExtensionEvent} onFiredAsync={this.setLastSynchronizationDateAsync} />
            </>
        );
    }
}

export default connect(
    EhiMedicalConditionLastSynchronizationSubtitleExtension,
    new DependencyAdapter<IEhiMedicalConditionLastSynchronizationSubtitleExtensionProps, IEhiMedicalConditionLastSynchronizationSubtitleExtensionDependencies>(c => ({
        ehiImmunizationApiAdapter: c.resolve<EhiImmunizationApiAdapter>("EhiImmunizationApiAdapter"),
        ehiAutonomyDisabilityStatementApiAdapter: c.resolve<EhiAutonomyDisabilityStatementApiAdapter>("EhiAutonomyDisabilityStatementApiAdapter"),
        ehiPregnancyStatementApiAdapter: c.resolve<EhiPregnancyStatementApiAdapter>("EhiPregnancyStatementApiAdapter"),
        ehiMedicationStatementApiAdapter: c.resolve<EhiMedicationStatementApiAdapter>("EhiMedicationStatementApiAdapter"),
        ehiCurrentConditionStatementApiAdapter: c.resolve<EhiCurrentConditionStatementApiAdapter>("EhiCurrentConditionStatementApiAdapter"),
        ehiFormerConditionStatementApiAdapter: c.resolve<EhiFormerConditionStatementApiAdapter>("EhiFormerConditionStatementApiAdapter"),
        ehiDeviceUseStatementApiAdapter: c.resolve<EhiDeviceUseStatementApiAdapter>("EhiDeviceUseStatementApiAdapter"),
        ehiProcedureStatementApiAdapter: c.resolve<EhiProcedureStatementApiAdapter>("EhiProcedureStatementApiAdapter"),
        ehiMedicalConditionServiceFactory: c.resolve<EhiMedicalConditionServiceFactory>("EhiMedicalConditionServiceFactory"),
        ehiRiskAssessmentApiAdapter: c.resolve<EhiRiskAssessmentApiAdapter>("EhiRiskAssessmentApiAdapter"),
        ehiPatientAllergyIntoleranceApiAdapter: c.resolve<EhiPatientAllergyIntoleranceApiAdapter>("EhiPatientAllergyIntoleranceApiAdapter"),
        ehiTherapyRecommendationApiAdapter: c.resolve<EhiTherapyRecommendationApiAdapter>("EhiTherapyRecommendationApiAdapter"),
        ehiPatientBloodTypeApiAdapter: c.resolve<EhiPatientBloodTypeApiAdapter>("EhiPatientBloodTypeApiAdapter"),
        ehiMedicalAlertStatementApiAdapter: c.resolve<EhiMedicalAlertStatementApiAdapter>("EhiMedicalAlertStatementApiAdapter")
    }))
);