import MedicationId from "@Primitives/MedicationId.g";
import Identifier from "@Toolkit/CommonWeb/Model/Identifier";
import MedicationTypeId from "@Primitives/MedicationTypeId.g";
import MedicationFormId from "@Primitives/MedicationFormId.g";
import SubstanceId from "@Primitives/SubstanceId.g";
import ISubstanceVersion from "./ISubstanceVersion";
import DosageModeId from "@Primitives/DosageModeId.g";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import PharmacyTypeId from "@Primitives/PharmacyTypeId.g";

export default class MedicationVersion {
    constructor(
        public id: MedicationId,
        public name: string,
        public efficacy: string,
        public manufacturerName: string,
        public amountInPackage: number,
        public identifiers: Identifier[],
        public isDeleted: boolean,
        public medicationTypeId: MedicationTypeId,
        public medicationFormId: MedicationFormId,
        public substanceIds: SubstanceId[],
        public substances: ISubstanceVersion[],
        public textAmount: string,
        public dosageModeId: DosageModeId,
        public dosageAmount: number,
        public registryIdentifier: string,
        public substanceAmount: number,
        public daysSuppliedCanBeCalculated: boolean,
        public validity: LocalDateRange,
        public pharmacyTypeId: PharmacyTypeId,
        public uniqueSizing: boolean
    ) { }

    public get isoCode() {
        return this.identifiers?.find(i => i.identifierSystemId.value === "ISO")?.value;
    }

    public get isLint() { // lint: géz, kötszer
        return this.isoCode?.startsWith("02");
    }
}
