import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import InputView from "./InputView";
import ResultView from "./ResultView";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";

/** @screen */
@State.observer
export default class BMICalculator extends React.Component {

    @State.observable.ref private weight: number = null;
    @State.observable.ref private height: number = null;
   
    @State.observable.ref private view: "input" | "result" = "input";

    @State.computed private get computeIsEnabled() {
        if (this.weight >= 10 && this.weight <= 250 && this.height >= 1 && this.height <= 2.5) {
            return !isNullOrUndefined(this.weight) && !isNullOrUndefined(this.height);
        }
        return false;
    }

    @State.computed private get result() {
        const value = this.weight / (this.height * this.height);
        const rounded = Math.round(value * 10) / 10;
        return rounded;
    }

    private resources = StaticProductivityResources.Widgets.BMICalculator;

    private bmiMap = new Map([
        [0, this.resources.Categories.VerySeverelyUnderweight],
        [16, this.resources.Categories.SeverelyUnderweight],
        [17, this.resources.Categories.Underweight],
        [18.5, this.resources.Categories.Normal],
        [25, this.resources.Categories.Overweight],
        [30, this.resources.Categories.ObeseClassI],
        [35, this.resources.Categories.ObeseClassII],
        [40, this.resources.Categories.ObeseClassIII],
        [45, this.resources.Categories.ObeseClassIV],
        [50, this.resources.Categories.ObeseClassV],
        [60, this.resources.Categories.ObeseClassVI],
    ]);

    @State.computed private get category() {

        let res: string = this.bmiMap.get(0);

        for (const key of this.bmiMap.keys()) {
            if (key > this.result) {
                return res;
            } else {
                res = this.bmiMap.get(key);
            }
        }

        return res;
    }

    @State.action.bound
    private setWeight(weight: number) {
        this.weight = weight;
    }

    @State.action.bound
    private setHeight(height: number) {
        this.height = height;
    }

    @State.action.bound
    private showResult() {
        this.view = "result";
    }

    @State.action.bound
    private clear() {
        this.weight = null;
        this.height = null;
        this.view = "input";
    }

    public render() {
        switch (this.view) {
            case "input":
                return (
                    <InputView
                        computeIsEnabled={this.computeIsEnabled}
                        height={this.height}
                        setHeight={this.setHeight}
                        setWeight={this.setWeight}
                        showResult={this.showResult}
                        weight={this.weight}
                    />
                );
            case "result":
                return (
                    <ResultView
                        category={this.category}
                        result={this.result}
                        reset={this.clear}
                    />
                );
        }
    }
}
