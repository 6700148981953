import * as React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ICodeSelectorCommonProps from "@HisPlatformControls/UniversalCodeSelector/ICodeSelectorCommonProps";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import UserId from "@Primitives/UserId.g";
import UserManagementDataProviderStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/UserManagementDataProviderStore";
import {isNullOrUndefined} from "@Toolkit/CommonWeb/NullCheckHelpers";
import * as HisUi from "@HisPlatformControls";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";

interface IUserCodeSelectorDependencies {
    userManagementDataProviderStore: UserManagementDataProviderStore;
    localizationService: ILocalizationService;
}

interface IUserCodeSelectorProps extends ICodeSelectorCommonProps<UserId> {
    _dependencies?: IUserCodeSelectorDependencies;
}

@State.observer
class UserCodeSelector extends React.Component<IUserCodeSelectorProps> {

    private get userManagementDataProviderStore() {
        return this.props._dependencies.userManagementDataProviderStore;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.props._dependencies.userManagementDataProviderStore.users.ensureAllLoadedAsync(), this);
    }

    @State.bound
    private getDisplayValueAsync(value: UserId) {
        if (!isNullOrUndefined(value)) {
            const user = this.userManagementDataProviderStore.users.get(value);
            if (!isNullOrUndefined(user)) {
                return Promise.resolve(user.displayName);
            }
        }

        return Promise.resolve("n/a");
    }

    @State.bound
    private onQuickSearchAsync(text: string) {
        const searchText = text.trim().toUpperCase();
        const items =  this.userManagementDataProviderStore.users.items.filter(item => item.displayName.toUpperCase().includes(searchText));
        return Promise.resolve(items.map(i => i.id));
    }

    public render() {
        return (
            <HisUi.UniversalCodeSelector
                {...this.props}
                getDisplayValueAsync={this.getDisplayValueAsync}
                onQuickSearchAsync={this.onQuickSearchAsync}
                complexSearchModalSize="normal"
            />
        );
    }
}

export default connect(
    UserCodeSelector,
    new DependencyAdapter<IUserCodeSelectorProps, IUserCodeSelectorDependencies>((container) => ({
        localizationService: container.resolve("ILocalizationService"),
        userManagementDataProviderStore: container.resolve("UserManagementDataProviderStore"),
    }))
);
