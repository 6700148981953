exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SearchBox_enter_3PFjJ {\n  opacity: 0.1;\n  width: 0px;\n}\n.SearchBox_enter-active_3gyLE {\n  opacity: 1;\n  width: 180px;\n  transition: 300ms;\n}\n.SearchBox_exit_2i6ib {\n  opacity: 1;\n  width: 180px;\n}\n.SearchBox_exit-active_RY6Hn {\n  opacity: 0.1;\n  width: 0px;\n  transition: 300ms;\n}\n.SearchBox_searchboxContainer_3asoj svg:hover {\n  fill: white !important;\n}\n", ""]);

// exports
exports.locals = {
	"enter": "SearchBox_enter_3PFjJ",
	"enter-active": "SearchBox_enter-active_3gyLE",
	"enterActive": "SearchBox_enter-active_3gyLE",
	"exit": "SearchBox_exit_2i6ib",
	"exit-active": "SearchBox_exit-active_RY6Hn",
	"exitActive": "SearchBox_exit-active_RY6Hn",
	"searchboxContainer": "SearchBox_searchboxContainer_3asoj"
};