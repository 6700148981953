import StoreMapper from "@Toolkit/CommonWeb/ApiAdapter/StoreMapper";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import ServiceRequestStoreMapper from "./ServiceRequestStoreMapper";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import ServiceRequestsListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestsListStore";
import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";

type Response = Proxy.GetServiceRequestsForStarterCareActivityQueryResponse | Proxy.GetServiceRequestsForPatientQueryResponse;

@Di.injectable()
export default class ServiceRequestsListStoreMapper extends StoreMapper<ServiceRequestsListStore, Response> {

    constructor(
        @Di.inject("ServiceRequestStoreMapper") private serviceRequestStoreMapper: ServiceRequestStoreMapper
    ) {
        super();
    }

    protected applyToStoreCore(target: ServiceRequestsListStore, response: Response) {
        target.items = response.serviceRequests.map(this.mapItem);
    }

    @State.action.bound
    private mapItem(dto: Proxy.ServiceRequestDto) {
        const itemMapper = this.serviceRequestStoreMapper;
        const newItem = new ServiceRequestStore();
        itemMapper.applyToStore(newItem, dto);
        return newItem;
    }
}
