import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import DocumentTypesApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/ReferenceData/DocumentTypesApiAdapter";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import { emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import SelectBox from "@CommonControls/SelectBox";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";

interface IDocumentTypeSelectBoxDependencies {
    documentTypesApiAdapter: DocumentTypesApiAdapter;
}

interface IDocumentTypeSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: IDocumentTypeSelectBoxDependencies;
    _pointOfCareId?: PointOfCareId;

    value: DocumentTypeId | DocumentTypeId[];
    onChange: (value: DocumentTypeId | DocumentTypeId[]) => void;
}

@State.observer
class DocumentTypeSelectBox extends React.Component<IDocumentTypeSelectBoxProps> {

    @State.computed private get documentTypesApiAdapter() { return this.props._dependencies!.documentTypesApiAdapter; }
    @State.computed.valueWrapper private get pointOfCareId() { return this.props._pointOfCareId; }

    private getTypesAsync = State.promisedComputed(emptyArray, async () => {
        const results = !this.pointOfCareId ?
                        await this.documentTypesApiAdapter.getAllDocumentTypesAsync() :
                        await this.documentTypesApiAdapter.getAvailableDocumentTypesAsync(this.pointOfCareId);
        return results.value.map(i => ({ value: i.id, text: i.name } as ISelectBoxItem<DocumentTypeId>));
    });

    public render() {
        const { _dependencies, _pointOfCareId, ...rest } = this.props;
        return (
            <SelectBox
                {...rest}
                items={this.getTypesAsync.get()}
                loading={this.getTypesAsync.busy}
            />
        );
    }
}

export default connect(
    DocumentTypeSelectBox,
    new CareActivityContextAdapter<IDocumentTypeSelectBoxProps>(c => ({ _pointOfCareId: c.careActivity?.pointOfCareId })),
    new DependencyAdapter<IDocumentTypeSelectBoxProps, IDocumentTypeSelectBoxDependencies>(c => ({
        documentTypesApiAdapter: c.resolve("DocumentTypesApiAdapter"),
    }))
);