import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import coverageCheckListStandaloneRouteDefinitions from "@HunEHealthInfrastructurePlugin/UseCases/CoverageCheckListUseCase/CoverageCheckListRoutes";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";

interface IHunSocialSettingsMenuDependencies {
    careApiAdapter: EhiCareApiAdapter;
    globalRoutingStore: GlobalRoutingStore;
}

interface IHunSocialSettingsMenuProps {
    _dependencies?: IHunSocialSettingsMenuDependencies;
}

@State.observer
class CoverageEligibilitySettingsMenu extends React.Component<IHunSocialSettingsMenuProps> {

    private get careApiAdapter() { return this.props._dependencies.careApiAdapter; }
    private get globalRoutingStore() { return this.props._dependencies.globalRoutingStore; }

    @State.bound
    private navigateToFactory<TParams>(route: RouteDefinition<TParams>, params: TParams = null, queryParameters?: object) {
        return () => {
            this.globalRoutingStore.push(route.makeRoute(params, queryParameters));
        };
    }

    private get permissionCheckedOperations(): any {
        const res = {};

        res["GetCoverageEligibilityCheckResults"] = async () => {
            await this.careApiAdapter.getCoverageEligibilityCheckResultsPermissionCheckAsync();
        };

        return res;
    }
    public render() {
        return (
            <PermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                <Ui.NavBar.Item
                    onClick={this.navigateToFactory(coverageCheckListStandaloneRouteDefinitions.coverageCheckList)}
                    permissionCheckOperationNames="GetCoverageEligibilityCheckResults"
                    permissionDeniedStyle="disabled"
                    automationId="coverageCheckList" >
                    {StaticHunEHealthInfrastructureCareResources.CareRegister.CoverageCheckListPanel.NavBarTitle}
                </Ui.NavBar.Item>
            </PermissionCheckContextProvider>
        );
    }
}

export default connect(
    CoverageEligibilitySettingsMenu,
    new DependencyAdapter<IHunSocialSettingsMenuProps, IHunSocialSettingsMenuDependencies>(c => ({
        careApiAdapter: c.resolve("EhiCareApiAdapter"),
        globalRoutingStore: c.resolve("GlobalRoutingStore")
    }))
);