import Button, { ISimpleButtonProps } from "@Toolkit/ReactClient/Components/CommonControls/Button";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ValidationBoundaryAdapter from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryAdapter";
import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

interface IValidationControlledButtonProps extends ISimpleButtonProps {
    _validationBoundary?: IValidationBoundaryStore;
    validationResults?: IClientValidationProblem[];
    propertyPath?: string;
    excludedRuleIds?: string;
    disableOnSeverity: "error" | "warning";
    isLoading?: boolean;
}

@State.observer
class ValidationControlledButton extends React.Component<IValidationControlledButtonProps> {
    @State.computed private get results() {
        let results = this.props.validationResults ?? this.props._validationBoundary?.getValidationProblems(this.props.propertyPath, false, true);

        if (this.props.excludedRuleIds) {
            const excludedRuleIdsArray = this.props.excludedRuleIds.split(",");
            results = results?.filter(item => !excludedRuleIdsArray.includes(item.ruleId));
        }

        return results;
    }

    @State.computed private get errorCount() {
        return this.results?.filter((r: any) => r.severity === "error")?.length || 0;
    }

    @State.computed private get warningCount() {
        return this.results?.filter((r: any) => r.severity === "warning")?.length || 0;
    }

    @State.computed private get hasError() {
        return this.errorCount > 0;
    }

    @State.computed private get hasWarning() {
        return this.warningCount > 0;
    }

    @State.computed private get isDisabled() {
        return !!this.props.isLoading || !!isNullOrUndefined(this.results) || (this.props.disableOnSeverity === "error" ? this.hasError : (this.hasError || this.hasWarning));
    }

    public render() {
        return <Button {...this.props} disabled={this.isDisabled}/>;
    }
}

export default connect(
    ValidationControlledButton,
    new ValidationBoundaryAdapter()
);
