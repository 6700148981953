import IPackageInitializer from "@HisPlatform/Common/Packages/IPackageInitializer";
import DependencyContainer, { Container } from "@DiContainer";
import { PatientAgeRegistryItem } from "@HisPlatform/Packages/Patients/Components/PatientAge/PatientAgeFormCustomBlock";
import { PatientBirthPlaceRegistryItem } from "@HisPlatform/Packages/Patients/Components/PatientBirthPlace/PatientBirthPlaceFormCustomBlock";
import { PatientInsurancePlanRegistryItem } from "@HisPlatform/Packages/Patients/Components/PatientInsurancePlan/PatientInsurancePlanFormCustomBlock";
import { ZipCodeAndSettlementRegistryItem } from "@HisPlatform/Packages/Patients/Components/ZipCodeAndSettlement/ZipCodeAndSettlementFormCustomBlock";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import GlobalActionRegistry from "@Toolkit/ReactClient/ActionProcessing/GlobalActionRegistry";
import IScreenRegistry from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import ShowCombinePatientsScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowCombinePatientsScreenAction.g";
import CombinePatientsScreen from "@HisPlatform/Packages/Patients/Screens/CombinePatientsScreen/CombinePatientsScreen";
import CombinePatientsAction from "@HisPlatform/Packages/Patients/FrontendActions/CombinePatientsAction.g";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { configureFrontendListMappings } from "@HisPlatform/BoundedContexts/WebAppBackend/FrontendListMapper";
import PatientMainMenuSideBar from "@HisPlatform/Components/Pages/Patient/PatientMainPage/View/PatientMainMenuSideBar";
import { configureFrontendActionMappings } from "@HisPlatform/Packages/Patients/FrontendActions/MapperConfigurations.g";
import ShowCreateNewImmunizationScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowCreateNewImmunizationScreenAction.g";
import ShowImmunizationListScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowImmunizationListScreenAction.g";
import ShowImmunizationScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowImmunizationScreenAction.g";
import ImmunizationListScreen from "@HisPlatform/Packages/Patients/Screens/ImmunizationListScreen/ImmunizationListScreen";
import ImmunizationScreen from "@HisPlatform/Packages/Patients/Screens/ImmunizationScreen/ImmunizationScreen";
import PatientScreenContextData from "@HisPlatform/Services/Definition/ActionProcessing/PatientScreenContextData";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import DownloadPatientRelatedDocumentAction from "@HisPlatform/Packages/Patients/FrontendActions/DownloadPatientRelatedDocumentAction.g";
import { downloadPatientRelatedDocumentActionProcessorFactory } from "@HisPlatform/Packages/Patients/FrontendActions/DownloadPatientRelatedDocumentActionProcessor";
import ShowCreateNewPatientRelatedDocumentScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowCreateNewPatientRelatedDocumentScreenAction.g";
import ShowPatientRelatedDocumentListScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientRelatedDocumentListScreenAction.g";
import ShowPatientRelatedDocumentScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientRelatedDocumentScreenAction.g";
import DocumentListScreen from "@HisPlatform/Packages/Patients/Screens/DocumentListScreen/DocumentListScreen";
import DocumentScreen from "@HisPlatform/Packages/Patients/Screens/DocumentScreen/DocumentScreen";
import IDynamicNavigationRegistry from "@HisPlatform/Services/Definition/DynamicNavigationRegistry/IDynamicNavigationRegistry";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import React from "react";
import UserColumn from "@HisPlatform/Components/HisPlatformControls/DataGridColumns/UserColumn";
import DocumentStateColumn from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Controls/Columns/DocumentStateColumn/DocumentStateColumn";
import DocumentKindColumn from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Controls/Columns/DocumentKindColumn/DocumentKindColumn";
import { getNavigationMenuItems } from "@HisPlatform/Packages/Patients/Configuration/NavigationMenuItems";
import IFormLogicRegistry from "@HisPlatform/Services/Definition/FormLogicRegistry/IFormLogicRegistry";
import PatientAdministrativeDataScreenFormLogic from "@HisPlatform/Packages/Patients/FormLogics/PatientAdministrativeDataScreenFormLogic";
import ShowNewPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowNewPatientScreenAction.g";
import PatientAdministrativeDataScreen from "@HisPlatform/Packages/Patients/Screens/PatientAdministrativeDataScreen/PatientAdministrativeDataScreen";
import ShowPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientScreenAction.g";
import { PatientValidityRegistryItem } from "@HisPlatform/Packages/Patients/Components/PatientValidity/PatientValidityFormCustomBlock";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import { PatientInfoBoxRegistryItem } from "@HisPlatform/Packages/Patients/Components/PatientInfoBox/PatientInfoBox";
import ShowPatientInvoiceListScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientInvoiceListScreenAction.g";
import { InvoiceListScreen } from "@HisPlatform/Packages/Patients/Screens/InvoiceListScreen/InvoiceListScreen";
import ShowInvoiceScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowInvoiceScreenAction.g";
import InvoiceScreen from "@HisPlatform/Packages/Patients/Screens/InvoiceScreen/InvoiceScreen";
import InvoiceTypeIdColumn from "@HisPlatform/BoundedContexts/Finance/Components/Controls/Columns/InvoiceTypeIdColumn/InvoiceTypeIdColumn";
import InvoiceStateColumn from "@HisPlatform/BoundedContexts/Finance/Components/Controls/Columns/InvoiceStateColumn/InvoiceStateColumn";
import ShowCreateNewInvoiceScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowCreateNewInvoiceScreenAction.g";
import InvoiceScreenFormLogic from "@HisPlatform/Packages/Patients/FormLogics/InvoiceScreenFormLogic";
import VendorId from "@Primitives/VendorId.g";
import InvoiceTypeId from "@Primitives/InvoiceTypeId.g";
import InvoiceTypeSelector from "@HisPlatform/Packages/Patients/Components/InvoiceTypeSelector/InvoiceTypeSelector";
import FinanceReferenceDataStore from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/FinanceReferenceDataStore";
import VatRateSelectBox from "@HisPlatform/BoundedContexts/Finance/Components/Controls/VatRateSelectBox/VatRateSelectBox";
import VendorSelector from "@HisPlatform/Packages/Patients/Components/VendorSelector/VendorSelector";
import VatRateId from "@Primitives/VatRateId.g";
import { VendorBankAccountFormCustomBlockRegistryItem } from "@HisPlatform/Packages/Patients/Components/VendorBankAccountFormCustomBlock/VendorBankAccountFormCustomBlock";
import PaymentTypeSelector from "@HisPlatform/Packages/Patients/Components/PaymentTypeSelector/PaymentTypeSelector";
import PaymentTypeId from "@Primitives/PaymentTypeId.g";
import BillableServicesApiAdapter from "@HisPlatform/Packages/Patients/Components/BillableServicesPanel/BillableServicesApiAdapter";
import ShowInvoicePdfAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowInvoicePdfAction.g";
import { InvoicePdfScreen } from "@HisPlatform/Packages/Patients/Screens/InvoicePdfScreen/InvoicePdfScreen";
import { BillableServicesPanelRegistryItem } from "@HisPlatform/Packages/Patients/Components/BillableServicesPanel/BillableServicesCustomBlock";

class PatientsPackageInitializer implements IPackageInitializer {
    public configureDependencies(container: Container): void {
        // Moved DI registrations to WebAppBackendDependencyConfiguration to mitigate a DI error
        container.bind("BillableServicesApiAdapter").to(BillableServicesApiAdapter).inSingletonScope();
    }

    public configureNData(configurator: INDataAttributeConfigurator): void {
        configurator.registerColumn("LastModifiedBy", <UserColumn />);
        configurator.registerColumn("DocumentState", <DocumentStateColumn />);
        configurator.registerColumn("DocumentKind", <DocumentKindColumn />);
        configurator.registerColumn("InvoiceTypeId", <InvoiceTypeIdColumn />);
        configurator.registerColumn("InvoiceState", <InvoiceStateColumn />);
    }

    public configureMapper(configurator: IMapperConfigurator): void {
        const currentCultureProvider = DependencyContainer.get<ICurrentCultureProvider>("ICurrentCultureProvider");

        configureFrontendActionMappings(configurator);
        configureFrontendListMappings(configurator, currentCultureProvider);
    }

    public configureFormEngine(registry: FormEditorRegistry) {
        registry.registerCustomBlock("PatientAge", PatientAgeRegistryItem);
        registry.registerCustomBlock("PatientBirthPlace", PatientBirthPlaceRegistryItem);
        registry.registerCustomBlock("PatientInsurancePlan", PatientInsurancePlanRegistryItem);
        registry.registerCustomBlock("ZipCodeAndSettlement", ZipCodeAndSettlementRegistryItem);
        registry.registerCustomBlock("PatientValidity", PatientValidityRegistryItem);
        registry.registerCustomBlock("PatientInfoBox", PatientInfoBoxRegistryItem);
        registry.registerCustomBlock("VendorBankAccount", VendorBankAccountFormCustomBlockRegistryItem);
        registry.registerCustomBlock("BillablePerformedServices", BillableServicesPanelRegistryItem);

        registry.registerEntityReferenceEditor("Vendor", {
            componentType: VendorSelector,
            props: {
                autoSelectSingleItem: true
            },
            displayName: "Szolgáltató",
            entityIdCtor: VendorId,
            loadReferenceDataAsync: async () => await DependencyContainer.get<FinanceReferenceDataStore>("FinanceReferenceDataStore").vendorMap.ensureLoadedAsync()
        });

        registry.registerExtensibleEnumEditor(
            "InvoiceType",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineExtensibleEnumDisplayName.InvoiceType,
                componentType: InvoiceTypeSelector,
                entityIdCtor: InvoiceTypeId,
                loadReferenceDataAsync: () => {
                    DependencyContainer.get<FinanceReferenceDataStore>("FinanceReferenceDataStore").invoiceTypeMap.ensureLoadedAsync();                    return Promise.resolve();
                }
            }
        );

        registry.registerEntityReferenceEditor("VatRate", {
            componentType: VatRateSelectBox,
            displayName: "ÁFA kulcs",
            entityIdCtor: VatRateId,
            loadReferenceDataAsync: async () => await DependencyContainer.get<FinanceReferenceDataStore>("FinanceReferenceDataStore").vatRateMap.ensureLoadedAsync()
        });

        registry.registerExtensibleEnumEditor(
            "PaymentType",
            {
                displayName: StaticCareResources.ReferenceData.FormEngineExtensibleEnumDisplayName.PaymentType,
                componentType: PaymentTypeSelector,
                entityIdCtor: PaymentTypeId,
                loadReferenceDataAsync: () => {
                    DependencyContainer.get<FinanceReferenceDataStore>("FinanceReferenceDataStore").paymentTypeMap.ensureLoadedAsync();                    return Promise.resolve();
                }
            }
        );
    }

    public configureFormLogics(registry: IFormLogicRegistry): void {
        registry.register("PatientAdministrativeDataForm", "PatientAdministrativeDataScreenFormLogic");
        registry.register("InvoiceScreenForm", "InvoiceScreenFormLogic");
    }

    public configureScreens(registry: IScreenRegistry): void {

        registry.register<ShowCombinePatientsScreenAction>(
            ShowCombinePatientsScreenAction.id,
            {
                component: CombinePatientsScreen,
            });

        registry.register<ShowImmunizationListScreenAction>(
            ShowImmunizationListScreenAction.id,
            {
                component: ImmunizationListScreen,
                getScreenContextData: (a: ShowImmunizationListScreenAction) => new PatientScreenContextData(a.patientId),
                sidebarComponent: PatientMainMenuSideBar
            },
            {
                displayName: StaticCareResources.PatientMenu.Immunization,
                iconName: "vaccine"
            }
        );

        registry.register<ShowCreateNewImmunizationScreenAction>(
            ShowCreateNewImmunizationScreenAction.id,
            {
                component: ImmunizationScreen,
                getScreenContextData: (a: ShowCreateNewImmunizationScreenAction) => new PatientScreenContextData(a.patientId)
            } as any,
            {
                displayName: StaticCareResources.MedicalCondition.Common.Label.NewEntry,
                iconName: "plus"
            }
        );

        registry.register<ShowImmunizationScreenAction>(
            ShowImmunizationScreenAction.id,
            {
                component: ImmunizationScreen,
                getScreenContextData: (a: ShowImmunizationScreenAction) => new PatientScreenContextData(a.patientId),
            } as any,
            {
                displayName: StaticCareResources.Common.Button.Edit,
                iconName: "angleRight"
            }
        );

        registry.register<ShowPatientRelatedDocumentListScreenAction>(
            ShowPatientRelatedDocumentListScreenAction.id,
            {
                component: DocumentListScreen,
                getScreenContextData: (a: ShowPatientRelatedDocumentListScreenAction) => new PatientScreenContextData(a.patientId),
                sidebarComponent: PatientMainMenuSideBar
            },
            {
                displayName: StaticWebAppResources.PatientBaseData.Menu.Documents,
                iconName: "document"
            }
        );

        registry.register<ShowCreateNewPatientRelatedDocumentScreenAction>(
            ShowCreateNewPatientRelatedDocumentScreenAction.id,
            {
                component: DocumentScreen,
                getScreenContextData: (a: ShowCreateNewPatientRelatedDocumentScreenAction) => new PatientScreenContextData(a.patientId)
            } as any,
            {
                displayName: StaticWebAppResources.Common.Button.Add,
                iconName: "plus"
            }
        );

        registry.register<ShowPatientRelatedDocumentScreenAction>(
            ShowPatientRelatedDocumentScreenAction.id,
            {
                component: DocumentScreen
            } as any,
            {
                iconName: "pen"
            }
        );

        registry.register<ShowNewPatientScreenAction>(
            ShowNewPatientScreenAction.id,
            {
                component: PatientAdministrativeDataScreen as any,
                getScreenContextData: (a: ShowNewPatientScreenAction) => {
                    return null;
                },
            
                sidebarComponent: PatientMainMenuSideBar
            }
        );

        registry.register<ShowPatientScreenAction>(
            ShowPatientScreenAction.id,
            {
                component: PatientAdministrativeDataScreen as any,
                getScreenContextData: (a: ShowPatientScreenAction) => {
                    if (a instanceof ShowPatientScreenAction)
                        return new PatientScreenContextData(a.patientId);
                    return null;
                },
                sidebarComponent: PatientMainMenuSideBar
            }
        );

        registry.register<ShowPatientInvoiceListScreenAction>(
            ShowPatientInvoiceListScreenAction.id,
            {
                component: InvoiceListScreen,
                getScreenContextData: (a: ShowPatientInvoiceListScreenAction) => {
                    if (a instanceof ShowPatientInvoiceListScreenAction)
                        return new PatientScreenContextData(a.patientId);
                    return null;
                },
                sidebarComponent: PatientMainMenuSideBar
            },
            {
                displayName: StaticWebAppResources.PatientBaseData.Menu.PatientInvoices,
                iconName: "fileInvoiceSolid"
            }
        );

        registry.register<ShowCreateNewInvoiceScreenAction>(
            ShowCreateNewInvoiceScreenAction.id,
            {
                component: InvoiceScreen as any,
                getScreenContextData: (a: ShowCreateNewInvoiceScreenAction) => {
                    return new PatientScreenContextData(a.patientId);
                },
                sidebarComponent: PatientMainMenuSideBar
            },
            {
                iconName: "plus"
            }
        );

        registry.register<ShowInvoiceScreenAction>(
            ShowInvoiceScreenAction.id,
            {
                component: InvoiceScreen as any,
                sidebarComponent: PatientMainMenuSideBar
            },
            {
                iconName: "pen"
            }
        );

        registry.register<ShowInvoicePdfAction>(
            ShowInvoicePdfAction.id,
            {
                component: InvoicePdfScreen,
                sidebarComponent: PatientMainMenuSideBar
            },
            {
                iconName: "fileInvoiceSolid"
            }
        );
    }

    public configureActionProcessor(registry: GlobalActionRegistry) {
        registry.register(CombinePatientsAction.id, null);
        registry.register(ShowCreateNewPatientRelatedDocumentScreenAction.id, null);
        registry.register(DownloadPatientRelatedDocumentAction.id, downloadPatientRelatedDocumentActionProcessorFactory, { iconName: "export" });
    }

    public configureDynamicNavigation(registry: IDynamicNavigationRegistry): void {
        getNavigationMenuItems().forEach(navigationItem => registry.register(navigationItem));
    }
}

export default new PatientsPackageInitializer();
