import React, { useMemo } from "react";
import { SelectBox } from "@CommonControls";
import { IFormEditorComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import { provideFormPanelStore, useFormPanelStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormPanelStoreProvider";
import { emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import EnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/EnumFormDataElement";

interface IFormEnumSelectorProps extends IFormEditorComponentProps {

}

function FormEnumSelector(props: IFormEnumSelectorProps) {

    const store = useFormPanelStore();
    const enumsByPropertyIdentifiers = store.enumsByPropertyIdentifiers.get();

    const items = useMemo(() => {

        if (!enumsByPropertyIdentifiers) {
            return emptyArray;
        }

        const enumStore = enumsByPropertyIdentifiers.get(props.propertyIdentifier);
        if (!enumStore) {
            throw new Error(`Enum store not found for property identifier: ${props.propertyIdentifier}`);
        }
        return enumStore.values
            .filter(opt => {
                const enumDataElement = (props.dataElement as EnumFormDataElement)!;
                const isValueActive = !enumDataElement.activeValues || enumDataElement.activeValues.includes(opt.value);
                return opt.value === props.value || (!opt.isDeleted && isValueActive);
            })
            .map(opt => ({
                text: opt.MultiLingualDisplayValue.getWithCurrentCultureCodeOrWithDefaultCultureCode(store.cultureCodeProvider.cultureCode),
                value: opt.value
            } as ISelectBoxItem<number>));
    }, [enumsByPropertyIdentifiers, props.propertyIdentifier]);

    return (
        <SelectBox
            {...props}
            items={items} 
        />
    );
}

export default provideFormPanelStore(FormEnumSelector);