import ResourceBlocking from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ResourceBlocking";

export interface IResourceBlockingEditorDialogParams {
    resourceBlocking: ResourceBlocking;
}

export interface IResourceBlockingEditorDialogResult {
    resourceBlocking: ResourceBlocking;
}

export default class ResourceBlockingEditorDialogParams implements IResourceBlockingEditorDialogParams {
    public static type = "ResourceBlockingEditorDialog";

    public get type() { return ResourceBlockingEditorDialogParams.type; }

    constructor(public readonly resourceBlocking: ResourceBlocking) {
    }
}
