import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import PatientId from "@Primitives/PatientId.g";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import { parseUseCaseStateFromUrlParam, getUseCaseAsUrlParam } from "@HisPlatform/Components/HisUseCaseHost/UseCaseUrlHelpers";
import UseCaseIdentifier from "@Primitives/UseCaseIdentifier.g";
import UseCases from "@Primitives/UseCases";
import UseCaseArgument from "@Primitives/UseCaseArgument";
import UseCaseDisplayMode from "@HisPlatform/BoundedContexts/Productivity/Api/Worklist/Enum/UseCaseDisplayMode.g";
import IMedicalAlertStatementRouteParams from "@HisPlatform/Application/Routes/IMedicalAlertStatementRouteParams";
import MedicalAlertStatementId from "@Primitives/MedicalAlertStatementId.g";
import MedicalAlertStatementListPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicalAlertStatement/MedicalAlertStatementListPanel";
import PatientRoutes from "@HisPlatform/Components/Pages/Patient/PatientRoutes";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";


interface IMedicalAlertStatementPageProps extends IRoutingFrameContentProps {
}

@State.observer
class MedicalAlertStatementPage extends React.Component<IMedicalAlertStatementPageProps> {

    @State.computed private get routeParams(): IMedicalAlertStatementRouteParams {
        return this.props.routingController.currentRoute.parameters as IMedicalAlertStatementRouteParams;
    }

    @State.computed private get selectedMedicalAlertStatementId(): MedicalAlertStatementId {
        if (this.useCaseState?.useCaseArguments?.length) {
            return this.useCaseState?.useCaseArguments[0].value;
        }

        if (this.useCaseState?.useCase.value === UseCases.medicalAlertStatementDetail) {
            return new MedicalAlertStatementId("new");
        }

        return null;
    }

    @State.computed
    private get patientId() {
        return new PatientId(this.routeParams?.patientId);
    }

    @State.computed private get useCaseState() {
        const useCaseState = this.routeParams?.useCase;
        return parseUseCaseStateFromUrlParam(useCaseState);
    }

    @State.bound
    private setUseCaseState(selectedMedicalAlertStatementId: string, useCaseState: INDataUseCaseState) {
        this.props.routingController.replace(
            PatientRoutes.medicalAlertStatement.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(useCaseState?.useCase, useCaseState?.displayMode, useCaseState?.useCaseArguments)
            })
        );
    }

    @State.bound
    private navigateToMedicalAlertStatement(medicalAlertStatementId: MedicalAlertStatementId) {
        this.props.routingController.replace(
            PatientRoutes.medicalAlertStatement.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    new UseCaseIdentifier(UseCases.medicalAlertStatementDetail),
                    UseCaseDisplayMode.MasterDetail,
                    [new UseCaseArgument(medicalAlertStatementId, "medicalAlertStatementId")])
            })
        );
    }

    @State.bound
    private goBack() {
        this.props.routingController.goBack();
    }

    @State.bound
    private setClosedState() {
        this.props.routingController.replace(
            PatientRoutes.medicalAlertStatement.makeRoute({
                patientId: this.patientId.value,
                mode: "read-write",
                appointmentId: "null",
                useCase: getUseCaseAsUrlParam(
                    null,
                    UseCaseDisplayMode.Full,
                    [new UseCaseArgument(new MedicalAlertStatementId("null"), "medicalAlertStatementId")])
            })
        );
    }

    public render() {
        return (
            <LoadingBoundary>
                <MedicalAlertStatementListPanel
                    _patientId={this.patientId}
                    onChange={this.setUseCaseState}
                    useCaseState={this.useCaseState}
                    selectedRowId={this.selectedMedicalAlertStatementId?.value}
                    onSelectedMedicalAlertStatementIdChange={this.navigateToMedicalAlertStatement}
                    onBack={this.goBack}
                    onCloseDetail={this.setClosedState}
                />
            </LoadingBoundary>
        );
    }
}

export default connect(
    MedicalAlertStatementPage
);
