import { useState, useEffect } from "react";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import ApplicationVersionApiAdapter from "@HisPlatform/BoundedContexts/Diagnostics/ApplicationLogic/ApiAdapter/ApplicationVersionApiAdapter";
import { useErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import ReleaseInfoStore from "@HisPlatform/Application/Store/ReleaseInfoStore";
import config from "@Config";

export default function useApplicationVersion() {
    const [version, setVersion] = useState(null);
    const [isLoading, setLoading] = useState(true);

    // const { apiAdapter } = useDependencies(c => ({
    //     apiAdapter: c.resolve<ApplicationVersionApiAdapter>(nameof<ApplicationVersionApiAdapter>()),
    // }));

    const dispatchError = useErrorDispatcher();

    useEffect(() => {
        setLoading(true);
        ReleaseInfoStore.getReleaseInfoAsync()
            .then((result) => {
                if (config.testFeatures.technicalControlsEnabled) {
                    setVersion(`${result.SetupKitBuildNumber} | Backend: ${result.BackendBuildNumber} | WebApp: ${result.WebAppBuildNumber}`);
                } else {
                    setVersion(result.SetupKitBuildNumber);
                }
            })
            .catch(dispatchError)
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return [version, isLoading];
}