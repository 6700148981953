import React from "react";
import { ICommonControlProps, getStandardHtmlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import humanizeDuration from "humanize-duration";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import moment from "moment";
import * as Ui from "@CommonControls";

interface IDurationBoxLabelDependencies {
    currentCultureProvider: ICurrentCultureProvider;
}

interface IDurationBoxLabelProps extends ICommonControlProps {
    _dependencies?: IDurationBoxLabelDependencies;
    from: moment.Moment;
    to: moment.Moment;
    automationId: string;
    label?: string;
}

class DurationBoxLabel extends React.PureComponent<IDurationBoxLabelProps> {

    @State.computed private get duration() {
        if (!this.props.from || !this.props.to || this.props.to <= this.props.from) {
            return null;
        }

        const diff = this.props.to.startOf("minutes").diff(this.props.from.startOf("minutes"));

        return humanizeDuration(diff, {
            units: ["y", "mo", "w", "d", "h", "m"],
            language: this.props._dependencies.currentCultureProvider.twoLetterISOLanguageName,
        });
    }

    public render() {
        return (
            <Ui.TextBox
                {...this.props}
                value={this.duration}
                isReadOnly
            />
        );
    }
}

export default connect(
    DurationBoxLabel,
    new DependencyAdapter<IDurationBoxLabelProps, IDurationBoxLabelDependencies>((container) => {
        return {
            currentCultureProvider: container.resolve("ICurrentCultureProvider")
        };
    })
);