import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import PerformanceStatementCreateTypeSelectBox from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Components/PerformanceStatementCreateTypeSelectBox/PerformanceStatementCreateTypeSelectBox";
import StaticHunSocialSecurityPerformanceStatementResources from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/StaticResources/StaticHunSocialSecurityPerformanceStatementResources";
import PerformanceStatementCreateType from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementCreateType.g";

export interface ISelectPerformanceStatementForCreationDialogProps {
    onNavigateToPerformanceStatementCreation: (performanceStatementCreateType: PerformanceStatementCreateType) => void;
}

/** @screen */
@State.observer
class SelectPerformanceStatementForCreationDialog extends React.Component<ISelectPerformanceStatementForCreationDialogProps> {

    @State.observable.ref private closeEvent = new TypedEvent<boolean>();
    @State.observable.ref private performanceStatementCreateType: PerformanceStatementCreateType = PerformanceStatementCreateType.NeakNormal;

    @State.action.bound
    private setPerformanceStatementCreateType(value: PerformanceStatementCreateType) {
        this.performanceStatementCreateType = value;
    }

    @State.action.bound
    private close() {
        this.closeEvent.emit(false);
    }

    @State.bound
    private selectPerformanceStatementForCreation() {
        this.closeEvent.emit(true);
        this.props.onNavigateToPerformanceStatementCreation(this.performanceStatementCreateType);
    }

    public render() {
        const resources = StaticHunSocialSecurityPerformanceStatementResources.SelectPerformanceStatementForCreationDialog;

        return (
            <UseCaseFrame
                modalCloseEvent={this.closeEvent}
                title={resources.Title}
                modalSize="tiny"
                leftFooter={<Ui.Button text={StaticWebAppResources.Common.Button.Close} onClick={this.close} visualStyle="link" automationId="closeButton" />}
                rightFooter={<Ui.Button visualStyle="primary" text={resources.Select} onClick={this.selectPerformanceStatementForCreation} automationId="saveButton" />}
            >
                <PerformanceStatementCreateTypeSelectBox
                    value={this.performanceStatementCreateType}
                    onChange={this.setPerformanceStatementCreateType}
                    label={resources.PerformanceStatementCreateType}
                    clearable={false}
                    automationId="performanceStatementCreateTypeSelectBox"
                />
            </UseCaseFrame>
        );
    }
}

export default connect(
    SelectPerformanceStatementForCreationDialog
);
