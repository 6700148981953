import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import Styles from "./FileUpload.less";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { file } from "@CommonControls/Icon/SvgImports";

interface IFileUploadDependencies {
    toolkitLocalizationService: IToolkitLocalizationService;
}

interface IFileUploadProps {
    _dependencies?: IFileUploadDependencies;
    label?: string;
    onChange?: (newValue: File[]) => void;
    maxFileSize?: number;
    showFiles?: boolean;
    id: string;
}

function FileUpload(props: IFileUploadProps) {

    const resources = props._dependencies.toolkitLocalizationService.staticResources.fileUpload;

    const onDrop = useCallback(files => {
        if (props.onChange) {
            props.onChange(files);
        }
    }, [props.onChange]);

    const { getRootProps, getInputProps, acceptedFiles, rejectedFiles } = useDropzone({
        onDrop,
        maxSize: props.maxFileSize,
        accept: "image/jpeg, image/png, image/bmp ,application/pdf, image/gif"
    });

    const fileList = acceptedFiles.map(file => {
        return (
            <li key={file.name}>
                {file.name} - {Math.round(file.size / 10.24) / 100} KByte
            </li>
        );
    }
    );
    return (
        <section>
            {props.label && <label htmlFor={props.id} className={Styles.label}>{props.label}</label>}
            <div {...getRootProps({ className: Styles.container })}>
                <input id={props.id} {...getInputProps()} />
                <p>{resources.placeholder}</p>
                {props.showFiles && !arrayIsNullOrEmpty(fileList) && (
                    <aside>
                        <ul>{fileList}</ul>
                    </aside>
                )}
            </div>
        </section>
    );
}

(FileUpload as React.FC<IFileUploadProps>).defaultProps = {
    maxFileSize: 1024 * 1024 * 10, // 10MB
    showFiles: true
};

export default connect(
    FileUpload,
    new DependencyAdapter<IFileUploadProps, IFileUploadDependencies>(c => ({
        toolkitLocalizationService: c.resolve("IToolkitLocalizationService"),
    }))
);