import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Proxy.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import IRatDeviceHintItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IRatDeviceHintItem";
import IRatDevice from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/IRatDevice";
import RatDeviceId from "@Primitives/RatDeviceId.g";
import { GetTypeNameWithPrefix } from "@HunEHealthInfrastructurePlugin/PluginHelper";
import { QueryPaging } from "@HisPlatform/BoundedContexts/CommonReferenceData/Api/Proxy.g";
import ComplexSearchRatDevicesQueryOrderingFields from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Api/Care/Enum/ComplexSearchRatDevicesQueryOrderingFields.g";

/* auto-inject-disable */
@Di.injectable()
export default class RatDeviceApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("ICareClient")) private readonly apiClient: Proxy.ICareClient        
    ) {
        super();
    }

    public searchRatDevices(freeText: string, maxResultCount: number) {
        return this.processOperationAsync(
            new SimpleStore<IRatDeviceHintItem[]>(),
            async (target) => {
                const response = await this.apiClient.searchRatDevicesQueryAsync(
                    CreateRequestId(),
                    freeText,
                    maxResultCount.toString()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.ratDevices.map(ai => {
                    return {
                        id: ai.id,
                        code: ai.code,
                        name: ai.name,
                        manufacturer: ai.manufacturer
                    } as IRatDeviceHintItem;
                });
            }
        );
    }

    @State.bound
    public getAllIdsAsync(): Promise<SimpleStore<RatDeviceId[]>> {
        return this.processOperationAsync(
            new SimpleStore<RatDeviceId[]>(),
            async (target) => {
                const response = await this.apiClient.getRatDeviceIdsQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.ids.map(id => id);
            }
        );
    }

    @State.bound
    public loadRatDevicesAsync(ids: RatDeviceId[]): Promise<SimpleStore<IRatDevice[]>> {
        return this.processOperationAsync(
            new SimpleStore<IRatDevice[]>(),
            async (target) => {
                const response = await this.apiClient.getRatDevicesByIdsQueryAsync(
                    CreateRequestId(),
                    new Proxy.GetRatDevicesByIdsControllerDto({ ids: ids })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.ratDevices.map(this.mapRatDevice);
            }
        );
    }

    private mapRatDevice(ai: Proxy.RatDeviceDto): IRatDevice {
        return {
            id: ai.id,
            code: ai.code,
            name: ai.name,
            manufacturer: ai.manufacturer
        } as IRatDevice;
    }

    @State.bound
    public complexSearchRatDevices(
        freeText: string,
        ordering: IOrderingState,
        paging: IPagingState) {
        const columnName = ordering && ordering.columnId as string;
        return this.processOperationAsync(
            new SimpleStore<IPagedItems<IRatDevice>>(),
            async (target) => {
                const response = await this.apiClient.complexSearchRatDevicesQueryAsync(
                    CreateRequestId(),
                    new Proxy.ComplexSearchRatDevicesControllerDto({
                        freeText: freeText,
                        pagingAndOrderings: new Proxy.QueryPagingAndOrderingsOfComplexSearchRatDevicesQueryOrderingFields({
                            orderings: columnName && [new Proxy.QueryOrderingOfComplexSearchRatDevicesQueryOrderingFields({
                                direction: ordering.direction === "asc" ? Proxy.OrderingDirection.Ascending : Proxy.OrderingDirection.Descending,
                                fieldName: ComplexSearchRatDevicesQueryOrderingFields[columnName[0].toUpperCase() + columnName.substring(1)]
                            })],
                            paging: paging && new QueryPaging({
                                pageIndex: paging.currentPage || 0,
                                pageSize: paging.pageSize || 20
                            })
                        })
                    })
                );

                target.operationInfo = createOperationInfo(response);
                target.value = {
                    totalCount: response.results && response.results.totalCount,
                    items: response.results && response.results.values.map(this.mapRatDevice)
                };

            }
        );
    }
}
