import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import StatusChangeReasonId from "@Primitives/StatusChangeReasonId.g";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import StatusChangeReasonDialogView from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/StatusChangeReasonDialog/StatusChangeReasonDialogView";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IStatusChangeReasonDialogParams } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/StatusChangeReasonDialog/StatusChangeReasonDialogParams";

interface IStatusChangeReasonDialogDependencies {
    careReferenceDataStore: CareReferenceDataStore;
}

interface IStatusChangeReasonDialogProps extends IModalComponentParams, IStatusChangeReasonDialogParams {
    _dependencies?: IStatusChangeReasonDialogDependencies;
}

/** @screen */
@State.observer
class StatusChangeReasonDialog extends React.Component<IStatusChangeReasonDialogProps> {

    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }

    @State.observable.ref private additionalText = "";
    @State.observable.ref private statusChangeReasonId: StatusChangeReasonId = null;

    @State.computed private get additionalTextRequired() {
        if (isNullOrUndefined(this.statusChangeReasonId)) {
            return false;
        }

        const all = this.careReferenceDataStore.statusChangeReason.getByTypeId(this.props.statusChangeReasonTypeId);
        return all.some(r => ValueWrapper.equals(r.id, this.statusChangeReasonId) && r.hasAdditionalText);
    }

    @State.action.bound
    private close() {
        this.props.onClose(null);
    }

    @State.bound
    private save() {
        this.props.onClose({
            statusChangeReasonId: this.statusChangeReasonId,
            additionalText: this.additionalText
        });
    }

    @State.action.bound
    private setStatusChangeReasonId(statusChangeReasonId: StatusChangeReasonId) {
        this.statusChangeReasonId = statusChangeReasonId;
    }

    @State.action.bound
    private setAdditionalText(newValue: string) {
        this.additionalText = newValue;
    }

    public render() {
        return (
            <StatusChangeReasonDialogView
                additionalText={this.additionalText}
                additionalTextRequired={this.additionalTextRequired}
                save={this.save}
                onClose={this.close}
                setAdditionalText={this.setAdditionalText}
                setStatusChangeReasonId={this.setStatusChangeReasonId}
                statusChangeReasonId={this.statusChangeReasonId}
                statusChangeReasonTypeId={this.props.statusChangeReasonTypeId}
                dialogBodyRenderer={this.props.dialogBodyRenderer}
                additionalTitle={this.props.additionalTitle}
            />
        );
    }
}

export default connect(
    StatusChangeReasonDialog,
    new DependencyAdapter<IStatusChangeReasonDialogProps, IStatusChangeReasonDialogDependencies>(c => ({
        careReferenceDataStore: c.resolve<CareReferenceDataStore>("CareReferenceDataStore"),
    }))
);
