import React from "react";
import FormLayoutGroupBlock from "./FormLayoutGroupBlock";
import FormLayoutRowBlock from "./FormLayoutRowBlock";
import IFormLayoutBlockStore from "@Toolkit/FormEngine/Model/Layout/IFormLayoutBlockStore";
import FormLayoutGroupBlockStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutGroupBlockStore";
import FormLayoutRowBlockStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutRowBlockStore";
import FormLayoutCustomBlockStore from "@Toolkit/FormEngine/Model/Layout/FormLayoutCustomBlockStore";
import FormLayoutCustomBlock from "./FormLayoutCustomBlock";
import FormSchemaReference from "@Toolkit/FormEngine/Model/Schema/FormSchemaReference";

export default function FormLayoutAnyBlock(props: { block: IFormLayoutBlockStore, cultureCode: string, schemaReference: FormSchemaReference }) {
    if (props.block instanceof FormLayoutGroupBlockStore) {
        return <FormLayoutGroupBlock cultureCode={props.cultureCode} block={props.block} schemaReference={props.schemaReference} />;
    }

    if (props.block instanceof FormLayoutRowBlockStore) {
        return <FormLayoutRowBlock block={props.block} cultureCode={props.cultureCode} schemaReference={props.schemaReference} />;
    }

    if (props.block instanceof FormLayoutCustomBlockStore) {
        return <FormLayoutCustomBlock block={props.block} />;
    }

    return null;
}
