import React, { useMemo, useCallback } from "react";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EditableShouldNotBeLongerThanValidationRule from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/Model/Validation/EditableShouldNotBeLongerThanValidationRule";
import { NumberBox } from "@CommonControls";
import RuleTypeId from "@Primitives/RuleTypeId.g";

function ShouldNotBeLongerThanValidationRulePropertyPanel() {

    const store = useFormLayoutEditorStore();

    const rule = useMemo(
        () => store.selectedEditorValidationRules.find(vr => vr.ruleType === RuleTypeId.ShouldNotBeLongerThan.value) as EditableShouldNotBeLongerThanValidationRule,
        [store.selectedEditorValidationRules]
    );
    const setMaxLength = useCallback((value: number) => {

        if (value > 0) {
            if (rule) {
                rule.setMaxLength(value);
            } else {
                store.addValidationRule(new EditableShouldNotBeLongerThanValidationRule(
                    store.selectedEditor.dataReference,
                    {
                        maxLength: value
                    }
                ), store.selectedEditor.parentDataReferences);
            }
        } else {
            store.removeValidationRule(rule);
        }

    }, [rule, store.selectedEditor.dataReference]);

    return (
        <NumberBox label="Maximum hossz" automationId="FieldMaxLength_NumberBox" value={rule?.maxLength ?? 0} onChange={setMaxLength} />
    );
}

export default State.observer(ShouldNotBeLongerThanValidationRulePropertyPanel);