import React, { useCallback } from "react";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps, IDataGridFilterValueSerializer } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import MonthPicker from "@CommonControls/MonthPicker/MonthPicker";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";

interface IDataGridMonthDateColumnDependencies {
    toolkitLocalizationService: IToolkitLocalizationService;
    dateTimeFormatProvider: IDateTimeFormatProvider;
}

type IDataGridMonthDateColumnProps = IDataGridColumnBaseProps & { _dependencies?: IDataGridMonthDateColumnDependencies; };


const DataGridMonthDateColumn: React.FC<IDataGridMonthDateColumnProps> = props => {

    const valueRenderer = useCallback((value: YearMonth) => {
        return !isNullOrUndefined(value) ? value.toMoment().format(props._dependencies.dateTimeFormatProvider.getMonthFormat()) : null;
    }, []);

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<YearMonth>) => {
        const value = filterProps.value;
        const changeHandler = (newValue: YearMonth) => { 
            filterProps.onChange(newValue);
        };
        return <MonthPicker {...filterProps} onChange={changeHandler} value={value} hoverOnlyIndicatorIcons />;
    }, []);

    const { children, ...columnProps } = props;

    return (
        <DataGridColumn
            width={95}
            {...columnProps}
            onRenderCellValue={valueRenderer}
            onRenderFilter={filterRenderer}
            filterValueSerializer={filterValueSerializer}
        />
    );
};

const filterValueSerializer: IDataGridFilterValueSerializer<YearMonth> = {
    serialize: value => value.stringify(),
    deserialize: value => YearMonth.parse(value)
};

export default connect(
    DataGridMonthDateColumn,
    new DependencyAdapter<IDataGridMonthDateColumnProps, IDataGridMonthDateColumnDependencies>(c => ({
        toolkitLocalizationService: c.resolve("IToolkitLocalizationService"),
        dateTimeFormatProvider: c.resolve("IDateTimeFormatProvider"),
    }))
);