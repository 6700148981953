import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Finance/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { buildQueryStringArray } from "@Toolkit/CommonWeb/QueryStringBuilder";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import _ from "@HisPlatform/Common/Lodash";
import InsurerOrganizationId from "@Primitives/InsurerOrganizationId.g";
import InsurancePlanId from "@Primitives/InsurancePlanId.g";
import IInsurancePlan from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/IInsurancePlan";
import IInsurerOrganization from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/IInsurerOrganization";
import InsurerOrganizationTypeId from "@Primitives/InsurerOrganizationTypeId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import InvoiceTypeId from "@Primitives/InvoiceTypeId.g";
import PaymentTypeId from "@Primitives/PaymentTypeId.g";

@Di.injectable()
export default class FinanceApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IFinanceClient") private readonly apiClient: Proxy.IFinanceClient,
        @Di.inject("IInvoicingClient") private readonly invoicingApiClient: Proxy.IInvoicingClient
    ) {
        super();
    }

    @State.bound
    public getInsurerOrganizationsByIdsAsync(ids: InsurerOrganizationId[]): Promise<SimpleStore<IInsurerOrganization[]>> {
        return this.processOperationAsync(
            new SimpleStore<IInsurerOrganization[]>(),
            async target => {

                if (arrayIsNullOrEmpty(ids)) {
                    target.value = [];
                }

                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value.toString()));

                const response = await this.apiClient.getInsurerOrganizationsByIdsQueryAsync(
                    CreateRequestId(),
                    buildQueryStringArray(normalizedIds)
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.insurerOrganizations.map(d => {
                    return {
                        id: d.id,
                        addressLine: d.address.addressLine,
                        countryId: d.address.countryId,
                        settlement: d.address.settlement,
                        zipCode: d.address.zipCode,
                        code: d.code,
                        displayCode: d.displayCode,
                        isActive: d.isActive,
                        isClaimSettlementPartner: d.isClaimSettlementPartner,
                        name: d.name,
                        insurerOrganizationTypeId: d.insurerOrganizationTypeId
                    } as IInsurerOrganization;
                });
            }
        );
    }

    @State.bound
    public getAllInsurerOrganizationIdsAsync() {
        return this.processOperationAsync(
            new SimpleStore<InsurerOrganizationId[]>(),
            async target => {
                const response = await this.apiClient.getAllInsurerOrganizationIdsQueryAsync(
                    CreateRequestId()
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.insurerOrganizationIds;
            }
        );
    }

    @State.bound
    public getInsurancePlansByIdsAsync(ids: InsurancePlanId[]): Promise<SimpleStore<IInsurancePlan[]>> {
        return this.processOperationAsync(
            new SimpleStore<IInsurancePlan[]>(),
            async target => {

                if (arrayIsNullOrEmpty(ids)) {
                    target.value = [];
                }

                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value.toString()));

                const response = await this.apiClient.getInsurancePlansByIdsQueryAsync(
                    CreateRequestId(),
                    buildQueryStringArray(normalizedIds)
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.insurancePlans.map(d => {
                    return {
                        id: d.id,
                        insurerOrganizationId: d.insurerOrganizationId,
                        code: d.code,
                        name: d.name,
                        isActive: d.isActive,
                    } as IInsurancePlan;
                });
            }
        );
    }

    @State.bound
    public getInsurancePlanIdsByInsurerOrganizationIdAsync(insurerOrganizationId: InsurerOrganizationId) {
        return this.processOperationAsync(
            new SimpleStore<InsurancePlanId[]>(),
            async target => {
                const response = await this.apiClient.getInsurancePlanIdsByInsurerOrganizationIdQueryAsync(
                    CreateRequestId(), insurerOrganizationId.value
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.insurancePlanIds;
            }
        );
    }

    @State.bound
    public loadInsurerOrganizationTypesAsync(): Promise<SimpleStore<InsurerOrganizationTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getInsurerOrganizationTypeIdsQueryAsync(...args),
            r => r.insurerOrganizationTypeIds
        );
    }

    @State.bound
    public loadInvoiceTypesAsync(): Promise<SimpleStore<InvoiceTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.invoicingApiClient.getInvoiceTypeIdsQueryAsync(...args),
            r => r.invoiceTypeIds
        );
    }

    @State.bound
    public loadPaymentTypesAsync(): Promise<SimpleStore<PaymentTypeId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.invoicingApiClient.getPaymentTypeIdsQueryAsync(...args),
            r => r.paymentTypeIds
        );
    }
}
