import React, { useMemo } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { SelectBox, UniversalEnumSelector } from "@CommonControls";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import StaticFormEditingResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import ReferencedEnumFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEnumFormDataElement";

function ReferencedEnumEditor() {

    const store = useFormLayoutEditorStore();

    const options = useMemo(() => {
        return store.formEditorRegistry.getEnumEditors().map(i => ({
            text: i.displayName,
            value: i.entityName
        } as ISelectBoxItem<string>));
    }, []);

    return (
        <div>
            <SelectBox
                automationId="ReferencedEnum_SelectBox"
                items={options}
                value={(store.selectedDataElement as ReferencedEnumFormDataElement)?.enumName}
                onChange={store.setEnum}
                clearable
                valueOnClear={null}
                label={StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.EntityReferenceLabel}
            />
        </div>
    );
}

export default State.observer(ReferencedEnumEditor);