import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import IDocumentNameExtensionPointProps from "@PluginInterface/BoundedContexts/DocumentManagement/ExtensionPoints/IDocumentNameExtensionPointProps";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

@Di.injectable()
export default class EhiDocumentExtensionComponentService implements IExtensionComponentService<IDocumentNameExtensionPointProps> {

    public isVisible(props: IDocumentNameExtensionPointProps) {
        return !isNullOrUndefined(props.extensionData?.HunEHealthInfrastructure_EhiDocumentInfo?.Value);
    }

    public shouldRerunIsVisible(props: IDocumentNameExtensionPointProps, prevProps: IDocumentNameExtensionPointProps) {
        return props.extensionData !== prevProps?.extensionData;
    }
}