import CareActivityId from "@Primitives/CareActivityId.g";
import Command from "@Toolkit/CommonWeb/CommandProcessing/Definition/Command";

export default class CreatePrescriptionCommand extends Command {
    public careActivityId: CareActivityId;

    constructor(
        originator: string,
        public readonly substanceName: string | undefined) {
        super("CreatePrescriptionCommand", originator);
    }
}