import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IEhiTokenRepository from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/Repositories/IEhiTokenRepository";
import EhiToken from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/ApplicationLogic/Model/EhiToken";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import EhiUserData from "./EhiUserData";

export class TokenContainer {
    @State.observable.ref private _userData: EhiUserData = null;

    public loggedInUserName = State.promisedComputed(null, async () => {
        if (!this._userData) {
            return null;
        }

        if (!this._userData.loggedInUserName) {
            await this.ensureInitializedAsync();
        }

        return this._userData.loggedInUserName;
    });

    @State.computed
    public get token(): EhiToken {
        return !!this._userData ? this._userData.token : null;
    }

    @State.computed
    public get isLoggedIn(): boolean {
        return !!this._userData && !isNullOrUndefined(this._userData.token);
    }

    constructor(
        ehiTokenRepository: IEhiTokenRepository,
        private readonly practitionerApiAdapter: PractitionerApiAdapter) {
        this.setUserData(new EhiUserData(ehiTokenRepository.getToken()));
    }

    @State.action.bound
    public async replaceAsync(token: EhiToken, loggedInUserEhiIdentifier?: string, loggedInUserName?: string): Promise<void> {
        this._userData = new EhiUserData(token, loggedInUserEhiIdentifier, loggedInUserName);
        await this.ensureInitializedAsync();
    }

    @State.action.bound
    public clear(): void {
        this._userData = null;
    }

    @State.action.bound
    private async ensureInitializedAsync(): Promise<void> {
        if (!this._userData || isNullOrUndefined(this._userData?.token?.userId)) {
            return;
        }

        const practitioner = await this.practitionerApiAdapter.getPractitionerByUserIdAsync(this._userData.token.userId);
        if (!practitioner.operationWasSuccessful) {
            return;
        }

        this._userData.loggedInUserName = practitioner.displayName;
    }

    @State.action.bound
    private setUserData(userData: EhiUserData): void {
        this._userData = userData;
    }
}
