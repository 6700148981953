import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import React from "react";

export default class NavMenuCustomItem implements INavMenuItem {

    private constructor(
        public readonly identifier: string,
        public readonly parentIdentifier: string,
        public readonly customContentComponentType: React.ComponentType<any>,
        public readonly side: "left" | "right",
        public readonly order?: number,
        public readonly props?: any,
    ) {
    }

    public static createChild(
        identifier: string,
        parentIdentifier: string,
        customContentComponentType: React.ComponentType<any>,
        order?: number,
        props?: any,
    ) {
        return new NavMenuCustomItem(identifier, parentIdentifier, customContentComponentType, null, order, props);
    }

    public static createTopLevel(
        identifier: string,
        customContentComponentType: React.ComponentType<any>,
        side: "left" | "right",
        order?: number,
        props?: any
    ) {
        return new NavMenuCustomItem(identifier, null, customContentComponentType, side, order, props);
    }
}
