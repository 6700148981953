import RatFormDetailPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/RatFormDetailPanel/RatFormDetailPanel";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import HunEHealthInfrastructureUseCases from "@HunEHealthInfrastructurePlugin/UseCases/HunEHealthInfrastructureUseCases";
import standaloneRouteDefinitions from "@HunEHealthInfrastructurePlugin/UseCases/CareUseCase/CareUseCaseRoutes";
import UseCaseDescriptorBuilder from "@PluginInterface/UseCases/UseCaseDescriptorBuilder";
import getFormInstanceBasedUseCaseArguments from "@Toolkit/FormEngine/Configuration/FormUseCaseConfigurationHelper";
import RatFormCareActivityPage from "@HunEHealthInfrastructurePlugin/UseCases/CareUseCase/RatForm/RatFormCareActivityPage";

export default function configureRatFormUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add(UseCaseDescriptorBuilder.create(
        HunEHealthInfrastructureUseCases.ratForms,
        builder => {
            builder.standalone(b => b
                .routeDefinition(standaloneRouteDefinitions.ratForms)
                .component(RatFormCareActivityPage));
        }, false
    ));

    useCaseRegistry.add(UseCaseDescriptorBuilder.create(
        HunEHealthInfrastructureUseCases.readOnlyRatForms,
        builder => {
            builder.standalone(b => b
                .routeDefinition(standaloneRouteDefinitions.ratForms).additionalQueryParams({ isReadOnly: true })
                .component(RatFormCareActivityPage));
        }, false
    ));

    useCaseRegistry.add2({
        identifiers: [HunEHealthInfrastructureUseCases.editableRatFormDetail],
        component: RatFormDetailPanel,
        mapUseCaseArgumentsToProps: args => { 
            return getFormInstanceBasedUseCaseArguments(args, false);
        }
    });

    useCaseRegistry.add2({
        identifiers: [HunEHealthInfrastructureUseCases.readonlyRatFormDetail],
        component: RatFormDetailPanel,
        mapUseCaseArgumentsToProps: args => { 
            return getFormInstanceBasedUseCaseArguments(args, true);
        }
    });
}