import LineChart from "./LineChart";
import BarChart from "./BarChart";
import AreaChart from "./AreaChart";
import PieChart from "./PieChart";

export default class Chart {

    
    public static Line = LineChart;
    
    public static Bar = BarChart;
    
    public static Area = AreaChart;
    
    public static Pie = PieChart;

}