import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ExtensibleEnumSelectBox, {IExtensibleEnumSelectBoxPropsBase} from "@HisPlatformControls/ExtensibleEnumSelectBox";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";

interface IEhiCovidSymptomsSeveritySelectBoxDependencies {
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
}

interface IEhiCovidSymptomsSeveritySelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    _dependencies?: IEhiCovidSymptomsSeveritySelectBoxDependencies;
}

@State.observer
class EhiCovidSymptomsSeveritySelectBox extends React.Component<IEhiCovidSymptomsSeveritySelectBoxProps> {
    private get dependencies() {
        return this.props._dependencies;
    }

    private get extensibleEnumStore() {
        return this.dependencies.hunEhiCareReferenceDataStore.ehiCovidSymptomsSeverities;
    }

    public render() {
        return (
            <ExtensibleEnumSelectBox
                {...this.props}
                extensibleEnumStore={this.extensibleEnumStore}
            />
        );
    }
}

export default connect(
    EhiCovidSymptomsSeveritySelectBox,
    new DependencyAdapter<IEhiCovidSymptomsSeveritySelectBoxProps, IEhiCovidSymptomsSeveritySelectBoxDependencies>((containerService) => {
        return {
            hunEhiCareReferenceDataStore: containerService.resolve("HunEhiCareReferenceDataStore")
        };
    })
);
