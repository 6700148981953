import Di from "@Di";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import ConditionStatementId from "@Primitives/ConditionStatementId.g";
import ConditionStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/ConditionStatement/ConditionStatementStore";
import ConditionStatementStoreMapper from "./ConditionStatementStoreMapper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import { getAddConditionStatementControllerDto, getUpdateConditionStatementControllerDto, getDeleteConditionStatementControllerDto, getDeleteConditionStatementHistoryItemControllerDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/ConditionStatement/ConditionStatementDtoMapper";
import PatientId from "@Primitives/PatientId.g";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";

@Di.injectable()
export default class ConditionStatementApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("IConditionStatementClient") private readonly apiClient: Proxy.IConditionStatementClient,
        @Di.inject("ConditionStatementStoreMapper") private readonly storeMapper: ConditionStatementStoreMapper
    ) {
        super();
    }

    public getByIdAsync(conditionStatementId: ConditionStatementId, requestLock: boolean): Promise<ConditionStatementStore> {
        return this.processOperationAsync(
            new ConditionStatementStore(false),
            async target => {
                const response = await this.apiClient.getConditionStatementByIdCommandAsync(
                    CreateRequestId(),
                    new Proxy.GetConditionStatementByIdControllerDto({ conditionStatementId: conditionStatementId, requestLock: requestLock })
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public addConditionStatementAsync(store: ConditionStatementStore, patientId: PatientId, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new ConditionStatementStore(false),
            async target => {
                const response = await this.apiClient.addConditionStatementCommandAsync(
                    CreateRequestId(),
                    getAddConditionStatementControllerDto(store, patientId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public updateConditionStatementAsync(store: ConditionStatementStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new ConditionStatementStore(false),
            async target => {
                const response = await this.apiClient.updateConditionStatementCommandAsync(
                    CreateRequestId(),
                    getUpdateConditionStatementControllerDto(store, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deleteConditionStatementHistoryItemAsync(store: ConditionStatementStore, versionNumber: number, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new ConditionStatementStore(false),
            async target => {
                const response = await this.apiClient.deleteConditionStatementHistoryItemCommandAsync(
                    CreateRequestId(),
                    getDeleteConditionStatementHistoryItemControllerDto(store, versionNumber, false, store.lockInfo?.lockId, false),
                    isPermissionCheckOnly
                );

                this.storeMapper.applyToStore(target, response);
            }
        );
    }

    public deleteConditionStatementAsync(store: ConditionStatementStore, isPermissionCheckOnly?: boolean) {
        return this.processOperationAsync(
            new SimpleStore<any>(),
            async target => {
                const response = await this.apiClient.deleteConditionStatementCommandAsync(
                    CreateRequestId(),
                    getDeleteConditionStatementControllerDto(store),
                    isPermissionCheckOnly
                );

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    public validateAsync(store: ConditionStatementStore, patientId: PatientId) {
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response =
                    store.isNew ?
                        await this.apiClient.addConditionStatementCommandAsync(CreateRequestId(), getAddConditionStatementControllerDto(store, patientId, true)) :
                        await this.apiClient.updateConditionStatementCommandAsync(CreateRequestId(), getUpdateConditionStatementControllerDto(store, true, store.lockInfo?.lockId, false));

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    public async checkPermissionForAddNewItemAsync(store: ConditionStatementStore, patientId: PatientId): Promise<ConditionStatementStore> {
        return await this.addConditionStatementAsync(store, patientId, true);        
    }

    public async checkPermissionForUpdateItemAsync(store: ConditionStatementStore): Promise<ConditionStatementStore> {
        return await this.updateConditionStatementAsync(store, true);
    }

    public async checkPermissionForDeleteItemAsync(store: ConditionStatementStore): Promise<SimpleStore> {
        return await this.deleteConditionStatementAsync(store, true);
    }
}
