import React from "react";
import * as Ui from "@CommonControls";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import RequestedServiceItem from "@HisPlatformControls/RequestedServiceListPanel/RequestedServiceItem";
import RequestedServiceStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/RequestedServiceStore";
import RequestedServiceRowBody from "@HisPlatformControls/RequestedServiceListPanel/RequestedServiceRowBody";
import IMedicalServicePanel from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/MedicalServices/IMedicalServicePanel";
import Styles from "./RequestedServiceListPanel.less";
import GroupBoxStyles from "@CommonControls/GroupBox/GroupBox.less";
import Popper, { IReferenceObject } from "@Toolkit/ReactClient/Components/Tooltip/Popper";
import { IRowBody, IRowCheckState } from "@CommonControls/DataGrid/IDataGridProps";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";

interface IRequestedServiceGroupProps {
    name: string;
    code?: string;
    items: RequestedServiceItem[];
    selectedItems: IObservableArray<RequestedServiceStore>;
    isPanel: boolean;
    onDeselectPanel: (panel: IMedicalServicePanel) => void;
    onSetRowSelectionState: (isChecked: boolean, row: RequestedServiceItem) => void;
    automationId?: string;
}

@State.observer
export default class RequestedServiceGroup extends React.Component<IRequestedServiceGroupProps> {

    @State.observable.ref private referenceElement: IReferenceObject = null;
    @State.observable.ref private tooltipContent: string = null;
    @State.observable.ref private showTooltip = false;

    @State.computed private get isPanelSelected() {
        if (this.props.items.some(i => i.isSelected) && !this.props.items.every(i => i.isSelected)) {
            return null;
        }
        return this.props.items.every(i => i.isSelected);
    }

    @State.computed private get numberOfSelectedItems() {
        return this.props.items.filter(i => i.isSelected).length;
    }

    private rowIsSelectable(row: RequestedServiceItem) {
        const state = this.getRowCheckState(row);
        return !state.isDisabled && state.isRowBodyVisible;
    }

    @State.bound
    private getRowCheckState(row: RequestedServiceItem) {
        return {
            isChecked: row.isSelected,
            isDisabled: row.isDisabledByGenderDependence || row.behavior === "panel-bound",
            isRowBodyVisible: !(this.props.isPanel && row.behavior === "panel-bound"),
            checkBoxAutomationId: `service_checkBox_${row.medicalService.code.value}`
        } as IRowCheckState;
    }

    @State.action.bound
    private setRowSelectedState(isChecked: boolean, row: RequestedServiceItem) {
        this.props.onSetRowSelectionState(isChecked, row);
    }

    @State.action.bound
    private setPanelSelectedState(isChecked: boolean) {
        if (isChecked) {
            this.props.items.filter(i => !i.isDisabledByGenderDependence).forEach(i => this.setRowSelectedState(true, i));
        } else {
            this.props.items.forEach(i => this.setRowSelectedState(false, i));
        }
    }

    @State.bound
    private deselectRow(row: RequestedServiceItem) {
        if (!this.rowIsSelectable(row)) {
            return;
        }
        this.setRowSelectedState(false, row);
    }

    @State.bound
    private selectRow(row: RequestedServiceItem) {
        if (!this.rowIsSelectable(row)) {
            return;
        }
        this.setRowSelectedState(true, row);
    }

    @State.action.bound
    private setTooltipReferenceAndContent(reference: any, content: string) {
        this.referenceElement = reference;
        this.tooltipContent = content;
        this.showTooltip = true;
    }

    @State.action.bound
    private disposeTooltip() {
        this.showTooltip = false;
    }

    @State.bound
    private renderRowBody(row: RequestedServiceItem, _rowId: any, index: number) {
        return {
            content: (
                <RequestedServiceRowBody
                    isPanel={this.props.isPanel}
                    onSelectRow={this.selectRow}
                    onDeselectRow={this.deselectRow}
                    onDeselectPanel={this.props.onDeselectPanel}
                    isSelected={row.isSelected}
                    row={row}
                    index={index}
                    setTooltipReferenceAndInitialize={this.setTooltipReferenceAndContent}
                    disposeTooltip={this.disposeTooltip}
                />
            ),
            showCells: false
        } as IRowBody;
    }

    public render() {
        return (
            <Ui.GroupBox
                title={this.renderGroupBoxTitle()}
                isCollapsible
                hasBorder
                visualStyle={this.props.isPanel ? "primary" : "standard"}
                automationId={this.props.automationId}
                style={{ marginLeft: 4, marginRight: 4 }}
            >
                <Ui.DataGrid
                    dataSource={this.props.items}
                    hasHeader={false}
                    rowCheckState={this.getRowCheckState}
                    onRowChecked={this.setRowSelectedState}
                    rowBody={this.renderRowBody}
                    footer={null}
                    automationId="__medicalServicesList"
                    rowHeight={34}
                    showSeparatorLines={false}
                >
                    <DataGridColumn />
                </Ui.DataGrid>
                <Popper
                    referenceElement={this.referenceElement}
                    tooltipContent={this.tooltipContent}
                    isOpen={this.showTooltip}
                />
            </Ui.GroupBox>
        );
    }

    private renderGroupBoxTitle(): React.ReactNode {

        const title = <div>{this.props.name}&nbsp;<span className={GroupBoxStyles.secondaryTitle}>({this.numberOfSelectedItems}/{this.props.items.length})</span></div>;

        if (this.props.isPanel) {
            return (
                <div className={Styles.panelGroupTitle}>
                    <Ui.CheckBox
                        className={Styles.checkbox}
                        verticalAlign="noPadding"
                        value={this.isPanelSelected}
                        disabled={this.props.items.every(i => i.isDisabledByGenderDependence)}
                        onChange={this.setPanelSelectedState}
                        canBeNull
                        stopClickPropagation
                        automationId={`service_panel_${this.props.code}`}
                    />
                    {title}
                </div>
            );
        }

        return title;
    }
}