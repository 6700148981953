import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import FormDefinitionId from "@Toolkit/FormEngine/Model/Primitives/FormDefinitionId.g";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import { IFormDataStore } from "@Toolkit/FormEngine/Panels/FormPanel/FormDataStore";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import { restoreCustomFormDataStoreAsync } from "@HisPlatform/BoundedContexts/FormEngine/ApplicationLogic/ApiAdapter/FormEngineMappers";
import Form from "@Toolkit/FormEngine/Model/Form";
import FormInstanceId from "@Toolkit/FormEngine/Model/Primitives/FormInstanceId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export async function mapToFormAsync(
    formEngineReferenceDataStore: IFormEngineReferenceDataStore,
    data: Proxy.FormFieldData[],
    definitionId: FormDefinitionId,
    validationResults: Proxy.CompositeValidationResult,
    formLogic: string,
    rowVersions: { [key: string]: RowVersion } = null,
    existingData: IFormDataStore = null) {
    
    const definition = await formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(definitionId);
    const mappedValidationResults = mapValidationResults(validationResults as unknown as IServerCompositeValidationResult);

    const form = new Form(
        FormInstanceId.new,
        RowVersion.initial,
        definitionId,
        data ? await restoreCustomFormDataStoreAsync(data as any, definition, formEngineReferenceDataStore) : existingData,
        mappedValidationResults,
        null,
        null,
        formLogic);

    if (!isNullOrUndefined(rowVersions)) {
        form.rowVersions = new Map(Object.entries(rowVersions));
    }

    return form;
}