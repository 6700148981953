import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import StaticTelecommunicationResources from "@HisPlatform/BoundedContexts/Telecommunication/StaticResources/StaticTelecommunicationResources";
import Log from "@HisPlatform/Services/Definition/Logger/Log";

@Di.injectable()
export default class AssecoRoomsCallContext {
    @State.observable.ref public assecoRoomsIsVisible = false;
    @State.observable.ref public organizerIdentifier: string = "";
    @State.observable.ref public hasMicrophonePermission = false;
    @State.observable.ref public hasCameraPermission = false;
    public cameraPermission: PermissionStatus = null;
    public microphonePermission: PermissionStatus = null;
    private confirmPermissionsDialogVisible = false;
    private missingPermissionDialogVisible = false;

    @State.computed
    public get hasMissingPermission() {
        return !this.hasMicrophonePermission || !this.hasCameraPermission;
    }

    constructor(@Di.inject("IDialogService") private readonly dialogService: IDialogService) {
    }

    @State.action.bound
    public showAssecoRooms(organizerIdentifier: string) {
        this.assecoRoomsIsVisible = true;
        this.organizerIdentifier = organizerIdentifier;
    }

    @State.action.bound
    public hideAssecoRooms() {
        this.assecoRoomsIsVisible = false;
        if (this.confirmPermissionsDialogVisible) {
            this.hideConfirmPermissionsDialog();
        }
        if (this.missingPermissionDialogVisible) {
            this.hideMissingPermissionsDialog();
        }
    }

    @State.bound
    public async checkForPermissionsAsync() {
        try {
            if (this.cameraPermission.state === "granted") {
                this.setHasCameraPermission(true);
            } else if (this.microphonePermission.state === "denied") {
                this.setHasCameraPermission(false);
            }
            if (this.microphonePermission.state === "granted") {
                this.setHasMicrophonePermission(true);
            } else if (this.microphonePermission.state === "denied") {
                this.setHasMicrophonePermission(false);
            }
            if (this.cameraPermission.state === "prompt" ||
                this.microphonePermission.state === "prompt") {
                if (this.assecoRoomsIsVisible) {
                    this.showConfirmPermissionsDialog();
                }
                await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
            }
            if (this.assecoRoomsIsVisible) {
                if (this.hasMissingPermission) {
                    if (this.confirmPermissionsDialogVisible) {
                        this.hideConfirmPermissionsDialog();
                    }
                    if (!this.missingPermissionDialogVisible) {
                        this.showMissingPermissionsDialog();
                    }
                } else {
                    if (this.confirmPermissionsDialogVisible) {
                        this.hideConfirmPermissionsDialog();
                    }
                    if (this.missingPermissionDialogVisible) {
                        this.hideMissingPermissionsDialog();
                    }
                }
            }
        }
        catch (err) {
            if (err.code === 8 && err.message === 'Requested device not found')
                Log.error(err);
            else
                throw err;
        }
    }

    @State.bound
    public async loadMicrophonePermissionAsync() {
        const microphonePermissionName = "microphone" as PermissionName;
        this.microphonePermission = await navigator.permissions.query({ name: microphonePermissionName });
        if (this.microphonePermission) {
            this.microphonePermission.onchange = this.onMicrophonePermissionChanged;
        }
    }

    @State.bound
    public async loadCameraPermissionAsync() {
        const cameraPermissionName = "camera" as PermissionName;
        this.cameraPermission = await navigator.permissions.query({ name: cameraPermissionName });
        if (this.cameraPermission) {
            this.cameraPermission.onchange = this.onCameraPermissionChanged;
        }
    }

    @State.action.bound
    private setHasMicrophonePermission(newValue: boolean) {
        this.hasMicrophonePermission = newValue;
    }

    @State.action.bound
    private setHasCameraPermission(newValue: boolean) {
        this.hasCameraPermission = newValue;
    }

    @State.bound
    private onMicrophonePermissionChanged() {
        const allowed = this.microphonePermission.state === "granted";
        if (allowed) {
            this.setHasMicrophonePermission(true);
            if (this.assecoRoomsIsVisible) {
                if (this.hasMissingPermission) {
                    if (this.confirmPermissionsDialogVisible) {
                        this.hideConfirmPermissionsDialog();
                    }
                    if (!this.missingPermissionDialogVisible) {
                        this.showMissingPermissionsDialog();
                    }
                } else {
                    if (this.confirmPermissionsDialogVisible) {
                        this.hideConfirmPermissionsDialog();
                    }
                    if (this.missingPermissionDialogVisible) {
                        this.hideMissingPermissionsDialog();
                    }
                }
            }
        } else {
            this.setHasMicrophonePermission(false);
            if (this.assecoRoomsIsVisible) {
                if (this.confirmPermissionsDialogVisible) {
                    this.dialogService.onClose();
                    this.confirmPermissionsDialogVisible = false;
                }
                if (!this.missingPermissionDialogVisible) {
                    this.showMissingPermissionsDialog();
                }
            }
        }

        return null as any;
    }

    @State.bound
    private onCameraPermissionChanged() {
        const allowed = this.cameraPermission.state === "granted";
        if (allowed) {
            this.setHasCameraPermission(true);
            if (this.assecoRoomsIsVisible) {
                if (this.hasMissingPermission) {
                    if (this.confirmPermissionsDialogVisible) {
                        this.hideConfirmPermissionsDialog();
                    }
                    if (!this.missingPermissionDialogVisible) {
                        this.showMissingPermissionsDialog();
                    }
                } else {
                    if (this.confirmPermissionsDialogVisible) {
                        this.hideConfirmPermissionsDialog();
                    }
                    if (this.missingPermissionDialogVisible) {
                        this.hideMissingPermissionsDialog();
                    }
                }
            }
        } else {
            this.setHasCameraPermission(false);
            if (this.assecoRoomsIsVisible) {
                if (this.confirmPermissionsDialogVisible) {
                    this.dialogService.onClose();
                    this.confirmPermissionsDialogVisible = false;
                }
                if (!this.missingPermissionDialogVisible) {
                    this.showMissingPermissionsDialog();
                }
            }
        }
        return null as any;
    }

    @State.bound
    private showConfirmPermissionsDialog() {
        this.confirmPermissionsDialogVisible = true;
        this.dialogService.ok(
            StaticTelecommunicationResources.TelecommunicationCallControl.ConfirmPermissionsDialogTitle,
            StaticTelecommunicationResources.TelecommunicationCallControl.ConfirmPermissionsDialogMessage).then(() => {
                this.confirmPermissionsDialogVisible = false;
            }).catch((err) => {
                throw err;
            });
    }

    @State.bound
    private showMissingPermissionsDialog() {
        this.missingPermissionDialogVisible = true;
        this.dialogService.ok(
            StaticTelecommunicationResources.TelecommunicationCallControl.MissingPermissionsDialogTitle,
            StaticTelecommunicationResources.TelecommunicationCallControl.MissingPermissionsDialogMessage).then(() => {
                this.missingPermissionDialogVisible = false;
            }).catch((err) => {
                throw err;
            });
    }

    @State.bound
    private hideConfirmPermissionsDialog() {
        this.dialogService.onClose();
        this.confirmPermissionsDialogVisible = false;
    }

    @State.bound
    private hideMissingPermissionsDialog() {
        this.dialogService.onClose();
        this.missingPermissionDialogVisible = false;
    }
}