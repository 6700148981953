exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HeaderWithIcon_container_2grWo {\n  display: flex;\n  flex-direction: row;\n}\n.HeaderWithIcon_iconContainer_3wdiX {\n  display: flex;\n}\n.HeaderWithIcon_iconContainer_3wdiX > svg {\n  margin: 11px 4px;\n}\n.HeaderWithIcon_titleContainer_1NM02 {\n  display: flex;\n  align-items: center;\n}\n.HeaderWithIcon_subTitleContainer_Ay4dE {\n  display: flex;\n  align-items: center;\n  position: relative;\n  top: -2px;\n}\n.HeaderWithIcon_subTitleContainer_Ay4dE .HeaderWithIcon_iconContainer_3wdiX > svg {\n  margin: 7px 4px;\n}\n.HeaderWithIcon_subTitleContainer_Ay4dE .HeaderWithIcon_titleContainer_1NM02 {\n  margin-bottom: 0;\n  margin-top: 5px;\n}\n.HeaderWithIcon_subTitle_7BSn3 {\n  display: flex;\n  line-height: 14px;\n  margin-left: 24px;\n  color: #8695a7;\n  font-family: PragatiNarrow;\n  font-size: 13px;\n  position: relative;\n  top: -3px;\n}\n.HeaderWithIcon_subTitleNoIcon_3_L8O {\n  margin-left: 0;\n}\n", ""]);

// exports
exports.locals = {
	"container": "HeaderWithIcon_container_2grWo",
	"iconContainer": "HeaderWithIcon_iconContainer_3wdiX",
	"titleContainer": "HeaderWithIcon_titleContainer_1NM02",
	"subTitleContainer": "HeaderWithIcon_subTitleContainer_Ay4dE",
	"subTitle": "HeaderWithIcon_subTitle_7BSn3",
	"subTitleNoIcon": "HeaderWithIcon_subTitleNoIcon_3_L8O"
};