import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IUserManagementResources from "./IUserManagementResources";
import { createStaticResourceDictionary } from "@Toolkit/ReactClient/Common/LocalizedStrings";


const StaticUserManagementResources = createStaticResourceDictionary<IUserManagementResources>();

export function loadStaticUserManagementResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    StaticUserManagementResources.load(localizationService.getResourceGroup("WebApp").UserManagement);
}

export default StaticUserManagementResources;