import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import * as Ui from "@CommonControls";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { IEhiEmergencyDialogParams } from "./EhiEmergencyDialogParams";
import StaticHunEHealthInfrastructureWebAppResources from "@HunEHealthInfrastructurePlugin/StaticResources/StaticHunEHealthInfrastructureWebAppResources";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import { isNullOrEmptyString } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import IClientCheckedRule from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientCheckedRule";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

interface IEhiEmergencyDialogProps extends IModalComponentParams, IEhiEmergencyDialogParams {
}

/** @screen */
@State.observer
export default class EhiEmergencyDialog extends React.Component<IEhiEmergencyDialogProps> {

    @State.observable.ref public validationResults: IClientValidationResult[] = null;
    
    @State.action.bound
    public setValidationResults(validationResults: IClientValidationResult[]) {
        this.validationResults = validationResults;
    }

    @State.observable public reason: string = "";
    
    @State.action.bound
    public setReason(reason: string) {
        this.reason = reason;
    }

    @State.observable public radioGroupValue: string = null;
    
    @State.action.bound
    public setRadioGroupValue(radioGroupValue: string) {
        this.radioGroupValue = radioGroupValue;
        this.setReason(radioGroupValue);
    }

    @State.bound
    private cancel() {
        this.props.onClose(null);
    }

    @State.bound
    private async submitAsync() {
        const validationResults = await this.onValidateAsync();
        this.setValidationResults(validationResults);
        if (validationResults.every(i => i.problems.every(j => j.severity !== "error"))) {
            this.props.onClose(this.reason);
        }
    }

    @State.bound
    private onValidateAsync(dirtyFields?: string[]) {
        const results: IClientValidationProblem[] = [];

        if (isNullOrEmptyString(this.reason)) {
            results.push({propertyPath: "Reason", severity: "error", message: StaticHunEHealthInfrastructureWebAppResources.EmergencyDialog.ShouldBeFilled});
        }

        if (this.reason?.length > 50) {
            results.push({propertyPath: "Reason", severity: "error", message: StaticHunEHealthInfrastructureWebAppResources.EmergencyDialog.ShouldBeShorter});
        }

        if (isNullOrEmptyString(this.radioGroupValue)) {
            results.push({propertyPath: "ReasonForQuery", severity: "error", message: StaticHunEHealthInfrastructureWebAppResources.EmergencyDialog.ShouldBeFilled});
        }

        return Promise.resolve([{entityName: "Emergency", checkedRules: [] as IClientCheckedRule[], entityId: null as any, problems: results}]);
    }

    public render() {
        return (
            <Ui.Modal
                title={StaticHunEHealthInfrastructureWebAppResources.EmergencyDialog.Title}
                closeOnOutsideClick={false}
                onClose={this.cancel}
                size="compact"
                isOpen
                >
                <Ui.Modal.Body>
                    <Ui.Flex xsJustify="center">
                        <Ui.Flex.Item>
                            {StaticWebAppResources.Common.DialogTitle.WarningTitle}!
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <p>{StaticHunEHealthInfrastructureWebAppResources.EmergencyDialog.Body.ReasonForQuery}
                        <br/>&nbsp;{StaticHunEHealthInfrastructureWebAppResources.EmergencyDialog.Body.PatientUnable}
                        <br/>&nbsp;{StaticHunEHealthInfrastructureWebAppResources.EmergencyDialog.Body.Patients24HourPermission}
                    </p>
                    <ValidationBoundary onValidateAsync={this.onValidateAsync} entityTypeName="Emergency" validationResults={this.validationResults}>
                        <Ui.RadioButtonGroup
                            label={StaticHunEHealthInfrastructureWebAppResources.EmergencyDialog.Labels.ReasonForQuery}
                            items={[
                                StaticHunEHealthInfrastructureWebAppResources.EmergencyDialog.Options.PatientUnable,
                                StaticHunEHealthInfrastructureWebAppResources.EmergencyDialog.Options.Patients24HourPermission
                            ]}
                            value={this.radioGroupValue}
                            onChange={this.setRadioGroupValue}
                            direction="column"
                            propertyIdentifier="ReasonForQuery"
                        />
                        <Ui.TextBox
                            label={StaticHunEHealthInfrastructureWebAppResources.EmergencyDialog.Labels.Reason}
                            value={this.reason}
                            onChange={this.setReason}
                            propertyIdentifier="Reason"
                            automationId="reasonTextBox"
                        />
                    </ValidationBoundary>
                    <p>{StaticHunEHealthInfrastructureWebAppResources.EmergencyDialog.Body.AuthorityCanValidate}</p>
                    <p><b>{StaticHunEHealthInfrastructureWebAppResources.EmergencyDialog.Question}</b></p>
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Flex xsJustify="between">
                        <Ui.Flex.Item>
                            <Ui.Button
                                text={StaticWebAppResources.Common.Button.Cancel}
                                onClick={this.cancel}
                                visualStyle="link"
                                automationId="cancelButton"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item>
                            <Ui.Button
                                text={StaticWebAppResources.Common.DialogButton.Yes}
                                onClickAsync={this.submitAsync}
                                visualStyle="primary"
                                automationId="submitButton"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.Modal.Footer>
            </Ui.Modal>
        );
    }
}