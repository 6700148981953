import Di from "@Di";
import Route from "./Route";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IHostRoutingAdapter from "@Toolkit/ReactClient/Routing/Abstractions/IHostRoutingAdapter";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";

@Di.injectable()
export default class GlobalRoutingStore implements IRoutingController<void> {
    @State.observable.ref private _currentRoute: Route = null;

    public get currentRoute() { return this._currentRoute; }
    public get currentPathname() { return this.hostRoutingAdapter.currentPathname; }
    public get currentQueryString() { return this.hostRoutingAdapter.currentQueryString; }

    constructor(
        @Di.inject("IHostRoutingAdapter") public readonly hostRoutingAdapter: IHostRoutingAdapter,
    ) { }

    public navigatingAwayHookAsync: () => Promise<boolean>;

    public replaceQueryString(query: string): void {
        this.hostRoutingAdapter.push(this.currentPathname, query);
    }

    public pushRaw(pathname: string): void {
        this.hostRoutingAdapter.push(pathname);
    }
    public replaceRaw(pathname: string): void {
        this.hostRoutingAdapter.replace(pathname);
    }

    public goBack() {
        this.hostRoutingAdapter.goBack();
    }

    public push(route: Route): void {
        this.hostRoutingAdapter.push(route.pathname, route.queryString);
    }

    public async pushAsync(route: Route): Promise<boolean> {
        return await this.hostRoutingAdapter.pushAsync(route.pathname, route.queryString);
    }

    public replace(route: Route): void {
        this.hostRoutingAdapter.replace(route.pathname, route.queryString);
    }

    // ----

    @State.action
    public setCurrentRoute(route: Route) {
        this._currentRoute = route;
    }
}