import CareActivityId from "@Primitives/CareActivityId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React, { useContext, useMemo } from "react";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import PatientContextProvider from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextProvider";
import CareActivityContextProvider from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextProvider";
import StaticHunSocialSecurityPerformanceStatementResources from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/StaticResources/StaticHunSocialSecurityPerformanceStatementResources";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IMasterDetailState from "@CommonControls/Layout/IMasterDetailState";
import { MasterDetailContext } from "@CommonControls/Layout/MasterDetailContext";
import DiagnosisListPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/CareRegister/DiagnosisListPanel/DiagnosisListPanel";
import ViewContextProvider from "@Toolkit/ReactClient/Components/ViewContext/ViewContextProvider";
import ScreenRenderer from "@HisPlatform/Components/ShowScreenAction/ScreenRenderer";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import CareScreenContextData from "@HisPlatform/Services/Definition/ActionProcessing/CareScreenContextData";
import ShowCareActivityBaseDataScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCareActivityBaseDataScreenAction.g";
import ShowDischargePatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowDischargePatientScreenAction.g";
import HisPermissionScopes from "@HisPlatform/Common/FrontendActions/HisPermissionScopes";
import ShowPerformedServicesScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowPerformedServicesScreenAction.g";
import ShowDiagnosisScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowDiagnosisScreenAction.g";

interface ICareRelatedEntitiesDetailPanelDependencies {
    authorizationService: AuthorizationService;
}

export interface ICareRelatedEntitiesDetailPanelProps {
    _dependencies?: ICareRelatedEntitiesDetailPanelDependencies;
    _pointOfCareId?: PointOfCareId;
    _masterDetailState?: IMasterDetailState;
    careActivityId: CareActivityId;
}

@State.observer
class CareRelatedEntitiesDetailPanel extends React.Component<ICareRelatedEntitiesDetailPanelProps> {
    public render() {
        const resources = StaticHunSocialSecurityPerformanceStatementResources.CareRelatedEntitiesDetailPanel;

        return (
            <>
                <CareActivityContextProvider careActivityId={this.props.careActivityId}>
                    <PatientContextProvider>
                        <Ui.PageBox2
                            hasTabs
                            initiallyActiveTabName="baseData"
                        >
                            {this.renderCareActivityBaseDataTab(resources)}
                            {this.renderDiagnosisTab(resources)}
                            {this.renderPerformedServicesTab(resources)}
                            {this.renderCareActivityDischargeTab(resources)}
                        </ Ui.PageBox2>
                    </PatientContextProvider>
                </CareActivityContextProvider>
            </>
        );
    }

    @State.bound
    private close() {
        this.props._masterDetailState?.onSelectedItemChange(null);
    }

    private renderHeader(title: string) {
        return (
            <Ui.PageBox2.Header
                title={title}
                toolbar={null}
                hasDetailCloseButton={true}
                onCloseDetail={this.close} />
        );
    }

    @State.bound
    private renderCareActivityBaseDataTab(resources: { Title: string; CareActivityBaseDataTabTitle: string; DiagnosisTabTitle: string; }) {
        const careActivityId = this.props.careActivityId;
        const pointOfCareId = this.props._pointOfCareId;

        const action =
            new ShowCareActivityBaseDataScreenAction(
                ScreenDisplayMode.WithoutSideBar,
                careActivityId);

        if (!this.props._dependencies.authorizationService.hasPermissionFor(action.id, HisPermissionScopes.pointOfCare(pointOfCareId?.value))) {
            return (
                <UnauthorizedAccessContent />
            );
        }

        const screenContextData = new CareScreenContextData(careActivityId, true);

        return (
            <Ui.PageBox2.Tab tabName="baseData"
                tabTitle={resources.CareActivityBaseDataTabTitle}
                automationId="baseDataTab">
                {this.renderHeader(resources.Title)}
                <Ui.PageBox2.Body>
                    <ScreenRenderer
                        action={action}
                        screenContextData={screenContextData}
                        screenState={null}
                        onClose={this.close}
                    />
                </Ui.PageBox2.Body>
                <Ui.PageBox2.Footer>
                    <HisUi.HisPanelButtonPortal.Host style={{ width: "100%" }} />
                </Ui.PageBox2.Footer>
            </Ui.PageBox2.Tab>);
    }

    @State.bound
    private renderCareActivityDischargeTab(resources: { Title: string; DischargeDataTabTitle: string; DiagnosisTabTitle: string; }) {
        const careActivityId = this.props.careActivityId;
        const pointOfCareId = this.props._pointOfCareId;

        const action =
            new ShowDischargePatientScreenAction(
                ScreenDisplayMode.WithoutSideBar,
                careActivityId);

        if (!this.props._dependencies.authorizationService.hasPermissionFor(action.id, HisPermissionScopes.pointOfCare(pointOfCareId?.value))) {
            return (
                <UnauthorizedAccessContent />
            );
        }

        const screenContextData = new CareScreenContextData(careActivityId, true);

        return (
            <Ui.PageBox2.Tab tabName="discharge"
                tabTitle={resources.DischargeDataTabTitle}
                automationId="dischargeDataTab">
                {this.renderHeader(resources.Title)}
                <Ui.PageBox2.Body>
                    <ScreenRenderer
                        action={action}
                        screenContextData={screenContextData}
                        screenState={null}
                        onClose={this.close}
                    />
                </Ui.PageBox2.Body>
                <Ui.PageBox2.Footer>
                    <HisUi.HisPanelButtonPortal.Host style={{ width: "100%" }} />
                </Ui.PageBox2.Footer>
            </Ui.PageBox2.Tab>);
    }

    private renderDiagnosisTab(resources: { Title: string; CareActivityBaseDataTabTitle: string; DiagnosisTabTitle: string; }) {

        const careActivityId = this.props.careActivityId;
        const pointOfCareId = this.props._pointOfCareId;

        const action =
            new ShowDiagnosisScreenAction(
                ScreenDisplayMode.WithoutSideBar,
                careActivityId);

        if (!this.props._dependencies.authorizationService.hasPermissionFor(action.id, HisPermissionScopes.pointOfCare(pointOfCareId?.value))) {
            return (
                <UnauthorizedAccessContent />
            );
        }

        const screenContextData = new CareScreenContextData(careActivityId, true);

        return (
            <Ui.PageBox2.Tab tabName="diagnosis"
                tabTitle={resources.DiagnosisTabTitle}
                automationId="diagnosisTab">
                {this.renderHeader(resources.Title)}
                <Ui.PageBox2.Body>
                    <ScreenRenderer
                        action={action}
                        screenContextData={screenContextData}
                        screenState={null}
                        onClose={this.close}
                    />
                </Ui.PageBox2.Body>
                <Ui.PageBox2.Footer>
                    <HisUi.HisPanelButtonPortal.Host style={{ width: "100%" }} />
                </Ui.PageBox2.Footer>
            </Ui.PageBox2.Tab>);
    }

    private renderPerformedServicesTab(resources: { Title: string; CareActivityBaseDataTabTitle: string; PerformedServiceTabTitle: string; }) {
        const careActivityId = this.props.careActivityId;
        const pointOfCareId = this.props._pointOfCareId;

        const action = new ShowPerformedServicesScreenAction(
            ScreenDisplayMode.WithoutSideBar,
            careActivityId
        );

        if (!this.props._dependencies.authorizationService.hasPermissionFor(action.id, HisPermissionScopes.pointOfCare(pointOfCareId?.value))) {
            return <UnauthorizedAccessContent />;
        }

        const screenContextData = new CareScreenContextData(careActivityId, true);

        return (
            <Ui.PageBox2.Tab tabName="performedService"
                tabTitle={resources.PerformedServiceTabTitle}
                automationId="performedServiceTab">
                {this.renderHeader(resources.Title)}
                <Ui.PageBox2.Body>
                    <ScreenRenderer
                        action={action}
                        screenContextData={screenContextData}
                        screenState={null}
                        onClose={this.close}
                    />
                </Ui.PageBox2.Body>
                <Ui.PageBox2.Footer>
                    <HisUi.HisPanelButtonPortal.Host style={{ width: "100%" }} />
                </Ui.PageBox2.Footer>
            </Ui.PageBox2.Tab>
        );
    }
}

const MasterDetailConnectedPanel = (props: ICareRelatedEntitiesDetailPanelProps) => {
    const ctx = useContext(MasterDetailContext);

    if (ctx == null) {
        return null;
    }

    return <CareRelatedEntitiesDetailPanel {...props} _masterDetailState={ctx?.state} />;
};

export default connect(
    MasterDetailConnectedPanel,
    new DependencyAdapter<ICareRelatedEntitiesDetailPanelProps, ICareRelatedEntitiesDetailPanelDependencies>(c => ({
        authorizationService: c.resolve("AuthorizationService"),
    })),
    new CareActivityContextAdapter<ICareRelatedEntitiesDetailPanelProps>(careActivityContext => ({
        _pointOfCareId: careActivityContext.careActivity?.pointOfCareId
    })),
);
