import CompositeFormDataElement from "@Toolkit/FormEngine/Model/Schema/CompositeFormDataElement";
import FormDataElementBase from "@Toolkit/FormEngine/Model/Schema/FormDataElementBase";

export function flattenDataElements(blocks: FormDataElementBase[]): Array<{ dataElement: FormDataElementBase, fullyQualifiedName: string }> {
    const dataElements: Array<{ dataElement: FormDataElementBase, path: string[] }> = [];
    blocks.forEach(dataElement => visitBlock(dataElement, []));
    return dataElements.map(de => ({ dataElement: de.dataElement, fullyQualifiedName: de.path.join(".") }));

    function visitBlock(dataElement: FormDataElementBase, path: string[]) {
        const clonedPath = [...path];
        clonedPath.push(dataElement.name);

        if (dataElement instanceof CompositeFormDataElement) {
            dataElement.formDataElements.forEach(fde => visitBlock(fde, clonedPath));
        } else {
            dataElements.push({ dataElement: dataElement, path: clonedPath });
        }
    }
}