import React from "react";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IName from "@Toolkit/CommonWeb/Model/IName";
import { isNullOrEmptyString, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import _ from "@HisPlatform/Common/Lodash";
import PractitionerApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Practitioners/PractitionerApiAdapter";
import IExternalPrescription from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IExternalPrescription";
import formatCodeName from "@HisPlatform/Components/HisPlatformControls/CodeNameFormatter/CodeNameFormatter";
import EhiCareApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/EhiCareApiAdapter";

export default abstract class ExternalPrescriptionDetailViewBase<TProps> extends React.Component<TProps> {
    @State.computed
    protected get practitioner() {
        return this.resolvePractitioner.get();
    }

    @State.computed
    protected get practitionerRecommendationDoctor() {
        return this.resolvePractitionerRecommendationDoctor.get();
    }

    @State.computed
    protected get organizationUnit() {
        return this.resolveOrganizationUnit.get();
    }

    @State.computed
    protected get organization() {
        return this.resolveOrganization.get();
    }

    protected get resources() {
        return StaticHunSocialSecurityMedicationRequestResources.ExternalPrescriptionDetailView;
    }

    protected get practitionerName(): IName {
        return null;
    }

    protected abstract get prescription(): IExternalPrescription;

    protected abstract get localizationService(): ILocalizationService;

    protected abstract get practitionerApiAdapter(): PractitionerApiAdapter;

    protected abstract get ehiCareApiAdapter(): EhiCareApiAdapter;

    private readonly resolvePractitioner = State.promisedComputed(null, async () => {
        return await this.resolvePractitionerAsync(this.prescription.practitioner.identifier, this.practitionerName);
    });

    private readonly resolvePractitionerRecommendationDoctor = State.promisedComputed(null, async () => {
        const doctorElement = await this.resolvePractitionerAsync(
            this.prescription.practitionerRecommendation?.practitionerCode,
            null,
            this.prescription.practitionerRecommendation?.practitionerName);

        return (
            <>{doctorElement} ({this.localizationService.localizeDate(this.prescription.practitionerRecommendation?.date)})</>
        );
    });

    private readonly resolveOrganizationUnit = State.promisedComputed(null, async () => {
        return await this.resolveOrganizationUnitAsync(this.prescription.organizationUnitId);
    });

    private readonly resolveOrganization = State.promisedComputed(null, async () => {
        return await this.resolveOrganizationAsync(this.prescription.organizationId);
    });

    @State.bound
    private async resolvePractitionerAsync(practitionerIdentifier: string, practitionerName?: IName, fallbackName?: string) {
        const fallbackNameToUse = !!fallbackName ? fallbackName : this.resources.Common.NoDataMessage;
        if (isNullOrEmptyString(practitionerIdentifier)) {
            return fallbackNameToUse;
        }

        let identifierValue = _.cloneDeep(practitionerIdentifier);
        if (identifierValue.startsWith("O")) {
            identifierValue = identifierValue.slice(1);
        }

        if (isNullOrUndefined(practitionerName)) {
            const practitionerId = await this.practitionerApiAdapter.getPractitionerIdByDoctorCodeAsync(identifierValue);
            if (isNullOrUndefined(practitionerId.value)) {
                return formatCodeName(practitionerIdentifier, fallbackNameToUse);
            } else {
                const practitioner = await this.practitionerApiAdapter.getPractitionerByIdAsync(practitionerId.value);
                return formatCodeName(practitionerIdentifier, practitioner.displayName);
            }
        } else {
            return formatCodeName(practitionerIdentifier, this.localizationService.localizePersonName(practitionerName));
        }
    }

    @State.bound
    private async resolveOrganizationUnitAsync(organizationUnitId: string) {
        if (isNullOrEmptyString(organizationUnitId)) {
            return this.resources.Common.NoDataMessage;
        }

        const organizationUnitName = await this.ehiCareApiAdapter.getEhiHealthcareProviderOrganizationUnitNameByCodeAsync(organizationUnitId);
        if (isNullOrUndefined(organizationUnitName.value)) {
            return formatCodeName(organizationUnitId, this.resources.Common.NoDataMessage);
        } else {
            return formatCodeName(organizationUnitId, organizationUnitName.value);
        }
    }

    @State.bound
    private async resolveOrganizationAsync(organizationId: string) {
        if (isNullOrEmptyString(organizationId)) {
            return this.resources.Common.NoDataMessage;
        }

        let idValue = _.cloneDeep(organizationId);
        if (idValue.startsWith("E")) {
            idValue = idValue.slice(1);
        }

        const organizationName = await this.ehiCareApiAdapter.getEhiHealthcareProviderNameByCodeAsync(idValue);
        if (isNullOrUndefined(organizationName.value)) {
            return formatCodeName(organizationId, this.resources.Common.NoDataMessage);
        } else {
            return formatCodeName(organizationId, organizationName.value);
        }
    }
}
