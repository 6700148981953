import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export interface IFormEngineValidationProblemMessages {
    readonly ShouldBeFilled: string;
    readonly ShouldNotBeShorterThan: string;
    readonly ShouldNotBeLongerThan: string;
    readonly ShouldBeWithinRange: string;
    readonly ShouldMatchPattern: string;
}

class FormEngineValidationProblemMessages {

    private _messages: IFormEngineValidationProblemMessages = {
        ShouldBeFilled: "ShouldBeFilled",
        ShouldNotBeShorterThan: "ShouldNotBeShorterThan",
        ShouldNotBeLongerThan: "ShouldNotBeLongerThan",
        ShouldBeWithinRange: "ShouldBeWithinRange",
        ShouldMatchPattern: "ShouldMatchPattern",
    };

    public load(messages: IFormEngineValidationProblemMessages) {
        this._messages = messages;
    }

    @State.bound
    public getMessageByValidationProblem(validationProblem: IClientValidationProblem) {
        if (isNullOrUndefined(validationProblem.ruleId)) {
            return null;
        }

        return this._messages[validationProblem.ruleId] ?? null;
    }

}

export default new FormEngineValidationProblemMessages();