import AccessRuleId from "@Primitives/AccessRuleId.g";
import UserGroupId from "@Primitives/UserGroupId.g";
import UserId from "@Primitives/UserId.g";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import IDirtyChecked from "@Toolkit/CommonWeb/Model/IDirtyChecked";
import StoreDirtyChecker from "@Toolkit/CommonWeb/Model/StoreDirtyChecker";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class AccessRuleBase  extends EntityStoreBase<AccessRuleId> implements IDirtyChecked {
    private _dirtyChecker = new StoreDirtyChecker();
    public subject: UserId | UserGroupId = null;
    @State.observable public isDeleted: boolean = false;

    public constructor(isNew: boolean) {
        super(isNew);
    }
  
    public isDirty(): boolean {
        return this._dirtyChecker.isDirty(this);
    }
    public takeSnapshot(): void {
        this._dirtyChecker.takeSnapshot(this);
    } 
    public propertyNamesExcludedFromDirtyCheck = [
        "_dirtyChecker"
    ];
}