import ClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidator";
import ValidationResultsBuilder, { IPropertyIdentifiers } from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationResultsBuilder";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import { formatReactString } from "@Toolkit/ReactClient/Common/LocalizedStrings";

export default class EhiPatientsCareActivitiesListFilterValidator extends ClientSideValidator {

    private static readonly dateRangeMaxLengthInDays = 180;

    constructor() {
        super({
            Filter: {
                dateRange: {
                    isRequired: false,
                    customEvaluators: [
                        (...args) => this.evaluateDateRange(...args)
                    ]
                }
            }
        }, null, true);
    }

    @State.bound
    private evaluateDateRange(builder: ValidationResultsBuilder, propertyIdentifiers: IPropertyIdentifiers, value: any) {
        if (value instanceof LocalDateRange && !!value && value.from !== null && value.to !== null) {

            const daysInRange = Math.abs(value.from.toUtcDayStartMoment().diff(value.to.toUtcDayStartMoment(), "days"));

            if (daysInRange > EhiPatientsCareActivitiesListFilterValidator.dateRangeMaxLengthInDays) {
                this.addDateRangeShouldNotBeLongerThanProblem(builder, propertyIdentifiers);
            }
        }
    }

    private addDateRangeShouldNotBeLongerThanProblem(builder: ValidationResultsBuilder, propertyIdentifiers: IPropertyIdentifiers) {
        builder.addProblem(
            propertyIdentifiers,
            "DateRangeShouldNotBeLongerThan",
            "error",
            null,
            null,
            formatReactString(StaticHunEHealthInfrastructureCareResources.EhiPatientsCareActivitiesListFilter.Validation.DateRangeIsTooLong, {
                MaxDays: `${EhiPatientsCareActivitiesListFilterValidator.dateRangeMaxLengthInDays}`
            }) as string
        );
    }
}