import UserGroupsApiAdapter from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Groups/UserGroupsApiAdapter";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import AuthorizationReferenceDataStore from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/AuthorizationReferenceDataStore";
import UserGroupId from "@Primitives/UserGroupId.g";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import UserGroup from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Groups/UserGroup";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import { wrappedValuesAreEquals } from "@HisPlatform/Common/ValueWrapperHelpers";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import RequestStatus from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/RequestStatus";
import UserGroupManagementPanelView from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserGroupManagementPanel/UserGroupManagementPanelView";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import UserManagementDataProviderStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/UserManagementDataProviderStore";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import AccessRuleApiAdapter from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/ApiAdapter/OperationAccessControl/AccessRuleApiAdapter";
import AccessRuleBase from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/AccessRuleBase";
import RequestedServiceStateBadge from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/RequestedServiceStateBadge/RequestedServiceStateBadge";

interface IUserGroupManagementPanelDependencies {
    userGroupsApiAdapter: UserGroupsApiAdapter;
    dialogService: IDialogService;
    notificationService: INotificationService;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    authorizationReferenceDataStore: AuthorizationReferenceDataStore;
    userManagementDataProviderStore: UserManagementDataProviderStore;
    accessRuleApiAdapter: AccessRuleApiAdapter;
}

interface IUserGroupManagementPanelProps {
    _dependencies?: IUserGroupManagementPanelDependencies;
    userGroupId: UserGroupId;
    activeTab: "BaseData" | "Permissions";

    onNavigationChanged(userGroupId: string, tab: string): void;

    backToPreviousPage(): void;
}

@State.observer
class UserGroupManagementPanel extends React.Component<IUserGroupManagementPanelProps> {

    @State.observable private isLoading: boolean;
    private isTabNavigation: boolean;

    @State.observable.ref private userGroup: UserGroup = null;
    @State.observable.ref private dataSource: InMemoryDataGridDataSource = new InMemoryDataGridDataSource(() => this.userGroupsDataStore.items);
    @State.observable.ref private userGroupAccessRules: AccessRuleBase[] = [];

    @State.computed
    private get activeTab() {
        return this.props.activeTab || "BaseData";
    }


    private get userGroupsDataStore() {
        return this.props._dependencies.userManagementDataProviderStore.userGroups;
    }

    private get userGroupsApiAdapter() {
        return this.props._dependencies.userGroupsApiAdapter;
    }

    private get notificationService() {
        return this.props._dependencies.notificationService;
    }

    private get dialogService() {
        return this.props._dependencies.dialogService;
    }

    @State.computed
    private get validationResults() {
        return this.props.userGroupId ? (this.userGroup?.validationResults ?? []) : [];
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync, this.userGroupsApiAdapter.updateUserGroupPermissionCheckAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(this.props.userGroupId), this);
    }

    public componentDidUpdate(prevProps: IUserGroupManagementPanelProps) {
        if (prevProps.userGroupId && !this.props.userGroupId) {
            this.unload();
        } else if (!prevProps.userGroupId || !wrappedValuesAreEquals(prevProps.userGroupId, this.props.userGroupId) || !this.userGroup) {
            dispatchAsyncErrors(this.reloadAsync(this.props.userGroupId), this);
        }
    }

    @State.action.bound
    private setIsLoading(newValue: boolean) {
        this.isLoading = newValue;
    }

    @State.bound
    private async loadAsync(id: UserGroupId) {
        this.setIsLoading(true);
        await Promise.all([
            this.setUserGroupByIdAsync(id),
            this.props._dependencies.userManagementDataProviderStore.userGroups.ensureAllLoadedAsync(),
            this.props._dependencies.authorizationReferenceDataStore.roleMap.ensureAllLoadedAsync(),
            this.props._dependencies.organizationReferenceDataStore.pointOfCareMap.ensureAllLoadedAsync(),
            this.loadAccessRulesAsync(id)
        ]);

        this.setIsLoading(false);
    }

    @State.bound
    private async reloadAsync(id: UserGroupId) {
        await this.setUserGroupByIdAsync(this.props.userGroupId);
        await this.loadAccessRulesAsync(id);
    }

    @State.action.bound
    public async setUserGroupByIdAsync(id: UserGroupId) {
        if (!id) {
            return;
        }
        let newStore: UserGroup;
        if (id.value !== "new") {
            const response = await this.userGroupsApiAdapter.getUserGroupByIdAsync(id);
            newStore = response;

        } else {
            newStore = new UserGroup(new UserGroupId("new"), null, null, null, [], "- ");
            newStore.isNew = true;
        }

        this.setUserGroup(newStore);
    }

    @State.bound
    private async loadAccessRulesAsync(id: UserGroupId) {
        if (!id) {
            return;
        }

        const accessRules = [];

        if (id.value !== "new") {
            const res = await this.props._dependencies.accessRuleApiAdapter.getAccessRulesAsync(id);
            accessRules.push(...res.value);
        }
        
        accessRules.forEach(a => a.takeSnapshot());
        this.setUserGroupAccessRules(accessRules);
    }

    @State.action.bound
    private setUserGroup(newValue: UserGroup) {
        this.userGroup = newValue;
        this.userGroup.takeSnapshot();
    }

    @State.bound
    private isAnyAccessRuleDirty() {
        return this.userGroupAccessRules.some(a => a.isDirty() && !(a.isNew && a.isDeleted));
    }

    @State.bound
    private async tryCloseUserEditorAsync() {
        if (!this.isTabNavigation && this.userGroup && (this.userGroup?.isDirty() || this.isAnyAccessRuleDirty())) {
            const answer = await this.dialogService.confirmIfNotSaved(StaticUserManagementResources.UserGroupManagementPanel.Message.SaveBeforeNavigationConfirmationTitle,
                StaticUserManagementResources.UserGroupManagementPanel.Message.SaveBeforeNavigationConfirmationMessage);
            if (answer.resultCode === DialogResultCode.Yes) {
                await this.trySaveUserGroupAsync();
                return !this.userGroup.hasValidationError;
            } else {
                return answer.resultCode === DialogResultCode.No;
            }
        }
        this.isTabNavigation = undefined;
        return true;
    }


    @State.action.bound
    private unload() {
        this.userGroup = null;
        this.userGroupAccessRules = [];
    }

    @State.bound
    private onActiveTabChange(activeTab: string) {
        this.isTabNavigation = true;
        this.props.onNavigationChanged(this.props.userGroupId?.value, activeTab);
    }

    @State.bound
    private onSelectedItemChange(selectedUserGroupId: string) {
        this.navigate(selectedUserGroupId, selectedUserGroupId ? this.activeTab : null);
    }

    @State.bound
    private addNewUserGroup() {
        this.navigate("new", this.activeTab);
    }

    @State.bound
    private navigate(selectedUserGroupId: string, activeTab: string) {
        this.props.onNavigationChanged(selectedUserGroupId, activeTab);
    }

    @State.action.bound
    private async trySaveUserGroupAsync() {
        if (!this.userGroup) {
            return;
        }
        this.setIsLoading(true);
        const validationResult = await this.validateAllAsync();
        const hasValidationError = validationResult.some(item => item.problems.some(p => p.severity === "error"));
        if (!hasValidationError) {
            const newStore = await this.saveUserGroupAsync();
            const couldSave = newStore.operationInfo.requestStatus === RequestStatus.Success && !newStore.hasValidationError;
            if (couldSave) {
               await this.saveAccessRulesAsync(newStore);
            } else {
                this.notificationService.showCannotSaveBecauseOfErrors();
                this.setValidation(newStore.validationResults);
                this.setIsLoading(false);
                return;
            }

            if (this.userGroup.isNew) {
                this.userGroup.takeSnapshot();
                this.userGroupAccessRules.forEach(a => a.takeSnapshot());
                this.props.onNavigationChanged(newStore.id.value, this.props.activeTab);
            } else {
                this.setUserGroup(newStore);
                await this.loadAccessRulesAsync(newStore.id);
            }

            this.notificationService.showSaveResult(newStore.isPersistedByOperationInfo, newStore.hasValidationWarning);
            await this.updateListAsync();
        } else {
            this.notificationService.showCannotSaveBecauseOfErrors();
            this.setValidation(validationResult);
        }

        this.setIsLoading(false);
    }

    @State.bound
    private async saveUserGroupAsync() {
        let newStore: UserGroup;
        if (this.userGroup.isNew) {
            newStore = await this.props._dependencies.userGroupsApiAdapter.addUserGroupAsync(this.userGroup);
        } else {
            newStore = await this.props._dependencies.userGroupsApiAdapter.updateUserGroupAsync(this.userGroup);
        }

        return newStore;
    }

    @State.bound
    private async saveAccessRulesAsync(newStore: UserGroup) {
        if (this.userGroup.isNew) {
            this.userGroupAccessRules.forEach(accessRule => {
                accessRule.subject = newStore.id;
            });
        }

        await this.props._dependencies.accessRuleApiAdapter.setAccessRulesAsync(this.notDeletedAccessRules);
        for (const accessRule of this.deletedAccessRules) {
            await this.props._dependencies.accessRuleApiAdapter.deleteAccessRuleAsync(accessRule);
        }
    }

    @State.bound
    private async updateListAsync() {
        this.userGroupsDataStore.invalidate();
        await this.userGroupsDataStore.ensureAllLoadedAsync();
    }

    @State.action.bound
    private setValidation(validation: IClientValidationResult[]) {
        this.userGroup.validationResults = validation;
    }

    @State.bound
    public async validateAllAsync() {
        const userGroupResults = await this.validateUserGroupAsync();
        const accessRuleResults = await this.validateAccessRulesAsync();

        return [...userGroupResults, ...accessRuleResults];
    }

    private async validateUserGroupAsync() {
        const result = await this.userGroupsApiAdapter.validateAsync(this.userGroup);
        return result.value;
    }

    private async validateAccessRulesAsync() {
        if (!this.notDeletedAccessRules.length) {
            return [];
        }

        const result = await this.props._dependencies.accessRuleApiAdapter.validateAccessRules(this.notDeletedAccessRules);
        return result.value;
    }

    @State.action.bound
    private setUserGroupAccessRules(newValue: AccessRuleBase[]) {
        this.userGroupAccessRules = newValue;
    }

    @State.computed
    private get notDeletedAccessRules() {
        return this.userGroupAccessRules.filter(a => !a.isDeleted);
    }

    @State.computed
    private get deletedAccessRules() {
        return this.userGroupAccessRules.filter(a => a.isDeleted && !!a.id);
    }
    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticUserManagementResources.UserGroupManagementPanel.Title} />;
        }

        return (
            <>
                <UserGroupManagementPanelView
                    userGroup={this.props.userGroupId ? this.userGroup : null}
                    dataSource={this.dataSource}
                    activeTab={this.props.activeTab}
                    addNewUserGroup={this.addNewUserGroup}
                    isLoading={this.isLoading}
                    onActiveTabChange={this.onActiveTabChange}
                    onSelectedItemChange={this.onSelectedItemChange}
                    saveUserGroupAsync={this.trySaveUserGroupAsync}
                    onValidateAllAsync={this.validateAllAsync}
                    validationResults={this.validationResults}
                    backToPreviousPage={this.props.backToPreviousPage}
                    accessRules={this.userGroupAccessRules}
                    onAccessRulesChange={this.setUserGroupAccessRules}
                />
                <NavigateAwayHook isEnabled onNavigateAwayAsync={this.tryCloseUserEditorAsync} />
            </>
        );
    }
}

export default connect(
    UserGroupManagementPanel,
    new DependencyAdapter<IUserGroupManagementPanelProps, IUserGroupManagementPanelDependencies>(container => {
        return {
            userGroupsApiAdapter: container.resolve("UserGroupsApiAdapter"),
            dialogService: container.resolve("IDialogService"),
            notificationService: container.resolve("INotificationService"),
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            authorizationReferenceDataStore: container.resolve("AuthorizationReferenceDataStore"),
            userManagementDataProviderStore: container.resolve("UserManagementDataProviderStore"),
            accessRuleApiAdapter: container.resolve("AccessRuleApiAdapter")
        };
    })
);
