import React, { useCallback } from "react";
import { IDataGridColumnBaseProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import ExtensibleEnumSelectBox from "@HisPlatformControls/ExtensibleEnumSelectBox";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";

export interface IExtensibleEnumColumnProps extends IDataGridColumnBaseProps {
    extensibleEnumStore?: ExtensibleEnumStore;
    displayMode?: "shorthand" | "name" | "both";
    excludedFilterValues?: string[];
}


const DataGridExtensibleEnumColumn: React.FC<IExtensibleEnumColumnProps> = props => {

    const { children, ...columnProps } = props;

    const renderCellValue = useCallback((value: any) => {
        if (Array.isArray(value)) {
            const displayValues = value?.map(i => getDisplayValue(i));
            return displayValues.join(", ");
        }
        return getDisplayValue(value);
    }, []);

    const getDisplayValue = (value: StringEntityId) => {
        const enumValue = value && props.extensibleEnumStore.get(value);
        const displayValue = enumValue && enumValue.displayValue;
        
        if (displayValue) {
            switch (props.displayMode || "name") {
                case "name": 
                    return displayValue.Name;
                case "shorthand":
                    return displayValue.Shorthand;
                case "both":
                    return <><b>{displayValue.Shorthand}</b>{" - " + displayValue.Name}</>;
                default:
                    return "n/a";
            }
        }

        return "";
    };

    const filterRenderer = useCallback((filterProps: IDataGridColumnFilterProps<StringEntityId | null>) => {
        return <ExtensibleEnumSelectBox {...filterProps} extensibleEnumStore={props.extensibleEnumStore} excludedValues={props.excludedFilterValues} hoverOnlyIndicatorIcons />;
    }, []);

    return (
        <DataGridColumn
            {...columnProps}
            onRenderCellValue={renderCellValue}
            onRenderFilter={filterRenderer}
        />
    );
};

export default DataGridExtensibleEnumColumn;