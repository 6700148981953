import React from "react";
import { Button } from "@CommonControls";
import { iconNameType } from "@CommonControls/Icon";
import Styles from "./ActionButton.less";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import { ICommonControlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import RequestedServiceAction from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceAction.g";

interface IActionButtonProps extends ICommonControlProps {
    actionToPerform: RequestedServiceAction;
    onClickAsync?: () => Promise<void>;
    size?: "standard" | "compact";
    automationId?: string;
}

@State.observer
export default class ActionButton extends React.Component<IActionButtonProps> {

    public static defaultProps: Partial<IActionButtonProps> = {
        size: "compact"
    };

    @State.computed private get style() {
        const stateStyle = (() => {
            switch (this.props.actionToPerform) {
                case RequestedServiceAction.RegisterAsReceived:
                    return { iconName: "enter", className: Styles.actionRegisterAsReceived };
                case RequestedServiceAction.CompleteActivity:
                    return { iconName: "stethoscope" as iconNameType, className: Styles.actionCompleteActivity };
                case RequestedServiceAction.ValidateDiagnosticReport:
                    return { iconName: "validated", className: Styles.actionValidateDiagnosticReport };
                case RequestedServiceAction.DeleteForExecutorAfterCompletion:
                case RequestedServiceAction.DeleteForExecutorBeforeCompletion:
                    return { iconName: "trash", className: Styles.actionDelete };
            }

            return null;
        })();

        return stateStyle ? {
            iconName: stateStyle.iconName,
            className: stateStyle.className,
        } : null;
    }

    public render() {

        if (this.style === null) {
            return null;
        }

        return (
            <Button
                className={combineClasses(Styles.actionButton, this.style.className)}
                onClickAsync={this.props.onClickAsync}
                stopPropagation={{ leftClick: true }}
                size={this.props.size}
                iconName={this.style.iconName as iconNameType}
                visualStyle="primary"
                tooltipContent={this.props.tooltipContent}
                tooltipPosition="bottom"
                automationId={this.props.automationId}
            />
        );
    }
}