import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import TelecomTypeId from "@Primitives/TelecomTypeId.g";
import PractitionerAddressStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerAddressStore";
import PractitionerTelecomStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerTelecomStore";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import { formatString } from "@Toolkit/CommonWeb/Formatters";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import UserDetailsView from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserPanel/UserDetailsView";
import PractitionerBaseDataView from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserPanel/PractitionerBaseDataView";
import PractitionerIdentifierStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerIdentifierStore";
import JobPositionId from "@Primitives/JobPositionId.g";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import Button from "@CommonControls/Button";
import PractitionerProfessionalExamStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerProfessionalExamStore";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import { wrappedValuesAreEquals } from "@HisPlatform/Common/ValueWrapperHelpers";
import UserGroupsView from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserPanel/UserGroupsView";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import ExternalPractitionerDataSource from "./ExternalPractitionerDataSource";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import UserListToolbar from "./Master/UserListToolbar";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import CopyUserGroupModalParams from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserPanel/Modals/CopyUserGroupModal/CopyUserGroupModalParams";
import UserList from "./Master/UserList";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import AccessRuleBase from "@HisPlatform/BoundedContexts/Authorization/ApplicationLogic/Model/OperationAccessControl/AccessRuleBase";
import PermissionConfigurationPanel from "@HisPlatform/BoundedContexts/Authorization/Components/Panels/RoleManagement/PermissionConfigurationPanel/PermissionConfigurationPanel";

interface IUserManagementPanelViewDependencies {
    commonReferenceDataStore: CommonReferenceDataDataStore;
    dialogService: IDialogService;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
}

interface IUserManagementPanelViewProps {
    _dependencies?: IUserManagementPanelViewDependencies;
    _modalService?: IModalService;
    onCancel?: () => void;
    user: User;
    practitioner: PractitionerStore;
    saveAsync: () => Promise<boolean>;
    tab: string;
    isNew: boolean;
    onNewUserModeChange: (userMode: boolean) => void;
    onAddUserToPractitioner: (addUserToPractitioner: boolean) => void;
    addUserToPractitioner: boolean;
    newUserMode: boolean;
    currentJobPositionId: JobPositionId;
    onValidateAllAsync: () => Promise<IClientValidationResult[]>;
    usersWithIdentifiersDataSource: InMemoryDataGridDataSource;
    practitionersDataSource: ExternalPractitionerDataSource;
    selectedId: string;
    onSelectedItemChange: (id: string) => void;
    addNewEntityAsync: () => Promise<void>;
    userMode: boolean;
    onUserModeChange: (userMode: boolean) => void;
    onBack: () => void;
    isLoading: boolean;
    onTabChanged: (newTab: string) => void;
    accessRules: AccessRuleBase[];
    onAccessRulesChange: (newValue: AccessRuleBase[]) => void;
}

@State.observer
class UserManagementPanelView extends React.Component<IUserManagementPanelViewProps> {
    private items: boolean[] = [true, false];
    @State.observable private isModalOpen = false;

    @State.computed
    private get modalService() {
        return this.props._modalService;
    }
    private displayValueGetter(item: boolean) {
        return item ?
            StaticUserManagementResources.UserManagementPanel.Label.Internal.toUpperCase() :
            StaticUserManagementResources.UserManagementPanel.Label.External.toUpperCase();
    }

    @State.bound
    private getIconNameForValues(item: boolean) {
        return item ? "id_card" : "language";
    }

    @State.computed
    private get hasUser() {
        return !isNullOrUndefined(this.props.user);
    }

    @State.computed
    private get hasPractitioner() {
        return !isNullOrUndefined(this.props.practitioner);
    }

    @State.computed
    private get displayName() {
        if (!this.hasUser && !this.hasPractitioner) {
            return "";
        }

        return this.hasUser && this.props.user.displayName !== null ? this.props.user.displayName : this.props.practitioner.displayName;
    }

    private getCopyModalButtonText() {
        return this.props.tab === "PointOfCares"
            ? StaticUserManagementResources.UserPanel.Label.CopyRoles
            : StaticUserManagementResources.UserPanel.Label.CopyGroups;
    }

    @State.computed
    private get isCopyModalButtonVisible() {
        return this.props.user?.id && this.props.tab === "Groups";
    }

    @State.computed
    private get allValidationResults() {
        const results: IClientValidationResult[] = [];

        this.props.practitioner?.validationResults?.forEach(i => results.push(i));
        this.props.user?.validationResults?.forEach(i => results.push(i));

        return results;
    }

    @State.bound
    public showCopyUserGroupModal() {
        this.modalService.showModal(new CopyUserGroupModalParams(
            this.props.user,
            this.props.practitioner,
            this.props.user?.copyUserGroupIds
        ));
    }

    public getAssignment() {
        if (!this.props.currentJobPositionId) {
            return "n/a";
        }
        const assignment = this.props._dependencies.organizationReferenceDataStore.jobPositions.get(this.props.currentJobPositionId);
        return assignment?.displayValue.Name;
    }

    public getTitle(tabTitle: string) {
        return this.displayName + ": " + tabTitle;
    }

    public getSubTitle() {
        return StaticUserManagementResources.UserPanel.Label.Assignment + ": " + this.getAssignment();
    }

    public render() {
        return (
            <ValidationBoundary
                validationResults={this.allValidationResults}
                onValidateAsync={this.props.onValidateAllAsync}
                validateOnMount>
                <SingleLayout>
                    <MasterDetailLayout
                        selectedItem={this.props.selectedId}
                        onSelectedItemChange={this.props.onSelectedItemChange}
                        activeTabName={this.props.tab}
                        onActiveTabNameChange={this.props.onTabChanged}
                        isLoading={this.props.isLoading}>
                        <MasterDetail.Master
                            title={StaticUserManagementResources.UserManagementPanel.Title}
                            iconName="employee"
                            toolbar={(<UserListToolbar onAddUserAsync={this.props.addNewEntityAsync} />)}>
                            <UserList
                                usersWithIdentifiersDataSource={this.props.usersWithIdentifiersDataSource}
                                practitionersDataSource={this.props.practitionersDataSource}
                                userMode={this.props.userMode}
                                onUserModeChange={this.props.onUserModeChange}
                                onBack={this.props.onBack} />
                        </MasterDetail.Master>
                        {!this.props.isNew &&
                            <MasterDetail.Detail
                                title="Title"
                                hasSidePadding
                                hasVerticalPadding>
                                <MasterDetail.Tab
                                    tabName="PersonalData"
                                    automationId="personalData"
                                    tabTitle={StaticUserManagementResources.UserPanel.Tabs.BaseData}
                                    title={this.getTitle(StaticUserManagementResources.UserPanel.Tabs.BaseData)}
                                    subTitle={this.getSubTitle()}
                                    toolbar={this.renderDetailToolbar()}
                                    tabPaneIconName="user_alt_solid"
                                    propertyPathRegexPattern="(Practitioner)">
                                    <PractitionerBaseDataView practitioner={this.props.practitioner}
                                        createAddressAsync={this.createAddressAsync}
                                        createEmailAsync={this.createEmailAsync}
                                        createPhoneAsync={this.createPhoneAsync}
                                        onDeleteAddressConfirmationAsync={this.onDeleteAddressConfirmationAsync}
                                        onDeleteTelecomConfirmationAsync={this.onDeleteTelecomConfirmationAsync}
                                        createIdentifierAsync={this.createIdentifierAsync}
                                        onDeleteIdentifierConfirmationAsync={this.onDeleteIdentifierAssignmentConfirmationAsync}
                                        createProfessionAsync={this.createProfessionAsync}
                                        onDeleteProfessionConfirmationAsync={this.onDeleteProfessionsConfirmationAsync} />
                                </MasterDetail.Tab>
                                <MasterDetail.Tab
                                    tabName="User"
                                    automationId="user"
                                    tabTitle={StaticUserManagementResources.UserPanel.Tabs.User}
                                    title={this.getTitle(StaticUserManagementResources.UserPanel.Tabs.User)}
                                    subTitle={this.getSubTitle()}
                                    toolbar={this.renderDetailToolbar()}
                                    tabPaneIconName="user_cog_solid"
                                    propertyPathRegexPattern="(User)">
                                    <UserDetailsView
                                        practitioner={this.props.practitioner}
                                        user={this.props.user}
                                        onAddUserToPractitioner={this.props.onAddUserToPractitioner}
                                        addUserToPractitioner={this.props.addUserToPractitioner} />
                                </MasterDetail.Tab>
                                <MasterDetail.Tab
                                    tabName="Groups"
                                    automationId="groups"
                                    tabTitle={StaticUserManagementResources.UserPanel.Tabs.Groups}
                                    title={this.getTitle(StaticUserManagementResources.UserPanel.Tabs.Groups)}
                                    subTitle={this.getSubTitle()}
                                    toolbar={this.renderDetailToolbar()}
                                    disabled={!this.hasUser}
                                    tabPaneIconName="users_solid"
                                    disablingTooltip={StaticUserManagementResources.UserPanel.Message.GroupsDisabledTabMessage}>
                                    <UserGroupsView user={this.props.user} />
                                </MasterDetail.Tab>
                                <MasterDetail.Tab
                                    tabName="PointOfCares"
                                    automationId="pointOfCares"
                                    tabTitle={StaticUserManagementResources.UserPanel.Tabs.PointOfCares}
                                    title={this.getTitle(StaticUserManagementResources.UserPanel.Tabs.PointOfCares)}
                                    subTitle={this.getSubTitle()}
                                    toolbar={this.renderDetailToolbar()}
                                    disabled={!this.hasUser}
                                    tabPaneIconName="user_shield_solid"
                                    disablingTooltip={StaticUserManagementResources.UserPanel.Message.PointOfCaresDisabledTabMessage}>
                                    <PermissionConfigurationPanel
                                        subjectId={this.props.user?.id}
                                        accessRules={this.props.accessRules}
                                        onAccessRulesChange={this.props.onAccessRulesChange}
                                    />
                                </MasterDetail.Tab>
                            </MasterDetail.Detail>}
                        {this.props.isNew &&
                            <MasterDetail.Detail
                                title={StaticUserManagementResources.UserPanel.Title.NewUser}
                                hasSidePadding
                                hasVerticalPadding
                                toolbar={this.renderDetailToolbar()}>
                                <>
                                    <Ui.RadioButtonGroup
                                        direction="row"
                                        value={this.props.newUserMode}
                                        onChange={this.props.onNewUserModeChange}
                                        items={this.items}
                                        getDisplayValue={this.displayValueGetter}
                                        getIconName={this.getIconNameForValues}
                                        displayType="groupedButtons"
                                        automationId="newUserMode" />
                                    {this.props.newUserMode &&
                                        <UserDetailsView
                                            practitioner={this.props.practitioner}
                                            user={this.props.user}
                                            onAddUserToPractitioner={null}
                                            addUserToPractitioner={false} />
                                    }
                                    <PractitionerBaseDataView practitioner={this.props.practitioner}
                                        createAddressAsync={this.createAddressAsync}
                                        createEmailAsync={this.createEmailAsync}
                                        createPhoneAsync={this.createPhoneAsync}
                                        onDeleteAddressConfirmationAsync={this.onDeleteAddressConfirmationAsync}
                                        onDeleteTelecomConfirmationAsync={this.onDeleteTelecomConfirmationAsync}
                                        createIdentifierAsync={this.createIdentifierAsync}
                                        onDeleteIdentifierConfirmationAsync={this.onDeleteIdentifierAssignmentConfirmationAsync}
                                        createProfessionAsync={this.createProfessionAsync}
                                        onDeleteProfessionConfirmationAsync={this.onDeleteProfessionsConfirmationAsync} />
                                </>
                            </MasterDetail.Detail>
                        }
                    </MasterDetailLayout>
                </SingleLayout>
            </ValidationBoundary>
        );
    }

    private renderDetailToolbar() {
        return (
            <>
                <Ui.SaveButton float="right" onClickAsync={this.props.saveAsync} automationId="save" />
                {this.isCopyModalButtonVisible &&
                    <Button text={this.getCopyModalButtonText()}
                        float="right"
                        size="standard"
                        onClick={this.showCopyUserGroupModal}
                        automationId="copyModal"
                    />
                }
            </>
        );
    }

    @State.bound
    private renderAddressText(address: PractitionerAddressStore): string {
        if (address.addressTypeId) {
            const addressType = (this.props._dependencies.commonReferenceDataStore.addressType.get(address.addressTypeId).displayValue.Name);
            return `${addressType} ${address.zipCode} ${address.settlement}`;
        }
        return `${address.zipCode} ${address.settlement}`;
    }

    @State.bound
    public async onDeleteAddressConfirmationAsync(address: PractitionerAddressStore) {
        if (address.isEmpty()) {
            return true;
        } else {
            const message = StaticUserManagementResources.UserPanel.Dialog.AddressDeleteConfirmationMessage;
            const addressString = this.renderAddressText(address);
            const dialogResult = await this.props._dependencies.dialogService.yesNo(StaticUserManagementResources.UserPanel.Dialog.ConfirmationTitle, formatString(message, addressString));
            return dialogResult.resultCode === DialogResultCode.Yes;
        }
    }

    @State.bound
    public async onDeleteTelecomConfirmationAsync(contactPoint: PractitionerTelecomStore) {
        if (contactPoint.isEmpty) {
            return true;
        } else {
            const message = StaticUserManagementResources.UserPanel.Dialog.TelecomDeleteConfirmationMessage;
            const textParts: string[] = [];
            if (contactPoint.telecomUseId) {
                textParts.push(this.props._dependencies.commonReferenceDataStore.telecomUse.get(contactPoint.telecomUseId).displayValue.Name);
            }
            textParts.push(contactPoint.value);
            const dialogResult = await this.props._dependencies.dialogService.yesNo(StaticUserManagementResources.UserPanel.Dialog.ConfirmationTitle, formatString(message, textParts.join(" ")));
            return dialogResult.resultCode === DialogResultCode.Yes;
        }
    }

    @State.bound
    public async onDeleteIdentifierAssignmentConfirmationAsync(identifier: PractitionerIdentifierStore) {
        if (identifier.isEmpty) {
            return true;
        } else {
            const message = StaticUserManagementResources.UserPanel.Dialog.IdentifierDeleteConfirmationMessage;
            const textParts: string[] = [];

            const identifierSystemItem = this.props._dependencies.commonReferenceDataStore.identifierSystemMap.items.find(item =>
                item.Entity.id.value === identifier?.identifier?.identifierSystemId?.value);

            if (identifierSystemItem) {
                textParts.push(identifierSystemItem.Localization.Name);
            }

            if (identifier.identifier.value) {
                textParts.push(identifier.identifier.value);
            }

            const dialogResult = await this.props._dependencies.dialogService.yesNo(StaticUserManagementResources.UserPanel.Dialog.ConfirmationTitle, formatString(message, textParts.length > 1 ? textParts.join(" ") : textParts.join()));
            return dialogResult.resultCode === DialogResultCode.Yes;
        }
    }

    @State.bound
    public async onDeleteProfessionsConfirmationAsync(professionalExam: PractitionerProfessionalExamStore) {
        if (professionalExam.isEmpty) {
            return true;
        } else {
            const message = StaticUserManagementResources.UserPanel.Dialog.IdentifierDeleteConfirmationMessage;
            const professionalExamItem = this.props._dependencies.organizationReferenceDataStore.professionalExamMap.items.find(item => wrappedValuesAreEquals(item.id, professionalExam.professionalExamId));
            const dialogResult = await this.props._dependencies.dialogService.yesNo(StaticUserManagementResources.UserPanel.Dialog.ConfirmationTitle, formatString(message, professionalExamItem.description));
            return dialogResult.resultCode === DialogResultCode.Yes;
        }
    }

    private createAddressAsync = () => Promise.resolve(new PractitionerAddressStore());
    private createPhoneAsync = () => Promise.resolve(new PractitionerTelecomStore(TelecomTypeId.Phone));
    private createEmailAsync = () => Promise.resolve(new PractitionerTelecomStore(TelecomTypeId.Email));
    private createIdentifierAsync = () => Promise.resolve(new PractitionerIdentifierStore());
    private createProfessionAsync = () => Promise.resolve(new PractitionerProfessionalExamStore());
}

export default connect(
    UserManagementPanelView,
    new DependencyAdapter<IUserManagementPanelViewProps, IUserManagementPanelViewDependencies>(container => {
        return {
            commonReferenceDataStore: container.resolve("CommonReferenceDataDataStore"),
            dialogService: container.resolve("IDialogService"),
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore")
        };
    }),
    new HisModalServiceAdapter()
);
