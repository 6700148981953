import React, { useMemo } from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import CareActivityId from "@Primitives/CareActivityId.g";
import CareActivityContext, { CareActivityReactContext } from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContext";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CareActivityLoader from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityLoader";
import HiddenLoadingIndicator from "@CommonControls/LoadingIndicator/HiddenLoadingIndicator";
import ContextHeaderApiAdapter from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ContextHeaderApiAdapter";
import { FrontendActionPermissionScope } from "@Toolkit/ReactClient/ActionProcessing/FrontendActionPermissionScope";
import PermissionScopeProvider from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/PermissionScopeProvider";

interface ICareActivityContextProviderDependencies {
    contextHeaderApiAdapter: ContextHeaderApiAdapter;
    careActivityLoader: CareActivityLoader;
}

interface ICareActivityContextProviderProps {
    _dependencies?: ICareActivityContextProviderDependencies;

    careActivityId: CareActivityId;
    children?: React.ReactNode;
}

@State.observer
class CareActivityContextProvider extends React.Component<ICareActivityContextProviderProps> {

    private careActivityContext = new CareActivityContext(
        this.props._dependencies.contextHeaderApiAdapter,
    );

    private get careActivityLoader() { return this.props._dependencies.careActivityLoader; }

    @State.observable.ref private _isLoading = true;
    @State.computed private get isLoading() {
        return this._isLoading || this.careActivityContext.isLoading;
    }

    @State.computed private get permissionScopes() {
        if (this.isLoading || !this.careActivityContext?.careActivity?.pointOfCareId) {
            return [];
        }

        return [
            new FrontendActionPermissionScope("PointOfCare", [this.careActivityContext.careActivity.pointOfCareId.value])
        ];
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public componentDidUpdate(prevProps: ICareActivityContextProviderProps) {
        if (!ValueWrapper.equals(prevProps.careActivityId, this.props.careActivityId)) {
            dispatchAsyncErrors(this.loadAsync(), this);
        }
    }

    public componentWillUnmount() {
        this.careActivityContext?.dispose();
    }

    @State.boundLoadingState("_isLoading")
    private async loadAsync() {
        if (this.props.careActivityId) {
            await this.careActivityContext.loadAsync(this.props.careActivityId);
            await this.careActivityLoader.loadCareActivityRelatedReferenceDataAsync(
                this.careActivityContext.baseData, this.careActivityContext.dischargeData);
        }
    }

    public render() {
        return (
            <CareActivityReactContext.Provider value={this.props.careActivityId && this.careActivityContext || null}>
                <PermissionScopeProvider scopes={this.permissionScopes}>
                    {(!this.isLoading || !this.props.careActivityId) && this.props.children}
                    {this.isLoading && <HiddenLoadingIndicator />}
                </PermissionScopeProvider>
            </CareActivityReactContext.Provider>
        );
    }
}

export default connect(
    CareActivityContextProvider,
    new DependencyAdapter<ICareActivityContextProviderProps, ICareActivityContextProviderDependencies>(c => ({
        contextHeaderApiAdapter: c.resolve("ContextHeaderApiAdapter"),
        careActivityLoader: c.resolve("CareActivityLoader"),
    }))
);