import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import * as Styles from "./PatientAppointmentsBody.less";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import {
    LocationParticipantDto,
    PractitionerParticipantDto,
    RegisteredPatientParticipantDto,
    AppointmentDto,
    ServiceRequestSubjectDto, SchedulingServiceSubjectDto
} from "@HisPlatform/BoundedContexts/Scheduling/Api/Proxy.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import * as Ui from "@CommonControls";
import { getIconNameByHealthcareProfessions } from "@HisPlatform/BoundedContexts/Organization/Components/Helpers/IconTypeNameHelper";
import { Moment } from "moment";
import PointOfCare from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/PointOfCare";
import MomentLabel from "@CommonControls/MomentLabel";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import PatientId from "@Primitives/PatientId.g";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import _ from "@HisPlatform/Common/Lodash";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import ServiceRequestDefinition from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestDefinition";
import IServiceRequestDataService from "@HisPlatform/BoundedContexts/Care/Services/Definition/ServiceRequestManagement/IServiceRequestDataService";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import SchedulingReferenceDataStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingReferenceDataStore";
import SchedulingServiceSubjectStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulingServiceSubjectStore";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessContent from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessContent";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import ActionBoundButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundButton";
import ShowNewAdmitPatientScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowNewAdmitPatientScreenAction.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import ShowPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientScreenAction.g";

interface IPatientAppointmentsBodyDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    careReferenceDataStore: CareReferenceDataStore;
    serviceRequestDataService: IServiceRequestDataService;
    schedulingReferenceDataStore: SchedulingReferenceDataStore;
}

interface IPatientAppointmentsBodyProps {
    _dependencies?: IPatientAppointmentsBodyDependencies;
    rawJsonValue: string;
    editUnregisteredPatientAppointment: (appointment: any) => void;
    editPatientAppointment: (appointment: any) => void;
    editPatientServiceRequest: (appointment: any) => void;
}

interface IPatientAppointmentItem {
    id: AppointmentScheduleEntryId;
    from: Moment;
    patientId: PatientId;
    isDataCleansingNeeded: boolean;
    locationId: OrganizationUnitId;
    location: PointOfCare;
    practitionerIds: PractitionerId[];
    practitioners: PractitionerStore[];
    subjectId: SchedulingServiceId | IEntityVersionSelector<ServiceRequestId>;
    subjectType: "SchedulingService" | "ServiceRequest";
    schedulingService: SchedulingServiceSubjectStore;
    serviceRequestIdentifier: string;
    serviceRequestDefinition: ServiceRequestDefinition;
    starterCareActivityId: CareActivityId;
}

@State.observer
class PatientAppointmentsBody extends React.Component<IPatientAppointmentsBodyProps> {

    private get organizationReferenceDataStore() { return this.props._dependencies.organizationReferenceDataStore; }
    private get careReferenceDataStore() { return this.props._dependencies.careReferenceDataStore; }
    private get serviceRequestDataService() { return this.props._dependencies.serviceRequestDataService; }
    private get schedulingReferenceDataStore() { return this.props._dependencies.schedulingReferenceDataStore; }

    @State.observable.ref private patientAppointmentItems: IPatientAppointmentItem[] = null;

    public componentDidMount() {
        dispatchAsyncErrors(this.initializePanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IPatientAppointmentsBodyProps) {
        if (this.props.rawJsonValue !== prevProps.rawJsonValue) {
            this.loadAsync();
        }
    }

    private initializePanelAsync = createInitialPanelLoader(this.loadAsync);

    @State.bound
    private async loadAsync() {

        const rawValue = JSON.parse(this.props.rawJsonValue);

        const isDataCleansingNeeded = rawValue && rawValue.IsDataCleansingNeeded as boolean;
        const appointmentsRawValue = rawValue && rawValue.Appointments;

        const patientAppointments: IPatientAppointmentItem[] = appointmentsRawValue && appointmentsRawValue.map((pa: any) => {
            const dto = AppointmentDto.fromJS(pa);

            let subjectId;
            let subjectType;

            if (dto.subject instanceof SchedulingServiceSubjectDto) {
                subjectId = (dto.subject as SchedulingServiceSubjectDto).schedulingServiceId;
                subjectType = "SchedulingService";
            } else {
                subjectId = new EntityVersionSelector((dto.subject as ServiceRequestSubjectDto).serviceRequestId, LocalDate.today());
                subjectType = "ServiceRequest";
            }

            return {
                id: dto.id,
                from: dto.interval.from,
                patientId: (dto.participants.find((p: any) => p instanceof RegisteredPatientParticipantDto) as RegisteredPatientParticipantDto)?.patientId,
                isDataCleansingNeeded: isDataCleansingNeeded,
                locationId: (dto.participants.find((p: any) => p instanceof LocationParticipantDto) as LocationParticipantDto).organizationUnitId,
                location: null,
                practitionerIds: dto.participants.filter((p: any) => p instanceof PractitionerParticipantDto).map(i => (i as PractitionerParticipantDto).practitionerId),
                practitioners: [],
                subjectId: subjectId, // TODO use date of appt creation?
                subjectType: subjectType,
                schedulingService: null,
                serviceRequestDefinition: null,
                serviceRequestIdentifier: "",
                starterCareActivityId: null
            } as IPatientAppointmentItem;
        });

        if (!!patientAppointments) {
            const locationSelectors = patientAppointments.map(pa => pa.locationId).filter(l => !isNullOrUndefined(l));
            const practitionerSelectors = _.flatten(patientAppointments.map(i => i.practitionerIds)).filter(p => !isNullOrUndefined(p));
            const schedulingServiceSelectors = patientAppointments.filter(pa => pa.subjectType === "SchedulingService").map(pa => pa.subjectId as SchedulingServiceId);
            const serviceRequestSelectors = patientAppointments.filter(pa => pa.subjectType === "ServiceRequest").map(pa => pa.subjectId);

            await this.organizationReferenceDataStore.pointOfCareMap.ensureLoadedAsync(locationSelectors);
            await this.loadHealthcareProfessionsAsync(locationSelectors);
            await this.organizationReferenceDataStore.practitionerMap.ensureLoadedAsync(practitionerSelectors);
            await this.schedulingReferenceDataStore.schedulingServices.ensureLoadedAsync(schedulingServiceSelectors);
            const serviceRequests = await Promise.all(serviceRequestSelectors.map(sr => this.serviceRequestDataService.getServiceRequestByIdAsync((sr as IEntityVersionSelector<ServiceRequestId>).id, null, false, false)));

            const serviceRequestDefMap = new Map<string, { starterCareActivity: CareActivityId, definitionId: IEntityVersionSelector<ServiceRequestDefinitionId> }>();

            serviceRequests.forEach(sr => {
                serviceRequestDefMap.set(
                    sr.id.value,
                    { starterCareActivity: sr.starterCareActivityId, definitionId: sr.serviceRequestDefinitionVersion });
            });

            const serviceRequestDefinitionIds = _.uniq(Array.from(serviceRequestDefMap.values()).map(v => v.definitionId));

            await this.careReferenceDataStore.serviceRequestDefinition.ensureLoadedAsync(serviceRequestDefinitionIds);

            patientAppointments.forEach(pa => {
                pa.location = this.organizationReferenceDataStore.pointOfCareMap.get(pa.locationId);

                pa.practitionerIds.forEach(i => {
                    const practitioner = this.organizationReferenceDataStore.practitionerMap.get(i);
                    pa.practitioners.push(practitioner);
                });
                if (pa.subjectType === "SchedulingService") {
                    pa.schedulingService = this.schedulingReferenceDataStore.schedulingServices.get(pa.subjectId as SchedulingServiceId);
                } else {
                    pa.serviceRequestDefinition = this.careReferenceDataStore.serviceRequestDefinition.get(serviceRequestDefMap.get((pa.subjectId as IEntityVersionSelector<ServiceRequestId>).id.value).definitionId);
                    pa.serviceRequestIdentifier = serviceRequests.find(s => s.id.value === (pa.subjectId as IEntityVersionSelector<ServiceRequestId>).id.value).serviceRequestIdentifier;
                    pa.starterCareActivityId = serviceRequestDefMap.get((pa.subjectId as IEntityVersionSelector<ServiceRequestId>).id.value).starterCareActivity;
                }
            });
            this.set(patientAppointments);
        }
    }

    @State.bound
    public renderShowNewAdmitPatientScreenButton(appointment: IPatientAppointmentItem) {
        let action = null;
        
        if(appointment.isDataCleansingNeeded) {
            action = ActionDescriptor.fromAction(new ShowPatientScreenAction(ScreenDisplayMode.Full, appointment.patientId, null));
        }
        else {            
            let serviceRequestId: ServiceRequestId = null;
            if(appointment.subjectType === "ServiceRequest") {
                serviceRequestId = (appointment.subjectId as IEntityVersionSelector<ServiceRequestId>).id;
            }
            action = ActionDescriptor.fromAction(new ShowNewAdmitPatientScreenAction(ScreenDisplayMode.Full, appointment.patientId, appointment.id, serviceRequestId));
        }

        if (action  == null)
            return <></>;

        return (
            <ActionBoundButton iconName="add_healthcare" actionDescriptor={action} automationId={appointment.id.value + "_editButton"} />
        );
    }

    @State.bound
    private async loadHealthcareProfessionsAsync(organizationUnitIds: OrganizationUnitId[]) {
        const ids = _.flatten(organizationUnitIds.map(this.organizationReferenceDataStore.pointOfCareMap.get).map(item => item.healthcareProfessionIds));
        const uniqIds = _.uniqBy(ids, item => item.value);
        await this.organizationReferenceDataStore.healthCareProfession.ensureLoadedAsync(uniqIds);
    }

    @State.action
    private set(patientAppointmentItems: IPatientAppointmentItem[]) {
        this.patientAppointmentItems = patientAppointmentItems;
    }

    @State.bound
    private editAppointment(appointment: IPatientAppointmentItem) {
        return (event: React.MouseEvent) => {
            event.stopPropagation();
            if (isNullOrUndefined(appointment.patientId?.value)) {
                this.props.editUnregisteredPatientAppointment({
                    id: appointment.id
                });
            } else if (appointment.subjectType === "SchedulingService") {
                this.props.editPatientAppointment({
                    id: appointment.id,
                    patientId: appointment.patientId
                });
            } else {
                this.props.editPatientServiceRequest({
                    careActivityId: appointment.starterCareActivityId?.value,
                    filter: "Patient",
                    serviceRequestId: (appointment.subjectId as IEntityVersionSelector<ServiceRequestId>).id.value,
                    skipInitialReadOnlyMode: false
                });
            }
        };
    }

    @State.bound
    private renderButtons(appointment: IPatientAppointmentItem) {
        return (
            <>
                <Ui.Button
                    onClick={this.editAppointment(appointment)}
                    size="standard"
                    iconName="pen"
                    automationId={appointment.id.value + "_editButton"}
                />
                {this.renderShowNewAdmitPatientScreenButton(appointment)}
            </>
        );
    }

    public render() {
        if (this.initializePanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessContent />;
        }

        if (!this.patientAppointmentItems) {
            return null;
        }
        return (
            <div className={Styles.container}>
                {this.patientAppointmentItems.map(pa =>
                    <div className={Styles.itemCard} key={pa.id.value}>
                        <div className={Styles.dateField}>
                            <Ui.Icon className={Styles.icon} iconName="appointments" size="normal" visualStyle="primary" />
                            <MomentLabel from={pa.from} />
                        </div>
                        {!!pa.location && <div className={Styles.locationField}>
                            <Ui.Icon className={Styles.icon}
                                iconName={getIconNameByHealthcareProfessions(pa.location.healthcareProfessionIds.map(this.props._dependencies.organizationReferenceDataStore.healthCareProfession.get))}
                                size="normal" visualStyle="primary" />
                            <p title={pa.location.name}>{pa.location.shorthand}</p>
                        </div>}
                        {!!pa.schedulingService && <div className={Styles.serviceField}>
                            <Ui.Icon className={Styles.icon} iconName={"editService"} size="normal" visualStyle="primary" />
                            <p className={Styles.doctorCode}>{pa.schedulingService.code}</p>&nbsp;
                            <p title={pa.schedulingService.name} >{pa.schedulingService.name}</p>
                        </div>}
                        {!!pa.serviceRequestDefinition && <div className={Styles.serviceField}>
                            <Ui.Icon className={Styles.icon} iconName={"stethoscope"} size="normal" visualStyle="primary" />
                            <p className={Styles.doctorCode}>{pa.serviceRequestIdentifier}</p>&nbsp;
                            <p title={pa.serviceRequestDefinition.shortName}>{pa.serviceRequestDefinition.shortName}</p>
                        </div>}
                        <div className={Styles.practitionerField}>
                            <Ui.Icon className={Styles.icon} iconName={"careType_familyDoctor"} size="normal" visualStyle="primary" />
                            {pa.practitioners.map(i =>
                                <React.Fragment key={i.code ?? i.id.value}>
                                    {!!i.code &&
                                        <>
                                            <p className={Styles.doctorCode}>{i.code}</p>&nbsp;
                                        </>
                                    }
                                    <Ui.PersonNameLabel personName={i.baseData.name} />
                                </React.Fragment>
                            )}
                        </div>
                        {this.renderButtons(pa)}
                    </div>
                )}
            </div>
        );
    }
}

export default connect(
    PatientAppointmentsBody,
    new DependencyAdapter<IPatientAppointmentsBodyProps, IPatientAppointmentsBodyDependencies>((container) => {
        return {
            organizationReferenceDataStore: container.resolve("OrganizationReferenceDataStore"),
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            serviceRequestDataService: container.resolve("IServiceRequestDataService"),
            schedulingReferenceDataStore: container.resolve("SchedulingReferenceDataStore")
        };
    })
);
