import { IValidationProblemParameterMapper } from "@Toolkit/CommonWeb/ApiAdapter/IValidationProblemParameterMapper";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import Di from "@Di";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import _ from "@HisPlatform/Common/Lodash";
import CommonReferenceDataDataStore from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/CommonReferenceDataDataStore";
import HunFinanceReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Finance/ApplicationLogic/Model/HunFinanceReferenceDataStore";
import IdentifierTypeId from "@Primitives/IdentifierTypeId.g";

@Di.injectable()
export default class HunSocialSecurityMedicationRequestValidationParameterMapper implements IValidationProblemParameterMapper {

    constructor(
        @Di.inject("HunFinanceReferenceDataStore") private financeReferenceDataStore: HunFinanceReferenceDataStore
    ) { }

    public collectValidationProblemParameterReferenceData(vp: IClientValidationProblem) {
        return;
    }

    public async loadCollectedValidationProblemParameterReferenceDataAsync(): Promise<void> {
        await this.financeReferenceDataStore.identifierTypeMap.ensureLoadedAsync();
    }

    public resolveValidationProblemParameters(vp: IClientValidationProblem): { [id: string]: string; } {
        let results = {};
        if (!isNullOrUndefined(vp.parameters?.IdentifierTypeId)) {
            const localizedEnum = this.financeReferenceDataStore.identifierTypeMap.get(new IdentifierTypeId(vp.parameters?.IdentifierTypeId.Value));
            const displayValue = `${vp.parameters?.IdentifierTypeId.Value} - ${localizedEnum?.displayValue.Name}`;
            
            results = {
                ...results,
                IdentifierTypeId: displayValue
            };
        }
        return results;
    }
}