import React, { useRef, useCallback } from "react";
import { IControlledSearchBoxProps } from "@CommonControls/SearchBox";
import { CSSTransition } from "react-transition-group";
import * as Ui from "@CommonControls";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Styles from "./SearchBox.less";


const ControlledSearchBox: React.FC<IControlledSearchBoxProps> = (props) => {

    const ref = useRef(null);

    const focusInput = useCallback(() => {
        if (!isNullOrUndefined(ref.current)) {
            ref.current.focus();
        }
    }, [ref]);

    return (
        <div style={{ display: "flex", flexDirection: "row" }} className={Styles.searchboxContainer}>
            <Ui.Icon onClick={props.onIconClick} iconName={props.iconName} visualStyle={props.iconVisualStyle} automationId={props.automationId + "Icon"} />
            <div style={{ flex: "1" }}>
                <Ui.TextBox
                    ref={ref}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    onEnterKeyDown={props.onEnterKeyDown}
                    visualMode={props.visualMode}
                    size={props.size}
                    automationId={props.automationId + "TextBox"}
                    style={props.style}
                    stopClickPropagation={props.stopClickPropagation}
                    required={props.isRequired}
                />
            </div>
        </div>
    );
};

ControlledSearchBox.defaultProps = {
    focusOnRender: true
};

export default ControlledSearchBox;