import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import IObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/IObservation";
import ScalarObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/ScalarObservation";
import Quantity from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/Quantity";
import TextualObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/TextualObservation";
import CategoryObservation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/Observations/CategoryObservation";
import RequestedServiceId from "@Primitives/RequestedServiceId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export function getObservationCreationDto(requestedServiceId: RequestedServiceId, observation: IObservation) {
    if (observation instanceof ScalarObservation) {
        return new Proxy.ScalarObservationCreationDto({
            observationDefinitionId: observation.observationDefinitionId,
            requestedServiceId: requestedServiceId,
            patientId: observation.patientId,
            careActivityId: observation.careActivityId,
            quantity: mapQuantity(observation.quantity),
            remarks: observation.remarks,
            rawValue: observation.rawValue
        });
    } else if (observation instanceof TextualObservation) {
        return new Proxy.TextualObservationCreationDto({
            observationDefinitionId: observation.observationDefinitionId,
            requestedServiceId: requestedServiceId,
            patientId: observation.patientId,
            careActivityId: observation.careActivityId,
            content: observation.contentStore.getContent()
        });
    } else if (observation instanceof CategoryObservation) {
        return new Proxy.CategoryObservationCreationDto({
            observationDefinitionId: observation.observationDefinitionId,
            requestedServiceId: requestedServiceId,
            patientId: observation.patientId,
            careActivityId: observation.careActivityId,
            categories: observation.categories.map(c => new Proxy.Category({ key: c.key, value: c.value }))
        });
    }

    throw new Error("Unknown derived IObservation type.");
}

export function getObservationUpdateDto(observation: IObservation) {
    if (observation instanceof ScalarObservation) {
        return new Proxy.ScalarObservationUpdateDto({
            observationId: observation.observationId,
            rowVersion: observation.rowVersion,
            quantity: mapQuantity(observation.quantity),
            remarks: observation.remarks,
            rawValue: observation.rawValue
        });
    } else if (observation instanceof TextualObservation) {
        return new Proxy.TextualObservationUpdateDto({
            observationId: observation.observationId,
            rowVersion: observation.rowVersion,
            content: observation.contentStore.getContent()
        });
    } else if (observation instanceof CategoryObservation) {
        return new Proxy.CategoryObservationUpdateDto({
            observationId: observation.observationId,
            rowVersion: observation.rowVersion,
            categories: observation.categories.map(c => new Proxy.Category({ key: c.key, value: c.value }))
        });
    }

    throw new Error("Unknown derived IObservation type.");
}

function mapQuantity(quantity: Quantity): Proxy.Quantity {
    if (isNullOrUndefined(quantity)) {
        return null;
    }
    return new Proxy.Quantity({
        amount: new Proxy.Numeric({
            value: quantity.amount
        }),
        units: new Proxy.Unit({
            name: quantity.units.name,
            shortCode: quantity.units.shortCode,
        })
    });
}

function getObservationId(observation: IObservation) {
    return isNullOrUndefined(observation.observationId) ? null : observation.observationId;
}
