import React from "react";
import ITitleGroup from "./ITitleGroup";
import Styles from "./TitleGroup.less";

interface ITitleGroupProps {
    titles: ITitleGroup[];
    containerWidth?: string;
    onClick?: (id: string) => void;
    currentlySelectedId?: string;
}

export default class TitleGroup extends React.Component<ITitleGroupProps> {

    private getClassNameByState(elementId: string) {
        if (this.props.currentlySelectedId === elementId) {
            return Styles.selected;
        }
        return null;
    }

    private getCircleClassNameByState(elementId: string) {
        if (this.props.currentlySelectedId === elementId) {
            return Styles.selectedCircle;
        }
        return null;
    }

    private onClickHandler(elementId: string) {
        return () => this.props.onClick(elementId);
    }

    public render() {
        return (
            <div style={{ width: this.props.containerWidth }} className={Styles.container}>
                {this.props.titles.map(t => {
                    return (
                        <ul key={t.elementId}>
                            <li onClick={this.onClickHandler(t.elementId)} className={this.getClassNameByState(t.elementId)} key={t.elementId}><div className={Styles.textContainer}><svg><circle className={this.getCircleClassNameByState(t.elementId)} cx="8" cy="10" r="7" strokeWidth="1" /></svg><p className={Styles.title}>{t.label}</p></div></li>
                            {t.childrenTitles && t.childrenTitles.map(c => {
                                return <li onClick={this.onClickHandler(c.elementId)} className={this.getClassNameByState(c.elementId)} key={c.elementId}><div className={Styles.textContainer}><p className={Styles.childTitle}>{c.label}</p></div></li>;
                            })}
                        </ul>
                    );
                })}
            </div>
        );
    }
}