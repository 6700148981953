import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { isNullOrEmpty } from "./NullCheckHelpers";
import moment from "moment";

export interface IDateRange {
    from: LocalDate;
    to: LocalDate;
}

export default class LocalDateRange {

    
    public static Unbound = new LocalDateRange(null, null);

    public get from() {
        return this._fromValue;
    }

    public get to() {
        return this._toValue;
    }

    constructor(
        private _fromValue: LocalDate = null,
        private _toValue: LocalDate = null
    ) {
    }

    public stringify(): string {
        return `${this.from ? this.from.stringify() : ""};${this.to ? this.to.stringify() : ""}`;
    }

    public static parse(val: string): LocalDateRange {
        const pieces = val && val.split(";");
        if (pieces && pieces.length === 2) {
            return new LocalDateRange(LocalDate.parse(pieces[0]), LocalDate.parse(pieces[1]));
        }
        return  null;
    }

    public toJSON() {
        return {
            From: this.from,
            To: this.to
        };
    }

    public isUnbound() {
        return !this.to && !this.from;
    }

    public static fromJS(data: any) {
        return data ? new LocalDateRange(LocalDate.fromJS(data.From), LocalDate.fromJS(data.To)) : null;
    }

    // Simple implementation. Use a library for something more powerful.
    public overlaps(other: LocalDateRange, options = { touchingIsOverlap: false }) {
        const lessThan = options.touchingIsOverlap ? (date1: LocalDate, date2: LocalDate) => date1.lessThanOrEquals(date2) : (date1: LocalDate, date2: LocalDate) => date1.lessThan(date2);

        // fully open interval always overlaps with another one        
        if (isNullOrEmpty(this.from) && (isNullOrEmpty(this.to)) || (isNullOrEmpty(other.from) && isNullOrEmpty(other.to))) {
            return true;
        }

        // intervals open on the same end always overlap
        if ((isNullOrEmpty(this.to) && isNullOrEmpty(other.to)) || (isNullOrEmpty(this.from) && isNullOrEmpty(other.from))) {
            return true;
        }

        // intervals open on opposite ends
        if (isNullOrEmpty(this.to) && isNullOrEmpty(other.from)) {
            return lessThan(this.from, other.to);
        }
        if (isNullOrEmpty(other.to) && isNullOrEmpty(this.from)) {
            return lessThan(other.from, this.to);
        }

        // this is fully defined, other is open-ended
        if (this.to !== null && this.from !== null && (isNullOrEmpty(other.from) || isNullOrEmpty(other.to))) {
            if (isNullOrEmpty(other.to)) {
                return lessThan(other.from, this.to);
            } else { // isNullOrEmpty(other.from)
                return lessThan(this.from, other.to);
            }
        }

        // other is fully defined, this is open-ended
        if (other.to !== null && other.from !== null && (isNullOrEmpty(this.from) || isNullOrEmpty(this.to))) {
            if (isNullOrEmpty(this.to)) {
                return lessThan(this.from, other.to);
            } else { // isNullOrEmpty(other.from)
                return lessThan(other.from, this.to);
            }
        }

        // both fully defined
        return lessThan(this.from, other.to) && lessThan(other.from, this.to);
    }

    public includes(date: LocalDate) {
        if (isNullOrEmpty(date)) {
            return false;
        }

        return (
            ((!this.from || this.from.isEmpty()) || this.from.lessThanOrEquals(date))
            && (!this.to || this.to.isEmpty() || this.to.greaterThanOrEquals(date))
        );
    }

    public includesMoment(time: moment.Moment) {
        const date = LocalDate.createFromMoment(time);
        return this.includes(date);
    }

    public getHashCode() {
        return `${this.from?.toUnix()}-${this.to?.toUnix()}`;
    }
}