import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import _ from "@HisPlatform/Common/Lodash";

export function stringifyVersionSelectors<TId extends IStringEntityId>(versionSelectors: Array<IEntityVersionSelector<TId>>) {
    const stringifiedVersionSelectors: string[] = [];

    versionSelectors.forEach((vs) => {
        if (!vs.validOn || !vs.validOn.stringify) {
            throw new Error("Api adapter expects validOn to be set in versionSelector.");
        }
        stringifiedVersionSelectors.push(vs.validOn.stringify() + "|" + vs.id.value);
    });

    return _.uniq(stringifiedVersionSelectors).join(";");
}