import React from "react";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import Money from "@Toolkit/CommonWeb/Model/Money";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface IMoneyLabelDependencies {
    localizationService: ILocalizationService;
}

interface IMoneyLabelProps {
    value: Money;
    _dependencies?: IMoneyLabelDependencies;
}

class MoneyLabel extends React.PureComponent<IMoneyLabelProps> {
    
    public render() {
        return (
            <p style={{ textAlign: "right" }}>{this.props._dependencies.localizationService.localizeMoney(this.props.value)}</p>
        );
    }

}

export default connect(
    MoneyLabel,
    new DependencyAdapter<IMoneyLabelProps, IMoneyLabelDependencies>( (container) => {
        return {
            localizationService: container.resolve<ILocalizationService>("ILocalizationService")
        };
    })
);