import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import OrganizationIdTypeUnion from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Structure/OrganizationUnitIdTypeUnion";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import MomentRange from "@Toolkit/CommonWeb/Model/MomentRange";
import DiagnosisStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisStore";

export function mapPatientCareActivityDataDtoToStore(r: Proxy.PatientCareActivityDataDto): PatientsCareActivityStore {
    const newStore = new PatientsCareActivityStore();

    newStore.careActivityId = r.careActivityId;
    newStore.beginningOfCare = r.beginningOfCare;
    newStore.dischargedAt = r.dischargedAt;
    newStore.careActivityState = r.state;
    newStore.primaryCareIdentifier = r.primaryCareIdentifier;
    newStore.primaryParticipantId = r.primaryParticipantId;
    newStore.organizationUnitId = r.organizationUnitId ? new PointOfCareId(r.organizationUnitId.value.toString()) : null;
    newStore.careActivitySource = r.careActivitySource;
    newStore.careTypeId = r.careTypeId;

    newStore.referralDoctorId = r.referralDoctorId;
    newStore.referralWorkplaceId = r.referralWorkplaceVersionSelector && new EntityVersionSelector<ExternalLocationId>(
        r.referralWorkplaceVersionSelector.entityId,
        r.referralWorkplaceVersionSelector.validOn
    );

    newStore.carePeriod = new MomentRange(newStore.beginningOfCare, newStore.dischargedAt);
    newStore.accessLevel = r.accessLevel;
    newStore.diagnoses = r.diagnoses && r.diagnoses.map(itemDto => {
        const item = new DiagnosisStore();
        item.id = itemDto.id;
        item.use = itemDto.use;
        item.conditionVersionSelector = { id: itemDto.conditionVersion.entityId, validOn: itemDto.conditionVersion.validOn };
        item.lateralityId = itemDto.lateralityId;
        return item;
    }) || [];

    newStore.referralDoctorCode = r.referralDoctorCode;
    newStore.primaryParticipantCode = r.primaryParticipantCode;
    newStore.referralWorkplaceCode = r.referralWorkplaceCode;
    newStore.pointOfCareCode = r.pointOfCareCode;

    newStore.extensionData = r.extensionData;

    return newStore;
}
