import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";

export default class NavMenuSeparatorItem implements INavMenuItem {
    private constructor(
        public readonly identifier: string,
        public readonly parentIdentifier: string,
        public readonly order: number,
        public readonly side?: "left" | "right",
    ) {
    }

    public static createChild (
        parentIdentifier: string,
        order: number
    ) {
        return new NavMenuSeparatorItem(null, parentIdentifier, order, null);
    }

    public static createTopLevel (
        side: "left" | "right",
        order: number
    ) {
        return new NavMenuSeparatorItem(null, null, order, side);
    }
}
