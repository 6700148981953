import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IReferralStore from "@PluginInterface/BoundedContexts/Care/CareRegister/CareActivityBaseData/IReferralStore";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import PractitionerId from "@Primitives/PractitionerId.g";
import LateralityId from "@Primitives/LateralityId.g";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import ConditionId from "@Primitives/ConditionId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class ReferralStore implements IReferralStore {
    @State.observable.ref public referralCreationDate: LocalDate = null;
    @State.observable.ref public referralDoctorId: PractitionerId = null;
    @State.observable.ref public referralWorkplace: EntityVersionSelector<ExternalLocationId> = new EntityVersionSelector<ExternalLocationId>(null, LocalDate.createEmpty());
    @State.observable.ref public referralCareIdentifier: string = null;
    @State.observable.ref public referralDiagnosisId1: ConditionId = null;
    @State.observable.ref public referralDiagnosisLateralityId1: LateralityId = null;
    @State.observable.ref public referralDiagnosisId2: ConditionId = null;
    @State.observable.ref public referralDiagnosisLateralityId2: LateralityId = null;

    @State.action.bound
    public update(referralStore: ReferralStore) {
        this.setCreatedAt(referralStore?.referralCreationDate);
        this.setDoctorId(referralStore?.referralDoctorId);
        this.setWorkPlace(referralStore?.referralWorkplace.id);
        this.setReferralCareIdentifier(referralStore?.referralCareIdentifier);
        this.setReferralDiagnosisId1(referralStore?.referralDiagnosisId1);
        this.setReferralDiagnosisId2(referralStore?.referralDiagnosisId2);
    }

    @State.action.bound
    public setCreatedAt(creationDate: LocalDate) {
        if (isNullOrUndefined(creationDate)) {
            this.referralWorkplace = this.referralWorkplace.withId(null);
        }
        this.referralWorkplace = this.referralWorkplace.withValidOn(creationDate);
        this.referralCreationDate = creationDate;
    }

    @State.action.bound
    public setDoctorId(id: PractitionerId) {
        this.referralDoctorId = id;
    }

    @State.action.bound
    public clearDoctorId() {
        this.referralDoctorId = null;
    }

    @State.action.bound
    public setWorkPlace(id: ExternalLocationId) {
        this.referralWorkplace = this.referralWorkplace.withId(id);
    }

    @State.action.bound
    public setReferralCareIdentifier(newValue: string) {
        this.referralCareIdentifier = newValue;
    }

    @State.action.bound
    public setReferralDiagnosisId1(id: ConditionId) {
        if (!ValueWrapper.equals(this.referralDiagnosisId1, id)) {
            this.referralDiagnosisId1 = id;
            this.referralDiagnosisLateralityId1 = null;
        }
    }

    @State.action.bound
    public setReferralDiagnosisId2(id: ConditionId) {
        if (!ValueWrapper.equals(this.referralDiagnosisId2, id)) {
            this.referralDiagnosisId2 = id;
            this.referralDiagnosisLateralityId2 = null;
        }
    }
}