import LockIndicatorComponent from "@HisPlatform/Components/HisPlatformControls/LockIndicatorComponent";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import FormPanel from "@Toolkit/FormEngine/Panels/FormPanel/FormPanel";
import ActionBoundButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundButton";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import { ScreenStateContextAdapter, ScreenStateContextStore } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import ToolbarButtons from "@Toolkit/ReactClient/Components/ToolbarButtons/ToolbarButtons";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import React from "react";
import ShowCreateNewInvoiceScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowCreateNewInvoiceScreenAction.g";
import ShowInvoiceScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowInvoiceScreenAction.g";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { provideInvoiceScreenStore, useInvoiceScreenStore } from "./InvoiceScreenStoreProvider";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import ValidationResultSummary from "@CommonControls/ValidationResultSummary";
import InfoBox from "@CommonControls/InfoBox";
import InvoiceState from "@HisPlatform/BoundedContexts/Finance/ApplicationLogic/Model/Finance/InvoiceState";

export interface IInvoiceScreenProps extends IScreenPropsBase<ShowCreateNewInvoiceScreenAction | ShowInvoiceScreenAction> {
    _screenState?: ScreenStateContextStore;
    _modalService?: IModalService;
}

function InvoiceScreen(props: IInvoiceScreenProps) {
    const store = useInvoiceScreenStore();

    const { localizationService } = useDependencies(c => ({
        localizationService: c.resolve<IToolkitLocalizationService>("IToolkitLocalizationService")
    }));

    return (
        <Screen
            title={StaticFinanceResources.InvoiceScreen.Title}
            toolbar={
                <>
                    <ToolbarButtons
                        beforeButtons={store.isEditInvoiceScreen && store.canAcquireLock && (
                            <LockIndicatorComponent locked={true} onLoadClickedAsync={store.loadAsync} lockedBy={store.lockInfo?.preventingLockUserId} preventingLockId={store.lockInfo?.preventingLockId} />
                        )}
                        onCancelAsync={store.onCancelAsync}
                        betweenButtons={<>
                            {!store.canAcquireLock && (
                                <>
                                    <ActionBoundButton
                                        actionDescriptor={store.saveInvoiceAction}
                                        onExecuteAsync={store.onSaveAsync}
                                        disabled={store.vIsReadOnly || store.logicInProgress}
                                        text={localizationService.staticResources.button.save}
                                        automationId="__save"
                                    />
                                    <ActionBoundButton
                                        actionDescriptor={store.saveInvoiceAction}
                                        onExecuteAsync={store.onFinalizeAsync}
                                        disabled={store.vIsReadOnly || store.logicInProgress}
                                        text={StaticFinanceResources.InvoiceScreen.Buttons.Finalize}
                                        visualStyle="primary"
                                        automationId="__finalize"
                                    />
                                    <ActionBoundButton
                                        actionDescriptor={store.deleteInvoiceAction}
                                        onExecuteAsync={store.onDeleteAsync}
                                        disabled={store.invoiceState !== InvoiceState.Draft || store.logicInProgress}
                                        text={StaticFinanceResources.InvoiceScreen.Buttons.Delete}
                                        visualStyle="negative"
                                        iconName="trash"
                                        automationId="__delete"
                                    />
                                </>
                            )}
                        </>}
                        saveActionsDescriptor={store.saveInvoiceAction}
                    />
                </>
            }>
            <ValidationBoundary
                entityTypeName="Invoice"
                validationResults={store.validationResults}
                onValidateAsync={store.onValidateAsync}
                validateOnMount>
                {
                    store.showInfoBox && <InfoBox iconName="info_with_circle">
                        {store.mustFinalizeInvoiceOnFulfillmentDay && <li>{StaticFinanceResources.InvoiceScreen.Warnings.MustFinalizeInvoiceOnFulfilmentDay}</li>}
                        {store.mustFinalizeInvoiceIn8Days && <li>{StaticFinanceResources.InvoiceScreen.Warnings.MustFinalizeInvoiceIn8Days}</li>}
                    </InfoBox>
                }
                <ValidationResultSummary results={store.highlightedValidationProblems} />
                <FormPanel
                    definitionId={store.formDefinitionId}
                    form={store.form}
                    onChange={store.onDataChangeAsync.fireAndForget}
                    isReadOnly={store.vIsReadOnly}
                />
            </ValidationBoundary>
        </Screen>
    );
}


export default connect<IInvoiceScreenProps>(
    provideInvoiceScreenStore(InvoiceScreen),
    new ScreenStateContextAdapter(),
    new HisModalServiceAdapter()
);