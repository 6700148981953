import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import * as Ui from "@CommonControls";
import EhiDocumentListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiDocumentListItem";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import DataGridDateTimeColumn from "@CommonControls/DataGrid/Column/DataGridDateTimeColumn";
import DataGridExtensibleEnumColumn from "@CommonControls/DataGrid/Column/DataGridExtensibleEnumColumn";
import PractitionerColumn, { PractitionerColumnDisplayMode } from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PractitionerColumn/PractitionerColumn";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import EhiHealthcareProviderOrganizationUnitColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiHealthcareProviderOrganizationUnitColumn/EhiHealthcareProviderOrganizationUnitColumn";
import EhiError from "@HunEHealthInfrastructurePlugin/Model/DomainModel/EhiError";
import EhiErrorSummary from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiErrorSummary";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import { DisplayMode } from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareColumn/PointOfCareColumn";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
interface IEhiDocumentsListPanelDependencies {
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    localizationService: ILocalizationService;
}

interface IEhiDocumentsListPanelProps extends IHasMasterDetailState {
    _dependencies?: IEhiDocumentsListPanelDependencies;

    extraFilter?: React.ReactNode;
    isExtraFilterVisible?: boolean;
    dataSource: InMemoryDataGridDataSource;
    errorSource: EhiError[];
    selectedDocumentId: string;
    onSelectedDocumentIdChange: (newDoc: EhiDocumentListItem) => void;
    onDownloadAsync?: (doc: EhiDocumentListItem) => Promise<void>;
    isCompact?: boolean;
    isLoading: boolean;
}

/** @screen */
@State.observer
class EhiDocumentsListPanel extends React.Component<IEhiDocumentsListPanelProps> {

    @State.observable private isLoading: boolean = false;

    private get hunEhiCareReferenceDataStore() { return this.props._dependencies!.hunEhiCareReferenceDataStore; }
    private get organizationReferenceDataStore() { return this.props._dependencies!.organizationReferenceDataStore; }
    private get localizationService() { return this.props._dependencies!.localizationService; }

    @State.boundLoadingState("isLoading")
    private async _initialLoadPanelAsync() {
        await this.hunEhiCareReferenceDataStore.ehiDocumentTypes.ensureLoadedAsync();
        const practitionerIds = (this.props.dataSource?.data.items as EhiDocumentListItem[]).map(it => it.doctorId);
        await this.organizationReferenceDataStore.doctorMap.ensureLoadedAsync(practitionerIds);
        const organizationUnitIds = (this.props.dataSource?.data.items as EhiDocumentListItem[]).map(it => it.organizationUnitId);
        await this.hunEhiCareReferenceDataStore.ehiHealthcareProviderOrganizationUnits.ensureLoadedAsync(organizationUnitIds);
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this._initialLoadPanelAsync);

    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    public componentDidUpdate(prevProps: IEhiDocumentsListPanelProps) {
        if (this.props.dataSource !== prevProps.dataSource) {
            dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
        }
    }

    @State.action.bound
    private setSelected(rawId: string) {
        this.props.onSelectedDocumentIdChange(this.getRowById(rawId));
    }

    @State.bound
    private getRowById(rowId: string) {
        if (isNullOrUndefined(rowId)) {
            return null;
        }
        const rowById = (this.props.dataSource?.data.items as EhiDocumentListItem[]).find(it => it.ehiId === rowId);
        return rowById;
    }

    private ehiOrganizationUnitFallbackValueRenderer(row: EhiDocumentListItem, displayMode: DisplayMode) {
        switch (displayMode) {
            case "shorthand":
                return row?.ehiOrganizationUnit?.code;
            case "name":
                return row?.ehiOrganizationUnit?.name;
            case "shorthand-name":
            default:
                return <>{row?.ehiOrganizationUnit?.code} - {row?.ehiOrganizationUnit?.name}</>;
        }
    }

    @State.bound
    private doctorFallbackValueRenderer(row: EhiDocumentListItem, displayMode: PractitionerColumnDisplayMode, isCodeBold: boolean) {
        switch (displayMode) {
            case "code":
                return isCodeBold ? <b>{row?.ehiDoctor?.stampCode}</b> : row?.ehiDoctor?.stampCode;
            case "name":
                return this.localizationService.localizePersonName(row?.ehiDoctor?.name);
            case "code-name":
            default:
                return <>{isCodeBold ? <b>{row?.ehiDoctor?.stampCode}</b> : row?.ehiDoctor?.stampCode} - {this.localizationService.localizePersonName(row?.ehiDoctor?.name)}</>;
        }
    }

    private renderExtraFilter() {
        return (
            <>
                {this.props.extraFilter}
                <EhiErrorSummary
                    errors={this.props.errorSource}
                />
            </>
        );
    }
    public render() {
        return (
            <>
                <Ui.DataGrid
                    extraFilter={this.renderExtraFilter()}
                    isExtraFilterVisible={this.props.isExtraFilterVisible}
                    dataSource={this.props.dataSource}
                    rowComparer="rowId"
                    rowId="ehiId"

                    onSelectedRowChange={this.setSelected}
                    selectedRow={this.props.selectedDocumentId ?? null}
                    rowHeight={35}
                    isSelectable
                    fixedLayout
                    fixedHeight={"100%"}
                    generateInitialFilterStore
                    changeOnMount
                    isLoading={this.props.isLoading}
                    automationId="ehiDocumentListPanel_dataGrid"
                >
                    <DataGridDateTimeColumn
                        id="timeStamp"
                        dataGetter={"timeStamp"}
                        header={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Documents.Labels.Timestamp}
                        isOrderable />
                    <DataGridExtensibleEnumColumn
                        dataGetter={"ehiDocumentTypeId"}
                        extensibleEnumStore={this.hunEhiCareReferenceDataStore.ehiDocumentTypes}
                        header={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Documents.Labels.DocumentType}
                    />
                    <EhiHealthcareProviderOrganizationUnitColumn
                        dataGetter={"organizationUnitId"}
                        header={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Documents.Labels.OrganizationUnit}
                        fallbackValueRenderer={this.ehiOrganizationUnitFallbackValueRenderer}
                        showHint={false}
                        isFilterable
                    />
                    <PractitionerColumn
                        dataGetter={"doctorId"}
                        fallbackValueRenderer={this.doctorFallbackValueRenderer}
                        header={StaticHunEHealthInfrastructureCareResources.EhiCareActivityDetails.Documents.Labels.CareDoctor}
                        isCodeBold
                        isVisible={!this.props._masterDetailState.isDetailOpen} />
                </Ui.DataGrid>
            </>
        );
    }
}

export default connect(
    EhiDocumentsListPanel,
    new DependencyAdapter<IEhiDocumentsListPanelProps, IEhiDocumentsListPanelDependencies>(c => ({
        hunEhiCareReferenceDataStore: c.resolve("HunEhiCareReferenceDataStore"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService")
    }))
);