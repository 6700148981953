import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import { IMedicationEquipmentSelectorDialogResult, IMedicationEquipmentSelectorDialogParams } from "./MedicationEquipmentSelectorDialogParams";
import MedicationId from "@Primitives/MedicationId.g";
import MedicationListPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/MedicationListPanel/MedicationListPanel";
import INDataUseCaseState from "@HisPlatform/BoundedContexts/Productivity/Components/NDataPanel/INDataUseCaseState";
import MedicationSubsidyOptionList from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/PrescriptionListPanel/MedicationSubsidyOptionList";
import ReferenceDataApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/ReferenceDataApiAdapter";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import MedicationSubsidy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationSubsidy";
import MedicationPricingAndSubsidies from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/MedicationPricingAndSubsidies";
import MedicationRequestReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/MedicationRequestReferenceDataStore";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import MedicationEquivalenceListNDataPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/MedicationEquivalenceListNDataPanel/MedicationEquivalenceListNDataPanel";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import { IExtensionPointProps } from "@PluginInterface/ExtensionPoint/IExtensionPointProps";
import MedicationFilterStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Panels/MedicationListPanel/Filter/MedicationFilterStore";
import _ from "@HisPlatform/Common/Lodash";
import MedicationsApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ReferenceData/MedicationsApiAdapter";
import ITextualPrescriptionTemplateListItem from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/ITextualPrescriptionTemplateListItem";
import PrescriptionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionApiAdapter";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import SubsidyOptionCheckResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/SubsidyOptionCheckResult";
import PricingAndSubsidyLoader from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Services/PricingAndSubsidyLoader";
import ISearchParametersService from "@Toolkit/CommonWeb/SearchParametersService/Definition/ISearchParametersService";
import { IPrescriptionsToBeCreatedListItem } from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrescriptionsToBeCreated/IPrescriptionsToBeCreatedListItem";
import MedicationTypeId from "@Primitives/MedicationTypeId.g";
import LoadingBoundary from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import MedicationSubsidyOptionCheckType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Api/ReferenceData/Prescription/Enum/MedicationSubsidyOptionCheckType.g";

interface IMedicationEquipmentSelectorDialogDependencies {
    referenceDataAdapter: ReferenceDataApiAdapter;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    medicationRequestReferenceDataStore: MedicationRequestReferenceDataStore;
    careReferenceDataStore: CareReferenceDataStore;
    medicationsApiAdapter: MedicationsApiAdapter;
    prescriptionApiAdapter: PrescriptionApiAdapter;
    notificationService: INotificationService;
    pricingAndSubsidyLoader: PricingAndSubsidyLoader;
    searchParametersService: ISearchParametersService;
}

interface IMedicationEquipmentSelectorDialogProps extends IModalComponentParams<IMedicationEquipmentSelectorDialogResult>, IMedicationEquipmentSelectorDialogParams {
    _dependencies?: IMedicationEquipmentSelectorDialogDependencies;
}

export enum PrescriptionType {
    Medication,
    Equipment,
    TextualFormula,
    OtherTextual,
    Permanent
}

/** @screen */
@State.observer
class MedicationEquipmentSelectorDialog extends React.Component<IMedicationEquipmentSelectorDialogProps> {

    @State.observable private selectedRowId: string = null;
    @State.observable private useCaseState: INDataUseCaseState = null;
    @State.observable private equivalenceListSelectedRowId: string = null;
    @State.observable private equivalenceListUseCaseState: INDataUseCaseState = null;
    @State.observable.ref public medicationReferenceData: MedicationPricingAndSubsidies = null;
    @State.observable.ref public filterStore: MedicationFilterStore = null;
    @State.observable private selectedItems: IPrescriptionsToBeCreatedListItem[] = [];

    private get referenceDataAdapter() { return this.props._dependencies.referenceDataAdapter; }
    private get organizationReferenceDataStore() { return this.props._dependencies.organizationReferenceDataStore; }
    private get medicationRequestReferenceDataStore() { return this.props._dependencies.medicationRequestReferenceDataStore; }
    private get pricingAndSubsidyLoader() { return this.props._dependencies.pricingAndSubsidyLoader; }

    private get validOn() {
        return this.props.validOn || LocalDate.today();
    }

    @State.observable public prescriptionType: PrescriptionType = PrescriptionType.Medication;

    @State.observable.ref public textualPrescriptionTemplateList: ITextualPrescriptionTemplateListItem[] = [];

    @State.observable.ref private subsidyOptionCheckResults: SubsidyOptionCheckResult[] = [];

    @State.action.bound
    public setTextualPrescriptionTemplateList(textualPrescriptionTemplateList: ITextualPrescriptionTemplateListItem[]) {
        this.textualPrescriptionTemplateList = textualPrescriptionTemplateList;
    }

    @State.action.bound
    public setPrescriptionType(prescriptionType: PrescriptionType) {
        this.prescriptionType = prescriptionType;
    }

    @State.action.bound
    private setSubsidyOptionCheckResults(optionCheckResults: SubsidyOptionCheckResult[]) {
        this.subsidyOptionCheckResults = optionCheckResults;
    }

    @State.computed.valueWrapper
    public get selectedMedicationId() {
        return this.selectedRowId ? new MedicationId(this.selectedRowId) : null;
    }

    @State.computed.valueWrapper
    public get selectedClaimTypeId() {
        return this.filterStore?.medicationSubsidyClaimTypeId;
    }

    @State.action.bound
    public selectMedicationId(row: any, rowId: string) {
        this.selectedRowId = rowId;
        if (rowId) {
            this.loadMedicationReferenceDataAsync(new MedicationId(rowId));
        }
    }

    @State.action.bound
    public selectEquivalenceListMedicationId(row: any, rowId: string) {
        this.equivalenceListSelectedRowId = rowId;
        if (rowId) {
            this.loadMedicationReferenceDataAsync(new MedicationId(rowId));
        }
    }

    @State.action.bound
    private setUseCaseState(rowId: string, useCaseState: INDataUseCaseState) {
        if (this.selectedRowId === rowId && _.isEqual(this.useCaseState, useCaseState)) {
            this.useCaseState = null;
            this.selectedRowId = null;
        } else {
            this.useCaseState = useCaseState;
            this.selectedRowId = rowId;
        }
    }

    @State.action.bound
    private setEquivalenceListUseCaseState(rowId: string, useCaseState: INDataUseCaseState) {
        if (this.equivalenceListSelectedRowId === rowId && _.isEqual(this.equivalenceListUseCaseState, useCaseState)) {
            this.equivalenceListUseCaseState = null;
            this.equivalenceListSelectedRowId = null;
        } else {
            this.equivalenceListUseCaseState = useCaseState;
            this.equivalenceListSelectedRowId = rowId;
        }
    }

    @State.action.bound
    public setMedicationReferenceData(medicationReferenceData: MedicationPricingAndSubsidies) {
        this.medicationReferenceData = medicationReferenceData;
    }

    @State.action.bound
    public setFilterStore(filterStore: MedicationFilterStore) {
        this.filterStore = filterStore;
    }

    @State.bound
    private async loadMedicationReferenceDataAsync(medicationId: MedicationId) {
        const medicationReferenceData = await this.referenceDataAdapter.getMedicationPricingAndSubsidiesAsync(medicationId, this.validOn);
        await this.organizationReferenceDataStore.professionalExamMap.ensureAllLoadedAsync();
        await this.medicationRequestReferenceDataStore.medicationSubsidyClaimTypes.ensureAllLoadedAsync();
        await this.medicationRequestReferenceDataStore.organizationRestrictionTypes.ensureLoadedAsync();
        await this.medicationRequestReferenceDataStore.medicationSubsidizedPricingTypes.ensureLoadedAsync();
        this.setMedicationReferenceData(medicationReferenceData.value);
        await this.pricingAndSubsidyLoader.loadReferenceDataAsync(medicationReferenceData.value, this.validOn);
    }

    @State.bound
    private onFilterStoreCreated(store: MedicationFilterStore) {
        this.setFilterStore(store);
        store.setMedicationType(MedicationTypeId.S);
        const searchTerm = this.props._dependencies.searchParametersService.get("searchTerm", null);
        if (!!searchTerm) {
            store.setGeneralSearch(searchTerm);
        }
    }

    @State.bound
    private onSelectMedication(medicationId: MedicationId) {
        this.props.onClose({ medicationId } as IMedicationEquipmentSelectorDialogResult);
    }

    public render() {
        return (
            <>
                <HisUi.ContextAwareModal
                    title={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.ModalTitle}
                    isOpen
                    onClose={this.cancel}
                    size="fullscreen">
                    <LoadingBoundary>
                        <Ui.Modal.Body>
                            <MedicationListPanel
                                selectedRowId={this.selectedRowId}
                                useCaseState={this.useCaseState}
                                onChange={this.setUseCaseState}
                                onRowClick={this.selectMedicationId}
                                onSelectMedication={this.onSelectMedication}
                                onFilterStoreCreated={this.onFilterStoreCreated}
                                variant="equipment"
                                fullHeight
                                prescriptionType={this.prescriptionType}
                                setPrescriptionType={this.setPrescriptionType}
                                selectedMedications={this.selectedItems}
                                validOn={this.validOn}
                                addMedicationFormulaPrescription={null}
                            />
                            {!!this.selectedRowId && <Ui.GroupBox title={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.EquivalentProducts}>
                                <MedicationEquivalenceListNDataPanel
                                    medicationId={this.selectedMedicationId}
                                    claimTypeId={this.selectedClaimTypeId}
                                    subsidies={this.medicationReferenceData?.subsidies}
                                    onSelectMedication={this.onSelectMedication}
                                    selectedRowId={this.equivalenceListSelectedRowId}
                                    useCaseState={this.equivalenceListUseCaseState}
                                    onChange={this.setEquivalenceListUseCaseState}
                                    onRowClick={this.selectEquivalenceListMedicationId}
                                    validOn={this.validOn}
                                />
                            </Ui.GroupBox>}
                            {!!this.selectedRowId && <Ui.GroupBox title={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.GroupTitles.SubsidyOptionRestrictions}>
                                {this.medicationReferenceData?.subsidies?.some(s => s.options?.length > 0) ?
                                    this.medicationReferenceData.subsidies.map(s => this.renderSubsidy(s))
                                    : StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Detail.NoSubsidyOptionsMessage}
                            </Ui.GroupBox>}
                        </Ui.Modal.Body>
                        <Ui.Modal.Footer>
                            <Ui.Button onClick={this.cancel} visualStyle={"flat"} text={StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.Buttons.cancel} float="left" automationId="cancelButton" />
                        </Ui.Modal.Footer>
                    </LoadingBoundary>
                </HisUi.ContextAwareModal>
                <HisUi.HisHeaderPortal>
                    <HisPlatformExtensionPoint
                        type="curativeSupplyPassInfoExtension"
                        extensionProps={{} as IExtensionPointProps}
                    >
                        <></>
                    </HisPlatformExtensionPoint>
                </HisUi.HisHeaderPortal>
            </>
        );
    }

    @State.bound
    private renderSubsidy(subsidy: MedicationSubsidy) {
        return (
            <MedicationSubsidyOptionList
                key={subsidy.id.value}
                subsidy={subsidy}
                medicationId={this.selectedMedicationId}
                careActivityId={this.props.careActivityId}
                checkType={MedicationSubsidyOptionCheckType.Prescription}
                optionCheckResults={this.subsidyOptionCheckResults}
                setOptionCheckResults={this.setSubsidyOptionCheckResults}
                claimTypeId={this.filterStore?.medicationSubsidyClaimTypeId}
            />
        );
    }

    @State.bound
    private cancel() {
        this.props.onClose(null);
    }
}

export default connect(
    MedicationEquipmentSelectorDialog,
    new DependencyAdapter<IMedicationEquipmentSelectorDialogProps, IMedicationEquipmentSelectorDialogDependencies>(c => ({
        referenceDataAdapter: c.resolve("ReferenceDataApiAdapter"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        medicationRequestReferenceDataStore: c.resolve("MedicationRequestReferenceDataStore"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        medicationsApiAdapter: c.resolve("MedicationsApiAdapter"),
        prescriptionApiAdapter: c.resolve("PrescriptionApiAdapter"),
        notificationService: c.resolve("INotificationService"),
        pricingAndSubsidyLoader: c.resolve("PricingAndSubsidyLoader"),
        searchParametersService: c.resolve("ISearchParametersService")
    }))
);
