import config from "@Config";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import StaticFormEditingResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import { LockToolbarComponent } from "@HisPlatform/Components/HisPlatformControls";
import ShowSearchPatientScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowSearchPatientScreenAction.g";
import ApplicationInfoModalMenu from "@HisPlatform/Packages/Settings/Components/ApplicationInfoModalMenu";
import AssecoRoomsSpikeMenu from "@HisPlatform/Packages/Settings/Components/AssecoRoomsSpikeMenu";
import CultureMenu from "@HisPlatform/Packages/Settings/Components/CultureMenu";
import DevCareWorklistMenu from "@HisPlatform/Packages/Settings/Components/DevCareWorklistMenu";
import FeedbackMenu from "@HisPlatform/Packages/Settings/Components/FeedbackMenu";
import LoginMenu from "@HisPlatform/Packages/Settings/Components/LoginMenu";
import ShowDeveloperScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowDeveloperScreenAction.g";
import ShowDocumentTemplateManagementScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowDocumentTemplateManagementScreenAction.g";
import ShowFormDefinitionsScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowFormDefinitionsScreenAction.g";
import ShowInsurerOrganizationsScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowInsurerOrganizationsScreenAction.g";
import ShowWorklistDefinitionsScreenAction from "@HisPlatform/Packages/Settings/FrontendActions/ShowWorklistDefinitionsScreenAction.g";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import NavMenuActionItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuActionItem";
import NavMenuCustomItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuCustomItem";
import NavMenuGroupItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuGroupItem";
import NavMenuSeparatorItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuSeparatorItem";

export function getNavigationMenuItems(): INavMenuItem[] {

    const leftTopLevelMenuItems: INavMenuItem[] = [
        NavMenuCustomItem.createTopLevel("ApplicationInfo", ApplicationInfoModalMenu, "left", 1)
    ];

    const rightTopLevelMenuItems: INavMenuItem[] = [
        NavMenuCustomItem.createTopLevel("Feedback", FeedbackMenu, "right", 2),
        NavMenuGroupItem.createTopLevel(
            "Settings",
            {
                side: "right",
                automationId: "dataManagement",
                order: 3,
                iconNameType: "cog"
            }
        ),
        NavMenuCustomItem.createTopLevel("Culture", CultureMenu, "right", 4),
        NavMenuCustomItem.createTopLevel("Login", LoginMenu, "right", 7)
    ];

    const childMenuItems: INavMenuItem[] = [
        // Settings group
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "InsurerOrganizations",
            "Settings",
            new ShowInsurerOrganizationsScreenAction(),
            "UpdateInsurerOrganizations",
            {
                automationId: "insurerManagement",
                text: StaticFinanceResources.InsurerOganizations.Title,
                order: 1
            }
        ),
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "DocumentTemplates",
            "Settings",
            new ShowDocumentTemplateManagementScreenAction(),
            "UpdateDocumentTemplates",
            {
                automationId: "documentTemplate",
                text: StaticDocumentManagementResources.TemplateManagement.Title,
                order: 8
            }
        ),
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "FormDefinitions",
            "Settings",
            new ShowFormDefinitionsScreenAction(),
            "GetFormDefinitions",
            {
                automationId: "formDefinitions",
                text: StaticFormEditingResources.FormDefinitionList.Title,
                order: 11
            }
        ),
        NavMenuActionItem.createChildWithPermissionOperationCheck(
            "WorklistDefinitions",
            "Settings",
            new ShowWorklistDefinitionsScreenAction(),
            "WorklistDefinitions",
            {
                automationId: "worklistDefinitions",
                text: StaticProductivityResources.Worklist.Definition.EditorTitle,
                order: 12
            }
        )
    ];

    if (config.testFeatures.technicalControlsEnabled) {
        rightTopLevelMenuItems.splice(0, 0, NavMenuGroupItem.createTopLevel(
            "DeveloperTools",
            {
                side: "right",
                automationId: "developerTools",
                order: 1,
                iconNameType: "dev"
            }
        ));

        childMenuItems.splice(0, 0,
            NavMenuActionItem.createChild(
                "DeveloperPage",
                "DeveloperTools",
                new ShowDeveloperScreenAction(),
                {
                    automationId: "developerPage",
                    text: StaticWebAppResources.Navbar.MainMenu.TechPage,
                    order: 1
                }
            ),
            NavMenuActionItem.createChild(
                "DeveloperPatientSearch",
                "DeveloperTools",
                new ShowSearchPatientScreenAction(ScreenDisplayMode.Full),
                {
                    automationId: "patientSearch2",
                    text: "DEV: Páciens kereső screen",
                    order: 2
                }
            ),
            NavMenuCustomItem.createChild("DeveloperCareWorklist", "DeveloperTools", DevCareWorklistMenu, 3),
            NavMenuCustomItem.createChild("AssecoRoomsSpike", "DeveloperTools", AssecoRoomsSpikeMenu, 4),
            NavMenuCustomItem.createChild("LockToolbar", "DeveloperTools", LockToolbarComponent, 5),
        );
    }

    return [...leftTopLevelMenuItems, ...rightTopLevelMenuItems, ...childMenuItems];
}

