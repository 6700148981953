import MedicationId from "@Primitives/MedicationId.g";
import SubstanceId from "@Primitives/SubstanceId.g";
import InternationalNonproprietaryNameId from "@Primitives/InternationalNonproprietaryNameId.g";

export class MedicationStatementSubstanceCodeSelectorItem {
    public id: MedicationId | SubstanceId | InternationalNonproprietaryNameId;
    public name?: string;
    public code?: string;
    public text?: string;

    constructor({ id = null, name = "", code = "",  text = "" }: { id?: MedicationId | SubstanceId | InternationalNonproprietaryNameId,  name?: string, code?: string, text?: string }) {
        this.id = id;
        this.name = name;
        this.text = text;
        this.code = code;
    }
}