import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import UserId from "@Primitives/UserId.g";

export interface IUserMultiSelectDialogParams extends IModalParams {
    currentUserId: UserId;
    alreadySelectedIds: UserId[];
    title: string;
}

export interface IUserMultiSelectDialogResult {
    selectedUserIds: UserId[];
}

export default class UserMultiSelectDialogParams implements IUserMultiSelectDialogParams {

    public static type = "UserMultiSelectDialog";
    public get type() { return UserMultiSelectDialogParams.type; }

    constructor(
        public currentUserId: UserId = null,
        public alreadySelectedIds: UserId[] = [],
        public title: string = ""
    ) {}
}