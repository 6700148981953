exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Option_icon-container_2kR7i {\n  width: 30px;\n}\n.Option_icon_2btvE {\n  float: right;\n  fill: white;\n  top: -1px;\n}\n.Option_option-text_OUlDq {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  width: calc(100% - 30px);\n}\n", ""]);

// exports
exports.locals = {
	"icon-container": "Option_icon-container_2kR7i",
	"iconContainer": "Option_icon-container_2kR7i",
	"icon": "Option_icon_2btvE",
	"option-text": "Option_option-text_OUlDq",
	"optionText": "Option_option-text_OUlDq"
};