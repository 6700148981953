import React from "react";
import Styles from "./Button.less";
import { iconNameType, iconVisualStyleType } from "@CommonControls/Icon";
import { ICommonControlProps, getStandardHtmlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import { Tooltip } from "@Toolkit/ReactClient/Components/Tooltip";
import * as Ui from "@CommonControls";
import CompositeClassName, { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { createAsyncErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import ViewContextAdapter from "@Toolkit/ReactClient/Components/ViewContext/ViewContextAdapter";
import IToolkitViewContextSettings from "@Toolkit/ReactClient/Components/ViewContext/IToolkitViewContextSettings";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PermissionCheckContextAdapter from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextAdapter";
import ReadOnlyContextAdapter from "@Toolkit/ReactClient/Components/ReadOnlyContext/ReadOnlyContextAdapter";

export type visualStyleType = "standard" | "primary" | "secondary" | "negative" | "link" | "positive" | "flat" | "negative-standard" | "slate";

interface IButtonDependencies {
    notificationService: INotificationService;
    localizationService: IToolkitLocalizationService;
}

export interface IButtonProps extends ICommonControlProps {
    _dependencies?: IButtonDependencies;
    _permissionDenied?: boolean;
    visualStyle?: visualStyleType;
    text?: string;
    iconName?: iconNameType;
    iconTextOrder?: "icon-text" | "text-icon";
    disabled?: boolean;
    size?: "standard" | "compact";
    float?: "left" | "right";
    fluid?: boolean;
    strong?: boolean;
    active?: boolean;
    automationId: string;
    stopPropagation?: { leftClick: boolean; } | boolean;
    iconVisualStyle?: iconVisualStyleType;
    visualMode?: "normal" | "dark" | "inverted";
    verticalAlign?: "normal" | "input";

    isNotPermitted?: boolean;
    onNotPermittedClickAsync?: () => Promise<void>;
    permissionCheckOperationNames?: string;
    permissionDeniedStyle?: "disabled" | "invisible";
}

export interface ISimpleButtonProps extends IButtonProps {
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    onClickAsync?: (event: React.MouseEvent<HTMLElement>) => Promise<any>;
    onMouseDown?: (event: React.MouseEvent<HTMLElement>) => void;
    onMouseUp?: (event: React.MouseEvent<HTMLElement>) => void;
    children?: React.ReactNode;
}

@State.observer
class Button extends React.Component<ISimpleButtonProps> {

    @State.observable private isLoading = false;
    private runAsync = createAsyncErrorDispatcher(this);

    private getClassForVisualStyle(visualStyle: visualStyleType) {
        switch (visualStyle) {
            case "standard":
                return Styles.normal;
            case "primary":
                return Styles.primary;
            case "secondary":
                return Styles.secondary;
            case "negative":
                return Styles.negative;
            case "negative-standard":
                return Styles.negativeNormal;
            case "positive":
                return Styles.positive;
            case "link":
                return Styles.link;
            case "flat":
                return Styles.flat;
            case "slate":
                return Styles.slate;
            default:
                throw Error("Unknown visual style!");
        }
    }

    private getClassName = (props: ISimpleButtonProps, readOnly: boolean) => {

        const isReadOnly = readOnly || props.disabled || (props._permissionDenied && props.permissionDeniedStyle === "disabled");
        const isCompact = props.size === "compact";
        const isDarkMode = props.visualMode === "dark";
        const isInverted = props.visualMode === "inverted";
        const isInputAligned = props.verticalAlign === "input";

        const className = combineClasses(
            this.props.isNotPermitted ? Styles.normal : this.getClassForVisualStyle(props.visualStyle),
            props.active && Styles.active,
            isCompact && Styles.compactSize,
            props.fluid && Styles.fluid,
            props.strong && Styles.strong,
            isDarkMode && Styles.darkMode,
            isInverted && Styles.inverted,
            isInputAligned && Styles.verticalAlignInput);

        return className;
    };

    public static defaultProps: Partial<ISimpleButtonProps> = {
        size: "standard",
        visualStyle: "standard",
        disabled: false,
        // inverted: false,
        tooltipTextAlign: "center",
        iconTextOrder: "icon-text",
        visualMode: "normal",
        verticalAlign: "normal"
    };

    constructor(props: ISimpleButtonProps) {
        super(props);
        if (this.props.onClick !== undefined && this.props.onClickAsync !== undefined) {
            throw new Error("You cannot use both onClick and onClickAsync props on a button!");
        }
    }

    private getIconDisabledState() {
        if (this.props.visualMode === "inverted") {
            return false;
        }
        return this.props.disabled;
    }

    public getIconSize = (props: ISimpleButtonProps, hasContent: boolean): string => {
        if (!hasContent && this.props.size !== "compact") {
            return "largeish";
        }
        switch (this.props.size) {
            case "compact":
                return this.props.visualStyle === "link" ? "compact" : "normal";
            case "standard":
                return "normal";
        }
        throw new Error("Invalid button size: " + this.props.size);
    };

    public getIconClassName = (props: ISimpleButtonProps, hasContent: boolean) => {
        const classes = new CompositeClassName();
        classes.addIf(this.props.size === "standard", hasContent ? Styles.iconContentWithTextStandard : Styles.iconContentOnlyStandard);
        classes.addIf(this.props.size === "compact", hasContent ? Styles.iconContentWithTextCompact : Styles.iconContentOnlyCompact);
        classes.addIf(this.props.size === "compact" && this.props.iconTextOrder === "text-icon", Styles.iconContentWithTextCompactIconAfterText);
        classes.addIf(this.props.size === "standard" && this.props.iconTextOrder === "text-icon", Styles.iconContentWithTextStandardIconAfterText);
        classes.addIf(this.props.iconName === "remove", Styles.deleteIconColor);
        return classes.classNames;
    };

    private renderIconText(text: string) {
        return <span className={Styles.iconText}>{text}</span>;
    }

    private renderIcon(hasContent: boolean) {
        const iconSize = this.getIconSize(this.props, hasContent) as any;
        return (
            <Ui.Icon
                className={this.getIconClassName(this.props, hasContent)}
                iconName={this.props.iconName}
                size={iconSize}
                disabled={this.props.disabled || this.getIconDisabledState()}
                automationId={`${this.props.automationId}_${this.props.iconName}`}
            />
        );
    }

    private renderContent = (props: ISimpleButtonProps) => {
        if (props.iconName) {
            if (props.text || props.children) {
                return (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {this.props.iconTextOrder === "text-icon" && (props.text ? this.renderIconText(props.text) : props.children)}
                        {this.renderIcon(true)}
                        {this.props.iconTextOrder === "icon-text" && (props.text ? this.renderIconText(props.text) : props.children)}
                    </div>);
            } else {
                return this.renderIcon(false);
            }
        }

        return props.text || props.children;
    };

    @State.action.bound
    private onClickHandler(event: React.MouseEvent<HTMLElement>): void {
        if (this.props.stopPropagation) {
            if (typeof this.props.stopPropagation === "boolean" || this.props.stopPropagation.leftClick) {
                event.stopPropagation();
            }
        }

        if (!this.props.disabled && !this.isLoading) {
            if (this.props.isNotPermitted) {
                if (this.props.onNotPermittedClickAsync) {
                    this.invokeAsyncOnClick(event, this.props.onNotPermittedClickAsync);
                } else {
                    this.props._dependencies.notificationService.error(
                        this.props._dependencies.localizationService.staticResources.common.unauthorizedOperation);
                }
            } else {
                this.invokeOnClick(event);
            }
        }
    }

    @State.action.bound
    private onMouseDown(event: React.MouseEvent<HTMLElement>): void {
        if (this.props.stopPropagation) {
            if (typeof this.props.stopPropagation === "boolean" || this.props.stopPropagation.leftClick) {
                event.stopPropagation();
            }
        }

        if (!this.props.disabled && !this.isLoading && !this.props.isNotPermitted) {
            this.invokeOnMouseDown(event);
        }
    }

    @State.action.bound
    private onMouseUp(event: React.MouseEvent<HTMLElement>): void {
        if (this.props.stopPropagation) {
            if (typeof this.props.stopPropagation === "boolean" || this.props.stopPropagation.leftClick) {
                event.stopPropagation();
            }
        }

        if (!this.props.disabled && !this.isLoading && !this.props.isNotPermitted) {
            this.invokeOnMouseUp(event);
        }
    }

    private invokeOnClick(event: React.MouseEvent<HTMLElement>) {
        if (this.props.onClickAsync) {
            this.invokeAsyncOnClick(event, this.props.onClickAsync);
        } else if (this.props.onClick) {
            this.props.onClick(event);
        }
    }

    private invokeAsyncOnClick(event: React.MouseEvent<HTMLElement>, asyncClickHandler: (e: React.MouseEvent<HTMLElement>) => Promise<void>) {
        if (asyncClickHandler) {
            this.setLoadingState();

            this.runAsync(asyncClickHandler(event).finally(() => {
                this.setLoadedState();
            }));
        }
    }

    private invokeOnMouseDown(event: React.MouseEvent<HTMLElement>) {
        if (this.props.onMouseDown) {
            this.props.onMouseDown(event);
        }
    }

    private invokeOnMouseUp(event: React.MouseEvent<HTMLElement>) {
        if (this.props.onMouseUp) {
            this.props.onMouseUp(event);
        }
    }

    @State.action
    private setLoadingState() {
        this.isLoading = true;
    }

    @State.action
    private setLoadedState() {
        this.isLoading = false;
    }

    public render() {
        if (this.props._permissionDenied && this.props.permissionDeniedStyle === "invisible") {
            return (
                <>
                </>
            );
        }

        if (this.props.tooltipType === "native") {
            return this.renderButton();
        }

        return (
            <Tooltip
                content={this.props.tooltipContent}
                placement={this.props.tooltipPosition}
                contentAlignment={this.props.tooltipTextAlign}>
                {this.renderButton()}
            </Tooltip>
        );
    }

    private renderButton() {
        const htmlProps = getStandardHtmlProps(this.props);

        htmlProps.className = combineClasses(
            this.props.className,
            this.getClassName(this.props, this.props.disabled || this.isLoading),
        );

        htmlProps.style = {
            ...htmlProps.style,
            float: this.props.float
        };

        if (this.props.isNotPermitted) {
            htmlProps.style.marginRight = 10;
        }

        const tooltipText = this.props.tooltipType === "native" && typeof this.props.tooltipContent === "string"
            ? this.props.tooltipContent
            : undefined;

        return (
            <button
                type="button"
                data-automation-id={this.props.automationId || undefined}
                onClick={this.onClickHandler}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                {...htmlProps}
                disabled={this.props.disabled || this.props._permissionDenied && this.props.permissionDeniedStyle === "disabled"}
                title={tooltipText}>
                <div className={Styles.loadingContainer} >
                    {this.renderContent(this.props)}
                    {this.props.isNotPermitted && (
                        <Ui.Icon iconName="shield" style={{ position: "absolute", right: -17, top: -11 }} automationId="shield" />
                    )}
                </div>
            </button>
        );
    }
}

export default connect(
    Button,
    new DependencyAdapter<ISimpleButtonProps, IButtonDependencies>(c => ({
        localizationService: c.resolve("IToolkitLocalizationService"),
        notificationService: c.resolve("INotificationService")
    })),
    new ViewContextAdapter<ISimpleButtonProps, IToolkitViewContextSettings>((props, ctx) => ({
        size: ctx.compactControlHeight ? "compact" : props.size,
        text: ctx.compactControlWidth ? undefined : props.text,
        tooltipContent: props.tooltipContent ?? (ctx.compactControlWidth ? props.text : undefined),
        children: ctx.compactControlWidth ? undefined : props.children,
    })),
    new PermissionCheckContextAdapter(),
    new ReadOnlyContextAdapter((props, isReadOnly) => ({
        disabled: props.disabled || isReadOnly
    }))
);