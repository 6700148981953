import { FrontendActionPermissionScope } from "@Toolkit/ReactClient/ActionProcessing/FrontendActionPermissionScope";

export class PermittedFrontendActionPermissionScope {
    private constructor(
        public readonly type: string,
        public readonly mode: "positive" | "negative",
        public readonly includedValues: string[] | null,
        public readonly excludedValues: string[] | null
    ) { }

    public static createPositive(type: string, includedValues: string[]) {
        return new PermittedFrontendActionPermissionScope(type, "positive", includedValues, null);
    }

    public static createNegative(type: string, excludedValues: string[]) {
        return new PermittedFrontendActionPermissionScope(type, "negative", null, excludedValues);
    }

    public isSatisfiedBy(scope: FrontendActionPermissionScope) {
        if (this.type !== scope.type) {
            return false;
        }

        if (this.mode === "positive") {
            return scope.values.every(i => this.includedValues.includes(i));
        }

        if (this.mode === "negative") {
            return !scope.values.some(i => this.excludedValues.includes(i));
        }

        throw new Error(`Unknown mode: '${this.mode}'`);
    }
}
