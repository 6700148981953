import Button, { IButtonProps } from "@CommonControls/Button";
import IShowScreenActionCallContextParams from "@HisPlatform/Services/Definition/ActionProcessing/IShowScreenActionCallContextParams";
import { useScreenNavigationContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import { FrontendActionPermissionScope } from "@Toolkit/ReactClient/ActionProcessing/FrontendActionPermissionScope";
import { usePermissionScopes } from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/PermissionScopeProvider";
import React, { useCallback, useMemo } from "react";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";
import useModalService from "@Toolkit/ReactClient/Components/ModalService/useModalService";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import { useHisActionDispatcher } from "@HisPlatform/Common/FrontendActions/HisActionDispatcher";
import { actionVisualStyleToButton } from "@Toolkit/ReactClient/ActionProcessing/ActionVisualStyle";

interface INDataGlobalFrontendActionButtonProps extends IButtonProps {
    descriptor: ActionDescriptor;
    scopes?: FrontendActionPermissionScope[];
}

export default function NDataGlobalFrontendActionButton(props: INDataGlobalFrontendActionButtonProps) {

    const { authorizationService } = useDependencies(c => ({
        authorizationService: c.resolve<AuthorizationService>("AuthorizationService")
    }));

    const action = props.descriptor.action;

    const contextScopes = usePermissionScopes();
    const isPermitted = useMemo(() => authorizationService.hasPermissionFor(
        action.id,
        ...[...props.descriptor.requiredPermissionScopes ?? [], ...contextScopes, ...(props.scopes ?? [])]
    ), [action.id, props.scopes]);

    const actionDispatcher = useHisActionDispatcher();
    const screenNavigationContext = useScreenNavigationContext();
    const modalService = useModalService();

    const clickAsync = useCallback(async () => {
        if (action instanceof ShowScreenFrontendActionBase) {
            await actionDispatcher.dispatchAsync<IShowScreenActionCallContextParams>(
                action,
                { navigationContext: screenNavigationContext, modalService, primaryScreenState: null }
            );
        } else {
            await actionDispatcher.dispatchAsync(action);
        }

    }, [action, screenNavigationContext, modalService, actionDispatcher]);

    const displaySettings = useMemo(() => actionDispatcher.getDisplaySettings(action), [action]);

    return (
        <Button
            text={displaySettings.displayName}
            iconName={displaySettings.iconName}
            tooltipContent={displaySettings.displayName}
            tooltipPosition={"bottom"}
            visualStyle={actionVisualStyleToButton(props.descriptor.presentation?.visualStyle)}
            disabled={props.descriptor.presentation?.isEnabled === false}
            {...props}
            isNotPermitted={!isPermitted}
            onClickAsync={clickAsync}
            stopPropagation
        />
    );
}