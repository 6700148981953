import React, { ReactNode } from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import * as Ui from "@CommonControls";
import EntityMultiSelectorStore from "./MultiEntitySelectorStore";
import IDataGridDataSource from "@CommonControls/DataGrid/DataSource/IDataGridDataSource";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IPagingState, DataGridLoadType, IOrderingState } from "@CommonControls/DataGrid/IDataGridProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";


interface IMultiEntitySelectorBaseDependencies {

}

export interface IMultiEntitySelectorPublicProps<TId extends IStringEntityId, TEntity extends EntityStoreBase<TId>> {
    orientation?: "horizontal" | "vertical";
    chosenItemsTableTitle?: string;
    allItemsTableTitle?: string;
    pageSize?: number;
    paging?: IPagingState;
    addButtonText?: string;
    removeButtonText?: string;
    automationId?: string;
    selectedItemsFilterStore?: any;
    selectedItemsPaging?: IPagingState;
    selectedItemsOnChange?: (
        type: DataGridLoadType,
        paging: IPagingState,
        ordering: IOrderingState | IOrderingState[],
        filter: any,
        columns: IDataGridColumnProps[]
    ) => Promise<void>;
    generateInitialFilterStore?: boolean;
    changeOnMount?: boolean;
    availablePageSizes?: number[];
    hidePager?: boolean;
    ignoreDisable?: boolean;
    rowHeight?: number | string;
}

export interface IMultiEntitySelectorBaseProps<TId extends IStringEntityId, TEntity extends EntityStoreBase<TId>> extends IMultiEntitySelectorPublicProps<TId, TEntity> {
    _dependencies?: IMultiEntitySelectorBaseDependencies;
    allItemsDataSource: TEntity[] | IPagedItems<TEntity> | IDataGridDataSource;
    allItemsOnChange?: (
        type: DataGridLoadType,
        paging: IPagingState,
        ordering: IOrderingState | IOrderingState[],
        filter: any,
        columns: IDataGridColumnProps[]
    ) => Promise<void>;
    allItemsIsLoading?: boolean;
    selectedItemsDataSource: TEntity[] | IPagedItems<TEntity> | IDataGridDataSource;
    renderColumns: (isSelectedGrid?: boolean) => React.ReactNode;
    renderSelectedColumns?: () => React.ReactNode;
    renderExtraRemoveButtons?: (value: any, row: TEntity) => ReactNode;
    canRemove?: (id: TId) => Promise<boolean>;
    onAdded?: (id: TEntity) => void;
    onRemoved?: (id: TEntity) => void;
    fixedHeight?: number | string;
}

@State.observer
class MultiEntitySelectorBase<TId extends IStringEntityId, TEntity extends EntityStoreBase<TId>> extends React.Component<IMultiEntitySelectorBaseProps<TId, TEntity>> {

    public static defaultProps: Partial<IMultiEntitySelectorBaseProps<any, any>> = {
        orientation: "horizontal"
    };

    private store: EntityMultiSelectorStore<TId, TEntity> = new EntityMultiSelectorStore<TId, TEntity>();

    constructor(props: IMultiEntitySelectorBaseProps<TId, TEntity>) {
        super(props);
        this.store.reInitDataSources(this.props);
    }

    public componentDidUpdate(prevProps: IMultiEntitySelectorBaseProps<TId, TEntity>) {
        if (prevProps.selectedItemsDataSource !== this.props.selectedItemsDataSource || prevProps.allItemsDataSource !== this.props.allItemsDataSource) {
            this.store.reInitDataSources(this.props);
        }
    }

    public render() {
        if (this.props.orientation === "horizontal") {
            return (
                <Ui.Flex automationId={this.props.automationId} style={{ height: this.props.fixedHeight }}>
                    <Ui.Flex.Item xs={6}  style={{ height: this.props.fixedHeight }}>
                        {this.renderAllItemsTable()}
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}  style={{ height: this.props.fixedHeight }}>
                        {this.renderChosenItemsTable()}
                    </Ui.Flex.Item>
                </Ui.Flex>
            );
        } else {
            return (
                <div data-automation-id={this.props.automationId || undefined}>
                    <div>{this.renderAllItemsTable()}</div>
                    <div>{this.renderChosenItemsTable()}</div>
                </div>
            );
        }
    }

    private renderAllItemsTable() {
        const columns = this.props.renderColumns(false);

        return (
            <Ui.DataGrid
                dataSource={this.store.allItemsDataSource}
                onChangeAsync={this.props.allItemsOnChange}
                automationId="__allItemsGrid"
                fixedLayout
                rowHeight={this.props.rowHeight || 44}
                generateInitialFilterStore={this.props.generateInitialFilterStore}
                changeOnMount={this.props.changeOnMount}
                availablePageSizes={this.props.availablePageSizes}
                fixedHeight={this.props.fixedHeight}
                hidePager={this.props.hidePager}
                paging={this.props.paging}
                showLoadingIndicator
                isLoading={this.props.allItemsIsLoading}
            >
                {columns}
                <DataGridColumn
                    id="add"
                    onRenderCellValue={this.renderAddColumn}
                    width="46px"
                    automationId="MoveButton"
                    cellTextAlign="center"
                />
            </Ui.DataGrid>
        );
    }

    private renderChosenItemsTable() {
        const renderFunc = this.props.renderSelectedColumns ? this.props.renderSelectedColumns : this.props.renderColumns;
        const columns = renderFunc(true);

        return (
            <Ui.DataGrid
                dataSource={this.store.selectedItemsDataSource}
                automationId="__chosenItemsGrid"
                filterStore={this.props.selectedItemsFilterStore}
                paging={this.props.selectedItemsPaging}
                fixedLayout
                rowHeight={this.props.rowHeight || 44}
                onChangeAsync={this.props.selectedItemsOnChange}
                generateInitialFilterStore={this.props.generateInitialFilterStore}
                changeOnMount={this.props.changeOnMount}
                availablePageSizes={this.props.availablePageSizes}
                fixedHeight={this.props.fixedHeight}
                hidePager={this.props.hidePager}
            >
                {columns}
                <DataGridColumn
                    id="remove"
                    onRenderCellValue={this.renderRemoveColumn}
                    width="46px"
                    automationId="MoveButton"
                    cellTextAlign="right"
                />
            </Ui.DataGrid>
        );
    }

    @State.bound
    private renderAddColumn(value: any, row: TEntity) {
        return (
            <Ui.Button
                onClickAsync={this.addAsyncHandlerFactory(row)}
                visualStyle="standard"
                disabled={!this.props.ignoreDisable && this.store.isItemSelected(row)}
                float="right"
                iconName="plus"
                size="compact"
                text={this.props.addButtonText}
                automationId="__moveItemButton"
                style={{ marginRight: 10 }}
            />
        );
    }

    @State.bound
    private renderRemoveColumn(value: any, row: TEntity) {
        return (
            <div>
                <Ui.Button
                    onClickAsync={this.removeAsyncHandlerFactory(row)}
                    visualStyle="negative-standard"
                    iconName="minus"
                    float="right"
                    size="compact"
                    text={this.props.removeButtonText}
                    automationId="__moveItemButton"
                    style={{ marginRight: 10 }}
                />
                {this.props.renderExtraRemoveButtons && this.props.renderExtraRemoveButtons(value, row)}
            </div>
        );
    }

    @State.bound
    private addAsyncHandlerFactory(row: TEntity) {
        return async () => {
            if (this.props.onAdded) {
                this.props.onAdded(row);
                this.props.allItemsOnChange ? await this.props.allItemsOnChange("changed", this.props.paging, null, null, null) : await this.store.reloadAsync();
            }
        };
    }

    @State.bound
    private removeAsyncHandlerFactory(row: TEntity) {
        return async () => {
            if (this.props.canRemove) {
                const canRemove = await this.props.canRemove(row.id);
                if (canRemove && this.props.onRemoved) {
                    this.props.onRemoved(row);
                    this.props.allItemsOnChange ? await this.props.allItemsOnChange("changed", this.props.paging, null, null, null) : await this.store.reloadAsync();
                }
            } else {
                this.props.onRemoved(row);
                this.props.allItemsOnChange ? await this.props.allItemsOnChange("changed", this.props.paging, null, null, null) : await this.store.reloadAsync();
            }
        };
    }
}

export default connect(
    MultiEntitySelectorBase,
    new DependencyAdapter<IMultiEntitySelectorBaseProps<any, any>, IMultiEntitySelectorBaseDependencies>(container => {
        return {};
    })
);
