import Di from "@Di";
import EditorScreenPanelStoreBase from "@Toolkit/CommonWeb/PanelStore/EditorScreenPanelStoreBase";
import { IEpisodeOfCareScreenProps } from "./EpisodeOfCareScreen";
import IForm from "@Toolkit/FormEngine/Model/IForm";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import LockAcquirerOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockAcquirerOperationInfo";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import LockingApiAdapter from "@HisPlatform/BoundedContexts/Locking/ApplicationLogic/ApiAdapter/Locking/LockingApiAdapter";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import EpisodeOfCareScreenApiAdapter from "./EpisodeOfCareScreenApiAdapter";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import ShowCreateNewEpisodeOfCareScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowCreateNewEpisodeOfCareScreenAction.g";
import ShowEpisodeOfCareScreenAction from "@HisPlatform/Packages/Care/FrontendActions/ShowEpisodeOfCareScreenAction.g";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import ReferencedEntityFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEntityFormFieldData";
import EpisodeOfCareId from "@Primitives/EpisodeOfCareId.g";
import IFormLogicRegistry from "@HisPlatform/Services/Definition/FormLogicRegistry/IFormLogicRegistry";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import IFormLogic from "@Toolkit/FormEngine/Model/IFormLogic";

@Di.injectable()
export default class EpisodeOfCareScreenStore extends EditorScreenPanelStoreBase<IEpisodeOfCareScreenProps> {
    @State.observable.ref public form: IForm = null;
    public formEngineDefinition: IFormDefinition;
    private formLogics: IFormLogic[] = [];

    @State.computed
    protected get showScreenAction() {
        return this.props.action;
    }

    @State.computed
    protected get contentToDirtyCheck() {
        return [this.form?.data.Content];
    }

    @State.computed
    public get vIsReadOnly() {
        return this.canAcquireLock;
    }

    @State.computed
    public get isCreateNewEpisodeOfCareScreen() {
        return this.props.action instanceof ShowCreateNewEpisodeOfCareScreenAction;
    }

    @State.computed
    public get isEpisodeOfCareScreen() {
        return this.props.action instanceof ShowEpisodeOfCareScreenAction;
    }

    constructor(
        @Di.inject("IDialogService") dialogService: IDialogService,
        @Di.inject("IToolkitLocalizationService") localizationService: IToolkitLocalizationService,
        @Di.inject("LockingApiAdapter") lockingApiAdapter: LockingApiAdapter,
        @Di.inject("INotificationService") notificationService: INotificationService,
        @Di.inject("EpisodeOfCareScreenApiAdapter") private episodeOfCareScreenApiAdapter: EpisodeOfCareScreenApiAdapter,
        @Di.inject("IFormLogicRegistry") private formLogicRegistry: IFormLogicRegistry,
        @Di.inject("IFormEngineReferenceDataStore") private formEngineReferenceDataStore: IFormEngineReferenceDataStore) {
        super(dialogService, notificationService, localizationService, lockingApiAdapter);
    }

    @State.bound
    public async loadCoreAsync() {

        if (this.isCreateNewEpisodeOfCareScreen) {
            const response = await this.episodeOfCareScreenApiAdapter.getNewEpisodeOfCareScreenDataAsync(
                (this.props.action as ShowCreateNewEpisodeOfCareScreenAction).patientId);

            this.setForm(response.result);
        } else {
            const requestLock = this.isEpisodeOfCareScreen;

            const response = await this.episodeOfCareScreenApiAdapter.getEpisodeOfCareScreenDataAsync(
                (this.props.action as ShowEpisodeOfCareScreenAction).episodeOfCareId, requestLock);
            if (response.operationInfo instanceof LockAcquirerOperationInfo) {
                State.runInAction(() => this.lockInfo = (response.operationInfo as LockAcquirerOperationInfo).lockInfo);
            }

            this.vSetValidationResults(response.result.validationResults);
            this.setForm(response.result);
        }

        this.formEngineDefinition = await this.formEngineReferenceDataStore.getOrLoadDefinitionByIdAsync(this.form.definitionId);

        this.formLogics = this.formLogicRegistry.getAll(this.formEngineDefinition.name);

        for (const formLogic of this.formLogics) {
            await formLogic.executeAsync(this.form);
        }
    }

    @State.bound
    public async onSaveAsync(): Promise<any> {
        if (this.isCreateNewEpisodeOfCareScreen) {
            return await this.onCreateAsync();
        } else {
            return await this.onUpdateAsync(false);
        }
    }

    @State.bound
    public async onCreateAsync(): Promise<any> {
        const saveResult = await this.handleSaveResultAsync(() => this.episodeOfCareScreenApiAdapter.createEpisodeOfCareAsync(this.form));

        if (saveResult.isPersisted) {
            const idField = getField<ReferencedEntityFormFieldData>(saveResult.result.data.Content, "EpisodeOfCareId");
            const newId = new EpisodeOfCareId(idField.value.toString());
            this.props._screenState.savedNew(newId, new ShowEpisodeOfCareScreenAction(this.props.action.displayMode, newId));
        }

        return saveResult.isPersisted;
    }

    @State.bound
    public async onUpdateAsync(releaseLock: boolean): Promise<any> {
        const saveResult =
            await this.handleSaveResultAsync<IForm>(() => this.episodeOfCareScreenApiAdapter.updateEpisodeOfCareAsync(this.form, releaseLock, this.lockInfo));

        if (saveResult.isPersisted && releaseLock) {
            State.runInAction(() => this.lockInfo = null);
            this.props._screenState.savedExisting();
        } else {
            this.setForm(saveResult.result);
            this.vSetValidationResults(saveResult.result.validationResults);
        }

        return saveResult.isPersisted;
    }

    @State.bound
    public async onDeleteAsync(): Promise<any> {
        const message = StaticCareResources.EpisodeOfCare.Messages.DeleteConfirmation;
        const canDeleteAnswer = await this.dialogService.yesNo(StaticCareResources.Common.Dialog.ConfirmationTitle, message);
        if (canDeleteAnswer.resultCode === DialogResultCode.Yes) {
            const saveResult =
                await this.handleSaveResultAsync(() => this.episodeOfCareScreenApiAdapter.deleteAsync(
                    (this.props.action as ShowEpisodeOfCareScreenAction).episodeOfCareId,
                    this.form,
                    this.lockInfo));

            if (saveResult.isPersisted) {
                this.props._screenState.savedExisting();
            }
        }
    }

    @State.bound
    public onCancelAsync(): Promise<any> {
        this.props._screenState.cancelled();
        return Promise.resolve();
    }

    @State.bound
    public async onValidateAsync(): Promise<IClientValidationResult[]> {
        if (!this.form) {
            return [];
        }

        let formValidationResult: IForm;
        if (this.isCreateNewEpisodeOfCareScreen) {
            const operationResult = await this.episodeOfCareScreenApiAdapter.createEpisodeOfCareAsync(this.form, true);
            formValidationResult = operationResult.result;
        } else {
            const operationResult = await this.episodeOfCareScreenApiAdapter.updateEpisodeOfCareAsync(this.form, false, this.lockInfo, true);
            formValidationResult = operationResult.result;
        }

        return formValidationResult.validationResults;
    }

    public readonly onDataChange = this.async(async () => {
        for (const logic of this.formLogics) {
            await logic.executeAsync(this.form);
        }
    });

    @State.action.bound
    private setForm(form: IForm) {
        this.form = form;
    }

    protected saveFunction: () => Promise<boolean> = this.onSaveAsync;
}