import Di from "@Di";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import * as Proxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import { IHomeMenu, HomeMenuItem, HomeMenuItemGroup } from "@HisPlatform/Packages/Care/Components/HomeMenu/IHomeMenu";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";
import { mapPermissionScopesFromDto } from "@HisPlatform/BoundedContexts/WebAppBackend/FrontendActionMapperHelpers";

@Di.injectable()
export default class HomeMenuApiAdapter extends ApiAdapterBase2 {

    constructor(
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader,
        @Di.inject("ICareClient") private readonly apiClient: Proxy.ICareClient,
    ){
        super(referenceDataLoader);
    }

    public getHomeMenuAsync() {
        return this.executeOperationAsync<IHomeMenu, Proxy.GetHomeMenuItemsQueryResponse>(
            () => this.apiClient.getHomeMenuItemsQueryAsync(CreateRequestId()),
            response => ({
                items: response.items!.map(hmig => {
                    if (hmig instanceof Proxy.HomeMenuItemGroupDto) {
                        return new HomeMenuItemGroup(
                            hmig.localizedDisplayName ? new MultiLingualLabel(new Map(hmig.localizedDisplayName.map(ldn => [ldn.cultureCode.value, ldn.label]))) : null,
                            hmig.displayNameResourceId ?? null,
                            hmig.items.map(this.mapHomeMenuItem)
                        );
                    } else if (hmig instanceof Proxy.HomeMenuItemDto) {
                        return this.mapHomeMenuItem(hmig);
                    }

                    throw new Error("Unknown home menu item type.");
                })
            })
        );
    }

    private mapHomeMenuItem(item: Proxy.HomeMenuItemDto): HomeMenuItem {
        return new HomeMenuItem(
            item.localizedDisplayName ? new MultiLingualLabel(new Map(item.localizedDisplayName.map(ldn => [ldn.cultureCode.value, ldn.label]))) : null,
            item.displayNameResourceId ?? null,
            item.localizedShortName ? new MultiLingualLabel(new Map(item.localizedShortName.map(ldn => [ldn.cultureCode.value, ldn.label]))) : null,
            item.shortNameResourceId ?? null,
            item.contextOrganizationUnitId ?? null,
            item.contextHealthcareProfessionIds ?? null,
            new ActionDescriptor(
                item.actionDescriptor.action.frontendAction,
                item.actionDescriptor.presentation,
                mapPermissionScopesFromDto(item.actionDescriptor.requiredPermissionScopes)
            ),
        );
    }
}