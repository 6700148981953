import React, { useCallback } from "react";
import Button from "@CommonControls/Button";
import { IDataGridColumnChildProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import DocumentInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentInfo";
import DocumentKind from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentKind.g";

interface IListActionsProps extends IDataGridColumnChildProps<DocumentInfo> {
    onDownloadAsync: (document: DocumentInfo) => Promise<void>;
    readOnly?: boolean;
}


const ListActions: React.FC<IListActionsProps> = props => {

    const downloadAsync = useCallback(async () => {
        await props.onDownloadAsync(props.row);
    }, [props.row]);

    if (props.row.kind === DocumentKind.Binary) {
        return (
            <>
                <Button iconName="export" onClickAsync={downloadAsync} size="compact" stopPropagation automationId={props.row.name + "_downloadButton"} />
            </>
        );
    } else if (!props.readOnly && props.row.kind === DocumentKind.TemplateBased) {
        return (
            <>
                <Button iconName="pen" size="compact" automationId={props.row.name + "_actionButton"} />
            </>
        );
    } else {
        return null;
    }
};

export default ListActions;