// tslint:disable:variable-name
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import ServiceRequestDefinitionDialogStore from "./ServiceRequestDefinitionDialogStore";

const { 
    ContextComponent: ServiceRequestDefinitionDialogStoreContext, 
    StoreProvider: ServiceRequestDefinitionDialogStoreProvider, 
    useStore: useServiceRequestDefinitionDialogStore,
    provideStore: provideServiceRequestDefinitionDialogStore,
} = createPanelStoreProvider(ServiceRequestDefinitionDialogStore);

export {
    ServiceRequestDefinitionDialogStoreContext,
    ServiceRequestDefinitionDialogStoreProvider,
    useServiceRequestDefinitionDialogStore,
    provideServiceRequestDefinitionDialogStore
};