import moment from "moment";
import { ISchedulerEvent } from "@CommonControls/Scheduler/ISchedulerProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class MaterializedTimePhase implements ISchedulerEvent {
    constructor(
        public readonly id: number,
        public readonly timePhaseId: number,
        public readonly startTime: moment.Moment,
        public readonly endTime: moment.Moment,
        public readonly isReadOnly?: boolean
    ) {
    }

    @State.bound
    public getDurationInMinutes() {
        return this.endTime.clone().diff(this.startTime, "minutes");
    }
}