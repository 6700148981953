import PractitionerEhiLoginMenu from "@HunEHealthInfrastructurePlugin/BoundedContexts/UserManagement/Extensions/PractitionerEhiLoginExtension/PractitionerEhiLoginMenu";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import NavMenuCustomItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuCustomItem";

export function getNavigationMenuItems(): INavMenuItem[] {
    const leftTopLevelMenuItems: INavMenuItem[] = [
    ];
    
    const rightTopLevelMenuItems: INavMenuItem[] = [
        NavMenuCustomItem.createTopLevel(
            "PractitionerEhiLogin",
            PractitionerEhiLoginMenu,
            "right",
            5)
    ];
    
    const childMenuItems: INavMenuItem[] = [
    
    ];

    return [...leftTopLevelMenuItems, ...rightTopLevelMenuItems , ...childMenuItems];
}