import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IModalComponentParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import OncologyDetailsPanel from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/DiagnosisExtension/DiagnosisExtension/OncologyDetailsPanel";
import { IOncologyDataModalParams } from "@HunEHealthInfrastructurePlugin/Common/Components/Widgets/OncologyWidget/OncologyDataModalParams";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import WellKnownReferenceCodes from "@HunEHealthInfrastructurePlugin/Common/WellKnownReferenceCodes";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";

interface IOncologyDataModalProps extends IModalComponentParams<void>, IOncologyDataModalParams {
}

@State.observer
class OncologyDataModal extends React.Component<IOncologyDataModalProps> {

    @State.action.bound
    private onModalClose() {
        this.props.onClose(null);
    }
    public render() {
        const morphologyIdentifierSystemId = new IdentifierSystemId(WellKnownReferenceCodes.MorphologyIdentifier);
        return (
            <Ui.Modal
                title={StaticProductivityResources.Widgets.OncologyWidget.ModalTitle}
                isOpen
                size="normal"
                closeButton
                onClose={this.onModalClose}>
                <Ui.Modal.Body>
                    <OncologyDetailsPanel
                        cancerClassificationDataStore={this.props.cancerClassificationDataStore}
                        cancerClassificationStoreLabel={this.props.cancerClassificationStoreLabel}
                        careActivityId={this.props.careActivityId}
                        medicalServiceIdFilter={this.props.medicalServiceIdFilter}
                        condition={this.props.condition}
                        dataStore={this.props.store}
                        morphologyIdentifierSystemId={morphologyIdentifierSystemId}
                        isReadOnly={true}
                    />
                </Ui.Modal.Body>
            </Ui.Modal>
        );
    }
}

export default OncologyDataModal;