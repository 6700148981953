import React from "react";
import StaticWebAppResources from "@StaticResources";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IUserListToolbarProps {
    onAddUserAsync: () => Promise<void>;
}


const UserListToolbar: React.FC<IUserListToolbarProps> = (props) => {
    return (
        <Ui.AddButton
            onClick={props.onAddUserAsync}
            automationId="addUserButton"
        />
    );
};

export default State.observer(UserListToolbar);
