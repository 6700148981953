exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ListPanel_container_3TACH {\n  display: block;\n}\n.ListPanel_loading-container_1Qm3- {\n  position: relative;\n  display: block;\n  width: 100%;\n  min-height: 100px;\n}\n.ListPanel_row_67oD6 {\n  display: flex;\n  flex-direction: row;\n  border: solid 1px #f4f7fc;\n  border-left: 4px solid #ebebeb;\n  margin: 8px 4px;\n  border-radius: 2px;\n  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);\n}\n.ListPanel_row_67oD6.ListPanel_new-item-row_3ShUP {\n  border-left-color: #21c313;\n}\n.ListPanel_row_67oD6.ListPanel_item-under-edit-row_27jbf {\n  border-left-color: #00b4ec;\n}\n.ListPanel_row_67oD6 .ListPanel_item-container_1tFAq {\n  flex: 1;\n  padding: 4px 4px 8px 8px;\n}\n.ListPanel_row_67oD6 .ListPanel_action-container_1nann {\n  flex-basis: 100px;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  padding-bottom: 8px;\n}\n.ListPanel_row_67oD6 .ListPanel_action-container_1nann.ListPanel_vertical-actions_3Okjh {\n  flex-basis: 35px;\n  flex-direction: column;\n  justify-content: flex-end;\n}\n.ListPanel_add-button-container_3uAOH {\n  width: fit-content;\n  min-width: 30px;\n  display: flex;\n  justify-content: flex-start;\n}\n.ListPanel_title_1YhpY {\n  margin: 0px;\n  padding: 0px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "ListPanel_container_3TACH",
	"loading-container": "ListPanel_loading-container_1Qm3-",
	"loadingContainer": "ListPanel_loading-container_1Qm3-",
	"row": "ListPanel_row_67oD6",
	"new-item-row": "ListPanel_new-item-row_3ShUP",
	"newItemRow": "ListPanel_new-item-row_3ShUP",
	"item-under-edit-row": "ListPanel_item-under-edit-row_27jbf",
	"itemUnderEditRow": "ListPanel_item-under-edit-row_27jbf",
	"item-container": "ListPanel_item-container_1tFAq",
	"itemContainer": "ListPanel_item-container_1tFAq",
	"action-container": "ListPanel_action-container_1nann",
	"actionContainer": "ListPanel_action-container_1nann",
	"vertical-actions": "ListPanel_vertical-actions_3Okjh",
	"verticalActions": "ListPanel_vertical-actions_3Okjh",
	"add-button-container": "ListPanel_add-button-container_3uAOH",
	"addButtonContainer": "ListPanel_add-button-container_3uAOH",
	"title": "ListPanel_title_1YhpY"
};