import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ImmunizationId from "@Primitives/ImmunizationId.g";
import VaccineAssignmentBase from "./VaccineAssignmentBase";
import ReferencedVaccineAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/ReferencedVaccineAssignment";
import { VaccineCodeSelectorItem } from "@HisPlatformControls/VaccineCodeSelector/VaccineCodeSelectorItem";
import CustomVaccineAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/Immunization/CustomVaccineAssignment";
import MedicalConditionItemBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionItemBase";

export default class ImmunizationStore extends MedicalConditionItemBase<ImmunizationId> {

    @State.observable.ref public vaccineAssignment: VaccineAssignmentBase;
    @State.observable public vaccineName: string;

    constructor(isNew: boolean) {
        super(isNew);
    }

    @State.computed public get vaccineCodeSelectorItem() {
        if (!this.vaccineAssignment) {
            return null;
        }
        if (this.vaccineAssignment instanceof ReferencedVaccineAssignment) {
            return new VaccineCodeSelectorItem({ id: this.vaccineAssignment.vaccineId });
        } else if (this.vaccineAssignment instanceof CustomVaccineAssignment) {
            return new VaccineCodeSelectorItem({ text: this.vaccineAssignment.name });
        }
        return null;
    }

    @State.action.bound
    public setVaccineAssignment(newValue: VaccineAssignmentBase) {
        this.vaccineAssignment = newValue;
    }

    @State.action.bound
    public setVaccineName(newValue: string) {
        this.vaccineName = newValue;
    }
}