import * as WebAppBackendProxy from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import DocumentTypeId from "@Primitives/DocumentTypeId.g";
import LockInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/LockInfo";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import DocumentId from "@Primitives/DocumentId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import DocumentKind from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentKind.g";
import DocumentState from "@HisPlatform/BoundedContexts/DocumentManagement/Api/Documents/Enum/DocumentState.g";
import DocumentScope from "@HisPlatform/BoundedContexts/DocumentManagement/Api/ReferenceData/Enum/DocumentScope.g";
import BinaryDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/BinaryDocument";
import DocumentBase from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentBase";
import DocumentChange from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentChange";
import DocumentFile from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentFile";
import DocumentInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/DocumentInfo";
import ScopeIdentifier from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/ScopeIdentifier";
import TemplateBasedDocument from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TemplateBasedDocument";
import TokenLockInfo from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Documents/TokenLockInfo";

export function getDocumentInfo(dto: WebAppBackendProxy.DocumentInfoDto) {
    if (!dto) {
        return null;
    }
    
    const resp = new DocumentInfo(
        false,
        dto.id,
        dto.scopeIdentifiers?.map(si => new ScopeIdentifier(si.scope as unknown as DocumentScope, si.identifier)),
        dto.documentTypeId ? new DocumentTypeId(dto.documentTypeId.value.toString()) : null,
        dto.documentKind as unknown as DocumentKind,
        dto.createdById,
        dto.createdAt,
        dto.lastModifiedById,
        dto.lastModifiedAt,
        dto.documentState as unknown as DocumentState,
        dto.name,
        dto.possibleActions,
        dto.isPrimary,
        dto.metaProperties,
        dto.extensionData);

    resp.rowVersion = dto.rowVersion;
    return resp;
}

export function mapDocumentWithLockInfo(target: DocumentBase, response: WebAppBackendProxy.GetPatientRelatedDocumentByIdCommandResponse) {
    target.info = getDocumentInfo(response.document);
    target.largeDataToken = response.largeDataToken;
    target.downloadFileName = response.downloadFileName;
    if (target instanceof BinaryDocument) {
        target.content = new Blob([Base64Converter.toByteArray(response.content)], { type: response.contentType });
    } else if (target instanceof TemplateBasedDocument) {
        target.templateContentToken = response.templateContentToken;
        const documentFile = DocumentFile.create(response.content);
        target.documentFile = documentFile;
        target.dataChangedSinceLastRefresh = response.changes.map(it => getChangeInfo(it));
        target.tokenLockInfos = response.editableTokensUsed.map(it => getTokenLockInfo(it));
    }
    target.info.lockInfo = getLockInfo(response);
}

export function getNewPatientRelatedTemplateDocument(dto: WebAppBackendProxy.NewTemplateBasedDocumentInfoDto, description: string) {
    const documentInfo = new DocumentInfo(
        true,
        new DocumentId("new"),
        dto.scopeIdentifiers?.map(si => new ScopeIdentifier(si.scope as unknown as DocumentScope, si.identifier)),
        dto.documentTypeId ? new DocumentTypeId(dto.documentTypeId.value.toString()) : null,
        dto.documentKind as unknown as DocumentKind,
        dto.createdBy,
        dto.createdAt,
        dto.lastModifiedBy,
        dto.lastModifiedAt,
        dto.documentState as unknown as DocumentState,
        dto.name,
        dto.possibleActions,
        dto.isPrimary);

    const templateDocument = new TemplateBasedDocument(true);
    templateDocument.info = documentInfo;
    templateDocument.description = description;
    templateDocument.documentFile = DocumentFile.create(dto.content);
    templateDocument.templateId = dto.templateId;
    templateDocument.templateContentToken = dto.templateContentToken;
    templateDocument.tokenLockInfos = dto.tokensWithLockInfo?.map(it => getTokenLockInfo(it));

    return templateDocument;
}

export function getTokenLockInfo(dto: WebAppBackendProxy.EditableTemplateTokenWithLockInfoDto) {
    const resp = new TokenLockInfo(dto.editableTemplateTokenInfoDto.tokenReference,
        dto.editableTemplateTokenInfoDto.tokenName,
        dto.editableTemplateTokenInfoDto.sourceId,
        dto.editableTemplateTokenInfoDto.sourceRowVersion,
        );

    if (!isNullOrUndefined(dto.acquiredLockInfo)){
        resp.acquiredLockInfo = getLockInfo(dto.acquiredLockInfo);
    }

    resp.acquiredRowVersion = dto.acquiredRowVersion;
    resp.sourceDescription = dto.sourceDescription;

    return resp;
}

function getLockInfo(response: any): LockInfo {
    if ("wasLockRequested" in response) {
        return new LockInfo(
            response.lockState as unknown as WebAppBackendProxy.EntityLockState,
            response.heldLockId,
            response.wasLockRequested,
            response.hasUpdatePermission,
            response.preventingLock?.owner?.name,
            response.preventingLock?.id,
            response.preventingLock?.owner?.userId
        );
    } else {
        return null;
    }
}

function getChangeInfo(dto: WebAppBackendProxy.ChangedTemplateTokenDto) {
    return new DocumentChange(
        dto.tokenReference,
        dto.symbol,
        dto.nameResourceId,
        dto.isTwoWay
    );
}