import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import {IModalComponentParams} from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import {
    IAppointmentScheduleDefinitionBaseDataModalParams,
    IAppointmentScheduleDefinitionBaseDataModalResult,
} from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/CreateNewItemModal/AppointmentScheduleDefinitionBaseDataModalParams";
import * as Ui from "@CommonControls";
import AppointmentScheduleDefinition from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentScheduleDefinition";
import AppointmentScheduleDefinitionBaseDataPanelView from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduleDefinitionsMasterDetailPanel/Detail/AppointmentScheduleDefinitionBaseDataPanelView";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import SchedulingConfigurationApiAdapter from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingConfigurationApiAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import * as HisUi from "@HisPlatformControls";
import PlanningPeriod from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/PlanningPeriod";

interface IAppointmentScheduleDefinitionBaseDataModalDependencies {
    apiAdapter: SchedulingConfigurationApiAdapter;
}

interface IAppointmentScheduleDefinitionBaseDataModalProps extends IModalComponentParams<IAppointmentScheduleDefinitionBaseDataModalResult>, IAppointmentScheduleDefinitionBaseDataModalParams {
    _dependencies?: IAppointmentScheduleDefinitionBaseDataModalDependencies;
}

@State.observer
class AppointmentScheduleDefinitionBaseDataModal extends React.Component<IAppointmentScheduleDefinitionBaseDataModalProps> {

    @State.observable.ref private store: AppointmentScheduleDefinition;

    @State.bound
    private onCancel() {
        this.props.onClose({result: null});
    }

    @State.bound
    private async okAsync() {
        const validated = await this.props._dependencies.apiAdapter.validateAppointmentScheduleDefinitionBaseDataAsync(this.store);
        if (validated.value.some(item => item.problems.some(p => p.severity === "error"))) {
            this.store.setValidationResults(validated.value);
            
            return;
        }
        this.props.onClose({result: this.store});
    }

    public componentDidMount() {
        State.runInAction(() => {
            this.store = new AppointmentScheduleDefinition(true);

            const emptyPlanningPeriod = new PlanningPeriod();
            this.store.addPlanningPeriod(emptyPlanningPeriod);

            this.store.setSelectedPlanningPeriod(emptyPlanningPeriod);
        });
    }

    @State.bound
    private async validateAsync() {
        const result = await this.props._dependencies.apiAdapter.validateAppointmentScheduleDefinitionBaseDataAsync(this.store);
        return result.value;
    }

    public render() {
        return (
            <HisUi.ContextAwareModal
                closeOnOutsideClick
                title={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.BaseData.PanelName}
                automationId="__selectUserGroupsModal"
                isOpen
                onClose={this.onCancel}
                size={"tiny"}
            >
                <Ui.Modal.Body>
                    <AppointmentScheduleDefinitionBaseDataPanelView store={this.store} onValidateAsync={this.validateAsync} handlePlanningPeriod />
                </Ui.Modal.Body>
                <Ui.Modal.Footer>
                    <Ui.Modal.Button
                        text={StaticSchedulingResources.AppointmentSchedulesDefinitionPanel.Panel.Save}
                        onClickAsync={this.okAsync}
                        visualStyle="primary" float="right"
                        automationId="appointmentScheduleDefinitionBaseDataSaveButton"
                    />
                </Ui.Modal.Footer>
            </HisUi.ContextAwareModal>
        );
    }
}

export default connect(
    AppointmentScheduleDefinitionBaseDataModal,
    new DependencyAdapter<IAppointmentScheduleDefinitionBaseDataModalProps, IAppointmentScheduleDefinitionBaseDataModalDependencies>(c => ({
        apiAdapter: c.resolve("SchedulingConfigurationApiAdapter")
    }))
);
