import Di from "@Di";
import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import ReportingApiAdapter from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/ReportingApiAdapter";
import ReportingDefinitionRefernceDataStore from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/Model/ReportingDefinitionRefernceDataStore";

@Di.injectable()
export default class ReportingReferenceDataStore extends ReferenceDataStoreBase {
    public reportingDefinitions = new ReportingDefinitionRefernceDataStore(this.reportingApiAdapter);

    constructor(
        @Di.inject("ReportingApiAdapter") private reportingApiAdapter: ReportingApiAdapter
    ) {
        super();
    }
} 