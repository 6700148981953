import Di from "@Di";
import IHeaderProvider from "@Toolkit/CommonWeb/Abstractions/HttpRequests/HeaderProvider/IHeaderProvider";
import IHeader from "@Toolkit/CommonWeb/Abstractions/HttpRequests/HeaderProvider/IHeader";
import IHeaderProviderService from "@HisPlatform/Services/Definition/HeaderProvider/IHeaderProviderService";

@Di.injectable()
export default class HeaderProvider implements IHeaderProvider, IHeaderProviderService {

    private token: string = null;
    private pointOfCareIdValue: string = null;
    private sessionId: string = null;

    public getHeaders(): IHeader[] {

        const headers = [
            { name: "Tenant-Id", value: "TestTenant" },
        ];

        if (this.token) {
            headers.push({ name: "Authorization", value: "Bearer " + this.token });
        }

        if (this.sessionId) {
            headers.push({ name: "Client-Session-Id", value: this.sessionId });
        }

        if (this.pointOfCareIdValue) {
            headers.push({ name: "Point-Of-Care-Id", value: this.pointOfCareIdValue });
        }

        return headers;
    }

    public setToken(token: string): void {
        this.token = token;
    }

    public setPointOfCareIdValue(idValue: string): void {
        this.pointOfCareIdValue = idValue;
    }

    public setSessionId(value: string): void {
        this.sessionId = value;
    }

}