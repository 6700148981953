import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import OncologyApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/ApiAdapter/OncologyApiAdapter";
import IDiagnosisExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IDiagnosisExtensionPointProps";

@Di.injectable()
export default class DiagnosisExtensionComponentService implements IExtensionComponentService<IDiagnosisExtensionPointProps> {

    constructor(@Di.inject("OncologyApiAdapter") private oncologyApiAdapter: OncologyApiAdapter) {
    }

    public async isVisibleAsync(props: IDiagnosisExtensionPointProps) {
        const response = await this.oncologyApiAdapter.isOncologyDataNeededQueryAsync(
            props.diagnosisStore.conditionVersionSelector.id,
            props.diagnosisStore.use,
            props.pointOfCareId);
        return response.value;
    }

    public shouldRerunIsVisible(props: IDiagnosisExtensionPointProps, prevProps: IDiagnosisExtensionPointProps) {
        return !ValueWrapper.equals(props.diagnosisStore?.conditionVersionSelector?.id, prevProps?.diagnosisStore?.conditionVersionSelector?.id) ||
            props.diagnosisStore?.use !== prevProps?.diagnosisStore?.use;
    }
}
