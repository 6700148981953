import * as Proxy from "@HisPlatform/BoundedContexts/UserManagement/Api/Proxy.g";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import UserId from "@Primitives/UserId.g";

export function getAddUserControllerDto(user: User) {
    return new Proxy.AddUserControllerDto({
        displayName: user.displayName,
        loginName: user.loginName,
        password: user.password,
        passwordAgain: user.passwordAgain,
        isEnabled: user.isEnabled,
        isPasswordExpirable: user.isPasswordExpirable,
        userGroupIds: user.userGroupIds
    });
}

export function getUpdateUserControllerDto(user: User) {
    const userDto =  new Proxy.UpdateUserControllerDto({
        id: user.id,
        displayName: user.displayName,
        isEnabled: user.isEnabled,
        isPasswordExpirable: user.isPasswordExpirable,
        userGroupIds: user.userGroupIds,
        loginName: user.loginName,
        rowVersion: user.rowVersion,
        passwordChange: isNullOrUndefined(user.isNewPassword) ? false : user.isNewPassword
    });

    if (user.isNewPassword) {
        userDto.password = user.password;
        userDto.passwordAgain = user.passwordAgain;
    }

    return userDto;
}

export function getUpdateUserPermissionCheckDto() {
    return new Proxy.UpdateUserControllerDto({
        id : new UserId("-1"),
        userGroupIds: [],
        isEnabled: false,
        isPasswordExpirable: false,
        isValidateOnly: true,
        passwordChange: false
    });
}
