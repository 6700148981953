import React, { useMemo } from "react";
import * as Ui from "@CommonControls";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import { CareLocation } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/CareLocation";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import DiagnosisId from "@Primitives/DiagnosisId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import IPropertyListItem from "@CommonControls/PropertyList/IPropertyListItem";
import MedicalServicePriority from "@HisPlatform/BoundedContexts/Care/Api/ReferenceData/MedicalServices/Enum/MedicalServicePriority.g";
import * as HisUi from "@HisPlatformControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import ExplicitIdentifierFilter from "@Toolkit/CommonWeb/Model/Filtering/ExplicitIdentifierFilter";

interface IServiceRequestSummaryPanelDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    careReferenceDataStore: CareReferenceDataStore;
    localizationService: ILocalizationService;
}

interface IServiceRequestSummaryPanelProps {
    _dependencies?: IServiceRequestSummaryPanelDependencies;

    serviceRequestSummaryTitle: string;
    serviceRequest: ServiceRequestStore;
    isAdministration: boolean;

    onCloseAsync: () => Promise<void>;
    onDeleteAsync: () => Promise<void>;
    onPrintAsync: () => Promise<void>;
    openForEditAsync: () => Promise<void>;

    withoutPageBox?: boolean;
    hideTitle?: boolean;
    hasNoTopMargin?: boolean;
    isLoading?: boolean;
}

function resolveCareLocationName(careLocation: CareLocation, organizationReferenceDataStore: OrganizationReferenceDataStore) {
    if (careLocation instanceof InternalCareLocation) {
        return organizationReferenceDataStore.allPointsOfCareMap.get(careLocation.pointOfCareId).name;
    } else if (careLocation instanceof ExternalCareLocation) {
        return organizationReferenceDataStore.externalLocationStore.get(careLocation.externalLocationSelector).name;
    }

    throw new Error("Cannot resolve CareLocation.");
}

/** @screen */
function ServiceRequestSummaryPanel(props: IServiceRequestSummaryPanelProps): React.ReactElement {

    const {
        organizationReferenceDataStore,
        localizationService,
        careReferenceDataStore
    } = props._dependencies;

    const referralProperties = useMemo(() => {

        const requesterDoctor = organizationReferenceDataStore.getDoctorById(props.serviceRequest.requesterDoctorId);

        return [
            {
                name: StaticWebAppResources.NewServiceRequestPage.ReferralDataLabels.Requester,
                value: resolveCareLocationName(props.serviceRequest.referralLocation, organizationReferenceDataStore),
                automationId: "referralLocation"
            },
            {
                name: StaticWebAppResources.NewServiceRequestPage.ReferralDataLabels.RequesterDoctor,
                value: `${localizationService.localizePersonName(requesterDoctor.name)} - ${requesterDoctor.code}`,
                automationId: "requesterDoctor"
            },
            {
                name: StaticWebAppResources.NewServiceRequestPage.ReferralDataLabels.ReferralCreationDate,
                value: localizationService.localizeDateTime(props.serviceRequest.createdAt),
                automationId: "createdAt"
            },
            {
                name: StaticWebAppResources.NewServiceRequestPage.SuspectedDiagnoses,
                value: props.serviceRequest.suspectedDiagnosisList.map(suspectedDiagnosis => {
                    const diag = careReferenceDataStore.condition.get(
                        new EntityVersionSelector<DiagnosisId>(suspectedDiagnosis.conditionId, LocalDate.createFromMoment(props.serviceRequest.createdAt))
                    );

                    return `${diag.code} ${diag.name}`;
                }),
                automationId: "suspectedDiagnosis"
            },

        ] as IPropertyListItem[];

    }, [props.serviceRequest]);

    const targetProperties = useMemo(() => {
        return [
            {
                name: "Azonosító",
                value: props.serviceRequest.serviceRequestIdentifier,
                automationId: "serviceRequestIdentifier"
            },
            {
                name: StaticWebAppResources.NewServiceRequestPage.TargetDataLabels.Priority,
                value: localizationService.localizeEnum(MedicalServicePriority[props.serviceRequest.medicalServicePriority], "MedicalServicePriority").Name,
                automationId: "medicalServicePriority"
            }
        ] as IPropertyListItem[];
    }, [props.serviceRequest]);

    const targetPointOfCareIdFilter: FilterBase[] = props.serviceRequest.targetPointOfCareId
        ? [new ExplicitIdentifierFilter("PointOfCare", props.serviceRequest.targetPointOfCareId.value)]
        : [];

    return (
        <>
            <Ui.Flex>
                <Ui.Flex.Item md={8}>
                    <Ui.PropertyList properties={referralProperties} hasColonAfterNames nameWidth={120} boldValues />
                </Ui.Flex.Item>
                <Ui.Flex.Item md={4}>
                    <Ui.ToolbarLayout
                        body={(
                            <Ui.PropertyList properties={targetProperties} hasColonAfterNames nameWidth={70} boldValues />
                        )}
                        bottomToolbar={(
                            <State.Observer>
                                {() =>
                                    props.serviceRequest.isExternal && (
                                        <HisUi.DoctorCodeSelector
                                            value={props.serviceRequest.executingDoctorId}
                                            onChange={props.serviceRequest.setExecutingDoctorId}
                                            propertyIdentifier="ServiceRequest.TargetDoctorId"
                                            automationId="serviceRequestTargetDoctor"
                                            label={StaticWebAppResources.NewServiceRequestPage.TargetDataLabels.PerformingDoctor}
                                        />
                                    ) ||
                                    !props.serviceRequest.isExternal && (
                                        <HisUi.DoctorCodeSelector
                                            value={props.serviceRequest.executingDoctorId}
                                            onChange={props.serviceRequest.setExecutingDoctorId}
                                            filters={targetPointOfCareIdFilter}
                                            propertyIdentifier="ServiceRequest.TargetDoctorId"
                                            automationId="serviceRequestTargetDoctor"
                                            label={StaticWebAppResources.NewServiceRequestPage.TargetDataLabels.PerformingDoctor}
                                        />
                                    )
                                }
                            </State.Observer>
                        )} />
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.Flex>
                <Ui.Flex.Item md={4} xs={6}>
                    <Ui.TextBox
                        label={StaticWebAppResources.NewServiceRequestPage.ExecutionNotesLabels.ClinicalQuestion}
                        value={props.serviceRequest.clinicalQuestion}
                        autoResize
                        multiline
                        multilineMaxLineCount={2}
                        propertyIdentifier="ServiceRequest.ClinicalQuestion"
                        isReadOnly
                        automationId="__clinicalQuestion"
                    />
                    <Ui.TextBox
                        label={StaticWebAppResources.NewServiceRequestPage.ExecutionNotesLabels.Remark}
                        value={props.serviceRequest.remark}
                        autoResize
                        multiline
                        multilineMaxLineCount={2}
                        propertyIdentifier="ServiceRequest.Remark"
                        isReadOnly
                        automationId="__remark"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item md={8} xs={6}>
                    <Ui.TextBox
                        label={StaticWebAppResources.NewServiceRequestPage.AdditionalData}
                        isReadOnly
                        multiline
                        multilineMinHeight={108}
                        propertyIdentifier="ServiceRequest.AdditionalData"
                        automationId="__additionalData"
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
        </>
    );
}

export default connect(
    ServiceRequestSummaryPanel,
    new DependencyAdapter<IServiceRequestSummaryPanelProps, IServiceRequestSummaryPanelDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
    }))
);
