import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import moment from "moment";
import EhiEReferralCovidExaminationSampleTypeId from "@Primitives/EhiEReferralCovidExaminationSampleTypeId.g";
import EhiEReferralCovidExaminationTypeId from "@Primitives/EhiEReferralCovidExaminationTypeId.g";
import EhiEReferralCovidContactExaminationReasonId from "@Primitives/EhiEReferralCovidContactExaminationReasonId.g";
import EhiEReferralCovidScreeningExaminationReasonId from "@Primitives/EhiEReferralCovidScreeningExaminationReasonId.g";
import CountryId from "@Primitives/CountryId.g";
import EhiEReferralCovidSymptomsSeverityId from "@Primitives/EhiEReferralCovidSymptomsSeverityId.g";
import EhiEReferralCovidPreviousResultId from "@Primitives/EhiEReferralCovidPreviousResultId.g";
import EhiEReferralCovidQuickTestResultId from "@Primitives/EhiEReferralCovidQuickTestResultId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EhiStructuredDocumentContactStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiStructuredDocumentContactStore";
import EhiStructuredDocumentContactTypeId from "@Primitives/EhiStructuredDocumentContactTypeId.g";

export default class EhiEReferralCovidData {
    @State.observable.ref public examinationSampleIdentifier: string;
    @State.observable.ref public examinationSampleTypeId: EhiEReferralCovidExaminationSampleTypeId;
    @State.observable.ref public samplingDate: moment.Moment;
    @State.observable.ref public examinationTypeId: EhiEReferralCovidExaminationTypeId;
    @State.observable.ref public sicknessStartFrom: LocalDate;
    @State.observable.ref public contactExaminationReasonId: EhiEReferralCovidContactExaminationReasonId;
    @State.observable.ref public contactExaminationOtherReasonJustification: string;
    @State.observable.ref public screeningExaminationReasonId: EhiEReferralCovidScreeningExaminationReasonId;
    @State.observable.ref public screeningExaminationOtherReasonJustification: string;
    @State.observable public stayAbroadInTheLastTwoWeeks: boolean;
    @State.observable.ref public countryOfResidenceAbroad: CountryId;
    @State.observable.ref public symptomsSeverityId: EhiEReferralCovidSymptomsSeverityId;
    @State.observable.ref public symptomsDescription: string;
    @State.observable public isReExamination: boolean;
    @State.observable.ref public previousResultId: EhiEReferralCovidPreviousResultId;
    @State.observable.ref public quickTestResultId: EhiEReferralCovidQuickTestResultId;
    @State.observable.ref public quickTestDate: moment.Moment;
    @State.observable.ref public practitionerContacts: IObservableArray<EhiStructuredDocumentContactStore> = State.observable([]);
    @State.observable.ref public patientContacts: IObservableArray<EhiStructuredDocumentContactStore> = State.observable([]);

    @State.action.bound
    public setExaminationSampleIdentifier(value: string) {
        this.examinationSampleIdentifier = value;
    }

    @State.action.bound
    public setExaminationSampleTypeId(value: EhiEReferralCovidExaminationSampleTypeId) {
        this.examinationSampleTypeId = value;
    }

    @State.action.bound
    public setSamplingDate(value: moment.Moment) {
        this.samplingDate = value;
    }

    @State.action.bound
    public setExaminationTypeId(value: EhiEReferralCovidExaminationTypeId) {
        this.examinationTypeId = value;
        this.sicknessStartFrom = null;
        this.contactExaminationReasonId = null;
        this.screeningExaminationReasonId = null;
        this.contactExaminationOtherReasonJustification = null;
        this.screeningExaminationOtherReasonJustification = null;
    }

    @State.action.bound
    public setSicknessStartFrom(value: LocalDate) {
        this.sicknessStartFrom = value;
    }

    @State.action.bound
    public setContactExaminationReasonId(value: EhiEReferralCovidContactExaminationReasonId) {
        this.contactExaminationReasonId = value;
    }

    @State.action.bound
    public setContactExaminationOtherReasonJustification(value: string) {
        this.contactExaminationOtherReasonJustification = value;
    }

    @State.action.bound
    public setScreeningExaminationReasonId(value: EhiEReferralCovidScreeningExaminationReasonId) {
        this.screeningExaminationReasonId = value;
    }

    @State.action.bound
    public setStayAbroadInTheLastTwoWeeks(value: boolean) {
        this.stayAbroadInTheLastTwoWeeks = value;
        this.countryOfResidenceAbroad = null;
    }

    @State.action.bound
    public setCountryOfResidenceAbroad(value: CountryId) {
        this.countryOfResidenceAbroad = value;
    }

    @State.action.bound
    public setSymptomsSeverityId(value: EhiEReferralCovidSymptomsSeverityId) {
        this.symptomsSeverityId = value;
        if (value?.value !== EhiEReferralCovidSymptomsSeverityId.Mild.value && value?.value !== EhiEReferralCovidSymptomsSeverityId.Severe.value) {
            this.symptomsDescription = null;
        }
    }

    @State.action.bound
    public setSymptomsDescription(value: string) {
        this.symptomsDescription = value;
    }

    @State.action.bound
    public setIsReExamination(value: boolean) {
        this.isReExamination = value;
    }

    @State.action.bound
    public setPreviousResultId(value: EhiEReferralCovidPreviousResultId) {
        this.previousResultId = value;
    }

    @State.action.bound
    public setQuickTestResultId(value: EhiEReferralCovidQuickTestResultId) {
        this.quickTestResultId = value;
        if (value?.value === EhiEReferralCovidQuickTestResultId.NotPerformed.value) {
            this.quickTestDate = null;
        }
    }

    @State.action.bound
    public setQuickTestDate(value: moment.Moment) {
        this.quickTestDate = value;
    }

    @State.action.bound
    public setScreeningExaminationOtherReasonJustification(value: string) {
        this.screeningExaminationOtherReasonJustification = value;
    }

    @State.action.bound
    public setPractitionerContacts(contacts: EhiStructuredDocumentContactStore[]) {
        this.practitionerContacts = State.observable(contacts);
    }

    @State.action.bound
    public addPractitionerContact(newContact: EhiStructuredDocumentContactStore) {
        this.practitionerContacts.push(newContact);
    }

    @State.action.bound
    public removePractitionerContact(contact: EhiStructuredDocumentContactStore) {
        this.practitionerContacts.remove(contact);
    }

    @State.action.bound
    public setPatientContacts(contacts: EhiStructuredDocumentContactStore[]) {
        this.patientContacts = State.observable(contacts);
    }

    @State.action.bound
    public addPatientContact(newContact: EhiStructuredDocumentContactStore) {
        this.patientContacts.push(newContact);
    }

    @State.action.bound
    public removePatientContact(contact: EhiStructuredDocumentContactStore) {
        this.patientContacts.remove(contact);
    }

    private static createContact(contact: any) {
        const contactTypeId = new EhiStructuredDocumentContactTypeId(contact.EhiStructuredDocumentContactTypeId.Value.toString());
        return new EhiStructuredDocumentContactStore(contactTypeId, contact.Value);
    }

    public static createFromExtensionDto(source: any) {
        const result = new EhiEReferralCovidData();
        if (!isNullOrUndefined(source)) {

            result.examinationSampleIdentifier = source.ExaminationSampleIdentifier;
            result.contactExaminationOtherReasonJustification = source.ContactExaminationOtherReasonJustification;
            result.stayAbroadInTheLastTwoWeeks = source.StayAbroadInTheLastTwoWeeks;
            result.symptomsDescription = source.SymptomsDescription;
            result.isReExamination = source.IsReExamination;
            result.screeningExaminationOtherReasonJustification = source.ScreeningExaminationOtherReasonJustification;
            result.examinationSampleTypeId = !isNullOrUndefined(source.ExaminationSampleTypeId)
                ? new EhiEReferralCovidExaminationSampleTypeId(source.ExaminationSampleTypeId.Value.toString())
                : null;
            result.examinationTypeId = !isNullOrUndefined(source.ExaminationTypeId)
                ? new EhiEReferralCovidExaminationTypeId(source.ExaminationTypeId.Value.toString())
                : null;
            result.contactExaminationReasonId = !isNullOrUndefined(source.ContactExaminationReasonId)
                ? new EhiEReferralCovidContactExaminationReasonId(source.ContactExaminationReasonId.Value.toString())
                : null;
            result.screeningExaminationReasonId = !isNullOrUndefined(source.ScreeningExaminationReasonId)
                ? new EhiEReferralCovidScreeningExaminationReasonId(source.ScreeningExaminationReasonId.Value.toString())
                : null;
            result.countryOfResidenceAbroad = !isNullOrUndefined(source.CountryOfResidenceAbroad)
                ? new CountryId(source.CountryOfResidenceAbroad.Value.toString())
                : null;
            result.symptomsSeverityId = !isNullOrUndefined(source.SymptomsSeverityId)
                ? new EhiEReferralCovidSymptomsSeverityId(source.SymptomsSeverityId.Value.toString())
                : null;
            result.previousResultId = !isNullOrUndefined(source.PreviousResultId)
                ? new EhiEReferralCovidPreviousResultId(source.PreviousResultId.Value.toString())
                : null;
            result.quickTestResultId = !isNullOrUndefined(source.QuickTestResultId)
                ? new EhiEReferralCovidQuickTestResultId(source.QuickTestResultId.Value.toString())
                : null;
            result.sicknessStartFrom = !isNullOrUndefined(source.SicknessStartFrom)
                ? LocalDate.create(source.SicknessStartFrom.Year, source.SicknessStartFrom.Month, source.SicknessStartFrom.Day)
                : null;
            result.samplingDate = !isNullOrUndefined(source.SamplingDate)
                ? moment(source.SamplingDate)
                : null;
            result.quickTestDate = !isNullOrUndefined(source.QuickTestDate)
                ? moment(source.QuickTestDate)
                : null;
            result.practitionerContacts = !isNullOrUndefined(source.PractitionerContacts)
                ? State.observable(source.PractitionerContacts.map(((i: any) => this.createContact(i))))
                : null;
            result.patientContacts = !isNullOrUndefined(source.PatientContacts)
                ? State.observable(source.PatientContacts.map(((i: any) => this.createContact(i))))
                : null;
        }
        return result;
    }

}
