import React from "react";
import NameStore from "@Primitives/NameStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import StaticResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import { width } from "@CommonControls/Flex/FlexItem";

interface IPatientBaseDataNameRowProps {
    nameStore: NameStore;
    isUnknown: boolean;
    displayProportions: width[];
    hasMothersName?: boolean;
    mothersNameStore: NameStore;
}

@State.observer
export default class PatientBaseDataNameRow extends React.Component<IPatientBaseDataNameRowProps> {

    public static defaultProps: Partial<IPatientBaseDataNameRowProps> = {
        displayProportions : [3, 3, 3, 3],
        hasMothersName: true
    };
    
    public render() {
        return (
            <Ui.Flex>
                <Ui.Flex.Item xs={this.props.displayProportions[0]}>
                    <Ui.NameTextBox
                        label={StaticResources.PatientRegister.PatientBaseData.Label.NamePrefix}
                        value={this.props.nameStore.prefix}
                        onChange={this.props.nameStore.setNamePrefix}
                        disabled={this.props.isUnknown}
                        propertyIdentifier="Name.Prefix"
                        automationId="namePrefixTextBox"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={this.props.displayProportions[1]}>
                    <Ui.NameTextBox
                        label={StaticResources.PatientRegister.PatientBaseData.Label.LastName}
                        value={this.props.nameStore.familyName}
                        onChange={this.props.nameStore.setFamilyName}
                        disabled={this.props.isUnknown}
                        propertyIdentifier="Name.FamilyName"
                        automationId="lastNameTextBox"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={this.props.displayProportions[2]}>
                    <Ui.NameTextBox
                        label={StaticResources.PatientRegister.PatientBaseData.Label.FirstName}
                        value={this.props.nameStore.givenName1}
                        onChange={this.props.nameStore.setGivenName1}
                        disabled={this.props.isUnknown}
                        propertyIdentifier="Name.GivenName"
                        automationId="firstNameTextBox"
                    />
                </Ui.Flex.Item>
                {this.props.hasMothersName && <Ui.Flex.Item xs={this.props.displayProportions[3]}>
                    <Ui.NameTextBox
                        label={StaticResources.PatientRegister.PatientBaseData.Label.MothersName}
                        value={this.props.mothersNameStore.givenName1}
                        onChange={this.props.mothersNameStore.setGivenName1}
                        disabled={this.props.isUnknown}
                        propertyIdentifier="MothersName"
                        automationId="mothersNameTextBox"
                    />
                </Ui.Flex.Item>}
            </Ui.Flex>
        );
    }
}