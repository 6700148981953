import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import UseCaseFrame from "@HisPlatform/Components/UseCaseFrame/UseCaseFrame";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import StaticHunSocialSecurityPerformanceStatementResources from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/StaticResources/StaticHunSocialSecurityPerformanceStatementResources";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import OrganizationSelector from "@HisPlatform/BoundedContexts/Organization/Components/OrganizationSelector";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ClientSideValidator from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidator";
import ClientSideValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidationBoundary";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { CareRegister } from "@HisPlatform/BoundedContexts/Care/Api/ApiConstraints.g";

interface ICloseCareActivitiesDialogDependencies {
    careActivityApiAdapter: CareActivityApiAdapter2;
    notificationService: INotificationService;
}

export interface ICloseCareActivitiesDialogProps {
    _dependencies?: ICloseCareActivitiesDialogDependencies;
}

/** @screen */
@State.observer
class CloseCareActivitiesDialog extends React.Component<ICloseCareActivitiesDialogProps> {

    private resources = StaticHunSocialSecurityPerformanceStatementResources.CloseCareActivitiesDialog;

    @State.observable.ref private closeEvent = new TypedEvent<boolean>();
    @State.observable.ref private pointOfCares: PointOfCareId[] = [];
    @State.observable.ref private period: LocalDateRange = null;

    private readonly clientSideValidator = new ClientSideValidator(CareRegister, {
        shouldBeFilled: this.resources.Validation.Required
    });

    @State.action.bound
    private close() {
        this.closeEvent.emit(false);
    }

    @State.bound
    private async closeCareActivitiesAsync() {
        let isValid = true;

        if (!this.clientSideValidator.isValid()) {
            isValid = false;
        }

        if (this.pointOfCares.length === 0) {
            this.props._dependencies.notificationService.error(this.resources.Validation.OrganizationUnitIdsShouldBeFilled);
            isValid = false;
        }

        if (!isValid) {
            return;
        }

        await this.props._dependencies.careActivityApiAdapter.closeCareActivitiesAsync(this.period, this.pointOfCares);

        this.closeEvent.emit(true);
    }

    @State.action.bound
    private onDateRangeChange(newValue: LocalDateRange) {
        this.period = newValue;
    }

    @State.action.bound
    public onPointOfCareChange(newValue: PointOfCareId, checkedValue: boolean) {
        if (this.pointOfCares) {
            if (checkedValue === false) {
                this.pointOfCares = this.pointOfCares.filter(x => x.value !== newValue.value);
            } else if (!this.pointOfCares.some(x => x.value === newValue.value)) {
                this.pointOfCares = [...this.pointOfCares, newValue];
            }
        }
    }

    public render() {
        return (
            <UseCaseFrame
                modalCloseEvent={this.closeEvent}
                title={this.resources.Title}
                modalSize="tiny"
                leftFooter={<Ui.Button text={StaticWebAppResources.Common.Button.Close} onClick={this.close} visualStyle="link" automationId="closeButton" />}
                rightFooter={<Ui.Button visualStyle="primary" text={this.resources.CloseCareActivities} onClickAsync={this.closeCareActivitiesAsync} automationId="closeCareActivitesButton" />}
            >
                <ClientSideValidationBoundary validator={this.clientSideValidator} entityTypeName="CloseCareActivities">
                    <Ui.DateRangePicker
                        label={this.resources.Period}
                        value={this.period}
                        initialSelectionState={"from"}
                        onChange={this.onDateRangeChange}
                        propertyIdentifier="Period"
                        automationId="periodPicker"
                    />
                    <OrganizationSelector
                        value={this.pointOfCares}
                        onChange={this.onPointOfCareChange}
                        filteringPropertyGroup={"HunMandatoryReportsSettings"}
                        filteringPropertyName={"PerformanceStatementEnabled"}
                        filteringPropertyValue={true}
                        isOpen
                        pointOfCareMode={true}
                        automationId="organization"
                    />
                </ClientSideValidationBoundary>
            </UseCaseFrame>
        );
    }
}

export default connect(
    CloseCareActivitiesDialog,
    new DependencyAdapter<ICloseCareActivitiesDialogProps, ICloseCareActivitiesDialogDependencies>(c => ({
        careActivityApiAdapter: c.resolve("CareActivityApiAdapter2"),
        notificationService: c.resolve("INotificationService"),
    }))
);
