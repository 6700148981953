import React from "react";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import CoverageEligibilityCheckResult from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/CheckCoverageEligibility/CoverageEligibilityCheckResult";


const CoverageEligibilityCheckResultSelectBox: React.FC<IUniversalEnumSelectorBaseProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumOrigin="server"
        enumName={"CoverageEligibilityCheckResult"}
        enumType={CoverageEligibilityCheckResult}
        displayMode="selectBox"
    />
);

export default CoverageEligibilityCheckResultSelectBox;