import React, { useCallback, useMemo } from "react";
import IActionProps from "./IActionProps";
import * as Ui from "@CommonControls";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";

interface ISecondaryActionMenuItemProps extends IActionProps {
    _activityRegistry?: IActivityRegistry;
    _permissionDenied?: boolean;

    permissionCheckOperationNames?: string;
    permissionDeniedStyle?: "disabled" | "invisible";
}


const SecondaryActionMenuItem: React.SFC<ISecondaryActionMenuItemProps> = props => {
    const callbackAsync = useCallback(async () =>
        await props.onActionAsync(props.rowId, props.commandToken, { useCaseDisplayMode: props.useCaseDisplayMode }, props.worklistActionType, props.activityReference, props.clientSideAction),
        [props.commandToken, props.onActionAsync]
    );

    const icon = useMemo(() => props._activityRegistry?.getByReference(props.activityReference)?.iconName ?? "close_x", [props.activityReference]);

    return (!!props._permissionDenied && props.permissionDeniedStyle === "invisible")
        ? <></>
        : (
            <Ui.ContextMenu.Item disabled={props.disabled || props._permissionDenied && props.permissionDeniedStyle === "disabled"} onClickAsync={callbackAsync}>
                <SpanWithIcon iconName={icon} automationId={props.automationId}>{props.title}</SpanWithIcon>
            </Ui.ContextMenu.Item>
        );
};

export default SecondaryActionMenuItem;
