import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import PerformanceReportGrouping from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReportGroupingType";

interface IPerformanceReportGroupingSelectBoxProps extends ISelectBoxBaseProps {
    value?: PerformanceReportGrouping | PerformanceReportGrouping[];
    onChange?: (newValue: PerformanceReportGrouping | PerformanceReportGrouping[]) => void;
}


const PerformanceReportGroupingSelectBox: React.FC<IPerformanceReportGroupingSelectBoxProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumName={"PerformanceReportGrouping"}
        enumOrigin="server"
        enumType={PerformanceReportGrouping}
        displayMode="selectBox"
        clearable={false}
    />
);

export default PerformanceReportGroupingSelectBox;