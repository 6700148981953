import React from "react";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import AppointmentInvalidationReason from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentInvalidationReason.g";

function AppointmentInvalidationReasonSelectBox(props: IUniversalEnumSelectorBaseProps): React.ReactElement {
    return (
        <UniversalEnumSelector
            {...props}
            enumOrigin="server"
            enumName={"AppointmentInvalidationReason"}
            enumType={AppointmentInvalidationReason}
            multiSelect
            clearable
        />
    );
}

(AppointmentInvalidationReasonSelectBox as React.FC<IUniversalEnumSelectorBaseProps>).defaultProps = {
    displayMode: "groupedRadioButtons"
};

export default AppointmentInvalidationReasonSelectBox;
