import MedicationSubsidy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationSubsidy";
import MedicationPricing from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationPricing";
import MedicationId from "@Primitives/MedicationId.g";

export default class MedicationPricingAndSubsidies {
    constructor(
        public id: MedicationId,
        public subsidies: MedicationSubsidy[],
        public pricing: MedicationPricing
    ) { }
}