import React, { useMemo } from "react";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import Styles from "./RequestedServiceStateBadge.less";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import Badge, { IBadgeProps } from "@CommonControls/Badge";
import RequestedServiceState from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/RequestedServiceState.g";

interface IRequestedServiceStateBadgeDependencies {
    localizationService?: ILocalizationService;
}

interface IRequestedServiceStateBadgeProps {
    _dependencies?: IRequestedServiceStateBadgeDependencies;
    state: RequestedServiceState;
    isCancellation?: boolean;
}

function RequestedServiceStateBadge(props: IRequestedServiceStateBadgeProps): React.ReactElement {
    const labelProps = useMemo(() => {

        if (isNullOrUndefined(props.state)) {
            return null;
        }

        const localized = props._dependencies.localizationService.localizeEnum(RequestedServiceState[props.state], "RequestedServiceState");
        const className = (() => {
            switch (props.state) {
                case RequestedServiceState.UnderRecording:
                    return Styles.stateCreated;
                case RequestedServiceState.Submitted:
                case RequestedServiceState.WaitingForSubmission:
                    return Styles.stateRequested;
                case RequestedServiceState.RegisteredAsReceived:
                    return Styles.stateRegisteredAsReceived;
                case RequestedServiceState.ActivityCompleted:
                    return Styles.stateActivityCompleted;
                case RequestedServiceState.NonValidatedCompleted:
                    return Styles.stateNonValidatedCompleted;
                case RequestedServiceState.ValidatedCompleted:
                    return Styles.stateValidatedCompleted;
                case RequestedServiceState.DeletedByExecutorSideAfterCompletion:
                case RequestedServiceState.DeletedByExecutorSideBeforeCompletion:
                case RequestedServiceState.DeletedByRequesterSide:
                case RequestedServiceState.WaitingForDelete:
                case RequestedServiceState.HasError:
                    return Styles.stateDeleted;
            }
        })();

        return {
            fluid: true,
            shape: "circle",
            upperCase: false,
            text: localized.Shorthand,
            className: combineClasses(Styles.badge, className),
            tooltipPosition: "bottom",
            tooltipContent: localized.Name,
        } as IBadgeProps;
    }, [props.state]);

    return labelProps === null ? null : (
        <div className={Styles.container}>
            <Badge {...labelProps} />
            {props.isCancellation &&
                <svg>
                    <line x1={7} y1={7} x2={22} y2={22} />
                </svg>
            }
        </div>
    );
}

export default connect(
    RequestedServiceStateBadge,
    new DependencyAdapter<IRequestedServiceStateBadgeProps, IRequestedServiceStateBadgeDependencies>(c => ({
        localizationService: c.resolve("ILocalizationService"),
    }))
);
