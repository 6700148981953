import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import Di from "@Di";
import Appointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/Appointment";
import * as Proxy from "@HisPlatform/BoundedContexts/Scheduling/Api/Proxy.g";
import SlotStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SlotStore";
import {
    GetSlotsDto,
    GetBookSlotDto,
    GetCancelSlotDto,
    CreateAppointmentDto,
    UpdateAppointmentDto,
    CancelAppointmentDto,
    ScheduleAppointmentsDto,
    GetCancelScheduledAppointmentsSlotBookingsDto,
    GetCreateScheduledAppointmentsDto,
    ComplexScheduleAppointmentsDto
} from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/SchedulingDtoMapper";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import SchedulingStoreMapper from "./SchedulingStoreMapper";
import { mapValidationResults } from "@Toolkit/CommonWeb/ApiAdapter/ValidationMapperHelpers";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import IServerCompositeValidationResult from "@Toolkit/CommonWeb/ApiAdapter/IServerCompositeValidationResult";
import PatientId from "@Primitives/PatientId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import AppointmentStatus from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentStatus.g";
import AppointmentCancellationReasonId from "@Primitives/AppointmentCancellationReasonId.g";
import AppointmentScheduleEntryId from "@Primitives/AppointmentScheduleEntryId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import AppointmentScheduleSlotSeriesId from "@Primitives/AppointmentScheduleSlotSeriesId.g";
import AppointmentScheduleSlotSeries from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/AppointmentSchedule";
import _ from "@HisPlatform/Common/Lodash";
import { buildQueryStringArray } from "@Toolkit/CommonWeb/QueryStringBuilder";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import SchedulingServiceRequestStoreMapper from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/ServiceRequestStoreMapper";
import {
    CreateServiceRequestWithAppointmentControllerDto,
    ModifyServiceRequestWithCreateAppointmentControllerDto,
    ModifyServiceRequestWithUpdateAppointmentControllerDto,
    ModifyServiceRequestWithDeleteAppointmentControllerDto,
    DeleteDraftServiceRequestWithAppointmentControllerDto
} from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/ApiAdapter/ServiceRequestDtoMapper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import ServiceRequestWithAppointment from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ServiceRequestWithAppointment";
import PractitionerId from "@Primitives/PractitionerId.g";
import SchedulingServiceId from "@Primitives/SchedulingServiceId.g";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import AppointmentFilterStore from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/DoctorPointOfCareListPanel/AppointmentFilterStore";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import SchedulePlan from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SchedulePlan";
import ScheduledAppointmentStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ScheduledAppointmentStore";
import ComplexSchedulePlan from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ComplexSchedulePlan";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import OperationProblem from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/OperationProblem";
import ProblemSeverity from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/ProblemSeverity";

const operationProblemSeverityMapping = {
    "NoEhiCompatibleIdentifierFoundError": ProblemSeverity.Warning
};

@Di.injectable()
export default class SchedulingApiAdapter extends ApiAdapterBase {

    constructor(
        @Di.inject("ISchedulingClient") private apiClient: Proxy.ISchedulingClient,
        @Di.inject("SchedulingStoreMapper") private schedulingStoreMapper: SchedulingStoreMapper,
        @Di.inject("SchedulingServiceRequestStoreMapper") private serviceRequestMapper: SchedulingServiceRequestStoreMapper,
        @Di.inject("IFormEngineReferenceDataStore") private readonly formEngineReferenceDataStore: IFormEngineReferenceDataStore,
    ) {
        super();
    }

    @State.bound
    public getSlots(filterStore: AppointmentFilterStore, dateRange: LocalDateRange, subject: SchedulingServiceId | ServiceRequestDefinitionId) {
        const slotsDto = GetSlotsDto(filterStore, dateRange, subject);
        return this.processOperationAsync(
            new SimpleStore<SlotStore[]>(),
            async target => {
                const response = await this.apiClient.getAppointmentScheduleSlotsQueryAsync(CreateRequestId(), slotsDto);
                target.operationInfo = createOperationInfo(response);
                target.value = response.slots.map(item => {
                    const store = new SlotStore();
                    store.id = item.id;
                    store.rowVersion = item.rowVersion;
                    store.from = item.interval?.from;
                    store.to = item.interval?.to;
                    store.isOverBooked = item.isOverBooked;
                    store.status = item.status;
                    store.appointmentScheduleSlotSeriesId = item.appointmentScheduleSlotSeriesId;
                    return store;
                });
            }
        );
    }

    @State.bound
    public bookSlots(appointment: Appointment, slotsToBook: SlotStore[]) {
        const bookSlotDto = GetBookSlotDto(appointment, slotsToBook);
        if (bookSlotDto.slotsToBook.length > 0) {
            return this.processOperationAsync(
                new SimpleStore<SlotStore[]>(),
                async target => {
                    const response = await this.apiClient.bookSlotsCommandAsync(CreateRequestId(), bookSlotDto);
                    target.operationInfo = createOperationInfo(response);
                    target.value = response.slots?.map(item => {
                        const store = new SlotStore();
                        store.id = item.id;
                        store.rowVersion = item.rowVersion;
                        store.from = item.interval?.from;
                        store.to = item.interval?.to;
                        store.isOverBooked = item.isOverBooked;
                        store.status = item.status;
                        store.appointmentScheduleSlotSeriesId = item.appointmentScheduleSlotSeriesId;
                        return store;
                    });
                }
            );
        }
        return null;
    }

    @State.bound
    public cancelSlotBookingAsync(appointment: Appointment, slots: SlotStore[]) {
        const cancelSlotDto = GetCancelSlotDto(appointment, slots);
        return this.processOperationAsync(
            new SimpleStore<SlotStore[]>(),
            async target => {
                const response = await this.apiClient.cancelSlotBookingsCommandAsync(CreateRequestId(), cancelSlotDto);
                target.operationInfo = createOperationInfo(response);
                target.value = response.slots.map(item => {
                    const store = new SlotStore();
                    store.id = item.id;
                    store.rowVersion = item.rowVersion;
                    store.from = item.interval?.from;
                    store.to = item.interval?.to;
                    store.isOverBooked = item.isOverBooked;
                    store.status = item.status;
                    store.appointmentScheduleSlotSeriesId = item.appointmentScheduleSlotSeriesId;
                    return store;
                });
            });
    }

    @State.bound
    public scheduleAppointments(schedulePlan: SchedulePlan, isValidateOnly: boolean) {
        const dto = ScheduleAppointmentsDto(schedulePlan, isValidateOnly);
        return this.processOperationAsync(
            new SimpleStore<{ validationResults: IClientValidationResult[], scheduledAppointments: ScheduledAppointmentStore[] }>(),
            async target => {
                const response = await this.apiClient.scheduleAppointmentsCommandAsync(CreateRequestId(), dto);

                target.value = {
                    validationResults: mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult),
                    scheduledAppointments: response.scheduledAppointments?.map(i => {
                        const scheduledAppointmentStore = new ScheduledAppointmentStore();
                        scheduledAppointmentStore.from = i.from;
                        scheduledAppointmentStore.schedulingServiceId = i.schedulingServiceId;
                        scheduledAppointmentStore.organizationUnitId = i.organizationUnitId;
                        scheduledAppointmentStore.practitionerId = i.practitionerId;
                        scheduledAppointmentStore.appointmentScheduleSlotSeriesId = i.appointmentScheduleSlotSeriesId;
                        scheduledAppointmentStore.slotsToBook = i.slots?.map(item => {
                            const store = new SlotStore();
                            store.id = item.id;
                            store.rowVersion = item.rowVersion;
                            store.from = item.interval?.from;
                            store.to = item.interval?.to;
                            store.isOverBooked = item.isOverBooked;
                            store.status = item.status;
                            store.appointmentScheduleSlotSeriesId = item.appointmentScheduleSlotSeriesId;
                            return store;
                        });

                        scheduledAppointmentStore.isBooked = i.isBooked;
                        scheduledAppointmentStore.isSelectedForCreation = i.isBooked;

                        return scheduledAppointmentStore;
                    })
                };

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public complexScheduleAppointmentsAsync(complexSchedulePlan: ComplexSchedulePlan, isValidateOnly: boolean) {
        const dto = ComplexScheduleAppointmentsDto(complexSchedulePlan, isValidateOnly);
        return this.processOperationAsync(
            new SimpleStore<{ validationResults: IClientValidationResult[], scheduledAppointments: ScheduledAppointmentStore[] }>(),
            async target => {
                const response = await this.apiClient.complexScheduleAppointmentsCommandAsync(CreateRequestId(), dto);

                target.value = {
                    validationResults: mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult),
                    scheduledAppointments: response.scheduledAppointments?.map(i => {
                        const scheduledAppointmentStore = new ScheduledAppointmentStore();
                        scheduledAppointmentStore.scheduleItemId = i.scheduleItemId,
                            scheduledAppointmentStore.from = i.from;
                        scheduledAppointmentStore.schedulingServiceId = i.schedulingServiceId;
                        scheduledAppointmentStore.organizationUnitId = i.organizationUnitId;
                        scheduledAppointmentStore.practitionerId = i.practitionerId;
                        scheduledAppointmentStore.appointmentScheduleSlotSeriesId = i.appointmentScheduleSlotSeriesId;
                        scheduledAppointmentStore.slotsToBook = i.slots?.map(item => {
                            const store = new SlotStore();
                            store.id = item.id;
                            store.rowVersion = item.rowVersion;
                            store.from = item.interval?.from;
                            store.to = item.interval?.to;
                            store.isOverBooked = item.isOverBooked;
                            store.status = item.status;
                            store.appointmentScheduleSlotSeriesId = item.appointmentScheduleSlotSeriesId;
                            return store;
                        });

                        scheduledAppointmentStore.isBooked = i.isBooked;
                        scheduledAppointmentStore.isSelectedForCreation = i.isBooked;

                        return scheduledAppointmentStore;
                    })
                };

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public createAppointment(appointment: Appointment, permissionCheckOnly: boolean = false) {
        const appointmentDto = CreateAppointmentDto(appointment, false);
        return this.processOperationAsync(
            new Appointment(true),
            async target => {
                const response = await this.apiClient.createAppointmentCommandAsync(CreateRequestId(), appointmentDto, permissionCheckOnly);

                this.schedulingStoreMapper.applyToStore(target, response);
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);

                target.isNew = !target.isPersistedByOperationInfo;
            }
        );
    }

    @State.bound
    public createScheduledAppointmentsAsync(
        scheduledAppointmentsForCreation: ScheduledAppointmentStore[],
        scheduledAppointmentsForCancellation: ScheduledAppointmentStore[],
        patientId: PatientId) {

        const createScheduledAppointmentsDto = GetCreateScheduledAppointmentsDto(
            scheduledAppointmentsForCreation,
            scheduledAppointmentsForCancellation,
            patientId);

        return this.processOperationAsync(
            new SimpleStore<any>(),
            async target => {
                const response = await this.apiClient.createScheduledAppointmentsCommandAsync(CreateRequestId(), createScheduledAppointmentsDto);
                target.operationInfo = createOperationInfo(response);
            });
    }

    @State.bound
    public cancelScheduledAppointmentsSlotBookingsAsync(scheduledAppointments: ScheduledAppointmentStore[]) {
        const cancelScheduledAppointmentsSlotBookingsDto = GetCancelScheduledAppointmentsSlotBookingsDto(scheduledAppointments);
        return this.processOperationAsync(
            new SimpleStore<any>(),
            async target => {
                const response = await this.apiClient.cancelScheduledAppointmentsSlotBookingsCommandAsync(CreateRequestId(), cancelScheduledAppointmentsSlotBookingsDto);
                target.operationInfo = createOperationInfo(response);
            });
    }

    @State.bound
    public renewSubmittedServiceRequestWithAppointment(
        id: ServiceRequestId,
        activeCareActivityId: CareActivityId,
        rowVersion: RowVersion,
        requestLock: boolean,
        lockId: LockId,
        extensionData: any,
        isPermissionCheckOnly: boolean,
        appointmentId: AppointmentScheduleEntryId,
        cancellationNote: string
    ) {
        return this.processOperationAsync(
            new SimpleStore<Proxy.RenewSubmittedServiceRequestWithAppointmentCommandResponse>(),
            async target => {
                const response = await this.apiClient.renewSubmittedServiceRequestWithAppointmentCommandAsync(CreateRequestId(),
                    new Proxy.RenewSubmittedServiceRequestWithAppointmentControllerDto({
                        activeCareActivityId: activeCareActivityId,
                        serviceRequestId: id,
                        serviceRequestRowVersion: rowVersion,
                        requestLock: requestLock,
                        lockId: lockId,
                        releaseLockIfSuccessful: false,
                        extensionData: extensionData,
                        appointmentScheduleEntryId: appointmentId,
                        appointmentCancellationNote: cancellationNote
                    }), isPermissionCheckOnly);

                target.operationInfo = createOperationInfo(response);
                target.value = response;
            }
        );
    }

    @State.bound
    public createServiceRequestWithAppointment(
        serviceRequest: ServiceRequestStore,
        shouldSubmitServiceRequest: boolean,
        requestLock: boolean,
        appointment: Appointment,
        isEmpty: boolean
    ) {
        const dto = CreateServiceRequestWithAppointmentControllerDto(
            serviceRequest,
            shouldSubmitServiceRequest,
            requestLock,
            appointment,
            false,
            isEmpty);
        return this.processOperationAsync(
            new SimpleStore<ServiceRequestWithAppointment>(new ServiceRequestWithAppointment()),
            async target => {
                const response = await this.apiClient.createServiceRequestWithAppointmentCommandAsync(CreateRequestId(), dto);

                target.value.appointment = new Appointment(true);
                target.value.serviceRequest = new ServiceRequestStore(true);

                this.schedulingStoreMapper.applyToStore(target.value.appointment, response);
                this.serviceRequestMapper.applyToStore(target.value.serviceRequest, response);

                target.value.setValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
                target.value.setOperationInfo(target.operationInfo);
                target.value.problems = !!response.extensionData && this.mapOperationProblems(response.extensionData["problems"]);

                target.value.appointment.isNew = !target.isPersistedByOperationInfo;
                target.value.serviceRequest.isNew = !target.isPersistedByOperationInfo;
            }
        );
    }

    @State.bound
    public validateServiceRequestWithAppointment(
        serviceRequest: ServiceRequestStore,
        appointment: Appointment,
        isEmpty: boolean
    ) {
        const dto = CreateServiceRequestWithAppointmentControllerDto(serviceRequest, false, false, appointment, true, isEmpty);
        return this.processOperationAsync(
            new SimpleStore<ServiceRequestWithAppointment>(new ServiceRequestWithAppointment()),
            async target => {
                const response = await this.apiClient.createServiceRequestWithAppointmentCommandAsync(CreateRequestId(), dto);

                target.value.appointment = new Appointment();
                target.value.serviceRequest = new ServiceRequestStore();

                target.value.setValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
                target.value.setOperationInfo(target.operationInfo);
            }
        );
    }

    @State.bound
    public modifyServiceRequestWithCreateAppointment(
        careActivityId: CareActivityId | null,
        serviceRequestStore: ServiceRequestStore | null,
        shouldSubmitServiceRequest: boolean | null,
        appointmentStore: Appointment | null,
        lockId: LockId | null,
        releaseLockIfSuccessful: boolean,
        extensionData?: any | null
    ) {
        const dto = ModifyServiceRequestWithCreateAppointmentControllerDto(
            careActivityId,
            serviceRequestStore,
            shouldSubmitServiceRequest,
            appointmentStore,
            lockId,
            releaseLockIfSuccessful,
            false,
            extensionData
        );
        return this.processOperationAsync(
            new SimpleStore<ServiceRequestWithAppointment>(new ServiceRequestWithAppointment()),
            async target => {
                const response = await this.apiClient.modifyServiceRequestWithCreateAppointmentCommandAsync(CreateRequestId(), dto);

                target.value.appointment = new Appointment(true);
                target.value.serviceRequest = new ServiceRequestStore(false);

                this.schedulingStoreMapper.applyToStore(target.value.appointment, response);
                this.serviceRequestMapper.applyToStore(target.value.serviceRequest, response);

                target.value.possibleServiceRequestManagementActions = response.possibleServiceRequestManagementActions;

                target.value.setValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
                target.value.setOperationInfo(target.operationInfo);
                target.value.problems = !!response.extensionData && this.mapOperationProblems(response.extensionData["problems"]);

                target.value.appointment.isNew = !target.isPersistedByOperationInfo;
            }
        );
    }

    @State.bound
    public modifyServiceRequestWithUpdateAppointment(
        careActivityId: CareActivityId | null,
        serviceRequestStore: ServiceRequestStore | null,
        shouldSubmitServiceRequest: boolean | null,
        appointmentStore: Appointment | null,
        lockId: LockId | null,
        releaseLockIfSuccessful: boolean,
        extensionData?: any | null
    ) {
        const dto = ModifyServiceRequestWithUpdateAppointmentControllerDto(
            careActivityId,
            serviceRequestStore,
            shouldSubmitServiceRequest,
            appointmentStore.id,
            appointmentStore,
            appointmentStore.rowVersion,
            lockId,
            releaseLockIfSuccessful,
            false,
            extensionData
        );
        return this.processOperationAsync(
            new SimpleStore<ServiceRequestWithAppointment>(new ServiceRequestWithAppointment()),
            async target => {
                const response = await this.apiClient.modifyServiceRequestWithUpdateAppointmentCommandAsync(CreateRequestId(), dto);

                target.value.appointment = new Appointment(false);
                target.value.serviceRequest = new ServiceRequestStore(false);

                this.schedulingStoreMapper.applyToStore(target.value.appointment, response);
                this.serviceRequestMapper.applyToStore(target.value.serviceRequest, response);

                target.value.possibleServiceRequestManagementActions = response.possibleServiceRequestManagementActions;

                target.value.setValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
                target.operationInfo = createOperationInfo(response);
                target.value.setOperationInfo(target.operationInfo);
                target.value.problems = !!response.extensionData && this.mapOperationProblems(response.extensionData["problems"]);
            }
        );
    }

    @State.bound
    public modifyServiceRequestWithDeleteAppointment(
        careActivityId: CareActivityId | null,
        serviceRequestStore: ServiceRequestStore | null,
        shouldSubmitServiceRequest: boolean | null,
        appointmentScheduleEntryId: AppointmentScheduleEntryId | null,
        rowVersion: RowVersion | null,
        lockId: LockId | null,
        releaseLockIfSuccessful: boolean,
        extensionData?: any | null
    ) {
        const dto = ModifyServiceRequestWithDeleteAppointmentControllerDto(
            careActivityId,
            serviceRequestStore,
            shouldSubmitServiceRequest,
            appointmentScheduleEntryId,
            rowVersion,
            lockId,
            releaseLockIfSuccessful,
            false,
            extensionData
        );
        return this.processOperationAsync(
            new ServiceRequestStore(false),
            async target => {
                const response = await this.apiClient.modifyServiceRequestWithDeleteAppointmentCommandAsync(CreateRequestId(), dto);

                this.serviceRequestMapper.applyToStore(target, response);
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    @State.bound
    public deleteDraftServiceRequestWithAppointmentAsync(
        appointmentScheduleEntryId: AppointmentScheduleEntryId | null,
        appointmentRowVersion: RowVersion | null,
        serviceRequestId: ServiceRequestId | null,
        activeCareActivityId: CareActivityId | null,
        serviceRequestRowVersion: RowVersion | null,
        lockId: LockId | null,
        releaseLockIfSuccessful: boolean
    ) {
        const dto = DeleteDraftServiceRequestWithAppointmentControllerDto(
            appointmentScheduleEntryId,
            appointmentRowVersion,
            serviceRequestId,
            activeCareActivityId,
            serviceRequestRowVersion,
            lockId,
            releaseLockIfSuccessful
        );
        return this.processOperationAsync(
            new SimpleStore<ServiceRequestWithAppointment>(new ServiceRequestWithAppointment()),
            async target => {
                const response = await this.apiClient.deleteDraftServiceRequestWithAppointmentCommandAsync(CreateRequestId(), dto);
                target.operationInfo = createOperationInfo(response);
                target.value.serviceRequest = new ServiceRequestStore();
                target.value.serviceRequest.operationInfo = target.operationInfo;
            }
        );
    }

    @State.bound
    public async updateAppointmentAsync(appointment: Appointment, permissionCheckOnly: boolean = false) {
        const appointmentDto = UpdateAppointmentDto(appointment);
        return await this.processOperationAsync(
            new Appointment(false),
            async target => {
                const response = await this.apiClient.updateAppointmentCommandAsync(CreateRequestId(), appointmentDto, permissionCheckOnly);

                this.schedulingStoreMapper.applyToStore(target, response);
                target.validationResults = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    @State.bound
    public async getAppointmentByIdAsync(id: AppointmentScheduleEntryId) {
        return await this.processOperationAsync(
            new Appointment(false),
            async target => {
                const response = await this.apiClient.getAppointmentByIdQueryAsync(CreateRequestId(), id.value);
                this.schedulingStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public async getAppointmentByIdForPractitionerScheduleAsync(id: AppointmentScheduleEntryId) {
        return await this.processOperationAsync(
            new Appointment(false),
            async target => {
                const response = await this.apiClient.getAppointmentByIdForPractitionerScheduleQueryAsync(CreateRequestId(), id.value);
                this.schedulingStoreMapper.applyToStore(target, response);
            }
        );
    }

    @State.bound
    public async getAppointmentByIdPermissionCheckAsync() {
        return await this.processOperationAsync(
            new SimpleStore<Appointment>(),
            async target => {
                const response = await this.apiClient.getAppointmentByIdQueryAsync(CreateRequestId(), "-1", true);

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public async tryGetAppointmentBySubjectServiceRequestIdAsync(id: ServiceRequestId) {
        return await this.processOperationAsync(
            new Appointment(true),
            async target => {
                const response = await this.apiClient.tryGetAppointmentBySubjectServiceRequestIdQueryAsync(CreateRequestId(), id.value);
                this.schedulingStoreMapper.applyToStore(target, response);
                target.isNew = !response.hasValue;
            }
        );
    }

    @State.bound
    public async validateAsync(appointment: Appointment) {
        const appointmentDto = CreateAppointmentDto(appointment, true);
        return await this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response = await this.apiClient.createAppointmentCommandAsync(CreateRequestId(), appointmentDto);
                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    @State.bound
    public getAppointmentsForRegisteredPatientAsync(patientId: PatientId, onlyActualFilter: boolean, statusFilters: AppointmentStatus[]): Promise<SimpleStore<Appointment[]>> {
        return this.processOperationAsync(
            new SimpleStore<Appointment[]>(),
            async target => {
                const response = await this.apiClient.getAppointmentsForRegisteredPatientQueryAsync(CreateRequestId(),
                    new Proxy.GetAppointmentsForRegisteredPatientControllerDto({
                        patientId: patientId,
                        onlyActualFilter: onlyActualFilter,
                        statusFilters: statusFilters
                    }));

                target.value = response.appointments.map(item => {
                    const store = new Appointment(false);
                    this.schedulingStoreMapper.applyToStore(store, item);
                    return store;
                });
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getAppointmentsForPractitionerAsync(practitionerId: PractitionerId, dateRange: LocalDateRange) {
        return this.processOperationAsync(
            new SimpleStore<Appointment[]>(),
            async target => {
                const response = await this.apiClient.getResourceUsagesForResourcesQueryAsync(CreateRequestId(),
                    new Proxy.GetResourceUsagesForResourcesControllerDto({
                        interval: new Proxy.DateIntervalDto({
                            from: dateRange.from,
                            to: dateRange.to
                        }),
                        resources: [new Proxy.PractitionerResource({

                            practitionerId: practitionerId
                        })]
                    }));

                const appointments: Appointment[] = [];

                const appointmentIds = response.resourceUsages.map(r => r.referringAppointmentScheduleEntry).filter(e => !!e);
                for (const scheduleEntryId of appointmentIds) {
                    const appointment = await this.getAppointmentByIdForPractitionerScheduleAsync(scheduleEntryId);
                    appointments.push(appointment);
                }

                target.value = appointments;
                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public getAppointmentsForPractitionerPermissionCheckAsync() {
        return this.processOperationAsync(
            new SimpleStore<Appointment[]>(),
            async target => {
                const response = await this.apiClient.getResourceUsagesForResourcesQueryAsync(CreateRequestId(),
                    new Proxy.GetResourceUsagesForResourcesControllerDto({
                        interval: new Proxy.DateIntervalDto({
                            from: LocalDate.today(),
                            to: LocalDate.today()
                        }),
                        resources: [new Proxy.PractitionerResource({ practitionerId: new PractitionerId("-1") })]
                    }),
                    true);

                target.operationInfo = createOperationInfo(response);
            }
        );
    }

    @State.bound
    public loadAppointmentCancellationReasonIds(): Promise<SimpleStore<AppointmentCancellationReasonId[]>> {
        return this.loadExtensibleEnumAsync(
            (...args) => this.apiClient.getAppointmentCancellationReasonIdsQueryAsync(...args),
            response => response.appointmentCancellationReasonIds
        );
    }

    @State.bound
    public cancelAppointment(
        appointmentId: AppointmentScheduleEntryId,
        cancellationReasonId: AppointmentCancellationReasonId,
        note: string,
        validateOnly: boolean): Promise<SimpleStore<IClientValidationResult[]>> {
        const dto = CancelAppointmentDto(cancellationReasonId, appointmentId, note, validateOnly);
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response = await this.apiClient.cancelAppointmentCommandAsync(CreateRequestId(), dto);

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    @State.bound
    public cancelAppointmentPermissionCheckAsync(): Promise<SimpleStore<IClientValidationResult[]>> {
        const dto = CancelAppointmentDto(null, new AppointmentScheduleEntryId("1"), "", true);
        return this.processOperationAsync(
            new SimpleStore<IClientValidationResult[]>(),
            async target => {
                const response = await this.apiClient.cancelAppointmentCommandAsync(CreateRequestId(), dto, true);

                target.operationInfo = createOperationInfo(response);
                target.value = mapValidationResults(response.compositeValidationResult as unknown as IServerCompositeValidationResult);
            }
        );
    }

    @State.bound
    public getAllAppointmentScheduleIdsAsync(): Promise<SimpleStore<AppointmentScheduleSlotSeriesId[]>> {
        return this.processOperationAsync(
            new SimpleStore<AppointmentScheduleSlotSeriesId[]>(),
            async target => {
                const response = await this.apiClient.getAllAppointmentScheduleSlotSeriesIdsQueryAsync(
                    CreateRequestId()
                );
                target.operationInfo = createOperationInfo(response);
                target.value = response.ids;
            }
        );
    }

    @State.bound
    public getAppointmentSchedulesByIds(ids: AppointmentScheduleSlotSeriesId[]): Promise<SimpleStore<AppointmentScheduleSlotSeries[]>> {
        return this.processOperationAsync(
            new SimpleStore<AppointmentScheduleSlotSeries[]>(),
            async target => {
                const normalizedIds = _.uniq(ids.filter(id => !!id).map(id => id.value));
                const response = await this.apiClient.getAppointmentScheduleSlotSeriesByIdsQueryAsync(
                    CreateRequestId(),
                    buildQueryStringArray(normalizedIds)
                );

                target.operationInfo = createOperationInfo(response);
                target.value = response.appointmentScheduleSlotSeriesDtos.map((item) => {
                    const newStore = new AppointmentScheduleSlotSeries();
                    newStore.id = item.appointmentScheduleSlotSeriesId;
                    item.resources.forEach(resource => {
                        if (resource instanceof Proxy.OrganizationUnitResource) {
                            newStore.locationParticipants.push(resource.organizationUnitId);
                        }
                        if (resource instanceof Proxy.PractitionerResource) {
                            newStore.practitionerParticipants.push(resource.practitionerId);
                        }
                    });
                    newStore.allowedSchedulingServices = item.allowedSchedulingServiceIds ?? [];
                    newStore.allowedServiceRequestDefinitions = item.allowedServiceRequestDefinitionIds ?? [];
                    newStore.appointmentScheduleDefinitionId = item.appointmentScheduleDefinitionId;
                    return newStore;
                });
            }
        );
    }

    @State.bound
    public connectAppointmentToCareActivityAsync(careActivityId: CareActivityId, appointmentScheduleEntryId: AppointmentScheduleEntryId, rowVersion: RowVersion) {
        return this.processOperationAsync(
            new SimpleStore<boolean>(),
            async target => {
                const response = await this.apiClient.connectAppointmentToCareActivityCommandAsync(CreateRequestId(),
                    new Proxy.ConnectAppointmentToCareActivityControllerDto({
                        careActivityId: careActivityId,
                        appointmentScheduleEntryId: appointmentScheduleEntryId,
                        rowVersion: rowVersion
                    }));
                target.operationInfo = createOperationInfo(response);
                target.value = response.isPersisted;
            });
    }

    @State.bound
    public disconnectAppointmentToCareActivityAsync(careActivityId: CareActivityId, appointmentScheduleEntryId: AppointmentScheduleEntryId, rowVersion: RowVersion) {
        return this.processOperationAsync(
            new SimpleStore<boolean>(),
            async target => {
                const response = await this.apiClient.disconnectAppointmentFromCareActivityCommandAsync(CreateRequestId(),
                    new Proxy.ConnectAppointmentToCareActivityControllerDto({
                        careActivityId: careActivityId,
                        appointmentScheduleEntryId: appointmentScheduleEntryId,
                        rowVersion: rowVersion
                    }));
                target.operationInfo = createOperationInfo(response);
                target.value = response.isPersisted;
            });
    }

    private mapOperationProblems(problemIds: string[]): OperationProblem[] {
        if (isNullOrUndefined(problemIds)) {
            return [];
        }

        const problems: OperationProblem[] = [];
        for (const problemId of problemIds) {
            const problem = new OperationProblem();
            problem.type = problemId;
            problem.severity = problemId in operationProblemSeverityMapping
                ? operationProblemSeverityMapping[problemId]
                : ProblemSeverity.Error;

            problems.push(problem);
        }

        return problems;
    }
}