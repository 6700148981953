import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import TokenUsageStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/TokenUsageStore";
import TokenSettingsPanelRegistry, { ITokenSettings } from "@HisPlatform/BoundedContexts/DocumentManagement/Services/TokenSettingsPanelRegistry/TokenSettingsPanelRegistry";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ITokenSettingsPanelRegistry from "@PluginInterface/BoundedContexts/DocumentManagement/TokenSettingsPanelRegistry/ITokenSettingsPanelRegistry";
import Button from "@CommonControls/Button";
import StaticDocumentManagementResources from "@HisPlatform/BoundedContexts/DocumentManagement/StaticResources/StaticDocumentManagementResources";
import ITemplateToken from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/ITemplateToken";

interface ITokenSettingsDependencies {
    tokenSettingsPanelRegistry: TokenSettingsPanelRegistry;
}

interface ITokenSettingsProps {
    _dependencies?: ITokenSettingsDependencies;
    tokenUsage: TokenUsageStore;
    token: ITemplateToken;
    onRemoveTokenAsync: () => Promise<void>;
    onUpdateTokenDisplayName: (tokenUsage: TokenUsageStore, newDisplayName: string) => void;
}

@State.observer
class TokenUsageSettings extends React.Component<ITokenSettingsProps> {
    public render() {
        return (
            <>
                {this.props._dependencies!.tokenSettingsPanelRegistry.render(
                    {
                        tokenFormatterSettings: this.props.tokenUsage.tokenFormatterSettings,
                        providerParameterSettings: this.props.tokenUsage.providerParameterSettings,
                        onSettingsChange: this.props.tokenUsage.setSettings,
                        onUpdateTokenDisplayName: this.setTokenDisplayName
                    } as ITokenSettings, this.props.token)}
                <Button
                    iconName="trash"
                    text={StaticDocumentManagementResources.TemplateManagement.RemoveToken}
                    onClickAsync={this.props.onRemoveTokenAsync}
                    visualStyle="negative-standard"
                    float="right"
                    automationId="removeTokenButton"
                />
            </>
        );
    }

    @State.bound
    private setTokenDisplayName(newName: string) {
        this.props.onUpdateTokenDisplayName(this.props.tokenUsage, newName);
    }
}

export default connect(
    TokenUsageSettings,
    new DependencyAdapter<ITokenSettingsProps, ITokenSettingsDependencies>(c => ({
        tokenSettingsPanelRegistry: c.resolve<TokenSettingsPanelRegistry>("ITokenSettingsPanelRegistry")
    }))
);