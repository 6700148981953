import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDisposable from "./IDisposable";

export type IListener<T> = (event?: T) => any;

export class TypedEvent<T = void> implements IDisposable {
    private listeners: Array<IListener<T>> = [];
    private listenersOnce: Array<IListener<T>> = [];

    /** Attaches a handler to this event. Returns a disposable object which can be used to detach that handler. */
    public on(listener: IListener<T>): IDisposable {
        this.assertNotDisposed();
        this.listeners.push(listener);
        return {
            dispose: () => this.off(listener)
        };
    }

    /** Attaches a one-time handler to this event. After the first time it will be detached. */
    public once(listener: IListener<T>): void {
        this.assertNotDisposed();
        this.listenersOnce.push(listener);
    }

    /** Detaches an event handler. */
    public off(listener: IListener<T>) {
        this.assertNotDisposed();
        const callbackIndex = this.listeners.indexOf(listener);
        if (callbackIndex > -1) {
            this.listeners.splice(callbackIndex, 1);
        }
    }

    /** Raise this event. */
    @State.bound
    public emit(event?: T) {
        this.assertNotDisposed();
        /** Update any general listeners */
        this.listeners.forEach((listener) => listener(event));

        /** Clear the `once` queue */
        this.listenersOnce.forEach((listener) => listener(event));
        this.listenersOnce = [];
    }

    /** Pipe this event to another one. When this event emitted, the other will be emitted too. */
    public pipe(targetEvent: TypedEvent<T>): IDisposable {
        this.assertNotDisposed();
        return this.on((e) => targetEvent.emit(e));
    }

    private assertNotDisposed() {
        if (this.listeners === null) {
            throw new Error("TypedEvent is disposed.");
        }
    }

    /** Removes all event handlers */
    public dispose() {
        this.listeners = null;
        this.listenersOnce = null;
    }
}