import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import * as Ui from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { formatStringWithObjectParams } from "@Toolkit/CommonWeb/Formatters";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import MedicationSubsidyClaimTypeColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/MedicationSubsidyClaimTypeColumn";
import { IEmptyStateSettings } from "@CommonControls/DataGrid/IDataGridProps";
import Styles from "./PrescriptionsToBeCreated.less";
import { IPrescriptionsToBeCreatedListItem } from "./IPrescriptionsToBeCreatedListItem";

interface IPrescriptionsToBeCreatedProps {
    items: IPrescriptionsToBeCreatedListItem[];
    removeMedicationItem: (row: IPrescriptionsToBeCreatedListItem) => void;
}

@State.observer
export default class PrescriptionsToBeCreated extends React.Component<IPrescriptionsToBeCreatedProps> {
    private readonly defaultEmptyStateSettings: IEmptyStateSettings = Object.freeze({
        title: "",
        showEmptyIcon: false
    });

    @State.observable.ref private dataSource: InMemoryDataGridDataSource = new InMemoryDataGridDataSource(() => this.props.items);

    public render() {
        return (
            <>
                <div className={Styles.outerContainer}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <span style={{ fontWeight: "bold" }}>{StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.PrescriptionsToBeCreated.title}</span>
                        <span>{this.getSubtitle()}</span>
                    </div>
                    <div className={Styles.innerContainer}>
                        <Ui.DataGrid
                            dataSource={this.dataSource}
                            actionsColumn={false}
                            rowHeight={43}
                            hasHeader={false}
                            footer={null}
                            maxHeight={"250px"}
                            emptyStateSettings={this.defaultEmptyStateSettings}
                            automationId="prescriptionsToBeCreated_dataGrid">
                            <DataGridColumn
                                id="Action"
                                onRenderCellValue={this.renderAction}
                                width={75} />
                            <DataGridColumn
                                id={"MedicationName"}
                                dataGetter={"name"} />
                            <MedicationSubsidyClaimTypeColumn
                                dataGetter={"claimType"}
                                id={"ClaimType"}
                                displayMode={"code"} />
                        </Ui.DataGrid>
                    </div>
                </div>
            </>
        );
    }

    @State.bound
    private renderAction(value: any, row: IPrescriptionsToBeCreatedListItem) {
        return <Ui.Button
                    iconName={"minus"}
                    visualStyle={"negative-standard"}
                    onClick={() => this.props.removeMedicationItem(row)}
                    automationId={row.name + "_removeButton"} />;
    }

    @State.bound
    private getSubtitle() {
        return formatStringWithObjectParams(StaticHunSocialSecurityMedicationRequestResources.PrescriptionListPanel.PrescriptionsToBeCreated.prescriptionsTotalCount, {
            totalCount: this.dataSource.data.totalCount.toString(),
        });
    }
}
