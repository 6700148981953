import React from "react";
import IPatientsCareActivityListCareOrganizationUnitColumnExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientsCareActivityListCareOrganizationUnitColumnExtensionPointProps";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PatientsCareActivityListCareOrganizationUnitColumn from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/PatientsCareActivityListCareOrganizationUnitColumnExtension/PatientsCareActivityListCareOrganizationUnitColumn";

@State.observer
export default class PatientsCareActivityListCareOrganizationUnitColumnExtension extends React.Component<IPatientsCareActivityListCareOrganizationUnitColumnExtensionPointProps> {
    public render() {
        return (
            <PatientsCareActivityListCareOrganizationUnitColumn
                header={this.props.header}
                displayOrder={this.props.displayOrder}
            />
        );
    }
}