import Di from "@Di";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import PractitionerRecommendationApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/PractitionerRecommendation/PractitionerRecommendationApiAdapter";
import ReferenceDataStoreBase from "@Toolkit/CommonWeb/Model/ReferenceDataStoreBase";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import PrescriptionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionApiAdapter";
import ReferenceDataStore from "@Toolkit/CommonWeb/ReferenceData/ReferenceDataStore";
import ReferenceDataApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/ReferenceDataApiAdapter";
import MedicationSubsidyClaimTypeId from "@Primitives/MedicationSubsidyClaimTypeId.g";
import IMedicationSubsidyClaimType from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/IMedicationSubsidyClaimType";
import MedicationSubsidy from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/MedicationSubsidy";
import MedicationSubsidyId from "@Primitives/MedicationSubsidyId.g";
import MedicationSubsidyColumnData from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/DataGridColumns/MedicationSubsidyColumnData";
import _ from "@HisPlatform/Common/Lodash";
import OrderTypeId from "@Primitives/OrderTypeId.g";
import TargetValueId from "@Primitives/TargetValueId.g";
import SubstanceBasedRestrictionId from "@Primitives/SubstanceBasedRestrictionId.g";
import ISubstanceBasedRestriction from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/ISubstanceBasedRestriction";
import TemporalReferenceDataStore from "@Toolkit/CommonWeb/TemporalData/TemporalReferenceDataStore";
import PackagingUnitId from "@Primitives/PackagingUnitId.g";
import PackagingUnit from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/PackagingUnit";
import DesignatedOrganizationId from "@Primitives/DesignatedOrganizationId.g";
import DesignatedOrganization from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/ReferenceData/DesignatedOrganization";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";

@Di.injectable()
export default class MedicationRequestReferenceDataStore extends ReferenceDataStoreBase {
    constructor(
        @Di.inject("PractitionerRecommendationApiAdapter") private practitionerRecommendationApiAdapter: PractitionerRecommendationApiAdapter,
        @Di.inject("PrescriptionApiAdapter") private prescriptionApiAdapter: PrescriptionApiAdapter,
        @Di.inject("ReferenceDataApiAdapter") private referenceDataApiAdapter: ReferenceDataApiAdapter,
        @Di.inject("ILocalizationService") private localizationService: ILocalizationService,
        @Di.inject("IReferenceDataLoader") private referenceDataLoader: IReferenceDataLoader
    ) {
        super();
        referenceDataLoader.register<MedicationSubsidyColumnData>("MedicationSubsidyDataDto", refs =>
            this.medicationSubsidyClaimTypes.ensureLoadedAsync(_.flatten(refs.map(i => i.medicationSubsidyClaimTypes))));
    }

    public equipmentPosition = new ExtensibleEnumStore(this.prescriptionApiAdapter.getEquipmentPositionIds, this.localizationService.localizeEnumId);
    public orderTypes = new ExtensibleEnumStore<OrderTypeId>(this.prescriptionApiAdapter.loadOrderTypeIdsAsync, this.localizationService.localizeEnumId);
    public targetValues = new ExtensibleEnumStore<TargetValueId>(this.prescriptionApiAdapter.loadTargetValueIdsAsync, this.localizationService.localizeEnumId);
    public equipmentSupportType = new ExtensibleEnumStore(this.practitionerRecommendationApiAdapter.loadEquipmentSupportTypeIdsAsync, this.localizationService.localizeEnumId);
    public ehiPrescriptionStatuses = new ExtensibleEnumStore(this.prescriptionApiAdapter.loadEhiPrescriptionStatusIdsAsync, this.localizationService.localizeEnumId);

    public medicationSubsidyClaimTypes = new ReferenceDataStore<MedicationSubsidyClaimTypeId, IMedicationSubsidyClaimType>(
        this.referenceDataApiAdapter.getMedicationSubsidyClaimTypesByIdsAsync,
        this.referenceDataApiAdapter.getAllMedicationSubsidyClaimTypeIds
    );

    public packagingUnits = new ReferenceDataStore<PackagingUnitId, PackagingUnit>(
        this.referenceDataApiAdapter.getPackagingUnitdsByIds,
        this.referenceDataApiAdapter.getAllPackagingUnitIdsAsync
    );

    public medicationSubsidies = new TemporalReferenceDataStore<MedicationSubsidyId, MedicationSubsidy>(
        this.referenceDataApiAdapter.getMedicationSubsidyVersionBySelectorsAsync
    );

    public substanceBasedRestrictions = new ReferenceDataStore<SubstanceBasedRestrictionId, ISubstanceBasedRestriction>(
        this.referenceDataApiAdapter.getSubstanceBasedRestrictionsByIdsAsync
    );

    public designatedOrganizations = new TemporalReferenceDataStore<DesignatedOrganizationId, DesignatedOrganization>(
        this.referenceDataApiAdapter.getDesignatedOrganizationVersionBySelectorsAsync
    );

    public organizationRestrictionTypes = new ExtensibleEnumStore(this.referenceDataApiAdapter.getOrganizationRestrictionTypesAsync, this.localizationService.localizeEnumId);
    public medicationSubsidizedPricingTypes = new ExtensibleEnumStore(this.referenceDataApiAdapter.getMedicationSubsidizedPricingTypesAsync, this.localizationService.localizeEnumId);

}