import React from "react";
import _ from "@HisPlatform/Common/Lodash";
import Styles from "./Tab.less";
import CompositeClassName from "@Toolkit/ReactClient/Common/CompositeClassName";
import Flex from "@CommonControls/Flex";
import { ITabPaneProps } from "@CommonControls/TabPane";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import { arrayIsNullOrEmpty, emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IValidationBoundaryStore from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationBoundaryStore";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import ValidationBoundaryAdapter from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundaryAdapter";

interface ITabProps {
    _validationContext?: IValidationBoundaryStore;
    defaultActiveIndex?: number | string;
    activeIndex?: number | string;
    onSelectedIndexChange?: (newIndex: number | string) => void;
    automationid?: string;
    noContent?: boolean;
    headerTextAlign?: "left" | "center" | "right";
    tabsGrow?: boolean;
    style?: React.CSSProperties;
    className?: string;
    contentClassName?: string;
    contentStyle?: React.CSSProperties;
    children?: React.ReactElement[];
    visualStyle?: "normal" | "pageBox" | "menu";
    childContainerClassName?: string;
}

@State.observer
class Tab extends React.Component<ITabProps> {

    public static defaultProps: Partial<ITabProps> = {
        tabsGrow: true,
        visualStyle: "normal"
    };

    @State.observable private internalSelectedIndex: number | string = this.props.defaultActiveIndex || 0;

    @State.computed
    private get tabValidationStates(): string[] {
        const result = React.Children.map(this.validChildren, (child: React.ReactElement) => {
            const childAsTabPane = child as unknown as React.Component<ITabPaneProps>;

            if (!!childAsTabPane?.props?.propertyPathRegexPattern && this.hasErrors(childAsTabPane.props)) {
                return "error";
            } else if (!!childAsTabPane?.props?.propertyPathRegexPattern && this.hasWarnings(childAsTabPane.props)) {
                return "warning";
            } else {
                return "none";
            }
        });
        return result;
    }

    @State.computed
    private get validChildren(): React.ReactElement[] {
        if (!this.props.children) {
            return [];
        }

        return this.props.children.filter(child => !!child);
    }

    @State.bound
    private hasErrors(childProps: ITabPaneProps) {
        return !arrayIsNullOrEmpty(this.getValidationResults(childProps).filter((v: { severity: string; }) => v.severity === "error"));
    }

    @State.bound
    private hasWarnings(childProps: ITabPaneProps) {
        return !arrayIsNullOrEmpty(this.getValidationResults(childProps).filter((v: { severity: string; }) => v.severity === "warning"));
    }

    @State.bound
    private getValidationResults(childProps: ITabPaneProps) {
        if (this.props._validationContext && childProps.propertyPathRegexPattern) {
            const regex = new RegExp(childProps.propertyPathRegexPattern);
            return this.props._validationContext.getValidationProblemsByRegex(regex);
        }
        return emptyArray;
    }

    constructor(props: ITabProps) {
        super(props);
        if (this.props.activeIndex !== undefined && this.props.defaultActiveIndex !== undefined) {
            throw new Error("defaultActiveIndex property is not allowed to use in controlled mode!");
        }
    }

    private get selectedIndex() {
        return this.props.activeIndex === undefined ? this.internalSelectedIndex : this.props.activeIndex;
    }

    public render() {

        const tabContainerClassName = new CompositeClassName(Styles.tabContainer, this.props.className);
        tabContainerClassName.addIf(this.props.visualStyle && this.props.visualStyle === "pageBox", Styles.tabContainerAlternate);
        tabContainerClassName.addIf(this.props.visualStyle && this.props.visualStyle === "menu", Styles.tabContainerMenu);

        return (
            <>
                <div
                    className={tabContainerClassName.classNames}
                    data-automation-id={this.props.automationid || undefined}
                    style={this.props.style}
                >
                    <Flex innerSpacing="none" outerSpacing="none" verticalSpacing="none" noWrap style={{
                        width: "100%",
                        textAlign: this.props.headerTextAlign
                    }}>
                        {React.Children.map(this.validChildren, (child: React.ReactElement, index: number) => {
                            if (!child) {
                                return null;
                            }

                            const clickHandler = child?.props?.disabled ? undefined : this.tabClickHandlerFactory(child.props?.name ?? index);
                            const childAsTabPane = child as unknown as React.Component<ITabPaneProps>;
                            const tabClassName = new CompositeClassName(this.getStyleIfSelected(child.props?.name ?? index));
                            if (childAsTabPane?.props?.className) {
                                tabClassName.add(childAsTabPane.props.className);
                            }
                            if (this.tabValidationStates[index] === "error") {
                                tabClassName.add(Styles.tabError);
                            }
                            if (this.tabValidationStates[index] === "warning") {
                                tabClassName.add(Styles.tabWarning);
                            }
                            if (child?.props?.disabled) {
                                tabClassName.add(Styles.tabDisabled);
                            }
                            const disablingTooltip = child?.props?.disabled ? child?.props?.disablingTooltip : undefined;
                            return child && (
                                <Flex.Item grow={this.props.tabsGrow}>
                                    {disablingTooltip ?
                                        this.renderPopper(clickHandler, tabClassName, child, disablingTooltip) :
                                        this.renderTabTitle(clickHandler, tabClassName, child)
                                    }
                                </Flex.Item>
                            );
                        })}
                    </Flex>
                </div>
                {!this.props.noContent && <div style={this.props.contentStyle} className={this.getContentClassName()}>
                    {React.Children.map(this.validChildren, (child: any, index: number) => {
                        if (child && this.selectedIndex === (child.props?.name ?? index)) {
                            return (
                                <div className={this.props.childContainerClassName}>{typeof (child.props.children) === "function" ? (child.props.children as any)() : child.props.children}</div>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>}
            </>
        );
    }

    private renderPopper(clickHandler: () => void, tabClassName: CompositeClassName, child: any, disablingTooltip: string) {
        return (
            <Popper tooltipContent={disablingTooltip}>
                {this.renderTabTitle(clickHandler, tabClassName, child)}
            </Popper>
        );
    }

    private renderTabTitle(clickHandler: () => void, tabClassName: CompositeClassName, child: any) {
        return (
            <div onClick={clickHandler}
                className={tabClassName.classNames}
                data-automation-id={child.props.automationId || undefined}>
                {child.props.propertyPathRegexPattern && (
                    <Ui.SmallValidationBadge
                        hideIfNoErrors
                        propertyPathRegexPattern={child.props.propertyPathRegexPattern}
                        className={Styles.validationBadge} />
                )}
                {child.props.iconName && (
                    <Ui.Icon iconName={child.props.iconName} className={Styles.icon}/>
                )}
                {child.props.title}
            </div>
        );
    }

    private tabClickHandlerFactory(index: number | string) {
        return () => {
            if (this.props.activeIndex === undefined) {
                State.runInAction(() => {
                    this.internalSelectedIndex = index;
                });
            }

            if (this.props.onSelectedIndexChange) {
                this.props.onSelectedIndexChange(index);
            }
        };
    }

    @State.bound
    private getStyleIfSelected(index: number | string) {
        if (this.selectedIndex === index) {
            const className = new CompositeClassName(Styles.tabSelected);
            className.addIf(this.props.visualStyle && this.props.visualStyle === "pageBox", Styles.tabSelectedAlternate);
            className.addIf(this.props.visualStyle && this.props.visualStyle === "menu", Styles.tabSelectedMenu);
            className.addIf(this.props.visualStyle && this.props.visualStyle === "pageBox" && React.Children.count(this.validChildren) - 1 === index, Styles.tabAlternateLast);
            return className.classNames;
        } else {
            const className = new CompositeClassName(Styles.tab);
            className.addIf(this.props.visualStyle && this.props.visualStyle === "pageBox", Styles.tabAlternate);
            className.addIf(this.props.visualStyle && this.props.visualStyle === "menu", Styles.tabMenu);
            className.addIf(this.props.visualStyle && this.props.visualStyle === "pageBox" && React.Children.count(this.validChildren) - 1 === index, Styles.tabAlternateLast);
            return className.classNames;
        }
    }

    @State.bound
    private getContentClassName() {
        const className = new CompositeClassName(Styles.content);
        className.addIf(this.props.visualStyle && this.props.visualStyle === "menu", Styles.contentMenu);
        className.addIf(!!this.props.contentClassName, this.props.contentClassName);
        return className.classNames;
    }
}

export default connect(
    Tab,
    new ValidationBoundaryAdapter<ITabProps>((props, validationStore) => ({ _validationContext: validationStore }))
);
