import React from "react";
import * as Ui from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import DiagnosisStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisStore";
import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import LateralityId from "@Primitives/LateralityId.g";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import DiagnosisListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/DiagnosisList/DiagnosisListStore";
import DiagnosisRoleId from "@Primitives/DiagnosisRoleId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ConditionId from "@Primitives/ConditionId.g";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";
import IDiagnosisAdditionalActionsProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IDiagnosisAdditionalActionsProps";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import PatientId from "@Primitives/PatientId.g";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import { MasterDetailContextAdapter } from "@CommonControls/Layout/MasterDetailContext";

interface IOpenDetailDiagnosisListDependencies {
    careReferenceDataStore: CareReferenceDataStore;
    localizationService: ILocalizationService;
}

interface IOpenDetailDiagnosisListProps {
    _dependencies?: IOpenDetailDiagnosisListDependencies;
    _patientId?: PatientId;
    _isCompact?: boolean;
    diagnosisListStore: DiagnosisListStore;
    onSelectedDiagnosisForDetailChange: (newValue: DiagnosisStore) => void;
    selectedDiagnosis: DiagnosisStore;
    pointOfCareId?: PointOfCareId;
}

@State.observer
class OpenDetailDiagnosisList extends React.Component<IOpenDetailDiagnosisListProps> {
    @State.computed
    private get referenceDataStore() {
        return this.props._dependencies.careReferenceDataStore;
    }

    private dataSource = new InMemoryDataGridDataSource(() => this.props.diagnosisListStore?.diagnoses || []);

    @State.bound
    private renderDiagnosisRole(diagnosisRoleId: DiagnosisRoleId) {
        const diagnosisRole = this.referenceDataStore.diagnosisRole.get(diagnosisRoleId);
        return diagnosisRole?.displayValue?.Shorthand;
    }

    @State.bound
    private diagnosisRoleOrder(value: DiagnosisStore) {
        return this.referenceDataStore.diagnosisRole.get(value.use).displayValue.Shorthand;
    }

    @State.bound
    private diagnosisRoleFilter(row: DiagnosisRoleId) {
        return this.referenceDataStore.diagnosisRole.get(row).displayValue.Shorthand;
    }

    @State.bound
    private renderCondition(versionSelector: IEntityVersionSelector<ConditionId>) {
        if (!versionSelector) {
            return "";
        }
        const condition = this.referenceDataStore.condition.get(versionSelector);
        return condition && (
            <>
                <b>{condition.code}</b> {condition.name}
            </>
        );
    }

    @State.bound
    private renderConditionHint(value: any) {
        if (!value) {
            return "";
        }
        const condition = this.props._dependencies.careReferenceDataStore.condition.get(value);
        return condition.code + " " + condition.name;
    }

    @State.bound
    private getCondition(value: IEntityVersionSelector<ConditionId>) {
        const condition = this.referenceDataStore.condition.get(value);
        return condition?.code || "";
    }

    @State.bound
    private conditionOrder(value: DiagnosisStore) {
        return this.getCondition(value.conditionVersionSelector);
    }

    @State.bound
    private conditionFilter(row: IEntityVersionSelector<ConditionId>) {
        return this.getCondition(row);
    }

    @State.bound
    private renderLaterality(lateralityId: LateralityId) {
        const laterality = this.referenceDataStore.laterality.get(lateralityId);
        return laterality?.displayValue?.Shorthand;
    }

    @State.bound
    private lateralityOrder(value: DiagnosisStore) {
        return this.referenceDataStore.laterality.get(value.lateralityId)?.displayValue.Shorthand;
    }

    @State.bound
    private lateralityFilter(row: LateralityId) {
        return this.referenceDataStore.laterality.get(row)?.displayValue.Shorthand;
    }

    @State.bound
    private getExtensionPointProps(store: DiagnosisStore): IDiagnosisAdditionalActionsProps {
        return {
            diagnosisStore: store,
            setSelectedItem: this.props.onSelectedDiagnosisForDetailChange,
            selectedItem: this.props.selectedDiagnosis,
            pointOfCareId: this.props.pointOfCareId,
            patientId: this.props._patientId,
            careActivityId: this.props.diagnosisListStore.id
        };
    }

    @State.bound
    private renderRowButtons(value: any, store: DiagnosisStore) {
        return (
            <div style={{marginRight: "8px"}}>
                <HisPlatformExtensionPoint extensionProps={this.getExtensionPointProps(store)}
                                           type="diagnosisAdditionalActions"
                >
                    <></>
                </HisPlatformExtensionPoint>
            </div>
        );
    }

    public render() {
        return (
            <>
                <Ui.DataGrid
                    dataSource={this.dataSource}
                    generateInitialFilterStore
                    fixedHeight="100%"
                    fixedLayout
                    automationId="__diagnosisList"
                    rowHeight="42px"
                    disableOnRowHover
                    changeOnMount
                    isSelectable
                    selectedRow={this.props.selectedDiagnosis}
                >
                    <DataGridColumn
                        dataGetter={"use"}
                        onRenderCellValue={this.renderDiagnosisRole}
                        header={StaticCareResources.OutpatientWorkflow.RecordedDiagnosesStep.Columns.DiagnosisReason}
                        automationId={"DiagnosisReason".split(".").pop()}
                        width="14%"
                        isFilterable
                        isOrderable
                        clientSideOrderableValueGetter={this.diagnosisRoleOrder}
                        clientSideFilterableValueGetter={this.diagnosisRoleFilter}
                        isVisible={!this.props._isCompact}
                    />
                    <DataGridColumn
                        dataGetter={"lateralityId"}
                        onRenderCellValue={this.renderLaterality}
                        header={StaticCareResources.OutpatientWorkflow.RecordedDiagnosesStep.Columns.LateralityShort}
                        automationId={"Laterality".split(".").pop()}
                        width="10%"
                        isFilterable
                        isOrderable
                        clientSideOrderableValueGetter={this.lateralityOrder}
                        clientSideFilterableValueGetter={this.lateralityFilter}
                        isVisible={!this.props._isCompact}
                    />
                    <DataGridColumn
                        dataGetter={"conditionVersionSelector"}
                        onRenderCellValue={this.renderCondition}
                        onRenderHintValue={this.renderConditionHint}
                        header={StaticCareResources.OutpatientWorkflow.RecordedDiagnosesStep.Columns.DiagnosisDescription}
                        automationId={"DiagnosisDescription".split(".").pop()}
                        width="66%"
                        isFilterable
                        isOrderable
                        clientSideOrderableValueGetter={this.conditionOrder}
                        clientSideFilterableValueGetter={this.conditionFilter}
                    />
                    <DataGridColumn
                        onRenderCellValue={this.renderRowButtons}
                        header=""
                        automationId={"Actions"}
                        width="10"
                        isVisible={!this.props._isCompact}
                    />
                </Ui.DataGrid>
            </>
        );
    }
}

export default connect(
    (OpenDetailDiagnosisList),
    new DependencyAdapter<IOpenDetailDiagnosisListProps, IOpenDetailDiagnosisListDependencies>(container => {
        return {
            careReferenceDataStore: container.resolve("CareReferenceDataStore"),
            localizationService: container.resolve("ILocalizationService")
        };
    }),
    new PatientContextAdapter<IOpenDetailDiagnosisListProps>(c => ({
        _patientId: c.patientId,
    })),
    new MasterDetailContextAdapter<IOpenDetailDiagnosisListProps>((p, c) => ({
        _isCompact: c.state.isCompact
    })),
);
