import { useFormTextField } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import { ICustomBlockRegistryItem, IFormCustomBlockComponentProps } from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React, { useCallback, useMemo } from "react";
import * as Ui from "@CommonControls";
import TaxPayerInfoType from "./TaxPayerInfoType";
import StaticFinanceResources from "@HisPlatform/BoundedContexts/Finance/StaticResources/StaticFinanceResources";
import { useFormLayoutContext } from "@Toolkit/FormEngine/Panels/FormLayoutContext";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

function TaxPayerInfoFormCustomBlock(props: IFormCustomBlockComponentProps) {
    const layoutContext = useFormLayoutContext();
    const compositeFieldName = layoutContext?.compositeDataReferences?.join(".");
    const resolveEntityPath = useCallback((relativePath: string): string => {
        return isNullOrUndefined(compositeFieldName)
            ? relativePath
            : `${compositeFieldName}.${relativePath}`;
    }, []);

    const [taxPayerInfoType, setTaxPayerInfoType] = useFormTextField(props.form.data.Content, resolveEntityPath("TaxPayerInfoType"));
    const [taxNumber, setTaxNumber] = useFormTextField(props.form.data.Content, resolveEntityPath("TaxNumber"));
    const [groupIdentifier, setGroupIdentifier] = useFormTextField(props.form.data.Content, resolveEntityPath("GroupIdentifier"));
    const [groupMemberTaxNumber, setGroupMemberTaxNumber] = useFormTextField(props.form.data.Content, resolveEntityPath("GroupMemberTaxNumber"));
    const [communityTaxNumber, setCommunityTaxNumber] = useFormTextField(props.form.data.Content, resolveEntityPath("CommunityTaxNumber"));

    const value = useMemo(() => TaxPayerInfoType[taxPayerInfoType], [taxPayerInfoType]);

    const valueSetter = useCallback(State.action((enumValue: TaxPayerInfoType) => {
        setTaxPayerInfoType(TaxPayerInfoType[enumValue]);
    }), [setTaxPayerInfoType]);

    return (
        <Ui.Flex>
            <Ui.Flex.Item sm={6}>
                <Ui.UniversalEnumSelector
                    label={StaticFinanceResources.Customers.TaxPayerInfo.Type}
                    enumName={"TaxPayerInfoType"}
                    enumType={TaxPayerInfoType}
                    displayMode="selectBox"
                    enumOrigin="client"
                    value={value}
                    onChange={valueSetter}
                    automationId="taxPayerInfoType"
                    clearable={false}
                    propertyIdentifier="TaxPayerInfoType"
                />
            </Ui.Flex.Item>
            <Ui.Flex.Item sm={6}>
                {taxPayerInfoType === "DomesticTaxPayerWithGroupMembership" &&
                    <>
                        <Ui.TextBox
                            value={groupIdentifier}
                            onChange={setGroupIdentifier}
                            label={StaticFinanceResources.Customers.TaxPayerInfo.GroupIdentifier}
                            propertyIdentifier="GroupIdentifier"
                            automationId="groupIdentifier"
                        />
                        < Ui.TextBox
                            value={groupMemberTaxNumber}
                            onChange={setGroupMemberTaxNumber}
                            label={StaticFinanceResources.Customers.TaxPayerInfo.GroupMemberTaxNumber}
                            propertyIdentifier="GroupMemberTaxNumber"
                            automationId="groupMemberTaxNumber"
                        />
                    </>
                }
                {taxPayerInfoType === "DomesticTaxPayerWithoutGroupMembership" &&
                    < Ui.TextBox
                        value={taxNumber}
                        onChange={setTaxNumber}
                        label={StaticFinanceResources.Customers.TaxPayerInfo.TaxNumber}
                        propertyIdentifier="TaxNumber"
                        automationId="taxNumber"
                    />
                }
                {taxPayerInfoType === "ForeignTaxPayerOrOther" &&
                    <>
                        <Ui.TextBox
                            value={taxNumber}
                            onChange={setTaxNumber}
                            label={StaticFinanceResources.Customers.TaxPayerInfo.TaxNumber}
                            propertyIdentifier="TaxNumber"
                            automationId="taxNumber"
                        />
                        < Ui.TextBox
                            value={communityTaxNumber}
                            onChange={setCommunityTaxNumber}
                            label={StaticFinanceResources.Customers.TaxPayerInfo.CommunityTaxNumber}
                            propertyIdentifier="CommunityTaxNumber"
                            automationId="communityTaxNumber"
                        />
                    </>
                }
            </Ui.Flex.Item>
        </Ui.Flex>
    );
}

export const TaxPayerInfoRegistryItem: ICustomBlockRegistryItem = {
    toolboxNewItemDisplayName: "Tax payer info",
    dataElementsFactory: () => [],
    componentType: State.observer(TaxPayerInfoFormCustomBlock)
};

export default TaxPayerInfoFormCustomBlock;
