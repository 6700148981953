import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import StaticFormEngineResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import Language from "@Primitives/Language";

function GroupPropertyPanel() {

    const store = useFormLayoutEditorStore();

    if (!store.selectedGroup) {
        return null;
    }

    return (
        <Ui.GroupBox title={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Group.Title} style={{ margin: 4 }}>

            <Ui.Flex>
                <Ui.Flex.Item xs={7}>
                    <Ui.TextBox
                        label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Group.GroupLabelLabel}
                        value={store.selectedGroup.localizedLabel}
                        onChange={store.selectedGroup.setLocalizedLabel}
                        automationId="GroupTitle_TextBox"
                    />
                </Ui.Flex.Item>
                <Ui.Flex.Item xs={5}>
                    <Ui.UniversalEnumSelector
                        label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Editor.LanguageSelectorLabel}
                        enumName={"Language"}
                        enumType={Language}
                        enumOrigin="client"
                        value={store.selectedGroup.currentLabelLanguage}
                        onChange={store.selectedGroup.setCurrentLabelLanguage}
                        automationId="LanguageSelector"
                        clearable={false}
                    />
                </Ui.Flex.Item>
            </Ui.Flex>
            <Ui.TextBox
                label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Group.LabelDataElementName}
                value={store.selectedGroup.dataReference}
                disabled={!store.selectedGroup.hasDataReference}
                onChange={store.setSelectedGroupDataElementName}
                automationId="GroupDataElementName_TextBox"
            />
            <Ui.CheckBox
                label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Group.HasDataReferenceLabel}
                automationId="GroupHasDataReference_CheckBox"
                value={store.selectedGroup.hasDataReference}
                onChange={store.setSelectedGroupHasDataReference}
            />
            <Ui.CheckBox
                label={StaticFormEngineResources.FormDefinitionEditor.PropertyPanel.Group.IsArrayLabel}
                automationId="GroupIsArray_CheckBox"
                value={store.selectedGroup.isArray}
                disabled={!store.selectedGroup.hasDataReference}
                onChange={store.setSelectedGroupIsArray}
            />
        </Ui.GroupBox>
    );
}

export default State.observer(GroupPropertyPanel);