import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class IssueData {
    @State.observable public issueKey: string;
    @State.observable public issueUrl: string;
    
    constructor(
        issueKey: string,
        issueUrl: string
    ) {
        this.issueKey = issueKey;
        this.issueUrl = issueUrl;
    }

    @State.action.bound
    public setIssueKey(issueKey: string) {
        this.issueKey = issueKey;
    }

    @State.action.bound
    public setIssueUrl(issueUrl: string) {
        this.issueUrl = issueUrl;
    }
}