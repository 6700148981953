import React from "react";
import CheckBox from "@CommonControls/CheckBox";
import IActionDispatcher from "@Toolkit/ReactClient/ActionProcessing/IActionDispatcher";
import { ScreenStateContextAdapter, ScreenStateContextStore } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import NDataScreen from "@HisPlatform/BoundedContexts/Productivity/Components/NDataScreen/NDataScreen";
import { HisActionDispatcherAdapter } from "@HisPlatform/Common/FrontendActions/HisActionDispatcher";
import ShowPatientRelatedDocumentListScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowPatientRelatedDocumentListScreenAction.g";
import { useDocumentListScreenStore, provideDocumentListScreenStore } from "@HisPlatform/Packages/Patients/Screens/DocumentListScreen/DocumentListStoreProvider";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";

export interface IDocumentListScreenProps
    extends IScreenPropsBase<ShowPatientRelatedDocumentListScreenAction> {
    _screenState?: ScreenStateContextStore;
    _actionDispatcher?: IActionDispatcher;
}

function DocumentListScreen(props: IDocumentListScreenProps) {
    const store = useDocumentListScreenStore();

    const dynamicListAsync = props.action.showIsDeleted ? store.dynamicListWithDeletedAsync : store.dynamicListAsync;

    const beforeGlobalActionsToolbar = <CheckBox
        verticalAlign="noPadding"
        label={StaticWebAppResources.Common.Button.ShowDeleted}
        value={store.showIsDeleted}
        labelPosition={"right"}
        style={{marginRight: 10, flex: 1}}
        onChange={store.setShowIsDeletedFilterAsync.fireAndForget}
        displayMode="switch"
        automationId="showDeletedCheckBox" />;

    return (
        <NDataScreen
            iconName="document"
            onGetDynamicListAsync={dynamicListAsync}
            beforeGlobalActionsToolbar={beforeGlobalActionsToolbar}
        />
    );
}

export default connect<IDocumentListScreenProps>(provideDocumentListScreenStore(DocumentListScreen),
    new ScreenStateContextAdapter(),
    new HisActionDispatcherAdapter<IDocumentListScreenProps>()
    );