import HealthcareProfessionId from "@Primitives/HealthcareProfessionId.g";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import ResourceId from "@Primitives/ResourceId.g";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";

export interface IHomeMenu {
    items: Array<HomeMenuItem | HomeMenuItemGroup>;
}

export class HomeMenuItemGroup {
    constructor(
        public readonly localizedDisplayName: MultiLingualLabel | null,
        public readonly displayNameResourceId: ResourceId | null,
        public readonly items: HomeMenuItem[],
    ) {}
}

export class HomeMenuItem {
    constructor(
        public readonly localizedDisplayName: MultiLingualLabel | null,
        public readonly displayNameResourceId: ResourceId | null,
        public readonly localizedShortName: MultiLingualLabel | null,
        public readonly shortNameResourceId: ResourceId | null,
        public readonly contextualOrganizationUnitId: OrganizationUnitId | null,
        public readonly contextualHealthcareProfessionIds: HealthcareProfessionId[] | null,
        public readonly actionDescriptor: ActionDescriptor,

    ) {}  
}