import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import IntervalGroupingResolution from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Api/Reporting/Enum/IntervalGroupingResolution.g";

interface IIntervalGroupingResolutionSelectProps extends ISelectBoxBaseProps {
    value?: IntervalGroupingResolution | IntervalGroupingResolution[];
    onChange?: (newValue: IntervalGroupingResolution | IntervalGroupingResolution[]) => void;
}


const IntervalGroupingResolutionSelectBox: React.FC<IIntervalGroupingResolutionSelectProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumName={"IntervalGroupingResolution"}
        enumOrigin="server"
        enumType={IntervalGroupingResolution}
        displayMode="selectBox"
    />
);

export default IntervalGroupingResolutionSelectBox;