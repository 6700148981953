import FormSchemaReference from "@Toolkit/FormEngine/Model/Schema/FormSchemaReference";
import FormLayoutReference from "@Toolkit/FormEngine/Model/Layout/FormLayoutReference";
import IFormDefinition from "@Toolkit/FormEngine/Model/IFormDefinition";
import _ from "@HisPlatform/Common/Lodash";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import { getEmptyFormFieldDataFor } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";

export interface IFormDataStore {
    SchemaReference: FormSchemaReference;
    LayoutReference: FormLayoutReference;
    Content: FormFieldDataBase[];
}

export function createEmptyFormDataStore(rootSchemaReference: FormSchemaReference, rootLayoutReference: FormLayoutReference, formDefinition: IFormDefinition): IFormDataStore {

    const dataStore: IFormDataStore = {
        SchemaReference: rootSchemaReference,
        LayoutReference: rootLayoutReference,
        Content: []
    };

    function addSchemaElementsToStore(target: IFormDataStore) {
        const schema = formDefinition.rootSchema.getVersion(target.SchemaReference.versionNumber);

        const formFieldData: FormFieldDataBase[] = [];
        getEmptyFormFieldDataFor(schema.dataElements, formFieldData);

        target.Content = formFieldData;
    }

    addSchemaElementsToStore(dataStore);

    return dataStore;
}
