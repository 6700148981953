import RowVersion from "@Toolkit/CommonWeb/Model/RowVersion";
import FormFieldDataBase from "@Toolkit/FormEngine/Model/Data/FormFieldDataBase";
import InsuranceId from "@Primitives/InsuranceId.g";
import { getField } from "@Toolkit/FormEngine/Panels/FormFieldHelpers";
import DateTimeFormFieldData from "@Toolkit/FormEngine/Model/Data/DateTimeFormFieldData";
import moment from "moment";
import PractitionerId from "@Primitives/PractitionerId.g";
import { getEntityFieldValue } from "@Toolkit/FormEngine/Panels/FormCustomBlockHelpers";
import ConditionId from "@Primitives/ConditionId.g";
import DateFormFieldData from "@Toolkit/FormEngine/Model/Data/DateFormFieldData";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import TextFormFieldData from "@Toolkit/FormEngine/Model/Data/TextFormFieldData";
import ReferencedEnumFormFieldData from "@Toolkit/FormEngine/Model/Data/ReferencedEnumFormFieldData";
import CareParticipantSettings from "./CareParticipantSettings";
import CareIdentifierSettings from "./CareIdentifierSettings";
import CareParticipantRole from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareParticipantRole.g";
import Urgency from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/Urgency.g";

export default class CareActivityBaseData {

    constructor(
        public formFields: FormFieldDataBase[],
        public rowVersion: RowVersion,
        public careParticipantSettings: CareParticipantSettings,
        public careIdentifierSettings: CareIdentifierSettings) {
    }

    public get insuranceId(): InsuranceId {
        const insuranceId = getEntityFieldValue<InsuranceId>(this.formFields, "InsuranceId", InsuranceId);
        return insuranceId;
    }

    public get wentUnderCareAt(): moment.Moment {
        const wentUnderCareAtField = getField<DateTimeFormFieldData>(this.formFields, "WentUnderCareAt");
        return wentUnderCareAtField.value;
    }

    public get primaryParticipant(): PractitionerId {
        const primaryParticipant = getEntityFieldValue<PractitionerId>(this.formFields, this.careParticipantSettings.primaryParticipant.value, PractitionerId);
        return primaryParticipant;
    }

    public get getAllParticipants(): Array<{ id: PractitionerId, role: CareParticipantRole }> {
        const result = this.careParticipantSettings.availableCareParticipants.map(i => {
            const practitionerId = getEntityFieldValue<PractitionerId>(this.formFields, i.id.value, PractitionerId);
            return {
                id: practitionerId,
                role: i.role
            };
        });
        return result;
    }

    public get primaryCareIdentifier(): string {
        const careIdentifierField = getField<TextFormFieldData>(this.formFields, this.careIdentifierSettings.primaryCareIdentifier.value);
        return careIdentifierField.value;
    }

    public get urgency(): Urgency {
        const urgencyField = getField<ReferencedEnumFormFieldData>(this.formFields, "Urgency");
        return urgencyField.value as unknown as Urgency;
    }

    public get referral() {
        const referralDiagnosisId1 = getEntityFieldValue<ConditionId>(this.formFields, "Referral.ReferralDiagnosisId1", ConditionId);
        const referralDoctorId = getEntityFieldValue<PractitionerId>(this.formFields, "Referral.ReferralDoctorId", PractitionerId);
        const referralWorkplace = getEntityFieldValue<ExternalLocationId>(this.formFields, "Referral.ReferralWorkplaceId", ExternalLocationId);

        const referralCreationDateField = getField<DateFormFieldData>(this.formFields, "Referral.ReferralCreationDate");

        return {
            referralDiagnosisId1: referralDiagnosisId1,
            referralCreationDate: referralCreationDateField.value,
            referralDoctorId: referralDoctorId,
            referralWorkplace: referralWorkplace
        };
    }
}