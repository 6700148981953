import StaticCareResources from "@HisPlatform/BoundedContexts/Care/StaticResources/StaticCareResources";
import SearchPatientMenu from "@HisPlatform/Packages/Patients/Components/SearchPatientMenu";
import ShowCombinePatientsScreenAction from "@HisPlatform/Packages/Patients/FrontendActions/ShowCombinePatientsScreenAction.g";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import INavMenuItem from "@Toolkit/ReactClient/DynamicNavigation/INavMenuItem";
import NavMenuActionItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuActionItem";
import NavMenuCustomItem from "@Toolkit/ReactClient/DynamicNavigation/NavMenuCustomItem";

export function getNavigationMenuItems(): INavMenuItem[] {
    
    const leftTopLevelMenuItems: INavMenuItem[] = [
        NavMenuCustomItem.createTopLevel("SearchPatient", SearchPatientMenu, "left", 6)
    ];
    
    const rightTopLevelMenuItems: INavMenuItem[] = [
    ];
    
    const childMenuItems: INavMenuItem[] = [
        NavMenuActionItem.createChild(
            "CombinePatients",
            "Settings",
            new ShowCombinePatientsScreenAction(ScreenDisplayMode.Full),
            {
                automationId: "combinePatients",
                text: StaticCareResources.PatientRegister.CombinePatientsScreen.Title,
                order: 17
            }
        )
    ];

    return [...leftTopLevelMenuItems, ...rightTopLevelMenuItems , ...childMenuItems];
}