import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export type IAsyncListener<T> = (event?: T) => Promise<void>;

export class TypedAsyncEvent<T = void> implements IDisposable {
    private listeners: Array<IAsyncListener<T>> = [];
    private listenersOnce: Array<IAsyncListener<T>> = [];

    /** Attaches a handler to this event. Returns a disposable object which can be used to detach that handler. */
    public on(listener: IAsyncListener<T>): IDisposable {
        this.assertNotDisposed();
        this.listeners.push(listener);
        return {
            dispose: () => this.off(listener)
        };
    }

    /** Attaches a one-time handler to this event. After the first time it will be detached. */
    public once(listener: IAsyncListener<T>): void {
        this.assertNotDisposed();
        this.listenersOnce.push(listener);
    }

    /** Detaches an event handler. */
    public off(listener: IAsyncListener<T>) {
        this.assertNotDisposed();
        const callbackIndex = this.listeners.indexOf(listener);
        if (callbackIndex > -1) {
            this.listeners.splice(callbackIndex, 1);
        }
    }

    /** Raise this event. */
    @State.bound
    public async emitAsync(event?: T, stopAtFirstError: boolean = false): Promise<void> {
        this.assertNotDisposed();

        const listeners = Array.from(this.listeners);

        
        for (let index = 0; index < listeners.length; ++index) {
            try {
                await listeners[index](event);
            } catch (err) {
                if (stopAtFirstError) {
                    throw err;
                }
            }
        }

        const listenersOnce = Array.from(this.listenersOnce);
        this.listenersOnce = [];

        
        for (let index = 0; index < listenersOnce.length; ++index) {
            try {
                const listener = listenersOnce[index];
                await listener[index](event);
            } catch (err) {
                if (stopAtFirstError) {
                    throw err;
                }
            }
        }
    }

    /** Pipe this event to another one. When this event emitted, the other will be emitted too. */
    public pipe(targetEvent: TypedAsyncEvent<T>): IDisposable {
        this.assertNotDisposed();
        return this.on((e) => targetEvent.emitAsync(e) as any);
    }

    private assertNotDisposed() {
        if (this.listeners === null) {
            throw new Error("TypedEvent is disposed.");
        }
    }

    /** Removes all event handlers */
    public dispose() {
        this.listeners = null;
        this.listenersOnce = null;
    }
}