//#region imports
import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ServiceRequestManagementApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestManagementApiAdapter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import ServiceRequestListPanelView from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestListPanel/ServiceRequestListPanelView";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ServiceRequestStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestStore";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import ServiceRequestFilter from "@Primitives/ServiceRequestFilter";
import ServiceRequestId from "@Primitives/ServiceRequestId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ExternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/ExternalCareLocation";
import InternalCareLocation from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareLocation/InternalCareLocation";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import ValueWrapper from "@Toolkit/CommonWeb/Model/ValueWrapper";
import CareActivityId from "@Primitives/CareActivityId.g";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import PatientId from "@Primitives/PatientId.g";
import ServiceRequestsListStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ServiceRequestManagement/ServiceRequestsListStore";
import IDisposable from "@Toolkit/CommonWeb/IDisposable";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import _ from "@HisPlatform/Common/Lodash";
import ActionIdentifier from "@Primitives/ActionIdentifier.g";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import CareActivityApiAdapter2 from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivity/CareActivityApiAdapter2";
import { IPanelWithButtonPortalProps } from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/IPanelWithButtonPortalProps";
import IServiceRequestDataService from "@HisPlatform/BoundedContexts/Care/Services/Definition/ServiceRequestManagement/IServiceRequestDataService";
import { IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import HisModalServiceAdapter from "@HisPlatform/Components/HisPlatformModalRenderer/HisModalServiceAdapter";
import DocumentPreviewModalParams from "@HisPlatform/BoundedContexts/DocumentManagement/Components/Modals/DocumentPreviewModal/DocumentPreviewModalParams";
import ServiceRequestDefinitionDialogParams from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestDefinitionDialog/ServiceRequestDefinitionDialogParams";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import ServiceRequestDocumentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/ServiceRequestManagement/ServiceRequestDocument/ServiceRequestDocumentApiAdapter";
import ISearchParametersService from "@Toolkit/CommonWeb/SearchParametersService/Definition/ISearchParametersService";
import PermissionCheckContextProvider from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import CareActivityBaseDataApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityBaseData/CareActivityBaseDataApiAdapter";
import DiagnosisListApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/DiagnosisList/DiagnosisListApiAdapter";
import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";
import IServiceRequestDefinitionDialogResult from "@HisPlatform/BoundedContexts/Care/Components/Panels/ServiceRequestManagement/ServiceRequestDefinitionDialog/IServiceRequestDefinitionDialogResult";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import { MasterDetailContextAdapter } from "@CommonControls/Layout/MasterDetailContext";

//#endregion

interface IServiceRequestListPanelDependencies {
    serviceRequestManagementApiAdapter: ServiceRequestManagementApiAdapter;
    serviceRequestDataService: IServiceRequestDataService;
    careReferenceDataStore: CareReferenceDataStore;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    localizationService: ILocalizationService;
    notificationService: INotificationService;
    careActivityApiAdapter: CareActivityApiAdapter2;
    careActivityBaseDataApiAdapter: CareActivityBaseDataApiAdapter;
    documentApiAdapter: ServiceRequestDocumentApiAdapter;
    searchParametersService: ISearchParametersService;
    diagnosisListApiAdapter: DiagnosisListApiAdapter;
}

interface IServiceRequestListPanelProps extends IPanelWithButtonPortalProps {
    _dependencies?: IServiceRequestListPanelDependencies;
    _modalService?: IModalService;
    _careActivityId?: CareActivityId;
    _patientId?: PatientId;
    _columnSet?: "normal" | "reduced";

    selectedServiceRequestId: ServiceRequestId;
    serviceRequestFilter: ServiceRequestFilter;
    refreshListEvent: TypedEvent;

    onServiceRequestFilterChange: (newValue: ServiceRequestFilter) => void;
    onCreateServiceRequest: (definitionId: EntityVersionSelector<ServiceRequestDefinitionId>, definitionDirection: ServiceRequestDirection) => void;
    onOpenRequest: (serviceRequestId: ServiceRequestId) => void;
    onOpenResults: (serviceRequestId: ServiceRequestId) => void;
    onOpenAdministration: (serviceRequestId: ServiceRequestId) => void;
    onBack: () => void;

    pageboxHeaderNoPadding?: boolean;
    pageboxHeaderSize?: "normal" | "large";
    withoutPageBox?: boolean;
    hideTitle?: boolean;
    showServiceRequestFilter?: boolean;
    hidePager?: boolean;
    isReadOnly?: boolean;
}

/** @screen */
@State.observer
class ServiceRequestListPanel extends React.Component<IServiceRequestListPanelProps> {
    //#region dependencies

    private get careReferenceDataStore() {
        return this.props._dependencies.careReferenceDataStore;
    }

    private get organizationReferenceDataStore() {
        return this.props._dependencies.organizationReferenceDataStore;
    }

    private get serviceRequestManagementApiAdapter() {
        return this.props._dependencies.serviceRequestManagementApiAdapter;
    }

    private get serviceRequestDataService() {
        return this.props._dependencies.serviceRequestDataService;
    }

    private get careActivityApiAdapter() {
        return this.props._dependencies.careActivityApiAdapter;
    }

    private get notificationService() {
        return this.props._dependencies.notificationService;
    }

    private get documentApiAdapter() {
        return this.props._dependencies.documentApiAdapter;
    }

    private get modalService() {
        return this.props._modalService;
    }

    //#endregion

    public static defaultProps: Partial<IServiceRequestListPanelProps> = {
        _columnSet: "normal",
        showServiceRequestFilter: true
    };

    @State.computed private get selectedIsNew() {
        return this.props.selectedServiceRequestId?.value === "new";
    }

    @State.observable.ref private _list: ServiceRequestStore[] = null;
    @State.observable.ref private possibleServiceRequestManagementActions: ActionIdentifier[] = null;
    @State.computed private get list() {
        if (this.selectedIsNew && !!this._list) {
            const newServiceRequest = new ServiceRequestStore(true);
            return [
                newServiceRequest,
                ...this._list
            ];

        } else {
            return this._list;
        }
    }

    @State.computed private get externalLocationVersionSelectors() {
        return this.list?.filter(s => s.isExternal).map(s => s.targetExternalLocationVersionSelector);
    }

    private readonly dataSource: InMemoryDataGridDataSource = new InMemoryDataGridDataSource(() => this.list);
    private refreshListEventHandler: IDisposable = null;

    @State.computed private get isServiceRequestSelected() {
        return !!this.props.selectedServiceRequestId;
    }

    @State.computed
    private get selectedServiceRequest() {
        if (isNullOrUndefined(this.props.selectedServiceRequestId)) {
            return null;
        }

        if (this.selectedIsNew) {
            return this.list.find(s => s.isNew);
        }

        return this.list.find(s => ValueWrapper.equals(s.id, this.props.selectedServiceRequestId));
    }

    private readOnlyActionValue = "CareRegister_ViewServiceRequests";

    @State.computed
    private get isListReadOnly() {
        return this.props.isReadOnly || (
            this.possibleServiceRequestManagementActions &&
            this.possibleServiceRequestManagementActions.length > 0 &&
            this.possibleServiceRequestManagementActions.some(a => a.value === this.readOnlyActionValue)
        );
    }

    @State.computed private get permissionCheckedOperations() {
        const res = {};

        if (this.props._careActivityId) {
            const fakeServiceRequestStore = new ServiceRequestStore(true);
            fakeServiceRequestStore.setServiceRequestDefinitionVersion({ id: new ServiceRequestDefinitionId("1"), validOn: LocalDate.today() });
            fakeServiceRequestStore.setStarterCareActivityId(this.props._careActivityId);
            fakeServiceRequestStore.direction = ServiceRequestDirection.InternalToInternal;

            res["CreateNew1"] = async () => await this.props._dependencies.serviceRequestDataService.checkPermissionForCreateServiceRequestAsync(fakeServiceRequestStore);
            res["CreateNew3"] = async () => await this.props._dependencies.diagnosisListApiAdapter.checkPermissionForGetDiagnosisListAsync(this.props._careActivityId);
        }

        return res;
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.loadAsync);
    public componentDidMount() {
        this.dataSource.explicitRefreshEvent.on(() => {
            dispatchAsyncErrors(this.loadAsync(), this);
        });

        this.refreshListEventHandler = this.props.refreshListEvent.on(() => {
            dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
        });
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);

        const startNewServiceRequest = this.props._dependencies.searchParametersService.get("startNewServiceRequest", null);
        if (!!startNewServiceRequest) {
            this.createNewRequestAsync();
        }
    }

    public componentDidUpdate(prevProps: IServiceRequestListPanelProps) {
        if (
            prevProps.serviceRequestFilter !== this.props.serviceRequestFilter ||
            !ValueWrapper.equals(prevProps._careActivityId, this.props._careActivityId) ||
            !ValueWrapper.equals(prevProps._patientId, this.props._patientId)
        ) {
            dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
        }
    }

    public componentWillUnmount() {
        if (this.refreshListEventHandler) {
            this.refreshListEventHandler.dispose();
        }
    }

    @State.bound
    private async loadAsync() {
        if (!this.props._careActivityId && !this.props._patientId) {
            return;
        }

        let response: ServiceRequestsListStore;

        if (this.props.serviceRequestFilter === ServiceRequestFilter.CareActivity) {
            await State.when(() => !!this.props._careActivityId);
            response =
                await this.serviceRequestManagementApiAdapter.getServiceRequestsByCareActivityIdAsync(this.props._careActivityId);
            const myServiceRequest =
                await this.serviceRequestDataService.getServiceRequestForExecutingCareActivityAsync(
                    this.props._careActivityId,
                    false);

            if (!myServiceRequest.isNull) {
                _.remove(response.items, (i: ServiceRequestStore) => {
                    return ValueWrapper.equals(i.id, myServiceRequest.id);
                });
                response.items.unshift(myServiceRequest);
            }
        } else {
            await State.when(() => !!this.props._patientId);

            response =
                await this.serviceRequestManagementApiAdapter.getServiceRequestsForPatientAsync(this.props._patientId, this.props._careActivityId);
        }

        await this.initializeServiceRequestStoresAsync(response.items);

        response.items = _.orderBy(response.items, [(i: ServiceRequestStore) => i.createdAt], "desc");

        if (this.props._careActivityId) {
            const possibleActionsStore = await this.careActivityApiAdapter.getPossibleActionsAsync([{ careActivityId: this.props._careActivityId }]);
            State.runInAction(() => {
                this.possibleServiceRequestManagementActions = possibleActionsStore.value[0].actions;
            });
        }

        this.initializePanel(response.items);
    }

    private async initializeServiceRequestStoresAsync(items: ServiceRequestStore[]) {
        const serviceVersionSelectors = items.map((i) => i.serviceRequestDefinitionVersion) as Array<IEntityVersionSelector<ServiceRequestDefinitionId>>;
        await this.careReferenceDataStore.serviceRequestDefinition.ensureLoadedAsync(serviceVersionSelectors);

        const internalLocations = items
            .filter(item => item.targetCareLocation instanceof InternalCareLocation)
            .map(item => (item.targetCareLocation as InternalCareLocation).pointOfCareId);
        await this.organizationReferenceDataStore.allPointsOfCareMap.ensureLoadedAsync(internalLocations);

        const referralLocationIds = items.filter(item => item.referralLocation instanceof InternalCareLocation)
            .map(item => (item.referralLocation as InternalCareLocation).pointOfCareId);
        const healthCareProfessionIds = _.flatten([...internalLocations, ...referralLocationIds].map(this.organizationReferenceDataStore.allPointsOfCareMap.get).map(item => item.healthcareProfessionIds));
        const uniqHealthCareProfessionIds = _.uniqBy(healthCareProfessionIds, item => item.value);
        await this.organizationReferenceDataStore.healthCareProfession.ensureLoadedAsync(uniqHealthCareProfessionIds);

        const externalLocations = items
            .filter(item => item.targetCareLocation instanceof ExternalCareLocation)
            .map(item => {
                return {
                    id: (item.targetCareLocation as ExternalCareLocation).externalLocationSelector.id,
                    validOn: (item.targetCareLocation as ExternalCareLocation).externalLocationSelector.validOn
                } as IEntityVersionSelector<ExternalLocationId>;
            });
        await this.organizationReferenceDataStore.externalLocationStore.ensureLoadedAsync(externalLocations);

        const doctorIds = items.map(it => it.requesterDoctorId).concat(items.map(it => it.targetDoctorId));
        await this.organizationReferenceDataStore.ensurePractitionerIdsLoadedAsync(doctorIds);
        await this.organizationReferenceDataStore.practitionerMap.ensureLoadedAsync(doctorIds);
    }

    @State.action
    private initializePanel(list: ServiceRequestStore[]) {
        this._list = list;
    }

    @State.bound
    private async createNewRequestAsync() {
        const result = await this.modalService.showDialogAsync<IServiceRequestDefinitionDialogResult>(new ServiceRequestDefinitionDialogParams());
        if (result) {
            this.props.onCreateServiceRequest(result.definitionId, result.direction);
        }
    }

    @State.action.bound
    private async openFinalDocumentAsync(serviceRequestStore: ServiceRequestStore) {
        if (!isNullOrUndefined(serviceRequestStore.executingCareActivityId)) {
            const serviceRequestDefinition = await this.careReferenceDataStore.serviceRequestDefinition.getOrLoadAsync(serviceRequestStore.serviceRequestDefinitionVersion);

            const doc = await this.documentApiAdapter.getSingleDocumentForServiceRequestAsync(
                serviceRequestStore.id,
                serviceRequestStore.executingCareActivityId,
                serviceRequestDefinition.reportDocumentTypeCode,
                null,
                null
            );

            if (!doc.value) {
                this.notificationService.info(StaticWebAppResources.NewServiceRequestPage.DocumentDoesNotExist);
            } else {
                await this.modalService.showModalAsync(new DocumentPreviewModalParams(doc.value.id));
            }
        } else {
            this.notificationService.info(StaticWebAppResources.NewServiceRequestPage.DocumentDoesNotExist);
        }
    }

    @State.action.bound
    private async openServiceRequestDocumentAsync(serviceRequestStore: ServiceRequestStore) {
        const serviceRequestDefinition = await this.careReferenceDataStore.serviceRequestDefinition.getOrLoadAsync(serviceRequestStore.serviceRequestDefinitionVersion);

        const doc = await this.documentApiAdapter.getSingleDocumentForServiceRequestAsync(
            serviceRequestStore.id,
            serviceRequestStore.starterCareActivityId,
            serviceRequestDefinition.requestDocumentTypeCode,
            null,
            true
        );

        if (!doc.value) {
            this.notificationService.info(StaticWebAppResources.NewServiceRequestPage.DocumentDoesNotExist);
        } else {
            await this.modalService.showModalAsync(new DocumentPreviewModalParams(doc.value.id));
        }
    }

    public render() {

        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={StaticWebAppResources.OutpatientWorkflow.ServiceRequestManagementStep.Title} />;
        }

        if (!this.list) {
            return null;
        }

        return (
            <PermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                <ServiceRequestListPanelView
                    localizationService={this.props._dependencies.localizationService}
                    notificationService={this.props._dependencies.notificationService}
                    dataSource={this.dataSource}
                    isServiceRequestSelected={this.isServiceRequestSelected}
                    onCreateNewRequestAsync={this.createNewRequestAsync}
                    selectedServiceRequest={this.selectedServiceRequest}
                    currentCareActivityId={this.props._careActivityId}
                    onBack={this.props.onBack}

                    onOpenFinalDocumentAsync={this.openFinalDocumentAsync}
                    onOpenAdministration={this.props.onOpenAdministration}
                    onOpenRequest={this.props.onOpenRequest}
                    onOpenResults={this.props.onOpenResults}
                    onOpenRequestDocumentAsync={this.openServiceRequestDocumentAsync}
                    onFilterServiceRequest={this.props.onServiceRequestFilterChange}
                    serviceRequestFilter={this.props.serviceRequestFilter}
                    externalLocationVersionSelectors={this.externalLocationVersionSelectors}

                    pageboxHeaderNoPadding={this.props.pageboxHeaderNoPadding}
                    pageboxHeaderSize={this.props.pageboxHeaderSize}
                    buttons={this.props.buttons}
                    hidePortalButtons={this.props.hidePortalButtons}
                    hideTitle={this.props.hideTitle}

                    withoutPageBox={this.props.withoutPageBox}
                    columnSet={this.props._columnSet}
                    showServiceRequestFilter={this.props.showServiceRequestFilter}
                    isListReadOnly={this.isListReadOnly}
                    hidePager={this.props.hidePager}
                />
            </PermissionCheckContextProvider>
        );
    }
}

export default connect(
    ServiceRequestListPanel,
    new DependencyAdapter<IServiceRequestListPanelProps, IServiceRequestListPanelDependencies>(c => ({
        serviceRequestManagementApiAdapter: c.resolve("ServiceRequestManagementApiAdapter"),
        serviceRequestDataService: c.resolve("IServiceRequestDataService"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        localizationService: c.resolve("ILocalizationService"),
        notificationService: c.resolve("INotificationService"),
        careActivityApiAdapter: c.resolve("CareActivityApiAdapter2"),
        careActivityBaseDataApiAdapter: c.resolve("CareActivityBaseDataApiAdapter"),
        documentApiAdapter: c.resolve("ServiceRequestDocumentApiAdapter"),
        searchParametersService: c.resolve("ISearchParametersService"),
        diagnosisListApiAdapter: c.resolve("DiagnosisListApiAdapter")
    })),
    new HisModalServiceAdapter(),
    new PatientContextAdapter<IServiceRequestListPanelProps>(c => ({
        _patientId: c.patientId
    })),
    new CareActivityContextAdapter<IServiceRequestListPanelProps>(c => ({
        _careActivityId: c.careActivityId
    })),
    new MasterDetailContextAdapter<IServiceRequestListPanelProps>((_, c) => ({
        _columnSet: c.state.isCompact ? "reduced" : "normal"
    })),
);
