import React from "react";
import EhiEReferralData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiEReferralData";
import * as Ui from "@CommonControls";
import StaticHunEHealthInfrastructureCareResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/StaticResources/StaticHunEHealthInfrastructureCareResources";
import EhiHealthcareProviderSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiHealthcareProviderSelector";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiHealthcareProviderOrganizationUnitSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiHealthcareProviderOrganizationUnitSelector";
import EhiEReferralServiceTypeSelector from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralServiceTypeSelector/EhiEReferralServiceTypeSelector";
import EhiEReferralReasonSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralReasonSelectBox";
import EhiEReferralTravelExpenseTypeSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Controls/EhiEReferralTravelExpenseTypeSelectBox";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import EhiHealthcareProviderId from "@Primitives/EhiHealthcareProviderId.g";
import EhiHealthcareProviderOrganizationUnitId from "@Primitives/EhiHealthcareProviderOrganizationUnitId.g";
import ExaminationSampleView from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/EhiEReferralExtension/CovidData/ExaminationSampleView";
import EpidemiologicalDataView from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/EhiEReferralExtension/CovidData/EpidemiologicalDataView";
import SymptomsView from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/EhiEReferralExtension/CovidData/SymptomsView";
import PreviousResultView from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/EhiEReferralExtension/CovidData/PreviousResultView";
import QuickTestView from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/EhiEReferralExtension/CovidData/QuickTestView";
import ContactsView from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Extensions/EhiEReferralExtension/CovidData/ContactsView";
import EhiStructuredDocumentContactStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/Model/EhiStructuredDocumentContactStore";
import MedicalServiceCodeSelector from "@HisPlatformControls/MedicalServiceCodeSelector";

interface IEhiEReferralExtensionPanelViewProps {
    store: EhiEReferralData;
    useEhiHealthcareProviderData: boolean;
    validationResults: IClientValidationResult[];
    setProviderId: (id: EhiHealthcareProviderId) => void;
    setProviderOrganizationUnitId: (id: EhiHealthcareProviderOrganizationUnitId) => void;
    isCovidDataNeeded: boolean;
    onDeleteContactConfirmationAsync: (contact: EhiStructuredDocumentContactStore) => Promise<boolean>;
    createContactAsync: () => Promise<EhiStructuredDocumentContactStore>;
}


const EhiEReferralExtensionPanelView: React.FC<IEhiEReferralExtensionPanelViewProps> = (props: IEhiEReferralExtensionPanelViewProps) => {
    if (!props.store) {
        return null;
    }
    return (
        <>

            <Ui.GroupBox title={StaticHunEHealthInfrastructureCareResources.EReferralPanel.Title}>
                {props.useEhiHealthcareProviderData &&
                    <Ui.Flex>
                        <Ui.Flex.Item sm={6}>
                            <EhiHealthcareProviderSelector value={props.store.ehiHealthcareProviderId}
                                onChange={props.setProviderId}
                                label={StaticHunEHealthInfrastructureCareResources.EReferralPanel.EhiHealthcareProviderId}
                                propertyIdentifier="EhiHealthcareProviderId"
                                automationId="ehiHealthcareProviderSelector"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item sm={6}>
                            <EhiHealthcareProviderOrganizationUnitSelector
                                value={props.store.ehiHealthcareProviderOrganizationUnitId}
                                onChange={props.setProviderOrganizationUnitId}
                                label={StaticHunEHealthInfrastructureCareResources.EReferralPanel.EhiHealthcareProviderOrganizationUnitId}
                                propertyIdentifier="EhiHealthcareProviderOrganizationUnitId"
                                ehiHealthcareProviderId={props.store.ehiHealthcareProviderId}
                                automationId="ehiHealthcareProviderOrganizationUnitSelector"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                }
                <Ui.Flex>
                    <Ui.Flex.Item sm={6}>
                        <EhiEReferralServiceTypeSelector value={props.store.ehiEReferralServiceTypeId}
                            onChange={props.store.setEhiEReferralServiceTypeId}
                            label={StaticHunEHealthInfrastructureCareResources.EReferralPanel.EhiEReferralServiceTypeId}
                            propertyIdentifier="EhiEReferralServiceTypeId"
                            isReadOnly={props.isCovidDataNeeded}
                            automationId="ehiEReferralServiceTypeSelector"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={6}>
                        <EhiEReferralReasonSelectBox
                            value={props.store.ehiEReferralReasonId}
                            onChange={props.store.setEhiEReferralReasonId}
                            label={StaticHunEHealthInfrastructureCareResources.EReferralPanel.EhiEReferralReasonId}
                            propertyIdentifier="EhiEReferralReasonId"
                            isReadOnly={props.isCovidDataNeeded}
                            automationId="ehiEReferralReasonSelectBox"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item sm={6}>
                        <Ui.TextBox value={props.store.anamnesis} onChange={props.store.setAnamnesis} multiline
                            label={StaticHunEHealthInfrastructureCareResources.EReferralPanel.Anamnesis}
                            propertyIdentifier="Anamnesis"
                            automationId="anamnesisTextBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={6}>
                        <Ui.TextBox
                            value={props.store.concilium}
                            onChange={props.store.setConcilium}
                            multiline
                            label={StaticHunEHealthInfrastructureCareResources.EReferralPanel.Concilium}
                            automationId="conciliumTextBox"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item sm={4}>
                        <EhiEReferralTravelExpenseTypeSelectBox
                            value={props.store.ehiEReferralTravelExpenseTypeId}
                            onChange={props.store.setEhiEReferralTravelExpenseTypeId}
                            label={StaticHunEHealthInfrastructureCareResources.EReferralPanel.EhiEReferralTravelExpenseTypeId}
                            propertyIdentifier="EhiEReferralTravelExpenseTypeId"
                            automationId="ehiEReferralTravelExpenseTypeSelectBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={4}>
                        <Ui.TextBox
                            value={props.store.travelVoucherIdentifier}
                            onChange={props.store.setTravelVoucherIdentifier}
                            label={StaticHunEHealthInfrastructureCareResources.EReferralPanel.TravelVoucherIdentifier}
                            automationId="travelVoucherIdentifierTextBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={4}>
                        <Ui.CheckBox
                            value={props.store.isInAreaCare}
                            onChange={props.store.setIsInAreaCare}
                            label={StaticHunEHealthInfrastructureCareResources.EReferralPanel.IsInAreaCare}
                            verticalAlign="inlineInput"
                            automationId="isInAreaCareCheckBox"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item sm={4}>
                        <Ui.NumberBox
                            value={props.store.sicknessInDaysSinceStart}
                            onChange={props.store.setSicknessInDaysSinceStart}
                            label={StaticHunEHealthInfrastructureCareResources.EReferralPanel.SicknessInDaysSinceStart}
                            automationId="sicknessInDaysSinceStartNumberBox"
                            propertyIdentifier="SicknessInDaysSinceStart"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={4}>
                        <Ui.TextBox
                            value={props.store.sickAllowanceIdentifier}
                            onChange={props.store.setSickAllowanceIdentifier}
                            label={StaticHunEHealthInfrastructureCareResources.EReferralPanel.SickAllowanceIdentifier}
                            automationId="sickAllowanceIdentifierTextBox"
                            propertyIdentifier="SickAllowanceIdentifier"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={4}>
                        <Ui.CheckBox
                            value={props.store.isOutOfOrder}
                            onChange={props.store.setIsOutOfOrder}
                            label={StaticHunEHealthInfrastructureCareResources.EReferralPanel.IsOutOfOrder}
                            verticalAlign="inlineInput"
                            automationId="isOutOfOrderCheckBox"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item sm={4}>
                        <MedicalServiceCodeSelector
                            label={StaticHunEHealthInfrastructureCareResources.EReferralPanel.ReferralMedicalServiceId}
                            value={props.store.referralMedicalServiceId}
                            onChange={props.store.setReferralMedicalServiceIdiceId}
                            automationId="referralMedicalServiceSelector"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={4}>
                        <Ui.CheckBox value={props.store.doctorNotification} onChange={props.store.setDoctorNotification}
                            label={StaticHunEHealthInfrastructureCareResources.EReferralPanel.DoctorNotification}
                            verticalAlign="inlineInput"
                            automationId="doctorNotificationCheckBox"
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item sm={4}>
                        <Ui.CheckBox value={props.store.patientNotification} onChange={props.store.setPatientNotification}
                            label={StaticHunEHealthInfrastructureCareResources.EReferralPanel.PatientNotification}
                            verticalAlign="inlineInput"
                            automationId="patientNotificationCheckBox"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.GroupBox>
            {
                props.isCovidDataNeeded &&
                <>
                    <ContactsView
                        store={props.store.covidData}
                        createContact={props.createContactAsync}
                        onDeleteContactConfirmationAsync={props.onDeleteContactConfirmationAsync}
                    />
                    <ExaminationSampleView store={props.store.covidData} />
                    <EpidemiologicalDataView store={props.store.covidData} />
                    <SymptomsView store={props.store.covidData} />
                    <PreviousResultView store={props.store.covidData} />
                    <QuickTestView store={props.store.covidData} />
                </>
            }
        </>
    );
}
    ;

export default State.observer(EhiEReferralExtensionPanelView);
