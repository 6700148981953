import Di from "@Di";
import { IHomeMenuListItemGroupView, IHomeMenuListItemView } from "@HisPlatform/Packages/Care/Components/HomeMenu/HomeControllerStore";
import { IHomeMenuProps } from "@HisPlatform/Packages/Care/Components/HomeMenu/HomeMenu";
import { createHomeMenuLocalizationHelpers } from "@HisPlatform/Packages/Care/Components/HomeMenu/HomeMenuLocalizationHelpers";
import ResourceId from "@Primitives/ResourceId.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import MultiLingualLabel from "@Toolkit/CommonWeb/MultiLingualLabel";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import { TypedEvent } from "@Toolkit/CommonWeb/TypedEvent";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";


@Di.injectable()
export default class HomeMenuStore extends PanelStoreBase<IHomeMenuProps> {

    @State.observable.ref public searchText = "";
    @State.observable.ref public isOpen = false;
    @State.observable.ref public hoveredIndex = -1;
    public readonly searchFocusEvent = new TypedEvent();

    private readonly localizationHelpers = createHomeMenuLocalizationHelpers(this.localizationService);

    @State.computed
    public get selectedItemDisplayName() {
        if (!this.props._homeController!.selectedItem) {
            return "";
        }

        return this.localizationHelpers.getLocalizedText(this.props._homeController!.selectedItem?.localizedShortName, this.props._homeController!.selectedItem?.shortNameResourceId);
    }

    @State.computed public get filteredItemGroups() {
        if (this.searchText.length === 0) {
            return this.props._homeController!.itemGroups;
        }

        let index = 0;
        return this.props._homeController!.itemGroups.map<IHomeMenuListItemGroupView>(group => ({
            ...group,
            items: group.items.filter(item => item.displayValue.toLowerCase().includes(this.searchText.toLowerCase())).map(i => ({
                ...i,
                index: index++
            }))
        })).filter(g => g.items.length > 0);
    }

    @State.computed public get filteredItemsLength() {
        return this.filteredItemGroups.reduce((sum, grp) => sum + grp.items.length, 0);
    }

    constructor(
        @Di.inject("ILocalizationService") private readonly localizationService: ILocalizationService,
    ) {
        super();
    }

    @State.action.bound
    public keyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        switch (event.key) {
            case "Enter":
                this.getHoveredItem()?.onSelect?.();
                event.preventDefault();
                this.close();
                break;
            case "ArrowDown":
                if (this.hoveredIndex < (this.filteredItemsLength - 1)) {
                    this.hoveredIndex++;
                }
                event.preventDefault();
                break;
            case "ArrowUp":
                if (this.hoveredIndex > 0) {
                    this.hoveredIndex--;
                }
                event.preventDefault();
                break;
        }
    }

    @State.action.bound
    public setSearchText(value: string) {
        this.searchText = value;
        this.hoveredIndex = 0;
    }

    @State.action.bound
    public searchFocused() {
        this.isOpen = true;
        this.hoveredIndex = 0;
    }

    @State.action.bound
    public close() {
        this.isOpen = false;
        this.hoveredIndex = -1;
        this.searchText = "";
    }

    private getHoveredItem() {
        return this.filteredItemGroups.flatMap(g => g.items).find(i => i.index === this.hoveredIndex);
    }

    @State.action.bound
    public open() {
        this.isOpen = true;
        setImmediate(() => {
            this.searchFocusEvent.emit();
        });
    }

    public readonly openSelectedAsync = this.async(async () => {
        this.close();
        await this.props._homeController!.showSelectedItemAsync();
    });

    public selectItemFactory(i: IHomeMenuListItemView) {
        return () => {
            this.close();
            i.onSelect?.();
        };
    }
}