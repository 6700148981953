import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import PatientDocumentTypeId from "@Primitives/PatientDocumentTypeId.g";
import IdentifierSystemId from "@Primitives/IdentifierSystemId.g";
import PatientDocumentCategory from "@HisPlatform/BoundedContexts/Care/Api/PatientRegister/Enum/PatientDocumentCategory.g";

export default class PatientDocumentType extends EntityStoreBase<PatientDocumentTypeId> {
    public category: PatientDocumentCategory;
    public identifierSystemId: IdentifierSystemId;
    public isSelectable: boolean;
}
