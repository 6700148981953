import ServiceRequestDirection from "@HisPlatform/BoundedContexts/Care/Api/ServiceRequestManagement/Enum/ServiceRequestDirection.g";
import PropertyGroup from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/DynamicProperties/PropertyGroup";

const ehiSettingsPropertyGroupName = "HunEHealthInfrastructureSettings";
const eReferralInternalToExternalPropertyName = "SendEReferralWithInternalToExternalRequests";
const eReferralInternalToInternalPropertyName = "SendEReferralWithInternalToInternalRequests";
const eReferralExternalToInternalPropertyName = "SendEReferralWithExternalToInternalRequests";
const covidPcrServiceRequestDefinitionCodesPropertyName = "CovidPcrServiceRequestDefinitionCodes";

export function IsEhiEReferralExtensionNeeded(properties: PropertyGroup[], direction: ServiceRequestDirection): boolean {
    const propertyGroup = properties.find(item => item.name === ehiSettingsPropertyGroupName);
    if (!propertyGroup) {
        return false;
    }
    switch (direction) {
        case ServiceRequestDirection.ExternalToInternal:
            return getPropertyValue(propertyGroup, eReferralExternalToInternalPropertyName);
        case ServiceRequestDirection.InternalToInternal:
            return getPropertyValue(propertyGroup, eReferralInternalToInternalPropertyName);
        case ServiceRequestDirection.InternalToExternal:
            return getPropertyValue(propertyGroup, eReferralInternalToExternalPropertyName);
        default:
            throw Error(`Unknown ServiceRequestDirection: ${direction}`);
    }
}

export function IsCovidDataNeeded(properties: PropertyGroup[], definitionCode: string): boolean {
    const propertyGroup = properties.find(item => item.name === ehiSettingsPropertyGroupName);
    if (!propertyGroup) {
        return false;
    }
    const property = getPropertyValue(propertyGroup, covidPcrServiceRequestDefinitionCodesPropertyName);
    return (property as string)?.includes(definitionCode);
}

function getPropertyValue(propertyGroup: PropertyGroup, propertyName: string) {
    const property = propertyGroup.properties.find(item => item.name === propertyName);
    const result = property.versions?.length ? property.getValue() : property.getEffectiveValue();
    return result;
}
