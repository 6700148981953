import React from "react";
import { Button } from "@CommonControls";
import { IButtonProps } from "@CommonControls/Button";
import ModalContext, { IModalContext } from "./ModalContext";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IModalButtonProps extends IButtonProps {
    onClick?: (close: () => void) => void;
    onClickAsync?: (close: () => void) => Promise<void>;
    closeModalOnClick?: boolean;
    automationId: string;
}

export default class ModalButton extends React.PureComponent<IModalButtonProps> {

    @State.bound
    private clickHandlerFactory(context: IModalContext) {
        return () => {
            if (this.props.closeModalOnClick) {
                this.closeModal(context);
            } else {
                if (this.props.onClick) {
                    this.props.onClick(() => this.closeModal(context));
                }
            }
        };
    }

    @State.bound
    private asyncClickHandlerFactory(context: IModalContext) {
        return async () => {
            if (this.props.closeModalOnClick) {
                this.closeModal(context);
            } else {
                if (this.props.onClickAsync) {
                    await this.props.onClickAsync(() => this.closeModal(context));
                }
            }
        };
    }

    @State.bound
    private closeModal(context: IModalContext) {
        if (context && context.close) {
            context.close();
        }
    }

    private renderCore(context: IModalContext) {

        const buttonProps: any = { ...this.props };

        delete buttonProps.onClick;

        const onClickProps = {} as any;

        if (this.props.onClickAsync) {
            onClickProps.onClickAsync = this.asyncClickHandlerFactory(context);
        } else {
            onClickProps.onClick = this.clickHandlerFactory(context);
        }

        return <Button {...buttonProps} {...onClickProps} automationId={this.props.automationId}/>;
    }

    public render() {
        return (
            <ModalContext.Consumer>
                {(context) => this.renderCore(context)}
            </ModalContext.Consumer>
        );
    }
}