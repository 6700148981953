import React from "react";
import IAppointmentSchedulerBaseProps from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduler/AppointmentSchedulerBaseProps";
import AppointmentScheduler from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduler/AppointmentScheduler";
import PractitionerId from "@Primitives/PractitionerId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ICalendarDayStyle from "@CommonControls/DateTimePicker/ICalendarDayStyle";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import PractitionerSelectBox from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PractitionerSelectBox";
import _ from "@HisPlatform/Common/Lodash";
import * as Styles from "./PractitionerAppointmentsSchedule.less";
import moment from "moment";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import { ISchedulerEvent } from "@CommonControls/Scheduler/ISchedulerProps";
import NoItemsMessage from "@CommonControls/NoItemsMessage";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import * as Ui from "@CommonControls";

interface IPractitionerAppointmentsScheduleProps extends IAppointmentSchedulerBaseProps {
    events: ISchedulerEvent[];
    practitionerId?: PractitionerId;
    explicitPractitionerIds: PractitionerId[];
    onPractitionerIdChanged: (newValue: PractitionerId) => void;
}

@State.observer
export default class PractitionerAppointmentsSchedule extends React.Component<IPractitionerAppointmentsScheduleProps> {
    @State.computed
    private get calendarStyles() {
        
        const groupedEvents = _.groupBy(this.props.events, (entry) => entry.startTime.format("L"));

        const res: ICalendarDayStyle[]  = [];

        _.forIn(groupedEvents, (value, key) => {
            res.push({
                className: Styles.calendar,
                day: moment(key, "L")
            } as ICalendarDayStyle);
        });

        return res;
    }

    @State.action.bound
    private getEntryClassName(events: ISchedulerEvent): string {
        if (this.isEntryBlock(events)) {
            return Styles.entryBlock;
        } else {
            return Styles.entry;
        }
    }

    @State.action.bound
    private isEntryBlock(event: ISchedulerEvent) {
        return (event.id < 0);
    }

    @State.computed private get scrollToTargets() {
        return _.uniqBy(this.props.events
            .filter(e =>  e.startTime.isSame(this.props.currentMoment, "day"))
            .map(e => new TimeOfDay(e.startTime.hour(), 0)), e => e.hours).sort((a, b) => a.hours - b.hours);
    }

    @State.bound
    private getEntryAutomationIdPrefix(event: ISchedulerEvent) {
        return this.isEntryBlock(event) ? "block" : "appointment";
    }

    @State.bound
    private renderAdditionalSidebarComponent() {
        return (
            <PractitionerSelectBox
                placeholder={StaticSchedulingResources.PractitionerAppointments.SelectPractitioner}
                className={Styles.practitionerSelectBox}
                label={StaticSchedulingResources.PractitionerAppointments.Practitioner}
                onChange={this.props.onPractitionerIdChanged}
                value={this.props.practitionerId}                 
                explicitIds={this.props.explicitPractitionerIds}
                automationId="practitionerSelectBox"
            />
        );
    }

    @State.bound
    private renderSchedulerPlaceholder(): React.ReactNode {
        const message = isNullOrUndefined(this.props.practitionerId)
            ? StaticSchedulingResources.PractitionerAppointments.NoPractitionerSelected
            : StaticSchedulingResources.PractitionerAppointments.PractitionerHasNoResourceBlockings;

        return (
            <Ui.Flex className={Styles.schedulerPlaceholder}>
                <Ui.Flex.Item>
                    <NoItemsMessage message={message} showEmptyIcon={true} />
                </Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    public render() {
        return (
            <AppointmentScheduler 
                {...this.props}
                calendarStyles={this.calendarStyles}
                onGetEntryClassName={this.getEntryClassName}
                entryEditIconClassName={Styles.editIcon}
                onGetEntryAutomationIdPrefix={this.getEntryAutomationIdPrefix}
                scrollToTargets={this.scrollToTargets}
                onRenderAdditionalSidebarComponent={this.renderAdditionalSidebarComponent}
                hideCalendarWithoutData
                onScheduleScaleChanged={this.props.onScheduleScaleChanged}
                onSelectedMonthChange={this.props.onSelectedMonthChange}
                renderSchedulerPlaceholder={this.renderSchedulerPlaceholder}
            />
        );
    }
}