import React from "react";
import CareActivitiesByDurationWidgetApiAdapter from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/CareActivitiesByDurationWidget/CareActivitiesByDurationWidgetApiAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import OrganizationUnitId from "@Primitives/OrganizationUnitId.g";
import IWidgetComponentProps from "@PluginInterface/Dashboard/IWidgetComponentProps";
import * as Ui from "@CommonControls";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { IIntervalWithCountDisplayData } from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/CareActivitiesByDurationWidget/IIntervalWithCountDisplayData";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import moment from "moment";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import { DashboardContextAdapter } from "@CommonControls/Dashboard/DashboardContext";
import { DashboardMode } from "@CommonControls/Dashboard/DashboardMode";
import ApplicationContext from "@HisPlatform/Model/DomainModel/ApplicationContext/ApplicationContext";

interface ICareActivitiesByDurationWidgetDependencies {
    apiAdapter: CareActivitiesByDurationWidgetApiAdapter;
    applicationContext: ApplicationContext;
}

interface ICareActivitiesByDurationWidgetProps extends IWidgetComponentProps {
    _mode?: DashboardMode;
    _dependencies?: ICareActivitiesByDurationWidgetDependencies;
}

/** @screen */
@State.observer
class CareActivitiesByDurationWidget extends React.Component<ICareActivitiesByDurationWidgetProps> {

    private get apiAdapter() { return this.props._dependencies.apiAdapter; }

    @State.observable.ref private dateRange: LocalDateRange = null;
    @State.observable private intervalsInMinutes: number[] = null;

    @State.observable.ref private data: IIntervalWithCountDisplayData[] = null;

    @State.computed private get height() { return this.props.configuration.InitialLayout.Height * 150; }

    @State.bound
    private getWidth(): number {
        switch (this.props._mode) {
            case "normal":
                return 300;
            case "small":
                return 150;
        }
    }

    private readonly initialLoadPanelAsync = createInitialPanelLoader(this.initializeAsync);
    public componentDidMount() {
        dispatchAsyncErrors(this.initialLoadPanelAsync(), this);
    }

    @State.bound
    private async initializeAsync() {
        this.setInitialValues();
        await this.loadAsync();
    }

    @State.action.bound
    private setDateRange(newValue: LocalDateRange) {
        this.dateRange = newValue;
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.action.bound
    private setInitialValues() {
        this.dateRange = new LocalDateRange(LocalDate.createFromMoment(DateTimeService.now().startOf("month")), LocalDate.createFromMoment(DateTimeService.now().endOf("month")));
        this.intervalsInMinutes = [10, 20, 30, 40, 50, 60];
    }

    @State.action.bound
    private async loadAsync() {

        if (!this.props._dependencies!.applicationContext.contextualOrganizationUnitId) {
            return;
        }

        const response = await this.apiAdapter.getCareActivityCountsByLengthAsync(
            this.dateRange.from,
            this.dateRange.to,
            this.intervalsInMinutes,
            this.props._dependencies!.applicationContext.contextualOrganizationUnitId);

        State.runInAction(() => {
            this.data = response.value.map(c => {
                return {
                    interval: this.getIntervalDisplayValue(c.from, c.to),
                    count: c.count
                } as IIntervalWithCountDisplayData;
            });
        });
    }

    private getIntervalDisplayValue(from: number, to: number) {
        if (!isNullOrUndefined(from) && isNullOrUndefined(to)) {
            return from.toString() + "+ " + StaticProductivityResources.Widgets.CareActivitiesByDurationWidget.Minute;
        }
        const fromValue = isNullOrUndefined(from) ? "" : from.toString();
        const toValue = isNullOrUndefined(to) ? "" : to.toString();
        return fromValue + " - " + toValue + " " + StaticProductivityResources.Widgets.CareActivitiesByDurationWidget.Minute;
    }

    private renderFilters() {
        return (
            <Ui.DateRangePicker
                visualMode="dark"
                label={StaticProductivityResources.Widgets.CareActivitiesByDurationWidget.DateRange}
                value={this.dateRange}
                onChange={this.setDateRange}
                clearable={false}
                automationId="careActivitiesByDurationWidgetDateRangePicker"
            />
        );
    }

    @State.bound
    private renderWidgetContent() {
        return (
            <div style={{ height: this.height }}>
                <Ui.Chart.Bar
                    height={this.height}
                    width={this.getWidth()}
                    data={this.data}
                    theme="dark"
                    XAxisDataKey="interval"
                    barSettings={[{ dataKey: "count", fill: "#2ad4b1", name: StaticProductivityResources.Widgets.CareActivitiesByDurationWidget.Count, unit: " " + StaticProductivityResources.Widgets.CareActivitiesByDurationWidget.Unit }]}
                    margin={{ top: 10, left: -30, bottom: 0, right: 10 }}
                    isTooltipEnabled={true}
                />
            </div>
        );
    }

    public render() {
        if (this.initialLoadPanelAsync.isUnauthorizedAccess) {
            return null;
        }

        return (
            <Ui.Dashboard.Widget
                name={this.props.name}
                isCloseByDefault={this.props.configuration && this.props.configuration.isCloseByDefault}
                icon={"chart"}
                isCollapsible
                title={StaticProductivityResources.Widgets.CareActivitiesByDurationWidget.Title}
                automationId="CareActivitiesByDurationWidget"
            >

                {this.renderFilters()}
                {this.renderWidgetContent()}
                <State.Reaction inspector={this.getOrganizationUnitId} reaction={this.reload} />
            </Ui.Dashboard.Widget>
        );
    }

    @State.bound
    private getOrganizationUnitId() {
        return this.props._dependencies!.applicationContext.contextualOrganizationUnitId;
    }

    @State.bound
    private reload() {
        dispatchAsyncErrors(this.initializeAsync(), this);
    }
}

export default connect(
    withLoadingBoundary(CareActivitiesByDurationWidget),
    new DependencyAdapter<ICareActivitiesByDurationWidgetProps, ICareActivitiesByDurationWidgetDependencies>(c => ({
        apiAdapter: c.resolve("CareActivitiesByDurationWidgetApiAdapter"),
        applicationContext: c.resolve("ApplicationContext")
    })),
    new DashboardContextAdapter<ICareActivitiesByDurationWidgetProps>(d => ({
        _mode: d.mode
    })),
);