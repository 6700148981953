/* auto-inject-disable */
import Di from "@Di";
import ICommandDispatcher from "@Toolkit/CommonWeb/CommandProcessing/Definition/ICommandDispatcher";
import IMessagingSubscription from "@Toolkit/CommonWeb/CommandProcessing/Definition/IMessagingSubscription";
import Command from "@Toolkit/CommonWeb/CommandProcessing/Definition/Command";
import CommandMessage from "@Toolkit/CommonWeb/CommandProcessing/Definition/CommandMessage";
import ICommandFactory from "@Toolkit/CommonWeb/CommandProcessing/Definition/ICommandFactory";
import IMessagingSubscriptionManager from "@Toolkit/CommonWeb/CommandProcessing/Definition/IMessagingSubscriptionManager";
import UnspecifiedCommand from "@Toolkit/CommonWeb/CommandProcessing/Definition/UnspecifiedCommand";
import Guid from "@Toolkit/CommonWeb/Guid";
import MessagingSubscription from "./MessagingSubscription";
import Log from "@HisPlatform/Services/Definition/Logger/Log";

const MESSAGE_EVENT: string = "message";

/**
 * A service that provides methods for subscribing to the messages of {@link Window} instances.
 */
@Di.injectable()
export default class MessagingSubscriptionManager implements IMessagingSubscriptionManager {
    private readonly _subscriptions: Set<MessagingSubscription> = new Set();
    private readonly _commandFactories: Map<string, ICommandFactory> = new Map<string, ICommandFactory>();

    constructor(
        @Di.inject("ICommandDispatcher") private readonly _commandDispatcher: ICommandDispatcher,
        @Di.multiInject("ICommandFactory") commandFactories: ICommandFactory[]
    ) {
        commandFactories.forEach(commandFactory =>
            this._commandFactories.set(commandFactory.commandType, commandFactory));

        self.addEventListener(MESSAGE_EVENT, this.onMessageReceived.bind(this), false);
    }

    /** {@inheritdoc} */
    public subscribeWithAccessor(getAssociatedWindow: () => Window): IMessagingSubscription {
        const subscription = new MessagingSubscription(
            Guid.newGuid(),
            getAssociatedWindow,
            this._subscriptions.delete.bind(this._subscriptions));

        this._subscriptions.add(subscription);

        return subscription;
    }

    private onMessageReceived(event: any): void {
        if (!event.data) {
            Log.warn("Window received a message with no data.");
            return;
        }

        const eventData = this.parseEventData(event.data);
        if (!eventData) {
            return;
        }

        const command = this.createCommand(eventData);
        if (!command) {
            return;
        }

        let subscriptionCount = 0;
        for (const subscription of this._subscriptions.values()) {
            if (!subscription.canHandle(event.source?.window || event.srcElement, command)) {
                continue;
            }

            Log.trace(`Subscription '${subscription.id}' received a command of type '${command.commandType}'.`);
            subscriptionCount++;

            const registrations = subscription.getRegistrations(command);
            for (const registration of registrations) {
                const commandCopy = JSON.parse(JSON.stringify(command));
                if (!!registration.mutator) {
                    registration.mutator(commandCopy);
                }

                const promise = !!registration.processor
                    ? registration.processor.processGenericAsync(commandCopy)
                    : this._commandDispatcher.dispatchAsync(commandCopy);
                promise.catch(Log.error);
            }
        }

        const logMessage = subscriptionCount > 0
            ? `Command '${command.commandType}' has been processed by ${subscriptionCount} subscriptions.`
            : `Command '${command.commandType}' hasn't been processed as there are no associated subscriptions.`;
        Log.trace(logMessage);
    }

    private parseEventData(data: any): any | null {
        const dataType = typeof data;
        let eventData = null;
        switch (dataType) {
            case "object":
                eventData = data;
                break;
            case "string":
                try {
                    eventData = JSON.parse(data);
                } catch (error) {
                    // Ignored.
                }
                break;
        }
        return !eventData || typeof eventData !== "object"
            ? null
            : eventData;
    }

    private createCommand(eventData: any): Command | null {
        if (!eventData?.commandType) {
            return new UnspecifiedCommand("Unknown", eventData);
        }

        const commandMessage = new CommandMessage(eventData.commandType, eventData.originator, eventData.data);
        const commandFactory = this._commandFactories.has(commandMessage.commandType)
            ? this._commandFactories.get(commandMessage.commandType)
            : null;
        if (!commandFactory) {
            Log.warn(`Received a command with unknown type '${commandMessage.commandType}'.`);
            return null;
        }

        return commandFactory.createFromMessage(commandMessage);
    }
}