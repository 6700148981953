import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IPrescriptionsSideMenuItemExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPrescriptionsSideMenuItemExtensionPointProps";
import SideMenuItem from "@CommonControls/SideMenu/SideMenuItem";

@State.observer
export default class PrescriptionsSideMenuItemExtension extends React.Component<IPrescriptionsSideMenuItemExtensionPointProps> {

    public render() {
        return (
            <>
                <SideMenuItem
                    iconName="pills"
                    isActive={this.props.isActive}
                    text={this.props.text}
                    onClick={this.props.onClick}
                    disabled={this.props.disabled}
                    permissionCheckOperationNames="ViewPatientCareActivities"
                    permissionDeniedStyle="disabled"
                    automationId="__prescriptions"
                />
            </>
        );
    }
}