import Di from "@Di";
import { IExtensionComponentService } from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import IPrescriptionEhiErrorsExtensionPointProps from "@PluginInterface/BoundedContexts/MedicationRequest/ExtensionPoints/IPrescriptionEhiErrorsExtensionPointProps";

@Di.injectable()
export default class PrescriptionEhiErrorSummaryExtensionComponentService implements IExtensionComponentService<IPrescriptionEhiErrorsExtensionPointProps> {

    public isVisible(props: IPrescriptionEhiErrorsExtensionPointProps) {
        return props?.extensionData !== null;
    }

    public shouldRerunIsVisible(props: IPrescriptionEhiErrorsExtensionPointProps, prevProps: IPrescriptionEhiErrorsExtensionPointProps) {
        return props?.extensionData !== prevProps?.extensionData;
    }
}