import Command from "@Toolkit/CommonWeb/CommandProcessing/Definition/Command";
import IMessagingSubscription from "@Toolkit/CommonWeb/CommandProcessing/Definition/IMessagingSubscription";
import ICommandProcessor from "@Toolkit/CommonWeb/CommandProcessing/Definition/ICommandProcessor";
import Guid from "@Toolkit/CommonWeb/Guid";
import IRegistration from "@Toolkit/CommonWeb/CommandProcessing/Definition/IRegistration";
import CallbackCommandProcessor from "./CallbackCommandProcessor";

/**
 * Represents a disposable subscription to a {@link Window}'s messages.
 */
export default class MessagingSubscription implements IMessagingSubscription {
    private readonly _commandConfigurations: { [commandType: string]: IRegistration[] } = {};

    constructor(
        public readonly id: Guid,
        public readonly getAssociatedWindow: () => Window,
        private readonly _onDispose: (subscription: MessagingSubscription) => void
    ) {
    }

    /** {@inheritdoc} */
    public dispose(): void {
        this._onDispose(this);
    }

    /** {@inheritdoc} */
    public register<TCommand extends Command>(commandType: string, mutator?: (command: TCommand) => void, processor?: ICommandProcessor<TCommand>): void {
        const registrations = this.getOrAddConfiguration(commandType);
        registrations.push({
            mutator: mutator,
            processor: processor
        });
    }

    /** {@inheritdoc} */
    registerCallback<TCommand extends Command>(commandType: string, callback: (command: TCommand) => Promise<void>): void {
        const registrations = this.getOrAddConfiguration(commandType);
        registrations.push({
            processor: new CallbackCommandProcessor<TCommand>(commandType, callback)
        });
    }

    /**
     * Determines if the subscription has a registration for the specified {@link Command}.
     * @param source The source of the received command.
     * @param command The received command.
     * @returns True, if the subscription has a registration for the command.
     */
    public canHandle(source: Window, command: Command): boolean {
        return source === this.getAssociatedWindow()
               && command.commandType in this._commandConfigurations;
    }

    /**
     * Gets the registrations associated to the specified {@link Command}.
     * @param command The command for which to get the registrations.
     * @returns The registrations associated to the given command.
     */
    public getRegistrations(command: Command): IRegistration[] {
        const registrations = this._commandConfigurations[command.commandType];
        return !!registrations ? [...registrations] : [];
    }

    private getOrAddConfiguration(commandType: string): IRegistration[] {
        let registrations = this._commandConfigurations[commandType];
        if (!registrations) {
            registrations = [];
            this._commandConfigurations[commandType] = registrations;
        }
        
        return registrations;
    }
}