import EmbeddedFormStore from "./EmbeddedFormStore";
import Di from "@Di";
import DynamicPropertiesApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/DynamicProperties/DynamicPropertiesApiAdapter";
import IFormEngineReferenceDataStore from "@Toolkit/FormEngine/Store/IFormEngineReferenceDataStore";
import IFormEngineApiAdapter from "@Toolkit/FormEngine/ApiAdapter/IFormEngineApiAdapter";
import ConfigurationDynamicPropertiesApiAdapter from "@HisPlatform/BoundedContexts/Configuration/ApplicationLogic/ApiAdapter/DynamicProperties/ConfigurationDynamicPropertiesApiAdapter";

@Di.injectable()
export default class EmbeddedFormStoreFactory {
    constructor(
        @Di.inject("DynamicPropertiesApiAdapter") private readonly dynamicPropertiesApiAdapter: DynamicPropertiesApiAdapter,
        @Di.inject("ConfigurationDynamicPropertiesApiAdapter") private readonly configurationDynamicPropertiesApiAdapter: ConfigurationDynamicPropertiesApiAdapter,
        @Di.inject("IFormEngineReferenceDataStore") private readonly formEngineReferenceDataStore: IFormEngineReferenceDataStore,
        @Di.inject("IFormEngineApiAdapter") private readonly formEngineApiAdapter: IFormEngineApiAdapter,
    ) { }

    public create(baseEntityType: string) {
        return new EmbeddedFormStore(
            this.dynamicPropertiesApiAdapter,
            this.configurationDynamicPropertiesApiAdapter,
            this.formEngineReferenceDataStore,
            this.formEngineApiAdapter,
            baseEntityType
        );
    }
}