import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import Styles from "./ScrollView.less";
import _ from "@HisPlatform/Common/Lodash";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";

interface IScrollViewProps {
    style?: React.CSSProperties;
    innerStyle?: React.CSSProperties;
    height?: string | number;
    wheelMode?: "vertical" | "horizontal";
    childElementIds?: string[];
    onCurrentElementChanged?: (elementId: string) => void;
    className?: string;
    innerClassName?: string;
}

export default class ScrollView extends React.Component<IScrollViewProps> {

    private innerDivRef = React.createRef<HTMLDivElement>();

    public static defaultProps: Partial<IScrollViewProps> = {
        wheelMode: "vertical"
    };

    public render() {
        return (
            <div className={combineClasses(Styles.scrollbarOuter, this.props.className)} style={{ ...this.props.style, height: this.props.height }}>
                <div
                    className={combineClasses(Styles.scrollbarWrapper, this.props.wheelMode === "horizontal" && Styles.horizontalOnly, this.props.innerClassName)}
                    ref={this.innerDivRef}
                    onWheel={this.props.wheelMode === "horizontal" ? this.scrollWheelHorizontally : undefined}
                    onScroll={this.scroll}
                    style={this.props.innerStyle}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }


    @State.bound
    private scroll() {
        this.setCurrentScrolledElementDebounced();
    }

    private setCurrentScrolledElementDebounced = _.debounce(() => {
        if (this.props.childElementIds) {
            const top = this.innerDivRef.current.scrollTop;
            const tops = this.getChildElementTops();
            if (this.props.onCurrentElementChanged) {
                const selectedTop = tops.find(t => t.top > (top - 10));
                if (selectedTop) {
                    this.props.onCurrentElementChanged(selectedTop.elementId);
                }
            }
        }
    }, 100);

    @State.bound
    private scrollWheelHorizontally(e: React.WheelEvent<any>) {
        const delta = e.deltaY * (/Firefox/.test(navigator.userAgent) ? 40 : 1);
        this.innerDivRef.current.scrollLeft = this.innerDivRef.current.scrollLeft + delta;
        e.preventDefault();
    }

    public scrollHorizontal(targetValue: number) {
        this.innerDivRef.current.scroll({
            left: targetValue,
            behavior: "smooth"
        });
    }

    public scrollTop(targetValue: number) {
        this.innerDivRef.current.scroll({
            top: targetValue,
            behavior: "smooth"
        });
    }

    private getChildElementTops() {
        const elementIdsWithTops = this.props.childElementIds && this.props.childElementIds.map(id => {
            const element = document.getElementById(id);
            return element && { elementId: id, top: element.offsetTop };
        }).filter(i => !!i);
        const orderedElementIdsWithTops = _.orderBy(elementIdsWithTops, i => i.top, "asc");
        return orderedElementIdsWithTops;
    }

    @State.bound
    public scrollToElement(elementId?: string) {
        if (elementId && this.innerDivRef.current) {
            const element = document.getElementById(elementId);
            if (element) {
                this.scrollTop(element.offsetTop);
            }
        }
    }

    public getScrollWidth() {
        return this.innerDivRef.current.scrollWidth;
    }

    public getScrollHeight() {

        return this.innerDivRef.current.scrollHeight;
    }

    public getScrollLeft() {
        return this.innerDivRef.current.scrollLeft;
    }

    public getScrollTop() {
        return this.innerDivRef.current.scrollTop;
    }

    public getClientWidth() {
        return this.innerDivRef.current.clientWidth;
    }

    public getClientHeight() {
        return this.innerDivRef.current.clientHeight;
    }
}