exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OrganizationUnitTreePanel_fixed-height_174ck {\n  height: calc(100% - 11px);\n}\n.OrganizationUnitTreePanel_list-container_2byOl {\n  height: calc(100% - 11px);\n}\n.OrganizationUnitTreePanel_detail-container_3a97G {\n  height: calc(100% - 11px);\n  margin-left: 18px;\n}\n.OrganizationUnitTreePanel_no-margin_2mHlZ {\n  margin: 0;\n}\n.OrganizationUnitTreePanel_title-space_34dB4 {\n  padding-left: 8px;\n}\n", ""]);

// exports
exports.locals = {
	"fixed-height": "OrganizationUnitTreePanel_fixed-height_174ck",
	"fixedHeight": "OrganizationUnitTreePanel_fixed-height_174ck",
	"list-container": "OrganizationUnitTreePanel_list-container_2byOl",
	"listContainer": "OrganizationUnitTreePanel_list-container_2byOl",
	"detail-container": "OrganizationUnitTreePanel_detail-container_3a97G",
	"detailContainer": "OrganizationUnitTreePanel_detail-container_3a97G",
	"no-margin": "OrganizationUnitTreePanel_no-margin_2mHlZ",
	"noMargin": "OrganizationUnitTreePanel_no-margin_2mHlZ",
	"title-space": "OrganizationUnitTreePanel_title-space_34dB4",
	"titleSpace": "OrganizationUnitTreePanel_title-space_34dB4"
};