import React from "react";
import { ScreenNavigationContextProvider, useScreenNavigationContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import ShowScreenActionHost from "@HisPlatform/Components/ShowScreenAction/ShowScreenActionHost";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";

interface IModalSecondaryScreenRendererProps {
    onCancelled?: () => void;
    onSavedExisting?: () => void;
    onSavedNew?: (newId: StringEntityId) => void;
    onDeleted?: () => void;
}

export default function ModalSecondaryScreenRenderer(props: IModalSecondaryScreenRendererProps) {
    const navigationContext = useScreenNavigationContext();

    if (!navigationContext.isSecondaryModalOpen) {
        return null;
    }

    return (
        <ScreenNavigationContextProvider>
            <ShowScreenActionHost
                screenRaw={navigationContext.currentSecondaryScreenRaw}
                onClose={navigationContext.clearAllNotPrimary}
                onCancelled={props.onCancelled}
                onSavedExisting={props.onSavedExisting}
                onSavedNew={props.onSavedNew}
                onDeleted={props.onDeleted}
            />
        </ScreenNavigationContextProvider>
    );
}