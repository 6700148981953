import WorkListArgument from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Worklist/WorkListArguments/WorkListArgument";
import PerformanceStatementCareActivityStatusType from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementCareActivityStatusType.g";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";

export default class BatchUpdatePerformanceStatementCareActivityManualNeakStatusAndMonthArgument extends WorkListArgument {
    constructor(
        public readonly newValidity: YearMonth,
        public readonly newType: PerformanceStatementCareActivityStatusType
    ) {
        super();
     }
}