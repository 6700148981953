import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import PractitionerId from "@Primitives/PractitionerId.g";
import { DisplayMode } from "@HisPlatform/BoundedContexts/Organization/Components/Controls/PointOfCareColumn/PointOfCareColumn";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import AsyncMessageQueue from "@Toolkit/CommonWeb/AsyncMessageQueue";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import { emptyObject, isNullOrUndefined, arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import _ from "@HisPlatform/Common/Lodash";
import * as Ui from "@CommonControls";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface IBookSlotDialogPractitionerSelectBoxDependencies {
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    localizationService: ILocalizationService;
}

interface IBookSlotDialogPractitionerSelectBoxProps extends ISelectBoxBaseProps {
    _dependencies?: IBookSlotDialogPractitionerSelectBoxDependencies;
    explicitIds: PractitionerId[][];
    reselectOnUpdate?: boolean;
    displayMode?: DisplayMode;
}

@State.observer
class BookSlotDialogPractitionerSelectBox extends React.Component<IBookSlotDialogPractitionerSelectBoxProps> {
    private readonly loadQueue = new AsyncMessageQueue(this.loadAsync);
    private readonly reloadQueue = new AsyncMessageQueue(this.reloadAsync);

    private get referenceDataStore() {
        return this.props._dependencies.organizationReferenceDataStore;
    }

    @State.observable.ref private items: Array<ISelectBoxItem<PractitionerId[]>> = null;

    public componentDidMount() {
        dispatchAsyncErrors(this.loadQueue.enqueueAndProcessAsync(emptyObject), this);
    }

    public componentDidUpdate(prevProps: IBookSlotDialogPractitionerSelectBoxProps) {
        if (!_.isEqual(prevProps.explicitIds, this.props.explicitIds)) {
            dispatchAsyncErrors(this.reloadQueue.enqueueAndProcessAsync(emptyObject), this);
        }
    }

    @State.bound
    private async loadAsync() {
        await this.initializeAsync();
        this.selectFirstItem();
    }

    @State.bound
    private async reloadAsync() {
        await this.initializeAsync();
        if (this.props.reselectOnUpdate && !this.items.some(i => _.isEqual(i.value, this.props.value))) {
            this.selectFirstItem();
        }
    }

    @State.bound
    private async initializeAsync() {
        const explicitIds = !isNullOrUndefined(this.props.explicitIds) ? _.flatten(this.props.explicitIds) : [];
        await this.referenceDataStore.practitionerMap.ensureLoadedAsync(explicitIds);
        this.initializeItems();
    }

    @State.action.bound
    private initializeItems() {
        this.items = this.props.explicitIds.map(e => this.toSelectBoxItem(e));
    }

    @State.bound
    private toSelectBoxItem(ids: PractitionerId[]) {
        return {
            text: this.idsToItemText(ids),
            value: ids
        } as ISelectBoxItem<PractitionerId[]>;
    }

    @State.bound
    private idsToItemText(ids: PractitionerId[]) {
        const personNames: string[] = [];

        ids.forEach(p => {
            const practitioner = this.props._dependencies.organizationReferenceDataStore.practitionerMap.get(p);
            const localizedName = this.props._dependencies.localizationService.localizePersonName(practitioner?.baseData?.name);
            personNames.push(localizedName);
        });

        return personNames.sort().join(" - ");
    }

    @State.bound
    private selectFirstItem() {
        if (this.items.length > 1) {
            this.props.onChange(this.items[0].value);
        }
    }

    public render() {
        return (
            <Ui.SelectBox
                {...this.props}
                loading={!this.items}
                items={this.items}
                autoSelectSingleItem
            />
        );
    }
}

export default connect(
    BookSlotDialogPractitionerSelectBox,
    new DependencyAdapter<IBookSlotDialogPractitionerSelectBoxProps, IBookSlotDialogPractitionerSelectBoxDependencies>(c => {
        return {
            organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
            localizationService: c.resolve("ILocalizationService")
        };
    })
);
