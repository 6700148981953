import React from "react";
import Styles from "./NavBar.less";
import { Icon } from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import CompositeClassName, { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import PermissionCheckReactContext from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckReactContext";
import IPermissionCheckContextModel from "@Toolkit/ReactClient/Components/PermissionCheckContext/IPermissionCheckContextModel";
import PermissionCheckContext from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContext";

interface INavBarItemProps {
    onClick?: () => void;
    onClickAsync?: () => Promise<void>;
    content?: React.ReactNode;
    dropLeft?: boolean;
    passive?: boolean;
    noPadding?: boolean;
    automationId?: string;
    hideChevron?: boolean;
    permissionCheckOperationNames?: string;
    permissionDeniedStyle?: "invisible" | "disabled";

    isNotPermitted?: boolean;
    hasNestedItems?: boolean;
    isDrowdownVisible?: boolean;

    style?: React.CSSProperties;
    dropdownContainerClassName?: string;
    isHovered?: boolean;
    dropdownToggleStyle?: React.CSSProperties;

    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

@State.observer
export default class NavBarItem extends React.Component<INavBarItemProps> {

    @State.observable.ref private isLoading = false;

    private dropdownContent: HTMLDivElement;
    private thisItem: HTMLDivElement;

    public componentDidUpdate(prevProps: INavBarItemProps) {
        if (this.props.isHovered !== prevProps.isHovered && this.props.isHovered === true) {
            this.thisItem?.scrollIntoView({
                block: "center"
            });
        }
    }

    @State.action.bound
    private onClickHandler(event: React.MouseEvent<HTMLElement>): void {
        event.stopPropagation();
        if (!this.isLoading) {
            if (this.props.onClickAsync) {
                this.setLoadingState();

                this.props.onClickAsync().then(() => {
                    this.setLoadedState();
                }).catch(() => {
                    this.setLoadedState();
                });

            } else if (this.props.onClick) {
                this.props.onClick();
            }
        }
    }

    @State.action
    private setLoadingState() {
        this.isLoading = true;
    }

    @State.action
    private setLoadedState() {
        this.isLoading = false;
    }

    private renderCore(checkedPermissions: IPermissionCheckContextModel[]) {
        const notAuthorized = this.props.isNotPermitted ||
            (this.props.permissionCheckOperationNames &&
                checkedPermissions.some(permission => !permission.isAuthorized &&
                    this.props.permissionCheckOperationNames.includes(permission.operationName)));

        if (this.props.hasNestedItems) {
            const dropDownToggleClassNames = new CompositeClassName(Styles.dropdownToggle);
            dropDownToggleClassNames.addIf(this.props.noPadding, Styles.noPadding);
            return (
                <div
                    className={combineClasses(Styles.dropdown, this.props.isDrowdownVisible === undefined && Styles.dropdownAutoHover)}
                    ref={(me) => this.thisItem = me}
                    onClick={this.onClickHandler}
                    data-automation-id={this.props.automationId || undefined}
                    onMouseEnter={this.props.onMouseEnter}
                    onMouseLeave={this.props.onMouseLeave}
                >
                    <div className={dropDownToggleClassNames.classNames} style={this.props.dropdownToggleStyle}>{this.props.content}{!this.props.hideChevron && <>&nbsp;<Icon iconName="chevronDown" visualStyle="white" /></>}</div>
                    <div className={combineClasses(Styles.dropdownContent, this.props.isDrowdownVisible && Styles.visible, this.props.dropdownContainerClassName)} ref={(me) => this.dropdownContent = me}>{this.props.children}</div>
                </div>
            );
        }

        const classes = [Styles.item];

        if (this.props.passive) {
            classes.push(Styles.passiveItem);
        }

        if (this.props.noPadding) {
            classes.push(Styles.noPadding);
        }

        if (notAuthorized) {
            classes.push(this.props.permissionDeniedStyle === "disabled" ? Styles.disabled : Styles.invisible);
        }

        if (this.props.isHovered) {
            classes.push(Styles.hover);
        }

        return (
            <div ref={(me) => this.thisItem = me} className={classes.join(" ")} onClick={this.onClickHandler} data-automation-id={this.props.automationId || undefined} style={this.props.style}>
                {this.props.children}
            </div>
        );
    }

    public render() {
        return (
            <PermissionCheckReactContext.Consumer>
                {
                    (permissionCheckedContext: PermissionCheckContext) =>
                        <State.Observer>
                            {() => this.renderCore(permissionCheckedContext.checkedPermissions)}
                        </State.Observer>
                }
            </PermissionCheckReactContext.Consumer>
        );
    }
}
