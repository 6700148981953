import React from "react";
import * as Ui from "@CommonControls";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";

interface IUserDetailsViewProps {
    user: User;
    practitioner: PractitionerStore;
    onAddUserToPractitioner: (addUserToPractitioner: boolean) => void;
    addUserToPractitioner: boolean;
}

const renderTitle = (props: IUserDetailsViewProps) => {
    if (!props.user || props.addUserToPractitioner) {
        return (
            <Ui.Flex>
                <Ui.Flex.Item>
                    {StaticUserManagementResources.UserPanel.Title.UserData}
                </Ui.Flex.Item>
                <Ui.Flex.Item>
                    <Ui.CheckBox align="left"
                        style={{ paddingTop: 0, paddingLeft: 8 }}
                        label={StaticUserManagementResources.UserPanel.Label.AddUser}
                        value={props.addUserToPractitioner}
                        onChange={props.onAddUserToPractitioner}
                        displayMode="switch"
                        verticalAlign="normal"
                        automationId="addUserToPractitionerCheckBox"
                    /></Ui.Flex.Item>
            </Ui.Flex>
        );
    }

    return <></>;
};

/** @screen */

const UserDetailsView: React.FC<IUserDetailsViewProps> = props => {
    if (!props.user && !props.practitioner) {
        return (<></>);
    }

    return (
        <ValidationBoundary
            entityTypeName="User"
            validateOnMount> 
            <Ui.GroupBox title={renderTitle(props)}>
                <Ui.Flex>
                    {!props.practitioner &&
                        <Ui.Flex.Item xs={4}>
                            <Ui.TextBox
                                label={StaticUserManagementResources.UserPanel.Label.DisplayName}
                                value={props.user.displayName}
                                onChange={props.user.setDisplayName}
                                propertyIdentifier={"DisplayName"}
                                automationId="displayName"
                            />
                        </Ui.Flex.Item>
                    }
                    {props.user &&
                        <Ui.Flex.Item xs={4}>
                            <Ui.TextBox
                                label={StaticUserManagementResources.UserPanel.Label.LoginName}
                                value={props.user.loginName}
                                onChange={props.user.setLoginName}
                                propertyIdentifier={"LoginName"}
                                isReadOnly={props.user && !props.user.isNew}
                                automationId="loginName"
                            />
                        </Ui.Flex.Item>}
                    {props.user && !props.user.isNew &&
                        <Ui.Flex.Item xs={4}>
                            <Ui.CheckBox align="left"
                                label={StaticUserManagementResources.UserPanel.Label.PasswordChange}
                                value={props.user.isNewPassword}
                                onChange={props.user.setIsNewPassword}
                                displayMode="switch"
                                verticalAlign="inlineInput"
                                automationId="isNewPasswordCheckBox"
                            />
                        </Ui.Flex.Item>
                    }
                </Ui.Flex>
                <Ui.Flex>
                    {props.user && props.user.isPasswordEnabled &&
                        <>
                            <Ui.Flex.Item xs={4}>
                                <Ui.TextBox
                                    isPassword
                                    label={props.user.isNew ? StaticUserManagementResources.UserPanel.Label.Password : StaticUserManagementResources.UserPanel.Label.NewPassword}
                                    value={props.user.password}
                                    onChange={props.user.setPassword}
                                    propertyIdentifier={"HisLoginCredential.Password"}
                                    showMultipleValidationProblems
                                    automationId="password"
                                />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <Ui.TextBox
                                    isPassword
                                    label={props.user.isNew ? StaticUserManagementResources.UserPanel.Label.PasswordAgain : StaticUserManagementResources.UserPanel.Label.NewPasswordAgain}
                                    value={props.user.passwordAgain}
                                    onChange={props.user.setPasswordAgain}
                                    propertyIdentifier={"HisLoginCredential.PasswordAgain"}
                                    showMultipleValidationProblems
                                    automationId="passwordAgain"
                                />
                            </Ui.Flex.Item>
                        </>
                    }
                </Ui.Flex>
                {props.user &&
                    <>
                        <Ui.Flex>
                            <Ui.CheckBox align="left"
                                label={StaticUserManagementResources.UserPanel.Label.IsDisabled}
                                value={props.user.isDisabled}
                                onChange={props.user.setIsDisabled}
                                displayMode="switch"
                                automationId="isDisabled"
                            />
                        </Ui.Flex>
                        <Ui.Flex>
                            <Ui.CheckBox align="left"
                                label={StaticUserManagementResources.UserPanel.Label.IsPasswordNotExpiring}
                                value={props.user.isPasswordNotExpiring}
                                onChange={props.user.setIsPasswordNotExpiring}
                                displayMode="switch"
                                automationId="isPasswordNotExpiring"
                            />
                        </Ui.Flex>
                    </>
                }
            </Ui.GroupBox>
        </ValidationBoundary>
    );
};

export default State.observer(UserDetailsView);
