export default class PermissionScopeType {
    public static readonly pointOfCare = "PointOfCare";
    public static readonly worklistType = "WorklistType";
    public static readonly worklistSubtype = "WorklistSubtype";
    public static readonly entityType = "EntityType";
    public static readonly entityLocation = "EntityLocation";
    public static readonly documentType = "DocumentType";
    public static readonly reportType = "ReportType";
    public static readonly documentAction = "DocumentAction";
    public static readonly feature = "Feature";
    public static readonly level = "Level";
}