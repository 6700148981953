import DependencyContainer from "@DiContainer";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import IHunEHealthInfrastructureCareResources from "./IHunEHealthInfrastructureCareResources";
import {createStaticResourceDictionary} from "@Toolkit/ReactClient/Common/LocalizedStrings";

const resources = createStaticResourceDictionary<IHunEHealthInfrastructureCareResources>();

export function loadStaticHunEHealthInfrastructureCareResources() {
    const localizationService = DependencyContainer.get<ILocalizationService>("ILocalizationService");
    resources.load(localizationService.getResourceGroup("WebApp").Care);
}

export default resources as IHunEHealthInfrastructureCareResources;
