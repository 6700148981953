import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import ICardioFrame from "@ICardioIntegrationPlugin/Components/ICardioFrame/ICardioFrame";
import PatientId from "@Primitives/PatientId.g";
import PatientContextAdapter from "@HisPlatform/Model/DomainModel/PatientContext/PatientContextAdapter";
import CareActivityId from "@Primitives/CareActivityId.g";
import CareActivityContextAdapter from "@HisPlatform/Model/DomainModel/CareActivityContext/CareActivityContextAdapter";
import DiagnosisListApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/DiagnosisList/DiagnosisListApiAdapter";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";

interface IICardioStandaloneHostDependencies {
    userContext: UserContext;
    organizationReferenceDataStore: OrganizationReferenceDataStore;
    diagnosisListApiAdapter: DiagnosisListApiAdapter;
    careReferenceDataStore: CareReferenceDataStore;
}

interface IICardioStandaloneHostProps extends IRoutingFrameContentProps {
    _dependencies?: IICardioStandaloneHostDependencies;
    _careActivityId?: CareActivityId;
    _patientId?: PatientId;
}

@State.observer
class ICardioStandaloneHost extends React.Component<IICardioStandaloneHostProps> {

    private get organizationReferenceDataStore() { return this.props._dependencies.organizationReferenceDataStore; }
    private get userContext() { return this.props._dependencies.userContext; }

    @State.observable.ref private isLoaded = false;
    @State.observable.ref private stampCode: string = null;
    @State.observable.ref private diagnosisCodes: string[] = [];

    @State.computed private get userName() {
        return "imi_" + this.userContext.loginName;
    }

    @State.computed private get name() {
        return "imi_" + this.userContext.displayName;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    private async loadAsync() {
        await State.when(() => !!this.props._patientId);
        if (this.userContext.practitionerId) {
            const result = await this.organizationReferenceDataStore.doctorMap.getOrLoadAsync(this.userContext.practitionerId);
            this.setStampCode(result.code);
        } else {
            this.setStampCode(null);
        }

        this.setDiagnosisCodes(await this.getDiagnosisCodesAsync());
    }

    @State.bound
    private async getDiagnosisCodesAsync(): Promise<string[]> {
        if (!this.props._careActivityId) {
            return [];
        }

        const diagnosisList = await this.props._dependencies.diagnosisListApiAdapter.getDiagnosisListAsync(this.props._careActivityId, false);
        const conditionVersionSelectors = diagnosisList.diagnoses.map(d => d.conditionVersionSelector);
        await this.props._dependencies.careReferenceDataStore.condition.ensureLoadedAsync(conditionVersionSelectors);

        return conditionVersionSelectors
            .map(s => this.props._dependencies.careReferenceDataStore.condition.get(s)?.code)
            .filter(Boolean);
    }

    @State.action
    private setStampCode(stampCode: string) {
        this.stampCode = stampCode;
        this.isLoaded = true;
    }

    @State.action
    private setDiagnosisCodes(diagnosisCodes: string[]): void {
        this.diagnosisCodes = diagnosisCodes;
    }

    public render() {
        return this.isLoaded && (
            <ICardioFrame
                hisCareActivityId={this.props._careActivityId}
                hisPatientId={this.props._patientId}
                diagnosisCodes={this.diagnosisCodes}
                mode="patient"
                username={this.userName}
                name={this.name}
                stampcode={this.stampCode}
            />
        );
    }
}

export default connect(
    ICardioStandaloneHost,
    new DependencyAdapter<IICardioStandaloneHostProps, IICardioStandaloneHostDependencies>(c => ({
        userContext: c.resolve("UserContext"),
        organizationReferenceDataStore: c.resolve("OrganizationReferenceDataStore"),
        diagnosisListApiAdapter: c.resolve("DiagnosisListApiAdapter"),
        careReferenceDataStore: c.resolve("CareReferenceDataStore"),
    })),
    new CareActivityContextAdapter<IICardioStandaloneHostProps>(c => ({
        _careActivityId: c.careActivityId
    })),
    new PatientContextAdapter<IICardioStandaloneHostProps>(c => ({
        _patientId: c.patientId
    }))
);