import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IDataGridColumnProps, { IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import _ from "@HisPlatform/Common/Lodash";
import { getFilterValue, getFilterMutator } from "@CommonControls/DataGrid/Filter/FilterHelpers";
import Styles from "@CommonControls/DataGrid/DataGrid.less";
import * as Ui from "@CommonControls";
import IToolkitLocalizationService from "@Toolkit/ReactClient/Services/Definition/LocalizationService/IToolkitLocalizationService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import moment from "moment";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import { nullFunction } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticToolkitResources from "@Toolkit/ReactClient/Services/Definition/LocalizationService/StaticToolkitResources";

interface IDataGridFilterCellDependencies {
    localizationService: IToolkitLocalizationService;
}

interface IDataGridFilterCellProps {
    _dependencies?: IDataGridFilterCellDependencies;

    column: IDataGridColumnProps;
    filterStore: any;
    onFilterChanged: () => void;
    isLastColumn: boolean;

    hasRefreshButton?: boolean;
    onRefreshAsnyc?: () => Promise<void>;

    hasExtraFilterButton?: boolean;
    onExtraFilterVisibilityChanged?: (newValue: boolean) => void;
    isExtraFilterVisible?: boolean;

    hasFilterClearButton?: boolean;
    onClearFilter?: () => void;

    hasPrintButton?: boolean;
    onPrintAsync?: () => Promise<void>;
}

@State.observer
class DataGridFilterCell extends React.Component<IDataGridFilterCellProps> {

    private get localizationService() {
        return this.props._dependencies.localizationService;
    }

    @State.computed private get value() {
        return getFilterValue(this.props.filterStore, this.props.column);
    }

    @State.computed private get onChange(): (newValue: any) => void {
        return getFilterMutator(this.props.filterStore, this.props.column);
    }

    @State.computed private get filterProps(): IDataGridColumnFilterProps {
        return {
            automationId: `__filter_${this.props.column.automationId ? this.props.column.automationId : this.props.column.id}`,

            value: this.value,
            onChange: (newValue: any) => {
                if (this.onChange) {
                    this.onChange.apply(this.props.filterStore, [newValue]);
                }
            }
        };
    }

    private get buttonsCount() {
        let counter = 0;
        if (this.props.hasRefreshButton) { counter++; }
        if (this.props.hasExtraFilterButton) { counter++; }
        if (this.props.hasFilterClearButton) { counter++; }
        if (this.props.hasPrintButton) { counter++; }
        return counter;
    }

    public render() {
        return (
            <th className={this.props.column.leftPadding === "small" ? Styles.leftPaddingSmall : undefined}>
                <Ui.Flex spacing="none" outerSpacing="none" innerSpacing="none" verticalSpacing="none" noWrap>
                    {!this.props.isLastColumn && (
                        <Ui.Flex.Item style={{ width: "100%" }}>
                            {this.props.column.isFilterable && this.props.column.onRenderFilter(this.filterProps)}
                        </Ui.Flex.Item>
                    )}
                    {this.props.isLastColumn && (
                        <Ui.Flex.Item>
                            <Ui.Flex spacing="none" outerSpacing="none" innerSpacing="none" verticalSpacing="none" noWrap>
                                {this.props.column.isFilterable && this.props.column.onRenderFilter(this.filterProps)}
                                {this.props.hasRefreshButton && (
                                    <Ui.Flex.Item>
                                        <Ui.Button
                                            iconName="sync"
                                            onClickAsync={this.props.onRefreshAsnyc}
                                            tooltipContent={StaticToolkitResources.dataGrid.moreButtons.refresh}
                                            tooltipType="native"
                                            className={Styles.topRightButton}
                                            automationId="dataGridRefreshButton"
                                        />
                                    </Ui.Flex.Item>
                                )}
                                {this.props.hasExtraFilterButton && (
                                    <Ui.Flex.Item>
                                        <Ui.CheckBox
                                            verticalAlign="noPadding"
                                            align="center"
                                            toggleButtonProps={{ iconName: "filter", className: Styles.topRightButton, size: "standard", automationId: "filterToggleButton" }}
                                            displayMode="toggle-button"
                                            value={this.props.isExtraFilterVisible}
                                            onChange={this.props.onExtraFilterVisibilityChanged}
                                            automationId="isExtraFilterVisibleCheckBox"
                                        />
                                    </Ui.Flex.Item>
                                )}
                                {this.buttonsCount > 3 && <Ui.ContextMenu.Provider id="datagrid_buttons" event="onClick">
                                    <Ui.Flex.Item>
                                        <Ui.Button
                                            iconName="more"
                                            onClick={nullFunction}
                                            tooltipContent={""}
                                            tooltipPosition="bottom"
                                            disabled={false}
                                            className={Styles.topRightButton}
                                            automationId="dataGridMoreButton"
                                        />
                                    </Ui.Flex.Item>
                                </Ui.ContextMenu.Provider>}
                                {<Ui.ContextMenu id="datagrid_buttons" key="datagrid_buttons">
                                    {this.props.hasRefreshButton && <Ui.ContextMenu.Item onClickAsync={this.props.onRefreshAsnyc}>
                                        <SpanWithIcon iconName="sync" automationId={`${this.props.column.automationId}_sync`}>{StaticToolkitResources.dataGrid.moreButtons.refresh}</SpanWithIcon>
                                    </Ui.ContextMenu.Item>}
                                    {this.props.hasExtraFilterButton && <Ui.ContextMenu.ToggleItem value={this.props.isExtraFilterVisible} onChange={this.props.onExtraFilterVisibilityChanged}>
                                        <SpanWithIcon iconName="filter" automationId={`${this.props.column.automationId}_filter`}>{StaticToolkitResources.dataGrid.moreButtons.extraFiltersVisible}</SpanWithIcon>
                                    </Ui.ContextMenu.ToggleItem>}
                                    {this.props.hasFilterClearButton && <Ui.ContextMenu.Item onClick={this.props.onClearFilter}>
                                        <SpanWithIcon iconName="clear_filter" automationId={`${this.props.column.automationId}_clear_filter`}>{StaticToolkitResources.dataGrid.moreButtons.clearFilters}</SpanWithIcon>
                                    </Ui.ContextMenu.Item>}
                                    {this.props.hasPrintButton && <Ui.ContextMenu.Item onClick={this.props.onPrintAsync}>
                                        <SpanWithIcon iconName="print" automationId={`${this.props.column.automationId}_print`}>{StaticToolkitResources.dataGrid.moreButtons.print}</SpanWithIcon>
                                    </Ui.ContextMenu.Item>}
                                </Ui.ContextMenu>}
                                {this.buttonsCount <= 3 && this.props.hasFilterClearButton && (
                                    <Ui.Flex.Item>
                                        <Ui.Button className={Styles.rightmostToprightButton} iconName="clear_filter" onClick={this.props.onClearFilter} disabled={!this.props.filterStore?.__isAnyFilled()} automationId="dataGridClearFilterButton" />
                                    </Ui.Flex.Item>
                                )}
                                {this.buttonsCount <= 3 && this.props.hasPrintButton && (
                                    <Ui.Flex.Item>
                                        <Ui.Button
                                            className={Styles.rightmostToprightButton}
                                            iconName="print"
                                            onClickAsync={this.props.onPrintAsync}
                                            tooltipContent={StaticToolkitResources.dataGrid.moreButtons.print}
                                            tooltipType="native"
                                            automationId="dataGridPrintButton" />
                                    </Ui.Flex.Item>
                                )}
                            </Ui.Flex>
                        </Ui.Flex.Item>
                    )}
                </Ui.Flex>
            </th>
        );
    }
}

export default connect(
    DataGridFilterCell,
    new DependencyAdapter<IDataGridFilterCellProps, IDataGridFilterCellDependencies>(c => ({
        localizationService: c.resolve("IToolkitLocalizationService")
    }))
);