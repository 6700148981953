/* auto-inject-disable */
import Di from "@Di";
import IFrontendListDataWithDefinition from "@HisPlatform/BoundedContexts/Productivity/Components/NDataCommon/IFrontendListDataWithDefinition";
import * as Proxy from "@AssecoMedPlugin/BoundedContexts/WebAppBackend/Api/Proxy.g";
import { FrontendListDto } from "@HisPlatform/BoundedContexts/WebAppBackend/Api/Proxy.g";
import ApiAdapterBase2 from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/ApiAdapters/ApiAdapterBase2";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import FrontendListParameters from "@HisPlatform/Model/FrontendListParameters";
import IMapperService from "@HisPlatform/Services/Definition/MapperService/IMapperService";
import IReferenceDataLoader from "@HisPlatform/Services/Definition/ReferenceDataLoader/IReferenceDataLoader";
import { GetTypeNameWithPrefix } from "@AssecoMedPlugin/PluginHelper";
import CareActivityId from "@Primitives/CareActivityId.g";

@Di.injectable()
export default class TelemetrySessionListScreenApiAdapter extends ApiAdapterBase2 {

    constructor(
        @Di.inject(GetTypeNameWithPrefix("ICareClient")) private readonly apiClient: Proxy.ICareClient,
        @Di.inject("IMapperService") private readonly mapperService: IMapperService,
        @Di.inject("IReferenceDataLoader") referenceDataLoader: IReferenceDataLoader) {
        super(referenceDataLoader);
    }

    public getTelemetrySessionListAsync(frontendListParameters: FrontendListParameters) {
        return this.executeOperationAsync<IFrontendListDataWithDefinition, Proxy.GetTelemetrySessionListQueryResponse>(
            () => this.apiClient.getTelemetrySessionListQueryAsync(CreateRequestId(),
                new Proxy.GetTelemetrySessionListControllerDto({
                    filters: this.mapperService.mapByName("FilterDtoArray", frontendListParameters),
                    pagingAndOrderings: this.mapperService.mapByName("QueryPagingsAndOrderings", frontendListParameters),
                    requestMode: frontendListParameters.requestMode
                })),
            response => {
                return this.mapperService.map<FrontendListDto, IFrontendListDataWithDefinition>(response.frontendList);
            }
        );
    }

    public refreshTelemetrySession(careActivityId: CareActivityId) {
        return this.executeOperationAsync<boolean, Proxy.RefreshTelemetrySession2CommandResponse>(
            () => this.apiClient.refreshTelemetrySession2CommandAsync(CreateRequestId(),
                new Proxy.RefreshTelemetrySession2ControllerDto({
                    careActivityId: careActivityId
                })),
            response => true    // TODO: is there a way to return nothing?
        );
    }
}