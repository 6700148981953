import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import IWidgetComponentProps from "@PluginInterface/Dashboard/IWidgetComponentProps";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import BMICalculator from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/BMICalculator/BMICalculator";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import GeneralCalculator from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/GeneralCalculator/GeneralCalculator";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import { withLoadingBoundary } from "@Toolkit/ReactClient/Components/LoadingBoundary/LoadingBoundary";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";

type calculatorType = "bmi" | "general";

@State.observer
class CalculatorWidget extends React.Component<IWidgetComponentProps> {

    private calculatorOptions: Array<ISelectBoxItem<calculatorType>> = [
        { text: StaticWebAppResources.CalculatorSelector.Name.BMICalculator, value: "bmi" } as ISelectBoxItem<calculatorType>,
        { text: StaticWebAppResources.CalculatorSelector.Name.GeneralCalculator, value: "general" } as ISelectBoxItem<calculatorType>,
    ];

    @State.observable.ref private selectedCalculator: calculatorType = "bmi";

    private resources = StaticProductivityResources.Widgets.BMICalculator;

    @State.action.bound
    private setSelectedCalculator(selectedCalculator: calculatorType) {
        this.selectedCalculator = selectedCalculator;
    }

    private renderCalculator() {
        switch (this.selectedCalculator) {
            case "bmi":
                return (
                    <BMICalculator />
                );
            case "general":
                return (
                    <GeneralCalculator />
                );
            default:
                throw new Error("Unknown calculator: " + this.selectedCalculator);
        }
    }

    public render() {
        return (
            <Ui.Dashboard.Widget
                name={this.props.name}
                icon={"calculator"}
                isCloseByDefault={this.props.configuration && this.props.configuration.isCloseByDefault}
                title={this.resources.Title}
                isCollapsible
                automationId="CalculatorWidget"
            >
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <Ui.SelectBox
                                items={this.calculatorOptions}
                                value={this.selectedCalculator}
                                size="standard"
                                onChange={this.setSelectedCalculator}
                                visualMode="dark"
                                clearable={false}
                                automationId="selectedCalculatorSelectBox"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            {this.renderCalculator()}
                        </Ui.Flex.Item>
                    </Ui.Flex>
            </Ui.Dashboard.Widget>
        );
    }

}
export default connect(withLoadingBoundary(CalculatorWidget));