import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import PatientId from "@Primitives/PatientId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import EhiStructuredDocumentContactTypeId from "@Primitives/EhiStructuredDocumentContactTypeId.g";

export default class EhiStructuredDocumentContactStore extends EntityStoreBase<PatientId> {

    @State.observable.ref public contactTypeId: EhiStructuredDocumentContactTypeId = null;
    @State.observable public value: string = "";

    constructor(contactTypeId: EhiStructuredDocumentContactTypeId, value: string) {
        super();
        this.contactTypeId = contactTypeId;
        this.value = value;
    }

    public get isPhone() {
        return this.contactTypeId && (this.contactTypeId.value === EhiStructuredDocumentContactTypeId.Phone.value || this.contactTypeId.value === EhiStructuredDocumentContactTypeId.Mobile.value);
    }

    public get isEmail() {
        return this.contactTypeId && this.contactTypeId.value === EhiStructuredDocumentContactTypeId.Email.value;
    }

    public get isEmpty() {
        return !this.contactTypeId && !this.value;
    }

    @State.action.bound
    public setContactTypeId(contactTypeId: EhiStructuredDocumentContactTypeId) {
        this.contactTypeId = contactTypeId;
    }

    @State.action.bound
    public setValue(newValue: string) {
        this.value = newValue;
    }
}