import React from "react";
import * as Ui from "@CommonControls";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import { iconNameType } from "@CommonControls/Icon";
import Orientation from "@HisPlatform/BoundedContexts/DocumentManagement/Api/PdfTemplating/Enum/Orientation.g";

interface IPrintOrientationSelectBoxProps extends ISelectBoxBaseProps {
    value?: Orientation;
    onChange?: (newValue: Orientation) => void;
}


const PrintOrientationSelectBox: React.FC<IPrintOrientationSelectBoxProps> = props => {
    const getIconName = (value: Orientation): iconNameType => {
        switch (value) {
            case Orientation.Landscape:
                return "landscape_solid";
            case Orientation.Portrait:
                return "portrait_solid";            
        }
    };
    return (
        <Ui.UniversalEnumSelector
            {...props}
            enumName={"Orientation"}
            enumOrigin="server"
            enumType={Orientation}
            displayMode="groupedRadioButtons"
            getIconName={getIconName}
        />);
};

export default PrintOrientationSelectBox;