import React, { useMemo } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { SelectBox } from "@CommonControls";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import { useFormLayoutEditorStore } from "@HisPlatform/BoundedContexts/FormEngine/Components/Panels/FormLayoutEditor/FormLayoutEditorStoreProvider";
import StaticFormEditingResources from "@HisPlatform/BoundedContexts/FormEngine/StaticResources/StaticFormEngineResources";
import ReferencedEntityFormDataElement from "@Toolkit/FormEngine/Model/Schema/ReferencedEntityFormDataElement";
import * as Ui from "@CommonControls";

function EntityReferenceEditor() {

    const store = useFormLayoutEditorStore();

    const options = useMemo(() => {
        return store.formEditorRegistry.getEntityReferenceEditors().map(i => ({
            text: i.displayName,
            value: i.entityName
        } as ISelectBoxItem<string>));
    }, []);

    return (
        <div>
            <SelectBox
                automationId="EntityReference_SelectBox"
                items={options}
                value={(store.selectedDataElement as ReferencedEntityFormDataElement)?.referencedEntity}
                onChange={store.setEntityReference}
                clearable
                valueOnClear={null}
                label={StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.EntityReferenceLabel}
            />
            <Ui.Button
                automationId="EntityReference_Filters_Button"
                text={StaticFormEditingResources.FormDefinitionEditor.PropertyPanel.Editor.FiltersButton}
                onClick={store.openFiltersModalAsync}
            />
        </div>
    );
}

export default State.observer(EntityReferenceEditor);