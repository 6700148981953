import React from "react";
import IPatientsCareActivityListButtonsExtensionPointProps from "@PluginInterface/BoundedContexts/Care/CareRegister/ExtensionPoints/IPatientsCareActivityListButtonsExtensionPointProps";
import EhiCareActivityListButtons from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiCareActivityListButtons/EhiCareActivityListButtons";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IEhiPatientsCareActivitiesListExtensionData from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiPatientsCareActivitiesListFilter/IEhiPatientsCareActivitiesListExtensionData";
import EhiPatientsCareActivitiesDataSource from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/Components/Panels/EhiPatientCareActivitiesDataSource/EhiPatientCareActivitiesDataSource";

@State.observer
export class PatientsCareActivityListButtonsExtension extends React.Component<IPatientsCareActivityListButtonsExtensionPointProps> {

    public render() {
        const extData: IEhiPatientsCareActivitiesListExtensionData = this.props.patientsCareActivityListExtensionData;
        return (
            <EhiCareActivityListButtons
                row={this.props.row}
                patientId={this.props.patientId}
                isEmergency={extData?.EmergencyAccess.IsEmergency ?? false}
                emergencyReason={extData?.EmergencyAccess.EmergencyReason ?? null}
                ehiCareService={(this.props.dataSource as EhiPatientsCareActivitiesDataSource)?.ehiCareService}
            />
        );
    }
}
