import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import HunEhiCareReferenceDataStore from "@HunEHealthInfrastructurePlugin/BoundedContexts/Care/ApplicationLogic/CareRegister/HunEhiCareReferenceDataStore";
import EhiCovidVentilationStatusId from "@Primitives/EhiCovidVentilationStatusId.g";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IDataGridColumnBaseProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";

interface IEhiCovidVentilationStatusColumnDependencies {
    hunEhiCareReferenceDataStore: HunEhiCareReferenceDataStore;
}

interface IEhiCovidVentilationStatusColumnProps extends IDataGridColumnBaseProps {
    _dependencies?: IEhiCovidVentilationStatusColumnDependencies;
}


const EhiCovidVentilationStatusColumn: React.FC<IEhiCovidVentilationStatusColumnProps> = (props: IEhiCovidVentilationStatusColumnProps) => {

    const valueRenderer = (value: EhiCovidVentilationStatusId) => {
        if (value) {
            const ehiCovidVentilationStatus = props._dependencies.hunEhiCareReferenceDataStore.ehiCovidVentilationStatuses.get(value);
            return ehiCovidVentilationStatus?.displayValue?.Name ?? "";
        }
        return "";
    };

    return (
        <DataGridColumn
            onRenderCellValue={valueRenderer}
            {...props}
        />
    );
};

export default connect(
    EhiCovidVentilationStatusColumn,
    new DependencyAdapter<IEhiCovidVentilationStatusColumnProps, IEhiCovidVentilationStatusColumnDependencies>(c => ({
        hunEhiCareReferenceDataStore: c.resolve("HunEhiCareReferenceDataStore")
    }))
);