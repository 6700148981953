import React from "react";
import * as Ui from "@CommonControls";
import ActionBoundSaveButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundSaveButton";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";
import IActionPresentation from "@Toolkit/ReactClient/ActionProcessing/IActionPresentation";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";

interface IToolbarButtonsProps {
    beforeButtons?: React.ReactNode;
    betweenButtons?: React.ReactNode;
    afterButtons?: React.ReactNode;
    onCancelAsync?: () => Promise<any>;
    saveActionsDescriptor?: ActionDescriptor<IActionPresentation>;
    onExecuteSaveAsync?: (action: FrontendActionBase) => Promise<void>
    saveButtonVisible?: boolean;
    cancelButtonVisible?: boolean;
}

// tslint:disable-next-line: variable-name
function ToolbarButtons(props: IToolbarButtonsProps) {
    return (
        <Ui.Flex>
            {props.beforeButtons}
            {props.cancelButtonVisible && <Ui.Flex.Item>
                <Ui.Button text={StaticWebAppResources.Common.Button.Back} onClickAsync={props.onCancelAsync} automationId="__cancel" />
            </Ui.Flex.Item>
            }
            {props.betweenButtons}
            {props.saveButtonVisible && <Ui.Flex.Item>
                <ActionBoundSaveButton actionDescriptor={props.saveActionsDescriptor} onExecuteAsync={props.onExecuteSaveAsync} automationId="__save" />
            </Ui.Flex.Item>}
            {props.afterButtons}
        </Ui.Flex>
    );
}

ToolbarButtons.defaultProps = { cancelButtonVisible: true };

export default ToolbarButtons;