import { PatientDocumentSourceType } from "./PatientDocumentSourceType";
import Identifier from "./Identifier";

export default class IdentifierWithSourceType {

    constructor(
        public readonly identifier: Identifier,
        public readonly sourceType: PatientDocumentSourceType
    ) {        
    }
}