import { injectable, inject, optional, multiInject } from "inversify";
import IDiDecorators from "@Toolkit/CommonWeb/DependencyInjection/Definition/IDiDecorators";


const Di = {
    inject: inject,
    injectable: injectable,
    multiInject: multiInject,
    optional: optional
} as IDiDecorators;

export default Di;
