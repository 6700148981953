import PatientId from "@Primitives/PatientId.g";
import * as CareProxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import MedicationStatementStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/MedicationStatementStore";
import MedicationStatementHistoryItem from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/MedicationStatementHistoryItem";
import { getHistoryItemDetailDto } from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/MedicalCondition/MedicalConditionDtoMapper";
import LockId from "@Toolkit/CommonWeb/Model/LockId";
import MedicationStatementId from "@Primitives/MedicationStatementId.g";
import ReferencedMedicationAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/ReferencedMedicationAssignment";
import ReferencedSubstanceAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/ReferencedSubstanceAssignment";
import ReferencedInternationalNonproprietaryNameAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/ReferencedInternationalNonproprietaryNameAssignment";
import CustomSubjectAssignment from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/CustomSubjectAssignment";
import StatementSubjectDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/StatementSubjectDetail";
import TreatmentDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/TreatmentDetail";
import MedicationStatementSubjectAssignmentBase from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicationStatement/MedicationStatementSubjectAssignmentBase";

export function getAddMedicationStatementControllerDto(source: MedicationStatementStore, patientId: PatientId, isValidateOnly: boolean) {
    return new CareProxy.AddMedicationStatementControllerDto({
        patientId: patientId,
        historyItemDtos: [getMedicationStatementHistoryItemDto(source.latestHistoryItem as MedicationStatementHistoryItem)],
        medicationStatementSubjectAssignmentBaseDto:  getMedicationStatementSubjectAssignmentDto(source.medicationStatementSubjectAssignment),
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
    });
}

export function getUpdateMedicationStatementControllerDto(source: MedicationStatementStore, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.UpdateMedicationStatementControllerDto({
        medicationStatementId: source.id,
        historyItemDtos: [getMedicationStatementHistoryItemDto(source.latestHistoryItem as MedicationStatementHistoryItem)],
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteMedicationStatementHistoryItemControllerDto(source: MedicationStatementStore, versionNumber: number, isValidateOnly: boolean, lockId: LockId, releaseLockIfSuccessful: boolean) {
    return new CareProxy.DeleteMedicationStatementHistoryItemControllerDto({
        medicationStatementId: source.id,
        versionNumbers: [versionNumber],        
        isValidateOnly: isValidateOnly,
        extensionData: source.extensionData,
        lockId: lockId,
        releaseLockIfSuccessful: releaseLockIfSuccessful
    });
}

export function getDeleteMedicationStatementControllerDto(store: MedicationStatementStore) {
    return new CareProxy.DeleteMedicationStatementControllerDto({
        medicationStatementId: store.id,
        lockId: store.lockInfo?.lockId,
        extensionData: store.extensionData,
        isValidateOnly: false,
        releaseLockIfSuccessful: true
    });
}

function getMedicationStatementSubjectAssignmentDto(source: MedicationStatementSubjectAssignmentBase) {
    let dto;

    if (source instanceof ReferencedMedicationAssignment) {
        dto = new CareProxy.ReferencedMedicationAssignmentDto({medicationId: source.medicationId });
    } else if (source instanceof ReferencedSubstanceAssignment) {
        dto = new CareProxy.ReferencedSubstanceAssignmentDto({substanceId: source.substanceId });
    } else if (source instanceof ReferencedInternationalNonproprietaryNameAssignment) {
        dto = new CareProxy.ReferencedInternationalNonproprietaryNameAssignmentDto({internationalNonproprietaryNameId: source.internationalNonproprietaryNameId });
    } else if (source instanceof CustomSubjectAssignment) {
        dto = new CareProxy.CustomSubjectAssignmentDto({ name: source.name });
    }

    return dto;
}

function getMedicationStatementHistoryItemDto(source: MedicationStatementHistoryItem) {
    return new CareProxy.MedicationStatementHistoryItemDto({
        versionNumber: source.versionNumber,
        index: source.index,
        createdAt: source.createdAt,
        historyItemDetail: getHistoryItemDetailDto(source.historyItemDetail),        
        statementSubjectDetail: getStatementSubjectDetailDto(source.statementSubjectDetail),
        treatmentDetail: getTreatmentDetailDto(source.treatmentDetail),
        extensionData: source.extensionData
    });
}

function getStatementSubjectDetailDto(source: StatementSubjectDetail) {
    return new CareProxy.StatementSubjectDetailDto({
        dosageAmount: source.dosageAmount,        
        dosageForm: source.dosageFormId,
        efficacy: source.efficacy,
        intakeFrequency: source.intakeFrequency
    });
}

function getTreatmentDetailDto(source: TreatmentDetail) {
    return new CareProxy.TreatmentDetailDto({
        length: source.length,
        startDate: source.startDate
    });
}
