exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PatientMainPage_root_-tJs2 {\n  height: calc(100% - 5px);\n}\n.PatientMainPage_root-new_10Azx {\n  height: 100%;\n}\n.PatientMainPage_menu-button_MLAg7 {\n  text-align: center;\n}\n.PatientMainPage_separator_xdl4c {\n  min-height: 2px;\n  margin: 10px auto;\n  width: 80%;\n  background-color: #ffffff6e;\n}\n", ""]);

// exports
exports.locals = {
	"root": "PatientMainPage_root_-tJs2",
	"root-new": "PatientMainPage_root-new_10Azx",
	"rootNew": "PatientMainPage_root-new_10Azx",
	"menu-button": "PatientMainPage_menu-button_MLAg7",
	"menuButton": "PatientMainPage_menu-button_MLAg7",
	"separator": "PatientMainPage_separator_xdl4c"
};