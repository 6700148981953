import React from "react";
import EntityCollectionStore, { ClientEntityCollectionType } from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/Personalization/EntityCollectionStore";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import ComplexSearchPanelView from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchPanelView";
import { ModalSize } from "@CommonControls/Modal";
import _ from "@HisPlatform/Common/Lodash";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import { wrappedValuesAreEquals } from "@HisPlatform/Common/ValueWrapperHelpers";
import ComplexSearchFilterStore from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchFilterStore";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import { DataGridLoadType, IOrderingState, IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import IDataGridColumnProps from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { iconNameType } from "@CommonControls/Icon";
import StaticWebAppResources from "@StaticResources";
import { arrayIsNullOrEmpty } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IColumnDescriptor from "./IColumnDescriptor";
import FilterBase from "@Toolkit/CommonWeb/Model/Filtering/FilterBase";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";

interface IComplexSearchPanelDependencies { }

interface IComplexSearchPanelProps<TRecord = any> {
    _dependencies?: IComplexSearchPanelDependencies;
    complexSearchLoadAsync: (filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: TRecord[], selectedGroup?: IComplexSearchItemGroup, filters?: FilterBase[]) => Promise<IPagedItems<TRecord>>;
    filters?: FilterBase[];
    itemGroups: IComplexSearchItemGroup[];
    selectedItems: IObservableArray<TRecord>;
    entityCollections: EntityCollectionStore[];
    singleSelect: (row: any) => void;

    onMultiSelectSave: () => void;

    complexSearchTitle: string;
    complexSearchOpen: boolean;
    onComplexSearchClose: () => void;
    createNewItemAsync: (code: string, name: string) => Promise<IClientValidationResult[]>;
    onValidateAllAsync: (code: string, name: string) => Promise<IClientValidationResult[]>;
    createNewItemButtonText: string;
    createNewItemCodePlaceHolder: string;
    createNewItemNamePlaceHolder: string;
    createNewItemCodePropertyIdentifier: string;
    createNewItemNamePropertyIdentifier: string;
    createNewItemValidationEntityName: string;
    showNewItem: boolean;
    selectableItemsSearchPlaceHolder: string;
    selectedItemsSearchPlaceHolder: string;

    complexSearchModalSize: ModalSize;
    showEntityCollections: boolean;
    isMultiSelect: boolean;
    codeGetter: string;
    nameGetter: string;
    getNameValueString: (value: any) => string;
    customNameRender: (value: any, row: TRecord) => React.ReactNode;
    getEntitiesByIds: (id: IStringEntityId[]) => TRecord[];
    isFavorite: (value: TRecord) => boolean;
    setFavorite: (value: TRecord) => void;

    automationId: string;
    hideAllgroup?: boolean;
    columnDescriptors?: IColumnDescriptor[];
    newItemPermissionIdentifier?: string;
}

const allItemsGroupName = "All";
const mostUsedItemsGroupName = "MostUsed";
const favoritesItemsGroupName = "Favorites";
const groupItemsGroupName = "Group";

export interface IComplexSearchItemGroup {
    name: string;
    localizedName: string;
    icon?: iconNameType;
}

@State.observer
class ComplexSearchPanel<TRecord = any> extends React.Component<IComplexSearchPanelProps<TRecord>> {

    @State.observable private selectedGroup: IComplexSearchItemGroup = this.allItemsGroup;
    @State.observable private isInitialized = false;
    @State.observable.ref private selectableItems: IPagedItems<TRecord> = { items: [], totalCount: 0 };
    private filterStore: ComplexSearchFilterStore = new ComplexSearchFilterStore(this.refreshAsync, this.setDefaultPaging);
    @State.observable private isLoading: boolean;
    @State.observable private showCreateNewItem: boolean;
    @State.observable.ref private validationResults: IClientValidationResult[] = null;

    @State.observable.ref private paging: IPagingState = {
        currentPage: 0,
        pageSize: 10
    };

    @State.observable.ref private ordering: IOrderingState = {
        columnId: "code",
        direction: "asc"
    };

    public componentDidUpdate(prevProps: Readonly<IComplexSearchPanelProps<TRecord>>) {
        if (!prevProps.complexSearchOpen && this.props.complexSearchOpen) {
            this.initializeFiltersAndPaging();
            dispatchAsyncErrors(this.refreshAsync(), this);
        }
        if (!prevProps.showNewItem && this.props.showNewItem) {
            this.setShowCreateNewItem(true);
        }
        if (prevProps.showNewItem && !this.props.showNewItem) {
            this.setShowCreateNewItem(false);
        }
        if (this.props.hideAllgroup && prevProps.itemGroups !== this.props.itemGroups) {
            dispatchAsyncErrors(this.refreshAsync(), this);
        }
    }

    @State.bound
    private async refreshAsync() {
        if (this.isLoading || this.selectedGroup.name === groupItemsGroupName || this.selectedGroup.name === favoritesItemsGroupName || this.selectedGroup.name === mostUsedItemsGroupName) {
            return;
        }
        if (this.props.hideAllgroup && this.selectedGroup.name === this.allItemsGroup.name) {
            if (!arrayIsNullOrEmpty(this.props.itemGroups)) {
                this.setSelectedGroup(this.props.itemGroups[0].name);
            }
        }
        this.setIsLoading(true);
        const items = await this.props.complexSearchLoadAsync(this.filterStore.commonFilterText, this.paging, this.ordering, this.props.selectedItems, this.selectedGroup, this.props.filters);
        this.setSelectableItems(items);

        this.setIsLoading(false);
    }

    @State.action.bound
    private setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @State.action.bound
    private async onGridChangeAsync(type: DataGridLoadType, paging: IPagingState, ordering: IOrderingState | IOrderingState[], filter: any, columns: IDataGridColumnProps[]) {
        this.paging = paging;
        await this.refreshAsync();
    }

    @State.action.bound
    private setSelectableItems(items: IPagedItems<TRecord>) {
        this.selectableItems = items;
    }

    @State.action.bound
    private setSelectedGroup(value: string) {
        this.initializeFiltersAndPaging();
        this.selectedGroup = this.relevantSearchPanelGroups.find(item => item.name === value);
    }

    private get listDataSource(): IPagedItems<TRecord> {
        let items = this.getItemsFromCollections("List");
        const filter = this.filterStore.commonFilterText?.trim().toUpperCase();
        items = this.filterAndOrderByName(items, filter);
        return { items: items, totalCount: items.length };
    }

    private get mostUsedDataSource() {
        let items = this.getItemsFromCollections("MostUsed");
        const filter = this.filterStore.commonFilterText?.trim().toUpperCase();
        items = this.filterAndOrderByName(items, filter);
        return { items: items, totalCount: items.length };
    }

    @State.bound
    private filterAndOrderByName(items: TRecord[], filter: string): TRecord[] {
        let result = items;
        if (filter) {
            if (this.props.getNameValueString) {
                result = result.filter(item => this.props.getNameValueString(_.get(item, this.props.nameGetter)).toUpperCase().includes(filter)
                    || (this.props.codeGetter && _.get(item, this.props.codeGetter).toUpperCase().includes(filter)));
            } else {
                result = result.filter(item => _.get(item, this.props.nameGetter).toUpperCase().includes(filter)
                    || (this.props.codeGetter && _.get(item, this.props.codeGetter).toUpperCase().includes(filter)));
            }
        }
        if (this.props.getNameValueString) {
            result = _.sortBy(result, item => this.props.getNameValueString(item[this.props.nameGetter]));
        } else {
            result = _.sortBy(result, [this.props.nameGetter]);
        }
        return result;
    }

    private getItemsFromCollections(type: ClientEntityCollectionType) {
        let ids = _.flatten(this.props.entityCollections?.filter(item => item.type === type)
            .map(item => item.idList));
        if (this.props.selectedItems?.length) {
            ids = ids.filter(item => !this.props.selectedItems.some(i => wrappedValuesAreEquals((i as any).id, item as { value: string })));
        }
        return this.props.getEntitiesByIds(ids);
    }

    private get groupsDataSource(): IPagedItems<EntityCollectionStore> {
        let collections = this.props.entityCollections?.filter(item => item.type === "Group");
        if (this.props.selectedItems?.length) {
            collections = collections.filter(item => !item.idList.every(id => this.props.selectedItems.some(i => wrappedValuesAreEquals((i as any).id, id as { value: string }))));
        }
        if (this.filterStore.commonFilterText) {
            collections = collections.filter(item => (item.name.toUpperCase().includes(this.filterStore.commonFilterText.trim().toUpperCase())));
        }
        collections = _.sortBy(collections, "name");
        return { items: collections, totalCount: collections.length };
    }

    private get allItemsGroup() {
        return {
            name: allItemsGroupName,
            localizedName: StaticWebAppResources.CodeSelector.ComplexSearch.All,
            icon: "sum"
        } as IComplexSearchItemGroup;
    }

    private get mostUsedItemsGroup() {
        return {
            name: mostUsedItemsGroupName,
            localizedName: StaticWebAppResources.CodeSelector.ComplexSearch.MostUsed,
            icon: "crown"
        } as IComplexSearchItemGroup;
    }

    private get favoritesItemsGroup() {
        return {
            name: favoritesItemsGroupName,
            localizedName: StaticWebAppResources.CodeSelector.ComplexSearch.Favorites,
            icon: "star"
        } as IComplexSearchItemGroup;
    }

    private get groupItemsGroup() {
        return {
            name: groupItemsGroupName,
            localizedName: StaticWebAppResources.CodeSelector.ComplexSearch.Groups,
            icon: "layer_group"
        } as IComplexSearchItemGroup;
    }

    @State.computed
    private get relevantSearchPanelGroups() {
        if (!this.props.entityCollections?.length && !this.props.showEntityCollections && !this.props.itemGroups?.length) {
            return null;
        }
        const result = [];
        if (!this.props.hideAllgroup) {
            result.push(this.allItemsGroup);
        }

        if (this.props.entityCollections.some(item => item.type === "MostUsed" && item.idList.length)) {
            result.push(this.mostUsedItemsGroup);
        }
        if (this.props.entityCollections.some(item => item.type === "List" && item.idList.length)) {
            result.push(this.favoritesItemsGroup);
        }

        if (this.props.isMultiSelect && this.props.entityCollections.some(item => item.type === "Group" && item.idList.length)) {
            result.push(this.groupItemsGroup);
        }

        if (this.props.itemGroups?.length) {
            result.push(...this.props.itemGroups);
        }

        return result;
    }

    private get dataSource() {
        switch (this.selectedGroup?.name) {
            case favoritesItemsGroupName:
                return this.listDataSource;
            case mostUsedItemsGroupName:
                return this.mostUsedDataSource;
            case allItemsGroupName:
                return this.selectableItems;
            default:
                return this.selectableItems;
        }
    }

    private get selectedItems(): IObservableArray<TRecord> {
        const filter = this.filterStore.selectedFilterText?.trim().toUpperCase();
        if (filter) {
            return State.observable(this.filterAndOrderByName(this.props.selectedItems, filter));
        }
        return this.props.selectedItems;
    }

    @State.action.bound
    private setShowCreateNewItem(value: boolean) {
        this.showCreateNewItem = value;
    }

    @State.action.bound
    private setValidationResults(value: IClientValidationResult[]) {
        this.validationResults = value;
    }

    @State.bound
    private async createNewItemAsync(code: string, name: string) {
        const validationResults = await this.props.createNewItemAsync(code, name);
        this.setValidationResults(validationResults);
        if (!validationResults.length || !validationResults.some(item => item.problems.some(problem => problem.severity === "error"))) {
            this.setShowCreateNewItem(false);
        }
    }

    @State.bound
    private async validateAsync(code: string, name: string) {
        if (!this.props.onValidateAllAsync) {
            return Promise.resolve([]);
        }
        const result = await this.props.onValidateAllAsync(code, name);
        this.setValidationResults(result);
        return result;
    }

    @State.action.bound
    private setDefaultPaging() {
        this.paging = {
            currentPage: 0,
            pageSize: 10
        };
    }

    @State.action.bound
    private initializeFiltersAndPaging() {
        this.setDefaultPaging();
        this.filterStore.__reset(false);
        this.selectedGroup = this.allItemsGroup;
    }

    public render() {
        return (
            <ComplexSearchPanelView
                dataSource={this.dataSource}
                filterStore={this.filterStore}
                selectedItems={this.selectedItems}
                singleSelect={this.props.singleSelect}
                complexSearchTitle={this.props.complexSearchTitle}
                complexSearchOpen={this.props.complexSearchOpen}
                automationId={this.props.automationId}
                codeGetter={this.props.codeGetter}
                nameGetter={this.props.nameGetter}
                getNameValueString={this.props.getNameValueString}
                customNameRender={this.props.customNameRender}
                showFavoritesColumn={this.props.showEntityCollections && this.selectedGroup?.name !== groupItemsGroupName}
                isMultiSelect={this.props.isMultiSelect}
                createNewItemAsync={this.props.createNewItemAsync && this.createNewItemAsync}
                onValidateAllAsync={this.validateAsync}
                validationResults={this.validationResults}
                createNewItemButtonText={this.props.createNewItemButtonText}
                showCreateNewItem={this.showCreateNewItem}
                setShowCreateNewItem={this.setShowCreateNewItem}
                createNewItemCodePlaceHolder={this.props.createNewItemCodePlaceHolder}
                createNewItemNamePlaceHolder={this.props.createNewItemNamePlaceHolder}
                createNewItemCodePropertyIdentifier={this.props.createNewItemCodePropertyIdentifier}
                createNewItemNamePropertyIdentifier={this.props.createNewItemNamePropertyIdentifier}
                createNewItemValidationEntityName={this.props.createNewItemValidationEntityName}
                onComplexSearchClose={this.props.onComplexSearchClose}
                isFavorite={this.props.isFavorite}
                setFavorite={this.props.setFavorite}
                relevantGroups={this.relevantSearchPanelGroups}
                selectedGroup={this.selectedGroup}
                setSelectedGroup={this.setSelectedGroup}
                groupDataSource={this.groupsDataSource}
                getEntitiesByIds={this.props.getEntitiesByIds}
                onMultiSelectSave={this.props.onMultiSelectSave}
                paging={this.paging}
                ordering={this.ordering}
                onChangeAsync={this.onGridChangeAsync}
                isLoading={this.isLoading}
                selectableItemsSearchPlaceHolder={this.props.selectableItemsSearchPlaceHolder}
                selectedItemsSearchPlaceHolder={this.props.selectedItemsSearchPlaceHolder}
                columnDescriptors={this.props.columnDescriptors}
                newItemPermissionIdentifier={this.props.newItemPermissionIdentifier}
            />
        );
    }
}

export default connect(
    ComplexSearchPanel,
    new DependencyAdapter<IComplexSearchPanelProps, IComplexSearchPanelDependencies>(c => ({} as IComplexSearchPanelDependencies))
);