import * as Proxy from "@HisPlatform/BoundedContexts/Care/Api/Proxy.g";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import Di from "@Di";
import IAgeCalculationService from "@HisPlatform/Services/Definition/AgeCalculation/IAgeCalculationService";
import { stringifyAgeQueryDto } from "./AgeQueryDtoHelper";
import _ from "@HisPlatform/Common/Lodash";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import UnknownBusinessError from "@HisPlatform/Model/DomainModel/BusinessError/UnknownBusinessError";
import StaticWebAppResources from "@StaticResources";

@Di.injectable()
export default class AgeCalculationService implements IAgeCalculationService {

    constructor(
        @Di.inject("IPatientRegisterClient") private patientRegisterClient: Proxy.IPatientRegisterClient,
        @Di.inject("ILocalizationService") private localizationService: ILocalizationService,
    ) { }

    @State.bound
    public async calculateAgeAsync(birthDate: LocalDate, deathDate: LocalDate): Promise<string> {
        let age: Proxy.AgeDisplayDto = null;
        let response: Proxy.GetPatientAgeQueryResponse = null;

        if (LocalDate.isNullOrEmpty(birthDate)) {
            return null;
        }

        try {
            response = await this.patientRegisterClient.getPatientAgeQueryAsync(CreateRequestId(), stringifyAgeQueryDto(birthDate, deathDate));
            age = response.ageResponseDto.age;
        } catch (e) {
            if (e.businessError && e.businessError instanceof UnknownBusinessError && e.businessError.name === "InvalidDateRangeError") {
                return StaticWebAppResources.PatientBaseData.Label.ErrorLabel;
            }
        }

        if (age) {
            const localizedAge = this.localizationService.localizeAge(age);
            return localizedAge;
        }

        return null;
    }
}