import React from "react";
import PatientsCareActivityStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/PatientsCareActivities/PatientsCareActivityStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import Styles from "./PatientCareActivity.less";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import CareTypeId from "@Primitives/CareTypeId.g";
import * as Ui from "@CommonControls";
import CareActivityStateIcon from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/CareActivityStateIcon/CareActivityStateIcon";
import IDateTimeFormatProvider from "@Toolkit/CommonWeb/DateTimeFormatProvider/Definition/IDateTimeFormatProvider";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticProductivityResources from "@HisPlatform/BoundedContexts/Productivity/StaticResources/StaticProductivityResources";
import Popper from "@Toolkit/ReactClient/Components/Tooltip/Popper";
import PatientCareActivityTooltip from "@HisPlatform/BoundedContexts/Dashboards/Components/Widgets/EHRWidget/PatientCareActivityTooltip";
import AccessLevel from "@Primitives/AccessLevel";
import DiagnosisRoleId from "@Primitives/DiagnosisRoleId.g";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import PermissionCheckContextProvider, { NestedPermissionCheckContextProvider } from "@Toolkit/ReactClient/Components/PermissionCheckContext/PermissionCheckContextProvider";
import CareActivityDocumentApiAdapter from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/ApiAdapter/CareRegister/CareActivityDocument/CareActivityDocumentApiAdapter";

interface IPatientCareActivityDependencies {
    dateTimeFormatProvider: IDateTimeFormatProvider;
    documentApiAdapter: CareActivityDocumentApiAdapter;
}

interface IPatientCareActivityProps {
    _dependencies?: IPatientCareActivityDependencies;
    item: PatientsCareActivityStore;
    careReferenceStore: CareReferenceDataStore;
    organizationReferenceStore: OrganizationReferenceDataStore;
    openModal: (selected: PatientsCareActivityStore) => void;
    openPrimaryDocument: (selected: PatientsCareActivityStore) => void;
}

/** @screen */
class PatientCareActivity extends React.Component<IPatientCareActivityProps> {
    private get documentApiAdapter() {
        return this.props._dependencies.documentApiAdapter;
    }
    public componentDidMount() {
        dispatchAsyncErrors(this.props.organizationReferenceStore.allPointsOfCareMap.ensureAllLoadedAsync(), this);
    }

    @State.bound
    private renderBeginningOfCareDate() {
        const dateFormat = this.props._dependencies.dateTimeFormatProvider.getDateFormat();
        return isNullOrUndefined(this.props.item.beginningOfCare) ? "" : this.props.item.beginningOfCare.format(dateFormat);
    }

    @State.computed private get permissionCheckedOperations() {
        const result = {};

        if (this.props.item.primaryDocumentId) {
            result["ViewPrimaryDocument"] = () => this.documentApiAdapter.getEhrDocumentByIdAsync(this.props.item.primaryDocumentId, true);
        }

        return result;
    }

    private renderDiagnosis() {
        const main = this.props.item.diagnoses.find(item => item.use.value === DiagnosisRoleId.Discharge.value);

        let text = "";
        if (!main) {
            text = StaticProductivityResources.Widgets.EHRWidget.NoMainDiagnosis;
        } else {
            const diagnosis = this.props.careReferenceStore.condition.get(main.conditionVersionSelector);
            text = `${diagnosis.code} ${diagnosis.name}`;
        }

        return (
            <div className={Styles.flexPadding}>
                <p className={Styles.diagnosisName}> {text} </p> <br />
                {this.renderBeginningOfCareDate()}
            </div>
        );
    }

    private renderIcon() {
        switch (this.props.item.careTypeId.value) {
            case CareTypeId.Outpatient.value:
                return (<Ui.Icon iconName={"patientWalking"} visualStyle={"primaryBadge"} />);
            case CareTypeId.Inpatient.value:
                return (<Ui.Icon iconName={"patientInBed"} />);
            default:
                return (<></>);
        }
    }

    private renderSource() { // TODO: string enum generation, this should be plugin'd?
        switch (this.props.item.careActivitySource.value) {
            case "HunEHealthInfrastructure":
                return (
                    <div className={Styles.sourceRectangle}>EESZT</div>
                );
            default: {
                return (<></>);
            }
        }
    }

    @State.bound
    private onModalClick() {
        this.props.openModal(this.props.item);
    }

    @State.bound
    private onPrimaryDocumentModalClick() {
        this.props.openPrimaryDocument(this.props.item);
    }

    private renderPointOfCareShorthand() {
        if (!isNullOrUndefined(this.props.item.organizationUnitId)) {
            const poc = this.props.organizationReferenceStore.allPointsOfCareMap.get(this.props.item.organizationUnitId);
            if (!isNullOrUndefined(poc)) {
                return poc.shorthand;
            }
            return "";
        }
        return "";
    }

    public render() {

        const isLowAccessLevel = this.props.item.accessLevel < AccessLevel.Full;

        return (
            <NestedPermissionCheckContextProvider operationsToCheck={this.permissionCheckedOperations}>
                <Popper
                    flipBehavior={["right"]}
                    tooltipContent={(
                        <PatientCareActivityTooltip item={this.props.item}
                            organizationReferenceStore={this.props.organizationReferenceStore}
                            careReferenceStore={this.props.careReferenceStore}
                        />
                    )}
                    wrapperElementType={"div"}
                    className={Styles.careActivityContainer}
                    tooltipPlacement={"right"}>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={3} className={Styles.container}>

                            <div className={Styles.statusBox}>
                                <CareActivityStateIcon state={this.props.item.careActivityState} />
                            </div>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={9}>
                            {this.renderDiagnosis()}
                            <Ui.Flex noWrap={true} verticalSpacing="none">
                                <Ui.Flex.Item xs={5} className={Styles.sourceContainer}>
                                    {this.props.item.organizationUnitId && this.renderPointOfCareShorthand()}
                                    <div className={Styles.typeIcon}>
                                        {this.renderIcon()}
                                    </div>
                                    {this.renderSource()}
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={7} className={Styles.buttonPadding}>
                                    <Ui.Button iconName={"angleRight"}
                                        onClick={this.onModalClick}
                                        disabled={!this.props.item.careActivityId}
                                        isNotPermitted={isLowAccessLevel}
                                        visualStyle={"standard"}
                                        visualMode="dark"
                                        float={"right"}
                                        automationId={`modalButton_${this.props.item.careActivityId.value}`}
                                    />
                                    {
                                        this.props.item.primaryDocumentId && (
                                            <Ui.Button iconName={"document"}
                                                permissionCheckOperationNames="ViewPrimaryDocument"
                                                permissionDeniedStyle="disabled"
                                                onClick={this.onPrimaryDocumentModalClick}
                                                visualStyle={"standard"}
                                                visualMode="dark"
                                                float={"right"}
                                                isNotPermitted={isLowAccessLevel}
                                                automationId={`openPrimaryDocumentButton_${this.props.item.careActivityId.value}`}

                                            />
                                        )
                                    }
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Popper>
            </NestedPermissionCheckContextProvider>
        );
    }
}

export default connect(
    PatientCareActivity,
    new DependencyAdapter<IPatientCareActivityProps, IPatientCareActivityDependencies>(c => ({
        dateTimeFormatProvider: c.resolve("IDateTimeFormatProvider"),
        documentApiAdapter: c.resolve("CareActivityDocumentApiAdapter")
    }))
);
