exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EhiErrorSummary_container_3Uaj9 {\n  margin-left: 12px;\n  margin-right: 12px;\n}\n.EhiErrorSummary_some-margin-top_3JEmu {\n  margin-top: 10px;\n  margin-left: 0px;\n  margin-right: 0px;\n}\n.EhiErrorSummary_no-margin_OLVB5 {\n  margin: 0;\n}\n", ""]);

// exports
exports.locals = {
	"container": "EhiErrorSummary_container_3Uaj9",
	"some-margin-top": "EhiErrorSummary_some-margin-top_3JEmu",
	"someMarginTop": "EhiErrorSummary_some-margin-top_3JEmu",
	"no-margin": "EhiErrorSummary_no-margin_OLVB5",
	"noMargin": "EhiErrorSummary_no-margin_OLVB5"
};