import Di from "@Di";
import BillableServicesApiAdapter from "@HisPlatform/Packages/Patients/Components/BillableServicesPanel/BillableServicesApiAdapter";
import { IBillableServicesPanelProps } from "@HisPlatform/Packages/Patients/Components/BillableServicesPanel/BillableServicesPanel";
import { PatientServiceList } from "@HisPlatform/Packages/Patients/Components/BillableServicesPanel/PatientServiceList";
import ILoadablePanelStore from "@Toolkit/CommonWeb/PanelStore/ILoadablePanelStore";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class BillableServicesPanelStore extends PanelStoreBase<IBillableServicesPanelProps> implements ILoadablePanelStore {

    @State.observable.ref
    public notBilledServices: PatientServiceList[] = null;

    constructor(
        @Di.inject("BillableServicesApiAdapter") private readonly apiAdapter: BillableServicesApiAdapter,
    ) {
        super();
    }

    public readonly loadAsync = this.async(async () => {
        const notBilledServices = await this.apiAdapter.getNotBilledServicesAsync(this.props._patientId, this.props.invoiceId);

        State.runInAction(() => {
            this.notBilledServices = notBilledServices.result;
        });
    });
}
