import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import UseCases from "@Primitives/UseCases";
import MedicalAlertStatementPanel from "@HisPlatform/BoundedContexts/Care/Components/Panels/MedicalCondition/MedicalAlertStatement/MedicalAlertStatementPanel";
import MedicalAlertStatementId from "@Primitives/MedicalAlertStatementId.g";

export default function configureMedicalAlertStatementUseCases(useCaseRegistry: IUseCaseRegistry) {

    useCaseRegistry.add2({
        identifiers: [UseCases.medicalAlertStatementDetail],
        component: MedicalAlertStatementPanel,
        mapUseCaseArgumentsToProps: args => {
            const map = new Map(args.map(a => [a.name, a.value]));
            const id = map.get("medicalAlertStatementId") ?? new MedicalAlertStatementId("new");
            return {
                id: id,
            };
        }
    });
}
