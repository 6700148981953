import Address from "@HisPlatform/BoundedContexts/CommonReferenceData/ApplicationLogic/Model/CommonReferenceData/Address";
import { AddressDto } from "@HisPlatform/BoundedContexts/Organization/Api/Proxy.g";
import ContagiousPatientReportType from "@HunSocialSecurityPlugin/BoundedContexts/Care/Api/ContagiousPatientReport/Enum/ContagiousPatientReportType.g";
import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/Care/Api/Proxy.g";
import ContagiousPatientReport from "@HunSocialSecurityPlugin/BoundedContexts/Care/ApplicationLogic/Model/ContagiousPatientReport/ContagiousPatientReport";
import ActionIdentifier from "@Primitives/ActionIdentifier.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import ConditionId from "@Primitives/ConditionId.g";
import ContagiousPatientReportId from "@Primitives/ContagiousPatientReportId.g";
import PatientId from "@Primitives/PatientId.g";
import PointOfCareId from "@Primitives/PointOfCareId.g";

export function getGetContagiousPatientReportByIdDto(contagiousPatientReportId: ContagiousPatientReportId, requestLock: boolean) {
    return new Proxy.GetContagiousPatientReportByIdControllerDto({
        contagiousPatientReportId: contagiousPatientReportId,
        requestLock: requestLock
    });
}

export function getCreateEmptyContagiousPatientReportDto(patientId: PatientId, conditionId: ConditionId, pointOfCareId: PointOfCareId, careActivityId: CareActivityId) {
    return new Proxy.CreateEmptyContagiousPatientOnsetReportControllerDto({
        patientId: patientId,
        conditionId: conditionId,
        pointOfCareId: pointOfCareId,
        careActivityId: careActivityId
    });
}

export function getUpdateContagiousPatientReportDto(report: ContagiousPatientReport, isValidateOnly: boolean, action?: ActionIdentifier, releaseLockIfSuccessful = true) {
    return new Proxy.UpdateContagiousPatientReportControllerDto({
        releaseLockIfSuccessful: releaseLockIfSuccessful,
        isValidateOnly: isValidateOnly,
        action: action,
        contagiousPatientReportId: report.id,
        lockId: report.lockInfo?.lockId,
        onsetReportData: getOnsetReportDataDto(report),
        endReportData: getEndReportDataDto(report),
        rowVersion: report.rowVersion
    });
}

function getEndReportDataDto(report: ContagiousPatientReport) {
    if (report.type === ContagiousPatientReportType.OnsetReport) {
        return null;
    }

    return new Proxy.EndReportDataDto({
        contagiousPatientOnsetReportId: report.endReportData.contagiousPatientOnsetReportId,
        endDate: report.endReportData.endDate,
        endDiagnosisId: report.endReportData.endDiagnosisId,
        endOutcomeTypeId: report.endReportData.outcomeTypeId
    });
}

function getOnsetReportDataDto(report: ContagiousPatientReport) {
    return new Proxy.OnsetReportDataDto({
        hospitalId: report.onsetData.hospitalId,
        hospitalizationDate: report.onsetData.hospitalizationDate,
        nursingLocationTypeId: report.onsetData.nursingLocationTypeId,
        occupationId: report.onsetData.occupationId,
        onsetDate: report.onsetData.onsetDate,
        onsetLocation: getAddressDto(report.onsetData.onsetLocation),
        recordingPractitionerId: report.onsetData.recordingPractitionerId,
        workplaceData: getWorkplaceData(report)
    });
}

function getWorkplaceData(report: ContagiousPatientReport) {
    return new Proxy.WorkPlaceDataDto({
        name: report.onsetData.workPlaceName,
        address: getAddressDto(report.onsetData.workPlaceLocation)
    });
}

function getAddressDto(address: Address) {
    return new AddressDto({
        addressLine: address?.addressLine,
        countryId: address?.countryId,
        settlement: address?.settlement,
        zipCode: address?.zipCode
    });
}