import State from "@Toolkit/ReactClient/Common/StateManaging";

export default class OrganizationUnitNaturalIdentifier {
    @State.observable public name: string = "";
    @State.observable public shortName: string = "";

    constructor(name?: string, shortName?: string) {
        this.name = name;
        this.shortName = shortName;
    }
    
    @State.action.bound
    public setName(name: string) {
        this.name = name;
    }
    
    @State.action.bound
    public setShortName(name: string) {
        this.shortName = name;
    }
}