import React from "react";
import PerformanceReportPanelView from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/Components/Panels/ReportingPanel/PerformanceReportPanel/PerformanceReportPanelView";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import StaticWebAppResources from "@HisPlatform/StaticResources/StaticWebAppResources";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import { IReportDefinition } from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/Model/IReportDefinition";
import ReportingApiAdapter from "@HisPlatform/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/ReportingApiAdapter";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import IFileSaverService from "@Toolkit/ReactClient/Services/Definition/FileSaverService/IFileSaverService";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import PerformanceReport from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/PerformanceReport";
import HunFinanceReferenceDataStore from "@HunSocialSecurityPlugin/BoundedContexts/Finance/ApplicationLogic/Model/HunFinanceReferenceDataStore";
import PerformanceReportDtoMapper from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/PerformanceReportDtoMapper";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import ReportingDataProviderStore from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/Model/ReportingDataProviderStore";
import RequestStatus from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/RequestStatus";
import HunReportingApiAdapter from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/ApplicationLogic/ApiAdapter/HunReportingApiAdapter";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import PerformanceReportId from "@Primitives/PerformanceReportId.g";
import NavigateAwayHook from "@Toolkit/ReactClient/Routing/NavigateAwayHook";
import StructureApiAdapter from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/ApiAdapter/Structure/StructureApiAdapter";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import DocumentApiAdapter from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Documents/DocumentApiAdapter";
import Base64Converter from "@Toolkit/CommonWeb/Base64";
import { createInitialPanelLoader } from "@HisPlatform/Components/UnauthorizedAccess/CreatePanelLoader";
import UnauthorizedAccessPageBox from "@HisPlatform/Components/UnauthorizedAccess/UnauthorizedAccessPageBox";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import StaticHunSocialSecurityReportingResources from "@HunSocialSecurityPlugin/BoundedContexts/Reporting/StaticResources/StaticHunSocialSecurityReportingResources";
import Route from "@Toolkit/ReactClient/Routing/Abstractions/Route";
import performanceReportScorePlanStandaloneRouteDefinitions from "@HunSocialSecurityPlugin/UseCases/PerformanceReportScorePlansUseCase/PerformanceReportScorePlanRoutes";

interface IPerformanceReportPanelDependencies {
    apiAdapter: ReportingApiAdapter;
    documentApiAdapter: DocumentApiAdapter;
    structureApiAdapter: StructureApiAdapter;
    fileSaverService: IFileSaverService;
    notificationService: INotificationService;
    financeReferenceDataStore: HunFinanceReferenceDataStore;
    reportingDataProviderStore: ReportingDataProviderStore;
    hunReportingApiAdapter: HunReportingApiAdapter;
    dialogService: IDialogService;
}

interface IPerformanceReportPanelProps {
    _dependencies?: IPerformanceReportPanelDependencies;
    onCancel: () => void;
    definition: IReportDefinition;
    onNavigateTo: (route: Route<any>) => void;
}

/** @screen */
@State.observer
class PerformanceReportPanel extends React.Component<IPerformanceReportPanelProps> {
    private get apiAdapter() { return this.props._dependencies.apiAdapter; }
    private get documentApiAdapter() { return this.props._dependencies.documentApiAdapter; }
    private get structureApiAdapter() { return this.props._dependencies.structureApiAdapter; }
    private get fileSaverService() { return this.props._dependencies.fileSaverService; }
    private get notificationService() { return this.props._dependencies.notificationService; }
    private get performanceReportsDataStore() { return this.props._dependencies.reportingDataProviderStore.performanceReports; }
    private get hunReportingApiAdapter() { return this.props._dependencies.hunReportingApiAdapter; }
    private get allProfessions() { return this.props._dependencies.financeReferenceDataStore.hunSocialSecurityInsuranceCodeHealthcareProfessions; }
    private get dialogService() { return this.props._dependencies.dialogService; }

    @State.observable private performanceReport: PerformanceReport;
    @State.observable.ref private dataSource: InMemoryDataGridDataSource = new InMemoryDataGridDataSource(() => this.performanceReportsDataStore.items);
    @State.observable private isLoading: boolean = false;
    @State.observable.ref private pointOfCareIds: PointOfCareId[] = [];

    @State.observable private validationResults: IClientValidationResult[] = [];

    @State.computed private get permissionCheckedOperations() {
        return async () => await this.apiAdapter.runReportAsync(this.props.definition.reportDefinitionIdentifier, {} as JSON, true);
    }

    @State.action.bound
    public setValidationResults(newValues: IClientValidationResult[]) {
        this.validationResults = newValues;
    }

    @State.action.bound
    public validateAsync(): Promise<IClientValidationResult[]> {
        return Promise.resolve((this.performanceReport !== null && this.performanceReport !== undefined) ? (this.performanceReport?.validationResults ?? []) : []);
    }

    @State.action.bound
    private setPerformanceReport(newValue: PerformanceReport) {
        this.performanceReport = newValue;
        this.performanceReport?.takeSnapshot();
    }

    @State.action.bound
    private setPointOfCareIds(newValue: PointOfCareId[]) {
        this.pointOfCareIds = newValue;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    @State.bound
    private async _loadAsync() {
        this.setIsLoading(true);
        await Promise.all([
            this.allProfessions.ensureAllLoadedAsync(),
            this.performanceReportsDataStore.ensureAllLoadedAsync()
        ]);

        const pointOfCareIds = await this.structureApiAdapter.getAllPointOfCareIdsAsync();
        this.setPointOfCareIds(pointOfCareIds.value);

        this.setIsLoading(false);
    }

    @State.action.bound
    private async onCreateAsync() {
        this.setIsLoading(true);
        const dtoMapper = new PerformanceReportDtoMapper();
        const parameter = dtoMapper.getPerformanceReportParametersDto(this.performanceReport, this.allProfessions.items);

        const validatedStore = this.performanceReport.isNew ?
            await this.hunReportingApiAdapter.addPerformanceReportAsync(this.performanceReport, this.allProfessions.items, true) :
            await this.hunReportingApiAdapter.updatePerformanceReportAsync(this.performanceReport, this.allProfessions.items, true);

        if (validatedStore.operationInfo.requestStatus !== RequestStatus.Success || validatedStore.hasValidationError) {
            this.notificationService.showCannotSaveBecauseOfErrors();
            this.setValidationResults(validatedStore.validationResults);
            this.setIsLoading(false);
            return;
        }

        const parameterString = JSON.stringify(parameter);
        const response = await this.apiAdapter.runReportAsync(this.props.definition.reportDefinitionIdentifier, JSON.parse(parameterString));
        const documentResponse = await this.documentApiAdapter.getDocumentContentAsync(response.value.largeDataIds[0]);
        const contentBytes = Base64Converter.toByteArray(documentResponse.value.content);
        this.fileSaverService.saveAs(new Blob([contentBytes], { type: documentResponse.value.mediaType }), documentResponse.value.fileName);
        this.setIsLoading(false);
    }

    @State.bound
    private async tryCloseEditorAsync(onCancelNeeded: boolean = true) {
        if (this.performanceReport && this.performanceReport.isDirty()) {
            const answer = await this.dialogService.confirmIfNotSaved(StaticHunSocialSecurityReportingResources.PerformanceReportPanel.SaveBeforeNavigationConfirmationTitle,
                StaticHunSocialSecurityReportingResources.PerformanceReportPanel.SaveBeforeNavigationConfirmationMessage);

            if (answer.resultCode !== DialogResultCode.Cancel) {
                if (answer.resultCode === DialogResultCode.Yes) {
                    const result = await this.savePerformanceReportAsync();
                    return result;
                }
                if (onCancelNeeded) {
                    this.props.onCancel();
                }
                return true;
            }
            return false;
        }
        return true;
    }

    @State.action.bound
    private async addNewPerformanceReportAsync() {
        const result = await this.tryCloseEditorAsync(false);
        if (!result) {
            return;
        }

        const performanceReport = new PerformanceReport(true);
        performanceReport.id = new PerformanceReportId("new");
        this.setPerformanceReport(performanceReport);
        this.setValidationResults([]);
    }

    @State.bound
    private async onSelectedItemChangeAsync(selectedPerformanceReportId: string) {
        const result = await this.tryCloseEditorAsync(false);
        if (!result) {
            return;
        }
        
        const selectedPerformanceReport = (this.dataSource.data.items as PerformanceReport[]).filter(x => x.id.value === selectedPerformanceReportId)[0];

        if (isNullOrUndefined(selectedPerformanceReport)) {            
            this.setPerformanceReport(null);
            this.setValidationResults([]);
            return;
        }

        this.setPerformanceReport(_.clone(selectedPerformanceReport));
        this.setValidationResults(selectedPerformanceReport.validationResults);
    }

    @State.action.bound
    private setIsLoading(newValue: boolean) {
        this.isLoading = newValue;
    }

    @State.action.bound
    private async deletePerformanceReportAsync() {
        this.setIsLoading(true);
        if (!this.performanceReport || (this.performanceReport && (!this.performanceReport.id || this.performanceReport.isNew))) {
            return;
        }

        await this.hunReportingApiAdapter.deletePerformanceReportAsync(this.performanceReport.id);
        this.setPerformanceReport(null);
        await this.updateListAsync();
        this.setIsLoading(false);
    }

    @State.action.bound
    private async savePerformanceReportAsync() {
        if (!this.performanceReport) {
            return true;
        }

        await this.allProfessions.ensureAllLoadedAsync();

        this.setIsLoading(true);
        let newStore: PerformanceReport;
        if (this.performanceReport.isNew) {
            newStore = await this.hunReportingApiAdapter.addPerformanceReportAsync(this.performanceReport, this.allProfessions.items);
        } else {
            newStore = await this.hunReportingApiAdapter.updatePerformanceReportAsync(this.performanceReport, this.allProfessions.items);
        }

        if (newStore.operationInfo.requestStatus !== RequestStatus.Success || newStore.hasValidationError) {
            this.notificationService.showCannotSaveBecauseOfErrors();
            this.setValidationResults(newStore.validationResults);
            this.setIsLoading(false);
            return false;
        }

        await this.updateListAsync();

        this.setPerformanceReport(this.performanceReportsDataStore.items.filter(x => x.id.value === newStore.id.value)[0]);

        this.notificationService.showSaveResult(newStore.isPersistedByOperationInfo, newStore.hasValidationWarning);
        this.setIsLoading(false);

        return true;
    }

    @State.bound
    private async updateListAsync() {
        this.performanceReportsDataStore.invalidate();
        await this.performanceReportsDataStore.ensureAllLoadedAsync();
    }

    private readonly loadAsync = createInitialPanelLoader(this._loadAsync, this.permissionCheckedOperations);

    public render() {
        if (this.loadAsync.isUnauthorizedAccess) {
            return <UnauthorizedAccessPageBox title={this.props.definition.name} />;
        }

        return (
            <>
                <PerformanceReportPanelView
                    definition={this.props.definition}
                    performanceReport={this.performanceReport}
                    pointOfCareIds={this.pointOfCareIds}
                    onCancel={this.props.onCancel}
                    onCreateAsync={this.onCreateAsync}
                    validationResults={this.validationResults}
                    onValidateAsync={this.validateAsync}
                    isLoading={this.isLoading}
                    dataSource={this.dataSource}
                    addNewPerformanceReportAsync={this.addNewPerformanceReportAsync}
                    savePerformanceReportAsync={this.savePerformanceReportAsync}
                    onSelectedItemChangeAsync={this.onSelectedItemChangeAsync}
                    deletePerformanceReportAsync={this.deletePerformanceReportAsync}
                    onNavigateTo={() => this.props.onNavigateTo(performanceReportScorePlanStandaloneRouteDefinitions.performanceReportScorePlan.makeRoute())}
                />
                <NavigateAwayHook isEnabled onNavigateAwayAsync={this.tryCloseEditorAsync} />
            </>
        );
    }
}

export default connect(
    PerformanceReportPanel,
    new DependencyAdapter<IPerformanceReportPanelProps, IPerformanceReportPanelDependencies>(c => {
        return {
            apiAdapter: c.resolve("ReportingApiAdapter"),
            documentApiAdapter: c.resolve("DocumentApiAdapter"),
            structureApiAdapter: c.resolve("StructureApiAdapter"),
            fileSaverService: c.resolve("IFileSaverService"),
            notificationService: c.resolve("INotificationService"),
            financeReferenceDataStore: c.resolve("HunFinanceReferenceDataStore"),
            reportingDataProviderStore: c.resolve("ReportingDataProviderStore"),
            hunReportingApiAdapter: c.resolve("HunReportingApiAdapter"),
            dialogService: c.resolve("IDialogService")
        };
    })
);