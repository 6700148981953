import CareActivityTextBlockId from "@Primitives/CareActivityTextBlockId.g";
import TextBlockTypeId from "@Primitives/TextBlockTypeId.g";
import CareActivityId from "@Primitives/CareActivityId.g";
import LockingEntityStoreBase from "@Toolkit/CommonWeb/Model/LockingEntityStoreBase";
import UserId from "@Primitives/UserId.g";
import moment from "moment";
import DocumentContentStore from "@CommonControls/DocumentEditor/DocumentContentStore";

export default class CareActivityTextBlock extends LockingEntityStoreBase<CareActivityTextBlockId> {
    public textBlockTypeId: TextBlockTypeId;
    public careActivityId: CareActivityId;
    public readonly contentStore = DocumentContentStore.create();
    public lastModifiedBy: UserId;
    public lastModifiedAt: moment.Moment;
}