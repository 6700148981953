import { createNestedRoutes } from "@Toolkit/ReactClient/Routing/CreateNestedRoutes";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";
import IOutpatientTreatmentRouteParams from "@HisPlatform/Application/Routes/IOutpatientTreatmentRouteParams";
import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";

export interface IPractitionerRecommendationStandaloneRouteParams extends IOutpatientTreatmentRouteParams {
    practitionerRecommendationId: string;
    recommendationType: string;
    readonly: string;
}

const standaloneRouteDefinitions = createNestedRoutes(ApplicationRoutes.careActivity, {
    practitionerRecommendations: new RouteDefinition<IPractitionerRecommendationStandaloneRouteParams>("/practitionerRecommendations/:recommendationType?/:practitionerRecommendationId?/:readonly?")
});

export default standaloneRouteDefinitions;