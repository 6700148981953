import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import StaticUserManagementResources from "@HisPlatform/BoundedContexts/UserManagement/StaticResources/StaticUserManagementResources";
import * as Ui from "@CommonControls";
import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import StaticWebAppResources from "@StaticResources";
import UserWithIdentifiers from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/UserWithIdentifiers";
import ExternalPractitionerDataSource from "@HisPlatform/BoundedContexts/UserManagement/Components/Panels/UserManagementPanel/ExternalPractitionerDataSource";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";

interface IUserListProps extends IHasMasterDetailState {
    usersWithIdentifiersDataSource: InMemoryDataGridDataSource;
    practitionersDataSource: ExternalPractitionerDataSource;
    userMode: boolean;
    onUserModeChange: (userMode: boolean) => void;
    onBack(): void;
}
const renderDisplayName = (_: any, row: any) => {
    return (
        <>
            <Ui.Icon iconName={row.iconName} style={{ marginRight: 5 }} />
            {row.displayName}
        </>);
};

const displayValueGetter = (item: boolean) => {
    return item ?
        StaticUserManagementResources.UserManagementPanel.Label.Internal.toUpperCase() :
        StaticUserManagementResources.UserManagementPanel.Label.External.toUpperCase();
};

const getIconNameForValues = (item: boolean) => {
    return item ? "id_card" : "language";
};

/** @screen */

const UserList: React.FC<IUserListProps> = (props) => {
    const rowId = (row: any) => {
        return props.userMode ? `user-${row.id.value}` : `practitioner-${row.id.value}`;
    };
    const items: boolean[] = [true, false];
    return (
        <>
            <Ui.RadioButtonGroup
                direction="row"
                value={props.userMode}
                onChange={props.onUserModeChange}
                items={items}
                getDisplayValue={displayValueGetter}
                getIconName={getIconNameForValues}
                displayType="groupedButtons"
                automationId="userMode" />
            {props.userMode &&
                <Ui.DataGrid
                    rowHeight={40}
                    fixedHeight="calc(100vh - 140px)"
                    dataSource={props.usersWithIdentifiersDataSource}
                    generateInitialFilterStore
                    changeOnMount
                    isSelectable
                    fixedLayout
                    actionsColumn={false}
                    onSelectedRowChange={props._masterDetailState.onSelectedItemChange}
                    selectedRow={props._masterDetailState.selectedItem}
                    adjacentButtonsCount={1}
                    rowId={rowId}
                    rowComparer="rowId"
                    hasBackButton
                    backButtonText={StaticWebAppResources.Common.Button.Back}
                    onBack={props.onBack} 
                    automationId="usersWithIdentifiers_dataGrid">
                    <DataGridColumn
                        id={0}
                        dataGetter={"user.displayName"}
                        clientSideOrderableValueGetter={"user.displayName"}
                        onRenderCellValue={renderDisplayName}
                        header={StaticUserManagementResources.UserManagementPanel.ColumnHeader.DisplayName}
                        width="35%"
                        isFilterable
                        isOrderable />
                    <DataGridColumn
                        id={1}
                        dataGetter={"loginName"}
                        clientSideOrderableValueGetter={"loginName"}
                        header={StaticUserManagementResources.UserManagementPanel.ColumnHeader.LoginName}
                        width="30%"
                        isFilterable
                        isOrderable />
                    <DataGridColumn
                        id={2}
                        dataGetter={"identifiersString"}
                        clientSideOrderableValueGetter={"identifiersString"}
                        header={StaticUserManagementResources.UserManagementPanel.ColumnHeader.Identifiers}
                        width="35%"
                        isFilterable
                        isOrderable />
                </Ui.DataGrid>}
            {!props.userMode &&
                <Ui.DataGrid
                    rowHeight={40}
                    fixedHeight="calc(100vh - 140px)"
                    dataSource={props.practitionersDataSource}
                    generateInitialFilterStore
                    changeOnMount
                    isSelectable
                    fixedLayout
                    actionsColumn={false}
                    onSelectedRowChange={props._masterDetailState.onSelectedItemChange}
                    selectedRow={props._masterDetailState.selectedItem}
                    adjacentButtonsCount={1}
                    rowId={rowId}
                    rowComparer="rowId"
                    hasBackButton
                    backButtonText={StaticWebAppResources.Common.Button.Back}
                    onBack={props.onBack}
                    automationId="userList_practicioners_dataGrid">
                    <DataGridColumn
                        id={"Name"}
                        dataGetter={"displayName"}
                        clientSideOrderableValueGetter={"displayName"}
                        onRenderCellValue={renderDisplayName}
                        header={StaticUserManagementResources.UserManagementPanel.ColumnHeader.DisplayName}
                        width="60%"
                        isFilterable
                        isOrderable />
                    <DataGridColumn
                        id={"Identifier"}
                        dataGetter={"identifiersString"}
                        clientSideOrderableValueGetter={"identifiersString"}
                        header={StaticUserManagementResources.UserManagementPanel.ColumnHeader.Identifiers}
                        width="40%"
                        isFilterable
                        isOrderable />
                </Ui.DataGrid>}
        </>
    );
};

export default State.observer(UserList);
