import State from "@Toolkit/ReactClient/Common/StateManaging";
import OrganizationReferenceDataStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/ReferenceData/OrganizationReferenceDataStore";
import CareReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/ReferenceData/CareReferenceDataStore";
import Di from "@Di";
import CareActivityDischargeDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityDischargeData/CareActivityDischargeDataStore";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import ConditionId from "@Primitives/ConditionId.g";
import PractitionerId from "@Primitives/PractitionerId.g";
import CareRegisterReferenceDataStore from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareRegisterReferenceDataStore";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import ExternalLocationId from "@Primitives/ExternalLocationId.g";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import CareActivityBaseData from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/CareRegister/CareActivityBaseData/CareActivityBaseData";
import CareParticipantRole from "@HisPlatform/BoundedContexts/Care/Api/CareRegister/Enum/CareParticipantRole.g";

@Di.injectable()
export default class CareActivityLoader {

    constructor(
        @Di.inject("OrganizationReferenceDataStore") private readonly organizationReferenceDataStore: OrganizationReferenceDataStore,
        @Di.inject("CareReferenceDataStore") private readonly careReferenceDataStore: CareReferenceDataStore,
        @Di.inject("CareRegisterReferenceDataStore") private readonly careRegisterReferenceDataStore: CareRegisterReferenceDataStore,
    ) { }

    @State.bound
    public async loadCareActivityRelatedReferenceDataAsync(
        baseData?: CareActivityBaseData,
        dischargeData?: CareActivityDischargeDataStore
    ) {
        // Warning! Not complete. Add your reference data loading here as needed.

        await this.careReferenceDataStore.careType.ensureLoadedAsync();
        await this.careReferenceDataStore.forwardingNeededAtDischarge.ensureLoadedAsync();
        await this.careReferenceDataStore.patientDocumentType.ensureAllLoadedAsync();
        await this.careRegisterReferenceDataStore.admissionReason.ensureLoadedAsync();
        await this.careRegisterReferenceDataStore.dischargeReason.ensureLoadedAsync();
        await this.careRegisterReferenceDataStore.financingClass.ensureLoadedAsync();

        const doctorIds: PractitionerId[] = [];
        const externalLocationIds: Array<IEntityVersionSelector<ExternalLocationId>> = [];

        const participants = baseData.getAllParticipants;

        await this.organizationReferenceDataStore.assistantMap.ensureLoadedAsync(
            participants.filter(i => i.role === CareParticipantRole.Assistant).map(i => i.id));
        doctorIds.push(...participants.filter(i => i.role === CareParticipantRole.Doctor).map(i => i.id));

        if (!!baseData?.referral) {
            await this.careReferenceDataStore.condition.ensureLoadedAsync([
                new EntityVersionSelector<ConditionId>(baseData.referral.referralDiagnosisId1, baseData.referral.referralCreationDate || LocalDate.today())
            ]);

            doctorIds.push(baseData.referral.referralDoctorId);
            externalLocationIds.push({ id: baseData.referral.referralWorkplace, validOn: baseData.referral.referralCreationDate || LocalDate.today() });
        }

        if (!!dischargeData) {
            externalLocationIds.push(dischargeData.receivingInstitute);
        }

        await this.organizationReferenceDataStore.externalLocationStore.ensureLoadedAsync(externalLocationIds);
        await this.organizationReferenceDataStore.doctorMap.ensureLoadedAsync(doctorIds);
    }

}