import Di from "@Di";
import * as Proxy from "@HisPlatform/BoundedContexts/Diagnostics/Api/Proxy.g";
import ApiAdapterBase from "@Toolkit/CommonWeb/ApiAdapter/ApiAdapterBase";
import SimpleStore from "@Toolkit/CommonWeb/Model/SimpleStore";
import { createOperationInfo } from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/OperationInfoHelper";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { CreateRequestId } from "@HisPlatform/Common/RequestHelper";
import _ from "@HisPlatform/Common/Lodash";
import IssueFieldStore from "@HisPlatform/BoundedContexts/Diagnostics/ApplicationLogic/Model/IssueFieldStore";
import IssueData from "@HisPlatform/BoundedContexts/Diagnostics/ApplicationLogic/Model/IssueData";
import { FeedbackType } from "@HisPlatform/BoundedContexts/Diagnostics/ApplicationLogic/Model/FeedbackType";
import IssueAttachment from "@HisPlatform/BoundedContexts/Diagnostics/ApplicationLogic/Model/IssueAttachment";

@Di.injectable()
export default class IssueReportingApiAdapter extends ApiAdapterBase {
    constructor(
        @Di.inject("IIssueReportingClient") private readonly apiClient: Proxy.IIssueReportingClient
    ) {
        super();
    }

    @State.bound
    public createIssueCommandAsync(
        feedbackType: FeedbackType,
        summary: string | null,
        description: string | null,
        fields: IssueFieldStore[] | null,
        attachments: IssueAttachment[] | null,
        isPermissionCheckOnly: boolean = false) {
        return this.processOperationAsync(
            new SimpleStore<IssueData>(),
            async target => {
                let fieldDtos: Proxy.IssueFieldDto[] = [];
                let attachmentDtos: Proxy.IssueAttachmentDto[] = [];
                if (fields) {
                    fieldDtos = fields.map(field => new Proxy.IssueFieldDto({ fieldId: field.fieldId, value: field.value }));
                }
                if (attachments) {
                    attachmentDtos = attachments.map(attachment => new Proxy.IssueAttachmentDto({ fileName: attachment.fileName, content: attachment.content }));
                }
                const response = await this.apiClient.createIssueCommandAsync(
                    CreateRequestId(),
                    new Proxy.CreateIssueControllerDto({ feedbackType: feedbackType, summary: summary, description: description, fields: fieldDtos, attachments: attachmentDtos }),
                    isPermissionCheckOnly);
                target.operationInfo = createOperationInfo(response);
                target.value = new IssueData(response.issueData.issueKey, response.issueData.issueUrl);
            }
        );
    }
}
