import IPlatformPlugin from "@PluginInterface/IPlatformPlugin";
import { Container } from "inversify";
import IExtensionComponentRegistry from "@Toolkit/ReactClient/Extensibility/ExtensionComponentRegistry/IExtensionComponentRegistry";
import { ExtensionPointType } from "@PluginInterface/ExtensionPoint/ExtensionPointType";
import IUseCaseRegistry from "@PluginInterface/UseCases/IUseCaseRegistry";
import IFormExtensionRegistry from "@PluginInterface/FormExtension/IFormExtensionRegistry";
import IPermissionDefinitionRegistry from "@PluginInterface/OperationAccessControl/IPermissionDefinitionRegistry";
import IActivityRegistry from "@PluginInterface/UseCases/IActivityRegistry";
import INDataAttributeConfigurator from "@PluginInterface/NData/INDataAttributeConfigurator";
import IMapperConfigurator from "@HisPlatform/Services/Definition/MapperService/IMapperConfigurator";
import FormEditorRegistry from "@Toolkit/FormEngine/Panels/FormPanel/FormEditorRegistry";
import "./DependencyConfiguration/MedioDependencyConfiguration";
import AlwaysVisibleExtensionComponentService from "@PluginInterface/ExtensionPoint/AlwaysVisibleExtensionComponentService";
import AppointmentScheduleDefinitionInfoPanel from "@MedioPlugin/BoundedContexts/Scheduling/Extensions/AppointmentScheduleDefinitionInfoExtension/AppointmentScheduleDefinitionInfoPanel";
import ICurrentCultureProvider from "@Toolkit/CommonWeb/Abstractions/CurrentCultureProvider/ICurrentCultureProvider";
import IResourceFetcher from "@Toolkit/CommonWeb/Abstractions/Localization/IResourceFetcher";
import { loadStaticMedioWebAppResources } from "@MedioPlugin/StaticResources/StaticMedioWebAppResources";
import configureMedioPermissionDefinitions from "@MedioPlugin/PermissionDefinitions/MedioPermissionDefinitionsConfiguration";

class MedioPlugin implements IPlatformPlugin {
    public name: string = "MedioPlugin";

    public async configureAsync(container: Container) {
        const applicationContext = container.get<ICurrentCultureProvider>("ICurrentCultureProvider");
        const resourceFetcher = container.get<IResourceFetcher>("IResourceFetcher");
        await resourceFetcher.fetchResourceGroupAsync("MedioValidationMessages", "ValidationMessages", applicationContext.cultureCode);
        await resourceFetcher.fetchResourceGroupAsync("MedioReferenceData", "ReferenceData", applicationContext.cultureCode);
        await resourceFetcher.fetchResourceGroupAsync("MedioWebApp", "WebApp", applicationContext.cultureCode);

        loadStaticMedioWebAppResources();

        this.configureExtensionComponentServices(container);
        await Promise.resolve();
    }

    public configureExtensionComponents(registry: IExtensionComponentRegistry<ExtensionPointType>) {
        registry.register("appointmentScheduleDefinitionInfo", AppointmentScheduleDefinitionInfoPanel, "AlwaysVisibleExtensionComponentService");
    }

    private configureExtensionComponentServices(container: Container) {
        return;
    }

    public configureUseCases(useCaseRegistry: IUseCaseRegistry): void {
        return;
    }

    public configureFormExtensions(formExtensionRegistry: IFormExtensionRegistry): void {
        return;
    }

    public configurePermissionDefinitions(permissionDefinitionRegistry: IPermissionDefinitionRegistry): void {
        configureMedioPermissionDefinitions(permissionDefinitionRegistry);
    }

    public configureActivities(activityRegistry: IActivityRegistry) {
        return;
    }

    public configureNData(configurator: INDataAttributeConfigurator): void {
        return;
    }

    public configureMapper(configurator: IMapperConfigurator): void {
        return;
    }

    public configureFormEngine(registry: FormEditorRegistry): void {
        return;
    }
}

export default new MedioPlugin();
