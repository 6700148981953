import React from "react";
import * as Ui from "@CommonControls";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import ExtensibleEnumStore from "@Toolkit/CommonWeb/Model/ExtensibleEnumStore";
import memoizeOne from "memoize-one";
import IExtensibleEnumValue from "@Toolkit/CommonWeb/Model/IExtensibleEnumValue";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import ILocalizedObject from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalization";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import _ from "@HisPlatform/Common/Lodash";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import State from "@Toolkit/ReactClient/Common/StateManaging";

export interface IExtensibleEnumSelectBoxPropsBase extends ISelectBoxBaseProps {
    idFilter?: IStringEntityId[];
    displayMode?: "shorthandTooltip" | "tooltipOnly" | "multiselectLongValues";
    excludedValues?: string[];
}

export interface IExtensibleEnumSelectBoxProps extends IExtensibleEnumSelectBoxPropsBase {
    extensibleEnumStore: ExtensibleEnumStore;
    itemDisplayValueGetter?: (item: ILocalizedObject) => string;
}

@State.observer
export default class ExtensibleEnumSelectBox extends React.Component<IExtensibleEnumSelectBoxProps> {

    private displayExpr(item: any) {
        return  item.Shorthand === "" ? item.Name : <><b>{item.Shorthand}</b>{` - ${item.Name}`}</>;
    }

    private textExpr(item: any) {
        return item.Shorthand === "" ? item.Name : `${item.Shorthand} - ${item.Name}`;
    }

    public static defaultProps: Partial<IExtensibleEnumSelectBoxProps> = {
        itemDisplayValueGetter: (item: any) => item.Shorthand === "" ? item.Name : `${item.Shorthand} - ${item.Name}`,
    };

    private getItems = memoizeOne((isLoaded: boolean, values: Array<IExtensibleEnumValue<any>>) => {
        let items: Array<IExtensibleEnumValue<any>> = null;
        if (isLoaded) {
            if (this.props.excludedValues && this.props.excludedValues.length > 0) {
                this.props.excludedValues.forEach(v => {
                    const excludedValue = values.find(e => e.id.value === v);
                    if (excludedValue) {
                        const index = values.indexOf(excludedValue);
                        values.splice(index, 1);
                    }
                });
                items = values;
            } else {
                items = values;
            }
            return items.map((i) => {
                return {
                    text: i.displayValue && this.props.itemDisplayValueGetter(i.displayValue),
                    value: i.id,
                    longDisplayValue: i.displayValue && i.displayValue
                } as ISelectBoxItem<any>;
            });
        }
        return [];
    }, _.isEqual);

    private getFilteredItems(isLoaded: boolean, extEnumValues: Array<IExtensibleEnumValue<any>>, idFilter: IStringEntityId[]) {
        let values = this.getItems(isLoaded, extEnumValues);
        if (idFilter && idFilter.length > 0) {
            values = values.filter(v => {
                return idFilter.some(id => id.value === v.value.value);
            });
        }
        return values;
    }

    public componentDidMount() {
        dispatchAsyncErrors(this.props.extensibleEnumStore.ensureLoadedAsync(), this);
    }

    public render() {
        const items = this.getFilteredItems(this.props.extensibleEnumStore.isLoaded, this.props.extensibleEnumStore.items, this.props.idFilter);
        const selectedItem = this.getSelectedItemDisplayValue(items);
        return (
            <Ui.SelectBox
                items={items}
                loading={!this.props.extensibleEnumStore.isLoaded}
                tooltipContent={this.getTooltipValue(selectedItem)}
                getOptionText={this.getOptionText}
                customValueRenderer={this.getCustomValue}
                orderItems={false}
                {...this.props}
            />
        );
    }

    @State.bound
    private getCustomValue(props: any) {
        if (this.props.displayMode === "shorthandTooltip") {
            return this.getShorthandValue(props);
        }
        return this.displayExpr(props.data.longDisplayValue);
    }

    @State.bound
    private getOptionText(value: any) {
        if (this.props.displayMode === "multiselectLongValues") {
            return value.longDisplayValue.Shorthand;
        }
        return this.textExpr(value.longDisplayValue);
    }

    private getShorthandValue(props: any): any {
        if (props && props.data && props.data.longDisplayValue) {
            return props.data.longDisplayValue.Shorthand;
        }
    }

    private getTooltipValue(selectedItemValue: string) {
        if (this.props.tooltipContent) {
            return this.props.tooltipContent;
        } else if (this.props.displayMode) {
            return selectedItemValue;
        } else {
            return null;
        }
    }

    private getSelectedItemDisplayValue(items: Array<ISelectBoxItem<any>>) {
        if (items.length > 0 && this.props.value && this.props.value.value) {
            const selectedItem = items.find(i => i.value.value === this.props.value.value);
            if (selectedItem && selectedItem.longDisplayValue) {
                return `${selectedItem.longDisplayValue.Shorthand} - ${selectedItem.longDisplayValue.Name}`;
            } else {
                return "";
            }
        } else {
            return "";
        }
    }
}
