import * as Proxy from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/Proxy.g";
import Di from "@Di";
import NeakErrorList from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/ApplicationLogic/Model/NeakErrorList";

@Di.injectable()
export default class NeakErrorListDtoMapper {

    public getNeakErrorListDto(store: NeakErrorList) {
        return new Proxy.NeakErrorListDto(
            {
                processLog: store.processLog,
                hasProcessError: store.hasProcessError,
            } as Proxy.INeakErrorListDto);
    }
}