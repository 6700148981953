import { UniversalEnumSelector } from "@CommonControls";
import IUniversalEnumSelectorBaseProps from "@CommonControls/UniversalEnumSelector/IUniversalEnumSelectorBaseProps";
import WeekOfMonth from "@HisPlatform/BoundedContexts/Scheduling/Api/Configuration/Enum/WeekOfMonth.g";
import React from "react";

interface IWeekOfMonthSelectorProps extends IUniversalEnumSelectorBaseProps {
    value?: WeekOfMonth | WeekOfMonth[];
    onChange?: (newValue: WeekOfMonth | WeekOfMonth[]) => void;
}


const WeekOfMonthSelector: React.FC<IWeekOfMonthSelectorProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumOrigin="server"
        enumName={"WeekOfMonth"}
        enumType={WeekOfMonth}
    />
);

export default WeekOfMonthSelector;