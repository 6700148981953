import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import PrescriptionApiAdapter from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/ApiAdapter/Prescription/PrescriptionApiAdapter";
import { IModalComponentParams, IModalService } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { IPrintPrescriptionDialogParams, IPrintPrescriptionDialogResult } from "./PrintPrescriptionDialogParams";
import PrescriptionPrintPreferences from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/ApplicationLogic/Model/Prescription/PrescriptionPrintPreferences";
import PrintOrientationSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrintOrientationSelectBox/PrintOrientationSelectBox";
import PrintPaperSizeSelectBox from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/Components/Controls/PrintPaperSizeSelectBox/PrintPaperSizeSelectBox";
import StaticHunSocialSecurityMedicationRequestResources from "@HunEHealthInfrastructurePlugin/BoundedContexts/MedicationRequest/StaticResources/StaticHunEHealthInfrastructureMedicationRequestResources";
import Styles from "./PrintPrescriptionDialog.less";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import PrescriptionPrintDataId from "@Primitives/PrescriptionPrintDataId.g";
import Guid from "@Toolkit/CommonWeb/Guid";

interface IPrintPrescriptionDialogDependencies {
    prescriptionApiAdapter: PrescriptionApiAdapter;
    notificationService: INotificationService;
    dialogService: IDialogService;
}

interface IPrintPrescriptionDialogProps extends IModalComponentParams<IPrintPrescriptionDialogResult>, IPrintPrescriptionDialogParams {
    _dependencies?: IPrintPrescriptionDialogDependencies;
}

/** @screen */
@State.observer
class PrintPrescriptionDialog extends React.Component<IPrintPrescriptionDialogProps> {

    private readonly prescriptionPrintPreferences: PrescriptionPrintPreferences = new PrescriptionPrintPreferences();

    @State.computed
    private get prescriptionApiAdapter() { return this.props._dependencies.prescriptionApiAdapter; }

    private get dialogService() { return this.props._dependencies.dialogService; }

    @State.computed private get prescriptionId() {
        return this.props.prescriptionId;
    }

    @State.observable private reprintReason: string = null;
    @State.observable private isPrintClicked: boolean = false;
    @State.observable private validationResults: IClientValidationResult[] = null;

    private prescriptionPrintDataId: PrescriptionPrintDataId = null;
    private deleteToken: Guid = null;

    @State.bound
    private setPrescriptionPrintDataId(id: PrescriptionPrintDataId) {
        this.prescriptionPrintDataId = id;
    }

    @State.bound
    private setDeleteToken(token: Guid) {
        this.deleteToken = token;
    }

    private printPrescription = State.asyncComputed(null, 100, async () => {
        if (this.prescriptionId !== null) {
            const result = await this.prescriptionApiAdapter.printPrescriptionAsync(
                this.prescriptionId,
                this.prescriptionPrintPreferences.orientation,
                this.prescriptionPrintPreferences.paperSize,
                this.prescriptionPrintPreferences.rotation,
                this.prescriptionPrintPreferences.offsetX,
                this.prescriptionPrintPreferences.offsetY);
            return result.value;
        }
        return null;
    });
    @State.computed
    private get getPrintPrescription() {
        return this.printPrescription.get();
    }

    @State.computed
    private get getDocumentPath() {
        return this.getPrintPrescription?.largeDataToken;
    }

    @State.computed
    private get getDownloadFileName() {
        return this.getPrintPrescription?.downloadFileName;
    }

    @State.computed
    private get getIsPrinted() {
        return this.getPrintPrescription?.isAlreadyPrinted;
    }

    @State.action.bound
    private async printClickedAsync() {
        const response = await this.prescriptionApiAdapter.createPrescriptionPrintDataAsync(this.prescriptionId, this.reprintReason);
        this.setPrescriptionPrintDataId(response.value.prescriptionPrintDataId);
        this.setDeleteToken(response.value.deleteToken);
        this.setValidationResults(response.value.validationResults);
        this.isPrintClicked = true;
        return response.isPersistedByOperationInfo;
    }

    @State.action.bound
    private onChangeReprintReason(newValue: string) {
        this.reprintReason = newValue;
    }

    @State.action.bound
    private setValidationResults(results: IClientValidationResult[]) {
        this.validationResults = results;
    }

    public render() {
        return (
            <ValidationBoundary
                entityTypeName={"PrescriptionPrintData"}
                validateOnMount
                validationResults={this.validationResults}
            >
                <HisUi.ContextAwareModal isOpen onClose={this.closeWithConfirmation} size="fullscreen">
                    <Ui.Flex style={{ alignItems: "top", flex: "1" }} verticalSpacing="none" outerSpacing="none">
                        <Ui.Flex.Item sm={4}>
                            <h1 className={Styles.titleText}>{StaticHunSocialSecurityMedicationRequestResources.PrintPreviewTitle}</h1>
                            <hr className={Styles.separator} />
                            <div style={{ padding: "0 8px" }} >
                                <Ui.Flex>
                                    <Ui.Flex.Item sm={6}>
                                        <PrintPaperSizeSelectBox
                                            label={StaticHunSocialSecurityMedicationRequestResources.PaperSize}
                                            value={this.prescriptionPrintPreferences.paperSize}
                                            onChange={this.prescriptionPrintPreferences.setPaperSize}
                                            clearable={false}
                                            automationId="printPaperSizeSelectBox"
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item sm={6}>
                                        <PrintOrientationSelectBox
                                            label={StaticHunSocialSecurityMedicationRequestResources.Orientation}
                                            value={this.prescriptionPrintPreferences.orientation}
                                            onChange={this.prescriptionPrintPreferences.setOrientation}
                                            clearable={false}
                                            automationId="printOrientationSelectBox"
                                        />
                                    </Ui.Flex.Item>
                                </Ui.Flex>
                                <Ui.Flex>
                                    <Ui.Flex.Item sm={3}>
                                        <Ui.NumberBox
                                            label={StaticHunSocialSecurityMedicationRequestResources.OffSetX}
                                            value={this.prescriptionPrintPreferences.offsetX}
                                            onChange={this.prescriptionPrintPreferences.setOffsetX}
                                            nullValue={0}
                                            clearable={false}
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item sm={3}>
                                        <Ui.NumberBox
                                            label={StaticHunSocialSecurityMedicationRequestResources.OffSetY}
                                            value={this.prescriptionPrintPreferences.offsetY}
                                            onChange={this.prescriptionPrintPreferences.setOffsetY}
                                            nullValue={0}
                                            clearable={false}
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item sm={6} >
                                        <div className={Styles.label} >{StaticHunSocialSecurityMedicationRequestResources.Rotate}</div>
                                        <>
                                            <Ui.Button
                                                onClick={this.prescriptionPrintPreferences.rotateRight}
                                                visualStyle="standard"
                                                iconName="rotate_right"
                                                size="standard"
                                                automationId="rotateRightButton"
                                            />
                                            <Ui.Button
                                                onClick={this.prescriptionPrintPreferences.rotateLeft}
                                                visualStyle="standard"
                                                iconName="rotate_left"
                                                size="standard"
                                                automationId="rotateLeftButton"
                                            />
                                        </>
                                    </Ui.Flex.Item>
                                    {this.getIsPrinted &&
                                        <>
                                            <Ui.Separator />
                                            <Ui.Flex.Item xs={12}>
                                                <Ui.Message type="info"><Ui.Icon iconName="infoCircle" /> {StaticHunSocialSecurityMedicationRequestResources.PrescriptionPrintDialog.ReprintReasonInfoMessage}</Ui.Message>
                                            </Ui.Flex.Item>
                                            <Ui.Flex.Item xs={12}>
                                                <Ui.TextBox
                                                    value={this.reprintReason}
                                                    onChange={this.onChangeReprintReason}
                                                    label={StaticHunSocialSecurityMedicationRequestResources.PrescriptionPrintDialog.ReprintReason}
                                                    propertyIdentifier="ReprintReason"
                                                    automationId="reprintReasonTextBox" />
                                            </Ui.Flex.Item>
                                        </>}
                                </Ui.Flex>
                            </div>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item sm={8}>
                            <Ui.PdfViewer2
                                height="100%"
                                documentPath={this.getDocumentPath}
                                downloadFileName={this.getDownloadFileName}
                                onPrintClickedAndValidatedAsync={this.printClickedAsync}
                                printBehavior="download-pdf"
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </HisUi.ContextAwareModal>
            </ValidationBoundary>);
    }

    @State.action.bound
    private close() {
        this.props.onClose(null);
    }

    @State.bound
    private closeWithConfirmation() {
        dispatchAsyncErrors(this.closeAndConfirmAsync(), this);
    }

    @State.action.bound
    private async closeAndConfirmAsync() {
        this.close();
        if (!!this.isPrintClicked) {
            const printSuccessResult = await this.dialogService.yesNo(
                StaticHunSocialSecurityMedicationRequestResources.PrescriptionPrintDialog.PrintSuccessConfirmationDialog.Title,
                StaticHunSocialSecurityMedicationRequestResources.PrescriptionPrintDialog.PrintSuccessConfirmationDialog.Message);
            if (printSuccessResult.resultCode === DialogResultCode.No) {
                await this.prescriptionApiAdapter.deletePrescriptionPrintDataAsync(this.prescriptionPrintDataId, this.deleteToken);
                this.setDeleteToken(null);
            }
        }
        this.isPrintClicked = false;
    }
}

export default connect(
    PrintPrescriptionDialog,
    new DependencyAdapter<IPrintPrescriptionDialogProps, IPrintPrescriptionDialogDependencies>(c => ({
        prescriptionApiAdapter: c.resolve("PrescriptionApiAdapter"),
        notificationService: c.resolve("INotificationService"),
        dialogService: c.resolve("IDialogService"),
    }))
);