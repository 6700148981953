import React from "react";
import ISelectBoxBaseProps from "@CommonControls/SelectBox/ISelectBoxBaseProps";
import UniversalEnumSelector from "@CommonControls/UniversalEnumSelector/UniversalEnumSelector";
import PerformanceStatementStatus from "@HunSocialSecurityPlugin/BoundedContexts/PerformanceStatement/Api/PerformanceStatement/Enum/PerformanceStatementStatus.g";

interface IPerformanceStatementStatusMultiSelectProps extends ISelectBoxBaseProps {
    value?: PerformanceStatementStatus | PerformanceStatementStatus[];
    onChange?: (newValue: PerformanceStatementStatus | PerformanceStatementStatus[]) => void;
}


const PerformanceStatementStatusSelectBox: React.FC<IPerformanceStatementStatusMultiSelectProps> = props => (
    <UniversalEnumSelector
        {...props}
        enumName={"PerformanceStatementStatus"}
        enumOrigin="server"
        enumType={PerformanceStatementStatus}
        displayMode="selectBox"
    />
);

export default PerformanceStatementStatusSelectBox;