import { IModalParams } from "@Toolkit/ReactClient/Components/ModalService/ModalServiceAbstractions";
import PointOfCareRoles from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/Actions/PointOfCareRoles";
import User from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/Users/User";
import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import UserId from "@Primitives/UserId.g";
import UserGroupId from "@Primitives/UserGroupId.g";

export interface ICopyUserGroupModalParams extends IModalParams {
    user: User;
    practitioner: PractitionerStore;
    copyUserGroups: (userGroups: UserGroupId[]) => void;

}
export interface IRoleEditorModalResult {
    pointOfCareRoles: PointOfCareRoles[];
    selectedUserId: UserId;
}

export default class CopyUserGroupModalParams implements ICopyUserGroupModalParams {

    public static type = "CopyUserGroupModalParams";
    public get type() { return CopyUserGroupModalParams.type; }

    constructor(
    public readonly user: User,
    public readonly practitioner: PractitionerStore,
    public readonly copyUserGroups: (userGroups: UserGroupId[]) => void,

    ) { }

}