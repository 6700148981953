import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import SlotStore from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/SlotStore";
import { IRowCheckState } from "@CommonControls/DataGrid/IDataGridProps";
import AppointmentSchedulerEvent from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentScheduler/AppointmentSchedulerEvent";
import moment from "moment";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import React from "react";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import * as Ui from "@CommonControls";
import Style from "./AppointmentSchedulePanelView.less";
import StaticSchedulingResources from "@HisPlatform/BoundedContexts/Scheduling/StaticResources/StaticSchedulingResources";
import { ISchedulerEvent } from "@CommonControls/Scheduler/ISchedulerProps";
import AppointmentCreationSchedule from "./AppointmentCreationSchedule";
import AppointmentParticipantPanel from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/AppointmentParticipantPanel/AppointmentParticipantPanel";
import AppointmentFilterStore from "@HisPlatform/BoundedContexts/Scheduling/Components/Panels/Scheduling/RegisteredPatientAppointmentsMasterDetailPanel/DoctorPointOfCareListPanel/AppointmentFilterStore";
import { TypedAsyncEvent } from "@Toolkit/CommonWeb/TypedAsyncEvent";
import ServiceRequestDefinitionId from "@Primitives/ServiceRequestDefinitionId.g";
import InvalidEvent from "@CommonControls/Scheduler/InvalidEvent";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import InvalidationInfo from "@HisPlatform/BoundedContexts/Scheduling/ApplicationLogic/Model/Scheduling/InvalidationInfo";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import AppointmentInvalidationReason from "@HisPlatform/BoundedContexts/Scheduling/Api/Scheduling/Enum/AppointmentInvalidationReason.g";

interface IAppointmentSchedulePanelViewDependencies {
    localizationService: ILocalizationService;
}

interface IAppointmentSchedulePanelViewProps {
    _dependencies?: IAppointmentSchedulePanelViewDependencies;
    firstSlotDisplayText: JSX.Element;
    freeSlotAvailable: boolean;
    showFirstSlotMessage: boolean;
    bookFirstAsync: () => Promise<void>;
    dismissFirstFreeSlotMessage: () => void;
    dataSource: InMemoryDataGridDataSource;
    rowCheckState: (row: SlotStore, rowId: React.ReactText, rowIndex: number) => IRowCheckState;
    selectRow: (isChecked: boolean, row: SlotStore, rowId: React.ReactText, rowIndex: number) => void;
    events: AppointmentSchedulerEvent[];
    invalidEvents: InvalidEvent[];
    isAppointmentPlaceable: (event: ISchedulerEvent) => boolean;
    placeAppointment: (event: ISchedulerEvent) => void;
    isCurrentEntry: (event: ISchedulerEvent) => boolean;
    selectedMoment: moment.Moment;
    setSelectedMoment: (value: moment.Moment) => void;
    newEventDuration: number;
    onSelectedMonthChange: (value: LocalDate) => void;
    onRenderCurrentEntryTooltip: () => React.ReactNode;
    readonly: boolean;
    onRenderCalendar?: (calendar: React.ReactNode) => React.ReactNode;
    appointmentFilterStore: AppointmentFilterStore;
    filtersChangedEvent: TypedAsyncEvent<void>;
    serviceRequestDefinitionId: ServiceRequestDefinitionId;
    isTelemedicineConsultation: boolean;
    invalidationInfo: InvalidationInfo;
    invalidationReason: AppointmentInvalidationReason;
}

@State.observer
class AppointmentSchedulePanelView extends React.Component<IAppointmentSchedulePanelViewProps> {

    @State.bound
    private gridView() {
        return (
            <Ui.DataGrid
                dataSource={this.props.dataSource}
                fixedLayout={true}
                generateInitialFilterStore={true}
                rowCheckState={this.props.rowCheckState}
                onRowChecked={this.props.selectRow}
                automationId="appointmentSchedulePanelView_dataGrid"
            >
                <DataGridColumn
                    width="150px"
                    header={StaticSchedulingResources.AppointmentSchedulePanel.ScheduleEntryStatus}
                    dataGetter={"getLocalizedValue"} />
                <DataGridColumn
                    width="500px"
                    header={StaticSchedulingResources.AppointmentSchedulePanel.ScheduleEntryInterval}
                    dataGetter={"interval"} />
            </Ui.DataGrid>
        );
    }

    @State.bound
    private renderFirstSlotMessage() {
        return (
            <>
                {!!this.props.events.length && this.props.showFirstSlotMessage &&
                    <Ui.Message type="primary" className={Style.message}>
                        <Ui.Flex xsJustify="between">
                            <Ui.Flex.Item xs={10}>
                                <div>{this.props.firstSlotDisplayText}</div>
                                {
                                    this.props.isTelemedicineConsultation &&
                                    <SpanWithIcon iconName="telemedicine" automationId="telemedicine">{StaticSchedulingResources.AppointmentSchedulePanel.TelemedicineConsultation}</SpanWithIcon>
                                }
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={2} style={{ alignSelf: "center" }}>
                                <div style={{ float: "right" }}>
                                    {this.props.freeSlotAvailable && <Ui.Button className={Style.bookFirstButton} visualStyle="primary" size="compact"
                                        onClickAsync={this.props.bookFirstAsync} text={StaticSchedulingResources.AppointmentSchedulePanel.BookFirstFreeSlot}
                                        automationId="_bookFirstAvailableSlotButton" />}
                                    <Ui.Icon className={Style.dismissMessage} iconName="remove" size="compact"
                                        visualStyle="primary" onClick={this.props.dismissFirstFreeSlotMessage} />
                                </div>
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Message>}
            </>
        );
    }

    @State.bound
    private appointmentParticipantPanel() {
        return (
            <>
                <AppointmentParticipantPanel
                    readonly={this.props.readonly}
                    filterStore={this.props.appointmentFilterStore}
                    filtersChangedEvent={this.props.filtersChangedEvent}
                    serviceRequestDefinitionId={this.props.serviceRequestDefinitionId}
                />
            </>
        );
    }

    @State.bound
    private renderInvalidationInfo() {
        return (
            <>
                {!!this.props.invalidationInfo &&
                    <Ui.Message type="primary" className={Style.message}>
                        <div>
                            <div><b>{StaticSchedulingResources.AppointmentSchedulePanel.InvalidationInfo.Title}</b></div><br />
                            <div>{StaticSchedulingResources.AppointmentSchedulePanel.InvalidationInfo.AppointmentInvalidationReason}: <b>{this.props._dependencies.localizationService.localizeEnum(AppointmentInvalidationReason[this.props.invalidationReason], "AppointmentInvalidationReason").Name}</b></div>
                            <div>{StaticSchedulingResources.AppointmentSchedulePanel.InvalidationInfo.AppointmentScheduleDefinition}: <b>{this.props.invalidationInfo.appointmentScheduleDefinition ?? "n/a"}</b></div>
                            <div>{StaticSchedulingResources.AppointmentSchedulePanel.InvalidationInfo.SchedulingService}: <b>{this.props.invalidationInfo.schedulingService ?? "n/a"}</b></div>
                            <div>{StaticSchedulingResources.AppointmentSchedulePanel.InvalidationInfo.OrganizationUnit}: <b>{this.props.invalidationInfo.organizationUnit ?? "n/a"}</b></div>
                            <div>{StaticSchedulingResources.AppointmentSchedulePanel.InvalidationInfo.Practitioner}: <b>{this.props.invalidationInfo.practitioner ?? "n/a"}</b></div>
                        </div>
                    </Ui.Message>}
            </>
        );
    }

    @State.bound
    private renderAdditionalBodyComponent() {
        return (
            <>
                {this.renderInvalidationInfo()}
                {this.appointmentParticipantPanel()}
                {this.renderFirstSlotMessage()}
            </>
        );
    }
    public render() {
        return (
            <>
                <AppointmentCreationSchedule
                    events={this.props.events}
                    invalidEvents={this.props.invalidEvents}
                    listView={this.gridView()}

                    isEntryEditable={this.props.isAppointmentPlaceable}
                    onEditEntry={this.props.placeAppointment}

                    isCurrentEntry={this.props.isCurrentEntry}

                    currentMoment={this.props.selectedMoment}
                    setSelectedMoment={this.props.setSelectedMoment}
                    newEventDuration={this.props.newEventDuration}
                    onSelectedMonthChange={this.props.onSelectedMonthChange}
                    onRenderTooltip={this.props.onRenderCurrentEntryTooltip}

                    onRenderCalendar={this.props.onRenderCalendar}
                    additionalBodyComponent={this.renderAdditionalBodyComponent()}
                />
            </>
        );
    }
}

export default connect(
    AppointmentSchedulePanelView,
    new DependencyAdapter<IAppointmentSchedulePanelViewProps, IAppointmentSchedulePanelViewDependencies>(c => {
        return {
            localizationService: c.resolve("ILocalizationService")
        };
    })
);
