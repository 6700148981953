import React from "react";
import { ICommonControlProps } from "@Toolkit/ReactClient/Common/CommonControlProps";
import Icon from "@CommonControls/Icon";
import Styles from "./NoItemsMessage.less";

interface INoItemsMessageProps extends ICommonControlProps {
    message?: string;
    showEmptyIcon?: boolean;
}

export default class NoItemsMessage extends React.PureComponent<INoItemsMessageProps> {
    public static defaultProps: Partial<INoItemsMessageProps> = {
        showEmptyIcon: true
    };
    public render() {
        return (
            <div style={{ textAlign: "center" }} data-automation-id="__noItemsMessage">
                {this.props.showEmptyIcon ? < Icon iconName="empty" size="giant" /> : <div style={{ height: "41px" }} />}
                {this.props.message && <p className={Styles.messageText}>{this.props.message}</p>}
                {this.props.children}
            </div>
        );
    }
}