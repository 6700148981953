import MedicationId from "@Primitives/MedicationId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IEntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/IEntityVersionSelector";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import EntityVersionSelector from "@Toolkit/CommonWeb/TemporalData/EntityVersionSelector";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";

export default class MedicationFormulaListItem {
    @State.observable.ref public medicationVersionSelector: EntityVersionSelector<MedicationId>;
    @State.observable public amount: number = 0;
    @State.observable public unit: string = "";

    @State.action.bound
    public setMedicationVersionSelector(medicationVersionSelector: EntityVersionSelector<MedicationId>) {
        this.medicationVersionSelector = medicationVersionSelector;
    }

    @State.action.bound
    public setAmount(amount: number) {
        this.amount = amount;
    }

    @State.action.bound
    public setUnit(unit: string) {
        this.unit = unit;
    }
}
