import Di from "@Di";
import IProfileItem from "./IProfileItem";
import { ProfileKeys } from "./UserProfileKeys";
import IWidgetMode from "./IWidgetMode";
import ProfileApiAdapter from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/ApiAdapter/Profiles/ProfileApiAdapter";
import { arrayIsNullOrEmpty, isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import IDashboardState from "@HisPlatformControls/HisDashboard/IDashboardState";
import AsyncMessageQueue from "@Toolkit/CommonWeb/AsyncMessageQueue";
import State from "@Toolkit/ReactClient/Common/StateManaging";

interface IUserProfileAction {
    type: "set" | "get";
    key: string;
    value?: string;
}

@Di.injectable()
export default class UserProfileStore {

    private messageQueue = new AsyncMessageQueue<IUserProfileAction, object>(this.processMessageAsync);

    constructor(
        @Di.inject("ProfileApiAdapter") private readonly profileApiAdapter: ProfileApiAdapter
    ) {
    }

    @State.bound
    private async processMessageAsync(message: IUserProfileAction) {
        if (message.type === "get") {
            const wm = await this.profileApiAdapter.loadByIdsAsync(message.key);
            if (wm.operationWasSuccessful) {
                if (!arrayIsNullOrEmpty(wm.value) && wm.value[0] && wm.value[0].value) {
                    return JSON.parse(wm.value[0].value);
                }
                return null;
            }
            return null;
        } else if (message.type === "set") {
            const profileItem = {
                key: message.key,
                value: message.value
            } as IWidgetMode;
            await this.profileApiAdapter.setProfileItemsAsync(profileItem);
            return null;
        }
        return null;
    }

    public async saveWidgetModeAsync(dashboardState: IDashboardState) {
        if (!dashboardState) {
            return;
        }
        await this.messageQueue.enqueueAndProcessAsync({
            key: ProfileKeys.WidgetModeDefinition,
            type: "set",
            value: JSON.stringify(dashboardState)
        });
    }

    public async getSavedWidgetModeAsync(): Promise<IDashboardState> {

        const value = await this.messageQueue.enqueueAndProcessAsync({
            key: ProfileKeys.WidgetModeDefinition,
            type: "get",
        });
        return value as IDashboardState;
    }
} 