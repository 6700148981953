import Di from "@Di";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import MedicalConditionHistoryItemDetail from "@HisPlatform/BoundedContexts/Care/ApplicationLogic/Model/MedicalCondition/MedicalConditionHistoryItemDetail";
import moment from "moment";
import PregnancyStatementStore from "./PregnancyStatementStore";
import PregnancyStatementId from "@Primitives/PregnancyStatementId.g";
import PregnancyStatementHistoryItem from "./PregnancyStatementHistoryItem";

@Di.injectable()
export default class PregnancyStatementFactory {

    public createNewStore() {
        const store = new PregnancyStatementStore(true);
        store.id = new PregnancyStatementId("new");
        store.expectedAt = LocalDate.today();
        store.historyItems.push(this.createNewHistoryItem());
        store.currentItemIndex = 0;

        return store;
    }

    private createNewHistoryItem() {
        const historyItem = new PregnancyStatementHistoryItem();

        historyItem.isNew = true;
        historyItem.versionNumber = 1;
        historyItem.index = 0;
        historyItem.createdAt = moment(moment.now());
        historyItem.pregnancyOutcomeAssignment = null;
        historyItem.historyItemDetail = new MedicalConditionHistoryItemDetail();

        return historyItem;
    }
}
