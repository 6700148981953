import PractitionerStore from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/PractitionerStore";
import IDoctor from "@HisPlatform/BoundedContexts/Organization/ApplicationLogic/Model/Practitioner/IDoctor";
import NameStore from "@Primitives/NameStore";
import EntityStoreBase from "@Toolkit/CommonWeb/Model/EntityStoreBase";
import PractitionerId from "@Primitives/PractitionerId.g";

export default class DoctorStore extends EntityStoreBase<PractitionerId> implements IDoctor {
    public code: string;
    public name: NameStore;

}
