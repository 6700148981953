import React, { useEffect } from "react";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import ILoginRouteParams from "@HisPlatform/Application/Routes/ILoginRouteParams";
import IAuthenticationService from "@HisPlatform/Services/Definition/Authentication/IAuthenticationService";
import { useErrorDispatcher } from "@Toolkit/CommonWeb/AsyncHelpers";
import { useDependencies } from "@Toolkit/ReactClient/Components/DependencyInjection/UseDependencies";
import LoadingIndicator from "@CommonControls/LoadingIndicator";
import GlobalRoutingStore from "@Toolkit/ReactClient/Routing/Abstractions/GlobalRoutingStore";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";

export function LoginPage(props: IRoutingFrameContentProps<ILoginRouteParams>) {
    const { authenticationService } = useDependencies(c => ({
        authenticationService: c.resolve<IAuthenticationService>("IAuthenticationService"),
    }));
    const dispatchError = useErrorDispatcher();

    useEffect(() => {
        authenticationService.authenticateAsync(props.routingController.currentRoute.parameters.redirectTo).catch(dispatchError);
    }, []);

    return (
        <p>Redirecting to login...</p>
    );
}

export function LoginCallbackPage() {
    return (
        <LoadingIndicator mode="fullscreen" />
    );
}

export function LoggedOutPage() {

    const { routingStore } = useDependencies(c => ({
        routingStore: c.resolve<GlobalRoutingStore>("GlobalRoutingStore"),
    }));

    useEffect(() => {
        setTimeout(() => {
            routingStore.replace(ApplicationRoutes.login.makeRoute());
        }, 250);
    }, []);

    return (
        <LoadingIndicator mode="fullscreen" />
    );
}
