import State from "@Toolkit/ReactClient/Common/StateManaging";
import TokenUsageStore from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/Model/Templating/TokenUsageStore";
import { ITemplateContentTokenUsage } from "@HisPlatform/BoundedContexts/DocumentManagement/ApplicationLogic/ApiAdapter/Templating/ITemplateContent";
import _ from "@HisPlatform/Common/Lodash";
import { arrayIsNullOrEmpty, emptyArray } from "@Toolkit/CommonWeb/NullCheckHelpers";

export default class TokenUsageListStore {
    private readonly tokenUsages = State.createObservableShallowMap<string, TokenUsageStore>();
    private nextIndex: number;

    @State.computed public get items() {
        return Array.from(this.tokenUsages.values());
    }

    constructor(
        tokenUsages: ITemplateContentTokenUsage[] = emptyArray
    ) {
        this.tokenUsages.replace(tokenUsages.map(u =>
            ([u.id, TokenUsageStore.parse(u.id, u.tokenFormatterSettings, u.providerParameterSettings)])
        ));

        const orderedUsages = _.orderBy(Array.from(this.tokenUsages.values()), u => u.index, "desc");
        this.nextIndex = arrayIsNullOrEmpty(orderedUsages) ? 1 : (orderedUsages[0].index + 1);
    }

    public useToken(symbol: string): TokenUsageStore {
        const tokenUsage = new TokenUsageStore(symbol, this.nextIndex, {}, null);
        this.tokenUsages.set(tokenUsage.tokenId, tokenUsage);
        this.nextIndex++;
        return tokenUsage;
    }

    public get(tokenId: string) {
        return this.tokenUsages.get(tokenId);
    }

    public delete(tokenId: string) {
        return this.tokenUsages.delete(tokenId);
    }
}